import React, {useState, Fragment, useEffect} from "react";
import ScoringItemDisplay from "./ScoringItem";
import cx from "classnames";
import Icon from "../atoms/Icon";
import {setSubset} from "../../actions/scoring";
import {useTranslation} from "react-i18next";
import DomainSummaryDisplay from "./DomainSummary";

// a set of scoreable items
// equivalent to a whole 'domain' for Bakkkenist, but for MNT its the set of 'domains'
export default function ScoringSetDisplay(props) {

    const currentDomain = props.currentDomain;

    const {t, i18n} = useTranslation();

    function descriptionHtmlContent() {
        return {
            __html: currentDomain.description
        }
    }

    useEffect(() => {
        if(currentDomain?.children?.length > 0) {
            for(let it=0; it<=currentDomain.children.length - 1; it++) {
                let scoringItem = currentDomain.children[it];
                if(scoringItem?.children?.length > 0) {
                    if(scoringItem?.children?.[0]?.children?.length > 0) {
                        let initialClosed = scoringItem.children[0].children.every(obj => obj?.scores?.some(score => props?.isSelected(obj.pk, score.id)));
                        props.setSubSet(scoringItem.number, !initialClosed, false, initialClosed);
                    }
                }
            }
        }
    },[currentDomain?.children?.length]);


    return (
        <ol>
            {currentDomain.children.map((scoringItem,idx) => {
                const scoringStatus = props.getStructureStatus(scoringItem.pk);
                const helpTableWarning = props.subSet?.[scoringItem.number]?.complete === false ;

                let icon = "";
                let notificationAppend = '';
                let scoringAppend = '';

                if(scoringStatus?.valid === false) {
                    icon = "error";
                } else if(scoringStatus?.warning) {
                    icon = "warning";
                }

                if(scoringStatus) {
                    if(scoringStatus?.updating) {
                        icon = "";
                        notificationAppend = 'scoring-notification--updating';
                        scoringAppend = 'scoring-item--updating';
                    } else if (scoringStatus?.warning) {
                        icon = "warning";
                        notificationAppend = 'scoring-notification--warning';
                        scoringAppend = 'scoring-item--warning'
                    } else if (!scoringStatus?.valid) {
                        icon = "error";
                        notificationAppend = 'scoring-notification--invalid';
                        scoringAppend = 'scoring-item--invalid'
                    } else if (scoringStatus?.valid) {
                        scoringAppend = 'scoring-item--valid';
                    }
                }
                if(helpTableWarning) {
                    icon = "warning";
                    notificationAppend = 'scoring-notification--warning';
                    scoringAppend = 'scoring-item--warning'
                }


                return (
                    <Fragment key={idx}>
                        <li key={scoringItem.pk}>
                            {
                                ( scoringStatus?.updating !== true && (((scoringStatus?.valid === false) || scoringStatus?.warning) || helpTableWarning) ) && (
                                <div className={cx("scoring-notification", notificationAppend)}>
                                    { (icon !== "") && (
                                        <Icon icon={icon} sizeModifier={"xsmall"} className={"scoring-notification__icon"}/>
                                    )}
                                    <ul>
                                        {/* Only display the first validation error */}
                                        {scoringStatus?.validation_error.filter((o,idx) => idx ===0)?.map(obj => (
                                            <li key={obj} className={"scoring-notification__item"}>
                                                {obj}
                                            </li>
                                        ))}
                                        {helpTableWarning && (
                                            <li key={"no-help-table"} className={"scoring-notification__item"}>
                                                {t("Het wordt sterk aangeraden de hulptabel volledig in te vullen")}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div
                                className={cx("scoring-item", scoringAppend, {
                                    // "scoring-notification--invalid": props.subSet?.[scoringItem.number]?.complete === false
                                })}>
                                <ScoringItemDisplay
                                    edit={props?.edit}
                                    getStructureMessages={props.getStructureMessages}
                                    numberPrefix={props.numberPrefix}
                                    scoringItem={scoringItem}
                                    isSelected={(score_id) => props.isSelected(scoringItem.pk, score_id)}
                                    isHighlighted={(score_id) => props.isHighlighted(scoringItem.pk, score_id)}
                                    setScore={(score_id) => props.setScore(scoringItem.pk, score_id)}
                                />

                                {scoringItem?.children?.length > 0 && (
                                    <button className={"expand-button"} onClick={() => props.setSubSet(scoringItem.number, !props.subSet?.[scoringItem.number].state, true) }>
                                        {t("Hulptabel")}
                                        <Icon icon={"angle-" + (props.subSet?.[scoringItem.number].state === true ? "up": "down")} sizeModifier={"xsmall"} className={"expand-button__icon"}/>
                                    </button>
                                )}

                            </div>
                            {scoringItem?.children?.length > 0 && (
                                <div className={cx(
                                    "scoring__sub-set",
                                    {
                                        "scoring__sub-set--open": props.subSet?.[scoringItem.number].state === true,
                                    }
                                )}>
                                    <h4 className={"scoring__sub-set-title"}>{t("Hulptabel domein")} {scoringItem.number}</h4>
                                    {scoringItem.children.map((section, sectionIdx) => {
                                        return (
                                            <ScoringSetDisplay
                                                edit={props?.edit}
                                                key={sectionIdx}

                                                scoringItem={scoringItem}
                                                numberPrefix={(props.numberPrefix ? props.numberPrefix + '.' : '') + scoringItem.number}
                                                currentDomain={{
                                                    children: section.children
                                                }}
                                                getStructureStatus={props.getStructureStatus}
                                                getStructureMessages={props.getStructureMessages}
                                                isSelected={props.isSelected}
                                                isHighlighted={(score_id) => props.isHighlighted(scoringItem.pk, score_id)}
                                                setScore={props.setScore}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </li>
                    </Fragment>
                )
            })}
        </ol>
    )

}
