
import { BreadcrumbsDefaultState} from "../patterns/organisms/Breadcrumbs";
import {SET_BREADCRUMB_ITEM} from "../actions/breadcrumbs";

export function BreadcrumbsState(state=BreadcrumbsDefaultState, action) {

    let ret = {
        ...state,
    }

    switch(action.type) {
        case SET_BREADCRUMB_ITEM:
            ret[action.breadcrumb] = action.content;
            return ret;
        default:
            return state;
    }
}
