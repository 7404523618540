import {
    SET_FGS_GROUP_SETTING,
    SET_FGS_DEFAULT_SETTING,
    SET_FGS_GROUP_SETTINGS_ALL, SET_FGS_RECALCULATE_USING, SET_FGS_GROUP_STATE, SET_FGS_GROUP_STATE_MULTIPLE
} from "../actions/function_group_structure";
import {FunctionGroupStructureDefaultState} from "../components/FunctionGroupStructure";

export function FunctionGroupStructureState(state=FunctionGroupStructureDefaultState, action) {

    let ret = {
        ...state,
    };
    const fgsDefault = {
        group_start: 1,
        group_end: 15,
        points_start: 1,
        default_factor: 1.25,
        groups: [],
        groupState: {},
        recalculateUsing: false,
    }

    const groupDefault = {
        factor: 1.25,
        function_group_structure: 93,
        id: 0,
        label: false,
        number: false,
        points_min: false,
        points_max: false,
        error: false,
    }

    switch(action.type) {

        case SET_FGS_DEFAULT_SETTING:
            ret.projects = {
                ...ret.projects,
                [action.projectId]: {
                    ...fgsDefault,
                    ...ret.projects?.[action.projectId],
                    ...action.value, // deliberately put BEFORE groups because we want the overwrite groups as you should not set it using defaults
                }
            }
            if(action?.recalculateUsing) {
                ret.projects[action.projectId].recalculateUsing = action.recalculateUsing;
            }
            return ret;


        case SET_FGS_RECALCULATE_USING:
            ret.projects = {
                ...ret.projects,
                [action.projectId]: {
                    ...fgsDefault,
                    ...ret.projects?.[action.projectId],
                    recalculateUsing: action.recalculateUsing,
                }
            }
            return ret;


        case SET_FGS_GROUP_SETTING:
            ret.projects = {
                ...ret.projects,
                [action.projectId]: {
                    ...fgsDefault,
                    ...ret.projects?.[action.projectId],
                    groups: [
                        ...ret.projects?.[action.projectId]?.groups || [],
                    ],
                }
            }

            if(action?.recalculateUsing) {
                ret.projects[action.projectId].recalculateUsing = action.recalculateUsing;
            }

            let idx = ret.projects?.[action.projectId]?.groups.findIndex(group => group.number === action.groupNumber);

            if(idx >= 0) {
                ret.projects[action.projectId].groups[idx] = {
                    ...groupDefault,
                    ...ret.projects?.[action.projectId]?.groups[idx] || [],
                    ...action.value,
                }
            }

            return ret;

        case SET_FGS_GROUP_STATE:

            ret.projects = {
                ...ret.projects,
                [action.projectId]: {
                    ...ret.projects?.[action.projectId],
                    groupState: {
                        ...ret.projects?.[action.projectId].groupState,
                        [action.groupId]: action.state,
                    }
                }
            };
            return ret;

        case SET_FGS_GROUP_STATE_MULTIPLE:

            ret.projects = {
                ...ret.projects,
                [action.projectId]: {
                    ...ret.projects?.[action.projectId],
                    groupState: action.obj,
                }
            };
            return ret;

        case SET_FGS_GROUP_SETTINGS_ALL:
            ret.projects = {
                ...ret.projects,
                [action.projectId]: {
                    ...fgsDefault,
                    ...ret.projects?.[action.projectId],
                    groups: action.value,
                }
            }
            return ret;

        default:
            return ret;
    }
}
