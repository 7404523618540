// Returns patternList1 with a compared specification and distance
export function comparePattern(patternList1, patternList2) {

    let returnList = [];

    for(let it=0; it<patternList1.length; it++) {
        const pl1 = patternList1[it];
        const pl2 = patternList2?.[it] || {score: '-'};

        const code1 = (pl1.score === '-' || pl1.score ==='X') ? 64 : pl1.score.charCodeAt(0);
        const code2 = (pl2.score === '-' || pl2.score ==='X') ? 64 : pl2.score.charCodeAt(0);

        let comp = Math.abs(code1 - code2);
        comp = comp > 2 || comp < -2 ? 2 : comp;

        returnList.push({
            ...pl1,
            comparison: comp,
        })
    }

    return returnList;

}
