import useSWR, {useSWRInfinite} from "swr";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder} from "../utils/constants";
import axios from "axios";

export function getLicenses(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.end_datetime__lte ? {
                end_datetime__lte: fetchState?.identKey?.end_datetime__lte
            } : {},
            fetchState?.identKey?.end_datetime__gte ? {
                end_datetime__gte: fetchState?.identKey?.end_datetime__gte
            } : {},
            fetchState?.identKey?.end_datetime__isnull ? {
                end_datetime__isnull: fetchState?.identKey?.end_datetime__isnull
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/licenses/?page=${index + 1}&ordering=${ordering}${filtering}` : false,
        {
            initialSize: fetchState?.size || 1
        }
    );
}

export function getLicenseTypes() {
    const url = "/api/licensetypes/";

    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getLicense(license_id) {
    const url = `/api/licenses/${license_id}/`;

    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export async function editLicense(license_id, values, navigate, setError, t) {
    await axios.patch(`/api/licenses/${license_id}/`, values)
        .then(result => {
            if (result.status === 200) {
                navigate("../");
            } else {
                setError("server", {
                    type: "server",
                    message: t("Er is iets verkeerd gegaan.")
                })
            }
        }).catch(error => {
            console.log(error);
            setError("server", {
                type: "server",
                message: t("Er is iets verkeerd gegaan.")
            })
        })
}
