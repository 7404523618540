export const ADD_ITEM = 'ADD_ITEM';

export function addItem(data) {

    return {
        type: ADD_ITEM,
        data,
    }
}

