import React, {Fragment} from "react";
import cx from "classnames";
import { functionStatus } from "../../utils/constants";
import i18n from "../../utils/i18n";
import ToolTip from "../../components/ToolTip";
import StatusPipTooltip from "../../components/StatusPipTooltip";

export const PipPatternState = {
    type: "concept",
    name: "Concept",
    full: false,
    plMod: {
        counter: {
            type: "to-counter",
            name: i18n.t("Te toetsen"),
        },
        countered: {
            type: "is-countered",
            name: i18n.t("Getoetst"),
        },
        determined: {
            type: "determined",
            name: i18n.t("Vastgesteld"),
        },
        expired: {
            type: "expired",
            name: i18n.t("Vervallen"),
        },
        archived: {
            type: "archived",
            name: i18n.t("Gearchiveerd"),
        },
        fullDetermined: {
            type: "determined",
            name: i18n.t("Vastgesteld"),
            full: true,
        },
        fullExpired: {
            type: "expired",
            name: i18n.t("Vervallen"),
            full: true,
        },
        fullArchived: {
            type: "archived",
            name: i18n.t("Vastgesteld"),
            full: true,
        }
    }
}

export function FunctionStatus(props) {
    let description = props?.description_status || 'skeleton';
    let scoring = props?.scoring_status || 'skeleton';
    let full = (description === scoring && ['determined', 'expired', 'archived'].includes(description));
    const StatusPipTooltipFun = () => <StatusPipTooltip {...props}/>

    return (
        <ToolTip component={StatusPipTooltipFun}
                 fullHeight={true}
        >
            <div className={"status-pip-wrapper"}>
            <Pip type={description} full={full} hideTooltip={true}/>
            {!full && (
                <Pip type={scoring} full={false} hideTooltip={true}/>
            )}
            </div>
        </ToolTip>
    )

}

export default function Pip(props) {
    return (
        <>
            {props.hideTooltip ?
                <span className={cx("status-pip", "status-pip--"+props.type, {"status-pip--full": props.full}, props.className)} /> :
                <ToolTip tooltip={functionStatus[props.type]}>
                    <span className={cx("status-pip", "status-pip--"+props.type, {"status-pip--full": props.full}, props.className)} />
                </ToolTip>
            }
        </>
    )
}
