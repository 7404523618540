import { combineReducers } from 'redux';
import { HeaderState } from "./header";
import { FormState } from "./form";
import { PatternStructureState } from "./patternlab";
import {OverviewState} from "./overview";
import {PopOverState} from "./popover";
import {BreadcrumbsState} from "./breadcrumbs";
import {ScoringState} from "./scoring";
import {AuthState} from "./auth";
import {FunctionGroupStructureState} from "./function_group_structure";
import {HeartbeatState} from "./heartbeat";
import {SiteInfo} from "./site_info";

// should persist, but could be mutated)
export function componentConfig(state={}, action) {
    switch(action.type) {
        default:
            return state;
    }
}

// For future reference; look into https://github.com/paularmstrong/normalizr for normalizing REST-view served
// database layouts
let reducers = {
    componentConfig: componentConfig,
    PatternStructureState: PatternStructureState,
    HeaderState: HeaderState,
    FormState: FormState,
    OverviewState: OverviewState,
    PopOverState: PopOverState,
    BreadCrumbsState: BreadcrumbsState,
    ScoringState: ScoringState,
    AuthState: AuthState,
    FunctionGroupStructureState: FunctionGroupStructureState,
    HeartbeatState: HeartbeatState,
    SiteInfo: SiteInfo,
}


// combine all reducers to a single item.
export default combineReducers(reducers);
