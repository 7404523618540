import React, {useEffect, useRef, useState} from "react";
import IconButton from "../molecules/IconButton";
import {Icon} from "../atoms/Icon";
import cx from "classnames";
import SidebarSummary from "../molecules/SidebarSummary";
import {
    SidebarOverviewDefinition,
    SidebarOverviewRelatedAutomaticFunctionDefinition,
    SidebarOverviewRelatedDomainFunctionDefinition,
    SidebarOverviewRelatedPatternFunctionDefinition
} from "../../components/ScoringSidebar";
import {useDispatch, useSelector} from "react-redux";
import Overview, {setStateFilterPure} from "../../components/Overview";
import {useParams} from "react-router-dom";
import {setOption} from "../../actions/overview";
import {useTranslation} from "react-i18next";
import {MNTFilter} from "../../components/Overviews/Filters/MNTFilter";
import {MNTDomains} from "../../utils/constants";
import ToolTip from "../../components/ToolTip";
import {useAuth} from "../../utils/useAuth";
import LoaderAnimation from "../atoms/LoaderAnimation";


export default React.memo(function ScoringSidebarDisplay(props) {

    let { client_id, project_id, function_id } = useParams();
    const {t, i18n} = useTranslation();
    const identKey = { client_id, project_id, function_id}
    const [freeSearch, setFreeSearch] = useState("");
    const [displaySidebarOptions, setDisplaySidebarOptions] = useState(false);
    const domain = useSelector(state => state.ScoringState?.selectedDomain || 1);
    const state = useSelector(state => Object.values(state.ScoringState.functions?.[function_id]?.scores || {}).some(o => o.updating));
    const dispatch = useDispatch();
    const sidebarIdentifier = 'sidebar-global-state';

    const [altFilterActive, setAltFilterActive] = useState("false");

    const searchOption = useSelector(state => state.ScoringState?.searchOption);
    const [definition, setDefinition] = useState(null);

    const sidebarGlobalState = useSelector(state => state.OverviewState[JSON.stringify(sidebarIdentifier)]?.scope === 'global') || false;

    const auth = useAuth();

    const canViewPoints = auth.hasPermission('base.view_reference_function_points', parseInt(project_id), 'project');

    const types = props?.functionDetail?.type === 'reference' ? "reference" : "reference,company";
    const globalTrue = [{
        identifier: "scope",
        value: "global",
    }];
    const globalFalse = [{
        identifier: "scope",
        value: "local",
    }];

    const searchOptions = [
        {
            identifier: 'automatic',
            name: t('Automatisch'),
            definition: SidebarOverviewRelatedAutomaticFunctionDefinition,
            Overview: <Overview
                identifier={"sidebar-function-overview-automatic"}
                identKey={{...identKey, identifier: 'sidebar-function-overview-automatic'}}
                definition={SidebarOverviewRelatedAutomaticFunctionDefinition}
                forceFilter={[
                    {
                        identifier: "name",
                        value: freeSearch,
                    },
                    {
                        identifier: 'domain',
                        value: 0,
                    },
                    {
                        identifier: "type__in",
                        value: types,
                    },
                    {
                        identifier: 'searchBust',
                        value: props?.functionDetail?.modification_date || 0,

                    },
                    ...(sidebarGlobalState? globalTrue : globalFalse),

                ]}
                forceResetSize={freeSearch!==""}
                contextData={{
                    type: props.functionDetail?.type,
                    comparedPatternList: props.comparedPatternList,
                    functionDetail: props.functionDetail,
                    displayPoints: props.displayPoints,
                    comparison: true,
                    toggleHighlight: props.toggleHighlight,
                    highlightActive: props.highlightActive,
                    function_id,
                }}
            />
        },
        {
            identifier: 'domain',
            name: t('Domein'),
            definition: SidebarOverviewRelatedDomainFunctionDefinition,
            Overview: <Overview
                identifier={"sidebar-function-overview-domain"}
                identKey={{...identKey, identifier: 'sidebar-function-overview-domain'}}
                definition={SidebarOverviewRelatedDomainFunctionDefinition}
                forceFilter={[
                    {
                        identifier: "name",
                        value: freeSearch,
                    },
                    {
                        identifier: 'domain',
                        value: domain,
                    },
                    {
                        identifier: "type__in",
                        value: types,
                    },
                    {
                        identifier: 'searchBust',
                        value: props?.functionDetail?.modification_date || 0,

                    },
                    ...(sidebarGlobalState? globalTrue : globalFalse),
                ]}
                forceResetSize={freeSearch!==""}
                contextData={{
                    type: props.functionDetail?.type,
                    comparedPatternList: props.comparedPatternList,
                    functionDetail: props.functionDetail,
                    displayPoints: props.displayPoints,
                    comparison: true,
                    toggleHighlight: props.toggleHighlight,
                    highlightActive: props.highlightActive,
                    function_id,
                }}
            />
        },

        {
            identifier: 'pattern',
            name: t('Patroon'),
            definition: SidebarOverviewRelatedPatternFunctionDefinition,
            Overview: <Overview
                identifier={"sidebar-function-overview-pattern"}
                identKey={{...identKey, identifier: 'sidebar-function-overview-pattern'}}
                definition={SidebarOverviewRelatedPatternFunctionDefinition}
                forceFilter={[
                    {
                        identifier: "name",
                        value: freeSearch,
                    },
                    {
                        identifier: "type__in",
                        value: types,
                    },
                    {
                        identifier: 'searchBust',
                        value: props?.functionDetail?.modification_date || 0,

                    },
                    ...(sidebarGlobalState? globalTrue : globalFalse),
                ]}
                forceResetSize={freeSearch!==""}
                contextData={{
                    type: props.functionDetail?.type,
                    comparedPatternList: props.comparedPatternList,
                    functionDetail: props.functionDetail,
                    displayPoints: props.displayPoints,
                    comparison: true,
                    toggleHighlight: props.toggleHighlight,
                    highlightActive: props.highlightActive,
                    function_id,
                }}
            />
        },
        {
            identifier: 'custom',
            name: t('Vrij zoeken'),
            definition: SidebarOverviewDefinition,

            // Default function list, just show everything within this project :)
            Overview: <Overview
                identifier={"sidebar-function-overview-custom"}
                identKey={{...identKey, identifier: 'sidebar-function-overview-custom'}}
                definition={SidebarOverviewDefinition}
                forceFilter={[
                    {
                        identifier: "name",
                        value: freeSearch,
                    },
                    ...(sidebarGlobalState? globalTrue : globalFalse),
                ]}
                forceResetSize={freeSearch!==""}
                contextData={{
                    type: props.functionDetail?.type,
                    comparedPatternList: props.comparedPatternList,
                    functionDetail: props.functionDetail,
                    displayPoints: props.displayPoints,
                    comparison: true,
                    toggleHighlight: props.toggleHighlight,
                    highlightActive: props.highlightActive,
                    function_id,
                }}
            />
        },
    ]


    // if the search option (free/pattern) or the scope of the pattern-search (global/local) changes, re-determine
    // the definition of the overview
    useEffect(() => {
        setDefinition(searchOptions.find(o => o.identifier === props.searchOption));
    }, [searchOption, sidebarGlobalState]);

    const stateIdentifier = { // A (serializable) json object which will be used to identify the (instance) state
       identifier: `${props.identKey?.identifier}-${searchOption}`,
       ...{...identKey, identifier: `${props.identKey?.identifier}-${searchOption}`}
    };


    let overviewFilterState = useSelector(state => state.OverviewState?.[JSON.stringify(stateIdentifier)]);

    return (
        <div className={"scoring-side-bar"}>
            <div className={"scoring-side-bar__top"} data-cy={"scoring-side-bar-top"}>
                <div className={"tool-header tool-header--compact"}>
                    <div className={"tool-header__primary"}>
                        <h2 className={"tool-header__title"}>{t("Vergelijken")}</h2>
                        {(state || false) && (
                            <LoaderAnimation xsmall={true}/>
                        )}
                    </div>
                    <div className={"tool-header__secondary"}>
                        <ToolTip tooltip={t("Vergelijkbare functies filteren")}>
                            <IconButton icon={"filter-sliders"}

                                        active={altFilterActive}
                                        sizeModifier={"medium"}
                                        onClick={ () => setDisplaySidebarOptions(!displaySidebarOptions)}
                            />
                        </ToolTip>
                    </div>
                </div>

                <SidebarSummary
                    {...props}
                />

                <div className={"side-bar-main"}>
                    <div className={"side-bar-search"}>
                        <input type={"search"}
                               onChange={(e) => {
                                   let columnIdentifier = "name"
                                   let value = e.target.value

                                   setStateFilterPure(columnIdentifier, value, stateIdentifier, dispatch)
                               }}
                               placeholder={t("Zoeken")}
                               className={"side-bar-search__input"}
                        />
                        <Icon icon={"search"}
                              sizeModifier={"small"}
                              className={"side-bar-search__icon"}
                        />
                    </div>

                    <div className={"side-bar-search"}>
                        <MNTFilter domains={MNTDomains}
                                   patternFilterNoMargin={true}
                                   advancedFilterClass={true}
                                   contextData={{
                                       noFunctionGroup: false,
                                       canViewPoints: canViewPoints,
                                   }}
                                   setFilter={(columnIdentifier, value) => setStateFilterPure(columnIdentifier, value, stateIdentifier, dispatch)}
                                   state={overviewFilterState?.filter}
                        />
                    </div>

                    <div className={"search-toggle"}>
                        <div onClick={() => {
                                dispatch(setOption(sidebarIdentifier, {
                                    scope: "local"
                                }));
                            }}
                            className={cx("search-toggle__item", { "search-toggle__item--active": !sidebarGlobalState })}>{t("Vergelijk binnen project")}
                        </div>
                        <div onClick={() => {
                                dispatch(setOption(sidebarIdentifier, {
                                    scope: "global"
                                }));
                            }}
                            className={cx("search-toggle__item", { "search-toggle__item--active": sidebarGlobalState })}>{t("Vergelijk globaal")}
                        </div>
                    </div>
                </div>

            </div>

            <div className={"scoring-side-bar__bottom"}>
                {displaySidebarOptions && (
                    <div className={"scoring-side-bar__search-options"}>
                        <ul className={"side-bar-filter"}>
                            {searchOptions.filter(option => props?.functionDetail?.type === 'company' ? (option.identifier !== "domain" && option.identifier !== 'automatic') : true).map((item, idx) => (
                                <li className={cx("side-bar-filter__tab", {"side-bar-filter__tab--active": item.identifier === props.searchOption})}
                                    key={idx}
                                    onClick={() => {
                                        if(item.identifier !== 'pattern') {
                                            setAltFilterActive("true");
                                        } else {
                                            setAltFilterActive("false");
                                        }
                                        props.setSearchOption(item.identifier);
                                        setDisplaySidebarOptions(false);
                                    }}
                                >
                                    <h3 className={"side-bar-filter__title"}>{item.name}</h3>
                                    <p className={"side-bar-filter__description"}>{item?.description}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {!displaySidebarOptions && (
                    <div className={"scoring-side-bar__search-results"}>
                        {definition && definition.Overview}
                    </div>
                )}
            </div>
        </div>
    )
});
