import { Cookies } from 'react-cookie';

const cookies = new Cookies();

import {
    SET_CACHE,
    SET_TOKEN,
    SET_NEXT,
    SET_OPTION,
    SET_LOGIN,
} from "../actions/auth";

export const AuthDefaultState = {
    user: {
        first_name: "",
        last_name: "",
        initials: "??",
        email: "",
        authorized: null,
    },
    insightUser: true,
    next: '/home',
    cache: {},
    token: cookies.get('token') || null,
}

export function AuthState(state=AuthDefaultState, action) {
    let ret = {};
    switch(action.type) {
        case SET_LOGIN:
            ret = {
                ...state,
                user: action.user,
            }
            return ret;
        case SET_TOKEN:
            ret = {
                ...state,
                token: action.token,
            }
            return ret;
        case SET_NEXT:
            ret = {
                ...state,
                next: action.next,
            }
            return ret;
        case SET_OPTION:
            ret = {
                ...state,
                ...action.option,
            }
            return ret;

        case SET_CACHE:
            ret = {
                ...state,
                cache: {
                    ...state.cache,
                    [action.key]: action.value,
                }
            }
            return ret;
        default:
            return state;
    }
}
