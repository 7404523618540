import {
    SET_DOMAIN,
    SET_FUNCTION_ID,
    SET_FUNCTION_SCORES,
    SET_HIGHLIGHT,
    SET_SCORE, SET_SCORE_LOADING,
    SET_SEARCH_OPTION,
    SET_SUBSET
} from "../actions/scoring";
import {ScoringDefaultState} from "../components/Scoring";

export function ScoringState(state=ScoringDefaultState, action) {

    let ret = {
        ...state,
    }

    switch(action.type) {

        case SET_FUNCTION_ID:
            ret.function_id = action.function_id;
            return ret;

        case SET_DOMAIN:
            ret.selectedDomain = action.identifier;
            return ret;

        case SET_FUNCTION_SCORES:
            ret.functions = {
                ...ret.functions,
                [action.function_id]: {
                    ...ret.functions[action.function_id],
                    scores: action.scores,
                }
            }
            return ret;


        case SET_SCORE:
            // TODO: Check if this reducer is actually still used, logic seems flawed due to action.structure_id is NOT the correct key
            ret.functions = {
                ...ret.functions,
                [action.function_id]: {
                    ...ret.functions[action.function_id],
                    scores: {
                        ...ret.functions[action.function_id]?.scores,
                        [action.structure_id]: {
                            ...ret.functions[action.function_id]?.scores[action.structure_id],
                            structure_id: action.structure_id,
                            score_id: action.score_id,
                            status: 'valid'
                        }
                    },
                    subSet: {
                        ...ret.functions[action.function_id]?.subSet,
                    },
                },
            }
            return ret;

        case SET_SCORE_LOADING:

            const obj = ret.functions[action.function_id]?.scores || {};
            const scoreKey = Object.keys(obj).find(key => obj[key].structure_id === action.structure_id) || Object.keys(obj).length;

            if(scoreKey) {
                ret.functions = {
                    ...ret.functions,
                    [action.function_id]: {
                        ...ret.functions[action.function_id],
                        scores: {
                            ...ret.functions[action.function_id]?.scores,
                            [scoreKey]: {
                                ...ret.functions[action.function_id]?.scores[scoreKey],
                                structure_id: action.structure_id,
                                score_id: false,
                                valid: false,
                                updating: true,
                            }
                        }
                    }
                }
            }
            return ret;

        case SET_SUBSET:
            let state = false;
            let explicit = false;

            const current = ret.functions?.[action.function_id]?.subSet?.[action.identifier] || {
                state: false,
                explicit: false
            };
            if(action.explicit || (!action.explicit  && !current?.explicit))  {
                state = action.state;
            } else {
                state = current.state;
            }
            if(action.explicit === false && current.explicit === false) {
                explicit = false;
            }
            if(action.explicit === true || current.explicit === true) {
                explicit = true;
            }

            ret.functions = {
                ...ret.functions,
                [action.function_id]: {
                    ...ret.functions[action.function_id],
                    scores: {
                        ...ret.functions[action.function_id]?.scores,
                    },
                    subSet: {
                        ...ret.functions[action.function_id]?.subSet,
                        [action.identifier]: {
                            ...ret.functions[action.function_id]?.subSet?.[action.identifier],
                            state: state,
                            explicit: explicit,
                        },
                    },
                }
            }
            if(action.complete !== null) {
                ret.functions[action.function_id].subSet[action.identifier].complete = action.complete;
            }
            return ret;

        case SET_HIGHLIGHT:
            let highlights = [ ...ret.highlights ];
            let idx = highlights.findIndex(item => item.id === action.function_id)
            let content = {
                id: action.function_id,
                scores: action.scores
            };
            if(idx >= 0) {
                if(action.scores.length === 0) {
                    highlights.splice(idx, 1);
                } else {
                    highlights[idx] = content;
                }
            } else {
                highlights.push(content);
            }
            ret.highlights = highlights;
            return ret;

        case SET_SEARCH_OPTION:
            return {
                ...ret,
                searchOption: action.searchOption
            };

        default:
            return ret;
    }
}

