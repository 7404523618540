export const SET_LOGIN = 'SET_LOGIN';
export const SET_NEXT  = 'SET_NEXT';
export const SET_OPTION = 'SET_AUTH_OPTION';
export const SET_CACHE = 'SET_CACHE';
export const SET_TOKEN = 'SET_TOKEN';

export function setLogin(user) {
    return {
        type: SET_LOGIN,
        user,
    }
}

export function setToken(token) {
    return {
        type: SET_TOKEN,
        token
    }
}

export function setNext(next) {
    return {
        type: SET_NEXT,
        next
    }
}

export function setOption(option) {
    return {
        type: SET_OPTION,
        option,
    }
}

export function setCache(key, value) {
    return {
        type: SET_CACHE,
        key,
        value,
    }
}
