import React from "react";

import {TextFilter} from "./Filters/TextFilter";
import {getUsers} from "../../queries/users";
import i18n from "../../utils/i18n";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import cx from "classnames";
import {useTranslation} from "react-i18next";

const StandardRowStructure = React.memo(tempProps => {
    let props = Object.assign(
        {
            id: false,
            first_name: "",
            insertion: "",
            last_name: "",
            username: "",
            email: "",
            client: {name: ""}
        },
        tempProps,
    );

    const detailClick = props?.onClick;
    const {t} = useTranslation();

    return (
        <tr key={props.id}
            className={"overview-table__row"}
        >
            <td className="overview-table__cell">
                    <span className={cx("meta-info__status-tag", {
                        "status-tag status-tag--active": props?.is_active,
                        "status-tag status-tag--negative": !props?.is_active
                    })}>{props?.is_active ? t("Actief") : t("Inactief")}</span>
            </td>
            <td className={"overview-table__cell overview-table__cell--name overview-table__cell--interactive"}
                onClick={() => {
                    detailClick(props);
                }}>
                <div className={"overview-table__text"} title={props.first_name}>
                    {props.first_name}
                </div>
            </td>
            <td className="overview-table__cell">
                <div className={"overview-table__text"} title={props.insertion}>
                    {props.insertion}
                </div>
            </td>
            <td className="overview-table__cell">
                <div className={"overview-table__text"} title={props.last_name}>
                    {props.last_name}
                </div>
            </td>
            <td className="overview-table__cell">
                <div className={"overview-table__text"} title={props?.username}>
                    {props.username}
                </div>
            </td>
            <td className="overview-table__cell">
                <div className={"overview-table__text"} title={props.email}>
                    {props.email}
                </div>
            </td>
            <td className={"overview-table__cell"}>
                <div className={"overview-table__text"} title={props?.client?.name}>
                    {props?.client?.name}
                </div>
            </td>
        </tr>
    )
});

export const UserSelectionDefinition = {
    queryFn: getUsers,
    RowStructure: StandardRowStructure,
    paginationSize: 5,
    noHeader: true,
    noSearch: true,
    noContainer: true,
    defaultOrderIdentifier: 'last_name',
    columns: [
        {
            title: i18n.t("Actief"),
            identifier: 'is_active',
            className: 'overview-table__filter-cell--width-large',
            type: 'dropdown',
            orderable: false,
            Filter: DropdownFilter,
            options: [
                {
                    title: i18n.t('Actief'),
                    identifier: 'true'
                },
                {
                    title: i18n.t('Inactief'),
                    identifier: 'false'
                }
            ]
        },
        {
            title: "Voornaam",
            identifier: 'first_name',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: "Naam",
            Filter: TextFilter,
        },
        {
            title: "Tussenvoegsel",
            identifier: 'insertion',
            type: "text-filter",
            orderable: false,
            className: 'overview-table__filter-cell--width-large',
            placeholder: "tussenvoegsel",
            Filter: TextFilter,
        },
        {
            title: "Achternaam",
            identifier: 'last_name',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: "Naam",
            Filter: TextFilter,
        },
        {
            title: "Oude gebruikersnaam",
            identifier: 'username',
            type: "text-filter",
            orderable: true,
            placeholder: "gebruikersnaam",
            Filter: TextFilter,
        },
        {
            title: "Email",
            identifier: 'email',
            type: "text-filter",
            orderable: true,
            placeholder: "voorbeeld@domeinnaam.nl",
            Filter: TextFilter,
        },
        {
            title: "Klant",
            identifier: 'client__name',
            type: "text-filter",
            orderable: true,
            placeholder: "Naam",
            className: 'overview-table__filter-cell--width-xlarge',
            Filter: TextFilter,
        },
    ],
}

