import React from "react";
import {useNavigate, useParams} from "react-router-dom";

import ClientMetaDisplay from "../patterns/organisms/ClientMeta";
import {getClient} from "../queries/client_detail";
import {useTranslation} from "react-i18next";
import {clientTabsProps} from "../utils/constants";
import TabNavigation from "./TabNavigation";

export default function ClientMeta() {
    const { client_id, user_group_id } = useParams();
    const client = getClient(client_id)
    const tabs = clientTabsProps(client_id)

    let clientDetail = client?.data;

    return (
        <>
            <ClientMetaDisplay
                clientDetail={clientDetail}
            />

            {!user_group_id &&
                <div className={"content-container content-container--tabs"}>
                    <TabNavigation tabs={tabs}/>
                </div>
            }
        </>
    )
}
