import React from "react";
import cx from "classnames";
import Icon from "../../../patterns/atoms/Icon";
import {useTranslation} from "react-i18next";

export function Title(props) {

    const {t, i18n} = useTranslation();

    const ordering = props?.ordering || {
        columnIdentifier: "---",
        direction: "",
    }

    return (
        <div className={cx("overview-table__column-title", props.className, {
            "overview-table__column-title--active": props.identifier === ordering.columnIdentifier,
            "overview-table__column-title--orderable": props.orderable,
        })}
             key={props.title}  onClick={() => {
            if(props.orderable) {
                props.order(props.identifier);
            }
        }}
        data-cy={`overview-table-column-title-${props.identifier}`}>
            {t(props.title)}

            <span>
                { props.identifier === ordering.columnIdentifier && (
                    props.ordering.direction !== '-' ? (<Icon icon={"sort-ascending"} sizeModifier={"tiny"} className={"overview-table__sort-icon"}/>) : (<Icon icon={"sort-descending"} sizeModifier={"tiny"} className={"overview-table__sort-icon"}/>)
                )}
            </span>

        </div>
    )
}
