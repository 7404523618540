import Overview from "../Overview";
import React, {useState} from "react";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import {FunctionOverviewDefinition} from "../Overviews/FunctionOverview";
import {useAuth} from "../../utils/useAuth";
import {withAccessRight} from "../../utils/withAccessHoC";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import FunctionAdvancedFilter from "../FunctionAdvancedFilter";
import FilterLabel from "../FilterLabel";

export function FunctionOverviewScreen(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();

    const hasPermission = auth.hasPermission('base.add_function');
    const canViewPoints = auth.hasPermission('base.view_reference_function_points');

    const contextData = {
        canViewPoints,
    }

    return (
        <div className={"content-container"}>

            <Quickview>
                <Overview
                    identifier={"global-function-overview"}
                    quickview={{
                        identifier: "function",
                        name: t("Alle functies")
                    }}
                    contextData={contextData}
                    identKey={{
                    }}
                    definition={{
                        ...FunctionOverviewDefinition,
                        columns: FunctionOverviewDefinition.columns.filter(o => !(['mnt', 'vbalans'].includes(o.identifier))),
                        // Have to add this empty primary title, so it still shows the advanced filter without a primary button.
                        primary: {
                            title: " "
                        },
                        noSearch: true,
                        advancedFilter: {
                            title: "Filteren",
                            identifier: 'advanced-filter',
                            action: {
                                Component: FunctionAdvancedFilter,
                                advancedFilters: [
                                    "creation_date_after",
                                    "creation_date_before",
                                    "modification_date_after",
                                    "modification_date_before",
                                    "code",
                                    "protected",
                                    "visible",
                                    "favorite",
                                    "user__in",
                                    "describer",
                                    "applier_one",
                                    "applier_two"
                                ],
                                filterLabels: (props) => filterLabels(props)
                        }
                    }}}
                    initialFilters={{
                        description_status__in: ['concept', 'to-counter', 'is-countered','determined'],
                        scoring_status__in: ['concept', 'to-counter', 'is-countered','determined'],
                    }}
                />

                <Overview
                    identifier={"global-function-overview-bkn"}
                    quickview={{
                        identifier: "function-bkn",
                        name: t("MNT-Patroon")
                    }}
                    contextData={{...contextData, method_id: 29}}
                    identKey={{
                        method_group: "bkn"
                    }}
                    definition={{
                        ...FunctionOverviewDefinition,
                        columns: FunctionOverviewDefinition.columns.filter(o => !(['vbalans'/*, 'method__name'*/].includes(o.identifier)) ),
                        // Have to add this empty primary title, so it still shows the advanced filter without a primary button.
                        primary: {
                            title: " "
                        },
                        noSearch: true,
                        advancedFilter: {
                            title: "Filteren",
                            identifier: 'advanced-filter',
                            action: {
                                Component: FunctionAdvancedFilter,
                                advancedFilters: [
                                    "domain1__gte",
                                    "domain2__gte",
                                    "domain3__gte",
                                    "domain4__gte",
                                    "domain5__gte",
                                    "domain6__gte",
                                    "domain7__gte",
                                    "domain8__gte",
                                    "domain9__gte",
                                    "domain1__lte",
                                    "domain2__lte",
                                    "domain3__lte",
                                    "domain4__lte",
                                    "domain5__lte",
                                    "domain6__lte",
                                    "domain7__lte",
                                    "domain8__lte",
                                    "domain9__lte",
                                    "function_group__gte",
                                    "function_group__lte",
                                    "points__gte",
                                    "points__lte",
                                    "creation_date_after",
                                    "creation_date_before",
                                    "modification_date_after",
                                    "modification_date_before",
                                    "code",
                                    "protected",
                                    "visible",
                                    "favorite",
                                    "user__in",
                                    "describer",
                                    "applier_one",
                                    "applier_two"
                                ],
                                filterLabels: (props) => filterLabels(props)
                            },
                        }
                    }}
                    initialFilters={{
                        description_status__in: ['concept', 'to-counter', 'is-countered','determined'],
                        scoring_status__in: ['concept', 'to-counter', 'is-countered','determined'],
                    }}
                />

                <Overview
                    identifier={"global-function-overview-vbalans"}
                    quickview={{
                        identifier: "function-vbalans",
                        name: t("FUWASYS-patroon")
                    }}
                    contextData={{...contextData, method_id: 39}}
                    identKey={{
                        method_group: "vbalans"
                    }}
                    definition={{
                        ...FunctionOverviewDefinition,
                        columns: FunctionOverviewDefinition.columns.filter(o => !(['mnt'/*, 'method__name'*/].includes(o.identifier)) && o.identifier !== "type__in"),
                        // Have to add this empty primary title, so it still shows the advanced filter without a primary button.
                        primary: {
                            title: " "
                        },
                        noSearch: true,
                        advancedFilter: {
                            title: "Filteren",
                            identifier: 'advanced-filter',
                            action: {
                                Component: FunctionAdvancedFilter,
                                advancedFilters: [
                                    "domain1__gte",
                                    "domain2__gte",
                                    "domain3__gte",
                                    "domain4__gte",
                                    "domain5__gte",
                                    "domain6__gte",
                                    "domain7__gte",
                                    "domain8__gte",
                                    "domain9__gte",
                                    "domain10__gte",
                                    "domain11__gte",
                                    "domain12__gte",
                                    "domain13__gte",
                                    "domain14__gte",
                                    "domain1__lte",
                                    "domain2__lte",
                                    "domain3__lte",
                                    "domain4__lte",
                                    "domain5__lte",
                                    "domain6__lte",
                                    "domain7__lte",
                                    "domain8__lte",
                                    "domain9__lte",
                                    "domain10__lte",
                                    "domain11__lte",
                                    "domain12__lte",
                                    "domain13__lte",
                                    "domain14__lte",
                                    "function_group__gte",
                                    "function_group__lte",
                                    "points__gte",
                                    "points__lte",
                                    "creation_date_after",
                                    "creation_date_before",
                                    "modification_date_after",
                                    "modification_date_before",
                                    "code",
                                    "protected",
                                    "visible",
                                    "favorite",
                                    "user__in",
                                    "describer",
                                    "applier_one",
                                    "applier_two"
                                ],
                                filterLabels: (props) => filterLabels(props)
                            }
                        }
                    }}
                    initialFilters={{
                        description_status__in: ['concept', 'to-counter', 'is-countered','determined'],
                        scoring_status__in: ['concept', 'to-counter', 'is-countered','determined'],
                    }}
                />

            </Quickview>
        </div>
    )
}

function filterLabels(props) {
       let filterLabels = [];

        if (Object.keys(props.filterState).some((e => e.includes('domain'))) && Object.keys(props.filterState).some((e => e.includes('gte')))) {
            filterLabels.push(<FilterLabel domainGte={true} key={"domain_gte"}
                                           filters={props.advancedFilter.action.advancedFilters}
                                           setFilter={props.setFilter}
                                           filterState={props.filterState}/>);
        }

        if (Object.keys(props.filterState).some((e => e.includes('domain'))) && Object.keys(props.filterState).some((e => e.includes('lte')))) {
            filterLabels.push(<FilterLabel domainLte={true} key={"domain_lte"}
                                           filters={props.advancedFilter.action.advancedFilters}
                                           setFilter={props.setFilter}
                                           filterState={props.filterState}/>)
        }

        Object.keys(props.filterState).map((filter) => {
            if (props.advancedFilter.action.advancedFilters.includes(filter)) {
                if (filter === "user__in") {
                    if (props.filterState[filter].length > 1) {
                        props.filterState[filter].map((userId) => filterLabels.push(<FilterLabel
                            userId={userId} key={filter}
                            filter={filter}
                            filterState={props.filterState}
                            setFilter={props.setFilter}/>))
                    } else {
                        filterLabels.push(<FilterLabel oneUser={true} key={filter}
                                                       filter={filter}
                                                       filterState={props.filterState}
                                                       setFilter={props.setFilter}/>)
                    }
                }
                if (!filter.includes("domain") && filter !== "user__in") {
                    filterLabels.push(<FilterLabel key={filter} filter={filter}
                                                   filterState={props.filterState}
                                                   setFilter={props.setFilter}/>)
                }
            }
        })

        return filterLabels
    }

export default withAccessRight(
    FunctionOverviewScreen,
    "base.view_function_invisible",
    []
)

