import React, {useState} from 'react';
import Overview from "../Overview";
import {TextFilter} from "./Filters/TextFilter";
import {deleteEnvironment, getEnvironmentOverview} from "../../queries/environments";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {IconButton} from "../../patterns/molecules/IconButton";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import {useNavigate} from "react-router-dom";
import StatusComponent from "../StatusComponent";
import {DateFormat} from "../../utils/DateFormat";
import {EnvironmentStatusFilter} from "./Filters/EnvironmentStatusFilter";

export default function EnvironmentOverview() {
    return (
        <Overview
            identifier={"environment-overview"}
            definition={EnvironmentOverviewDefinition}
        />
    )
}

export const EnvironmentOverviewDefinition = {
    title: "Demo-omgevingen",
    identifier: 'environment-overview',
    queryFn: getEnvironmentOverview,
    loadAllAllowed: true,
    noSearch: true,
    defaultOrderIdentifier: 'status',
    primary: {
        title: "Aanmaken",
        identifier: "create-environment",
        link: '/environment/create/',
        "data-cy": "create-environment-button"
    },
    RowStructure: (props) => {
        const [deleteConfirm, setDeleteConfirm] = useState();
        const navigate = useNavigate();

        const startDate = props.start_date ? DateFormat(props.start_date) : "Niet gespecificeerd";
        const endDate = props.end_date ? DateFormat(props.end_date) : "Niet gespecificeerd";

        function RemoveModal(props) {
            return (
                <ConfirmModal
                    key={'remove-environment'}
                    title={("Verwijder demo-omgeving")}
                    body={("Weet je zeker dat je de onderstaande demo-omgeving wilt verwijderen?")}
                    subject={props.domain}
                    confirmModal={() => {
                        deleteEnvironment(props.id).then(result => {
                            if (result.status === 204) {
                                return;
                            }
                        }).catch(function (error) {
                            console.log(error);
                            return "Something went wrong...";
                        })
                        props.refresh();
                    }}
                    closeModal={() => {
                        setDeleteConfirm(false)
                        props.refresh()
                    }}
                />
            )
        }

        return (
            <tr className={"overview-table__row"}>
                {props.status === "closed" ?
                    <td className="overview-table__cell">
                        {props.name}
                    </td> :
                    <td className="overview-table__cell overview-table__cell--name overview-table__cell--interactive" onClick={() => {navigate(`/environment/${props.id}/edit`)}}>
                        {props.name}
                    </td>
                }
                <td className="overview-table__cell">
                    {props.domain_full}
                </td>
                <td className="overview-table__cell">
                    {startDate}
                </td>
                <td className="overview-table__cell">
                    {endDate}
                </td>
                <td className="overview-table__cell">
                    <StatusComponent status={props.status}/>
                </td>
                <td className={"overview-table__cell overview-table__cell--buttons"}>
                    <IconButton title={"Wijzig Demo-omgeving"} disabled={props.status === "closed" ? true : false}
                                data-cy={"environment-edit-button"}
                                icon={"edit"} sizeModifier={"medium"} onClick={() => {
                        navigate(`/environment/${props.id}/edit`)
                    }}/>
                    <IconButton title={"Verwijder Demo-omgeving"} icon={"trash"} sizeModifier={"medium"}
                                onClick={() => setDeleteConfirm(true)}/>
                </td>
                {deleteConfirm &&
                    <RemoveModal id={props.id} domain={props.domain} refresh={props.refresh}/>
                }
            </tr>
        )
    },
    columns: [
        {
            title: 'Naam',
            identifier: 'name',
            orderable: true,
            className: 'overview-table__filter-cell--width-xlarge',
            placeholder: 'Naam',
            Filter: TextFilter
        },
        {
            title: 'Domeinnaam',
            identifier: 'domain',
            orderable: true,
            placeholder: 'Domein',
            Filter: TextFilter
        },
        {
            title: 'Start',
            identifier: 'start_date',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: 'Start',
            Filter: EmptyFilter
        },
        {
            title: 'Eind',
            identifier: 'end_date',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: 'Eind',
            Filter: EmptyFilter
        },
        {
            title: 'Status',
            identifier: 'status',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: 'Status',
            Filter: EnvironmentStatusFilter,
            options: [
                {
                    title: 'Actief' ,
                    identifier: 'active'
                },
                {
                    title: 'Nog niet actief',
                    identifier: 'inactive',
                },
                {
                    title: 'Verlopen',
                    identifier: 'closed'
                },
                {
                    title: 'Fout',
                    identifier: 'error'
                },
                {
                    title: 'Opstarten',
                    identifier: 'starting'
                }
            ],
        },
        {
            identifier: 'action',
            className: 'overview-table__filter-cell--width-button-duo',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ]
}

