import Overview from "../Overview";
import React, {useRef, useState} from "react";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../utils/useAuth";
import {useParams} from "react-router-dom";
import {UserGroupOverviewDefinition} from "../Overviews/UserGroupOverview";
import MultiFieldModal from "../../patterns/organisms/MultiFieldModal";
import {remoteRemoveUserGroup, remoteRemoveUsersFromUserGroup, remoteSetUserGroup} from "../../queries/usergroups";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";


export default function UserGroupOverviewScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();

    const { client_id } = useParams();

    const [removeGroup, setRemoveGroup] = useState(false);

    const hasAddRight = auth.checkAccessList('base.change_usergroup',
        [
            [client_id, 'client'],
        ]
    )
    const hasRemoveRight = auth.checkAccessList('base.delete_usergroup',
        [
            [client_id, 'client'],
        ]
    )

    // create a new ref which can be passed to Overview and which will contain a reference to the overview's mutate
    const overviewRef = useRef(false)

    const hasCreateUserGroupPrimary = ( hasAddRight ? {
        title: t("Gebruikersgroep aanmaken"),
        identifier: "usergroup-create-action",
        action: {
            Component: MultiFieldModal,
            remoteCall: async o => {
                await remoteSetUserGroup(client_id, o.name, o.description);
                overviewRef.current.mutate();
            },
            fields:[
                {
                    name: "name",
                    label: t("Gebruikersgroep"),
                    placeholder: t("Naam van de groep")
                },
                {
                    name: "description",
                    label: t("Beschrijving"),
                    placeholder: t("toelichting")
                }
            ]
        }
    } : {});


    return (
        <>
            {removeGroup && (
                <ConfirmModal
                    key={'remove-project-from-projectgroup'}
                    title={t("Verwijder gebruiker uit gebruikersgroep")}
                    body={t("Weet je zeker dat je de volgende gebruiker wilt verwijderen uit de gebruikersgroep") + " \"" + removeGroup.name  + "\""}
                    subject={`${removeGroup.name}` }
                    confirmModal={async () => {
                        await remoteRemoveUserGroup(removeGroup.id);
                        overviewRef.current.mutate();

                    }}
                    closeModal={() => setRemoveGroup(false)} />
            )}

            <div className={"content-container"}>

                <Quickview>

                    <Overview
                        ref={overviewRef}
                        identifier={"client-usergroups"}
                        quickview={{
                            identifier: "client-usergroups",
                            name: t("Gebruikersgroepen")
                        }}
                        identKey={{client_id }}
                        contextData={{
                            location,
                            client_id,
                            hasAddRight,
                            hasRemoveRight,
                            remove: (props) => setRemoveGroup(props)
                        }}
                        definition={{
                            ...UserGroupOverviewDefinition,
                            primary: hasCreateUserGroupPrimary,
                            columns: UserGroupOverviewDefinition.columns
                                .filter(o => o.identifier !== 'action' || hasRemoveRight)
                                .filter(o => o.identifier !== 'client__name')
                                .filter(o => o.identifier !== 'users' || hasRemoveRight),
                        }}
                    />
                </Quickview>
            </div>
        </>
    )
}
