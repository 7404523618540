import Modal from "react-modal";
import IconButton from "../molecules/IconButton";
import Icon from "../atoms/Icon";
import React, {useEffect, useState} from "react";
import cx from "classnames";
import ButtonPrimary from "../atoms/ButtonPrimary";
import ButtonSecondary from "../atoms/ButtonSecondary";
import ConfirmModal from "./ConfirmModal";
import {StatusTag} from "./StatusTag";
import {useTranslation} from "react-i18next";
import Notification from "../molecules/Notification";
import {useDispatch, useSelector} from "react-redux";
import {
    setFGSGroupSettingsAll,
    setGroupState,
    setGroupStateMultiple
} from "../../actions/function_group_structure";
import {FunctionSource} from "../../utils/constants";
import ButtonQuaternary from "../atoms/ButtonQuaternary";

function GroupDefinition(props) {
    const {t, i18n} = useTranslation();
    // Fetch the state for this instance
    const open = useSelector(state => state.FunctionGroupStructureState?.projects?.[props.projectId].groupState?.[props.fgsGroup.id]) || false;
    const dispatch = useDispatch();
    const previousGroup = props.currentFgs.groups.find(group => group.id === props.fgsGroup.id)

    return (
        <>
            <tr key={props.fgsGroup.id} className={"fgs-groups__row"}>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell align-right"}>
                        {props.fgsGroup.number}
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell"}>
                        <input type={"text"} className={"input--small input--number"}
                               placeholder={""}
                               onChange={e => props.setFGSGroupSetting('label', e.target.value)}
                               value={props.fgsGroup.label}
                        />
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell fgs-groups__cell--no-border"}>
                        <div className={"fgs-groups__input-wrapper"}>
                            <input type={"number"} step=".01"
                                   className={cx("fgs-groups__input fgs-groups__input--large input--small input--number", {"input--placeholder": parseFloat(props.newFgs.default_factor) === parseFloat(props.fgsGroup.factor)})}
                                   onChange={e => props.setFGSGroupSetting('factor', e.target.value || props.newFgs.default_factor)}
                                   value={props.fgsGroup.factor}/>
                            {props.fgsGroup?.factor - previousGroup?.factor < 0 && (
                                <span
                                    className={"fgs-groups__change fgs-groups__change--minus fgs-groups__change--large"}>
                                    <Icon icon={"sort-descending"} sizeModifier={"small"}
                                          className={"fgs-groups__change-icon"}/>
                                    {Math.abs((props.fgsGroup.factor - previousGroup.factor).toFixed(3))}
                                </span>
                            )}
                            {props.fgsGroup?.factor - previousGroup?.factor === 0 && (
                                <span className={"fgs-groups__change fgs-groups__change--large"}>
                                    {props.fgsGroup.factor - previousGroup.factor}
                                </span>
                            )}
                            {props.fgsGroup?.factor - previousGroup?.factor > 0 && (
                                <span
                                    className={"fgs-groups__change fgs-groups__change--plus fgs-groups__change--large"}>
                                    <Icon icon={"sort-ascending"} sizeModifier={"small"}
                                          className={"fgs-groups__change-icon"}/>
                                    {(props.fgsGroup.factor - previousGroup.factor).toFixed(3)}
                                </span>
                            )}
                        </div>
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell"}>
                        {props.fgsGroup.preview_factor ?
                            <span
                                className={cx("fgs-groups__resolve fgs-groups__resolve--large", {"fgs-groups__resolve--opened": props.fgsGroup.preview_factor !== props.fgsGroup.factor})}>{props.fgsGroup.preview_factor}</span> :
                            <span className="fgs-groups__resolve fgs-groups__resolve--large">TODO</span>
                        }
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell fgs-groups__cell--no-border"}>
                        <div className={"fgs-groups__input-wrapper"}>
                            <input type={"number"}
                                   className={"fgs-groups__input input--small input--number"}
                                   placeholder={""}
                                   onChange={e => props.setFGSGroupSetting('points_min', e.target.value)}
                                   value={props.fgsGroup.points_min}/>
                            {props.fgsGroup.error === 'point_offset_error' &&
                                <div className={"fgs-groups__cell-error-icon"}></div>
                            }
                            {props.fgsGroup?.points_min - previousGroup?.points_min < 0 && (
                                <span className={"fgs-groups__change fgs-groups__change--minus"}>
                                    <Icon icon={"sort-descending"} sizeModifier={"small"}
                                          className={"fgs-groups__change-icon"}/>
                                    {Math.abs(props.fgsGroup.points_min - previousGroup.points_min)}
                                </span>
                            )}
                            {props.fgsGroup?.points_min - previousGroup?.points_min === 0 && (
                                <span className={"fgs-groups__change"}>
                                    {props.fgsGroup.points_min - previousGroup.points_min}
                                </span>
                            )}
                            {props.fgsGroup?.points_min - previousGroup?.points_min > 0 && (
                                <span className={"fgs-groups__change fgs-groups__change--plus"}>
                                    <Icon icon={"sort-ascending"} sizeModifier={"small"}
                                          className={"fgs-groups__change-icon"}/>
                                    {props.fgsGroup.points_min - previousGroup.points_min}
                                </span>
                            )}
                        </div>
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell"}>
                        {props.fgsGroup.preview_points_min ?
                            <span
                                className={cx("fgs-groups__resolve", {"fgs-groups__resolve--opened": props.fgsGroup.preview_points_min !== props.fgsGroup.points_min})}>{props.fgsGroup.preview_points_min}</span> :
                            <span className="fgs-groups__resolve">TODO</span>}
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell fgs-groups__cell--no-border"}>
                        <div className={"fgs-groups__input-wrapper"}>
                            <input type={"number"} step="1" className={"fgs-groups__input input--small input--number"}
                                   placeholder={""}
                                   onChange={e => props.setFGSGroupSetting('points_max', e.target.value)}
                                   value={props.fgsGroup.points_max}/>
                            {props.fgsGroup?.points_max - previousGroup?.points_max < 0 && (
                                <span className={"fgs-groups__change fgs-groups__change--minus"}>
                                    <Icon icon={"sort-descending"} sizeModifier={"small"}
                                          className={"fgs-groups__change-icon"}/>
                                    {Math.abs(props.fgsGroup.points_max - previousGroup.points_max)}
                                </span>
                            )}
                            {props.fgsGroup?.points_max - previousGroup?.points_max === 0 && (
                                <span className={"fgs-groups__change"}>
                                    {props.fgsGroup.points_max - previousGroup.points_max}
                                </span>
                            )}
                            {props.fgsGroup?.points_max - previousGroup?.points_max > 0 && (
                                <span className={"fgs-groups__change fgs-groups__change--plus"}>
                                    <Icon icon={"sort-ascending"} sizeModifier={"small"}
                                          className={"fgs-groups__change-icon"}/>
                                    {props.fgsGroup.points_max - previousGroup.points_max}
                                </span>
                            )}
                        </div>
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell"}>
                        {props.fgsGroup.preview_points_max ?
                            <span
                                className={cx("fgs-groups__resolve", {"fgs-groups__resolve--opened": props.fgsGroup.preview_points_max !== props.fgsGroup.points_max})}>{props.fgsGroup.preview_points_max}</span> :
                            <span className="fgs-groups__resolve">TODO</span>}
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper"}>
                    <div className={"fgs-groups__cell fgs-groups__cell--amount"}>
                        <div className={"fgs-groups__input-wrapper"}>
                            <div className={"fgs-groups__amount"}>
                                {props.fgsGroup.functions.length}
                                {props.fgsGroup.functions.length - props.fgsGroup.prevCount < 0 && (

                                    <span
                                        className={"fgs-groups__change fgs-groups__change--standalone fgs-groups__change--minus"}>
                                        <Icon icon={"sort-descending"} sizeModifier={"small"}
                                              className={"fgs-groups__change-icon"}/>
                                        {Math.abs(props.fgsGroup.functions.length - props.fgsGroup.prevCount)}
                                    </span>
                                )}
                                {props.fgsGroup.functions.length - props.fgsGroup.prevCount === 0 && (
                                    <span className={"fgs-groups__change fgs-groups__change--standalone"}>
                                        {props.fgsGroup.functions.length - props.fgsGroup.prevCount}
                                    </span>
                                )}
                                {props.fgsGroup.functions.length - props.fgsGroup.prevCount > 0 && (

                                    <span
                                        className={"fgs-groups__change fgs-groups__change--standalone fgs-groups__change--plus"}>
                                        <Icon icon={"sort-ascending"} sizeModifier={"small"}
                                              className={"fgs-groups__change-icon"}/>
                                        {props.fgsGroup.functions.length - props.fgsGroup.prevCount}
                                    </span>

                                )}
                            </div>
                        </div>
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper fgs-groups__cell-wrapper--score-bar fgs-groups__cell-wrapper--clickable"}>
                    <div className={"fgs-groups__cell"} onClick={() => {
                        if (props.fgsGroup.functions.length > 0) {
                            dispatch(setGroupState(props.projectId, props.fgsGroup.id, !open));
                        }
                    }}>
                        <span className={"fgs-groups__score-bar"}
                              style={{width: Math.round(props.fgsGroup.functions.length / props.max * 100) + "%"}}/>
                    </div>
                </td>
                <td className={"fgs-groups__cell-wrapper fgs-groups__cell-wrapper--clickable"}>
                    <div className={"fgs-groups__cell"} onClick={() => {
                        if (props.fgsGroup.functions.length > 0) {
                            dispatch(setGroupState(props.projectId, props.fgsGroup.id, !open));
                        }
                    }}>
                        <IconButton icon={"angle-" + (open ? "up" : "down")} active={"true"}
                                    sizeModifier={"small"} disabled={props.fgsGroup.functions.length <= 0}/>
                    </div>
                </td>
            </tr>
            {props.fgsGroup.functions.length > 0 &&
                <tr key={props.fgsGroup.id + "-functions"}
                    className={cx("fgs-groups__sub-row", {'fgs-groups__sub-row--hidden': !open})}>
                    <td className={"fgs-function-table-wrapper"} colSpan={props.colSpanTable}>
                        <table className={"fgs-function-table"}>
                            <thead>
                            <tr className={"fgs-function-table__header-row"}>
                                <th className={"fgs-function-table__header fgs-function-table__header--name"}>Functienaam</th>
                                <th className={"fgs-function-table__header fgs-function-table__header--points"}>Punten</th>
                                <th className={"fgs-function-table__header fgs-function-table__header--group"}>Groep
                                    (oud)
                                </th>
                                <th className={"fgs-function-table__header fgs-function-table__header--difference"}>Verschil</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.fgsGroup.functions.map((func, idx) => {
                                return (
                                    <tr key={func.id} className={"fgs-function-table__row"}>
                                        <td className={"fgs-function-table__cell"}>
                                            {func.name}
                                        </td>
                                        <td className={"fgs-function-table__cell fgs-function-table__cell--number"}>
                                            {func.points}
                                        </td>
                                        <td className={"fgs-function-table__cell fgs-function-table__cell--centered"}>
                                            <div
                                                className={cx("pattern-group pattern-group--small")}>{func.function_group}</div>
                                        </td>
                                        <td className={"fgs-function-table__cell fgs-function-table__cell--centered"}>
                                            {props.fgsGroup.number - func.function_group < 0 && (
                                                <span
                                                    className={"fgs-groups__change fgs-groups__change--function-table fgs-groups__change--minus"}>
                                                <Icon icon={"sort-descending"} sizeModifier={"small"}
                                                      className={"fgs-groups__change-icon"}/>
                                                    {props.fgsGroup.number - func.function_group}
                                                </span>
                                            )}
                                            {props.fgsGroup.number - func.function_group === 0 && (
                                                <span
                                                    className={"fgs-groups__change fgs-groups__change--function-table"}>
                                                    {props.fgsGroup.number - func.function_group}
                                                </span>
                                            )}
                                            {props.fgsGroup.number - func.function_group > 0 && (
                                                <span
                                                    className={"fgs-groups__change fgs-groups__change--function-table fgs-groups__change--plus"}>
                                                <Icon icon={"sort-ascending"} sizeModifier={"small"}
                                                      className={"fgs-groups__change-icon"}/>
                                                    {props.fgsGroup.number - func.function_group}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </td>
                </tr>
            }
        </>
    )
}

export default function FunctionGroupStructureDisplay(props) {

    const {t, i18n} = useTranslation();
    const [resetFGS, setResetFGS] = useState(false);
    const [saveFgs, setSaveFGS] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const hasErrors = props.newFgs?.groups?.find(o => o.error !== false);
    const allErrors = props.newFgs?.groups?.filter(o => o.error !== false);

    const dispatch = useDispatch();

    const [fgsResolveBefore, setFgsResolveBefore] = useState("correct");
    const [fgsResolveAfter, setFgsResolveAfter] = useState("follow");

    const autoFixAllowed = allErrors.some((group, index, array) => {
        if (array.length === 1) {
            return true;
        }

        if (array.length === 2 && group.number - array[index - 1]?.number === 1 && props.lastRecalculateUsing === "points_min") {
            return true;
        }

        return false;
    })

    const onRadioChangeBefore = (e) => {
        setFgsResolveBefore(e.target.value)
    }

    const onRadioChangeAfter = (e) => {
        setFgsResolveAfter(e.target.value)
    }

    useEffect(() => {
        if (hasErrors && autoFixAllowed) {
            if (props.lastRecalculateUsing === "points_min" && !(hasErrors?.number === 2 && !allErrors.find(g => g.number === 3))) {
                if (fgsResolveBefore === "noAction") {
                    previewNoActionBefore(props.newFgs.groups, hasErrors, props.projectDetail.id, dispatch);
                }

                if (fgsResolveBefore === "correct") {
                    previewCorrectFactorBefore(props.newFgs.groups, hasErrors, props.projectDetail.id, dispatch);
                }

                if (fgsResolveBefore === "follow") {
                    previewFollowFactorBefore(props.newFgs.groups, hasErrors, props.projectDetail.id, dispatch);
                }
            }

            if (fgsResolveAfter === "noAction") {
                previewNoActionAfter(props.newFgs.groups, hasErrors, props.projectDetail.id, dispatch);
            }

            if (fgsResolveAfter === "correct") {
                previewCorrectFactorAfter(props.newFgs.groups, hasErrors, props.projectDetail.id, dispatch);
            }

            if (fgsResolveAfter === "follow") {
                previewFollowFactorAfter(props.newFgs.groups, hasErrors, props.projectDetail.id, dispatch, props.lastRecalculateUsing);
            }
        } else {
            props.newFgs.groups.map(group => {
                group.preview_points_min = null
                group.preview_points_max = null
                group.preview_factor = null
            })

            dispatch(setFGSGroupSettingsAll(props.projectDetail.id, props.newFgs.groups))
        }


    }, [fgsResolveBefore, fgsResolveAfter, props.lastRecalculateUsing, hasErrors])

    function previewNoActionBefore(groups, hasErrors, projectId, dispatch) {
        const reversedGroups = groups.reverse();

        reversedGroups.map(group => {
            if (group.number < hasErrors?.number) {
                group.preview_points_max = null
                group.preview_points_min = null
                group.preview_factor = null
            }
        })

        const result = reversedGroups.reverse();

        dispatch(setFGSGroupSettingsAll(projectId, result))
    }

    function previewNoActionAfter(groups, hasErrors, projectId, dispatch) {
        groups.map(group => {
            if (group.number > hasErrors?.number || group.number === hasErrors?.number) {
                group.preview_points_min = null
                group.preview_points_max = null
                group.preview_factor = null
            }
        })

        dispatch(setFGSGroupSettingsAll(projectId, groups))
    }

    function previewCorrectFactorBefore(groups, hasErrors, projectId, dispatch) {
        const reversedGroups = groups.reverse();

        reversedGroups.map(group => {
            if (group.number < hasErrors?.number) {
                group.preview_points_max = null
                group.preview_points_min = null
                group.preview_factor = null
            }

            const previousGroup = reversedGroups.find(g => g.number == group.number + 1)
            if (group.number === hasErrors?.number - 1) {
                group.preview_points_max = previousGroup.points_min - 1
                group.preview_factor = (group.preview_points_max / group.points_min).toFixed(3);
            }
        })

        const result = reversedGroups.reverse();

        dispatch(setFGSGroupSettingsAll(projectId, result))
    }


    function previewCorrectFactorAfter(groups, hasErrors, projectId, dispatch) {
        groups.map(group => {
            if ((group.number > hasErrors?.number && props.lastRecalculateUsing === "points_min") || (group.number >= hasErrors?.number && (props.lastRecalculateUsing === "points_max" || props.lastRecalculateUsing === "factor" || props.lastRecalculateUsing === "autofix"))) {
                group.preview_points_min = null
                group.preview_points_max = null
                group.preview_factor = null
            }

            const previousGroup = groups.find(g => g.number == group.number - 1)
            if ((group.number === hasErrors?.number + 1 && props.lastRecalculateUsing === "points_min") || (group.number === hasErrors?.number && (props.lastRecalculateUsing === "points_max" || props.lastRecalculateUsing === "factor" || props.lastRecalculateUsing === "autofix"))) {
                group.preview_points_min = previousGroup.points_max + 1
                group.preview_factor = (group.points_max / group.preview_points_min).toFixed(3);
            }
        })

        dispatch(setFGSGroupSettingsAll(projectId, groups))
    }

    function previewFollowFactorBefore(groups, hasErrors, projectId, dispatch) {
        const reversedGroups = groups.reverse();

        reversedGroups.map(group => {
            const previousGroup = reversedGroups.find(g => g.number == group.number + 1)
            const nextGroup = reversedGroups.find(g => g.number == group.number - 1)

            if (group.number < hasErrors?.number) {
                group.preview_points_min = null
                group.preview_points_max = null
                group.preview_factor = null

                if (previousGroup.preview_points_min) {
                    group.preview_points_max = previousGroup.preview_points_min - 1
                } else {
                    group.preview_points_max = previousGroup.points_min - 1
                }

                if (!nextGroup) {
                    group.preview_factor = (group.preview_points_max / group.points_min).toFixed(3);
                } else {
                    group.preview_points_min = Math.round(group.preview_points_max / group.factor)
                }

            }
        })

        const result = reversedGroups.reverse();

        dispatch(setFGSGroupSettingsAll(projectId, result))
    }

    function previewFollowFactorAfter(groups, hasErrors, projectId, dispatch, lastRecalculateUsing) {
        groups.map(group => {
            const previousGroup = groups.find(g => g.number == group.number - 1)
            const nextGroup = groups.find(g => g.number == group.number + 1)

            if (group.number === hasErrors?.number && ((lastRecalculateUsing === "points_max" || lastRecalculateUsing === "factor" || lastRecalculateUsing === "autofix")
                || (lastRecalculateUsing === "points_min" && hasErrors?.number === 2 && !allErrors.find(g => g.number === 3)))) {
                group.preview_points_min = null
                group.preview_points_max = null
                group.preview_factor = null
                group.preview_points_min = previousGroup.points_max + 1
                group.preview_points_max = Math.round(group.preview_points_min * group.factor)
            }

            if (group.number > hasErrors?.number) {
                group.preview_points_min = null
                group.preview_points_max = null
                group.preview_factor = null
                if (previousGroup.preview_points_max) {
                    group.preview_points_min = previousGroup.preview_points_max + 1
                } else {
                    group.preview_points_min = previousGroup.points_max + 1
                }

                if (!nextGroup) {
                    group.preview_points_max = 9999
                } else {
                    group.preview_points_max = Math.round(group.preview_points_min * group.factor)
                }
            }
        })

        dispatch(setFGSGroupSettingsAll(projectId, groups))
    }

    const [colSpanTable, setColSpanTable] = useState("11");
    const [colSpanAmountFunctions, setColSpanAmountFunctions] = useState("2");

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1350) {
                setColSpanTable("10");
                setColSpanAmountFunctions("1");
            } else {
                setColSpanTable("11");
                setColSpanAmountFunctions("2");
            }
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    return (
        <Modal isOpen={props.functionGroupStructureActive} className={"modal modal--fgs"}
               overlayClassName={"modal-background modal-background--full-screen"} bodyOpenClassName={"has-modal"}>
            {props?.canSaveFGS && props?.fgsForced && (
                <Notification type={"warning"}
                              text={t("De functiegroepenstructuur wordt afgedwongen door een ander project en kan daarom niet worden opgeslagen.")}/>
            )}
            {hasErrors && props?.canSaveFGS && !props?.fgsForced && (
                <Notification type={"error"}
                              text={t("De functiegroepenstructuur bevat fouten en kan daardoor niet worden opgeslagen")}/>
            )}
            {showHistory && (
                <Modal isOpen={true} className={"modal"} overlayClassName={"modal-background"}
                       bodyOpenClassName={"has-modal"}>
                    <div className={"modal-header"}>
                        <div className={"modal-header__top"}>
                            <h2 className={"modal-header__title"}>{t("Functiegroepenstructuur - geschiedenis")}</h2>
                            <ul className={"modal-header__controls"}>
                                <li className={"modal-header__control"}>
                                    <IconButton icon={"close"} onClick={() => setShowHistory(false)}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"modal__body"}>
                        {props?.snapshots?.map(snapshot => {
                            const date = new Intl.DateTimeFormat("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                                // hour: 'numeric',
                                // minute: 'numeric',
                            }).format(new Date(snapshot.creation_date));
                            const filename = `${props.projectDetail.client.name} - ${props.projectDetail.name} - ${t("Functiegroepenstructuur")} ${date} - FUWA Online.pdf`;
                            return (
                                <a key={snapshot.id}
                                   download={filename}
                                   href={snapshot.download_url}
                                   className={"file-list__item"}
                                >
                                    <div className={"file-list__primary"}>
                                        <div className={"file-list__filename"}>
                                            {filename}
                                        </div>
                                    </div>
                                    <div className={"file-list__secondary"}>
                                        <Icon icon={cx("download")} sizeModifier={"small"}/>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                </Modal>
            )}

            <div className={"modal-header modal-header--large modal-header--no-border"}>
                <div className={"new-modal-header__top"}>
                    <div className={"new-modal-header__title-container"}>
                        <div className={"modal-header__title-wrapper"}>
                            <h2 className={"modal-header__title"}>{t("Functiegroepenstructuur")}</h2>
                            {props.fgsForced && (
                                <StatusTag
                                    title={"CAO Project"}
                                    type={"neutral"}
                                />
                            )}
                            {props.projectDetail.is_collective_labour_agreement &&
                                <span
                                    className={"overview-table__tag status-tag status-tag--cao"}>{t(FunctionSource['cao'].short)}</span>
                            }
                        </div>
                        <span
                            className={"modal-header__breadcrumbs"}>{props.projectDetail.client.name + " / " + props.projectDetail.name}</span>
                    </div>
                    <ul className={"modal-header__controls"}>
                        <li className={"modal-header__control"}>
                            <IconButton icon={"close"} onClick={() => props.setFunctionGroupStructureActive(false)}/>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"fgs-actions"}>
                <div className={"icon-button-group"}>
                    <IconButton icon={"history"}
                                onClick={() => setShowHistory(true)}
                                border={"true"}
                    />
                    <IconButton icon={"download"}
                                onClick={() => props.pdf()}
                                border={"true"}
                    />
                </div>
                <div className={"icon-button-group"}>
                    <IconButton icon={"row-expand"}
                                onClick={() => {
                                    dispatch(setGroupStateMultiple(props.projectDetail.id, props.newFgs?.groups.map(o => o.id), true))
                                }}
                                border={"true"}
                    />
                    <IconButton icon={"row-minimize"}
                                onClick={() => {
                                    dispatch(setGroupStateMultiple(props.projectDetail.id, props.newFgs?.groups.map(o => o.id), false))
                                }}
                                border={"true"}
                    />
                </div>
            </div>
            <div className={"fgs-settings"}>
                <div className={"fgs-settings__primary"}>
                    <div className={"fgs-settings__input-container"}>
                        <label className={"fgs-settings__label"} htmlFor={"fgs-startgroup"}>Startgroep</label>
                        <input className={"fgs-settings__input input--small input--number"} type={"number"} step="1"
                               id={"fgs-startgroup"}
                               placeholder={props.currentFgs.group_start}
                               onChange={e => props.setFGSDefaultSetting('group_start', parseInt(e.target.value))}
                               value={props.newFgs.group_start || ""}/>
                    </div>
                    <div className={"fgs-settings__input-container"}>
                        <label className={"fgs-settings__label"} htmlFor={"fgs-endgroup"}>Eindgroep</label>
                        <input className={"fgs-settings__input input--small input--number"} type={"number"} step="1"
                               id={"fgs-endgroup"}
                               placeholder={props.currentFgs.group_end}
                               onChange={e => props.setFGSDefaultSetting('group_end', parseInt(e.target.value))}
                               value={props.newFgs.group_end || ""}/>
                    </div>
                    <div className={"fgs-settings__input-container"}>
                        <label className={"fgs-settings__label"} htmlFor={"fgs-startvalue"}>Startwaarde</label>
                        <input className={"fgs-settings__input input--small input--number"} type={"number"} step="1"
                               id={"fgs-startvalue"}
                               placeholder={props.currentFgs.points_start}
                               onChange={e => props.setFGSDefaultSetting('points_start', parseInt(e.target.value))}
                               value={props.newFgs.points_start || ""}/>
                    </div>
                    <div className={"fgs-settings__input-container"}>
                        <label className={"fgs-settings__label"} htmlFor={"fgs-standardfactor"}>Standaardfactor</label>
                        <input className={"fgs-settings__input fgs-settings__input--wide input--small input--number"} type={"number"} step=".01"
                               id={"fgs-standardfactor"}
                               placeholder={props.currentFgs.default_factor}
                               onChange={e => props.setFGSDefaultSetting('default_factor', e.target.value)}
                               value={props.newFgs.default_factor || ""}/>
                    </div>
                </div>
                <div className={"fgs-settings__secondary"}>
                    <div>
                        <ButtonQuaternary icon={"reset"}
                                          text={"Herstellen"}
                                          onClick={() => setResetFGS(true)}
                        />
                        {resetFGS && (
                            <ConfirmModal
                                key={'save-fgs'}
                                title={("Functiegroepenstructuur herstellen")}
                                body={("Weet je zeker dat je de instellingen van deze Functiegroepenstructuur wilt herstellen? Alle wijzigingen gaan verloren")}
                                confirmModal={() => {
                                    props.reset();
                                }}
                                closeModal={() => setResetFGS(false)}
                            />
                        )}
                    </div>
                    {props?.canSaveFGS === true && (
                        <div>
                            <ButtonPrimary text={"Opslaan"}
                                           disabled={hasErrors || props.fgsForced}
                                           onClick={() => setSaveFGS(true)}
                            />
                            {saveFgs && (
                                <ConfirmModal
                                    key={'save-fgs'}
                                    title={("Functiegroepenstructuur opslaan")}
                                    body={("Weet je zeker dat je de functiegroepenstructuur wilt opslaan?")}
                                    confirmModal={() => {
                                        props.saveFGS();
                                    }}
                                    closeModal={() => setSaveFGS(false)}/>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {!autoFixAllowed && hasErrors &&
                <div className={'fgs-resolve'}>
                    <div className={'fgs-resolve__title'}>
                        <Icon icon={'warning-triangle'} sizeModifier={'small'}></Icon>
                        <h4 className={'fgs-resolve__heading'}>{t("Automatisch fouten oplossen")}</h4>
                    </div>
                    <div className={'fgs-resolve__content'}>
                        <p className={'fgs-resolve__text'}>
                            {t("Het is niet mogelijk om de fouten in deze structuur automatisch op te lossen omdat er meer dan één fout aanwezig is. Corrigeer de fouten handmatig, of herstel de gehele functiegroepenstructuur.")}
                        </p>
                        <ButtonPrimary icon={"reset"}
                                       text={t("Herstellen")}
                                       onClick={() => setResetFGS(true)}
                        />
                    </div>
                </div>
            }

            {autoFixAllowed &&
                <div className={'fgs-resolve'}>
                    <div className={'fgs-resolve__title'}>
                        <Icon icon={'warning-triangle'} sizeModifier={'small'}></Icon>
                        <h4 className={'fgs-resolve__heading'}>{t("Automatisch fouten oplossen")}</h4>
                    </div>
                    <div className={'fgs-resolve__content'}>
                        <div className={'fgs-resolve__content-left'}>
                            {props.lastRecalculateUsing === "points_min" && !(hasErrors?.number === 2 && !allErrors.find(g => g.number === 3)) &&
                                <div className={'fgs-resolve__radio-column'}>
                                    <h5 className={'fgs-resolve__subheading'}>{t("Voor groep ") + hasErrors?.number}</h5>
                                    <div className={'fgs-resolve__radio-buttons'}>
                                        <div className={'fgs-resolve__radio'}>
                                            <input className={'radio'} type='radio' name={'fgs-resolve-before'}
                                                   id={'fgs-resolve-before-no-action'}
                                                   value={"noAction"} checked={fgsResolveBefore === "noAction"}
                                                   onChange={onRadioChangeBefore}
                                            />
                                            <label htmlFor={'fgs-resolve-before-no-action'}>{t("Niets doen")}</label>
                                        </div>
                                        <div className={'fgs-resolve__radio'}>
                                            <input className={'radio'} type='radio' name={'fgs-resolve-before'}
                                                   id={'fgs-resolve-before-correct'}
                                                   value={"correct"} checked={fgsResolveBefore === "correct"}
                                                   onChange={onRadioChangeBefore}
                                            />
                                            <label htmlFor={'fgs-resolve-before-correct'}>{t("Factor corrigeren")}</label>
                                        </div>
                                        <div className={'fgs-resolve__radio'}>
                                            <input className={'radio'} type='radio' name={'fgs-resolve-before'}
                                                   id={'fgs-resolve-before-follow'}
                                                   value={"follow"} checked={fgsResolveBefore === "follow"}
                                                   onChange={onRadioChangeBefore}
                                            />
                                            <label htmlFor={'fgs-resolve-before-follow'}>{t("Factor volgen")}</label>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className={'fgs-resolve__radio-column'}>
                                <h5 className={'fgs-resolve__subheading'}>{t("Na groep ") + (props.lastRecalculateUsing === "points_min"  && !(hasErrors?.number === 2 && !allErrors.find(g => g.number === 3)) ? hasErrors?.number : hasErrors?.number - 1)}</h5>
                                <div className={'fgs-resolve__radio-buttons'}>
                                    <div className={'fgs-resolve__radio'}>
                                        <input className={'radio'} type='radio' name={'fgs-resolve-after'}
                                               id={'fgs-resolve-after-no-action'}
                                               value={"noAction"} checked={fgsResolveAfter === "noAction"}
                                               onChange={onRadioChangeAfter}
                                        />
                                        <label htmlFor={'fgs-resolve-after-no-action'}>{t("Niets doen")}</label>
                                    </div>
                                    <div className={'fgs-resolve__radio'}>
                                        <input className={'radio'} type='radio' name={'fgs-resolve-after'}
                                               id={'fgs-resolve-after-correct'}
                                               value={"correct"} checked={fgsResolveAfter === "correct"}
                                               onChange={onRadioChangeAfter}
                                        />
                                        <label htmlFor={'fgs-resolve-after-correct'}>{t("Factor corrigeren")}</label>
                                    </div>
                                    <div className={'fgs-resolve__radio'}>
                                        <input className={'radio'} type='radio' name={'fgs-resolve-after'}
                                               id={'fgs-resolve-after-follow'}
                                               value={"follow"} checked={fgsResolveAfter === "follow"}
                                               onChange={onRadioChangeAfter}
                                        />
                                        <label htmlFor={'fgs-resolve-after-follow'}>{t("Factor volgen")}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ButtonPrimary text={t("Oplossen")}
                                       onClick={() => {
                                           props.newFgs.groups.map((group) => {
                                               if (group.preview_factor) {
                                                   group.factor = group.preview_factor;
                                                   group.preview_factor = null;
                                               }
                                               if (group.preview_points_max) {
                                                   group.points_max = group.preview_points_max;
                                                   group.preview_points_max = null;
                                               }
                                               if (group.preview_points_min) {
                                                   group.points_min = group.preview_points_min;
                                                   group.preview_points_min = null;
                                               }
                                           })
                                           props.newFgs.recalculateUsing = "autofix";

                                           dispatch(setFGSGroupSettingsAll(props.projectDetail.id, props.newFgs.groups))
                                       }}
                        />
                    </div>
                </div>
            }
            <div className={"fgs-groups"}>
                <table className={"fgs-groups__table"}>
                    <thead>
                    <tr>
                        <th className={"fgs-groups__header fgs-groups__header--group"}>{t("Groep")}</th>
                        <th className={"fgs-groups__header fgs-groups__header--label"}>{t("Label")}</th>
                        <th className={"fgs-groups__header fgs-groups__header--number"} colSpan={"2"}>{t("Factor")}</th>
                        <th className={"fgs-groups__header fgs-groups__header--number"}
                            colSpan={"2"}>{t("Ondergrens")}</th>
                        <th className={"fgs-groups__header fgs-groups__header--number"}
                            colSpan={"2"}>{t("Bovengrens")}</th>
                        <th className={"fgs-groups__header"}
                            colSpan={colSpanAmountFunctions}>{colSpanAmountFunctions === "2" ? t("Aantal functies") : t("Functies")}</th>
                        <th className={"fgs-groups__header"}>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.data.groups.map((fgsGroup, idx) => {
                        return (
                            <GroupDefinition
                                projectId={props?.projectDetail?.id}
                                setFGSGroupSetting={(identifier, value) => props.setFGSGroupSetting(fgsGroup.number, identifier, value)}
                                key={fgsGroup.number}
                                max={props.data.max}
                                currentFgs={props.currentFgs}
                                newFgs={props.newFgs}
                                fgsGroup={fgsGroup}
                                colSpanTable={colSpanTable}
                            />
                        )
                    })}
                    </tbody>
                </table>

                <ButtonSecondary
                    text={"Groep toevoegen"}
                    icon={"add"}
                    className={cx("fgs-groups__add", {"fgs-groups__add--resolve-open": autoFixAllowed || (!autoFixAllowed && hasErrors)})}
                    onClick={() => props.setFGSDefaultSetting('group_end', parseInt(props.newFgs.groups[props.newFgs.groups.length - 1].number + 1))}
                />
            </div>


        </Modal>
    )

}
