import React from "react";

export function withHook(Wrapped, hook) {

    return function(props) {

        let addedHook = hook(props);

        let newProps = {
            ...props,
            ...addedHook,
        };

        return (
            <Wrapped {...newProps} />
        )
    }
}
