import React, {useEffect, useState} from "react";
import cx from "classnames";

import {InfoButton} from "../../patterns/molecules/InfoButton";
import GroupPatternScore from "../../patterns/molecules/GroupPatternScore";
import {FunctionStatus} from "../../patterns/atoms/Pip";
import TruncateMiddle from "../../utils/TruncateMiddle";
import {
    getFunctions,
    getOtherFunctions,
    getOwnFunctions,
    getProjectGroupFunctions
} from "../../queries/client_project_functions";
import {NameSourcetypeFilter} from "./Filters/NameSourcetypeFilter";
import {MNTFilter} from "./Filters/MNTFilter";
import {MNTTitle} from "./Titles/MNTTitle";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import {FunctionStatusFilter} from "./Filters/StatusFilter";
import {DateFilter} from "./Filters/DateFilter";
import {TextFilter} from "./Filters/TextFilter";
import {Icon} from "../../patterns/atoms/Icon";
import OverviewName from "../../patterns/molecules/OverviewName";
import useSWR, {mutate} from 'swr';
import {
    archiveFunctions,
    prefetchFunction,
    remoteCopyBulkFunction,
    remoteSetBulkStatus
} from "../../queries/function_detail";
import {generatePath, useLocation, useParams} from "react-router-dom";
import ProjectMeta from "../ProjectMeta";
import Overview from "../Overview";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import {FunctionSource, functionStatus, isFuwaterVbalans, MNTDomains, VBalansDomains} from "../../utils/constants";
import {getProject} from "../../queries/project_detail";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import FunctionTypeIndicator from "../../patterns/atoms/FunctionTypeIndicator";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import {withAccessRight, WithAccessRight} from "../../utils/withAccessHoC";
import {useAuth} from "../../utils/useAuth";
import {AccessDenied} from "../../patterns/organisms/AccessDenied";
import Modal from "react-modal";
import IconButton from "../../patterns/molecules/IconButton";
import ButtonSecondary from "../../patterns/atoms/ButtonSecondary";
import ButtonPrimary from "../../patterns/atoms/ButtonPrimary";
import Notification from "../../patterns/molecules/Notification";
import {Field} from "react-final-form";
import Drawer from "../Drawer";
import {ProjectSelectionDefinition} from "./ProjectSelection";
import i18n from "../../utils/i18n";
import FunctionAdvancedFilter from "../FunctionAdvancedFilter";
import FilterLabel from "../FilterLabel";
import ButtonQuaternary from "../../patterns/atoms/ButtonQuaternary";
import {setFilter} from "../../actions/overview";
import {useDispatch} from "react-redux";
import {Input} from "../../patterns/atoms/Input";


export function FunctionStatusChangeModal(props) {

    const {t, i18n} = useTranslation();
    const [fieldValue, setFieldValue] = useState({
        description_status: "empty",
        scoring_status: "empty",
    });
    const status = Object.entries(functionStatus).filter(o => o[0] !== "archived");
    const save = async () => {
        const functions = Object.entries(props.bulkState).filter(o => o[1] === true).map(o => o[0]);
        await remoteSetBulkStatus(fieldValue, functions);
        props.closeModal();
        props.toggleBulk();
    }

    return (
        <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"}
               bodyOpenClassName={"has-modal"}>
            <div className={"modal-header"}>
                <div className={"modal-header__top"}>
                    <h2 className={"modal-header__title"}>{t("Wijzig status")}</h2>
                    <ul className={"modal-header__controls"}>
                        <li className={"modal-header__control"}>
                            <IconButton icon={"close"} onClick={() => props.closeModal()}/>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"modal__body"}>
                <Notification type={"warning"} sizeModifier={"large"}
                              text={t("U staat op het punt de status van meerdere functies veranderen.")}/>
                <div className="input-group">
                    <label className="label"
                           htmlFor={"description_status"}>{t("Nieuwe status voor beschrijving")}</label>
                    <div className="input-group__input">
                        <div className="select-container">
                            <select
                                onChange={e => setFieldValue(state => {
                                        return {
                                            ...state,
                                            description_status: e.target.value,
                                        }
                                    }
                                )}
                                value={fieldValue.description_status}
                                name={"description_status"}
                                id={"description_status"}
                            >
                                <option key={"empty"} value={""}>{t("Niet veranderen")}</option>
                                {status.map(o => {
                                    return (
                                        <option key={o[0]} value={o[0]}>{t(o[1])}</option>
                                    )
                                })}
                            </select>
                            <Icon icon={"angle-down"} sizeModifier={"small"} className={"select-container__icon"}/>
                        </div>
                    </div>
                </div>
                <div className="input-group">
                    <label className="label" htmlFor={"scoring_status"}>{t("Nieuwe status voor indeling")}</label>
                    <div className="input-group__input">
                        <div className="select-container">
                            <select
                                onChange={e => setFieldValue(state => {
                                        return {
                                            ...state,
                                            scoring_status: e.target.value,
                                        }
                                    }
                                )}
                                value={fieldValue.scoring_status}
                                name={"scoring_status"}
                                id={"scoring_status"}
                            >
                                <option key={"empty"} value={""}>{t("Niet veranderen")}</option>
                                {status.map(o => {
                                    return (
                                        <option key={o[0]} value={o[0]}>{t(o[1])}</option>
                                    )
                                })}
                            </select>
                            <Icon icon={"angle-down"} sizeModifier={"small"} className={"select-container__icon"}/>
                        </div>
                    </div>
                </div>
                <p className="paragraph">
                    {t("Met het wijzigen van de status via bulk-selectie zullen er géén aanvullende vragen gesteld worden. De wijziging zal toegepast worden evenals de daar aan verbonden acties en consequenties.")}
                </p>
            </div>
            <div className="modal-footer">
                <ul className={"modal__buttons"}>
                    <li className={"modal__button"}>
                        <ButtonSecondary
                            onClick={() => props.closeModal()}>
                            {t("Annuleren")}
                        </ButtonSecondary>
                    </li>
                    <li className={"modal__button"}>
                        <ButtonPrimary
                            onClick={() => {
                                save();
                            }}>
                            {t("Toepassen")}
                        </ButtonPrimary>
                    </li>
                </ul>
            </div>
        </Modal>
    )
}


export function CopyFunctionsModal(props) {

    const {t, i18n} = useTranslation();
    const [fieldValue, setFieldValue] = useState(false);
    const [loading, setLoading] = useState(false);
    const save = async () => {
        const functions = Object.entries(props.bulkState).filter(o => o[1] === true).map(o => o[0]);
        setLoading(true);
        await remoteCopyBulkFunction(fieldValue, functions);
        setLoading(false);
        props.closeModal();
        props.toggleBulk();
    }

    return (
        <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"}
               bodyOpenClassName={"has-modal"}>
            <div className={"modal-header"}>
                <div className={"modal-header__top"}>
                    <h2 className={"modal-header__title"}>{t("Kopieer functies")}</h2>
                    <ul className={"modal-header__controls"}>
                        <li className={"modal-header__control"}>
                            <IconButton icon={"close"} onClick={() => props.closeModal()}/>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"modal__body"}>
                <p className="paragraph">
                    {t("Kopieert de geselecteerde functies naar het gekozen project")}
                </p>

                <div className="input-group">
                    <Drawer
                        title={t("Selecteer Project")}
                        buttonText={t("Zoek project")}
                        label={t("Project")}
                        initialReadOnlyVal={props?.project?.name}
                        readOnlyVal={obj => obj.name}
                        placeholder={t("Geen project gekozen")}
                        id={"project_id_drawer"}
                        isForm={true}
                        disabled={false}

                        reset={() => {
                            // props.form.change('project_id', '');
                            setFieldValue(false);
                        }}

                        onClick={(obj) => {
                            setFieldValue(obj.id);
                        }}

                        contentComponent={
                            <Overview
                                identifier={"select-project-overview"}
                                identKey={{
                                    client_id: props.client_id,
                                    function_id: props.function_id,
                                }}
                                contextData={{
                                    location,
                                }}
                                definition={ProjectSelectionDefinition}
                                initialFilters={{
                                    status__in: ['concept', 'determined', 'expired']
                                }}
                            />
                        }
                    />
                </div>
            </div>
            <div className="modal-footer">
                <ul className={"modal__buttons"}>
                    <li className={"modal__button"}>
                        <ButtonSecondary
                            onClick={() => props.closeModal()}>
                            {t("Annuleren")}
                        </ButtonSecondary>
                    </li>
                    <li className={"modal__button"}>
                        <ButtonPrimary
                            className={cx({'button--loading': loading})}
                            disabled={!fieldValue}
                            onClick={() => {
                                save();
                            }}>
                            <span className={"button__text"}>{t("Kopiëren")}</span>
                            {loading && <div className={"button__loader"}><LoaderAnimation infinite={"true"} small={true} inverted={true}/></div>}
                        </ButtonPrimary>
                    </li>
                </ul>
            </div>
        </Modal>
    )
}

export const StandardRowStructure = React.memo(tempProps => {

    const {t, i18n} = useTranslation();

    let props = Object.assign(
        {
            id: false,
            type: 'unknown',
            name: t(""),
            pattern_list: [],
            version_number: "",
            project: {
                name: (<>&nbsp;</>)
            },
            function_group_label: "-",
            points: "-",
            displayPoints: props?.displayPoints,
            comparison: props?.comparison,
            modification_date: false,
        },
        tempProps,
    );
    // may be passed to OverviewName but this is the default in OverviewName  as well
    // const lengths = {
    //     small: 15,
    //     medium: 20,
    //     large: 25,
    //     xlarge: 30,
    //     full: 35,
    // }
    const detailUrl = props?.contextData?.insight === true ?
        generatePath('/function/:function_id', {
            client_id: props.contextData?.client_id,
            project_id: props.contextData.project_id,
            function_id: props.id
        }) : props?.contextData?.client_id ?
            generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                client_id: props.contextData?.client_id,
                project_id: props?.contextData?.project_id || props.project.id,
                function_id: props.id
            }) : props?.client?.id ? generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                client_id: props.client.id,
                project_id: props.project.id,
                function_id: props.id
            }) : false;

    const detailClick = props?.onClick ? () => props.onClick(props) : (e) => {
        props.navigate(detailUrl);

    }

    const kind = () => {

        if(props.is_collective_labour_agreement) {
            return FunctionSource["cao"]
        }

        // project, based_on, uses, method, example
        if(props.source) {
            return FunctionSource[props.source];
        }
    }

    const creation_date = props.creation_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date(props.creation_date)) : false;
    const modification_date = props.modification_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date(props.modification_date)) : false;

    return (
        <tr
            onMouseEnter={() => !props?.fake && prefetchFunction(props.id)}
            key={props.id}
            className={"overview-table__row"}
        >
            {props?.bulkColumn && props.bulkColumn}

            {props.columns.some(col => col.identifier === 'type__in') && (
                <td className={"overview-table__cell"}>
                    <FunctionTypeIndicator type={props.type}/>
                </td>
            )}
            <td className={cx("overview-table__cell overview-table__cell--name", {"overview-table__cell--interactive": detailUrl})}
                onClick={detailUrl ? detailClick : () => false}
                data-cy={"overview-table-cell-function-name"}
            >
                {detailUrl ? (
                    <a href={detailUrl} onClick={e => e.preventDefault()}>
                        <OverviewName
                            {...props}
                            kind={kind()}
                        />
                    </a>

                ) : (
                    <OverviewName
                        {...props}
                        kind={kind()}
                    />
                )}
            </td>
            {props.columns.some(col => ['vbalans', 'mnt'].includes(col.identifier)) && (
                <td className={"overview-table__cell"}>
                    <GroupPatternScore {...props} comparison={false} displayPoints={false} type={props.type}/>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'description_status__in') && (
                <td className={"overview-table__cell overview-table__cell--no-padding"}>
                    <FunctionStatus description_status={props.description_status}
                                    scoring_status={props.scoring_status}
                                    functionId={props.id}/>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'modification_date') && (
                <td className={"overview-table__cell"}>
                    {props.modification_date ? (

                        <div className={"overview-table__text"}>
                            <time dateTime={props.modification_date}>
                                {new Intl.DateTimeFormat("nl-NL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric"
                                }).format(new Date(props.modification_date))}
                            </time>
                        </div>
                    ) : (
                        <div className={"overview-table__skeleton"}/>
                    )}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'department') && (
                <td className={"overview-table__cell"}>
                    <div className={"overview-table__text"}>
                        {props.department?.name}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'sub_department') && (
                <td className={"overview-table__cell"}>
                    <div className={"overview-table__text"}>
                        {props.sub_department?.name}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'client__name') && (
                <td className={"overview-table__cell"}>
                    <div className={"overview-table__text"}>
                        {props?.client?.name}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'project__name') && (
                <td className={"overview-table__cell"}>
                    <div className={"overview-table__text"}>
                        {props.project.name}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'info') && (
                <td className={"overview-table__cell overview-table__cell--buttons"}>
                    <InfoButton
                        icon={"info"}
                        sizeModifier={"medium"}
                        prefix_identifier={"client-project-overview"}
                        id={props.id}
                        title={t("Meer informatie")}
                    >
                        <div className={"popover__content"}>
                            <div className={"popover__title"} title={props.name}> {props.name} </div>
                        </div>
                        <div className={"popover__content"}>
                            <dl className={"popover-info popover-info--duo"}>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Versie")}</dt>
                                    <dd className={"popover-info__value"}>{props.version_number || "-"}</dd>

                                    <dt className={"popover-info__key"}>{t("Aanmaakdatum")}</dt>
                                    <dd className={"popover-info__value"}>{creation_date || "-"}</dd>

                                    {/*<dt className={"popover-info__key"}>{t("Door")}</dt>*/}
                                    {/*<dd className={"popover-info__value"}>{props.created_by?.get_full_name || "-"} </dd>*/}

                                    <dt className={"popover-info__key"}>{t("Project")}</dt>
                                    <dd className={"popover-info__value"}>{props?.project?.name || "-"} </dd>

                                </div>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Functiecode")}</dt>
                                    <dd className={"popover-info__value"}>{props.code || "-"} </dd>

                                    <dt className={"popover-info__key"}>{t("Wijzigingsdatum")} op</dt>
                                    <dd className={"popover-info__value"}>{modification_date || "-"} </dd>

                                    {/*<dt className={"popover-info__key"}>{t("Door")}</dt>*/}
                                    {/*<dd className={"popover-info__value"}>{props.modified_by?.get_full_name || "-"} </dd>*/}

                                    <dt className={"popover-info__key"}>{t("Klant")}</dt>
                                    <dd className={"popover-info__value"}>{props?.client?.name || "-"}</dd>
                                </div>
                            </dl>
                        </div>
                        <div className={"popover__content"}>
                            <dl className={"popover-info popover-info--duo"}>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Hoofdafdeling")}</dt>
                                    <dd className={"popover-info__value"}>{props?.department?.name || "-"}</dd>

                                </div>
                                <div className={"popover-info__column"}>

                                    <dt className={"popover-info__key"}>{t("Subafdeling")}</dt>
                                    <dd className={"popover-info__value"}>{props?.sub_department?.name || "-"}</dd>
                                </div>
                            </dl>
                        </div>
                        <div className={"popover__content"}>
                            <dl className={"popover-info popover-info--duo"}>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
                                    <dd className={"popover-info__value"}>
                                        {props.describer_group ?
                                            props.describer_group?.name
                                            :
                                            props?.describer?.get_full_name || "-"
                                        }
                                    </dd>
                                </div>
                                <div className={"pop-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Toepasser")}</dt>
                                    <dd className={"popover-info__value"}>
                                        {props.applier_one_group ?
                                            props.applier_one_group?.name
                                            :
                                            props?.applier_one?.get_full_name || "-"
                                        }
                                    </dd>

                                    <dt className={"popover-info__key"}>{t("Toetser")}</dt>
                                    <dd className={"popover-info__value"}>
                                        {props.applier_two_group ?
                                            props.applier_two_group?.name
                                            :
                                            props?.applier_two?.get_full_name || "-"
                                        }
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </InfoButton>
                </td>
            )}
        </tr>
    )
});

function ArchiveFunctions(props) {
    return (
        <>
            <ConfirmModal
                key={'archive-functions'}
                title={("Functies archiveren")}
                body={("Weet je zeker dat je deze functies wilt archiveren?")}
                confirmModal={() => {
                    archiveFunctions(Object.keys(props.bulkState)).then(() => {
                        props.closeModal();
                        props.toggleBulk()
                    })
                }}
                closeModal={() => props.closeModal()}/>
        </>
    )
}


export const ClientProjectFunctionOverviewDefinitionDefault = {

    title: i18n.t("Functieoverzicht"),
    identifier: 'function-overview-client',
    loadAllAllowed: true,
    queryFn: getFunctions,
    RowStructure: StandardRowStructure,
    primary: {
        title: i18n.t("Functie aanmaken"),
        identifier: 'create-function',
        link: './create/',
        "data-cy": "function-create-button"
    },
    secondary: {
        // title: i18n.t("Bulk-actie"),
        // identifier: 'bulk-action',
        // icon: 'list-icon'
    },
    noSearch: true,
    advancedFilter: {
        identifier: 'advanced-filter-client-project-function',
        action: {
            Component: FunctionAdvancedFilter,
            advancedFilters: [
                "domain1__gte",
                "domain2__gte",
                "domain3__gte",
                "domain4__gte",
                "domain5__gte",
                "domain6__gte",
                "domain7__gte",
                "domain8__gte",
                "domain9__gte",
                "domain10__gte",
                "domain11__gte",
                "domain12__gte",
                "domain13__gte",
                "domain14__gte",
                "domain1__lte",
                "domain2__lte",
                "domain3__lte",
                "domain4__lte",
                "domain5__lte",
                "domain6__lte",
                "domain7__lte",
                "domain8__lte",
                "domain9__lte",
                "domain10__lte",
                "domain11__lte",
                "domain12__lte",
                "domain13__lte",
                "domain14__lte",
                "function_group__gte",
                "function_group__lte",
                "points__gte",
                "points__lte",
                "creation_date_after",
                "creation_date_before",
                "modification_date_after",
                "modification_date_before",
                "code",
                "protected",
                "visible",
                "favorite",
                "user__in",
                "describer",
                "applier_one",
                "applier_two"
            ],
            filterLabels: (props) => filterLabels(props)
        }
    },
    bulkOptions: [
        {
           bulkIdentifier: "",
           title: "- Maak een keuze -"
        },
        {
            bulkIdentifier: "export-excel",
            title: i18n.t("Exporteer naar Excel"),
            confirmBody: i18n.t("Weet je zeker dat je van de geselecteerde regels een Excel-bestand wilt maken?"),
            targetUrl: "/client/:client_id/project/:project_id/export/excel",
        },
        {
            bulkIdentifier: "export-pdf",
            title: i18n.t("Exporteer naar PDF"),
            confirmBody: i18n.t("Weet je zeker dat je van de geselecteerde regels een PDF-bestand wilt maken?"),
            targetUrl: "/client/:client_id/project/:project_id/export/pdf",
        },
        {
            bulkIdentifier: "change-status",
            title: i18n.t("Wijzig status van deze functies"),
            Component: FunctionStatusChangeModal,
        },
        {
            bulkIdentifier: "copy-functions",
            title: i18n.t("Kopieer functies naar ander project"),
            Component: CopyFunctionsModal,
        },
        {
            bulkIdentifier: "pdf-function-report",
            title: i18n.t("Gebruik in functierapportage"),
            confirmBody: i18n.t("Weet je zeker dat je van de geselecteerde regels een Functierapportage (PDF-bestand) wilt maken?"),
            targetUrl: "/client/:client_id/project/:project_id/function-report",
        },
        {
            bulkIdentifier: "pdf-project-report",
            title: i18n.t("Gebruik in projectrapportage"),
            confirmBody: i18n.t("Weet je zeker dat je van de geselecteerde regels een Projectrapportage (PDF-bestand) wilt maken?"),
            targetUrl: "/client/:client_id/project/:project_id/report",
        },
        {
            bulkIdentifier: "archive-function",
            title: "Archiveer functies",
            Component: ArchiveFunctions,
            bulkOption: {modal: true},
            allowed: function allowed(props) {
                return props.canArchiveFunction
            }
        }
    ],
    bulkValue: (obj) => obj.id,
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: 'Type',
            identifier: 'type__in',
            type: 'dropdown',
            className: 'overview-table__filter-cell--width-type',
            orderable: true,
            Filter: DropdownFilter,
            options: [
                {
                    title: i18n.t('Referentie'),
                    identifier: 'reference'
                },
                {
                    title: i18n.t('Bedrijfseigen'),
                    identifier: 'company',
                },
                {
                    title: i18n.t('Inpasbare functie'),
                    identifier: 'match',
                },
            ],
            Skeleton: FunctionTypeIndicator,
        },
        {
            title: i18n.t("Naam"),
            identifier: 'name',
            type: "name-kind",
            orderable: true,
            Filter: NameSourcetypeFilter,
            FilterOptions: [
                {
                    title: i18n.t('CAO'),
                    identifier: 'true'
                },
                {
                    title: i18n.t('Niet CAO'),
                    identifier: 'false'
                },
            ]
        },
        {
            type: 'mnt',
            identifier: 'mnt',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: {domains: MNTDomains},
            Filter: MNTFilter,
            FilterOptions: {domains: MNTDomains},
            Skeleton: GroupPatternScore,
        },
        {
            type: 'vbalans',
            identifier: 'vbalans',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: {
                domains: VBalansDomains,
                type: "vbalans",
            },
            Filter: MNTFilter,
            FilterOptions: {
                domains: VBalansDomains,
                type: "vbalans",
            },

        },
        {
            title: 'Stat.',
            identifier: 'description_status__in',
            type: 'status-filter',
            orderable: false,
            className: 'overview-table__filter-cell--width-status',
            Filter: FunctionStatusFilter,
            Skeleton: () => (
                <div className={"overview-table__status"}>
                    <FunctionStatus/>
                </div>
            )
        },
        {
            title: i18n.t('Bijgewerkt'),
            identifier: 'modification_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter,
            "data-cy": "modification-date-filter"
        },
        {
            title: i18n.t('Hoofdafdeling'),
            identifier: 'department',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter
        },
        {
            title: i18n.t('Subafdeling'),
            identifier: 'sub_department',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter
        },
        {
            title: i18n.t('Klant'),
            identifier: 'client__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t('Naam'),
            type: TextFilter,
        },
        {
            title: i18n.t('Project'),
            identifier: 'project__name',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter
        },
        {
            identifier: 'info',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],
}

function filterLabels(props) {
    let filterLabels = [];

    if(Object.keys(props.filterState).some((e => e.includes('domain'))) && Object.keys(props.filterState).some((e => e.includes('gte')))) {
        filterLabels.push(<FilterLabel domainGte={true} key={"domain_gte"}
                                       filters={props.advancedFilter.action.advancedFilters}
                                       setFilter={props.setFilter}
                                       filterState={props.filterState}/>);
    }

    if(Object.keys(props.filterState).some((e => e.includes('domain'))) && Object.keys(props.filterState).some((e => e.includes('lte')))) {
        filterLabels.push(<FilterLabel domainLte={true} key={"domain_lte"}
                                       filters={props.advancedFilter.action.advancedFilters}
                                       setFilter={props.setFilter}
                                       filterState={props.filterState}/>)
    }

    Object.keys(props.filterState).map((filter) => {
        if(props.advancedFilter.action.advancedFilters.includes(filter)) {
            if(filter === "user__in") {
                if(props.filterState[filter].length > 1) {
                    props.filterState[filter].map((userId) => filterLabels.push(<FilterLabel
                        userId={userId} key={filter}
                        filter={filter}
                        filterState={props.filterState}
                        setFilter={props.setFilter}/>))
                } else {
                    filterLabels.push(<FilterLabel oneUser={true} key={filter}
                                                   filter={filter}
                                                   filterState={props.filterState}
                                                   setFilter={props.setFilter}/>)
                }
            }
            if(!filter.includes("domain") && filter !== "user__in") {
                filterLabels.push(<FilterLabel key={filter} filter={filter}
                                               filterState={props.filterState}
                                               setFilter={props.setFilter}/>)
            }
        }
    })

    return filterLabels
}

export const ClientProjectFunctionOverviewDefinition = {
    ...ClientProjectFunctionOverviewDefinitionDefault,
    columns: ClientProjectFunctionOverviewDefinitionDefault.columns.filter(col => !['project__name', 'client__name'].includes(col.identifier)),
}

export const ClientProjectFunctionOverviewDefinitionOwn = {
    ...ClientProjectFunctionOverviewDefinitionDefault,
    identifier: "function-overview-own",
    queryFn: getOwnFunctions,
    columns: ClientProjectFunctionOverviewDefinitionDefault.columns.filter(col => !['project__name', 'client__name'].includes(col.identifier)),
}

export const ClientProjectFunctionOverviewDefinitionOther = {
    ...ClientProjectFunctionOverviewDefinitionDefault,
    identifier: "function-overview-other",
    queryFn: getOtherFunctions,
    columns: ClientProjectFunctionOverviewDefinitionDefault.columns.filter(col => !['department', 'sub_department'].includes(col.identifier)),
}

export const ClientProjectFunctionOverviewDefinitionProjectGroup = {
    ...ClientProjectFunctionOverviewDefinitionDefault,
    identifier: "function-overview-projectgroup",
    title: i18n.t("Projectgroep-functieoverzicht"),
    queryFn: getProjectGroupFunctions,
    columns: ClientProjectFunctionOverviewDefinitionDefault.columns.filter(col => !['department', 'sub_department'].includes(col.identifier)),
}


export function ProjectFunctionOverviewBase(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    let {client_id, project_id} = auth.insightUser ? {
        client_id: auth.user.insight_into_projects[0].client.id,
        project_id: auth.user.insight_into_projects[0].id
    } : useParams();

    let location = useLocation();
    const {data: projectDetail} = getProject(project_id);
    const {data: methodDetail} = useSWR(() => projectDetail?.method?.id ? `/api/methods/${projectDetail?.method?.id}/` : false);

    const [definitionDefault, setDefinitionDefault] = useState(ClientProjectFunctionOverviewDefinition);
    const [definitionOwn, setDefinitionOwn] = useState(ClientProjectFunctionOverviewDefinitionOwn);
    const [definitionOther, setDefinitionOther] = useState(ClientProjectFunctionOverviewDefinitionOther);
    const [definitionProjectGroup, setDefinitionProjectGroup] = useState(ClientProjectFunctionOverviewDefinitionProjectGroup);

    const canViewPoints = auth.hasPermission('base.view_reference_function_points', parseInt(project_id), 'project');
    const canBulkChangeStatus = auth.checkAccessList('base.change_bulk_status', [
        [projectDetail?.id, 'project'],
        [projectDetail?.client?.id, 'client'],
    ]);
    const canArchiveFunction = auth.hasPermission('base.archive_function');

    const canAccessProject = projectDetail?.client?.id ? (
            // path inc client
            auth.checkAccessList('base.view_project', [
                [projectDetail?.id, 'project'],
                [projectDetail?.client?.id, 'client'],
            ])
        )
        :
        (
            //path ex client
            auth.checkAccessList('base.view_project', [
                [projectDetail?.id, 'project'],
            ])
        );

    const canAccessProjectGroup = projectDetail?.client?.id ? (
            // path inc client
            auth.checkAccessList('base.view_projectgroup', [
                [projectDetail?.id, 'project'],
                [projectDetail?.client?.id, 'client'],
            ])
        )
        :
        (
            //path ex client
            auth.checkAccessList('base.view_projectgroup', [
                [projectDetail?.id, 'project'],
            ])
        );

    const canCreateReport = auth.hasPermission('base.can_create_reports');


    const filterFuwater = (columns, methodId, allow = false) => {
        const fuwaterVBalans = isFuwaterVbalans(methodId);
        return columns.filter(col => fuwaterVBalans ? ((col.identifier !== 'type__in' || allow) && col.identifier !== 'mnt') : col.identifier !== 'vbalans');
    }

    const filterMatchOptionFromColumns = (columns, allow = false) => {
        let retColumns = [...columns];
        if(!allow) {
            const typeColumnIndex = columns.findIndex(col => col.identifier === 'type__in');
            if(typeColumnIndex >= 0) {
                retColumns[typeColumnIndex].options = columns[typeColumnIndex].options.filter(col => col.identifier !== 'match');
            }
        }
        return retColumns;

    }

    useEffect(() => {

        if(methodDetail?.id > 0) {
            // if the id changes, re-eval the init logic

            setDefinitionDefault(curState => {
                return {
                    ...curState,
                    columns: filterMatchOptionFromColumns(filterFuwater(ClientProjectFunctionOverviewDefinition.columns, methodDetail?.id, methodDetail?.allow_function_matching), methodDetail?.allow_function_matching),
                    primary: !client_id ? {
                        ...curState?.primary,
                        allowed: false,
                    } : curState.primary,
                }
            });
            setDefinitionOwn(curState => {
                return {
                    ...curState,
                    columns: filterMatchOptionFromColumns(filterFuwater(ClientProjectFunctionOverviewDefinitionOwn.columns, methodDetail?.id, methodDetail?.allow_function_matching), methodDetail?.allow_function_matching),
                    primary: !client_id ? {
                        ...curState?.primary,
                        allowed: false,
                    } : curState.primary,
                }
            });
            setDefinitionOther(curState => {
                return {
                    ...curState,
                    columns: filterMatchOptionFromColumns(filterFuwater(ClientProjectFunctionOverviewDefinitionOther.columns, methodDetail?.id, methodDetail?.allow_function_matching), methodDetail?.allow_function_matching),
                    primary: !client_id ? {
                        ...curState?.primary,
                        allowed: false,
                    } : curState.primary,
                }
            })
            setDefinitionProjectGroup(curState => {
                return {
                    ...curState,
                    columns: filterMatchOptionFromColumns(filterFuwater(ClientProjectFunctionOverviewDefinitionProjectGroup.columns, methodDetail?.id, methodDetail?.allow_function_matching), methodDetail?.allow_function_matching),
                    primary: !client_id ? {
                        ...curState?.primary,
                        allowed: false,
                    } : curState.primary,
                }
            })
        }

    }, [projectDetail?.id, methodDetail?.id]);

    useEffect(() => {
        setDefinitionDefault(curState => {
            return {
                ...curState,
                bulkOptions: ClientProjectFunctionOverviewDefinition.bulkOptions
                    .filter(o => o.bulkIdentifier !== 'pdf-function-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'pdf-project-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'change-status' || canBulkChangeStatus)
                    .filter(o => o.bulkIdentifier !== 'copy-functions' || canBulkChangeStatus)
            }
        });
        setDefinitionOwn(curState => {
            return {
                ...curState,
                bulkOptions: ClientProjectFunctionOverviewDefinitionOwn.bulkOptions
                    .filter(o => o.bulkIdentifier !== 'pdf-function-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'pdf-project-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'change-status' || canBulkChangeStatus)
                    .filter(o => o.bulkIdentifier !== 'copy-functions' || canBulkChangeStatus)
            }
        })
        setDefinitionOther(curState => {
            return {
                ...curState,
                bulkOptions: ClientProjectFunctionOverviewDefinitionOther.bulkOptions
                    .filter(o => o.bulkIdentifier !== 'pdf-function-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'pdf-project-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'change-status' || canBulkChangeStatus)
                    .filter(o => o.bulkIdentifier !== 'copy-functions' || canBulkChangeStatus)
            }
        })
        setDefinitionProjectGroup(curState => {
            return {
                ...curState,
                bulkOptions: ClientProjectFunctionOverviewDefinitionProjectGroup.bulkOptions
                    .filter(o => o.bulkIdentifier !== 'pdf-function-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'pdf-project-report' || (canAccessProject && canCreateReport))
                    .filter(o => o.bulkIdentifier !== 'change-status' || canBulkChangeStatus)
                    .filter(o => o.bulkIdentifier !== 'copy-functions' || canBulkChangeStatus)
            }
        })

    }, [canAccessProject, canBulkChangeStatus, canCreateReport]);


    if(!projectDetail) {
        return <LoaderAnimation/>;
    }
    if(projectDetail?.noAccess) {
        return <AccessDenied/>
    }

    const contextData = Object.assign({}, {
        location,
        client_id,
        project_id,
        canArchiveFunction,
        project: projectDetail,
        method_id: projectDetail?.method?.id
    }, canViewPoints ? {canViewPoints: true} : {});


    if(projectDetail?.is_collective_labour_agreement === true && client_id && projectDetail?.client.id !== parseInt(client_id)) {
        return (
            <Notification type={"warning"} sizeModifier={"large"}
                          text={t("Het lijkt er op dat je een project probeert te benaderen dat niet bij deze klant hoort")}/>
        );
    }

    return (
        <div className={"content-container"}>
            <Quickview>

                <Overview
                    identifier={"project-functions-default"}
                    quickview={{
                        identifier: "default",
                        name: t("Projectfuncties")
                    }}
                    identKey={{client_id, project_id}}
                    contextData={contextData}
                    initialFilters={{
                        description_status__in: ['concept', 'to-counter', 'is-countered', 'determined'],
                        scoring_status__in: ['concept', 'to-counter', 'is-countered', 'determined'],
                    }}
                    definition={definitionDefault}
                />

                <Overview
                    identifier={"project-functions-own"}
                    quickview={{
                        identifier: "project-functions",
                        name: t("Eigen functies")
                    }}
                    identKey={{client_id, project_id}}
                    contextData={contextData}
                    initialFilters={{
                        description_status__in: ['concept', 'to-counter', 'is-countered', 'determined'],
                        scoring_status__in: ['concept', 'to-counter', 'is-countered', 'determined'],
                    }}
                    definition={definitionOwn}
                />

                {/*<Overview*/}
                {/*    identifier={"project-functions-other"}*/}
                {/*    quickview={{*/}
                {/*        identifier: "other-functions",*/}
                {/*        name: t("Beschikbare functies")*/}
                {/*    }}*/}
                {/*    identKey={{client_id, project_id}}*/}
                {/*    contextData={contextData}*/}
                {/*    initialFilters={{*/}
                {/*        description_status__in: ['concept', 'to-counter', 'is-countered','determined'],*/}
                {/*        scoring_status__in: ['concept', 'to-counter', 'is-countered','determined'],*/}
                {/*    }}*/}
                {/*    definition={definitionOther}*/}
                {/*/>*/}

                {canAccessProjectGroup && projectDetail?.project_groups?.length > 0 && (
                    <Overview
                        identifier={"project-functions-projectgroup"}
                        quickview={{
                            identifier: "project-group-functions",
                            name: t("Projectgroep functies")
                        }}
                        identKey={{client_id, project_id}}
                        contextData={contextData}
                        initialFilters={{
                            description_status__in: ['concept', 'to-counter', 'is-countered', 'determined'],
                            scoring_status__in: ['concept', 'to-counter', 'is-countered', 'determined'],
                        }}
                        definition={definitionProjectGroup}
                    />
                )}
            </Quickview>
        </div>
    )
}


// OLD STYLE
// export const ProjectFunctionOverview = withAccessRight(
//     ProjectFunctionOverviewBase,
//     'base.view_project',
//     'project_id',
//     'project'
// );

// NEW STYLE
export const ProjectFunctionOverview = withAccessRight(
    ProjectFunctionOverviewBase,
    'base.view_project',
    [
        ['project_id', 'project'],
        ['client_id', 'client'],
    ]
);


export function InsightProjectFunctionOverview(props) {

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const auth = useAuth();
    let {client_id, project_id} = {
        client_id: auth.user.insight_into_projects?.[0]?.client?.id,
        project_id: auth.user.insight_into_projects?.[0]?.id
    };

    let location = useLocation();
    const projectDetail = getProject(project_id);

    const [fuwaterVBalans, setFuwaterVBalans] = useState(null);
    const [definitionDefault, setDefinitionDefault] = useState(ClientProjectFunctionOverviewDefinition);

    const canViewPoints = auth.hasPermission('base.view_reference_function_points', parseInt(project_id), 'project');

    useEffect(() => {
        // if the id changes, check if fuwater/vbalans
        setFuwaterVBalans(isFuwaterVbalans(projectDetail?.data?.method?.id));
    }, [projectDetail?.data?.method?.id]);

    useEffect(() => {
        // if changes to/from fuwater/vbalans reset the definitions

        setDefinitionDefault({
            ...definitionDefault,
            columns: ClientProjectFunctionOverviewDefinition.columns.filter(col => fuwaterVBalans ? (col.identifier !== 'type__in' && col.identifier !== 'mnt') : col.identifier !== 'vbalans'),
        });
    }, [fuwaterVBalans]);

    if(!projectDetail?.data) {
        return <LoaderAnimation/>;
    }

    const contextData = Object.assign({}, {
        location,
        client_id,
        project_id,
        method_id: projectDetail?.data?.method?.id
    }, canViewPoints ? {canViewPoints: true} : {});

    return (
        <>
            <div
                className={"content-wrapper content-wrapper--white content-wrapper--border-bottom content-wrapper--padding-top"}>
                <div className={"content-container"}>
                    <div className={"meta-info"}>
                        <div className={"meta-info__top"}>
                            <div className={"meta-info__primary"}>
                                <div className={"meta-info__title-container"}>
                                    <h1 className={"meta-info__title"}>{projectDetail?.data?.name}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={"content-container"}>
                <div className={"external-overview-search"}>
                    <span className={"heading-delta"}>{t("Naar welke functie bent u op zoek?")}</span>
                    <div>
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            placeholder={t("Functienaam")}
                            className={"external-overview-search__input"}
                            onChange={(e) => {
                                dispatch(setFilter({
                                    identifier: "insight-functions",
                                    client_id: client_id,
                                    project_id: project_id
                                }, {columnIdentifier: "name", value: e.target.value}))
                            }}
                        />
                        <Icon icon={"search-list"}
                              sizeModifier={"medium"}
                              className={"external-overview-search__icon"}
                        />
                    </div>
                </div>
            </div>

            <div className={"content-container"}>
                <Overview
                    identifier={"insight-functions"}
                    quickview={{
                        identifier: "default",
                        name: t("Projectfuncties")
                    }}
                    identKey={{client_id, project_id}}
                    contextData={{
                        ...contextData,
                        insight: true,
                    }}
                    initialFilters={{}}
                    definition={{
                        ...definitionDefault,
                        bulkOptions: false,
                        primary: {},
                        columns: definitionDefault.columns.filter(col => !["description_status__in", "modification_date", "info"].includes(col.identifier)),
                        noFilter: true,
                        defaultOrderIdentifier: "points",
                    }}
                />
            </div>
        </>
    )
}
