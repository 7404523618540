import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {IconButton} from "../../patterns/molecules/IconButton";

export default function DefaultScreen(props) {

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        const escapeHandler = (e) => {
            if (e.key === "Escape") {
                if (location?.state?.cancel) {
                    navigate(location.state.cancel);
                } else {
                    navigate('../');
                }
            }
        }

        document.addEventListener("keydown", escapeHandler);

        return () => {
            document.removeEventListener("keydown", escapeHandler);
        }
    })

    return (
        <div className={"form-container"}>
            <div className={"form-container__inner"}>
                {props.children}
            </div>
            <IconButton
                icon={"close"}
                className={"form-container__close-button"}
                onClick={() => {
                    if (location?.state?.cancel) {
                        navigate(location.state.cancel);
                    } else {
                        navigate('../');
                    }
                }}
            />
        </div>
    )
}
