import React, {useEffect, useState, useMemo} from "react";
import {InfoButton} from "../../patterns/molecules/InfoButton";
import {getProjects} from "../../queries/client_project";
import {TextFilter} from "./Filters/TextFilter";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {generatePath, useLocation, useParams} from "react-router-dom";
import ClientMeta from "../ClientMeta";
import Overview from "../Overview";
import {ProjectStatusFilter} from "./Filters/StatusFilter";
import {FunctionSource, projectStatus} from "../../utils/constants";
import {DateFilter} from "./Filters/DateFilter";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import IconButton from "../../patterns/molecules/IconButton";
import {UserOverviewDefinition} from "./UserOverview";
import {getUser} from "../../queries/user";
import {useAuth} from "../../utils/useAuth";
import cx from "classnames";
import {UserGroupOverviewDefinition} from "./UserGroupOverview";
import MultiFieldModal from "../../patterns/organisms/MultiFieldModal";
import {remoteSetUserGroup} from "../../queries/usergroups";
import TruncateMiddle from "../../utils/TruncateMiddle";
import i18n from "../../utils/i18n";
import MethodFilter from "./Filters/MethodFilter";


export const DashboardRowStructure = (tempProps) => {

    const {t, i18n} = useTranslation();

    let props = Object.assign(
        {},
        tempProps,
    );

    // FIXME: we fallback to client id 0 when there is no info because migration is not yet great.
    const detailUrl = generatePath('/client/:client_id/project/:project_id/function', { client_id: props?.client?.id || 0 , project_id: props.id});

    const detailClick = props?.onClick ? () => props.onClick(props) : (e) => {
        props.navigate( detailUrl );

    }

    const {interactive} = props;

    const lengths = {
        small: 14,
        medium: 14,
        large: 11,
        xlarge: 11,
        full: 11,
    }

    return (
        <tr key={props.id} className={"overview-table__row"}>

            {props.columns.some(col => col.identifier === 'client__name') && (
                <td className={"overview-table__cell overview-table__cell--interactive"}
                    onClick={() => {
                        props?.onClick ? props.onClick(props) : props?.client && props.navigate(generatePath('/client/:client_id/', {
                            client_id: props.client.id,
                        }));
                    }}
                >
                    <div className={"overview-table__text"} title={props.client?.name}>
                        <TruncateMiddle length={lengths[props.breakpoint]} secondary_length={lengths[props.breakpoint]} subst={'…'}>
                            { props?.client?.name ? props.client.name : "" }
                        </TruncateMiddle>
                    </div>
                </td>
            )}

            {props.columns.some(col => col.identifier === 'name') && (
                <td title={props.name} onClick={ detailUrl ? detailClick : () => false } className={cx("overview-table__cell overview-table__cell--name", {"overview-table__cell--interactive": interactive})}>
                    <TruncateMiddle length={lengths[props.breakpoint] + 5 } secondary_length={lengths[props.breakpoint] - 5} subst={'…'}>
                        { props.name ? props.name : "" }
                    </TruncateMiddle>
                    {props.is_collective_labour_agreement && (<span className={"overview-table__tag status-tag status-tag--cao"}>{t(FunctionSource['cao'].short)}</span>)}
                </td>
            )}
        </tr>
    )

}

export const ClientProjectOverviewDefinition = {

    title: i18n.t("Projectoverzicht"),
    identifier: 'project-overview',
    interactive: true,
    loadAllAllowed: true,
    queryFn: getProjects,
    bulkValue: (obj) => obj.id,
    RowStructure: function (tempProps) {

        const {t, i18n} = useTranslation();

        let props = Object.assign(
            {},
            tempProps,
        );

        const modDate = props?.modification_date  ? new Intl.DateTimeFormat("nl-NL", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        }).format(new Date(props.modification_date)) : "-";

        // FIXME: we fallback to client id 0 when there is no info because migration is not yet great.
        const detailUrl = generatePath('/client/:client_id/project/:project_id/function', { client_id: props?.client?.id || 0 , project_id: props.id});

        const detailClick = props?.onClick ? () => props.onClick(props) : (e) => {
            props.navigate( detailUrl );

        }

        const {interactive} = props;

        const lengths = {
            small: 15,
            medium: 20,
            large: 25,
            xlarge: 30,
            full: 35,
        }

        return (
            <tr key={props.id} className={"overview-table__row"}>

                {props?.bulkColumn && props.bulkColumn }

                {props.columns.some(col => col.identifier === 'client__name') && (
                    <td className={"overview-table__cell overview-table__cell--interactive"}
                        onClick={() => {
                            props?.onClick ? props.onClick(props) : props.navigate(generatePath('/client/:client_id/', {
                                client_id: props.client.id,
                            }));
                        }}
                    >
                        <div className={"overview-table__text"} title={props.client?.name}>
                            {props.client?.name}
                        </div>
                    </td>
                )}

                {props.columns.some(col => col.identifier === 'name') && (
                    <td onClick={ detailUrl ? detailClick : () => false } data-cy="overview-table-cell-project-name" className={cx("overview-table__cell overview-table__cell--name", {"overview-table__cell--interactive": interactive})}>
                        <TruncateMiddle length={lengths[props.breakpoint]} secondary_length={lengths[props.breakpoint]} subst={'…'}>
                            { props.name ? props.name : "" }
                        </TruncateMiddle>
                        {props.is_collective_labour_agreement && (<span className={"overview-table__tag status-tag status-tag--cao"}>{t(FunctionSource['cao'].short)}</span>)}
                    </td>
                )}
                {props.columns.some(col => col.identifier === 'source_project__name') && (
                    <td className={"overview-table__cell"} >
                        <div className={"overview-table__text"} title={props.source_project?.name}>
                            {props?.source_project?.name || ""}
                        </div>
                    </td>
                )}
                {props.columns.some(col => col.identifier === 'method__name') && (
                    <td className={"overview-table__cell"}>{ props.method?.name ? props.method.name : ''}</td>
                )}
                {props.columns.some(col => col.identifier === 'modification_date') && (
                    <td className={"overview-table__cell"}>{ modDate } </td>
                )}
                {props.columns.some(col => col.identifier === 'status__in') && (
                    <td className={"overview-table__cell"}>
                        {props.status && (
                            <span className={cx("meta-info__status-tag status-tag", {
                                "status-tag--active": props?.status === 'determined',
                                "status-tag--negative": props?.status === 'expired',
                            })}>{projectStatus[props.status]}</span>
                        )}
                    </td>
                )}
                {props.columns.some(col => col.identifier === 'action' && props.contextData?.canAddProjectgroup) && (
                    <td className={"overview-table__cell"}>
                        <IconButton title={"Verwijder koppeling"} icon={"trash"}  sizeModifier={"medium"} onClick={() => props?.contextData?.removeProject(props)}/>
                    </td>
                )}
            </tr>
        )
    },
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: 'Klant',
            orderable: true,
            identifier: 'client__name',
            className:'overview-table__filter-cell--width-large',
            placeholder: "Naam",
            filter: TextFilter,
        },
        {
            title: 'Projectnaam',
            orderable: true,
            identifier: 'name',
            className:'',
            placeholder: "Naam",
            filter: TextFilter,
            FilterOptions: {
                "data-cy": "project-name-text-filter"
            }
        },
        {
            title: 'Bronproject',
            orderable: false,
            identifier: 'source_project__name',
            className:'overview-table__filter-cell--width-xlarge',
            Filter: TextFilter,
        },
        {
            title: 'Methode',
            orderable: true,
            identifier: 'method__name',
            className:'overview-table__filter-cell--width-large',
            placeholder: "Naam",
            Filter: MethodFilter,
        },
        {
            title: 'Bijgewerkt',
            identifier: 'modification_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date-time',
            Filter: DateFilter
        },
        {
            title: 'Status',
            identifier: 'status__in',
            type: 'status-filter',
            orderable: false,
            className: 'overview-table__filter-cell--width-medium',
            Filter: ProjectStatusFilter
        },
        {
            identifier: 'action',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
        // {
        //     identifier: 'info',
        //     className: 'overview-table__filter-cell--width-icon-button',
        //     Title: EmptyTitle,
        //     Filter: EmptyFilter
        // }
    ],
}

export default function ClientProjectOverview(props) {

    const {t, i18n} = useTranslation();
    let { client_id } = useParams();
    let location = useLocation();


    const auth = useAuth();
    const hasCreateProjectPrimary = auth?.checkAccessList("base.add_project", [
        ['client_id', 'client'],
    ]);

    return (
        <>
            <ClientMeta />

            <div className={"content-container"}>

                <Quickview>

                <Overview
                    identifier={"client-projects"}
                    quickview={{
                        identifier: "client-projects",
                        name: t("Projecten")
                    }}
                    identKey={{client_id}}
                    contextData={{
                        location,
                        client_id,
                    }}
                    definition={{
                        ...ClientProjectOverviewDefinition,
                        columns: ClientProjectOverviewDefinition.columns.filter(o => o.identifier !== 'action' && o.identifier !== 'client__name'),
                        primary: {
                            title: t("Project aanmaken"),
                            identifier: 'create-project',
                            link: generatePath('/client/:client_id/project/selector', {client_id}),
                            allowed: hasCreateProjectPrimary
                        }
                    }}
                    initialFilters={{
                        status__in: ['concept', 'determined']
                    }}
                />

                </Quickview>
            </div>
        </>
    )
}
