import React from "react";
import FullNameFromUserIdComponent from "./FullNameFromUserIdComponent";
import {useTranslation} from "react-i18next";

export default function FilterLabel(props) {
    const {t} = useTranslation();

    const labels = {
        function_group__gte: "Groepbereik vanaf",
        function_group__lte: "Groepbereik tot",
        points__gte: "Puntenbereik vanaf",
        points__lte: "Puntenbereik tot",
        creation_date_after: "Datum aangemaakt vanaf",
        creation_date_before: "Datum aangemaakt tot",
        modification_date_after: "Laatst bewerkt vanaf",
        modification_date_before: "Laatst bewerkt tot",
        type: props.filterState[props.filter],
        code: "Functiecode",
        protected: "Beschermd",
        visible: "Onzichtbaar",
        favorite: "Favoriet",
        describer: "Beschrijver",
        applier_one: "Toepasser",
        applier_two: "Toetser"
    }

    function RemoveButton(props) {
        return(
            <button className={"overview-filter__remove"} onClick={props.onClick}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     className={"icon icon--xsmall"}>
                    <use href={`#close`}/>
                </svg>
            </button>
        )
    }

    if(props.userId) {
       return (
            <div className={"overview-filter"} key={props.userId}>
                <span className={"overview-filter__label"}>
                    <FullNameFromUserIdComponent userId={props.userId} />
                </span>
                <RemoveButton onClick={() => {
                    props.setFilter(props.filter, props.filterState[props.filter].filter(v => v !== props.userId))
                }}/>
            </div>
       )
    }

    if(props.oneUser) {
        return (
            <div className={"overview-filter"}>
                <span className={"overview-filter__label"}>
                    <FullNameFromUserIdComponent userId={props.filterState[props.filter]} />
                </span>
                <RemoveButton onClick={() => {
                    props.setFilter(props.filter, "")
                }}/>
            </div>
        )
    }

    if (props.domainGte) {
        return (
            <div className={"overview-filter"}>
                <span className={"overview-filter__label"}>{t("Patroonbereik Vanaf")}</span>
                <RemoveButton onClick={() => {
                    props.filters.map((filter) =>{
                        if(filter.includes("domain") && filter.includes("gte")){
                            props.setFilter(filter, "")
                        }
                    })
                }}/>
            </div>
        )
    }

    if (props.domainLte) {
        return (
            <div className={"overview-filter"}>
                <span className={"overview-filter__label"}>{t("Patroonbereik Tot")}</span>
                <RemoveButton onClick={() => {
                    props.filters.map((filter) =>{
                        if(filter.includes("domain") && filter.includes("lte")){
                            props.setFilter(filter, "")
                        }
                    })
                }}/>
            </div>
        )
    }

    return(
        <div className={"overview-filter"}>
            <span className={"overview-filter__label"}>{t(labels[props.filter] || props.filterState[props.filter])}</span>
            <RemoveButton onClick={() => {
                props.setFilter(props.filter, "")
            }}/>
        </div>
    )
}
