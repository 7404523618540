import Overview from "../Overview";
import {ProjectGroupOverviewDefinition} from "../Overviews/ProjectGroupOverview";
import React from "react";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import MultiFieldModal from "../../patterns/organisms/MultiFieldModal";
import {remoteAddProjectGroup} from "../../queries/project_group";
import {useAuth} from "../../utils/useAuth";

export function ProjectGroupOverviewScreen(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const canCreateProjectGroup = auth.hasPermission('base.add_projectgroup');

    return (
        <div className={"content-container"}>

            <Quickview>

                <Overview
                    identifier={"projectgroups"}
                    quickview={{
                        identifier: "projectgroups",
                        name: t("Projectgroepen")
                    }}
                    definition={{
                        ...ProjectGroupOverviewDefinition,
                        primary: {
                            title: t("Projectgroep aanmaken"),
                            identifier: 'create-projectgroup',
                            allowed: canCreateProjectGroup,
                            action: {
                                Component: MultiFieldModal,
                                remoteCall: o => {
                                    remoteAddProjectGroup(o.name, o.sharing_method);

                                },
                                fields:[
                                    {
                                        name: "name",
                                        label: t("Projectgroep"),
                                        placeholder: t("Naam van de groep")
                                    },
                                    {
                                        name: "sharing_method",
                                        label: t("Type"),
                                        options: [
                                            {
                                                identifier: "automatic",
                                                value: t("Automatisch")
                                            },{
                                                identifier: "manual",
                                                value: t("Handmatig")
                                            },
                                        ]
                                    }
                                ]
                            }
                        }
                    }}
                />
            </Quickview>
        </div>
    )
}
