import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Overview from "../../components/Overview";
import SingleFieldModal from "./SingleFieldModal";
import {remoteSetDepartment} from "../../queries/department";
import {DepartmentSelectionDefinition} from "../../components/Overviews/DepartmentSelection";
import {withAccessRight} from "../../utils/withAccessHoC";
import {UserGroupFormItem} from "../../components/UserGroupFormItemNew";
import {AccessDenied} from "./AccessDenied";
import {helpText, isFuwaterVbalans} from "../../utils/constants";
import Notification from "../molecules/Notification";
import {Controller, useForm} from "react-hook-form";
import Select from "../atoms/Select";
import {withHook} from "../../utils/withHookHoC";
import {useFunctionEdit} from "../../components/FunctionEdit";
import {Input} from "../atoms/Input";
import Drawer from "../../components/Drawer";
import {editFunction} from "../../queries/function_detail";
import {useNavigate} from "react-router-dom";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const FunctionEditPatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function FunctionEditDisplay(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {register, handleSubmit, setError, control, clearErrors, setValue, formState: {errors}} = useForm();

    useEffect(() => {
        if(props.current_function) {
            setValue("faux-type", (isFuwaterVbalans(props.project?.method?.id)) ? 'reference' : props.current_function?.type || (props.type_options?.some(o => o.value === 'company') ? 'company' : props.type_options?.[0]?.value || 'reference'));
            setValue("name", props.current_function?.name);
            setValue("code", props.current_function?.code);
            setValue("additional_information", props.current_function?.additional_information);
            setValue("department_id", props.current_function?.department?.id);
            setValue("sub_department_id", props.current_function?.sub_department?.id);
            setValue("describer_group_id", props.current_function?.describer_group?.id);
            setValue("applier_one_group_id", props.current_function?.applier_one_group?.id);
            setValue("applier_two_group_id", props.current_function?.applier_two_group?.id);
        }
    }, [props.current_function])

    if (!props?.canChangeIfCollectiveLabourAgreement) {
        return <AccessDenied additionalText={t("Onvoldoende rechten om een functie binnen dit project te wijzigen")}/>;
    }
    if (!props?.canChangeIfProtected) {
        return <AccessDenied
            additionalText={t("Deze functie mag niet gewijzigd worden omdat die gemarkeerd is als 'beschermd'")}/>;
    }

    const onSubmit = () => async (values) => {
        await editFunction(values, navigate, props.function_id);
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Functie bewerken")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error.cause.message === "server-error-function-edit" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <section className={"form-meta"}>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Project")}</span>
                        <span className={"form-meta__description"}
                              data-cy={"project-name"}>{props?.project?.name}</span>
                    </div>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Klant")}</span>
                        <span className={"form-meta__description"} data-cy={"client-name"}>{props.client?.name}</span>
                    </div>
                </section>


                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Functiegegevens")}</legend>

                    <div className="input-group">
                        <Select
                            name={"faux-type"}
                            type={"select"}
                            options={props.type_options?.filter(o => o.value !== 'match' || (o.value === "match" && props?.method?.allow_function_matching === true))}
                            id={"faux-type"}
                            label={t("Functietype")}
                            readOnly={true}
                            disabled={true}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Functienaam")}
                            placeholder={t("Functienaam")}
                            data-cy={"name-input"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"code"}
                            type={"text"}
                            id={"code"}
                            label={t("Functiecode")}
                            placeholder={t("Functiecode")}
                            register={register}
                            error={errors.code}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"additional_information"}
                            type={"text"}
                            id={"additional_information"}
                            label={t("Aanvullende informatie")}
                            placeholder={t("Aanvullende informatie over deze functie...")}
                            register={register}
                            error={errors.additional_information}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Overige gegevens")}</legend>

                    <div className="input-group">
                        <Controller name={"department_id"} control={control}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={"Selecteer hoofdafdeling"}
                                            label={t("Hoofdafdeling")}
                                            initialReadOnlyVal={props?.department?.name}
                                            placeholder={t("Geen hoofdafdeling gekozen")}
                                            readOnlyVal={obj => obj.name}
                                            id={"department_id_drawer"}
                                            disabled={false}
                                            action={{
                                                title: "Nieuwe afdeling aanmaken",
                                                Component: SingleFieldModal,
                                                remoteCall: name => remoteSetDepartment(props.client_id, name),
                                                field: {
                                                    name: "department",
                                                    label: t("Afdeling"),
                                                    placeholder: t("Naam van de afdeling")
                                                }
                                            }}
                                            isForm={true}
                                            reset={() => {
                                                onChange(null)
                                            }}
                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}
                                            contentComponent={
                                                <Overview
                                                    identifier={"select-department-overview"}
                                                    identKey={{
                                                        client_id: props.client_id,
                                                        project_id: props.project_id
                                                    }}
                                                    contextData={{
                                                        location,
                                                        client_id: props.client_id,
                                                        project_id: props.project_id,
                                                    }}
                                                    definition={DepartmentSelectionDefinition}
                                                />
                                            }
                                        />
                                    )}/>
                    </div>

                    <div className="input-group">
                        <Controller name={"sub_department_id"} control={control}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={"Selecteer subafdeling"}
                                            label={t("Subafdeling")}
                                            initialReadOnlyVal={props?.sub_department?.name}
                                            placeholder={t("Geen subafdeling gekozen")}
                                            readOnlyVal={obj => obj.name}
                                            id={"sub_department_id_drawer"}
                                            disabled={false}
                                            action={{
                                                title: "Nieuwe afdeling aanmaken",
                                                Component: SingleFieldModal,
                                                remoteCall: name => remoteSetDepartment(props.client_id, name),
                                                field: {
                                                    name: "department",
                                                    label: t("Afdeling"),
                                                    placeholder: t("Naam van de afdeling")
                                                }
                                            }}
                                            isForm={true}
                                            reset={() => {
                                                onChange(null)
                                            }}
                                            onClick={(obj) => {
                                                onChange(obj.id);
                                            }}
                                            contentComponent={
                                                <Overview
                                                    identifier={"select-sub-department-overview"}
                                                    identKey={{
                                                        client_id: props.client_id,
                                                        project_id: props.project_id
                                                    }}
                                                    contextData={{
                                                        location,
                                                        client_id: props.client_id,
                                                        project_id: props.project_id,
                                                    }}
                                                    definition={DepartmentSelectionDefinition}
                                                />
                                            }
                                        />
                                    )}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <Controller name={"describer_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.describer_group}
                                        client_id={props.client?.id}
                                        id_prefix={"describer_group"}
                                        label={"Beschrijver"}
                                        helpTitle={"Wat is een \"beschrijver\"?"}
                                        helpText={t(helpText.beschrijver)}
                                        onChange={onChange}
                                        error={errors.describer_group}
                                    />
                                )}
                    />

                    <Controller name={"applier_one_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.applier_one_group}
                                        client_id={props.client?.id}
                                        id_prefix={"applier_one_group"}
                                        label={"Toepasser"}
                                        helpTitle={t("Wat is een \"toepasser\"?")}
                                        helpText={t(helpText.toepasser)}
                                        onChange={onChange}
                                        error={errors.applier_one_group}
                                    />
                                )}
                    />

                    <Controller name={"applier_two_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.applier_two_group}
                                        client_id={props.client?.id}
                                        id_prefix={"applier_two_group"}
                                        label={"Toetser"}
                                        helpTitle={t("Wat is een \"Toetser\"?")}
                                        helpText={t(helpText.toetser)}
                                        onChange={onChange}
                                        error={errors.applier_two_group}
                                    />
                                )}
                    />

                    {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                    text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"function-edit-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const FunctionEdit = withAccessRight(
    withHook(
        FunctionEditDisplay,
        useFunctionEdit
    ),
    'base.change_function',
    [
        ['project_id', 'project'],
        ['client_id', 'client']
    ]
)
