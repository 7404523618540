import React from 'react';
import cx from "classnames";

export const PatternPatternState = {
    pattern_list: [
        { score: 'H', points:70 },
        { score: 'F', points:38 },
        { score: 'F', points:34 },
        { score: 'X', points:0 },
        { score: 'E', points:20 },
        { score: 'A', points:8 },
        { score: 'H', points:123},
        { score: 'G', points:50 },
        { score: 'G', points:60 },
    ],
    comparison: false,
    plMod: {
        comparison: {
            comparison: true,
            pattern_list: [
                {score: 'H', points:123 },
                {score: 'G', points:115 },
                {score: 'G', points:115 },
                {score: 'X', points:0 },
                {score: 'F', points:123 },
                {score: 'A', points:12 },
                {score: 'H', points:123 },
                {score: 'G', points:120 },
                {score: 'X', points:0 },
            ],
        },
        points: {
            comparison: false,
            displayPoints: true,
            pattern_list: [
                {score: 'H', points:70 },
                {score: 'F', points:38 },
                {score: 'F', points:34 },
                {score: 'X', points:0 },
                {score: 'E', points:20 },
                {score: 'A', points:8 },
                {score: 'H', points:123 },
                {score: 'G', points:50 },
                {score: 'G', points:60 },
            ],
        },
        fuwater: {
            comparison: false,
            displaypoints: true,
            pattern_list: [
                {score: '1', points:1 },
                {score: '2', points:2 },
                {score: '1', points:1 },
                {score: '2', points:2 },
                {score: '1', points:1 },
                {score: '3', points:3 },
                {score: '3', points:3 },
                {score: '1', points:1 },
                {score: '2', points:2 },
                {score: '1', points:1 },
                {score: '2', points:2 },
                {score: '1', points:1 },
                {score: '1', points:1 },
                {score: '2', points:1 },
            ]
        }
    }
}

// props should contain comparison:true if comparison should be done at all
export default function Pattern(props) {

    return (
        <div className={"pattern-pattern-with-points"}>
            <div className={cx("pattern-pattern", {"pattern-pattern--vbalans": props.pattern_list.length > 12})}>
                {props.pattern_list.map( (obj, key) => {
                    const {score, comparison} = obj;
                    return (
                        <span
                            data-cy={"pattern-" + key}
                            className={
                                cx("pattern-pattern__domain", {
                                    ["pattern-pattern__domain--comparison-" + comparison]: props.comparison
                                })}
                            key={key}>{score}</span>
                    )
                })}
            </div>
            {props?.displayPoints && props.pattern_list.length <= 12 && (
                <div className={cx("pattern-domain-points")}>
                    <>
                        {props.pattern_list.map( (obj, key) => {
                            const {score, points} = obj;
                            return (
                                <span key={key} className={"pattern-domain-points__domain"}>{points}</span>
                            )
                        })}
                    </>
                </div>
            )}
        </div>
    )
}
