import { ADD_ITEM } from "../actions/header";

import { HeaderDefaultState } from "../components/Header";

export function HeaderState(state=HeaderDefaultState, action) {

    let ret = {
        ...state,
    }

    switch(action.type) {

        case ADD_ITEM:
            let items = state.items;
            items.push(action.data);
            ret = {
                ...ret,
                items,
            }
            return ret;
        default:
            return ret;
    }
}

