import React from "react";
import cx from "classnames";
import {environmentStatus} from "../utils/constants";

export default function StatusComponent(props) {
    return (
        <div className={cx({
            "environment-status--active": props.status === "active",
            "environment-status--inactive": props.status === "inactive",
            "environment-status--closed": props.status === "closed",
            "environment-status--error": props.status === "error",
            "environment-status--starting": props.status === "starting"
        })}>
            <div className={"environment-status"}>{environmentStatus[props.status]}</div>
        </div>
    )
}
