import React, {useState as useStateReact} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import {editorConfiguration} from "../../components/RichtextManager";

export default function Note(props) {

    const [ noteActive, setNoteActive] = useStateReact(false);

    return (
        <>
            <button className={"expand-button expand-button--inline"}
                    onClick={() => setNoteActive(!noteActive)}>{t("Persoonlijke notitie")}</button>
            {noteActive && (
                <div className="note">
                    <CKEditor
                        editor={InlineEditor}
                        config={editorConfiguration}
                        data={props.note}
                        onChange={(e, editor) => {
                            console.log("Note for score identifier: "+ props.identifier)
                            // setData(idx, editor.getData());
                        }}
                  onReady={ editor => {
                      // console.log("attaching");
                      // CKEditorInspector.attach( editor );
                  }}
                />
            </div>
          )}
      </>
    )
}
