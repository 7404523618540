import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import Drawer from "../../components/Drawer";
import Overview from "../../components/Overview";
import {ClientSelectionDefinition} from "../../components/Overviews/ClientSelection";
import {useTranslation} from "react-i18next";
import {createProject} from "../../queries/project_detail";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Notification from "../molecules/Notification";
import {getClient} from "../../queries/client_detail";
import {ProjectSelectionDefinition} from "../../components/Overviews/ProjectSelection";
import {Input} from "../atoms/Input";
import ButtonTertiary from "../atoms/ButtonTertiary";
import ConfirmModal from "./ConfirmModal";

export default function ProjectCreateCao() {
    const {client_id} = useParams();
    const client = getClient(client_id).data;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + 1);

    const [customSettings, setCustomSettings] = useState(false);

    const {
        register,
        handleSubmit,
        clearErrors,
        setError,
        control,
        setValue,
        reset,
        watch,
        getValues,
        formState: {errors}
    } = useForm({
        defaultValues: {
            client_id: client_id,
            source_project_usage: "based_on",
            source_project_function_group_structure_usage: "follow",
            source_project_force_function_group_structure: false,
            method_functions_as_reference: false,
            base_project_on: true,
            license: {
                license_type: 9,
                end_datetime: currentDate
            }
        }
    });

    const onSubmit = () => async (values) => {
        await createProject(values, navigate);
    }

    if(customSettings) {
        return (
            <ConfirmModal
                title={t("Handmatig instellen")}
                body={t("Weet u zeker dat je de project instellingen handmatig wilt instellen? U kunt hierna niet meer terug naar het huidige formulier.")}
                closeModal={() => setCustomSettings(false)}
                confirmModal={() => {
                    if(client_id) {
                        navigate(`/client/${client_id}/project/selector/create`, {state: getValues()})
                    } else {
                        navigate("/project/selector/create", {state: getValues()})
                    }
                }}
            />
        )

    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Project aanmaken")}</h1>

            <section className={"form-meta"}>
                <div className={"form-meta__item"}>
                    <span className={"form-meta__label"}>Type project</span>
                    <span className={"form-meta__description"}>{t("Cao-project")}</span>
                </div>
            </section>
            <form onSubmit={(e) => {
                clearErrors(["server"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if(error.cause.message === "server-error-project-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>
                    <div className={"input-group"}>
                        <Controller name={"client_id"} control={control}
                                    rules={{required: t("Dit is een verplicht veld")}}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer Klant")}
                                            buttonText={t("Zoek klant")}
                                            label={t("Klant")}
                                            initialReadOnlyVal={client?.name}
                                            readOnlyVal={obj => obj.name}
                                            placeholder={t("Geen klant gekozen")}
                                            disabled={false}
                                            id={"client-drawer"}
                                            error={errors.client_id}
                                            required

                                            isForm={true}

                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-client-overview"}
                                                    identKey={{
                                                        client_id: watch("client_id"),
                                                    }}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ClientSelectionDefinition}
                                                />
                                            }
                                        />)}
                        />
                    </div>

                    <div className="input-group">
                        <Controller name={"source_project_id"} control={control}
                                    rules={{required: t("Dit is een verplicht veld")}}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer bron cao project")}
                                            buttonText={t("Zoek bron cao project")}
                                            id={"source-project-drawer"}
                                            label={t("Bron cao project")}
                                            readOnlyVal={obj => obj.name}
                                            disabled={false}
                                            error={errors.source_project_id}
                                            placeholder={t("Geen bron cao project gekozen")}
                                            required

                                            isForm={true}

                                            reset={() => {
                                                onChange(null);
                                                setValue("method_id", null);
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                                setValue("method_id", obj?.method?.id)
                                                setValue("source_project_name", obj?.name)
                                                setValue("source_project_force_function_group_structure", obj.force_function_group_structure)
                                                setValue("name", obj?.name)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-project-overview-global"}
                                                    quickview={{
                                                        identifier: "projects",
                                                        name: t("Alle projecten")
                                                    }}
                                                    identKey={{is_collective_labour_agreement: true}}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ProjectSelectionDefinition}
                                                    initialFilters={{
                                                        status__in: ['concept', 'determined']
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Projectnaam")}
                            placeholder={t("Naam van dit project")}
                            data-cy={"project-name-input"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Projectinstellingen")}</legend>

                    <section className={"form-meta"}>
                        <a className={"form-meta__link"}
                           onClick={() => {
                               setCustomSettings(true)
                           }
                           }>{t("Handmatig instellen")}</a>
                        <div className={"form-meta__item"}>
                            <div className={"form-meta__description"}>
                                <h2 className={"form-meta__description-heading"}>Automatisch toegewezen
                                    instellingen</h2>
                                <ul className={"default"}>
                                    <li>{t("Functiegroepenstructuur uit bronproject wordt")}&nbsp;
                                        <strong><em>{t("afgedwongen")}</em></strong></li>
                                    <li>{t("Functies uit bronproject worden")}&nbsp;
                                        <strong><em>{t("gevolgd")}</em></strong></li>
                                    <li>{t("Methode-eigen functies zijn")}&nbsp;
                                        <strong><em>{t("niet beschikbaar als referentiefuncties")}</em></strong></li>
                                    <li>{t("Basis licentie met einddatum")}&nbsp;{`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`}</li>
                                </ul>
                            </div>
                        </div>
                    </section>


                    {errors.server &&
                        <Notification className={"form-container__error"} type={"error"}
                                      text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <div className={"form-container__buttons"}>
                        <ButtonTertiary
                            text={t("Annuleren")}
                            fullWidth={true}
                            onClick={() => {
                                navigate('../');
                            }}
                        />
                        <ButtonPrimary
                            text={t("Aanmaken")}
                            fullWidth={true}
                            data-cy={"create-project-submit-button"}
                        />
                    </div>
                </fieldset>
            </form>
        </>
    );
}
