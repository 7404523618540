import {
    SET_OPTION,
    SET_PAGE,
    SET_DIRECTION,
    SET_FILTER,
    SET_FILTERS,
    CLEAR_FILTER,
    CLEAR_FILTERS,
    SET_ORDER,
    INIT_STATE,
} from "../actions/overview";

const defaultState = {
}

export function OverviewState(state=defaultState, action) {
    const stateIdentifier = JSON.stringify(action.stateIdentifier || action.identifier);

    let overviewState = {
        ...state[stateIdentifier],
    }

    switch (action.type) {

        case INIT_STATE:
            overviewState = action.initialState;
            break;

        case SET_DIRECTION:
            overviewState = {
                ...overviewState,
                direction: action.data
            }
            break;

        case SET_ORDER:
            overviewState = {
                ...overviewState,
                ordering: action.data
            }

            break;

        case SET_PAGE:
            overviewState = {
                ...overviewState,
                page: action.data
            }

            break;
        case CLEAR_FILTER:
            overviewState = {
                ...overviewState,
                filter: {}
            }
            break;
        case SET_FILTER:
            overviewState = {
                ...overviewState,
                filter: {
                    ...overviewState?.filter,
                    [action.data.columnIdentifier || action.data.identifier]: action.data.value,
                }
            }
            if(action.data.value === "") {
                delete overviewState.filter[action.data.columnIdentifier || action.data?.identifier];
            }
            break;

        case SET_FILTERS:
            const filters = action.filters
            overviewState = {
                ...overviewState,
                filter: {
                    ...overviewState?.filter,
                    ...filters
                }
            }

            Object.keys(filters).map((filter) => {
                if(filters[filter] === "") {
                    delete overviewState.filter[filter]
                }
            })
            break;

        case CLEAR_FILTERS:
            const resetFilters = action.filters
            Object.keys(resetFilters).forEach((filter) => {
                delete overviewState.filter[resetFilters[filter]]
            })
            break;

        case SET_OPTION:
            overviewState = Object.assign({}, overviewState, action.data);
            break;

        default:
            return state;
    }

    return {
        ...state,
        [stateIdentifier]: overviewState
    }
}
