import React from "react";
import {DropdownFilter} from "../../../patterns/molecules/DropdownFilter";
import {getMethods} from "../../../queries/method_detail";

export default function MethodFilter(props) {
    const methods = getMethods().data?.results;

    const options = methods?.map((method) => {
        return {
            title: method.name,
            identifier: method.id.toString()
        }
    })

    return (
        <DropdownFilter
            {...props}
            options={options}
            identifier={'method__id__in'}
        />
    )
}
