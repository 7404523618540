import React from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {RadioInputs} from "../atoms/Input";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {useNavigate, useParams} from "react-router-dom";
import Notification from "../molecules/Notification";
import ButtonTertiary from "../atoms/ButtonTertiary";

export default function ProjectCreateSelector() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {client_id} = useParams();
    const {register, handleSubmit, setValue, formState: {errors}} = useForm();

    function onSubmit(data) {
        if (data.project_create === "project") {
            if (client_id) {
                navigate(`/client/${client_id}/project/selector/create`)
            } else {
                navigate("/project/selector/create")
            }
        }

        if (data.project_create === "cao-project") {
            if (client_id) {
                navigate(`/client/${client_id}/project/selector/create-cao`)
            } else {
                navigate("/project/selector/create-cao")
            }
        }
    }

    const options = [
        {
            id: "project",
            name: "Nieuw project"
        },
        {
            id: "cao-project",
            name: "CAO-project"
        }
    ]

    return (
        <>
            <h1 className={"form-container__title"}>{t("Project aanmaken")}</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend form-container__legend--left-aligned"}>{t("Wat voor soort project wilt u aanmaken?")}</legend>
                    <RadioInputs
                        id={"project_create"}
                        name={"project_create"}
                        options={options}
                        label={t("Soort project")}
                        error={errors.project_create}
                        register={register}
                        setValue={setValue}
                        required
                    />

                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }

                    <div className={"form-container__buttons"}>
                        <ButtonTertiary
                            text={t("Annuleren")}
                            // fullWidth={true}
                            onClick={() => {
                                navigate('../');
                            }}
                        />
                        <ButtonPrimary
                            text={t("Bevestigen")}
                            // fullWidth={true}
                            data-cy={"create-project-submit-button"}
                        />
                    </div>
                </fieldset>
            </form>
        </>
    );
}
