import React from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {useLicenseCreate} from "../../components/LicenseRequestCreate";
import {withHook} from "../../utils/withHookHoC";
import SVG from "../../utils/SVG";
import {useForm} from "react-hook-form";
import {Input} from "../atoms/Input";
import Select from "../atoms/Select";
import Notification from "../molecules/Notification";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const LicenseCreatePatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function LicenseCreateDisplay(props) {
    const {t} = useTranslation();
    const {register, handleSubmit, setError, clearErrors, watch, formState: {errors}} = useForm();

    return (
        <>
            <SVG src={"logo-humancapitalgroup.svg"} className={"form-container__logo"}/>
            <h1 className={"form-container__title"}>{t("Licentie aanvragen")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(props.onSubmit)(e).catch((error) => {
                    if (error.cause.message === "server-error-license-request-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>

                    <div className="input-group">
                        <Input
                            name={"company_name"}
                            type={"text"}
                            id={"company_name"}
                            label={t("Bedrijfsnaam")}
                            placeholder={t("Bedrijfsnaam")}
                            data-cy={"license-request-create-company_name"}
                            register={register}
                            error={errors.company_name}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"short_company_name"}
                            type={"text"}
                            id={"short_company_name"}
                            label={t("Verkorte bedrijfsnaam")}
                            placeholder={t("Verkorte bedrijfsnaam")}
                            register={register}
                            error={errors.short_company_name}
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"company_size"}
                            type={'select'}
                            options={props.size_options}
                            id={"company_size"}
                            label={t("Aantal FTE")}
                            placeholder={t("Kies het aantal FTE")}
                            register={register}
                            error={errors.size}
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"license_type"}
                            type={'select'}
                            options={props.license_options}
                            id={"license_type"}
                            label={t("Type licentie")}
                            register={register}
                            error={errors.license_type}
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"based_on_project"}
                            type={'select'}
                            options={props.based_on_options}
                            label={t("Welke cao of adviesregeling is voor uw organisatie van toepassing")}
                            id={"based_on_project"}
                            register={register}
                            error={errors.based_on_project}
                        />
                    </div>
                    {watch("based_on_project") === "anders" &&
                        <div className="input-group">
                            <Input
                                name={"other_based_on"}
                                type={"text"}
                                id={"other_based_on"}
                                label={t("Anders, namelijk...")}
                                placeholder={t("Graag invullen...")}
                                register={register}
                                error={errors.other_based_on}
                            />
                        </div>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Bezoekadres")}</legend>
                    <div className="input-group">
                        <Input
                            name={"address"}
                            type={"text"}
                            id={"address"}
                            label={t("Adres")}
                            placeholder={t("Straatnaam 12")}
                            register={register}
                            error={errors.address}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"zip_code"}
                            type={"text"}
                            id={"zip_code"}
                            label={t("Postcode")}
                            placeholder={t("1234 AA")}
                            register={register}
                            error={errors.zip_code}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"city"}
                            type={"text"}
                            id={"city"}
                            label={t("Stad")}
                            placeholder={t("Amsterdam")}
                            register={register}
                            error={errors.city}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"country"}
                            type={"text"}
                            id={"country"}
                            label={t("Land")}
                            placeholder={t("Nederland")}
                            register={register}
                            error={errors.country}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Postadres")}</legend>
                    <div className="input-group">
                        <Input
                            name={"mail_address"}
                            type={"text"}
                            id={"mail_address"}
                            label={t("Adres")}
                            placeholder={t("Straatnaam 12 of Postbus 12345")}
                            register={register}
                            error={errors.mail_address}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"mail_zip_code"}
                            type={"text"}
                            id={"mail_zip_code"}
                            label={t("Postcode")}
                            placeholder={t("1234 AA")}
                            register={register}
                            error={errors.mail_zip_code}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"mail_city"}
                            type={"text"}
                            id={"mail_city"}
                            label={t("Stad")}
                            placeholder={t("Amsterdam")}
                            register={register}
                            error={errors.mail_city}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"mail_country"}
                            type={"text"}
                            id={"mail_country"}
                            label={t("Land")}
                            placeholder={t("Nederland")}
                            register={register}
                            error={errors.mail_country}
                        />
                    </div>

                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Contactpersoon")}</legend>
                    <div className="input-group">
                        <Input
                            name={"contact_name"}
                            type={"text"}
                            id={"contact_name"}
                            label={t("Naam")}
                            placeholder={t("Naam contactpersoon")}
                            register={register}
                            error={errors.contact_name}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"contact_function"}
                            type={"text"}
                            id={"contact_function"}
                            label={t("Functie")}
                            placeholder={t("Functie van de contactpersoon")}
                            register={register}
                            error={errors.contact_function}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"contact_email"}
                            type={"text"}
                            id={"contact_email"}
                            label={t("Emailadres")}
                            placeholder={t("Emailadres contactpersoon")}
                            register={register}
                            error={errors.contact_email}
                            pattern={{
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Dit is geen geldig email adres"
                            }}
                            data-cy={"contact_email-input"}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"contact_telephone"}
                            type={"text"}
                            id={"contact_telephone"}
                            label={t("Telefoonnummer")}
                            placeholder={t("012-3456789")}
                            register={register}
                            error={errors.contact_telephone}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Klantbeheerder")}</legend>
                    <p className={"form-container__fieldset-description"}>
                        Een klantbeheerder is de contactpersoon voor Human Capital Group voor de applicatie; die andere
                        gebruikers binnen de organisatie toevoegt aan de applicatie.
                        De Klantbeheerder kan dezelfde persoon zijn als de contactpersoon.
                    </p>
                    <div className="input-group">
                        <Input
                            name={"admin_name"}
                            type={"text"}
                            id={"admin_name"}
                            label={t("Naam")}
                            placeholder={t("Naam klantbeheerder")}
                            register={register}
                            error={errors.admin_name}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"admin_title"}
                            type={"text"}
                            id={"admin_title"}
                            label={t("Functie")}
                            placeholder={t("Functie van de klantbeheerder")}
                            register={register}
                            error={errors.admin_title}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"admin_email"}
                            type={"text"}
                            id={"admin_email"}
                            label={t("Emailadres")}
                            placeholder={t("Emailadres klantbeheerder")}
                            register={register}
                            error={errors.admin_email}
                            pattern={{
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Dit is geen geldig email adres"
                            }}
                            data-cy={"admin_email-input"}
                        />
                    </div>

                    {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                    text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                    {Object.values(errors).find((error) => error.type === "pattern") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"email-pattern-errors-summary"}
                                      text={t("Het ingevulde e-mailadres voldoet niet aan de eisen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Aanvragen")}
                        fullWidth={true}
                        data-cy={"license-request-create-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const LicenseRequestCreate = withHook(
    LicenseCreateDisplay,
    useLicenseCreate
)
