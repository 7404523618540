import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function Training() {
    const {agenda_items: agendaItems} = useSelector( state => state.SiteInfo);
    const {t} = useTranslation();

    return(
        <div className={"training-links"}>
            <h1 className={"training-links__list-heading"}>{t("Eerstvolgende trainingen")}</h1>
            <ul className="training-links__list">
                {agendaItems?.map(agendaItem => {
                    return(
                        <li className={"training-links__list-item"} key={agendaItem.id}>
                            <div className={"training-links__list-date"}>{agendaItem.title}</div>
                            <h2 className={"training-links__list-title"}>{agendaItem.label}</h2>
                            <a className={"training-links__list-cta"} href={agendaItem.target} target="_blank">{t("Meer informatie")}</a>
                        </li>
                    )
                })}
                {agendaItems.length === 0 && (
                    <li className={"training-links__list-item"} key={"none"}>
                        Er zijn op dit moment geen trainingsdata bekend.
                    </li>
                )}
            </ul>
        </div>
    )
}
