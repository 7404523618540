import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import IconButton from "../molecules/IconButton";
import ButtonSecondary from "../atoms/ButtonSecondary";
import ButtonPrimary from "../atoms/ButtonPrimary";

export default function SingleFieldModalTrigger(props) {
    const [ active, setActive ] = useState(false);
    const [ fieldValue, setFieldValue] = useState("");

    const confirm = async () => {
        if(props?.remoteCall) {
            let result = await props.remoteCall(fieldValue);
            if(result?.data && props?.onClick) {
                props?.onClick(result.data);
            }

        }
        setFieldValue("");
    }

    return (
        <>
            <a href={"#"} onClick={ e=> { e.preventDefault(); setActive(true)}} className={"button button--primary"}>
                {props.title}
            </a>
            {active && (
                <SingleFieldModal
                    {...props}
                    closeModal={() => setActive(false)}
                    setFieldValue={setFieldValue}
                    fieldValue={fieldValue}
                    confirmModal={confirm}
                />
            )}
        </>

    )
}


export function SingleFieldModal(props) {

    const {t, i18n} = useTranslation();
    return (
        <>
            <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"} bodyOpenClassName={"has-modal"}>
                <div className={"modal-header"}>
                    <div className={"modal-header__top"}>
                        <h2 className={"modal-header__title"}>{ t(props.title) || t("Aanmaken")}</h2>
                        <ul className={"modal-header__controls"}>
                            <li className={"modal-header__control"}>
                                <IconButton icon={"close"} onClick={() => props?.closeModal()}/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={"modal__body"}>
                    <div className="input-group">
                        <label className="label" htmlFor={props.field.name}>{props.field.label}</label>
                        <div className="input-group__input">
                            <input
                                placeholder={props.field?.placeholder || ""}
                                id={props.field.name}
                                name={props.field.name}
                                type="text"
                                value={props.fieldValue}
                                onChange={e => props.setFieldValue(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="modal-footer">
                    <ul className={"modal__buttons"}>
                        {!props?.singular === true && (
                            <li className={"modal__button"}>
                                <ButtonSecondary
                                    onClick={() => props?.closeModal()}>
                                    {t(props?.cancelText)|| t("Annuleren")}
                                </ButtonSecondary>
                            </li>
                        )}
                        <li className={"modal__button"}>
                            <ButtonPrimary
                                onClick={() => {
                                    if(props?.confirmModal) {
                                        props?.confirmModal();
                                    }
                                    props?.closeModal();
                                }}
                            >
                                {t(props?.confirmText)|| t("Aanmaken")}
                            </ButtonPrimary>
                        </li>
                    </ul>
                </div>

            </Modal>
        </>
    )

}
