import React from "react";

export function DateFilter(props) {
    const date = new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date("2020-12-31"));

    return ( <></>
        // <input key={props.identifier}
        //        type={"text"}
        //        placeholder={date}
        //        className={"text-filter"}
        //        disabled={true}
        //        onChange={(e) => props.filter(props.identifier, e)}
        // />
    )
}
