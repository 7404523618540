import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../utils/useAuth";
import axios from "axios";
import {getFunction} from "../../queries/function_detail";
import {getClient} from "../../queries/client_detail";
import {withHook} from "../../utils/withHookHoC";
import React, {useEffect, useState} from "react";
import {getProject} from "../../queries/project_detail";
import ButtonPrimary from "../atoms/ButtonPrimary";
import LoaderAnimation from "../atoms/LoaderAnimation";
import {ReportGeneratingMessage} from "../molecules/ReportGeneratingMessage";
import {useForm} from "react-hook-form";
import {Input, CheckboxInput, SimpleRadioInputs} from "../atoms/Input";
import Notification from "../molecules/Notification";
import {function_type_options_fun, isFuwaterVbalans} from "../../utils/constants";
import useSWR from "swr";

export function FunctionReportDisplay(props) {
    const {
        register, handleSubmit, setError, clearErrors, setValue, watch, formState: {errors}
    } = useForm({
        defaultValues: {
            reference_meta: true,
            company_meta: true,
            match_meta: true,
            company_motivation: true,
            match_motivation: true,
            company_confirmed_group: true,
            determined_functions: "determined",
            reference_scoring_radio: "reference_scoring_as_basic"
        }, shouldUnregister: true
    });

    useEffect(() => {
        setValue("company_reference_functions", true);
    }, [])

    useEffect(() => {
        if(props.client_id) {
            setValue("client_id", props.client_id)
        }

        if(props.project_id) {
            setValue("project_id", props.project_id)
        }

        if(props.function_id && !props.isMulti) {
            setValue("function_id", props.function_id)
        }

        if(props.isMulti) {
            setValue("reference_functions", true)
            setValue("company_functions", !isFuwaterVbalans(props.method_id))
            setValue("match_functions", props.methodDetail?.allow_function_matching)
        }
    }, [props.client_id, props.project_id, props.function_id, props.method_id, props.methodDetail])

    const {t} = useTranslation();

    if(!props?.onSubmit) {
        return <LoaderAnimation/>;
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Functierapportage maken")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(props.onSubmit)(e).catch((error) => {
                    if(error.cause.message === "server-error-function-report" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>

                <section className={"form-meta"}>
                    {!props.isMulti &&
                        <div className={"form-meta__item"}>
                            <span className={"form-meta__label"}>{t("Functie")}</span>
                            <span className={"form-meta__description"}
                                  data-cy={"project-name"}>{props?.function_name}</span>
                        </div>
                    }
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Project")}</span>
                        <span className={"form-meta__description"} data-cy={"project-name"}>{props?.project_name}</span>
                    </div>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Klant")}</span>
                        <span className={"form-meta__description"} data-cy={"client-name"}>{props.client_name}</span>
                    </div>
                </section>

                <fieldset className={"form-container__fieldset"}>

                    <legend className={"form-container__legend"}>{t("Weergaveopties")}</legend>

                    <FunctionReportTable type_options={props.type_options} register={register} isMulti={props.isMulti}
                                         function_type={props.function_type} watch={watch} setValue={setValue}
                                         project_id={props.project_id}/>
                </fieldset>

                {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                text={errors.server.message}/>}

                <fieldset className={"form-container__fieldset"}>
                    {props.pending === false ? (
                        <ButtonPrimary
                            text={t("Rapportage maken")}
                            fullWidth={true}
                            disabled={props.generating}
                        >
                            {props.generating && (
                                <div className={"button__loader"}>
                                    <LoaderAnimation small={true} infinite={true}/>
                                </div>
                            )}
                        </ButtonPrimary>
                    ) : (
                        <ReportGeneratingMessage/>
                    )}
                </fieldset>
            </form>
        </>
    )
}

export const useFunctionReport = (props) => {

    const {t, i18n} = useTranslation();

    let navigate = useNavigate();
    let location = useLocation();

    let {client_id, project_id, function_id} = useParams();
    const {data: clientDetail} = getClient(client_id);
    const {data: projectDetail} = getProject(project_id);
    const {data: functionDetail} = getFunction(function_id);
    const {data: methodDetail} = useSWR(() => projectDetail?.method?.id ? `/api/methods/${projectDetail?.method?.id}/` : false);

    const [selection, setSelection] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        setSelection(Object.entries(location.state || {}).filter(o => o[1] === true).map(o => parseInt(o[0])));
    }, [location.state])

    const auth = useAuth();

    if(!clientDetail || !projectDetail || (function_id && !functionDetail)) {
        return {};
    }


    const isMulti = !functionDetail;

    const authList = [
        [project_id, 'project'],
        [client_id, 'client'],
    ]

    const canViewReferenceFunctionPoints = auth.checkAccessList('base.view_reference_function_points', authList);
    const canViewCompanyFunctionPoints = auth.checkAccessList('base.view_company_function_points', authList);
    const canViewReferenceFunctionScoring = auth.checkAccessList('base.view_reference_function_scoring', authList);
    const canViewCompanyFunctionScoring = auth.checkAccessList('base.view_company_function_scoring', authList);

    const type_options = function_type_options_fun({auth: auth, t: t, projectId: projectDetail?.id})

    return {
        t,
        i18n,

        generating,
        pending,
        isMulti,
        selection: selection || [],
        client_id: clientDetail?.id,
        project_id: projectDetail?.id,
        function_id: functionDetail?.id,
        method_id: projectDetail?.method?.id,
        methodDetail: methodDetail,

        client_name: clientDetail?.name,
        project_name: projectDetail?.name,
        function_name: functionDetail?.name,
        function_type: functionDetail?.type,
        canViewCompanyFunctionPoints,
        canViewReferenceFunctionPoints,
        canViewReferenceFunctionScoring,
        canViewCompanyFunctionScoring,

        type_options,
        onSubmit: async (values) => {
            setGenerating(true);

            let url = isMulti ? `/api/export/function/report/?pdf=1` : `/api/export/function/report/${functionDetail.id}/?pdf=1`;

            let submitValues = {...values}

            if(values.reference_scoring_radio) {
                if(values.reference_scoring_radio === "reference_scoring_as_basic") {
                    submitValues.reference_scoring_as_basic = true;
                }

                if(values.reference_scoring_radio === "reference_scoring") {
                    submitValues.reference_scoring = true
                }
            }

            delete submitValues["reference_scoring_radio"]
            delete submitValues["reference_scoring_checkbox"]
            delete submitValues["reference_functions"]
            delete submitValues["company_functions"]
            delete submitValues["match_functions"]

            axios.post(url, Object.assign({}, {
                client_name: clientDetail?.name,
                project_name: projectDetail?.name,
                selection,
                ...submitValues,
            })).then(result => {
                    if(result?.data?.status === "ok") {
                        setPending(true);
                    }
                }
            )
            // }), {
            //     responseType: 'blob',
            // }).then( response => {
            //     const date = new Intl.DateTimeFormat("nl-NL", {
            //         day: "2-digit",
            //         month: "2-digit",
            //         year: "numeric"
            //         // hour: 'numeric',
            //         // minute: 'numeric',
            //     }).format(new Date());
            //     const extension = response.headers['content-type'] === "application/x-zip-compressed" ? "zip" : "pdf"
            //     const functionDetailName = functionDetail ? `${functionDetail?.name} - ` : '';
            //     const filename = `${projectDetail?.client?.name} - ${projectDetail?.name} - ${functionDetailName} ${date} - FUWA Online.${extension}`;
            //     instantDownload(response.data, filename);
            //     setGenerating(false);
            // });

            // PDF call

        },
    }
}

export const FunctionReport = withHook(
    FunctionReportDisplay,
    useFunctionReport,
)

export function FunctionReportTable(props) {
    const {t} = useTranslation();
    const auth = useAuth();
    const {client_id, project_id} = useParams();

    const authList = [
        [project_id, 'project'],
        [client_id, 'client'],
    ]

    const canViewCompanyFunctionScoring = auth.checkAccessList('base.view_company_function_scoring', authList);
    const canViewReferenceFunctionScoring = auth.checkAccessList('base.view_reference_function_scoring', authList);
    const canViewReferenceFunctionScoringAsBasic = auth.checkAccessList('base.view_reference_function_scoring_as_basic', authList)
    const canViewReferenceFunctionPoints = auth.checkAccessList('base.view_reference_function_points', authList);
    const canViewCompanyFunctionPoints = auth.checkAccessList('base.view_company_function_points', authList);

    if(props.isMulti) {
        return <table className={"feature-table"}>
            <thead>
            <tr>
                <th className={"feature-table__head feature-table__cell"}></th>
                {props.watch("reference_functions") &&
                    <th className={"feature-table__head feature-table__cell"}>
                        <div
                            className={"function-type-indicator function-type-indicator--small function-type-indicator--reference"}>{t("R")}</div>
                    </th>}
                {props.watch("company_functions") &&
                    <th className={"feature-table__head feature-table__cell"}>
                        <div
                            className={"function-type-indicator function-type-indicator--small function-type-indicator--company"}>{t("B")}</div>
                    </th>}
                {props.watch("match_functions") &&
                    <th className={"feature-table__head feature-table__cell"}>
                        <div
                            className={"function-type-indicator function-type-indicator--small function-type-indicator--match"}>{t("I")}</div>
                    </th>}
            </tr>
            </thead>
            <tbody>
            <tr key={"meta"} className={"feature-table__row"}>
                <td className={"feature-table__cell"}>
                    {t("Meta-informatie")}
                    <p className={"feature-table__help-text"}>{t("Toont aanvullende informatie van de functie.")}</p>
                </td>
                {props.watch("reference_functions") &&
                    <td className={"feature-table__cell"}>
                        <CheckboxInput
                            key={"reference_meta"}
                            name={"reference_meta"}
                            id={"reference_meta"}
                            type={"checkbox"}
                            register={props.register}
                        />
                    </td>
                }
                {props.watch("company_functions") &&
                    <td className={"feature-table__cell"}>
                        <CheckboxInput
                            key={"company_meta"}
                            name={"company_meta"}
                            id={"company_meta"}
                            type={"checkbox"}
                            register={props.register}
                        />
                    </td>
                }
                {props.watch("match_functions") &&
                    <td className={"feature-table__cell"}>
                        <CheckboxInput
                            key={"match_meta"}
                            name={"match_meta"}
                            id={"match_meta"}
                            type={"checkbox"}
                            register={props.register}
                        />
                    </td>
                }
            </tr>

            <tr key={"description"} className={"feature-table__row"}>
                <td className={"feature-table__cell"}>
                    {t("Beschrijving")}
                </td>
                {props.watch("reference_functions") &&
                    <td className={"feature-table__cell"}>
                        <CheckboxInput
                            key={"reference_description"}
                            name={"reference_description"}
                            id={"reference_description"}
                            type={"checkbox"}
                            register={props.register}
                        />
                    </td>
                }
                {props.watch("company_functions") &&
                    <td className={"feature-table__cell"}>
                        <CheckboxInput
                            key={"company_description"}
                            name={"company_description"}
                            id={"company_description"}
                            type={"checkbox"}
                            register={props.register}
                        />
                    </td>
                }
                {props.watch("match_functions") &&
                    <td className={"feature-table__cell"}>
                        <CheckboxInput
                            key={"match_description"}
                            name={"match_description"}
                            id={"match_description"}
                            type={"checkbox"}
                            register={props.register}
                        />
                    </td>
                }
            </tr>

            {(canViewReferenceFunctionScoring && props.watch("reference_functions")) &&
                <tr key={"reference_scoring"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        <div className={"feature-table__label"}>{t("Gradering")}</div>
                        {props.watch("reference_scoring_checkbox") &&
                            <SimpleRadioInputs
                                id={"reference_scoring_radio"}
                                name={"reference_scoring_radio"}
                                options={[
                                    {
                                        id: "reference_scoring_as_basic",
                                        name: "Domein scores"
                                    },
                                    {
                                        id: "reference_scoring",
                                        name: "Domein en aspect scores"
                                    }
                                ]}
                                register={props.register}
                                setValue={props.setValue}
                            />
                        }
                    </td>
                    {(props.watch("reference_functions") && canViewReferenceFunctionScoringAsBasic) &&
                        <>
                            <td className={"feature-table__cell"}>
                                <CheckboxInput
                                    key={"reference_scoring_checkbox"}
                                    name={"reference_scoring_checkbox"}
                                    id={"reference_scoring_checkbox"}
                                    type={"checkbox"}
                                    register={props.register}
                                />
                            </td>
                        </>
                    }
                    {(props.watch("reference_functions") && !canViewReferenceFunctionScoringAsBasic) &&
                        <>
                            <td className={"feature-table__cell"}>
                                <CheckboxInput
                                    key={"reference_scoring"}
                                    name={"reference_scoring"}
                                    id={"reference_scoring"}
                                    type={"checkbox"}
                                    register={props.register}
                                />
                            </td>
                        </>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                </tr>
            }

            {(canViewReferenceFunctionScoring && props.watch("reference_functions")) &&
                <tr key={"reference_scoring_compact"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        {t("Gradering - compacte weergave")}
                        <p className={"feature-table__help-text"}>{t("Toont de domein en aspect scores  op A4-formaat")}</p>
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"reference_scoring_compact"}
                                name={"reference_scoring_compact"}
                                id={"reference_scoring_compact"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                </tr>
            }

            {((canViewCompanyFunctionScoring && props.watch("company_functions")) || (props.watch("reference_functions") && !canViewReferenceFunctionScoring && canViewReferenceFunctionScoringAsBasic)) &&
                <tr key={"company_scoring"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        <div className={"feature-table__label"}>{t("Waardering")}</div>
                        {props.watch("company_scoring") &&
                            <CheckboxInput
                                key={"company_auxiliary_tables"}
                                name={"company_auxiliary_tables"}
                                id={"company_auxiliary_tables"}
                                type={"checkbox"}
                                label={t("Toon hulptabellen")}
                                register={props.register}
                            />
                        }
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}>
                            {(!canViewReferenceFunctionScoring && canViewReferenceFunctionScoringAsBasic) &&
                                <CheckboxInput
                                    key={"reference_scoring_as_basic"}
                                    name={"reference_scoring_as_basic"}
                                    id={"reference_scoring_as_basic"}
                                    type={"checkbox"}
                                    register={props.register}
                                />
                            }
                        </td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"company_scoring"}
                                name={"company_scoring"}
                                id={"company_scoring"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                </tr>
            }

            {/*{props.watch("company_functions") &&*/}
            {/*    <tr key={"company_group_advice_bar"} className={"feature-table__row"}>*/}
            {/*        <td className={"feature-table__cell"}>*/}
            {/*            {t("Functiegroep indicatie balk")}*/}
            {/*        </td>*/}
            {/*        {props.watch("reference_functions") &&*/}
            {/*            <td className={"feature-table__cell"}></td>*/}
            {/*        }*/}
            {/*        {props.watch("company_functions") &&*/}
            {/*            <td className={"feature-table__cell"}>*/}
            {/*                <CheckboxInput*/}
            {/*                    key={"company_group_advice_bar"}*/}
            {/*                    name={"company_group_advice_bar"}*/}
            {/*                    id={"company_group_advice_bar"}*/}
            {/*                    type={"checkbox"}*/}
            {/*                    register={props.register}*/}
            {/*                />*/}
            {/*            </td>*/}
            {/*        }*/}
            {/*        {props.watch("match_functions") &&*/}
            {/*            <td className={"feature-table__cell"}></td>*/}
            {/*        }*/}
            {/*    </tr>*/}
            {/*}*/}

            {props.watch("company_functions") &&
                <tr key={"company_group_advice"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        {t("Voorgestelde functiegroep")}
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"company_group_advice"}
                                name={"company_group_advice"}
                                id={"company_group_advice"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                </tr>
            }

            {(props.watch("company_functions") || props.watch("match_functions")) &&
                <tr key={"confirmed_group"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        {t("Bevestigde functiegroep")}
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"company_confirmed_group"}
                                name={"company_confirmed_group"}
                                id={"company_confirmed_group"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"match_confirmed_group"}
                                name={"match_confirmed_group"}
                                id={"match_confirmed_group"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                </tr>
            }

            {(props.watch("company_functions") || props.watch("match_functions")) &&
                <tr key={"reference_functions"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        {t("Gekozen referentiefunctie(s)")}
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"company_reference_functions"}
                                name={"company_reference_functions"}
                                id={"company_reference_functions"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"match_reference_functions"}
                                name={"match_reference_functions"}
                                id={"match_reference_functions"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                </tr>
            }

            {props.watch("reference_functions") &&
                <tr key={"reference_motivation"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        {t("Functiebeeld")}
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"reference_motivation"}
                                name={"reference_motivation"}
                                id={"reference_motivation"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                </tr>
            }

            {(props.watch("company_functions") || props.watch("match_functions")) &&
                <tr key={"motivation"} className={"feature-table__row"}>
                    <td className={"feature-table__cell"}>
                        {t("Onderbouwing")}
                    </td>
                    {props.watch("reference_functions") &&
                        <td className={"feature-table__cell"}></td>
                    }
                    {props.watch("company_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"company_motivation"}
                                name={"company_motivation"}
                                id={"company_motivation"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                    {props.watch("match_functions") &&
                        <td className={"feature-table__cell"}>
                            <CheckboxInput
                                key={"match_motivation"}
                                name={"match_motivation"}
                                id={"match_motivation"}
                                type={"checkbox"}
                                register={props.register}
                            />
                        </td>
                    }
                </tr>
            }
            </tbody>
        </table>
    } else {

        if(props.function_type === "reference") {
            return (
                <div className="input-group">
                    <CheckboxInput
                        key={"reference_meta"}
                        name={"reference_meta"}
                        id={"reference_meta"}
                        type={"checkbox"}
                        label={t("Meta-informatie")}
                        register={props.register}
                        helpText={t("Toont aanvullende informatie van de functie op het voorblad.")}
                    />

                    <CheckboxInput
                        key={"reference_description"}
                        name={"reference_description"}
                        id={"reference_description"}
                        type={"checkbox"}
                        label={t("Beschrijving")}
                        register={props.register}
                    />

                    {canViewReferenceFunctionScoring &&
                        <>
                            {canViewReferenceFunctionScoringAsBasic &&
                                <>
                                    <CheckboxInput
                                        key={"reference_scoring_checkbox"}
                                        name={"reference_scoring_checkbox"}
                                        id={"reference_scoring_checkbox"}
                                        type={"checkbox"}
                                        label={t("Gradering")}
                                        register={props.register}
                                    />

                                    {props.watch("reference_scoring_checkbox") &&
                                        <SimpleRadioInputs
                                            id={"reference_scoring_radio"}
                                            name={"reference_scoring_radio"}
                                            options={[
                                                {
                                                    id: "reference_scoring_as_basic",
                                                    name: "Domein scores",
                                                    indented: true,
                                                },
                                                {
                                                    id: "reference_scoring",
                                                    name: "Domein en aspect scores",
                                                    indented: true,
                                                }
                                            ]}
                                            register={props.register}
                                            setValue={props.setValue}
                                        />
                                    }
                                </>
                            }

                            {!canViewReferenceFunctionScoringAsBasic &&
                                <CheckboxInput
                                    key={"reference_scoring"}
                                    name={"reference_scoring"}
                                    id={"reference_scoring"}
                                    type={"checkbox"}
                                    label={t("Gradering")}
                                    register={props.register}
                                />
                            }
                        </>
                    }

                    {(!canViewReferenceFunctionScoring && canViewReferenceFunctionScoringAsBasic) &&
                        <CheckboxInput
                            key={"reference_scoring_as_basic"}
                            name={"reference_scoring_as_basic"}
                            id={"reference_scoring_as_basic"}
                            type={"checkbox"}
                            label={t("Waardering")}
                            register={props.register}
                        />
                    }

                    {canViewReferenceFunctionScoring &&
                        <CheckboxInput
                            key={"reference_scoring_compact"}
                            name={"reference_scoring_compact"}
                            id={"reference_scoring_compact"}
                            type={"checkbox"}
                            label={t("Gradering - compacte weergave")}
                            register={props.register}
                            helpText={t("Toont de domein en aspect scores  op A4-formaat.")}
                        />
                    }

                    <CheckboxInput
                        key={"reference_motivation"}
                        name={"reference_motivation"}
                        id={"reference_motivation"}
                        type={"checkbox"}
                        label={t("Functiebeeld")}
                        register={props.register}
                    />

                    {canViewReferenceFunctionPoints &&
                        <CheckboxInput
                            key={"reference_points"}
                            name={"reference_points"}
                            id={"reference_points"}
                            type={"checkbox"}
                            label={t("Toon punten")}
                            register={props.register}
                        />
                    }
                </div>
            )
        }

        if(props.function_type === "company") {
            return (
                <div className="input-group">
                    <CheckboxInput
                        key={"company_meta"}
                        name={"company_meta"}
                        id={"company_meta"}
                        type={"checkbox"}
                        label={t("Meta-informatie")}
                        helpText={t("Toont aanvullende informatie van de functie op het voorblad.")}
                        register={props.register}
                    />

                    <CheckboxInput
                        key={"company_description"}
                        name={"company_description"}
                        id={"company_description"}
                        type={"checkbox"}
                        label={t("Beschrijving")}
                        register={props.register}
                    />

                    {canViewCompanyFunctionScoring &&
                        <CheckboxInput
                            key={"company_scoring"}
                            name={"company_scoring"}
                            id={"company_scoring"}
                            type={"checkbox"}
                            label={t("Waardering")}
                            register={props.register}
                        />
                    }

                    {canViewCompanyFunctionScoring &&
                        <CheckboxInput
                            key={"company_auxiliary_tables"}
                            name={"company_auxiliary_tables"}
                            id={"company_auxiliary_tables"}
                            type={"checkbox"}
                            label={t("Toon hulptabellen")}
                            register={props.register}
                            indented={true}
                            disabled={!props.watch("company_scoring")}
                        />
                    }

                    {/*<CheckboxInput*/}
                    {/*    key={"company_group_advice_bar"}*/}
                    {/*    name={"company_group_advice_bar"}*/}
                    {/*    id={"company_group_advice_bar"}*/}
                    {/*    type={"checkbox"}*/}
                    {/*    label={t("Functiegroep indicatie balk")}*/}
                    {/*    register={props.register}*/}
                    {/*/>*/}

                    <CheckboxInput
                        key={"company_group_advice"}
                        name={"company_group_advice"}
                        id={"company_group_advice"}
                        type={"checkbox"}
                        label={t("Voorgestelde functiegroep")}
                        register={props.register}
                    />

                    <CheckboxInput
                        key={"company_confirmed_group"}
                        name={"company_confirmed_group"}
                        id={"company_confirmed_group"}
                        type={"checkbox"}
                        label={t("Bevestigde functiegroep")}
                        register={props.register}
                    />

                    <CheckboxInput
                        key={"company_reference_functions"}
                        name={"company_reference_functions"}
                        id={"company_reference_functions"}
                        type={"checkbox"}
                        label={t("Gekozen referentiefunctie(s)")}
                        register={props.register}
                    />

                    {canViewReferenceFunctionPoints &&
                        <CheckboxInput
                            key={"company_reference_points"}
                            name={"company_reference_points"}
                            id={"company_reference_points"}
                            type={"checkbox"}
                            label={t("Toon punten")}
                            register={props.register}
                            indented={true}
                            disabled={!props.watch("company_reference_functions")}
                        />
                    }

                    <CheckboxInput
                        key={"company_motivation"}
                        name={"company_motivation"}
                        id={"company_motivation"}
                        type={"checkbox"}
                        label={t("Onderbouwing")}
                        register={props.register}
                    />

                    {canViewCompanyFunctionPoints &&
                        <CheckboxInput
                            key={"company_points"}
                            name={"company_points"}
                            id={"company_points"}
                            type={"checkbox"}
                            label={t("Toon punten")}
                            register={props.register}
                        />
                    }
                </div>
            )
        }

        if(props.function_type === "match") {
            return (
                <div className="input-group">
                    <CheckboxInput
                        key={"match_meta"}
                        name={"match_meta"}
                        id={"match_meta"}
                        type={"checkbox"}
                        label={t("Meta-informatie")}
                        helpText={t("Toont aanvullende informatie van de functie op het voorblad.")}
                        register={props.register}
                    />

                    <CheckboxInput
                        key={"match_description"}
                        name={"match_description"}
                        id={"match_description"}
                        type={"checkbox"}
                        label={t("Beschrijving")}
                        register={props.register}
                    />

                    <CheckboxInput
                        key={"match_confirmed_group"}
                        name={"match_confirmed_group"}
                        id={"match_confirmed_group"}
                        type={"checkbox"}
                        label={t("Bevestigde functiegroep")}
                        register={props.register}
                    />

                    <CheckboxInput
                        key={"match_reference_functions"}
                        name={"match_reference_functions"}
                        id={"match_reference_functions"}
                        type={"checkbox"}
                        label={t("Gekozen referentiefunctie(s)")}
                        register={props.register}
                    />

                    {canViewReferenceFunctionPoints &&
                        <CheckboxInput
                            key={"match_reference_points"}
                            name={"match_reference_points"}
                            id={"match_reference_points"}
                            type={"checkbox"}
                            label={t("Toon punten")}
                            register={props.register}
                            indented={true}
                            disabled={!props.watch("match_reference_functions")}
                        />
                    }

                    <CheckboxInput
                        key={"match_motivation"}
                        name={"match_motivation"}
                        id={"match_motivation"}
                        type={"checkbox"}
                        label={t("Onderbouwing")}
                        register={props.register}
                    />
                </div>
            )
        }
    }
}
