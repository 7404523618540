import React from "react";
import {MNTDomains, typeToLetter} from "../utils/constants";
import {comparePattern} from "../utils/compare_function_pattern";
import cx from "classnames";

export default function FunctionSimilarity(props) {
    const comparedPattern = comparePattern(props.pattern_list, props.contextData?.functionDetail?.pattern_list)

    return (
        <div className={"function-similarity"}>
            <div className={"function-similarity__heading-row"}>
                <div className={"function-similarity__heading function-similarity__heading--start"}></div>
                {MNTDomains.map((domain, idx) =>
                    <div className={"function-similarity__heading"} key={idx}>{domain.short}</div>
                )}
                <div className={"function-similarity__heading"}></div>
            </div>
            <div className={"function-similarity-function"}>
                <div className={cx("function-similarity-function__type", {"function-similarity-function__type--reference": props.contextData?.functionDetail?.type === "reference"})}>{typeToLetter(props.contextData?.functionDetail?.type)}</div>
                <div className={"function-similarity-function__data"}>
                    <div className={"function-similarity-function__pattern"}>
                        {props.contextData?.functionDetail?.pattern_list.map((pattern, idx) =>
                            <div
                                className={"function-similarity__cell function-similarity__cell--domain"} key={idx}>{pattern.score}</div>
                        )}
                        <div className={"function-similarity__cell"}>
                            <div className={"pattern-group"}>
                                {props.contextData?.functionDetail?.function_group_label ? props.contextData?.functionDetail?.function_group_label : props.contextData?.functionDetail?.intermediate_function_group_label ? props.contextData?.functionDetail?.intermediate_function_group_label : "-"}
                            </div>
                        </div>
                    </div>
                    <div className={"function-similarity-function__points"}>
                        {props.contextData?.functionDetail?.pattern_list.map((pattern, idx) =>
                            <div
                                className={"function-similarity__cell function-similarity__cell--number"} key={idx}>{pattern.points}</div>
                        )}
                        <div
                            className={"function-similarity__cell function-similarity__cell--number"}>{props.contextData?.functionDetail?.points}</div>
                    </div>
                </div>
            </div>
            <div className={"function-similarity-function function-similarity-function--reference"}>
                <div className={cx("function-similarity-function__type", {"function-similarity-function__type--reference": props.type === "reference"})}>{typeToLetter(props.type)}</div>
                <div className={"function-similarity-function__data"}>
                    <div className={"function-similarity-function__pattern"}>
                        {comparedPattern.map((pattern, idx) =>
                            <div
                                className={cx("function-similarity__cell function-similarity__cell--domain", "function-similarity__cell--compare-" + pattern.comparison)} key={idx}>{pattern.score}</div>
                        )}
                        <div className={"function-similarity__cell"}>
                            <div className={"pattern-group"}>
                                {props.function_group_label ? props.function_group_label : "-"}
                            </div>
                        </div>
                    </div>
                    <div className={"function-similarity-function__points"}>
                        {props.pattern_list.map((pattern, idx) =>
                            <div
                                className={"function-similarity__cell function-similarity__cell--number"} key={idx}>{pattern.points}</div>
                        )}
                        <div
                            className={"function-similarity__cell function-similarity__cell--number"}>{props.points}</div>
                    </div>
                </div>
            </div>
            <div className={"function-similarity-function"}>
                <div className={"function-similarity-function__title"}>Verschil</div>
                <div className={"function-similarity-function__data"}>
                    <div className={"function-similarity-function__pattern"}>
                        {props.pattern_list.map((pattern,idx ) =>
                            <div
                                className={"function-similarity__cell function-similarity__cell--number"} key={idx}>{pattern.difference}</div>
                        )}
                        <div
                            className={"function-similarity__cell function-similarity__cell--number function-similarity__cell--similarity"}>{props.analytical_difference}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"function-similarity-function"}>
                <div className={"function-similarity-function__title"}>Afstand</div>
                <div className={"function-similarity-function__data"}>
                    <div className={"function-similarity-function__pattern"}>
                        {props.pattern_list.map((pattern, idx) =>
                            <div
                                className={"function-similarity__cell function-similarity__cell--number"} key={idx}>{pattern.distance}</div>
                        )}
                        <div
                            className={"function-similarity__cell function-similarity__cell--number function-similarity__cell--similarity"}>{props.analytical_distance}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
