import {useSWRInfinite} from "swr";
import {buildOrder, buildFilter, loadIfAllowed} from "../utils/constants";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import axios from 'axios';
import {generatePath} from "react-router-dom";

export function getUsers(stateToDebounce) {

    const identifiers = ['client-users', 'project-users', 'select-user-overview', 'select-describer-overview',
        'select-applier_one-overview', 'select-applier_two-overview', 'global-user-overview', 'user-selection-overview',
        'select-user-describer', 'select-user-applier_one', 'select-user-applier_two', 'function-advanced-filter-user__in',
        'function-advanced-filter-describer', 'function-advanced-filter-applier_one', 'function-advanced-filter-applier_two'];
    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id: parseInt(fetchState?.identKey?.client_id)
            } : {},
            fetchState?.identKey?.user_group_id ? {
                user_group__id: parseInt(fetchState?.identKey?.user_group_id)
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState.identifier) ? `/api/users/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index + 1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getProjectUsers(stateToDebounce) {

    const identifiers = ['client-users', 'project-users', 'select-user-overview', 'select-describer-overview',
        'select-applier_one-overview', 'select-applier_two-overview', 'global-user-overview'];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id: parseInt(fetchState?.identKey?.client_id)
            } : {},
            fetchState?.identKey?.user_group_id ? {
                user_group__id: parseInt(fetchState?.identKey?.user_group_id)
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState.identifier) ? `/api/projects/${fetchState.identKey.project_id}/users/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index + 1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}


export async function remoteAddUsersToProject(userlist, projectId) {

    await axios.patch(`/api/projects/${projectId}/add_users/`, {
        users: userlist,
        project_id: projectId,
    })
}

export async function remoteRemoveUsersFromProject(userlist, projectId) {

    await axios.patch(`/api/projects/${projectId}/remove_users/`, {
        users: userlist,
        project_id: projectId,
    })
}

export async function assignRole(values, navigate, user_id) {
    let data = {
        user_id: values.user_id,
        role_id: values.role_id,
    }

    switch (values.type) {
        case 'client':
            data.content_type = 2;
            data.object_id = values.client_id;
            break
        case 'project':
            data.content_type = 10;
            data.object_id = values.project_id;
            break
    }


    await axios.post("/api/userroles/", data)
        .then(result => {
            if (result.status === 201) {
                if (result.data?.id) {
                    navigate(
                        generatePath('/user/:user_id/roles', {
                            user_id,
                        })
                    );
                } else {
                    throw new Error("server-error-user-assign-role", {cause: result});
                }
            } else {
                throw new Error("server-error-user-assign-role", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}
