import React, { useCallback, useState, useEffect } from "react";
import debounce from "lodash.debounce";

export const useDebounce = (obj= null, wait= 1000) => {
  const [state, setState] = useState(obj);

  const setDebouncedState = (_val) => {
    _debounce(_val);
  };

  const _debounce = useCallback(
    debounce((_prop) => {
      setState(_prop);
    }, wait),
    []
  );

  return [state, setDebouncedState];
};


export const useDebounce2 = (callback, delay) => {
	const debouncedFn = useCallback(
		debounce((...args) => callback(...args), delay),
		[delay], // will recreate if delay changes
	);
	return debouncedFn;
}

export function useDebounceVercel(value, delay=200) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}


