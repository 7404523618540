import cx from "classnames";
import React from "react";

export default function DomainSummaryDisplay(props) {

    return (
        <ol className={"domain-navigation"}>
            {props?.structure?.domains?.map(domain => {
                return (
                    <li
                        key={domain.number}
                        className={cx(
                            "domain-navigation__domain-item", {
                                "domain-navigation__domain-item--selected": props.selectedDomain === domain.number,
                                "domain-navigation__domain-item--valid": props.getDomainStatus(domain.pk) === 'valid',
                                "domain-navigation__domain-item--invalid": props.getDomainStatus(domain.pk) === 'invalid',
                            })}
                        onClick={() => props.onClick(domain.number)}
                    >
                        {domain.number}.{domain.name.substring(0,4)}
                    </li>
                )
            })}
        </ol>
    )

}
