import React from "react";

function truncateMiddle(string="", length=20, secondary_length=-1, subst='…') {

    secondary_length = secondary_length > 0 ? secondary_length : length;

    if(string.length > (length + secondary_length + 2)) {
        return string.substr(0, length) + subst + string.substr(-1 * secondary_length, secondary_length)
    }

    return string;
}

export default function TruncateMiddle(props) {

    let string = truncateMiddle(props.children, props.length, props.secondary_length, props.subst);

    return (
        <>
            {string}
        </>
    )


}
