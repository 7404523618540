import React, {Fragment} from "react";
import LoaderAnimation from "../atoms/LoaderAnimation";
import cx from "classnames";
import Pip from "../atoms/Pip";
import {functionStatus, BKNScores, FUWATERScores, MNTLetters, isFuwaterVbalans} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {createFunctionDescriptionTemplate} from "../../queries/function_description";
import ByNameDate from "../molecules/ByNameDate";
import {getFunction} from "../../queries/function_detail";
import {GroupAdvice} from "./Ranking";
import {RankingRowStructure} from "../../components/Overviews/ClientProjectRankingFunctions";
import {MNTDomains, VBalansDomains} from "../../utils/constants";
import {FunctionMetaInfo} from "./FunctionMeta";
import {getProject} from "../../queries/project_detail";
import {useAuth} from "../../utils/useAuth";
import {useParams} from "react-router-dom";


export function FunctionSummaryStatusDisplay(props) {
    const functionDetail = props?.functionDetail;
    const functionProjectDetail = getProject(functionDetail?.project.id)?.data;

    const {t, i18n} = useTranslation();

    if(!functionDetail) {
        return <LoaderAnimation/>;
    }


    return (
        <div className={"summary-meta"}>
            <FunctionMetaInfo
                functionDetail={functionDetail}
                functionProjectDetail={functionProjectDetail}
                wide={true}
            />
        </div>
    )
}

export function ReferenceSummaryFunctionDetail(props) {

    const functionDetail = getFunction(props.id);
    if(!functionDetail.data || functionDetail.isLoading) {
        return <tr>
            <td><LoaderAnimation/></td>
        </tr>;
    }

    return (
        <RankingRowStructure
            {...functionDetail?.data}
            contextData={props.contextData}/>
    )

}

export function ReferenceSummary(props) {

    const {t, i18n} = useTranslation();

    const {data: projectDetail} = getProject(props.functionDetail?.project?.id);

    return (
        <div className={"overview overview--no-sticky"}>
            <div className="tool-header tool-header--no-border">
                <h2 className="tool-header__title">{t("Referentiefuncties")}</h2>
            </div>
            <table className={"overview-table"}>
                <thead className="overview-table__head">
                <tr className={"overview-table__row"}>
                    <th className="overview-table__filter-cell overview-table__filter-cell--width-type">
                        <div
                            className="overview-table__column-title overview-table__column-title--empty">{t("Type")}</div>
                    </th>
                    <th className="overview-table__filter-cell">
                        <div className="overview-table__column-title overview-table__column-title--empty">Naam</div>
                    </th>
                    <th className="overview-table__filter-cell overview-table__filter-cell--width-pattern-widget">
                        <div className="overview-table__column-title overview-table__column-title--empty">
                            <div className="pattern-filter-title__group">Gr.</div>
                            <div className="pattern-filter-title__domain">
                                {isFuwaterVbalans(projectDetail?.method?.id) && <>
                                    {VBalansDomains.map((o, idx) => <span key={idx}
                                                                          className="pattern-filter-title__letter">{o.short}</span>)}
                                </>}
                                {!isFuwaterVbalans(projectDetail?.method?.id) && <>
                                    {MNTDomains.map((o, idx) => <span key={idx}
                                                                      className="pattern-filter-title__letter">{o.short}</span>)}
                                </>}
                            </div>
                            <div className="pattern-filter-title__points">Punt.</div>
                        </div>
                    </th>
                </tr>
                <RankingRowStructure
                    {...props.functionDetail}
                    contextData={{
                        summary: true,
                        comparison: false
                    }}/>
                </thead>
                <tbody>

                {props.functionDetail.reference_functions?.length === 0 && (
                    <tr>
                        <td colSpan={3} className={"overview-table__cell summary-motivation__text"}>
                            {t("Geen referentiefuncties opgegeven")}
                        </td>
                    </tr>
                )}
                {props.functionDetail.reference_functions?.map(func => {
                    return (
                        <ReferenceSummaryFunctionDetail
                            contextData={{
                                summary: true,
                                comparison: !(props.functionDetail?.type === 'match' && isFuwaterVbalans(projectDetail?.method?.id)),
                                patternList: props.functionDetail.pattern_list,
                            }}
                            key={func.id}
                            id={func.id}/>
                    )
                })}
                </tbody>
            </table>
        </div>
    )

}

export function FunctionCompanyMatchDisplay(props) {

    const {t, i18n} = useTranslation();
    const content = props.content;
    const functionDetail = props.functionDetail;

    function motivationHtmlContent() {

        return {
            __html: functionDetail?.ranking_motivation || t("Geen onderbouwing opgegeven")
        }
    }

    if(!functionDetail) {
        return (
            <LoaderAnimation/>
        )
    }

    return (
        <div className="summary-container">
            <dl className="group-only-advice">
                <div className="group-only-advice__column">
                    <dt className={"group-only-advice__key"}>{t("Functiegroep")}</dt>
                    <dd className={"group-only-advice__value"}>
                        <div
                            className={"pattern-group"}>{functionDetail?.function_group_label || functionDetail?.function_group || "-"}</div>
                    </dd>
                </div>
            </dl>

            <ReferenceSummary
                functionDetail={functionDetail}/>

            <div className={"summary-motivation"}>
                <div className="summary-motivation__inner">
                    <h2 className={"summary-motivation__title"}>{t("Onderbouwing")}</h2>
                    <div className={"summary-motivation__text"} dangerouslySetInnerHTML={motivationHtmlContent()}/>
                </div>
            </div>
        </div>

    )
}

export function DomainList(props) {

    const {t, i18n} = useTranslation();

    const {content} = props;


    return (
        <div className={"summary"}>
            {content?.domains?.map((domain, idx) => {
                // <div key={domain.pk} className={"summary"}>
                return (
                    <React.Fragment key={idx}>
                        <div className={"summary__header"}>
                            <h2 className={"summary__title"}>{domain.number}. {domain.name}</h2>
                            <ol className={"grader"}>
                                {domain.scores.filter(s => props?.domainOnly !== true || MNTLetters.includes(s.label)).map((score, scoreIdx) => {
                                    const selected = score.id === props.getStructureStatus(domain.pk)?.score_id;
                                    return (
                                        <li key={scoreIdx} className={"grader__item"}>
                                            <label className={cx("grader__label", {
                                                "grader__label--selected": selected
                                            })}
                                                   data-cy={selected && `domain-${domain.number}-selected`}>{score.label}</label>
                                        </li>
                                    )
                                })}
                            </ol>
                        </div>

                        {props?.domainOnly !== true && domain?.children?.length > 0 && (
                            <div className="summary__body">

                                {domain.children.map((section, sectionIdx) => {
                                    if(section.title === '') {
                                        return (
                                            <Fragment key={sectionIdx}>
                                                <h2 className="summary__sub-title">{t("Hulptabel")}</h2>
                                                <ol className={"summary-grading-indicator"}>
                                                    {MNTLetters.map(o => (
                                                        <li key={o}
                                                            className={"summary-grading-indicator__item summary-grading-indicator__item--header"}>{o}</li>
                                                    ))}
                                                </ol>
                                                <ol className={"summary__list"}>
                                                    {section.children.map((subdomain, subdomainIdx) => {
                                                        return (
                                                            <li className={"summary__list-item"} key={subdomainIdx}>
                                                                <div className="summary__aspect">
                                                                    {subdomain.name}
                                                                </div>
                                                                <ol className="summary-grading-indicator">

                                                                    {subdomain.scores.map((score, scoreIdx) => {
                                                                        const selected = score.id === props.getStructureStatus(subdomain.pk)?.score_id;
                                                                        return (
                                                                            <li
                                                                                key={scoreIdx}
                                                                                className={cx("summary-grading-indicator__item", {
                                                                                    "summary-grading-indicator__item--active": selected
                                                                                })}>
                                                                                {selected && "X"}
                                                                            </li>
                                                                        )

                                                                    })}
                                                                </ol>
                                                            </li>
                                                        )
                                                    })}
                                                </ol>
                                            </Fragment>
                                        )
                                    }
                                })}
                            </div>
                        )}
                    </React.Fragment>
                )
            })}
        </div>

    )

}

export function FunctionCompanySummaryDisplay(props) {

    const {t, i18n} = useTranslation();
    const content = props.content;
    const functionDetail = props.functionDetail;


    function motivationHtmlContent() {

        return {
            __html: functionDetail?.ranking_motivation || t("Geen onderbouwing opgegeven")
        }
    }

    if(!content?.domains) {
        return (
            <LoaderAnimation/>
        )
    }

    return (
        <div className={"summary-container"}>
            <dl className="group-only-advice">
                {['determined', 'expired', 'archived'].includes(functionDetail?.scoring_status) && (
                    <div className={"group-only-advice__column"}>
                        <dt className={"group-only-advice__key"}>{t("Functiegroep")}</dt>
                        <dd className={"group-only-advice__value"}>
                            <div
                                className={"pattern-group"}>{functionDetail?.function_group_label || functionDetail?.function_group || "-"}</div>
                        </dd>
                    </div>
                )}
                {['concept', 'to-counter', 'is-countered'].includes(functionDetail?.scoring_status) && (
                    <>
                        <div className={"group-only-advice__column"}>
                            <dt className={"group-only-advice__key"}>{t("Adviesgroep")}</dt>
                            <dd className={"group-only-advice__value"}>
                                <div className={"pattern-group"}
                                     data-cy={"advice-pattern-group"}>{functionDetail?.function_group_info?.label || functionDetail?.function_group_info?.number || "-"}</div>
                            </dd>
                        </div>
                        <div className={"group-only-advice__column"}>
                            <dt className={"group-only-advice__key"}>{t("Gekozen groep")}</dt>
                            <dd className={"group-only-advice__value"}>
                                {functionDetail?.function_group ? (
                                    <div
                                        className={"pattern-group"}>{functionDetail?.function_group_label || functionDetail?.function_group || "-"}</div>
                                ) : (
                                    <>
                                        {t("Adviesgroep volgen")}
                                    </>
                                )}
                            </dd>
                        </div>
                    </>
                )}
            </dl>
            <GroupAdvice
                edit={false}
                functionDetail={functionDetail}
                fgsDetail={props.fgsDetail}
                summary={true}

            />
            <ReferenceSummary
                functionDetail={functionDetail}/>


            <div className={"summary-motivation"}>
                <div className="summary-motivation__inner">
                    <h2 className={"summary-motivation__title"}>{t("Onderbouwing")}</h2>
                    <div className={"summary-motivation__text"} data-cy={"summary-motivation-text"}
                         dangerouslySetInnerHTML={motivationHtmlContent()}/>
                </div>
            </div>

            <DomainList
                content={content}
                getStructureStatus={props.getStructureStatus}
                // domainOnly={true}
            />

        </div>
    )
}

export default function FunctionGradingSummaryDisplay(props) {

    const {t, i18n} = useTranslation();
    const content = props.content;
    const functionDetail = props.functionDetail;

    const auth = useAuth();
    const canViewPoints = auth.hasPermission('base.view_reference_function_points', functionDetail?.project?.id, 'project');
    const canChangeReference = auth.hasPermission('base.change_reference_function', functionDetail?.project?.id, 'project');

    function motivationHtmlContent() {

        return {
            __html: functionDetail?.ranking_motivation || t("Geen onderbouwing opgegeven")
        }
    }

    if(!content?.domains && canChangeReference) {
        return (
            <LoaderAnimation/>
        )
    }

    return (
        <div className={"summary-container"}>

            <dl className="group-only-advice">
                <div className={"group-only-advice__column"}>
                    <dt className={"group-only-advice__key"}>{t("Functiegroep")}</dt>
                    <dd className={"group-only-advice__value"}>
                        <div
                            className={"pattern-group"}>{functionDetail?.function_group_label || functionDetail?.function_group || "-"}</div>
                    </dd>
                </div>
            </dl>

            <div className={"summary-motivation"}>
                <div className="summary-motivation__inner">
                    <h2 className={"summary-motivation__title"}>{t("Functiebeeld")}</h2>
                    <div className={"summary-motivation__text"} dangerouslySetInnerHTML={motivationHtmlContent()}/>
                </div>
            </div>


            {!canChangeReference && (
                <DomainList
                    content={content}
                    getStructureStatus={props.getStructureStatus}
                    domainOnly={true}
                />
            )}

            {canChangeReference && content.domains.map((domain, domainIdx) => {

                const score = props.functionDetail?.pattern_list?.find((o, idx) => domain.number === (idx + 1) ? o : false);

                return (
                    <div key={domain.pk} className={"summary summary--open"}>
                        <div className={"summary__header"}>
                            <h2 className={"summary__title"}>{domain.number}. {domain.name}</h2>
                            {!props?.fuwater && (
                                <div className={"summary-points-score"}>
                                    {canViewPoints &&
                                        <div className={"summary-points-score__points"}>{score.points}</div>}
                                    <div className={"summary-points-score__score"}>{score.score}</div>
                                </div>
                            )}
                        </div>
                        <div className={"summary__body"}>
                            {domain.children.map((section, sectionIdx) => {
                                return (
                                    <Fragment key={sectionIdx}>
                                        <h2 className={"summary__sub-title"}>{section.title}</h2>
                                        <ol className={"summary-grading-indicator"}>
                                            {(props.fuwater ? FUWATERScores : BKNScores).map(o => {
                                                return (
                                                    <li key={o}
                                                        className={"summary-grading-indicator__item summary-grading-indicator__item--header"}>{o}</li>
                                                )
                                            })}
                                        </ol>
                                        <ol className={"summary__list"}>
                                            {section.children.map(aspect => {
                                                return (
                                                    <li key={aspect.pk} className={"summary__list-item"}>
                                                        <div className={"summary__aspect"}>
                                                            {aspect.name}
                                                        </div>
                                                        <ol className={"summary-grading-indicator"}>
                                                            {aspect.scores.map((score, scoreIdx) => {
                                                                const selected = score.id === props.getStructureStatus(aspect.pk)?.score_id;
                                                                return (
                                                                    <li key={scoreIdx}
                                                                        className={cx("summary-grading-indicator__item", {
                                                                            "summary-grading-indicator__item--active": selected,
                                                                        })}>
                                                                        {selected && "X"}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ol>
                                                    </li>
                                                )

                                            })}
                                        </ol>
                                    </Fragment>
                                )

                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
