import React from 'react';
import Icon from "./Icon";
import {useTranslation} from "react-i18next";
import cx from "classnames";

export default function Select(props) {
    const {t} = useTranslation();

    return (
        <>
            {props.label &&
                <div className={"input-group__label-container"}>
                    <label className={"input-group__label"} htmlFor={props.id}>{props.label}</label>
                    {props.required && props.type !== "hidden" &&
                        <span className={"input-group__required"}>{t("Verplicht")}</span>
                    }
                </div>
            }
            {props.helpText &&
                <p className={"input-group__help-text"}>{props.helpText}</p>
            }
            {props.error && (
                <div className={"input-group__error-message"}>
                    <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}
                          data-cy={props.id + "-select-error-icon"}/>
                    <p data-cy={props.id + "-select-error-message"}>{props.error.message}</p>
                </div>
            )}
            <div className={cx("input-group__input", {"input-group__input--error": props.error})}>
                <div className={"select-container"}>
                    <select id={props.id}
                            disabled={props?.disabled}
                            onChange={(...args) => {
                                if (props?.onChange) {
                                    props.onChange(...args);
                                }
                            }}
                            data-cy={props.id + "-select"}
                            {...props.input}
                            {...(props.register && props.register(props.name, {required: props.required && t("Dit is een verplicht veld")}))}
                    >
                        {props.placeholder && <option key={-1} value={""}>{props.placeholder}</option>}
                        {props?.options?.map((option, index) => {
                            return (
                                <option key={index} value={option.display_name ? (option.value) : (option.id)}>
                                    {t(option.display_name ? (option.display_name) : (option.name))}
                                </option>
                            )
                        })}
                    </select>
                    <Icon icon={"angle-down"} sizeModifier={"small"} className={"select-container__icon"}/>
                </div>
            </div>
        </>
    )
}
