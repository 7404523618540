import React, {Suspense, useEffect, useState} from "react";
import {
    BrowserRouter as Router,
    Link,
    Route,
    Routes,
    Outlet,
    useLocation,
    useNavigate,
    useParams, useRoutes, generatePath,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import { useTranslation } from "react-i18next";

import { addItem } from "../actions/header";
import Header from "./Header";

import '../../scss/screen.scss';
import Login from "./Login";
import { withCookies } from "react-cookie";
import {Breadcrumbs} from "../patterns/organisms/Breadcrumbs";
import DefaultScreen, {InsightScreen} from "./Screens/DefaultScreen";
import FormScreen from "./Screens/FormScreen";
import FunctionDetailScreen from "./Screens/FunctionDetailScreen";
import ProjectOverviewScreen from "./Screens/ProjectOverviewScreen";
import ProjectMeta from "./ProjectMeta";
import { MonitorHeartbeat } from "./Heartbeat";
import axios from "axios";
import i18n from "../utils/i18n";
import {adminTabsProps} from "../utils/constants";

// detail screens
import Dashboard from "./Dashboard";
import ProjectDetail from "./ProjectDetail";

// overviews
import ClientOverview from "./Overviews/ClientOverview";
import ClientProjectOverview from "./Overviews/ClientProjectOverview";
import {
    InsightProjectFunctionOverview,
    ProjectFunctionOverview,
    ProjectFunctionOverviewBase
} from "./Overviews/ClientProjectFunctionOverview";


// create stubbs
import { UserCreate } from "../patterns/organisms/UserCreate";
import { UserEdit } from "../patterns/organisms/UserEdit"
import { ClientCreate } from "../patterns/organisms/ClientCreate";
import { ClientEdit } from "../patterns/organisms/ClientEdit";
import { FunctionCreate } from "../patterns/organisms/FunctionCreate";
import { FunctionEdit} from "../patterns/organisms/FunctionEdit";
import { ProjectCreate } from "../patterns/organisms/ProjectCreate";
import { ProjectEdit } from "../patterns/organisms/ProjectEdit";
import { LicenseRequestCreate } from "../patterns/organisms/LicenseRequestCreate";
import {setLogin, setNext, setOption} from "../actions/auth";
import {AuthDefaultState} from "../reducers/auth";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {ProjectUserOverview} from "./Overviews/ProjectUserOverview";
import {ProjectProjectsOverview} from "./Overviews/ProjectProjectsOverview";
import UserOverviewScreen from "./Screens/UserOverviewScreen";
import UserGroupOverviewScreen from "./Screens/UserGroupOverviewScreen";
import FunctionOverviewScreen from "./Screens/FunctionOverviewScreen";
import Export from "./Export";
import {ProjectUserGroupDetail} from "./UserGroupDetail";
import {SWRConfig} from "swr";
import {ProjectGroupOverviewScreen}from "./Screens/ProjectGroupOverviewScreen";
import {ProjectGroupDetailScreen} from "./Screens/ProjectGroupDetailScreen";
import UserDetailScreen from "./Screens/UserDetailScreen";
import {useAuth} from "../utils/useAuth";
import {AccessDenied} from "../patterns/organisms/AccessDenied";
import Noty from "noty";
import {UserRoleScreen} from "./Screens/UserRoleScreen";
import {UserAssignRole} from "./Screens/UserAssignRole";
import {FunctionReport} from "../patterns/organisms/FunctionReport";
import {ProjectReport} from "../patterns/organisms/ProjectReport";
import ClientMeta from "./ClientMeta";
import FunctionSummary, {InsightSummary} from "./FunctionSummary";
import ProjectFileUpload from "./Screens/ProjectFileUpload";
import DescriptionTemplate from "./DescriptionTemplate";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import MethodOverviewScreen from "./Screens/MethodOverviewScreen";
import MethodDetailScreen from "./Screens/MethodDetailScreen";
import DefaultTextOverviewScreen from "./Screens/DefaultTextOverviewScreen";
import DefaultTextDetailScreen from "./Screens/DefaultTextDetailScreen";
import ClientFileUpload from "./Screens/ClientFileUpload";
import {ReportsOverview} from "./Screens/ReportsOverview";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import {ProjectInsight} from "../patterns/organisms/ProjectInsight";
import LicenseOverview from "./Overviews/LicenseOverview";
import {LicenseEdit} from "../patterns/organisms/LicenseEdit";
import EnvironmentOverview from "./Overviews/EnvironmentOverview";
import { EnvironmentCreate } from "../patterns/organisms/EnvironmentCreate";
import {EnvironmentEdit} from "../patterns/organisms/EnvironmentEdit";
import TabScreen from "./Screens/TabScreen";
import ProjectCreateSelector from "../patterns/organisms/ProjectCreateSelector";
import ProjectCreateCao from "../patterns/organisms/ProjectCreateCao";
import LoginAdmin from "./LoginAdmin";

// function reload_monitor() {
//
//     const ws = {};
//     ws.current = new W3CWebSocket('ws://127.0.0.1:8000');
//     ws.current.onopen = () => console.log("Auto reloading enabled (socket ready)");
//     ws.current.onclose = () => {
//         console.log("Auto reloading disabled (socket unavailable)");
//         setTimeout(() => monitor(), 5000);
//     }
//
//     ws.current.onmessage = (message) => {
//         let data = JSON.parse(message.data);
//         if(data.manifest_changed === true) {
//             window.location.href = window.location.href;
//         }
//     }
// }


function QuickMessage(props) {
    const {t} = useTranslation();

    return (
        <ConfirmModal
            title={"FUWA Online"}
            body={props.message}
            singular={true}
            confirmText={t("Ok")}
            closeModal={() => {
                window.location.href = '/';
            }}
        />

    )
}

function QuickForm(props) {

    const {t, i18n} = useTranslation();

    /*
        props.endpoint: '/api/create_client/'
        props.inputs = [
            {
                'name': 'name',
            },
            {
                'name': 'email',
            }
        ]
     */
    let states = {};
    props.inputs.map((obj,idx) => {
        states[obj.name] = useState('');
    })


    const save = (e) => {
        let data = {};
        Object.entries(states).map( (item,idx) => {
            const [key, content] = item;
            data[key] = content[0];
        });
        axios.post(props.endpoint, data).then(result => {
            return result;
        });
    }

    return (
        <div className={"content-container"} style={{backgroundColor: "white"}}>
            <form>
                {Object.entries(states).map( (item, idx) => {
                    return (
                        <input key={idx}
                               type={item[0].substring(item[0].length -3, item[0].length) === '_id' ? "number": "text"}
                               name={item[0]}
                               placeholder={item[0]}
                               value={item[1][0]}
                               onChange={e => item[1][1](e.target.value)}
                        />
                    )

                })}
            </form>
            <br/>
            <a href="#"
               className={"button button--primary"}
               onClick={(e) => save(e)}
            >{t("Maak nieuwe aan")}</a>
            <br/>
        </div>
    )
}

// Easy component which defines the Form for "Creating a client"
function CreateExample(props) {
    return (
        // The form can not handle other inputs, nor can it handle complex behavior
        <QuickForm
            endpoint={"/api/example_create/"}        // Endpoint to call and POST the data to
            inputs={[
                {
                    name: "name",                   // an input with name "name"
                },
            ]}
        />
    )
}

function Section(props) {

    let params = useParams();
    let location = useLocation();

    console.log(props, params, location);

    return (
        <div>
            Section: {props.name}
            <Outlet />
        </div>
    )
}

function Default(props) {
    const {t, i18n} = useTranslation();
    let navigate = useNavigate();

    const auth = useAuth();

    const dispatch = useDispatch();
    useEffect(() => {
        if(!auth.user.authorized) {
            dispatch(setNext(location.pathname));
            navigate('/', {
                replace: false,
                state: {
                    next: location.pathname,
                }
            });
        }
    })
    return  (
        <React.Fragment>
            <br/>
            <br/>
            404 - Pagina niet gevonden<br/>
            <br/>
            <ButtonPrimary onClick={() => {
                navigate(generatePath('/dashboard/'));
            }}>
                {t("Ga naar de startpagina")}
            </ButtonPrimary>
        </React.Fragment>
    );
}

export const insightRouteList = [
    { path: 'home/*',                                                                   element: <InsightScreen><InsightProjectFunctionOverview /></InsightScreen>, breadCrumb: i18n.t("")},
    { path: 'client/*',                                                                 element: <InsightScreen/>, breadCrumb: ""},
    { path: 'function/:function_id/*',                                                  element: <InsightScreen><InsightSummary/></InsightScreen>, breadCrumb: i18n.t("FunctionName")},
]
export const flatRouteList = [
    // { path: '/',                                                                     element: <Login/> },
    { path: 'home/*',                                                                   element: <DefaultScreen><Dashboard/></DefaultScreen>, breadCrumb: i18n.t( "Dashboard") },
    { path: 'dashboard/*',                                                              element: <DefaultScreen><Dashboard/></DefaultScreen>, breadCrumb: i18n.t( "Dashboard") },
    { path: 'client/*',                                                                 element: <DefaultScreen><ClientOverview/></DefaultScreen>, breadCrumb: i18n.t( "Klanten")},
    // { path: 'client/export/pdf',                                                     element: <FormScreen><Export model="export_client_list" type={"pdf"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'client/export/excel',                                                      element: <FormScreen><Export model="export_client_list" type={"xlsx"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar Excel")},
    { path: 'client/export/pdf',                                                        element: <FormScreen><Export model="export_client_list" type={"pdf"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'client/create',                                                            element: <FormScreen><ClientCreate/></FormScreen>, breadCrumb: i18n.t( "Klant aanmaken")},
    // { path: 'project/*',                                                             element: <DefaultScreen><Breadcrumbs /><ProjectOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Projecten")},
    { path: 'client/:client_id/*',                                                      element: <DefaultScreen><Breadcrumbs /><ClientProjectOverview/></DefaultScreen>, breadCrumb: i18n.t( "ClientName")},
    { path: 'client/:client_id/edit',                                                   element: <FormScreen><ClientEdit/></FormScreen>, breadCrumb: i18n.t( "Klant bewerken")},
    { path: 'client/:client_id/usergroup/*',                                            element: <DefaultScreen><Breadcrumbs /><ClientMeta /><UserGroupOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Gebruikersgroepen")},
    { path: 'client/:client_id/usergroup/:user_group_id/*',                             element: <DefaultScreen><Breadcrumbs /><ClientMeta/> <ProjectUserGroupDetail/></DefaultScreen>, breadCrumb: i18n.t( "UserGroupName")},
    { path: 'client/:client_id/user/*',                                                 element: <DefaultScreen><Breadcrumbs /><ClientMeta/><UserOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Gebruikers")},
    { path: 'client/:client_id/user/:user_id/*',                                        element: <DefaultScreen><Breadcrumbs/><UserDetailScreen/></DefaultScreen>, breadCrumb: i18n.t( "UserName")},
    { path: 'client/:client_id/user/:user_id/edit',                                     element: <FormScreen><UserEdit/></FormScreen>, breadCrumb: i18n.t( "Gebruiker bewerken")},
    { path: 'client/:client_id/user/:user_id/roles/*',                                  element: <DefaultScreen><Breadcrumbs/><UserRoleScreen/></DefaultScreen>, breadCrumb: i18n.t( "Gebruikersrollen")},
    { path: 'client/:client_id/user/create',                                            element: <FormScreen><UserCreate/></FormScreen>, breadCrumb: i18n.t( "Gebruiker aanmaken")},
    { path: 'client/:client_id/files/*',                                                element: <DefaultScreen><Breadcrumbs /><ClientMeta /><ClientFileUpload/></DefaultScreen>, breadCrumb: i18n.t( "Klantbestanden")},
    // { path: 'client/:client_id/user/export/pdf',                                     element: <FormScreen><Export type={"pdf"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'client/:client_id/user/export/excel',                                      element: <FormScreen><Export model="export_user_list" type={"xlsx"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar Excel")},
    { path: 'client/:client_id/user/export/pdf',                                        element: <FormScreen><Export model="export_user_list" type={"pdf"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'default-text/*',                                                           element: <TabScreen {...adminTabsProps}><DefaultTextOverviewScreen/></TabScreen>, breadCrumb: i18n.t( "Standaard teksten")},
    { path: 'default-text/:default_text_id/*',                                          element: <DefaultScreen><Breadcrumbs /><DefaultTextDetailScreen/></DefaultScreen>, breadCrumb: i18n.t( "Standaard teksten")},
    { path: 'method/*',                                                                 element: <TabScreen {...adminTabsProps}><MethodOverviewScreen/></TabScreen>, breadCrumb: i18n.t( "Methode")},
    { path: 'method/:method_id/*',                                                      element: <DefaultScreen><Breadcrumbs /><MethodDetailScreen/></DefaultScreen>, breadCrumb: i18n.t( "Methode")},
    { path: 'user/*',                                                                   element: <DefaultScreen><UserOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Gebruikers")},
    { path: 'user/create',                                                              element: <FormScreen><UserCreate/></FormScreen>, breadCrumb: i18n.t( "Gebruiker aanmaken")},
    { path: 'user/:user_id/*',                                                          element: <DefaultScreen><Breadcrumbs/><UserDetailScreen/></DefaultScreen>, breadCrumb: i18n.t( "UserName")},
    { path: 'user/:user_id/edit',                                                       element: <FormScreen><UserEdit/></FormScreen>, breadCrumb: i18n.t( "Gebruiker bewerken")},
    { path: 'user/:user_id/roles/*',                                                    element: <DefaultScreen><Breadcrumbs/><UserRoleScreen/></DefaultScreen>, breadCrumb: i18n.t( "Gebruikersrollen")},
    { path: 'user/:user_id/assign/*',                                                   element: <FormScreen><UserAssignRole/></FormScreen>, breadCrumb: i18n.t( "Rol toewijzen")},
    { path: 'projectgroup/*',                                                           element: <DefaultScreen><ProjectGroupOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Projectgroepen")},
    { path: 'projectgroup/:project_group_id/*',                                         element: <DefaultScreen><Breadcrumbs /><ProjectGroupDetailScreen/></DefaultScreen>, breadCrumb: i18n.t( "ProjectGroupName")},
    { path: 'project/*',                                                                element: <DefaultScreen><ProjectOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Projecten")},
    { path: 'project/export/excel',                                                     element: <FormScreen><Export model="export_project_list" type={"xlsx"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar Excel")},
    { path: 'project/export/pdf',                                                       element: <FormScreen><Export model="export_project_list" type={"pdf"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'project/:project_id/function/*',                                           element: <DefaultScreen><Breadcrumbs /><ProjectMeta /><ProjectFunctionOverview/></DefaultScreen>, breadCrumb: i18n.t( "ProjectName")},
    { path: 'project/selector/create-cao',                                              element: <FormScreen><ProjectCreateCao/></FormScreen>},
    { path: 'project/selector/create',                                                  element: <FormScreen><ProjectCreate/></FormScreen>, breadCrumb: i18n.t( "Project aanmaken")},
    { path: 'project/selector',                                                         element: <FormScreen><ProjectCreateSelector/></FormScreen>},
    { path: 'function/*',                                                               element: <DefaultScreen><FunctionOverviewScreen/></DefaultScreen>, breadCrumb: i18n.t( "Functies")},
    { path: 'reports/*',                                                                element: <DefaultScreen><ReportsOverview/></DefaultScreen>, breadCrumb: i18n.t( "Rapportages")},
    { path: 'function/create',                                                          element: <FormScreen><FunctionCreate/></FormScreen>, breadCrumb: i18n.t( "Functie aanmaken")},
    { path: 'client/:client_id/project/export/excel',                                   element: <FormScreen><Export model={"export_project_list"} type={"xlsx"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar Excel")},
    { path: 'client/:client_id/project/export/pdf',                                     element: <FormScreen><Export model={"export_project_list"} type={"pdf"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'client/:client_id/project/selector/create-cao',                            element: <FormScreen><ProjectCreateCao/></FormScreen>},
    { path: 'client/:client_id/project/selector/create',                                element: <FormScreen><ProjectCreate/></FormScreen>, breadCrumb: i18n.t( "Project aanmaken")},
    { path: 'client/:client_id/project/selector',                                       element: <FormScreen><ProjectCreateSelector/></FormScreen>},
    { path: 'client/:client_id/project/:project_id/*',                                  element: <DefaultScreen><Breadcrumbs /><ProjectDetail/></DefaultScreen>, breadCrumb: i18n.t( "ProjectName")},
    { path: 'client/:client_id/project/:project_id/edit',                               element: <FormScreen><ProjectEdit/></FormScreen>, breadCrumb: i18n.t( "Project bewerken")},
    { path: 'client/:client_id/project/:project_id/insight',                            element: <FormScreen><ProjectInsight/></FormScreen>, breadCrumb: i18n.t( "Inzageaccount")},
    { path: 'client/:client_id/project/:project_id/user/*',                             element: <DefaultScreen><Breadcrumbs /><ProjectUserOverview/></DefaultScreen>, breadCrumb: i18n.t( "Externe toegang")},
    { path: 'client/:client_id/project/:project_id/report/*',                           element: <FormScreen><ProjectReport/></FormScreen>, breadCrumb: i18n.t( "Functie rapportage")},
    { path: 'client/:client_id/project/:project_id/function-report/*',                  element: <FormScreen><FunctionReport/></FormScreen>, breadCrumb: i18n.t( "Functie rapportage")},
    { path: 'client/:client_id/project/:project_id/user/create',                        element: <FormScreen><UserCreate/></FormScreen>, breadCrumb: i18n.t( "Gebruiker aanmaken")},
    { path: 'client/:client_id/project/:project_id/projects/*',                         element: <DefaultScreen><Breadcrumbs /><ProjectProjectsOverview/></DefaultScreen>, breadCrumb: i18n.t( "Project projecten")},
    { path: 'client/:client_id/project/:project_id/files/*',                            element: <DefaultScreen><Breadcrumbs /><ProjectMeta /><ProjectFileUpload/></DefaultScreen>, breadCrumb: i18n.t( "Projectbestanden")},
    { path: 'client/:client_id/project/:project_id/template/*',                         element: <DefaultScreen><Breadcrumbs /><ProjectMeta /><DescriptionTemplate/></DefaultScreen>, breadCrumb: i18n.t( "Standaard template")},
    { path: 'client/:client_id/project/:project_id/function/*',                         element: <DefaultScreen><Breadcrumbs /><ProjectMeta /><ProjectFunctionOverview/></DefaultScreen>, breadCrumb: i18n.t( "Functieoverzicht")},
    { path: 'client/:client_id/project/:project_id/function/create',                    element: <FormScreen><FunctionCreate/></FormScreen>, breadCrumb: i18n.t( "Functie aanmaken")},
    { path: 'client/:client_id/project/:project_id/export/pdf',                         element: <FormScreen><Export type={"pdf"} /></FormScreen>, breadCrumb: i18n.t( "Exporteer naar PDF")},
    { path: 'client/:client_id/project/:project_id/export/excel',                       element: <FormScreen><Export type={"xlsx"}/></FormScreen>, breadCrumb: i18n.t( "Exporteer naar Excel")},
    // { path: 'client/:client_id/project/:project_id/function/*',                      element: <DefaultScreen><ProjectDetail/></DefaultScreen>, breadCrumb: i18n.t("Functies") },
    // { path: 'client/:client_id/project/:project_id/edit',                            element: <FunctionDetailScreen/>},
    { path: 'client/:client_id/project/:project_id/function/:function_id/*',            element: <DefaultScreen><FunctionDetailScreen/></DefaultScreen>, breadCrumb: i18n.t("FunctionName")},
    { path: 'client/:client_id/project/:project_id/function/:function_id/edit',         element: <FormScreen><FunctionEdit/></FormScreen>, breadCrumb: i18n.t("Functie bewerken")},
    { path: 'client/:client_id/project/:project_id/function/:function_id/report',       element: <FormScreen><FunctionReport/></FormScreen>, breadCrumb: i18n.t("Functie bewerken")},
    { path: 'backoffice/license/*',                                                     element: <DefaultScreen><LicenseOverview /></DefaultScreen>, breadCrumb: i18n.t("Licenties")},
    { path: 'backoffice/license/:license_id/edit',                                      element: <FormScreen><LicenseEdit/></FormScreen>, breadCrumb: i18n.t("Licentie bewerken")},
    { path: 'environment/*',                                                            element: <TabScreen {...adminTabsProps}><EnvironmentOverview/></TabScreen>},
    { path: 'client/:client_id/project/:project_id/environment/create',                 element: <FormScreen><EnvironmentCreate/></FormScreen>},
    { path: 'client/:client_id/environment/create',                                     element: <FormScreen><EnvironmentCreate/></FormScreen>},
    { path: 'environment/create',                                                       element: <FormScreen><EnvironmentCreate/></FormScreen>},
    { path: 'environment/:environment_id/edit',                                         element: <FormScreen><EnvironmentEdit/></FormScreen>},
    { path: 'login-admin',                                                              element: <TabScreen {...adminTabsProps}><LoginAdmin/></TabScreen>}
    // { path: 'client/:client_id/project/:project_id/function/:function_id/description',  element: <Section name={"Client project function description"} />},
    // { path: 'client/:client_id/project/:project_id/function/:function_id/scoring',      element: <Section name={"Client project function scoring"} />},
]

export const publicRouteList= [
    { path: '/',                                                element: <Login/> },
    { path: 'license/*',                                        element: <FormScreen><LicenseRequestCreate/></FormScreen>},
    { path: 'license/thanks/*',                                 element: <QuickMessage message={i18n.t("Bedankt voor uw aanvraag! Wij nemen zo spoedig mogelijk contact met u op")}/>},
    { path: '*',                                                element: <Default/> },
]

// export const allRoutes = [...publicRouteList, ...flatRouteList, ...insightRouteList];

function setCssVariable(cssVar, cssValue) {
    const r = document.querySelector(':root');
    r.style.setProperty(cssVar, cssValue)

}


export function FUWA(props) {

    const {t, i18n} = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    const location = useLocation();
    const navigate = useNavigate();

    const state = useSelector(state => state.AuthState);

    const themes = ['light', 'dark', 'auto'];
    const dispatch = useDispatch();
    const cookieTheme = props.cookies.get('theme');
    const [theme, setTheme] = useState(themes[0]);
    const themeObj = {
        themes,
        theme,
        setTheme
    }
    const auth = useAuth();
    let toCheck = auth.authorized() === null ;
    let routes = publicRouteList;


    useEffect(() => {
        function handleResize() {
            setCssVariable("--viewportWidth", window.visualViewport.width);
        }
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        }

    }, []);

    useEffect(() => {

        // dispatch(addItem((<Link to={'/dashboard'} className={"dropdown-field__label"}>{t("Dashboard")}</Link>)))
        // dispatch(addItem((<Link to={'/client'} className={"dropdown-field__label"}>{t("Klanten")}</Link>)))
        // dispatch(addItem((<Link to={'/user'} className={"dropdown-field__label"}>{t("Gebruikers")}</Link>)))
        // dispatch(addItem((<Link to={'/project'} className={"dropdown-field__label"}>{t("Projecten")}</Link>)))
        // dispatch(addItem((<Link to={'/function'} className={"dropdown-field__label"}>{t("Functies")}</Link>)))
        // dispatch(addItem((<hr/>)));
        // dispatch(addItem((<a className={"dropdown-field__label"} href={"/logout/"}>{t("Uitloggen")}</a>)));
        // reload_monitor();

        if(cookieTheme !== theme) {
            setTheme(cookieTheme || theme);
        }
    },[]);

    useEffect(() => {
        if(auth.user?.client?.language) {
            i18n.changeLanguage(auth.user?.client?.language)
        }
    }, [auth.user.client]);

    useEffect(() => {
        props.cookies.set('theme', theme, { path: '/'})
        document.documentElement.dataset.theme = themes.includes(theme) ? theme : themes[0];
    }, [theme]);

    useEffect(() => {
        if(auth.authorized() && ['/','/login','/login/'].includes(location.pathname)) {
            if(auth.insightUser) {
                navigate(state.next || 'home');
            }
            navigate(state.next || 'dashboard');
            dispatch(setOption({
                error_message: false,
            }))
        }
    }, [auth.authorized()])


    // useEffect(() => {
    //     if(location?.pathname) {
    //         const route = allRoutes.find(o => o.path === location.pathname)
    //     }
    // }, [location.pathname]);

    if(auth.authorized() && auth.insightUser === false) {
        routes = [ ...routes, ...flatRouteList];
    }
    if(auth.authorized() && auth.insightUser === true) {
        routes = [ ...routes, ...insightRouteList];
    }

    // auth state not known yet, we just initialized, we should handle this and make sure we are either auth=true or auth=false
    if(toCheck){
        auth.validate_login();
        return <div className={"load-screen"}><LoaderAnimation /></div>
    }

    const Routes = ()  => useRoutes(routes);

    return (
        <>
            <SWRConfig
                value={{
                    persistSize: false,          // makes sure requests for Overveiws stay on same page when ordering changes
                    dedupingInterval: 5000,    // ignores additional calls with the same key (prevents hammering)
                    fetcher: url => axios.get(url).then(res => res.data ).catch(error => {
                        console.log(error.response);
                        if(error.response.status === 404 || error.response.status === 403) {
                            console.log("404 || 403", error.response);
                            return Promise.reject({noAccess:true});
                        }

                        auth.validate_login();
                        dispatch(setOption({
                            error_message: "auth_failed"
                        }))
                        return Promise.reject(error);
                    }),
                    shouldRetryOnError: false,
                    onError: (error,key) => {
                        console.log({error: error});
                        if(error.status !== 403 && error.status !== 404) {
                            // sentry here?
                        }
                    }
                }}>
                <MonitorHeartbeat/>
                <Routes/>
            </SWRConfig>
        </>
    )
}

// FUWA.whyDidYouRender = true;

export default withCookies(FUWA);
