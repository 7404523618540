import React, {useState, useEffect, Fragment, memo, useRef} from "react";
import {getScores, getStructure} from "../queries/scoring";
import {useDispatch, useSelector} from "react-redux";
import cx from "classnames";
import {
    remoteRemoveScore,
    remoteSetScore,
    setDomain,
    setFunctionScores,
    setScore,
    setScoreLoading,
    setSubset,
    setFunctionId,
} from "../actions/scoring";
import {useParams, useRouteMatch} from "react-router-dom";
import DomainSummaryDisplay from "../patterns/organisms/DomainSummary";
import ScoringSetDisplay from "../patterns/organisms/ScoringSet";
import ScoringItemDisplay, {ScoreReference} from "../patterns/organisms/ScoringItem";
import useSWR, { mutate } from 'swr';
import {getFunction} from "../queries/function_detail";
import {getProject} from "../queries/project_detail";
import Comments from "../patterns/organisms/Comments";
import IconButton from "../patterns/molecules/IconButton";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import Modal from "react-modal";
import ButtonSecondary from "../patterns/atoms/ButtonSecondary";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import {useTranslation} from "react-i18next";
import {Icon} from "../patterns/atoms/Icon";
import {DescriptionDisplay} from "./Description";
import axios from "axios";

export const ScoringDefaultState = {
    functions: {},
    highlights: [],
    selectedDomain: 1,
    searchOption: '',
    function_id: undefined
}

function DomainSummary(props) {

    const state = useSelector(state => state.ScoringState.selectedDomain);
    const dispatch = useDispatch();

    const functionDetail = getFunction(props.function_id);
    const structureDetail = getStructure(props.methodId, functionDetail?.data?.type);
    // const scores = getScores(props.function_id)


    if(!structureDetail?.data) {
        return (
            <LoaderAnimation />
        )
    }

    return (
        <DomainSummaryDisplay
            structure={structureDetail?.data}
            selectedDomain={state}
            getDomainStatus={props.getDomainStatus}
            // getStructureStatus={props.getStructureStatus}
            // getStructureMessages={props.getStructureMessages}
            onClick={(identifier) =>  dispatch(setDomain(identifier))}
        />
    )

}

export function AspectDescriptionModal(props) {


    const {t, i18n} = useTranslation();
    const description = props.aspect?.description;
    const [active, setActive] = useState(false);

    function domainDescription(content) {
        return {
            __html: content
        }
    }

    return (
        <>
            <ToolTip tooltip={t("Definitie")}>
                <IconButton icon={"definition"} sizeModifier={"small"}
                            title={t("Toon definitie")}
                            onClick={() => setActive(true)}
                />
            </ToolTip>
            {active && (
                <Modal isOpen={active !== false}
                       className={"modal modal--full-screen"}
                       overlayClassName={"modal-background"}
                       bodyOpenClassName={"has-modal"}>
                    <div className={"modal-header"}>
                        <div className={"modal-header__top"}>
                            <h2 className={"modal-header__title"}>{t(props.aspect.name)}</h2>
                            <ul className={"modal-header__controls"}>
                                <li className={"modal-header__control"}>
                                    <IconButton icon={"close"} onClick={() => setActive(false)}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"comments-header"}> {/* FIXME: this is temporary, this will be tabs */}
                        {t("Definitie")}
                    </div>
                    <div className="definition-body">

                        <div className="description-editor__rich-text" dangerouslySetInnerHTML={domainDescription(description)} />
                    </div>
                </Modal>
            )}
        </>
    )

}
export function DomainDescriptionModal(props) {

    const {t, i18n} = useTranslation();
    const description = props.currentDomain?.description;
    const [active, setActive] = useState(false);

    function domainDescription(content) {
        return {
            __html: content
        }
    }

    return (
        <>
            <ToolTip tooltip={t("Definitie")}>
                <IconButton icon={"definition"} sizeModifier={"small"}
                            title={t("Toon definitie")}
                            onClick={() => setActive(true)}
                />
            </ToolTip>
            {active && (
                <Modal isOpen={active !== false}
                       className={"modal modal--full-screen"}
                       overlayClassName={"modal-background"}
                       bodyOpenClassName={"has-modal"}>
                    <div className={"modal-header"}>
                        <div className={"modal-header__top"}>
                            <h2 className={"modal-header__title"}>{t("Toelichting")}</h2>
                            <ul className={"modal-header__controls"}>
                                <li className={"modal-header__control"}>
                                    <IconButton icon={"close"} onClick={() => setActive(false)}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"modal__body"}>
                        <div className="description-editor__rich-text" dangerouslySetInnerHTML={domainDescription(description)} />
                    </div>
                    <div className="modal-footer">
                        <ul className={"modal__buttons"}>
                            <li className={"modal__button"}>
                                <ButtonPrimary
                                    onClick={() => setActive(false)}>
                                    {t("Sluiten")}
                                </ButtonPrimary>
                            </li>
                        </ul>
                    </div>
                </Modal>
            )}
        </>
    )

}

const _getStructureMessages = (structure_id, type='notes', messageObject={}) => {
    const options = ['notes', 'comments'];
    if(!options.includes(type)) {
        return [];
    }

    let messages = messageObject?.scoringstructure;
    return messages?.[type].filter(o => o.scoring_structure_id === structure_id);
}


export function Grading(props) {

    let { function_id, project_id } = useParams();
    function_id = parseInt(function_id);

    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();

    const stateSelectedDomain = useSelector(state =>  state.ScoringState.selectedDomain);
    const stateFunctionId = useSelector(state =>  state.ScoringState.function_id);

    const {data: functionDetail, mutate: mutateFunction} = useSWR( () => function_id ? `/api/functions/${function_id}/` : false);
    const {data: projectDetail , mutate: mutateProject } = useSWR(() => project_id  ? `/api/projects/${project_id}/` : false);
    const {data: structure, isError: structureError } = getStructure(projectDetail?.method?.id, functionDetail?.type);
    const debounceUpdate = useDebounce2(value => mutateFunction(), 3000);
    const canEdit = props?.editState?.canEdit && !props?.editState?.scoring_immutable || false;

    const [scoredDomains, setScoredDomains] = useState({});
    const [faultyScores, setFaultyScores] = useState([]);
    const [faultyDomains, setFaultyDomains] = useState([]);
    const [currentDomain, setCurrentDomain] = useState(false);

    const titleRef = useRef();

    const getStructureStatus = (structure_id) => functionDetail?.scores.find( obj => obj.structure_id === structure_id);

    const getStructureMessages = (structure_id, type) => _getStructureMessages(structure_id, type, functionDetail?.messages);

    const setScore = async(structure_id, score_id, remove=false) => {
        dispatch(setScoreLoading(function_id, structure_id));
        if(canEdit) {
            const {data} = remove ?
                await remoteRemoveScore(function_id, false, score_id)
                :
                await remoteSetScore(function_id, false, score_id);

            debounceUpdate();

            return data?.status === 'ok';
        }
    }

    useEffect(() => {

        if(stateFunctionId !== function_id) {
            dispatch(setFunctionId(function_id));
            dispatch(setDomain(1))
        }

    }, [function_id])

    useEffect(() => {
        if(functionDetail?.scores) {
            dispatch(setFunctionScores(function_id, functionDetail.scores))
        }
    }, [functionDetail?.scores])

    // When function is loaded/updated, parse the scores so we have information on what the status is of the domains
    useEffect(() => {
        if(structure && functionDetail?.scores?.length > 0) {
            let newScoredDomains = {};

            functionDetail?.scores.map( obj => {
                let domainId = structure?.structureIdToDomain?.[obj.structure_id];
                if(!newScoredDomains?.[domainId]) {
                    newScoredDomains[domainId] = [];
                }
                newScoredDomains[domainId].push(obj.structure_id);
            })
            setScoredDomains(newScoredDomains);

            // find faulty scores & store
            const newFaultyScores = functionDetail?.scores.filter( obj => ( (obj?.valid === false  && obj?.updating !== true) || obj?.warning === true)) || []
            setFaultyScores(faultyScores);

            // find faulty domains & store
            const newFaultyDomains = newFaultyScores.map( obj => structure?.structureIdToDomain?.[obj.structure_id]);
            setFaultyDomains(newFaultyDomains);

        }
    }, [functionDetail?.scores?.length, structure]);

    useEffect(() => {
        if(stateSelectedDomain && structure) {
            setCurrentDomain(structure?.domains.find(obj => obj.number === stateSelectedDomain));
        }
    }, [stateSelectedDomain, structure]);

    if(!structure) {
        return <LoaderAnimation/>
    }

    if(structureError) {
        return <AccessDenied error={structureError} />;
    }

    // console.log("Grading render");
    return (
        <>
            {/*
                I know this is not the way... but its a quickfix which won't break other components
                We should pass the ref to childs, but thats more error-prone at this time
            */}
            <div ref={titleRef}/>
            <DomainSummary
                function_id={function_id}
                methodId={projectDetail?.method.id}
                getDomainStatus={(domain_id) => {
                    let total = 0;
                    structure?.domains.find(obj => obj.pk === domain_id)?.children?.map( obj => {
                        total = total + obj.children.length;
                    })
                    let isFullyScored = total === scoredDomains[domain_id]?.length;
                    return faultyDomains.includes(domain_id) ? 'invalid': (isFullyScored ? 'valid' : '');
                } }
            />
            {currentDomain && (
                <div className={"scoring__set"}>
                    {(currentDomain.name || currentDomain.number) && (

                        <div className={"scoring__header"}>
                            {currentDomain.name && (<h3 className={"scoring__domain-title"}>{currentDomain.name}</h3>)}
                            {currentDomain.number && (<h4 className={"scoring__domain-number"}>{t("Domein")} {currentDomain.number}</h4>)}

                            <ul className={"scoring-options"}>
                                {/*<li className={"scoring-option"}>*/}
                                {/*    <IconButton icon={"compare-domain"} sizeModifier={"small"} title={t("Vergelijken")}  />*/}
                                {/*</li>*/}

                                <li className={"scoring-option"}>
                                    <DomainDescriptionModal
                                        currentDomain={currentDomain}/>
                                </li>
                                <li className={cx("scoring-option")}>
                                    <Comments
                                        type={"comment"}
                                        comments={getStructureMessages(currentDomain.pk, 'comments')}
                                        identifier={currentDomain.pk}
                                        scoringItem={currentDomain}
                                    />
                                </li>
                                <li className={cx("scoring-option")}>
                                    <Comments
                                        type={"note"}
                                        comments={getStructureMessages(currentDomain.pk, 'notes')}
                                        identifier={currentDomain.pk}
                                        scoringItem={currentDomain}
                                    />
                                </li>
                            </ul>
                        </div>
                    )}
                    {currentDomain.children.map((section, sectionIdx) => {

                        return (
                            <div className={"scoring__aspect-list"} key={sectionIdx}>
                                {section.title &&
                                    <h2 className={"scoring__subdomain-title"}>{section.title}</h2>
                                }
                                <ScoringSet
                                    methodId={projectDetail.method.id}
                                    functionType={functionDetail.type}
                                    functionId={functionDetail.id}
                                    numberPrefix={currentDomain.number}
                                    canEdit={canEdit}
                                    domains={section.children}
                                    setScore={setScore}
                                    isHighlighted={props.isHighlighted}
                                    isSelected={(score_id) => functionDetail?.scores.find( obj => obj.score_id === score_id)}
                                    getStructureMessages={getStructureMessages}

                                    />


                            </div>
                        );
                    })}
                </div>
            )}
            <div className={"scoring__navigation"}>
                { stateSelectedDomain > 1 && (
                    <button
                        className={cx("button", "button--tertiary", "scoring__button-previous")}
                        onClick={() => {
                            dispatch(setDomain(stateSelectedDomain - 1))
                            titleRef.current.scrollIntoView({ behavior: 'smooth' });
                        }}>
                        {t("Vorig domein")}
                    </button>)}

                { stateSelectedDomain < structure.domains.length && (
                    <button
                        className={cx("button", "button--primary", "scoring__button-next")}
                        onClick={() => {
                            dispatch(setDomain(stateSelectedDomain + 1))
                            titleRef.current.scrollIntoView({ behavior: 'smooth' });
                        }}>
                        {t("Volgend domein")}
                    </button>
                )}
            </div>
        </>
    )

}


export function Grading2(props) {

    // let { path, url } = useRouteMatch();
    let { function_id, project_id } = useParams();
    function_id = parseInt(function_id);

    const state = useSelector(state => state.ScoringState);
    const dispatch = useDispatch();

    const functionDetail = getFunction(function_id);
    const projectDetail = getProject(project_id);
    const structure = getStructure(projectDetail?.data?.method.id, functionDetail?.data?.type);
    const [descriptionActive, setDescriptionActive] = useState(false);
    const [scoredDomains, setScoredDomains] = useState({});


    const structureContent = structure?.data;

    let currentDomain =  structureContent?.domains.find(obj => obj.number === state.selectedDomain);

    const getStructureStatus = (structure_id) => functionDetail?.data?.scores.find( obj => obj.structure_id === structure_id);

    const getStructureMessages = (structure_id, type='notes') => {
        const options = ['notes', 'comments'];
        if(!options.includes(type)) {
            return [];
        }

        let messages = functionDetail?.data?.messages?.scoringstructure;
        return messages?.[type].filter(o => o.scoring_structure_id === structure_id);
    }

    const faultyScores = functionDetail?.data?.scores.filter( obj => ( (obj?.valid === false  && obj?.updating !== true) || obj?.warning === true))
    let faultyDomains = faultyScores.map( obj => structureContent?.structureIdToDomain?.[obj.structure_id]);


    useEffect(() => {
        if(functionDetail?.data?.scores?.length > 0) {
            let newScoredDomains = {};

            functionDetail?.data?.scores.map( obj => {
                let domainId = structureContent?.structureIdToDomain?.[obj.structure_id];
                if(!newScoredDomains?.[domainId]) {
                    newScoredDomains[domainId] = [];
                }
                newScoredDomains[domainId].push(obj.structure_id);
            })
            setScoredDomains(newScoredDomains);
        }
    }, [functionDetail?.data?.scores?.length, structureContent]);

    function domainDescription(content) {
        return {
            __html: content
        }
    }


    return (
        <>
            <DomainSummary
                function_id={function_id}
                methodId={projectDetail?.data?.method.id}
                getDomainStatus={(domain_id) => {
                    let total = 0;
                    structureContent?.domains.find(obj => obj.pk === domain_id)?.children?.map( obj => {
                        total = total + obj.children.length;
                    })
                    let isFullyScored = total === scoredDomains[domain_id]?.length;
                    return faultyDomains.includes(domain_id) ? 'invalid': (isFullyScored ? 'valid' : '');
                } }
            />

            {currentDomain && (
                <>
                    <div className={"scoring__set"}>
                        {(currentDomain.name || currentDomain.number) && (

                            <div className={"scoring__header"}>
                                {currentDomain.name && (<h3 className={"scoring__domain-title"}>{currentDomain.name}</h3>)}
                                {currentDomain.number && (<h4 className={"scoring__domain-number"}>{t("Domein")}{currentDomain.number}</h4>)}

                                <ul className={"scoring-options"}>
                                    {/*<li className={"scoring-option"}>*/}
                                    {/*    <IconButton icon={"compare-domain"} sizeModifier={"small"} title={"Vergelijken"}  />*/}
                                    {/*</li>*/}

                                    <li className={"scoring-option"}>
                                        <DomainDescriptionModal
                                            currentDomain={currentDomain}/>
                                    </li>
                                    <li className={cx("scoring-option")}>
                                        <Comments
                                            type={"comment"}
                                            comments={getStructureMessages(currentDomain.pk, 'comments')}
                                            identifier={currentDomain.pk}
                                            scoringItem={currentDomain}
                                        />
                                    </li>
                                    <li className={cx("scoring-option")}>
                                        <Comments
                                            type={"note"}
                                            comments={getStructureMessages(currentDomain.pk, 'notes')}
                                            identifier={currentDomain.pk}
                                            scoringItem={currentDomain}
                                        />
                                    </li>
                                </ul>
                            </div>
                        )}

                        {currentDomain.children.map((section, sectionIdx) => {
                            return (
                                <Fragment key={sectionIdx}>
                                    <div className={"scoring__aspect-list"}>
                                        {section.title &&
                                            <h2 className={"scoring__subdomain-title"}>{section.title}</h2>
                                        }
                                        <ScoringSetDisplay
                                            edit={props?.editState?.canEdit && !props?.editState?.scoring_immutable }
                                            subSet={state.functions[function_id]?.subSet}
                                            setSubSet={(identifier, state, explicit, complete=null) => dispatch(setSubset(function_id, identifier, state, explicit, complete))}
                                            numberPrefix={state.selectedDomain}
                                            currentDomain={section}
                                            getStructureStatus={getStructureStatus}
                                            getStructureMessages={getStructureMessages}
                                            isSelected={(structure_id, score_id) => functionDetail?.data?.scores.find( obj => obj.score_id === score_id)}
                                            isHighlighted={props.isHighlighted}
                                            setScore={async (structure_id, score_id) => {
                                                if(props.editState?.canEdit && !props?.editState?.scoring_immutable) {

                                                    if(functionDetail?.data?.scores.some( o => o.score_id === score_id)) {

                                                        functionDetail.mutate({...functionDetail.data, scores: functionDetail?.data?.scores?.filter(o => o.score_id !== score_id), }, false);
                                                        await remoteRemoveScore(function_id, structure_id, score_id);

                                                    } else {

                                                        let newItem = {structure_id, score_id, valid:false, invalid:false, updating: true};
                                                        let newScoresArray = [...functionDetail?.data?.scores];
                                                        let idx = newScoresArray.findIndex( item => item.structure_id === structure_id);
                                                        idx >= 0 ? newScoresArray[idx] = newItem : newScoresArray.push(newItem);

                                                        functionDetail.mutate({...functionDetail.data, scores: newScoresArray}, false);
                                                        await remoteSetScore(function_id, structure_id, score_id);

                                                    }

                                                    functionDetail.mutate();

                                                }
                                            }}
                                        />
                                    </div>
                                </Fragment>
                            )
                        })}
                    </div>

                    <div className={"scoring__navigation"}>
                        { state.selectedDomain > 1 && (
                            <button
                                className={cx("button", "button--tertiary", "scoring__button-previous")}
                                onClick={() => {
                                    dispatch(setDomain(state.selectedDomain - 1))
                                }}>
                                {t("Vorig domein")}
                            </button>)}

                        { state.selectedDomain < structureContent.domains.length && (
                            <button
                                className={cx("button", "button--primary", "scoring__button-next")}
                                onClick={() => {
                                    dispatch(setDomain(state.selectedDomain + 1))
                                }}>
                                {t("Volgend domein")}
                            </button>
                        )}
                    </div>
                </>
            )}
        </>
    )

}

export function ScoringSetItemScore(props) {
    const scoringSetItem = props?.scoringSetItem;
    const [selected, setSelected] = useState(false);
    // console.log("ScoringSetItemScore render");

    return (
        <ol className="grader">
            {scoringSetItem?.scores.map( (score, idx) => {
                const isSelected = selected !== false ? selected === score.id : (props.isSelected(score.id)?.score_id > 0|| false);
                let highlightColors = props.isHighlighted(score.id) || [];
                let isDisabled = !score.id; // score.id is set to null when it is disabled at the server side

                return (
                    <li
                        key={score.label}
                        className="grader__item">
                        <input
                            onChange={async () => {
                                // console.log('clicking')
                            }}
                            onClick={async () => {
                                if(props.canEdit) {
                                    setSelected(isSelected ? 0 : score.id);
                                    let result = await props.setScore(scoringSetItem.pk, score.id, isSelected);
                                    if(!result) {
                                        setSelected(0);
                                    }
                                }
                            }}
                            id={`grader-${score.id}`}
                            name={`grader-${scoringSetItem.pk}`}
                            type="radio"
                            checked={isSelected}
                            disabled={isDisabled}
                            className={"grader__radio"}

                        />
                        <label htmlFor={`grader-${score.id}`} className="grader__label" data-cy={`grader-${score.id}`}>{score.label}</label>
                        <div className="grader-compare">
                            {highlightColors.includes(1) && (<span className={"grader-compare__item grader-compare__item--1"}/>)}
                            {highlightColors.includes(2) && (<span className={"grader-compare__item grader-compare__item--2"}/>)}
                            {highlightColors.includes(3) && (<span className={"grader-compare__item grader-compare__item--3"}/>)}
                        </div>
                    </li>
                );
            })}
        </ol>
    );

}

export function ScoringSetItem(props) {

    const {t, i18n} = useTranslation();
    const scoringItem = props.scoringItem;
    const dispatch = useDispatch();

    const subSetState = useSelector(state => state.ScoringState.functions?.[props.functionId]?.subSet?.[scoringItem.number]);
    const state = useSelector(state => {
        const obj = state.ScoringState.functions?.[props.functionId]?.scores || {};
        return obj[Object.keys(obj).find(key => obj[key].structure_id === scoringItem.pk)];
    });

    const notes = props.getStructureMessages(scoringItem.pk, 'notes');
    const comments = props.getStructureMessages(scoringItem.pk, 'comments');

    const helpTableWarning = subSetState?.complete === false ;

    useEffect(() => {
        if(props.scoringItem?.children?.length > 0) {
            for(let it=0; it<=props.scoringItem.children.length - 1; it++) {
                let childScoringItem = props.scoringItem.children[it];
                if(childScoringItem?.children?.length > 0) {
                    let initialClosed = childScoringItem.children.every(obj => obj?.scores?.some(score => props?.isSelected(score.id)));
                    dispatch(setSubset(props.functionId, props.scoringItem.number, !initialClosed, false, initialClosed));
                }
            }
        }
    },[props.scoringItem?.children?.length]);

    // console.log("ScoringSetItem render");

    let icon = "";
    let notificationAppend = "";
    let scoringAppend = "";

    if(state) {
        if(state?.updating) {
            icon = "";
            notificationAppend = 'scoring-notification--updating';
            scoringAppend = 'scoring-item--updating';
        } else if (state?.warning) {
            icon = "warning";
            notificationAppend = 'scoring-notification--warning';
            scoringAppend = 'scoring-item--warning'
        } else if (!state?.valid) {
            icon = "error";
            notificationAppend = 'scoring-notification--invalid';
            scoringAppend = 'scoring-item--invalid'
        } else if (state?.valid) {
            scoringAppend = 'scoring-item--valid';
        }
    }
    if(helpTableWarning) {
        icon = "warning";
        notificationAppend = 'scoring-notification--warning';
        scoringAppend = 'scoring-item--warning'
    }


    return (
        <li key={scoringItem.pk}>
            {(helpTableWarning || notificationAppend !== "") && (
                <div className={cx("scoring-notification", notificationAppend)}>
                    {icon !== "" && <Icon icon={icon} sizeModifier={"xsmall"} className={"scoring-notification__icon"}/>}
                    {(helpTableWarning || state?.validation_error?.length > 0) && (
                        <ul>
                            {state?.validation_error?.filter( (o,idx) => idx === 0 ).map( obj => (
                                <li key={obj} className="scoring-notification__item">
                                    {obj}
                                </li>
                            ))}
                            {helpTableWarning && (
                                <li key={"no-help-table"} className={"scoring-notification__item"}>
                                    {t("Het wordt sterk aangeraden de hulptabel volledig in te vullen")}
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            )}
            <div className={cx("scoring-item", scoringAppend)}>
                <div className="scoring-item__text">
                    <span className="scoring-item__number">{props?.numberPrefix ? props.numberPrefix + '.' : ''}{scoringItem.number}</span>
                    <span className="scoring-item__name">{scoringItem.name}</span>
                </div>
                <ScoringSetItemScore
                    methodId={props?.methodId}
                    functionType={props?.functionType}
                    scoringSetItem={scoringItem}
                    canEdit={props?.canEdit}
                    setScore={props.setScore}
                    isHighlighted={props.isHighlighted}
                    isSelected={props.isSelected}
                />
                <ul className={"scoring-item__options"}>
                    {/*<li className={"scoring-item__option"}>*/}
                    {/*  <IconButton icon={"compare-aspect"} sizeModifier={"small"} title={"Vergelijken"}  />*/}
                    {/*</li>*/}
                    <li className={"scoring-item__option"}>
                        <AspectDescriptionModal
                            aspect={props.scoringItem}
                        />
                    </li>
                    <li className={"scoring-item__option"}>
                        <ScoreReference
                            canEdit={props?.canEdit}
                            numberPrefix={props.numberPrefix}
                            scoringItem={props.scoringItem}
                            isSelected={props.isSelected}
                            isHighlighted={props.isHighlighted}
                            setScore={props.setScore} />
                    </li>
                    <li className={cx(
                        "scoring-item__option", {
                            "scoring-item__option--visible": comments.length > 0
                        }
                    )}>
                        <Comments
                            type={"comment"}
                            scoringItem={props.scoringItem}
                            comments={comments}
                            identifier={props.scoringItem.pk}
                        />
                    </li>
                    <li className={cx(
                        "scoring-item__option", {
                            "scoring-item__option--visible": notes?.length > 0
                        }
                    )}>
                        <Comments
                            type={"note"}
                            scoringItem={props.scoringItem}
                            comments={notes}
                            identifier={props.scoringItem.pk}
                        />
                    </li>
                </ul>

                {scoringItem?.children?.length > 0 && (
                    <button className={"expand-button"} onClick={() => dispatch(setSubset(props.functionId, scoringItem.number, !subSetState.state, true)) }>
                        {t("Hulptabel")}
                        <Icon icon={"angle-" + (subSetState?.state === true ? "up": "down")} sizeModifier={"xsmall"} className={"expand-button__icon"}/>
                    </button>
                )}

            </div>

            {scoringItem?.children?.length > 0 && (
                <div className={cx(
                    "scoring__sub-set",
                    {
                        "scoring__sub-set--open": subSetState?.state === true,
                    }
                )}>
                    <h4 className={"scoring__sub-set-title"}>{t("Hulptabel domein")} {scoringItem.number}</h4>
                    {scoringItem.children.map((section, sectionIdx) => {
                        return (
                            <ScoringSet
                                key={sectionIdx}
                                methodId={props?.methodId}
                                functionType={props?.functionType}
                                functionId={props?.functionId}

                                domains={section.children}

                                numberPrefix={scoringItem.number}
                                canEdit={props?.canEdit}
                                setScore={props.setScore}
                                isHighlighted={(score_id) => props.isHighlighted(scoringItem.pk, score_id)}
                                isSelected={props.isSelected}
                                getStructureMessages={props.getStructureMessages}
                            />
                        )
                    })}
                </div>
            )}

        </li>

    )

}

export function ScoringSet(props) {

    // const {data: structure } = getStructure(props?.methodId, props.functionType);
    const domains = props.domains;

    // console.log("ScoringSet render");
    // console.log(structure);

    return (
        <ol>
            {domains.map((scoringItem, idx) => {
                return (
                    <ScoringSetItem
                        key={scoringItem.pk}
                        methodId={props?.methodId}
                        functionType={props?.functionType}
                        functionId={props?.functionId}
                        scoringItem={scoringItem}
                        canEdit={props?.canEdit}
                        setScore={props.setScore}
                        isHighlighted={(score_id) => props.isHighlighted(scoringItem.pk, score_id)}
                        isSelected={props.isSelected}
                        numberPrefix={props.numberPrefix}
                        getStructureMessages={props.getStructureMessages}


                    />
                );
            })}
        </ol>

    )

}

import {useDebounce2} from "../utils/useDebounce";
import {AccessDenied} from "../patterns/organisms/AccessDenied";
import ToolTip from "./ToolTip";

export function Scoring(props) {

    let { function_id, project_id } = useParams();
    function_id = parseInt(function_id);
    const dispatch = useDispatch();

    const [scoresFetched, setScoresFetched] = useState(false);

    const {data: functionDetail, mutate: mutateFunction} = useSWR( () => function_id ? `/api/functions/${function_id}/` : false);
    const {data: projectDetail , mutate: mutateProject } = useSWR(() => project_id  ? `/api/projects/${project_id}/` : false);
    const {data: structure, isError: structureError } = getStructure(projectDetail?.method?.id, functionDetail?.type);
    const debounceUpdate = useDebounce2(value => mutateFunction(), 1000);
    const canEdit = props?.editState?.canEdit && !props?.editState?.scoring_immutable || false;

    useEffect(() => {
        if(functionDetail?.scores && functionDetail.scores?.length > 0 && scoresFetched === false) {
            dispatch(setFunctionScores(function_id, functionDetail.scores))
            setScoresFetched(true)
        }
    }, [functionDetail?.scores, scoresFetched])

    const setScore = async(structure_id, score_id, remove=false) => {
        dispatch(setScoreLoading(function_id, structure_id));
        if(canEdit) {
            const {data} = remove ?
                await remoteRemoveScore(function_id, false, score_id)
                :
                await remoteSetScore(function_id, false, score_id);

            debounceUpdate();

            return data?.status === 'ok';
        }
    }

    const getStructureMessages = (structure_id, type) => _getStructureMessages(structure_id, type, functionDetail?.messages);

    if(structureError) {
        return <AccessDenied error={structureError} />;
    }

    return (
        <div className="scoring__set">
            {structure?.domains && (
                <div className="scoring__aspect-list">
                    <ScoringSet
                        methodId={projectDetail?.method?.id}
                        functionType={functionDetail?.type}
                        functionId={functionDetail?.id}
                        numberPrefix={""}
                        canEdit={canEdit}
                        domains={structure.domains}
                        setScore={setScore}
                        isHighlighted={props.isHighlighted}
                        isSelected={(score_id) => functionDetail?.scores.find( obj => obj.score_id === score_id)}
                        getStructureMessages={getStructureMessages}
                    />
                </div>
            )}
        </div>
    )

}

export function Scoring2(props) {

    // let { path, url } = useRouteMatch();
    let { function_id, project_id } = useParams();
    function_id = parseInt(function_id);

    const state = useSelector(state => state.ScoringState);
    const dispatch = useDispatch();

    const functionDetail = getFunction(function_id);
    const projectDetail = getProject(project_id);
    const structure = getStructure(projectDetail?.data?.method.id, functionDetail?.data?.type);

    const getStructureMessages = (structure_id, type='notes') => {
        const options = ['notes', 'comments'];
        if(!options.includes(type)) {
            return [];
        }

        let messages = functionDetail?.data?.messages?.scoringstructure;
        return messages?.[type].filter(o => o.scoring_structure_id === structure_id);
    }

    let currentDomain = {
        title: "",
        name: "",
        children: structure?.data?.domains || []
    };

    return (
        <div className={"scoring__set"}>

            {currentDomain && (
                <div className={"scoring__aspect-list"}>
                    <ScoringSetDisplay
                        edit={props?.editState?.canEdit && !props?.editState?.scoring_immutable}
                        subSet={state.functions[function_id]?.subSet}
                        setSubSet={(identifier, state, explicit, complete=null) => dispatch(setSubset(function_id, identifier, state, explicit, complete))}

                        numberPrefix={""}
                        currentDomain={currentDomain}
                        getStructureStatus={(structure_id) => functionDetail?.data?.scores.find( obj => obj.structure_id === structure_id)}
                        getStructureMessages={getStructureMessages}
                        isSelected={(structure_id, score_id) => functionDetail?.data?.scores.find( obj => obj.score_id === score_id)}
                        isHighlighted={props.isHighlighted}
                        setScore={async (structure_id, score_id) => {
                            if(props.editState?.canEdit && !props?.editState?.scoring_immutable) {

                                if(functionDetail?.data?.scores.some( o => o.score_id === score_id)) {

                                    functionDetail.mutate({...functionDetail.data, scores: functionDetail?.data?.scores?.filter(o => o.score_id !== score_id), }, false);
                                    await remoteRemoveScore(function_id, structure_id, score_id);

                                } else {

                                    let newItem = {structure_id, score_id, valid:false, invalid:false, updating: true};
                                    let newScoresArray = [...functionDetail?.data?.scores];
                                    let idx = newScoresArray.findIndex( item => item.structure_id === structure_id);
                                    idx >= 0 ? newScoresArray[idx] = newItem : newScoresArray.push(newItem);

                                    functionDetail.mutate({...functionDetail.data, scores: newScoresArray}, false);
                                    await remoteSetScore(function_id, structure_id, score_id);

                                }

                                functionDetail.mutate();

                            }
                        }}
                    />
                </div>
            )}
        </div>

    )

}
