import React, {useEffect, useState} from "react";
import IconButton from "../molecules/IconButton";
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getMessages, remoteSetMessage} from "../../queries/comments";
import Modal from "react-modal";
import User from "../../components/User";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Icon from "../atoms/Icon";
import {projectStatus} from "../../utils/constants";

import FunctionGroupStructure from "../../components/FunctionGroupStructure";
import {InfoButton} from "../molecules/InfoButton";
import cx from "classnames";
import LoaderAnimation from "../atoms/LoaderAnimation";
import {useAuth} from "../../utils/useAuth";
import {getProjectInsight, remoteSetFavorite, remoteUnsetFavorite} from "../../queries/project_detail";
import ConfirmModal from "./ConfirmModal";
import {checkAccessList} from "../../utils/withAccessHoC";
import {archiveProject} from "../../queries/project_detail";
import ToolTip from "../../components/ToolTip";

export function FGSItem(props) {
    const projectDetail = props.projectDetail;
    const {t, i18n} = useTranslation();

    return (
        <Modal
            isOpen={props.functionGroupStructureActive}
            className={"modal modal--fgs"}
            overlayClassName={"modal-background modal-background--full-screen"}
            bodyOpenClassName={"has-modal"}>
            <FunctionGroupStructure
                functionGroupStructureActive={props.functionGroupStructureActive}
                setFunctionGroupStructureActive={props.setFunctionGroupStructureActive}
                projectDetail={projectDetail}
            />
        </Modal>
    )
}

export default function ProjectMetaDisplay(props) {

    const projectDetail = props.projectDetail;
    const auth = useAuth();
    const [activeContext, setActiveContext] = useState(false);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    // list of levels to check the access against
    const permLevels = [
        [projectDetail?.id, 'project'],
        [projectDetail?.client?.id, 'client'],
    ]

    if(!projectDetail) {
        return <LoaderAnimation/>;
    }

    if(!projectDetail?.client) {
        projectDetail.client = {
            id: 27,
            name: false,
        }
    }
    const canChangeCollectiveLabourAgreement = auth.checkAccessList("base.change_collective_labour_agreement", permLevels);
    const canSetupEnvironments = auth.checkAccessList('base.can_setup_environments', permLevels)
    const canChangeIfCollectiveLabourAgreement = canChangeCollectiveLabourAgreement || projectDetail.is_collective_labour_agreement === false;

    const canCreateReports = auth.checkAccessList('base.can_create_reports', [
        [projectDetail?.id, 'project'],
        [projectDetail?.client?.id, 'client']
    ]);
    const hasArchiveRight = checkAccessList('base.archive_project', [
        ['client_id', 'client'],
        [projectDetail?.client?.id, 'client']
    ])

    const canAccessProject = projectDetail?.client?.id ? (
            // path inc client
            auth.checkAccessList('base.view_project', [
                [projectDetail?.id, 'project'],
                [projectDetail?.client?.id, 'client'],
            ])
        )
        :
        (
            //path ex client
            auth.checkAccessList('base.view_project', [
                [projectDetail?.id, 'project'],
            ])
        );

    const canAccessSourceProject = projectDetail?.source_project?.client?.id ? (
            // path inc client
            auth.checkAccessList('base.view_project', [
                [projectDetail?.source_project?.id, 'project'],
                [projectDetail?.source_project?.client?.id, 'client'],
            ])
        )
        :
        (
            //path ex client
            auth.checkAccessList('base.view_project', [
                [projectDetail?.source_project?.id, 'project'],
            ])
        );

    const archive = async (id) => {
        await archiveProject(id);
        navigate("/project");
    }

    const basedOnProjectPath = projectDetail?.source_project && canAccessSourceProject ? (
        projectDetail?.source_project?.client?.id ?
            generatePath("/client/:client_id/project/:project_id/function", {
                client_id: projectDetail?.source_project?.client?.id,
                project_id: projectDetail?.source_project?.id,
            })
            :
            generatePath("/project/:project_id/function", {
                project_id: projectDetail?.source_project?.id,
            })
    ) : false;

    const contextItemsAllowed = (list) => {
        return list.filter(o => !('allowed' in o) || ('allowed' in o && (o.allowed === true || o.allowed() !== false)));
    }

    let {project_id} = useParams();
    const {data: projectInsight} = getProjectInsight(parseInt(project_id));
    const becomeUser = async () => {
        await auth.switchUser(projectInsight?.id)
    }

    const contextItems = [
        {
            // allowed: () => projectDetail.permissions.includes('base.view_functiongroupstructure'),
            allowed: () => auth.hasPermission('base.can_view_fgs_popup', projectDetail.id, 'project'),
            identifier: 'fgs',
            link: (
                <a className={"dropdown-field__label"} title={t("Functiegroepenstructuur")}
                   onClick={() => setActiveContext('fgs')}>
                    {t("Functiegroepenstructuur")}
                </a>
            ),
            Component: <FGSItem key={'fgs'} projectDetail={projectDetail}
                                functionGroupStructureActive={activeContext === 'fgs'}
                                setFunctionGroupStructureActive={(activeState) => setActiveContext(activeState ? 'fgs' : false)}/>
        },
        {
            identifier: 'hr',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => canCreateReports,
            identifier: 'project-function-report',
            link: (
                <Link to={generatePath('/client/:client_id/project/:project_id/function-report', {
                    client_id: projectDetail?.client?.id,
                    project_id: projectDetail?.id
                })} className={"dropdown-field__label"} title={t("Maak functierapportage van alle functies")}>
                    {t("Maak functierapportage")}
                </Link>
            )
        },
        {
            allowed: () => canCreateReports,
            identifier: 'project-report',
            link: (
                <Link to={generatePath('/client/:client_id/project/:project_id/report', {
                    client_id: projectDetail?.client?.id,
                    project_id: projectDetail?.id
                })} className={"dropdown-field__label"} title={t("Maak projectrapportage van het gehele project")}>
                    {t("Maak projectrapportage")}
                </Link>
            )
        },
        {
            identifier: 'hr2',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => auth.hasPermission('base.change_project', projectDetail.id, 'project') && canChangeIfCollectiveLabourAgreement,
            identifier: 'project-edit',
            link: (
                <Link to={generatePath('/client/:client_id/project/:project_id/edit', {
                    client_id: projectDetail?.client?.id,
                    project_id: projectDetail?.id
                })} className={"dropdown-field__label"} title={t("Projecteigenschappen")}
                      data-cy={"context-menu-dropdown-project-edit"}>
                    {t("Wijzig eigenschappen")}
                </Link>
            ),
        },
        {
            allowed: () => auth.hasPermission('base.change_project', projectDetail.id, 'project') && canChangeIfCollectiveLabourAgreement,
            identifier: 'project-edit-template',
            link: (
                <Link to={generatePath('/client/:client_id/project/:project_id/template', {
                    client_id: projectDetail?.client?.id,
                    project_id: projectDetail?.id
                })} className={"dropdown-field__label"} title={t("Wijzig standaard template")}>
                    {t("Wijzig standaard template")}
                </Link>
            ),
        },
        {
            allowed: () => auth.hasPermission('base.change_project', projectDetail.id, 'project') && canChangeIfCollectiveLabourAgreement,
            identifier: 'project-insight',
            link: (
                <Link to={generatePath('/client/:client_id/project/:project_id/insight', {
                    client_id: projectDetail?.client?.id,
                    project_id: projectDetail?.id
                })} className={"dropdown-field__label"} title={t("Wijzig inzageaccount instellingen")}
                      data-cy={"context-menu-project-insight"}>
                    {t("Wijzig inzageaccount instellingen")}
                </Link>
            ),
        },
        {
            identifier: 'hr3',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => canSetupEnvironments,
            identifier: 'environment-create',
            link: (
                <Link to={generatePath('/client/:client_id/project/:project_id/environment/create', {
                    client_id: projectDetail?.client?.id,
                    project_id: projectDetail?.id
                })} className={"dropdown-field__label"} title={t("Demo-omgeving aanmaken")} data-cy={"client-dropdown-environment-create"}>
                    {t("Demo-omgeving aanmaken")}
                </Link>
            ),
        },
        {
            identifier: 'hr4',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => auth.hasPermission('base.change_project', projectDetail.id, 'project') && canChangeIfCollectiveLabourAgreement && (projectInsight?.email ? true : false) && (projectInsight?.password ? true : false),
            identifier: 'project-insight-login',
            linkTitle: t("Log in als inzage gebruiker"),
            Component: <ConfirmModal
                title={t("Log in als inzage gebruiker")}
                body={t(
                    "Let op! Als je op \"Log In\" klikt zal je automatisch worden ingelogd als deze gebruiker. " +
                    "Je zult zelf uit moeten loggen wanneer je klaar bent met het testen voor deze gebruiker. Na het " +
                    "uitloggen zul je opnieuw in moeten loggen als applicatiebeheerder.")
                }
                confirmText={t("Log in als inzage gebruiker")}
                confirmModal={() => becomeUser()}
                closeModal={() => setActiveContext(false)}
            />
        },
        {
            identifier: 'hr5',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => hasArchiveRight,
            identifier: 'archive-project',
            link: (
                <a className={"dropdown-field__label"} title={t("Archiveer dit project")}
                   onClick={() => setActiveContext('archive-project')}>
                    {t("Project archiveren")}
                </a>
            ),
            Component: <ConfirmModal
                key={'archive-project'}
                title={("Project archiveren")}
                body={("Weet je zeker dat je dit project wilt archiveren?") + ":"}
                subject={projectDetail?.name}
                confirmModal={() => archive(projectDetail?.id)}
                closeModal={() => setActiveContext(false)}/>
        },
    ]

    return (
        <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
            <div className={"content-container"}>
                <div className={"meta-info"}>
                    <div className={"meta-info__top"}>
                        <div className={"meta-info__primary"}>
                            <div className={"meta-info__title-container"}>
                                <h1 className={"meta-info__title"}
                                    data-cy={"meta-info-title"}>{projectDetail?.name}</h1>
                                <span className={cx("meta-info__status-tag", {
                                    "status-tag status-tag--neutral": ['concept', undefined].includes(projectDetail?.status),
                                    "status-tag status-tag--active": ['determined'].includes(projectDetail?.status),
                                    "status-tag status-tag--negative": ['expired', 'archived'].includes(projectDetail?.status)
                                })}>{t(projectStatus[projectDetail?.status])}</span>
                                {projectDetail?.is_collective_labour_agreement &&
                                    <span className={"meta-info__status-tag status-tag status-tag--cao"}
                                          data-cy={"meta-info-cao"}>{t("CAO")}</span>}
                            </div>

                            {projectDetail?.source_project && (
                                <p className={"meta-info__post-title"} data-cy={"meta-info-post-title"}>
                                    {projectDetail?.source_project_usage === 'based_on' ? t('Gebaseerd op ') : t('Gekopieerd van ')}
                                    {basedOnProjectPath ?
                                        <Link to={basedOnProjectPath}
                                              className={"link"}>{projectDetail?.source_project.name}</Link>
                                        :
                                        <span>{projectDetail?.source_project.name}</span>
                                    }

                                </p>
                            )}
                            {projectDetail?.project_groups?.length > 0 && (
                                <p className={"meta-info__post-title"}>
                                    {t('Projectgroep')}: {projectDetail?.project_groups[0].name}
                                </p>
                            )}
                        </div>

                        {canAccessProject && (
                            <div className={"meta-info__secondary"}>
                                <div className={"meta-info__buttons"}>
                                    {projectDetail.favorite ? (
                                        <ToolTip tooltip={t("Favoriet")}>
                                            <IconButton icon={"favorite-on"}
                                                        className={"icon-button--favorite"}
                                                        border={"true"}
                                                        title={t("Favoriet")} active={"true"}
                                                        onClick={async () => {
                                                            await remoteUnsetFavorite(projectDetail?.id);
                                                            props.projectMutate();
                                                        }}
                                            />
                                        </ToolTip>
                                    ) : (
                                        <ToolTip tooltip={t("Favoriet")}>
                                            <IconButton icon={"favorite-off"}
                                                        border={"true"}
                                                        title={t("Favoriet")}
                                                        onClick={async () => {
                                                            await remoteSetFavorite(projectDetail?.id);
                                                            props.projectMutate();
                                                        }}
                                            />
                                        </ToolTip>
                                    )}

                                    <div className={"icon-button-group"}>
                                        <InfoButton
                                            icon={"info"}
                                            title={t("Meer informatie")}
                                            popOverClickClass={"dropdown-toggle"}
                                            popOverClass={"dropdown"}
                                            popOverClassActive={"dropdown dropdown--active"}
                                            border={"true"}
                                        >
                                            <div
                                                className={"popover popover--narrow popover--flip-right-item-left popover--active"}>
                                                <div className={"popover__content"}>
                                                    <dl className={"popover-info"}>
                                                        <dt className={"popover-info__key"}>{t("Methode")}</dt>
                                                        <dd className={"popover-info__value"}
                                                            data-cy={"meta-info-method"}>{props?.projectDetail?.method?.name}</dd>
                                                        <dt className={"popover-info__key"}>{t("Laatst bewerkt")}</dt>
                                                        <dd className={"popover-info__value"}>
                                                            {props?.projectDetail?.modification_date ? (
                                                                <time
                                                                    dateTime={props?.projectDetail?.modification_date}>
                                                                    {new Intl.DateTimeFormat("nl-NL", {
                                                                        day: "2-digit",
                                                                        month: "2-digit",
                                                                        year: "numeric",
                                                                        hour: 'numeric',
                                                                        minute: 'numeric',
                                                                    }).format(new Date(props?.projectDetail?.modification_date))}
                                                                </time>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>
                                        </InfoButton>

                                        <InfoButton
                                            icon={"context-menu"} title={t("Contextmenu")}
                                            popOverClickClass={"dropdown-toggle"}
                                            popOverClass={"dropdown"}
                                            popOverClassActive={"dropdown dropdown--active"}
                                            border={"true"}
                                            data-cy={"project-meta-context-menu"}
                                        >
                                            <ul>
                                        {contextItemsAllowed(contextItems).map((obj, idx) => {
                                            return (
                                                <li key={obj.identifier} className={"dropdown-field"}
                                                    onClick={obj?.onClick ? obj?.onClick : null}
                                                >
                                                    {obj?.linkTitle ? (
                                                        <a className={"dropdown-field__label"} title={t(obj.linkTitle)}
                                                           onClick={() => setActiveContext(obj.identifier)}>
                                                            {t(obj.linkTitle)}
                                                        </a>
                                                    ) : (
                                                        <>{obj.link}</>
                                                    )}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                        </InfoButton>
                                        {contextItemsAllowed(contextItems).map((obj, idx) => {
                                            if(activeContext === obj.identifier) {
                                                return obj.Component;
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

}
