import React from "react";

export default function CheckboxFilter(props) {
    function handleChange(e) {
        if (props.trueOrString) {
            if (!e.target.checked) {
                return props.setFilter(props.identifier, "")

            }
            return props.setFilter(props.identifier, e.target.checked)

        }
        if (props.falseOrString) {
            if (props.state?.[props.identifier] === false) {
                return props.setFilter(props.identifier, "")
            }

            return props.setFilter(props.identifier, false)
        }
        return props.setFilter(props.identifier, e.target.checked)
    }

    function handleCheckedFalseOrString () {
        if (props.state?.[props.identifier] === false) {
            return true
        }

        if (props.state?.[props.identifier] === "") {
            return false;
        }
    }

    return (
        <div>
            <input type={"checkbox"}
                   className={"checkbox"}
                   id={props.identifier}
                   onChange={(e) => handleChange(e)}
                   checked={props.falseOrString ? handleCheckedFalseOrString() || false : props.state?.[props.identifier] || false }
            />
            <label htmlFor={props.identifier}>{props.label}</label>
        </div>
    )
}
