import React from "react";
import cx from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function TabNavigation(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const {t} = useTranslation();

    const contextItemsAllowed = (list) => {
        return list.filter(o => !('allowed' in o) || ('allowed' in o && (o.allowed === true || o.allowed() !== false)));
    }

    return (
        <nav className={"tab-navigation"}>
            {contextItemsAllowed(props.tabs)?.map((tab) =>
                <button
                    key={tab.url}
                    className={cx("tab-navigation__tab", {"tab-navigation__tab--active": location.pathname === tab.url})}
                    onClick={() => {
                        navigate(tab.url)
                    }}>{t(tab.displayName)}
                </button>
            )}
        </nav>
    )
}
