import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {CheckboxInput, Input} from "../atoms/Input";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Select from "../atoms/Select";
import Drawer from "../../components/Drawer";
import Overview from "../../components/Overview";
import {ProjectSelectionDefinition} from "../../components/Overviews/ProjectSelection";
import {ClientSelectionDefinition} from "../../components/Overviews/ClientSelection";
import Notification from "../molecules/Notification";
import {withAccessRight} from "../../utils/withAccessHoC";
import Quickview from "../../components/Quickview";
import {UserGroupFormItem} from "../../components/UserGroupFormItemNew";
import {helpText} from "../../utils/constants";
import {Controller, useForm} from "react-hook-form";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {createProject, getProjectOptions} from "../../queries/project_detail";
import {useAuth} from "../../utils/useAuth";
import {getClient} from "../../queries/client_detail";
import {getMethods} from "../../queries/method_detail";
import DatePickerFilter from "../../components/Overviews/Filters/DatePickerFilter";
import {getLicenseTypes} from "../../queries/license_detail";
import LoaderAnimation from "../atoms/LoaderAnimation";
import cx from "classnames";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const ProjectCreatePatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function ProjectCreateDisplay(props) {
    const location = useLocation();
    const caoProjectState = location?.state;
    const {client_id} = useParams();

    const {
        register,
        handleSubmit,
        watch,
        setError,
        setValue,
        control,
        clearErrors,
        getValues,
        formState: {errors, isSubmitting}
    } = useForm({
        defaultValues: {
            client_id: caoProjectState?.client_id ? caoProjectState?.client_id : client_id,
            license_type: caoProjectState?.license?.license_type ? caoProjectState.license?.license_type : null,
            end_datetime: caoProjectState?.license?.end_datetime ? caoProjectState.license?.end_datetime : null,
            method_functions_as_reference: typeof caoProjectState?.method_functions_as_reference === "boolean" ? caoProjectState.method_functions_as_reference : false,
            name: caoProjectState?.name ? caoProjectState?.name : null,
            source_project_function_group_structure_usage: caoProjectState?.source_project_function_group_structure_usage ? caoProjectState?.source_project_function_group_structure_usage : null,
            source_project_force_function_group_structure: caoProjectState?.source_project_force_function_group_structure ? caoProjectState?.source_project_force_function_group_structure : null,
            source_project_id: caoProjectState?.source_project_id ? caoProjectState?.source_project_id : null,
            source_project_usage: caoProjectState?.source_project_usage ? caoProjectState?.source_project_usage : null,
            method_id: caoProjectState?.method_id ? caoProjectState?.method_id : null,
            base_project_on: typeof caoProjectState?.base_project_on === "boolean" ? caoProjectState?.base_project_on : null,
            is_collective_labour_agreement: false,
            force_fgs: false,
            hide_points: false,
            default_applier_one_group_id: null,
            default_applier_two_group_id: null,
            default_describer_group_id: null,
        },
        shouldUnregister: true
    });

    const licenseTypes = getLicenseTypes().data?.results;
    // Can't use initialValues from react-hook-form because the api call is not done yet when the form is rendering.
    useEffect(() => {
        if (licenseTypes) {
            setValue("license_type", licenseTypes?.find((licenseType) => licenseType.name === "Basis (automatisch)").id)
        }

        if(caoProjectState) {
            setValue("source_project_usage", caoProjectState?.source_project_usage ? caoProjectState?.source_project_usage : null)
            setValue("source_project_force_function_group_structure", caoProjectState?.source_project_force_function_group_structure ? caoProjectState?.source_project_force_function_group_structure : null)
        }
    }, [licenseTypes, caoProjectState])

    const {t} = useTranslation();
    const navigate = useNavigate();

    const onSubmit = () => async (values) => {
        const submitValues = {
            ...values, license: {
                end_datetime: values.end_datetime,
                license_type: values.license_type
            }
        }

        delete submitValues["end_datetime"]
        delete submitValues["license_type"]

        await createProject(submitValues, navigate);
    }

    const auth = useAuth();
    const canAddLicense = auth.hasPermission('base.add_license');
    const canMakeCAO = auth.hasPermission("base.add_collective_labour_agreement");
    const canForceHidePoints = canMakeCAO && auth.hasPermission("base.can_force_hide_points");

    const client = getClient(caoProjectState?.client_id ? caoProjectState?.client_id : client_id).data;
    const methods = getMethods().data?.results;

    const project_options = getProjectOptions()?.data?.actions?.POST;
    const source_project_options = project_options?.source_project_usage.choices;
    const based_on_fgs_options = project_options?.source_project_function_group_structure_usage.choices;

    return (
        <>
            <h1 className={"form-container__title"}>{t("Project aanmaken")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error.cause.message === "server-error-project-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>
                    <div className={"input-group"}>
                        <Controller name={"client_id"} control={control}
                                    rules={{required: t("Dit is een verplicht veld")}}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer Klant")}
                                            buttonText={t("Zoek klant")}
                                            label={t("Klant")}
                                            initialReadOnlyVal={client?.name}
                                            readOnlyVal={obj => obj.name}
                                            placeholder={t("Geen klant gekozen")}
                                            disabled={!!props?.client}
                                            id={"client-drawer"}
                                            error={errors.client_id}
                                            required

                                            isForm={true}

                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-client-overview"}
                                                    identKey={{
                                                        client_id: client_id,
                                                        project_id: props.project_id
                                                    }}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ClientSelectionDefinition}
                                                />
                                            }
                                        />)}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Projectnaam")}
                            placeholder={t("Naam van dit project")}
                            data-cy={"project-name-input"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>
                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Licentie instellingen")}</legend>
                    <div className="input-group">
                        <Select
                            name={"license_type"}
                            type={'select'}
                            options={licenseTypes}
                            id={"license_type"}
                            label={t("Licentie")}
                            register={register}
                            disabled={!canAddLicense}
                        />
                    </div>
                    <div className="input-group">
                        <Controller name={"end_datetime"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"end_datetime"}
                                            type={"text"}
                                            id={"end_datetime"}
                                            onChange={onChange}
                                            selected={value}
                                            label={t("Vervaldatum")}
                                        />)}/>
                    </div>
                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Projectinstellingen")}</legend>

                    {canMakeCAO && (
                        <>
                            <div className="input-group">
                                <CheckboxInput
                                    name={"is_collective_labour_agreement"}
                                    type={"checkbox"}
                                    id={"is_collective_labour_agreement"}
                                    label={t("Is een basis CAO project")}
                                    placeholder={t("")}
                                    helpTitle={t("Wat is een basis CAO project?")}
                                    helpText={t("Een basis CAO project is het project waarin alle functies staan " +
                                        "die door een organisatie zoals een vakbond zijn opgesteld en gevalideerd. " +
                                        "Dit basisproject dicteert bijvoorbeeld de functiegroepenstructuur " +
                                        "en welke functies er automatisch meeveranderen.")}
                                    data-cy={"is-collective-labour-agreement-input"}
                                    register={register}
                                />
                            </div>

                            {watch("is_collective_labour_agreement") && (
                                <>
                                    <div className="input-group">
                                        <CheckboxInput
                                            name={"force_function_group_structure"}
                                            type={"checkbox"}
                                            id={"force_function_group_structure"}
                                            label={t("Gebruik functiegroepenstructuur afdwingen")}
                                            helpTitle={t("Wat doet functiegroepenstructuur afdwingen?")}
                                            helpText={t("Wanneer een functiegroepenstructuur wordt afgedwongen " +
                                                "binnen een project zal, indien een project daarop wordt gebaseerd, " +
                                                "de functiegroepenstructuur niet te veranderen zijn en automatisch " +
                                                "gevolgd worden.")}
                                            register={register}
                                        />
                                    </div>
                                    {canForceHidePoints && (
                                        <div className="input-group">
                                            <CheckboxInput
                                                name={"hide_points"}
                                                type={"checkbox"}
                                                id={"hide_points"}
                                                label={t("Verberg punten")}
                                                helpTitle={t("Wat is 'verberg punten'?")}
                                                helpText={t("In sommige gevallen kan het zo zijn dat een vakbond zeer expliciet " +
                                                    "aangeeft dat het in géén geval mogelijk mag zijn om het aantal punten " +
                                                    "te tonen. Deze optie zorgt er voor dat ook adviseurs het aantal punten " +
                                                    "niet kan zien.")}
                                                register={register}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {!watch("is_collective_labour_agreement") &&
                        <>
                            <div className="input-group">
                                <CheckboxInput
                                    name={"base_project_on"}
                                    type={"checkbox"}
                                    onChange={(e) => {
                                        if (e.target.checked === false) {
                                            props.form.change('method_id', null);
                                        }
                                    }}
                                    id={"base_project_on"}
                                    label={t("Gebruik ander project als basis")}
                                    helpTitle={t("Hoe werkt baseren op een ander project?")}
                                    helpText={t(
                                        "Bij het baseren op een ander project worden verschillende instellingen " +
                                        "vanuit het te baseren project overgenomen. Dat zijn: de beschrijvingen, " +
                                        "de referentie- en / of bedrijfseigen functies, de functiegroepenstructuur. " +
                                        "Wanneer wordt gebaseerd op een cao (basis)project kan het " +
                                        "zijn dat bepaalde instellingen (bijvoorbeeld de functiegroepenstructuur) " +
                                        "niet kan worden gewijzigd. Deze wordt dan 'afgedwongen'.")}
                                    data-cy={"based-project-on-checkbox"}
                                    register={register}
                                />
                            </div>

                            {watch("base_project_on") &&
                                <>
                                    <div className="input-group">
                                        <Controller name={"source_project_id"} control={control}
                                                    render={({field: {onChange}}) => (
                                                        <Drawer
                                                            title={t("Baseer op project")}
                                                            buttonText={t("Zoek project")}
                                                            id={"source-project-drawer"}
                                                            label={t("Bronproject")}
                                                            initialReadOnlyVal={caoProjectState?.source_project_name}
                                                            readOnlyVal={obj => obj.name}
                                                            placeholder={t("Kies een project...")}
                                                            disabled={false}
                                                            error={errors.source_project_id}

                                                            isForm={true}

                                                            reset={() => {
                                                                onChange(null)
                                                                setValue("source_project_usage", null)
                                                                setValue("source_project_function_group_usage", null)
                                                                setValue("method_id", null)
                                                            }}

                                                            onClick={(obj) => {
                                                                onChange(obj.id)
                                                                setValue("source_project_force_function_group_structure", obj.force_function_group_structure)
                                                                setValue("method_id", obj.method.id)
                                                            }}

                                                            contentComponent={
                                                                <Quickview>
                                                                    {watch("client_id") && (
                                                                        <Overview
                                                                            identifier={"select-project-overview"}
                                                                            quickview={{
                                                                                identifier: "client-projects",
                                                                                name: t("Van deze klant")
                                                                            }}
                                                                            identKey={{
                                                                                client_id: watch("client_id") ? watch("client_id") : client_id,
                                                                            }}
                                                                            contextData={{
                                                                                location,
                                                                            }}
                                                                            definition={ProjectSelectionDefinition}
                                                                            initialFilters={{
                                                                                status__in: ['concept', 'determined']
                                                                            }}
                                                                        />
                                                                    )}
                                                                    <Overview
                                                                        identifier={"select-project-overview-global"}
                                                                        quickview={{
                                                                            identifier: "projects",
                                                                            name: t("Alle projecten")
                                                                        }}
                                                                        identKey={{}}
                                                                        contextData={{
                                                                            location,
                                                                        }}
                                                                        definition={ProjectSelectionDefinition}
                                                                        initialFilters={{
                                                                            status__in: ['concept', 'determined']
                                                                        }}
                                                                    />
                                                                </Quickview>
                                                            }
                                                        />
                                                    )}
                                        />
                                    </div>
                                    {(watch("source_project_id") || getValues("source_project_id")) &&
                                        <div className="input-group">
                                            {watch("source_project_force_function_group_structure") &&
                                                <Select
                                                    name={"source_project_usage"}
                                                    type={'select'}
                                                    options={source_project_options?.filter(o => o.value === 'based_on')}
                                                    id={"source_project_usage"}
                                                    label={t("Het nieuwe project zal")}
                                                    placeholder={t("Maak een keuze")}
                                                    register={register}
                                                    error={errors.source_project_usage}
                                                />
                                            }

                                            {!watch("source_project_force_function_group_structure") &&
                                                <Select
                                                    name={"source_project_usage"}
                                                    type={'select'}
                                                    options={source_project_options}
                                                    id={"source_project_usage"}
                                                    label={t("Het nieuwe project zal")}
                                                    placeholder={t("Maak een keuze")}
                                                    register={register}
                                                    error={errors.source_project_usage}
                                                />
                                            }
                                        </div>
                                    }

                                    {watch("source_project_usage") === "based_on" &&
                                        <div className="input-group">
                                            {getValues("source_project_force_function_group_structure") &&
                                                <Select
                                                    name={"source_project_function_group_structure_usage"}
                                                    type={'select'}
                                                    options={based_on_fgs_options?.filter(o => o.value === 'follow')}
                                                    id={"source_project_function_group_structure_usage"}
                                                    label={t("De functiegroepenstructuur vanuit het bronproject zal")}
                                                    placeholder={t("Maak een keuze")}
                                                    register={register}
                                                    error={errors.source_project_function_group_structure_usage}
                                                />
                                            }

                                            {getValues("source_project_force_function_group_structure") === false &&
                                                <Select
                                                    name={"source_project_function_group_structure_usage"}
                                                    type={'select'}
                                                    options={based_on_fgs_options}
                                                    id={"source_project_function_group_structure_usage"}
                                                    label={t("De functiegroepenstructuur vanuit het bronproject zal")}
                                                    placeholder={t("Maak een keuze")}
                                                    register={register}
                                                    error={errors.source_project_function_group_structure_usage}
                                                />
                                            }
                                        </div>
                                    }

                                    {watch("source_project_usage") === "copied_from" &&
                                        <div className="input-group">
                                            <Select
                                                name={"source_project_function_group_structure_usage"}
                                                type={'select'}
                                                options={based_on_fgs_options?.filter(o => o.value !== 'follow')}
                                                id={"source_project_function_group_structure_usage"}
                                                label={t("De functiegroepenstructuur vanuit het bronproject zal")}
                                                placeholder={t("Maak een keuze")}
                                                register={register}
                                                error={errors.source_project_function_group_structure_usage}
                                            />
                                        </div>
                                    }

                                    {watch("source_project_id") &&
                                        <div className="input-group">
                                            <Select
                                                name={"method_id"}
                                                type={'select'}
                                                options={methods}
                                                disabled={true}
                                                id={"method_id"}
                                                label={t("Methode")}
                                                placeholder={t("Selecteer een methode")}
                                                helpText={t("Er is gekozen om dit project te baseren op een ander project. " +
                                                    "Daardoor wordt de methode van het gekozen project overgenomen. " +
                                                    "Indien een andere methode gewenst is moet of de selectie " +
                                                    "van het bronproject worden verwijderd, of een ander project " +
                                                    "gekozen worden met de gewenste methode")}
                                                helpTitle={t("Waarom kan ik de methode niet veranderen?")}
                                                data-cy={"method-select"}
                                                register={register}
                                                error={errors.method_id}
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }

                    {!watch("base_project_on") &&
                        <div className="input-group">
                            <Select
                                name={"method_id"}
                                type={'select'}
                                options={methods}
                                onChange={(e) => {
                                    // trigger a reset for the based on
                                    document.querySelector('.drawer-reset-source_project_drawer')?.click();
                                    // because the trigger also resets method, re-set it :)
                                    props.form.change('method_id', e.target.value);

                                }}
                                id={"method_id"}
                                label={t("Methode")}
                                placeholder={t("Selecteer een methode")}
                                register={register}
                                required
                                error={errors.method_id}
                            />
                        </div>
                    }

                    {watch("method_id") &&
                        <div className="input-group">
                            <CheckboxInput
                                name={"method_functions_as_reference"}
                                type={"checkbox"}
                                id={"method_functions_as_reference"}
                                label={t("Methode-eigen-functies bruikbaar als referentiefuncties")}
                                placeholder={t("")}
                                helpTitle={t("Methode-eigen-functies gebruiken als referentiefuncties")}
                                helpText={t("Bij een methode worden meerdere functies opgesteld die " +
                                    "mogelijk nuttig zijn om te gebruiken binnen een project. Met deze " +
                                    "optie wordt aangegeven dat deze set functies bruikbaar is om " +
                                    "als referentie-functie toe te voegen bij de onderbouwing.")}
                                data-cy={"method-functions-as-reference-input"}
                                register={register}
                            />
                        </div>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Rollen")}</legend>

                    <Controller name={"default_describer_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.default_describer_group}
                                        client_id={client?.id}
                                        id_prefix={"default_describer_group"}
                                        label={t("Beschrijver")}
                                        helpTitle={"Wat is een \"beschrijver\"?"}
                                        helpText={t(helpText.beschrijver)}
                                        onChange={onChange}
                                        error={errors.default_describer_group}
                                    />
                                )}
                    />

                    <Controller name={"default_applier_one_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.default_applier_one_group}
                                        client_id={client?.id}
                                        id_prefix={"default_applier_one_group"}
                                        label={t("Toepasser")}
                                        helpTitle={t("Wat is een \"toepasser\"?")}
                                        helpText={t(helpText.toepasser)}
                                        onChange={onChange}
                                        error={errors.default_applier_one_group}
                                    />
                                )}
                    />

                    <Controller name={"default_applier_two_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.default_applier_two_group}
                                        client_id={client?.id}
                                        id_prefix={"default_applier_two_group"}
                                        label={t("Toetser")}
                                        helpTitle={t("Wat is een \"Toetser\"?")}
                                        helpText={t(helpText.toetser)}
                                        onChange={onChange}
                                        error={errors.default_applier_two_group}
                                    />
                                )}
                    />

                    {isSubmitting && <Notification className={"form-container__info"} type={"info"}
                                      text={t("Het kan enige tijd duren voordat het project volledig is aangemaakt. Wanneer wordt gewacht zal het project automatisch geopend worden.  Indien u niet wilt wachten mag het scherm afgesloten worden, het aanmaken vindt dan in de achtergrond plaats")}/>}
                    {errors.server &&
                        <Notification className={"form-container__error"} type={"error"}
                                      text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>


                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        className={cx({'button--loading': isSubmitting})}
                        fullWidth={true}
                        data-cy={"create-project-submit-button"}
                    >
                        <span className={"button__text"}>{("Aanmaken")}</span>
                        {isSubmitting && <div className={"button__loader"}><LoaderAnimation infinite={"true"} small={true} inverted={true}/></div>}
                    </ButtonPrimary>
                </fieldset>
            </form>
        </>
    )
}

export const ProjectCreate = withAccessRight(
    ProjectCreateDisplay,
    "base.add_project",
    [
        ["client_id", "client"]
    ]
)
