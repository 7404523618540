import React, {useState} from "react";
import Notification from "../molecules/Notification";
import OverviewName from "../molecules/OverviewName";
import GroupPatternScore from "../molecules/GroupPatternScore";
import {getFunction} from "../../queries/function_detail";
import SidebarSummary from "../molecules/SidebarSummary";
import SidebarFunction from "../molecules/SidebarFunction";
import IconButton from "../molecules/IconButton";
import {DescriptionModal} from "../../components/Screens/Description";
import {FunctionInfoIconButton} from "../../components/Overviews/FunctionInfoIconButton";
import {useTranslation} from "react-i18next";
import i18n from "../../utils/i18n";

export const SelectedFunctionsPatternState = {
    functions: [
        {
            id: 1,
            function_group: 3,
            name: i18n.t('Administratief medewerker'),
            version_number: 1,
            pattern_list: [
                { score: 'G' },
                { score: 'F' },
                { score: 'F' },
                { score: 'X' },
                { score: 'E' },
                { score: 'A' },
                { score: 'H' },
                { score: 'G' },
                { score: 'G' },
            ],
        },
        {
            id: 2,
            function_group: 4,
            name: i18n.t('Accountmanager'),
            version_number: 2,
            pattern_list: [
                { score: 'H'},
                { score: 'F'},
                { score: 'F'},
                { score: 'X'},
                { score: 'F'},
                { score: 'A'},
                { score: 'H'},
                { score: 'G'},
                { score: 'H'},
            ],
        }
    ],
    comparedPatternList: [
        { score: 'G'},
        { score: 'F'},
        { score: 'F'},
        { score: 'X'},
        { score: 'G'},
        { score: 'A'},
        { score: 'H'},
        { score: 'G'},
        { score: 'F'},
    ],
    plMod: {
        functions: [],
    }
}

export function PreloadAndDisplay(props) {

    const functionDetail = getFunction(props.id);

    const comparison = props.comparison !== false;


    return (
        <>
            <SidebarFunction
                displayPoints={false}
                comparedPatternList={props?.comparedPatternList}
                comparison={comparison}
                function={functionDetail?.data}>
                {props.children}
            </SidebarFunction>
        </>
    )
}

export default function SelectedFunctionsDisplay(props) {

    const {t, i18n} = useTranslation();
    const [description, setDescription] = useState(false);

    return (
        <div className="scoring-side-bar">

            {props.functionDetail?.type !== 'match' && (
                <div className={"scoring-side-bar__top"}>
                    <SidebarSummary
                        {...props}
                    />
                </div>
            )}


            <div className="side-bar-main">
                <h2 className="side-bar-main__header">Gekozen referentiefuncties</h2>
                {!(props?.functionDetail?.reference_functions?.length > 0) && (
                    <>
                        <Notification type={"warning"} sizeModifier={"small"} className={"side-bar-main__notification"} text={t("Geen referentiefunctie geselecteerd")}/>
                        {/*<div className={"side-bar-main__message"}>{t("Geen passende referentiefunctie gevonden")}</div>*/}
                    </>
                )}

                {props?.functionDetail?.reference_functions?.length > 0 && (
                    <ul>
                        {props?.functionDetail?.reference_functions?.map((func, idx) => {
                            return (
                                <li key={func.id + '-' + idx}>
                                    <PreloadAndDisplay
                                        {...func}
                                        comparison={props?.functionDetail?.type !== "match"}
                                        removeFunction={props.removeFunction}
                                        comparedPatternList={props?.functionDetail?.pattern_list}>

                                        <div className={"side-bar-function__buttons"}>

                                            <div className="side-bar-function__info">
                                                <FunctionInfoIconButton
                                                    className={"side-bar-function__info"}
                                                    function_id={func.id}
                                                    small={true}
                                                />
                                            </div>

                                            <IconButton title={t("Vergelijk beschrijving")} className={"side-bar-function__button"} icon={"compare-description"} sizeModifier={"medium"}
                                                        onClick={() => setDescription(func.id)}
                                            />
                                            { description === func.id && (
                                                <DescriptionModal
                                                    function_id={props?.functionDetail?.id}
                                                    function_id_two={func.id}
                                                    comparison={props?.functionDetail?.type !== "match"}

                                                    description={description}
                                                    setDescription={setDescription}
                                                />
                                            )}

                                            {props.edit && props.removeFunction && (
                                                <IconButton title={t("Referentiefunctie verwijderen")} className={"side-bar-function__minus"} icon={"minus"}  sizeModifier={"medium"} onClick={() => props.removeFunction(func.id)}/>
                                            )}
                                        </div>
                                    </PreloadAndDisplay>
                                </li>
                            )


                        })}
                    </ul>
                )}

                {(props?.functionDetail?.reference_functions?.length >= 3) && (
                    <>
                        <Notification type={"warning"} className={"side-bar-main__notification"} sizeModifier={"small"} text={t("Maximum aantal toegevoegd")}/>
                    </>
                )}
            </div>
        </div>
    )
}
