export const SET_OPTION = 'SET_OPTION';
export const SET_PAGE = 'SET_PAGE';
export const SET_ORDER = 'SET_ORDER';
export const SET_DIRECTION = 'SET_DIRECTION';
export const SET_FILTER = 'SET_FILTER';
export const SET_FILTERS = 'SET_FILTERS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const INIT_STATE = 'INIT_STATE';

export function initState(stateIdentifier, initialState) {
    return {
        type: INIT_STATE,
        stateIdentifier,
        initialState,
    };
}

export function setPage(stateIdentifier, data) {
    return {
        type: SET_ORDER,
        stateIdentifier,
        data,
    }
}

export function setOrder(stateIdentifier, data) {
    return {
        type: SET_ORDER,
        stateIdentifier,
        data,
    }
}
export function setDirection(stateIdentifier, data) {
    return {
        type: SET_DIRECTION,
        stateIdentifier,
        data,
    }
}

export function setFilter(stateIdentifier, data) {
    return {
        type: SET_FILTER,
        stateIdentifier,
        data,
    }
}

export function setFilters(stateIdentifier, filters) {
    return {
        type: SET_FILTERS,
        stateIdentifier,
        filters
    }
}

export function setOption(stateIdentifier, data) {
    return {
        type: SET_OPTION,
        stateIdentifier,
        data,
    }
}

export function clearFilter(stateIdentifier) {
    return {
        type: CLEAR_FILTER,
        stateIdentifier,
    }
}

export function clearFilters(stateIdentifier, filters) {
    return {
        type: CLEAR_FILTERS,
        stateIdentifier,
        filters
    }
}
