import cx from "classnames";
import {getUsers} from "../../queries/users";
import {TextFilter} from "./Filters/TextFilter";
import {DateFilter} from "./Filters/DateFilter";
import {EmptyFilter} from "./Filters/EmptyFilter";
import React from "react";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import IconButton from "../../patterns/molecules/IconButton";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {generatePath} from "react-router-dom";
import {projectStatus} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import i18n from "../../utils/i18n";

export const UserOverviewDefinition = {

    title: i18n.t("Gebruikersoverzicht"),
    identifier: 'user-overview',
    queryFn: getUsers,
    bulkValue: (obj) => obj.id,
    primary: {},
    secondary: {
        // title: "Bulk-actie",
        // identifier: 'bulk-action',
        // icon: 'list-icon'
    },
    defaultOrderIdentifier: 'last_name',
    columns: [
        {
            title: i18n.t("Voornaam"),
            identifier: 'first_name',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter,
        },
        {
            title: i18n.t("Tussenvoegsel"),
            identifier: 'insertion',
            type: "text-filter",
            orderable: false,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t("tussenvoegsel"),
            Filter: TextFilter,
        },
        {
            title: i18n.t("Achternaam"),
            identifier: 'last_name',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter,
        },
        {
            title: i18n.t("Oude gebruikersnaam"),
            identifier: 'username',
            type: "text-filter",
            orderable: true,
            placeholder: i18n.t("gebruikersnaam"),
            Filter: TextFilter,
        },
        {
            title: i18n.t("Email"),
            identifier: 'email',
            type: "text-filter",
            orderable: true,
            placeholder: i18n.t("voorbeeld@domeinnaam.nl"),
            Filter: TextFilter,
        },
        {
            title: i18n.t("Klant"),
            identifier: 'client__name',
            type: "text-filter",
            orderable: true,
            placeholder: i18n.t("Naam"),
            className: 'overview-table__filter-cell--width-xlarge',
            Filter: TextFilter,
        },
        {
            title: i18n.t("Laatste login"),
            identifier: 'last_login',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter,
        },
        {
            title: i18n.t("Actief"),
            identifier: 'is_active',
            className: 'overview-table__filter-cell--width-large',
            type: 'dropdown',
            orderable: false,
            Filter: DropdownFilter,
            options: [
                {
                    title: i18n.t('Actief'),
                    identifier: 'true'
                },
                {
                    title: i18n.t('Inactief'),
                    identifier: 'false'
                }
            ]
        },
        {
            identifier: 'action',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],
    RowStructure: (props) => {

        const {t, i18n} = useTranslation();
        const {interactive} = props;

        const detailUrl = props?.id && props?.client?.id ? generatePath('/client/:client_id/user/:user_id/', {
            user_id: props.id,
            client_id: props.client.id,
        }) : false;

        const detailClick = props?.onClick ? () => props.onClick(props) : (e) => {
            props.navigate( detailUrl );
        }

        return (
            <tr className={"overview-table__row"}>

                {props?.bulkColumn && props.bulkColumn }

                <td
                    data-cy={"overview-table-cell-first-name"}
                    onClick={detailUrl && interactive ? detailClick : () => false }
                    className={cx("overview-table__cell overview-table__cell--name", {
                        "overview-table__cell--interactive": interactive
                    })}>
                    <div className={"overview-table__text"} title={props.first_name}>
                        {props.first_name}
                    </div>
                </td>
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.insertion}>
                        {props.insertion}
                    </div>
                </td>
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.last_name}>
                        {props.last_name}
                    </div>
                </td>
                {props.columns.some(col => col.identifier === 'username') && (
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props?.username}>
                        {props.username}
                    </div>
                </td>
                )}
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.email}>
                        {props.email}
                    </div>
                </td>
                <td
                    onClick={() => {
                        if(props?.client?.id) {
                            props.navigate(generatePath('/client/:client_id/', {
                                client_id: props?.client?.id,
                            }));
                        }
                    }}
                    className={cx("overview-table__cell",{
                        "overview-table__cell--interactive": props?.client?.id,
                    })}>
                    <div className={"overview-table__text"} title={props?.client?.name}>
                        {props?.client?.name}
                    </div>
                </td>
                <td className={"overview-table__cell"}>
                    {props.last_login  ?
                        props.last_login === "2009-01-01T00:00:00+0100" ? (
                            <div className="overview-table__text">
                                {t("Nooit")}
                            </div>

                        ) : (
                        <div className={"overview-table__text"}>
                            <time dateTime={props.last_login}>
                                {new Intl.DateTimeFormat("nl-NL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric"
                                }).format(new Date(props.last_login))}
                            </time>
                        </div>
                    ) : (
                        <div className="overview-table__text">
                            {t("Onbekend")}
                        </div>
                    )}
                </td>
                {props.columns.some(col => col.identifier === 'is_active') &&
                    <td className="overview-table__cell">
                        <span className={cx("meta-info__status-tag", {
                            "status-tag status-tag--active": props?.is_active,
                            "status-tag status-tag--negative": !props?.is_active
                        })}>{props?.is_active ? t("Actief") : t("Inactief")}</span>
                    </td>
                 }
                {props.columns.some(col => col.identifier === 'action') && (
                    <td className={"overview-table__cell"}>
                        <IconButton title={t("Verwijder gebruiker")} icon={"trash"}  sizeModifier={"medium"} onClick={() => props?.contextData?.removeUser(props)}/>
                    </td>
                )}
            </tr>
        )
    },
}
