import Overview from "../Overview";
import {ClientProjectOverviewDefinition} from "../Overviews/ClientProjectOverview";
import React from "react";
import Quickview from "../Quickview";
import ClientOverview from "../Overviews/ClientOverview";
import {useTranslation} from "react-i18next";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import {generatePath} from "react-router-dom";
import {useAuth} from "../../utils/useAuth";

export default function ProjectOverviewScreen(props) {

    const {t, i18n} = useTranslation();

    const auth = useAuth();
    const hasCreateProjectPrimary = auth?.hasPermission("base.add_project");

    return (
        <div className={"content-container"}>
            <Quickview>

                <Overview
                    identifier={"projects"}
                    quickview={{
                        identifier: "projects",
                        name: t("Projecten")
                    }}
                    definition={{
                        ...ClientProjectOverviewDefinition,
                        columns: ClientProjectOverviewDefinition.columns.filter(o => o.identifier !== 'action'),
                        primary: {
                            title: t("Project aanmaken"),
                            identifier: 'create-project',
                            "data-cy": "create-project-button",
                            link: generatePath('/project/selector'),
                            allowed: hasCreateProjectPrimary
                        }
                    }}
                    initialFilters={{
                        status__in: ['concept', 'determined', 'expired']
                    }}
                />
            </Quickview>
        </div>
    )
}
