import {InfoButton} from "../../patterns/molecules/InfoButton";
import React from "react";
import {getFunction} from "../../queries/function_detail";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {FunctionStatus} from "../../patterns/atoms/Pip";


export function FunctionInfoIconButton(props) {
    const functionInfo = props?.functionInfo;
    const functionDetail = props.function_id ? getFunction(props.function_id) : false;
    const {t, i18n} = useTranslation();


    if(!functionInfo && !functionDetail?.data) {
        return (
            <LoaderAnimation/>
        )
    }

    const item = functionInfo || functionDetail?.data;


    return (
        <InfoButton
            className={cx(props?.className)}
            popOverClass={cx("popover", {
                "popover--narrow": props?.small === true
            })}
            icon={"info"}
            sizeModifier={"medium"}
            title={t("Meer informatie")}
            border={props.border}
        >
            <div className={"popover__content"}>
                <div className={"popover__title"} title={item.name}>
                    {item.name}
                </div>
            </div>
            <div className={"popover__content"}>
                <dl className={"popover-info popover-info--duo"}>
                    <div className={"popover-info__column"}>
                        <dt className={"popover-info__key"}>{t("Klant")}</dt>
                        <dd className={"popover-info__value"}>{item?.client?.name || "-"}</dd>

                        <dt className={"popover-info__key"}>{t("Project")}</dt>
                        <dd className={"popover-info__value"}>{item?.project?.name || "-"} </dd>

                        <dt className={"popover-info__key"}>{t("Versie")}</dt>
                        <dd className={"popover-info__value"}>{item?.version_number || "-"} </dd>
                    </div>

                    <div className={"popover-info__column"}>
                        <dt className={"popover-info__key"}>{t("Hoofdafdeling")}</dt>
                        <dd className={"popover-info__value"}>{item?.department?.name || "-"} </dd>

                        <dt className={"popover-info__key"}>{t("Subafdeling")}</dt>
                        <dd className={"popover-info__value"}>{item?.sub_department?.name || "-"}</dd>
                    </div>
                </dl>
            </div>

            {props?.small !== true && (

                <div className={"popover__content"}>
                    <dl className={"popover-info popover-info--duo"}>
                        <div className={"popover-info__column"}>
                            <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
                            <dd className={"popover-info__value"}>
                                {props.describer_group ?
                                    props.describer_group?.name
                                    :
                                    props?.describer?.get_full_name || "-"
                                }
                            </dd>
                        </div>

                        <div className={"popover-info__column"}>
                            <dt className={"popover-info__key"}>{t("Toepasser")}</dt>
                            <dd className={"popover-info__value"}>
                                {props.applier_one_group ?
                                    props.applier_one_group?.name
                                    :
                                    props?.applier_one?.get_full_name || "-"
                                }
                            </dd>

                            <dt className={"popover-info__key"}>{t("Toetser")}</dt>
                            <dd className={"popover-info__value"}>
                                {props.applier_two_group ?
                                    props.applier_two_group?.name
                                    :
                                    props?.applier_two?.get_full_name || "-"
                                }
                            </dd>
                        </div>
                    </dl>
                </div>
            )}
        </InfoButton>
    )
}
