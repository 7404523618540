import React, {Fragment, useState} from "react";
import ButtonPrimary from "../atoms/ButtonPrimary";
import cx from "classnames";
import GroupPatternScore from "../molecules/GroupPatternScore";
import {DropdownFilter} from "../molecules/DropdownFilter";
import {InfoButton} from "../molecules/InfoButton";
import TruncateMiddle from "../../utils/TruncateMiddle";
import {FunctionStatus} from "../atoms/Pip";
import {Icon} from "../atoms/Icon";
import {IconButton} from "../molecules/IconButton";
import {useMedia} from "../../utils/useMedia";
import {TextFilter} from "../../components/Overviews/Filters/TextFilter";
import {DateFilter} from "../../components/Overviews/Filters/DateFilter";
import {Title as DefaultTitle} from "../../components/Overviews/Titles/Default";
import {NameSourcetypeFilter} from "../../components/Overviews/Filters/NameSourcetypeFilter";
import {MNTTitle} from "../../components/Overviews/Titles/MNTTitle";
import {MNTFilter} from "../../components/Overviews/Filters/MNTFilter";
import {FunctionStatusFilter} from "../../components/Overviews/Filters/StatusFilter";
import {EmptyTitle} from "../../components/Overviews/Titles/EmptyTitle";
import {EmptyFilter} from "../../components/Overviews/Filters/EmptyFilter";

import {generatePath, Link} from "react-router-dom";
import {MNTDomains, SkeletonDefaults} from "../../utils/constants";
import ButtonSecondary from "../atoms/ButtonSecondary";
import LoaderAnimation from "../atoms/LoaderAnimation";
import {useTranslation} from "react-i18next";
import FunctionTypeIndicator from "../atoms/FunctionTypeIndicator";
import ConfirmModal from "./ConfirmModal";
import HorizontalFilterMenu from "../../components/HorizontalFilterMenu";
import i18n from "../../utils/i18n";
import ToolTip from "../../components/ToolTip";
import Select from "../atoms/Select";

export const OverviewPatternState = {

    title: i18n.t("Functieoverzicht"),
    identifier: 'function-overview',
    id: 9001,
    quickviews: [
        {
            title: i18n.t("Standaard"),
            identifier: 'default',
            RowStructure: function(tempProps,) {
                let props = Object.assign(
                    {},
                    tempProps,
                    {
                        comparison: false,
                        group: 4,
                        points: 123,
                        modification_date: "2019-08-04 12:00:01",
                        pattern_list: [
                            {score: 'H', comparison: 1,},
                            {score: 'F', comparison: 0,},
                            {score: 'F', comparison: 0,},
                            {score: 'X', comparison: 0,},
                            {score: 'E', comparison: 0,},
                            {score: 'A', comparison: 1,},
                            {score: 'H', comparison: 1,},
                            {score: 'G', comparison: 0,},
                            {score: 'G', comparison: 2,},
                        ]
                    }
                );

                const lengths = {
                    small: 15,
                    medium: 20,
                    large: 25,
                    xlarge: 30,
                    full: 35,
                }

                return (
                    <tr
                        key={props.id}
                        className={"overview-table__row"}
                    >
                        <td className={"overview-table__cell"}
                            onClick={(e) => props.navigate(`${props.url}function/${props.id}/`)}
                        >
                            <FunctionTypeIndicator type={props.type}/>
                        </td>
                        <td className={"overview-table__cell overview-table__cell--name"}
                            onClick={(e) => props.navigate(`${props.url}function/${props.id}/`)}
                        >
                            <div className={"overview-table__name overview-table__text"}>
                                <TruncateMiddle length={lengths[props.breakpoint]}
                                                secondary_length={lengths[props.breakpoint]} subst={'…'}>
                                    {props.name ? props.name : (<i>[GEEN NAAM]</i>)}
                                </TruncateMiddle>
                                {/*FIXME: titles in elements below are hardcoded and should be translatable strings:*/}
                                <div className={"overview-table__tags"}>
                                    {props.version_number && (<span
                                        className={"overview-table__tag overview-table__version"}> v{props.version_number}</span>)}
                                    {props.kind && (
                                        <span className={"overview-table__tag status-tag"}>{props.kind}</span>)}
                                    {props.invisible && (
                                        <span className={"overview-table__tag"} title={"Onzichtbaar"}><Icon
                                            icon={"visibility-off"} sizeModifier={"xsmall"}
                                            className={"overview-table__icon"}/></span>)}
                                    {props.protected && (
                                        <span className={"overview-table__tag"} title={"Beschermd"}><Icon
                                            icon={"protection-on"} sizeModifier={"xsmall"}
                                            className={"overview-table__icon"}/></span>)}
                                    {props.linked && (<span className={"overview-table__tag"} title={"Gekoppeld"}><Icon
                                        icon={"linked"} sizeModifier={"xsmall"}
                                        className={"overview-table__icon"}/></span>)}
                                </div>
                            </div>
                        </td>
                        <td className={"overview-table__cell"}
                            onClick={(e) => props.navigate(`${props.url}function/${props.id}/`)}
                        >
                            <GroupPatternScore {...props} />
                        </td>
                        <td className={"overview-table__cell"}
                        >
                            <div className={"overview-table__status"}>
                                <FunctionStatus description_status={props.description_status}
                                                scoring_status={props.scoring_status}/>
                            </div>
                        </td>
                        <td className={"overview-table__cell"}
                            onClick={(e) => props.navigate(`${props.url}function/${props.id}/`)}
                        >
                            <div className={"overview-table__text"}>
                                02-09-2019
                            </div>
                        </td>
                        <td className={"overview-table__cell"}
                            onClick={(e) => props.navigate(`${props.url}function/${props.id}/`)}
                        >
                            <div className={"overview-table__text"}>
                                {props.department?.name}
                            </div>
                        </td>
                        <td className={"overview-table__cell"}
                            onClick={(e) => props.navigate(`${props.url}function/${props.id}/`)}
                        >
                            <div className={"overview-table__text"}>
                                {props.sub_department?.name}
                            </div>
                        </td>
                        <td className={"overview-table__cell"}>
                            <InfoButton
                                icon={"info"}
                                sizeModifier={"medium"}
                                title={i18n.t("Meer informatie")}
                            >
                                Hoi: {props.title}
                            </InfoButton>
                        </td>
                    </tr>
                )
            }
        },
        {
            title: i18n.t("Mijn functies"),
            identifier: 'my-functions'
        },
        {
            title: i18n.t("Openstaande acties"),
            identifier: 'open'
        }
    ],
    primary: {
        title: i18n.t("Functie"),
        identifier: 'create-function'
    },
    secondary: {
        // title: "Bulk-actie",
        // identifier: 'bulk-action',
        // icon: 'list-icon'
    },
    columns: [
        {
            title: i18n.t('Type'),
            identifier: 'type',
            type: 'dropdown',
            className: 'overview-table__filter-cell--width-type',
            orderable: true,
            Filter: DropdownFilter,
            options: [
                {
                    title: i18n.t('R'),
                    identifier: 'reference'
                },
                {
                    title: i18n.t('B'),
                    identifier: 'company',
                },
            ],
        },
        {
            title: i18n.t("Naam"),
            identifier: 'name',
            type: "name-kind",
            orderable: true,
            Filter: NameSourcetypeFilter,
            FilterOptions: [
                {
                    title: i18n.t('CAO'),
                    identifier: 'cao'
                },
                {
                    title: i18n.t('Methode'),
                    identifier: 'method-function'
                }
            ]
        },
        {
            type: 'mnt',
            identifier: 'mnt',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: {domains: MNTDomains},
            Filter: MNTFilter,
            FilterOptions: {domains: MNTDomains},
        },
        {
            title: i18n.t('Stat.'),
            identifier: 'status',
            type: 'status-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-status',
            Filter: FunctionStatusFilter
        },
        {
            title: i18n.t('Bijgewerkt'),
            identifier: 'modification_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter
        },
        {
            title: i18n.t('Hoofdafdeling'),
            identifier: 'department',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            Filter: TextFilter
        },
        {
            title: i18n.t('Subafdeling'),
            identifier: 'sub_department',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            Filter: TextFilter
        },
        {
            identifier: 'info',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],


    count: 1234,
    state: {
        filter: {
            'mnt-domain-4': 'B',
            'mnt-domain-1': 'B',
            'sub-department': 'Best',
        },
        ordering: {
            identifier: "name",
            className: "overview-table__column-title--ordered-descending",
        }
    },
    stateIdentifier: {
        identifier: 'project-functions',
        id: 154,
    },
    overview: {
        setSize: 10,
        status: 'success',
        data: [
            {
                results: [
                    {
                        id: 1,
                        name: 'Standaard Titel met een lange tekst en een heel lang verhaal en weet ik wat allemaal je weet nooit of dit past',
                        type: 'company',
                        description_status: "concept",
                        scoring_status: "concept",
                        department: "Finance",
                        sub_department: "Administratie"
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering A',
                        id: 2,
                        type: 'reference',
                        description_status: "concept",
                        scoring_status: "to-counter",
                        version_number: 1,
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering B',
                        id: 3,
                        type: 'company',
                        description_status: "to-counter",
                        scoring_status: "determined",
                        department: "dP advertentiebedrijf",
                        version_number: 4,
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering C',
                        id: 4,
                        type: 'reference',
                        description_status: "determined",
                        scoring_status: "is-countered",
                        department: "Uitvoerende functies",
                        sub_department: "Uitvoerende functies",
                        version_number: 4,
                        kind: "CAO"
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering D',
                        id: 5,
                        type: 'reference',
                        description_status: "determined",
                        scoring_status: "determined",
                        department: "Strategie, Beleid en Organisatie",
                        version_number: 5,
                    },
                    {
                        id: 6,
                        name: 'Medewerker Financiële Administratie Inkomen',
                        type: 'reference',
                        description_status: "expired",
                        scoring_status: "expired",
                        version_number: 3,
                        invisible: true,
                    },
                    {
                        id: 7,
                        type: 'reference',
                        description_status: "archived",
                        scoring_status: "archived",
                        department: "Decentrale functies",
                        sub_department: "Decentrale functies",
                        version_number: 1,
                    },
                    {
                        id: 8,
                        name: 'In- en uitslagmedewerker Bewaring+Meertaken Planner',
                        type: 'reference',
                        protected: true,
                    },
                    {
                        id: 9,
                        type: 'reference'
                    },
                    {
                        id: 10,
                        name: 'Alternatieve titel 3',
                        type: 'reference',
                        department: "Service Organisatie, Uitvoerende functies",
                        sub_department: "Service Organisatie, Leidinggevende functies",
                        linked: true,
                    },
                    {
                        id: 11,
                        name: 'Standaard Titel met een lange tekst en een heel lang verhaal en weet ik wat allemaal je weet nooit of dit past',

                        type: 'company',
                        description_status: "concept",
                        scoring_status: "concept",
                        department: "Finance",
                        sub_department: "Administratie",
                        linked: true,
                        protected: true,
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering A',
                        id: 12,
                        type: 'reference',
                        description_status: "concept",
                        scoring_status: "to-counter",
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering B',
                        id: 13,
                        type: 'company',
                        description_status: "to-counter",
                        scoring_status: "determined",
                        department: "dP advertentiebedrijf",
                        linked: true,
                        protected: true,
                        invisible: true,
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering C',
                        id: 14,
                        type: 'reference',
                        description_status: "determined",
                        scoring_status: "is-countered",
                        department: "Uitvoerende functies",
                        sub_department: "Uitvoerende functies",
                        linked: true,
                        protected: true,
                        invisible: true,
                        kind: "CAO",
                        version_number: 11
                    },
                    {
                        name: 'Brug & Sluiswachter — Medewerker Uitvoering D',
                        id: 15,
                        type: 'reference',
                        description_status: "determined",
                        scoring_status: "determined",
                        department: "Strategie, Beleid en Organisatie",
                    },
                    {
                        id: 16,
                        name: 'Medewerker Financiële Administratie Inkomen',
                        type: 'reference',
                        description_status: "expired",
                        scoring_status: "expired",
                    },
                    {
                        id: 17,
                        type: 'reference',
                        description_status: "archived",
                        scoring_status: "archived",
                        department: "Decentrale functies",
                        sub_department: "Decentrale functies",
                    },
                    {
                        id: 18,
                        name: 'In- en uitslagmedewerker Bewaring+Meertaken Planner',
                        type: 'reference'
                    },
                    {
                        id: 19,
                        type: 'reference'
                    },
                    {
                        id: 20,
                        name: 'Alternatieve titel 3',
                        type: 'reference',
                        department: "Service Organisatie, Uitvoerende functies",
                        sub_department: "Service Organisatie, Leidinggevende functies",
                    },
                ]
            }
        ]
    }

}

// Create a component which walks through all Column definitions and find if a skeleton rendering element is present
// The resulting component is memoized as the result is always identical
const FakeRow = React.memo((props) => {
    return (
        <tr className={cx(props?.className, SkeletonDefaults?.tr)}>
            {props.columns.map((column, idx) => {
                const Skeleton = column?.Skeleton || (props => <div className={"table-skeleton"}/>);
                return (
                    <td
                        className={cx(SkeletonDefaults?.td)}
                        key={idx}>
                        <Skeleton/>
                    </td>
                );
            })}
        </tr>
    );
});

export default function OverviewDisplay(props) {

    const [activeBulkItem, setActiveBulkItem] = useState(false);
    const [bulkItem, setBulkItem] = useState(props.bulkOptions?.[0]?.bulkIdentifier);

    const {t, i18n} = useTranslation();
    const breakpoints = [
        {
            name: 'small',
            value: useMedia("(max-width:1024px)")
        },
        {
            name: 'medium',
            value: useMedia("(min-width:1025px) and (max-width:1100px)"),
        },
        {
            name: 'large',
            value: useMedia("(min-width:1101px) and (max-width:1200px)"),
        },
        {
            name: 'xlarge',
            value: useMedia("(min-width:1201px) and (max-width:1365px)"),
        },
        {
            name: 'full',
            value: useMedia("(min-width:1366px)")
        }
    ]
    const breakpoint = breakpoints.find(obj => obj.value)?.name || 'small';

    const checkBulkValue = (bulk, row) => (bulk && typeof bulk === "function") ? bulk(row) : false;

    const getAllCurrentItems = () => {
        // we loop the data set which contains multiple pages
        return props.overview.data.map(page => {
            // for everypage we loop the results
            return page.results.map(item => {
                // for every result we find the bulkvalue as is done when individually checking them
                return (checkBulkValue(props.bulkValue, item) || item);
            });
        }).flat();
    }

    const selectBulkOptions = props.bulkOptions ? props.bulkOptions?.flatMap((option) => {
        if(option.allowed && !option?.allowed(props.contextData)) {
            return [];
        }

        return {display_name: option.title, value: option.bulkIdentifier}
    }) : props.bulkOptions

    // For now, we check if we have name, and if so, use that column for 'global' search :)
    let globalSearch = props?.columns?.findIndex(obj => obj.identifier === 'name') >= 0;
    return (
        <React.Fragment>
            {props?.noHeader && props?.quickview && (
                <ul className="quick-views quick-views--no-header">
                    {props?.quickview && props?.quickview?.quickviews?.map((quickviewItem, idx) => {
                        return (
                            <li key={quickviewItem.identifier}
                                className={"quick-views__item"}>
                                <button
                                    className={cx("quick-views__link", {"quick-views__link--active": quickviewItem.identifier === props.quickview.quickview})}
                                    data-cy={"quickview-" + quickviewItem.identifier}
                                    onClick={() => props.quickview.setQuickview(quickviewItem.identifier)}
                                >
                                    {t(quickviewItem.name)}
                                </button>
                            </li>
                        )
                    })}
                </ul>
            )}
            <div data-cy={props.stateIdentifier.identifier} className={cx({"drawer__body-inner": props.isDrawer})}>
                <div ref={props.titleRef} className={cx("overview", {
                    "overview--no-container": props?.noContainer,
                    "overview--no-sticky": props?.noSticky,
                    "overview--no-primary": !props.primary?.title,
                    "overview--no-filter": props?.noFilter
                })}>
                    {!props?.noHeader && (
                        <div className={cx({"overview__sticky-header": !props?.noSticky})}>
                            <div className={"tool-header"}>
                                <div className={"tool-header__primary"}>
                                    <h2 className={"tool-header__title"}>{t(props.title)}</h2>
                                    {props.noPagination !== true && (
                                        <>
                                            {props.count !== false && (<span
                                                className={"tool-header__count"}>({props.count} {props.count === 1 ? t('resultaat') : t('resultaten')})</span>)}
                                        </>
                                    )}
                                    <ToolTip tooltip={t("Overzicht herladen")}>
                                        <IconButton className={"tool-header__refresh"}
                                                    icon={"refresh"}
                                                    sizeModifier={"medium"}
                                                    onClick={props.refresh} title={t('Verversen')}
                                        />
                                    </ToolTip>
                                </div>
                                <div className={"tool-header__secondary"}>
                                    {props.children}
                                    <ul className="quick-views">
                                        {props.quickview && props.quickview?.quickviews?.length > 1 && props.quickview.quickviews.map((quickviewItem, idx) => {
                                            return (
                                                <li key={quickviewItem.identifier}
                                                    className={"quick-views__item"}>
                                                    <button
                                                        className={cx("quick-views__link", {"quick-views__link--active": quickviewItem.identifier === props.quickview.quickview})}
                                                        onClick={() => props.quickview.setQuickview(quickviewItem.identifier)}
                                                    >
                                                        {t(quickviewItem.name)}
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {(props?.primary?.title || props?.secondary?.title) && (
                                <>
                                    <div className={"overview-tools"}>
                                        <div className={"overview-tools__primary"}>
                                            {props?.primary && props?.primary?.allowed === false ? (
                                                <>
                                                    <ButtonPrimary disabled={true}
                                                                   data-cy={props.primary?.["data-cy"]}>{props.primary.title}</ButtonPrimary>
                                                </>
                                            ) : (
                                                <>
                                                    {props?.primary?.title && props.primary?.identifier && props.primary?.link && !props.bulkEnabled && (
                                                        <Link className={"button button--primary"}
                                                              data-cy={props.primary?.["data-cy"]}
                                                              to={(props.primary.link instanceof Function && props.primary.link(props)) || props.primary.link}>
                                                            {props.primary.title}
                                                        </Link>
                                                    )}
                                                    {props?.primary?.title && props.primary?.identifier && props.primary?.action && (
                                                        <>
                                                            {props?.primary.action?.Component ? (
                                                                <props.primary.action.Component
                                                                    title={props.primary.title} {...props.primary.action}
                                                                    refresh={props.refresh}/>

                                                            ) : (
                                                                <ButtonPrimary text={props.primary.title}
                                                                               onClick={props.primary.action}/>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {props.bulkOptions && !props.bulkEnabled && (
                                                <ButtonSecondary
                                                    onClick={props.toggleBulk}
                                                    text={t("Bulk-actie")}
                                                    icon={"bulk-select"}
                                                />
                                            )}
                                            {props.bulkOptions && props.bulkEnabled && (
                                                <>
                                                    <div className={"select-container select-container--inline"}>
                                                        <Select id={props.id}
                                                                onChange={(e) => {
                                                                    setBulkItem(e.target.value)
                                                                }}
                                                                options={selectBulkOptions}
                                                        />
                                                        <Icon icon={"angle-down"} sizeModifier={"small"} className={"select-container__icon"}/>
                                                    </div>
                                                    {props.bulkOptions && props.bulkEnabled && (
                                                        <ButtonPrimary
                                                            onClick={() => setActiveBulkItem(bulkItem)}
                                                            text={t("Toepassen")}
                                                        />
                                                    )}
                                                    {props.bulkOptions.map(option => {
                                                        if(activeBulkItem === option.bulkIdentifier) {
                                                            if(option.targetUrl) {
                                                                return <ConfirmModal
                                                                    key={option.bulkIdentifier}
                                                                    title={option.title}
                                                                    body={option.confirmBody}
                                                                    confirmModal={() => {
                                                                        props.navigate(
                                                                            generatePath(
                                                                                option.targetUrl,
                                                                                props?.contextData ? props.contextData : {},
                                                                            ), {
                                                                                replace: false,
                                                                                state: {
                                                                                    ...props.bulkState
                                                                                }
                                                                            });

                                                                        setBulkItem(props.bulkOptions[0].bulkIdentifier)
                                                                        props.toggleBulk()
                                                                    }}
                                                                    closeModal={() => setActiveBulkItem(false)}
                                                                />
                                                            }
                                                            if(option.Component) {
                                                                const Component = option.Component;
                                                                return (
                                                                    <Component
                                                                        key={option.bulkIdentifier}
                                                                        contextData={props.contextData}
                                                                        bulkState={props.bulkState}
                                                                        bulkOption={props.bulkOption}
                                                                        toggleBulk={() => {
                                                                            setBulkItem(props.bulkOptions[0].bulkIdentifier)
                                                                            props.toggleBulk()}
                                                                        }
                                                                        closeModal={() => {
                                                                            props.overview.mutate();
                                                                            setActiveBulkItem(false);
                                                                        }}/>
                                                                )
                                                            }
                                                        }
                                                    })}
                                                </>
                                            )}

                                            {props.bulkOptions && props.bulkEnabled && (
                                                <ButtonSecondary
                                                    onClick={() => {
                                                        setBulkItem(props.bulkOptions[0].bulkIdentifier)
                                                        props.toggleBulk()
                                                    }}
                                                    text={t("Annuleren")}
                                                />
                                            )}
                                        </div>
                                        <div className={"overview-tools__secondary"}>
                                            {props?.advancedFilter && props?.advancedFilter.action?.Component && (
                                                <>
                                                    <props.advancedFilter.action.Component
                                                        overviewIdentifier={props.stateIdentifier.identifier}
                                                        identifier={props.advancedFilter.identifier}
                                                        {...props.advancedFilter.action}
                                                        setFilter={props.setFilter}
                                                        setFilters={props.setFilters}
                                                        clearFilters={props.clearFilters}
                                                        filterState={props.filterState}
                                                        contextData={props.contextData}
                                                    />
                                                </>
                                            )}
                                            {props?.secondary?.title && props.secondary?.identifier && (
                                                <ButtonSecondary text={"Filteren"} icon={"filter"}
                                                                 className={"overview-filter-button"}/>
                                            )}
                                            {(!props?.noSearch && globalSearch) && (
                                                <div className="overview-search">
                                                    <input type={"search"}
                                                           placeholder={"Zoeken"}
                                                           className={"overview-search__input"}
                                                           onChange={(e) => props.filter('name', e)}
                                                    />

                                                    <Icon icon={"search-list"} sizeModifier={"small"}
                                                          className={"overview-search__icon"}/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {props?.advancedFilter && props?.advancedFilter.action?.Component && (
                                        <div className={"overview-filter-container"}>
                                            <HorizontalFilterMenu
                                                filterLabels={props.advancedFilter.action.filterLabels}
                                                filterState={props.filterState} advancedFilter={props.advancedFilter}
                                                setFilter={props.setFilter}/>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    <table className={"overview-table"}>
                        <thead className={"overview-table__head"}>
                        <tr className={"overview-table__row"}>
                            {props?.bulkEnabled && (
                                <th key={"bulk"}
                                    className={cx("overview-table__filter-cell overview-table__filter-cell--width-bulk")}
                                >
                                    <EmptyTitle/>
                                    <input
                                        type={"checkbox"}
                                        title={t("Selecteer alle huidige items")}
                                        className={"checkbox checkbox--no-label"}
                                        onChange={e => {
                                            // when all is returned, set the multibulk
                                            props.setMultiBulk(getAllCurrentItems(), !!e?.target?.checked);
                                        }}
                                        // we are only checked if ALL items are checked
                                        checked={getAllCurrentItems().every(o => props.bulkState?.[o] === true)}
                                        id={"overview-checkbox-check-all"}
                                    />
                                    <label htmlFor={"overview-checkbox-check-all"}
                                           className={"overview-table__multi-bulk"}
                                           title={t("Selecteer alle huidige items")}>{t("Selecteer alle huidige items")}</label>
                                    {/*<input*/}
                                    {/*    onChange={e => {*/}
                                    {/*        // when all is returned, set the multibulk*/}
                                    {/*        props.setMultiBulk(getAllCurrentItems(), !!e?.target?.checked);*/}
                                    {/*    }}*/}
                                    {/*    type={"checkbox"}*/}
                                    {/*    className={"checkbox checkbox--no-label"}*/}
                                    {/*    // we are only checked if ALL items are checked*/}
                                    {/*    checked={getAllCurrentItems().every(o => props.bulkState?.[o] === true)}*/}
                                    {/*    id={"overview-check-all"}*/}
                                    {/*/>*/}
                                    {/*<label htmlFor={"overview-checkbox-check-all"} title={t("Selecteer alle huidige items")}>{t("Selecteer alle huidige items")}</label>*/}
                                </th>
                            )}
                            {props.columns.map((column, idx) => {

                                let additionalProps = {
                                    columns: props.columns,
                                    filter: props.filter,
                                    filterOptions: column?.FilterOptions,
                                    identifier: column?.identifier,
                                    interactive: props?.interactive,
                                    options: column?.options,
                                    order: props.order,
                                    orderable: props?.noOrder === true ? false : column?.orderable,
                                    ordering: props.orderingState,
                                    placeholder: column?.placeholder,
                                    prefix_identifier: props.prefix_identifier,
                                    stateIdentifier: props.stateIdentifier,
                                    setFilter: props.setFilter,
                                    setFilters: props.setFilters,
                                    clearFilters: props.clearFilters,
                                    state: props.filterState || '',
                                    title: column?.title,
                                    value: props.filterState || '',
                                    contextData: props?.contextData || {},
                                };

                                // Default to a title
                                let Title = DefaultTitle;
                                // Default to Text filter
                                let Filter = column?.Filter || TextFilter;

                                if(column?.Title) {
                                    Title = column.Title;
                                    additionalProps = Object.assign(additionalProps, column?.TitleOptions);
                                }

                                return (
                                    <th key={idx} className={cx("overview-table__filter-cell", column.className)}>
                                        <Title {...additionalProps} />
                                        {props?.noFilter === false && (
                                            <div className={"overview-table__filter"}>
                                                <Filter {...additionalProps} />
                                            </div>
                                        )}
                                    </th>
                                )
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {props.overview && props.overview.data && props.overview.data.map((page, idx) => {

                            return (
                                <Fragment key={idx}>
                                    {page.results.map((row, idx) => {

                                        if(row.fake) {
                                            return (
                                                <Fragment key={idx}>
                                                    <FakeRow
                                                        columns={props.columns}
                                                        className={"skeleton-row"}
                                                    />
                                                </Fragment>
                                            );
                                        }

                                        const bulkValueResult = checkBulkValue(props.bulkValue, row);
                                        const checked = props.bulkState?.[bulkValueResult] || false;
                                        const check = e => props.setBulk(bulkValueResult || row);
                                        const BulkColumn = p => {

                                            return props?.bulkEnabled ? (
                                                <td
                                                    className={cx("overview-table__cell", {
                                                        "overview-table__cell--row-selected": checked,
                                                    })}
                                                    onClick={check}
                                                >
                                                    <input
                                                        onChange={check}
                                                        type={"checkbox"}
                                                        className={"checkbox checkbox--no-label"}
                                                        checked={checked}
                                                        id={"overview-checkbox-" + (row?.id || row?.pk || idx)}
                                                    />
                                                    <label
                                                        htmlFor={"overview-checkbox-" + (row?.id || row?.pk || idx)}>{t("Selecteer rij")}</label>
                                                </td>
                                            ) : null;
                                        }


                                        const Component = props.RowStructure;

                                        return (
                                            <Component
                                                bulkColumn={<BulkColumn/>}
                                                key={row.id}
                                                {...row}
                                                breakpoint={breakpoint}
                                                interactive={props?.interactive}
                                                columns={props.columns}
                                                contextData={props?.contextData}
                                                navigate={props.navigate}
                                                onClick={(props?.bulkEnabled && check) || props?.onClick || false}
                                                prefix_identifier={props.prefix_identifier}
                                                url={props.url}
                                                refresh={props.refresh}
                                            />
                                        )

                                    })}
                                </Fragment>
                            )

                        })}

                        {props?.additions.map((obj, idx) => {
                            return (
                                <Fragment key={idx}>
                                    <FakeRow
                                        columns={props.columns}
                                        className={"filler-row"}
                                    />
                                </Fragment>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {props?.noPagination === false && (
                    <p className={cx("overview-pagination", {"overview-pagination--no-container": props?.noContainer})}>
                        <button disabled={props.overview.isValidating || !props.loadMore}
                                className={cx('button button--tertiary', {
                                    'button--loading': props.overview.isValidating
                                })}
                                onClick={() => props.setSize(props.overview.size + 1)}>
                            <span className={"button__text"}>{t("Toon meer resultaten")}</span>
                            {props.overview.isValidating && (
                                <div className={"button__loader"}>
                                    <LoaderAnimation small={true}/>
                                </div>
                            )}
                        </button>
                        {props?.loadAllAllowed && props.maxSize > 1 && props.loadMore && (

                            <button disabled={props.overview.isValidating || !props.loadMore}
                                    className={cx('button button--secondary overview__button-show-all', {
                                        'button--loading': props.overview.isValidating
                                    })}
                                    onClick={() => props.loadAll()}>
                                <span className={"button__text"}>{t("Toon alle resultaten")}</span>
                                {props.overview.isValidating && (
                                    <div className={"button__loader"}>
                                        <LoaderAnimation small={true}/>
                                    </div>
                                )}
                            </button>

                        )}
                    </p>
                )}
            </div>
        </React.Fragment>
    )
}
