import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import IconButton from "../molecules/IconButton";
import ButtonSecondary from "../atoms/ButtonSecondary";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Icon from "../atoms/Icon";
import Notification from "../molecules/Notification";

export default function MultiFieldModalTrigger(props) {

    const {t, i18n} = useTranslation();
    const defaultValue = Object.assign({}, ...(props.fields.map(o => ({[o.name]: ""}))))
    const [ active, setActive ] = useState(false);
    const [ fieldValue, setFieldValue] = useState(defaultValue);
    const [ error, setError ] = useState(false);


    const confirm = async () => {

        if(props.fields.every( o => fieldValue[o.name] !== "")) {

            if(props?.remoteCall) {
                let result = await props.remoteCall(fieldValue);
                if(result?.data && props?.onClick) {
                    props?.onClick(result.data);
                }

            }

            if(props?.refresh) {
                props.refresh();
            }
            setActive(false)
            setFieldValue(defaultValue);
        } else {
            setError(t("Niet alle velden zijn ingevuld"));
        }
    }

    return (
        <>
            <a href={"#"} onClick={ e=> { e.preventDefault(); setActive(true)}} className={"button button--primary"}>
                {props.title}
            </a>
            {active && (
                <MultiFieldModal
                    {...props}
                    closeModal={() => setActive(false)}
                    setFieldValue={(id, val) => setFieldValue({
                        ...fieldValue,
                        [id]: val,
                    })}
                    fieldValue={fieldValue}
                    error={error}
                    confirmModal={confirm}
                />
            )}
        </>

    )
}


export function MultiFieldModal(props) {

    const {t, i18n} = useTranslation();
    return (
        <>
            <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"} bodyOpenClassName={"has-modal"}>
                <div className={"modal-header"}>
                    <div className={"modal-header__top"}>
                        <h2 className={"modal-header__title"}>{ t(props.title) || t("Aanmaken")}</h2>
                        <ul className={"modal-header__controls"}>
                            <li className={"modal-header__control"}>
                                <IconButton icon={"close"} onClick={() => props?.closeModal()}/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={"modal__body"}>
                    {props.fields.map( field => {

                        return (
                            <div key={field.name} className="input-group">
                                <label className="label" htmlFor={field.name}>{t(field.label)}</label>
                                <div className="input-group__input">
                                    {field?.options ? (
                                        <div className="select-container">
                                            <select
                                                onChange={e => props.setFieldValue(field.name, e.target.value)}
                                                value={props.fieldValue[field.name]}
                                                name={field.name}
                                                id={field.name}
                                            >
                                                <option key={"empty"} value={""} disabled={true}>{t("Maak een keuze")}</option>
                                                {field.options.map( o => {
                                                    return (
                                                        <option key={o.identifier} value={o.identifier}>{t(o.value)}</option>
                                                    )
                                                })}
                                            </select>
                                            <Icon icon={"angle-down"} sizeModifier={"small"} className={"select-container__icon"}/>
                                        </div>
                                    ) : (

                                        <input
                                            placeholder={t(field?.placeholder)|| ""}
                                            id={field.name}
                                            name={field.name}
                                            type="text"
                                            value={props.fieldValue[field.name]}
                                            onChange={e => props.setFieldValue(field.name, e.target.value)}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                    {props.error && (
                        <>
                            <Notification type={"error"} sizeModifier={"large"} text={props.error}/>
                        </>
                    )}
                </div>

                <div className="modal-footer">
                    <ul className={"modal__buttons"}>
                        {!props?.singular === true && (
                            <li className={"modal__button"}>
                                <ButtonSecondary
                                    onClick={() => props?.closeModal()}>
                                    {t(props?.cancelText)|| t("Annuleren")}
                                </ButtonSecondary>
                            </li>
                        )}
                        <li className={"modal__button"}>
                            <ButtonPrimary
                                onClick={() => {
                                    if(props?.confirmModal) {
                                        props?.confirmModal();
                                    }
                                }}
                            >
                                {t(props?.confirmText)|| t("Aanmaken")}
                            </ButtonPrimary>
                        </li>
                    </ul>
                </div>

            </Modal>
        </>
    )

}
