import React from "react";

import {getProjects} from "../../queries/client_project";
import {TextFilter} from "./Filters/TextFilter";
import {ProjectStatusFilter} from "./Filters/StatusFilter";
import {FunctionSource, projectStatus} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import i18n from "../../utils/i18n";
import {NameSourcetypeFilter} from "./Filters/NameSourcetypeFilter";


const StandardRowStructure = React.memo(tempProps => {

    const {t, i18n} = useTranslation();
    let props = Object.assign(
        {
            id: false,
            name: "",
            method: "",
            status: "",
        },
        tempProps,
    );

    const detailClick = props?.onClick;

    return (
        <tr key={props.id}
            onClick={() => {
                detailClick(props);
            }}
            className={"overview-table__row"}
        >
            <td className={"overview-table__cell overview-table__cell--name overview-table__cell--interactive"} data-cy={"overview-table-cell-name"}>
                { props.name ? props.name : ''}
                {props.is_collective_labour_agreement && (<span className={"overview-table__tag status-tag status-tag--cao"}>{t(FunctionSource['cao'].short)}</span>)}
            </td>
            {props.columns.some(col => col.identifier === 'source_project__name') && (
                <td className={"overview-table__cell"} >
                    <div className={"overview-table__text"} title={props.source_project?.name}>
                        {props?.source_project?.name || ""}
                    </div>
                </td>
            )}
            <td className={"overview-table__cell overview-table__cell--name"}>{ props.client?.name ? props.client.name : ''}</td>
            <td className={"overview-table__cell overview-table__cell--name"}>{ props.method?.name ? props.method.name : ''}</td>
            {props.columns.some(col => col.identifier === 'status__in') && (
                <td className={"overview-table__cell"}>
                    {props.status && (
                        <span className={cx("meta-info__status-tag status-tag", {
                            "status-tag--active": props?.status === 'determined',
                            "status-tag--negative": ['expired', 'archived'].includes(props?.status),
                        })}>{projectStatus[props.status]}</span>
                    )}
                </td>
            )}
        </tr>
    )
});

export const ProjectSelectionDefinition = {

    title: i18n.t("Projectoverzicht"),
    identifier: 'project-overview',
    queryFn: getProjects,
    RowStructure: StandardRowStructure,
    paginationSize: 5,
    noHeader: true,
    noSearch: true,
    noContainer: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: i18n.t("Naam"),
            identifier: 'name',
            type: "name-kind",
            orderable: true,
            Filter: NameSourcetypeFilter,
            FilterOptions: [
                {
                    title: i18n.t('CAO'),
                    identifier: 'true'
                },
                {
                    title: i18n.t('Niet CAO'),
                    identifier: 'false'
                },
            ]
        },
        {
            title: i18n.t('Bronproject'),
            orderable: false,
            identifier: 'source_project__name',
            className:'overview-table__filter-cell--width-xlarge',
            Filter: TextFilter,
        },
        {
            title: i18n.t('Klant'),
            orderable: true,
            identifier: 'client__name',
            className: 'overview-table__filter-cell--width-pattern-widget',
            filter: TextFilter,
        },
        {
            title: i18n.t('Methode'),
            orderable: true,
            identifier: 'method__name',
            className: 'overview-table__filter-cell--width-pattern-widget',
            filter: TextFilter,
        },
        {
            title: i18n.t('Status'),
            identifier: 'status__in',
            type: 'status-filter',
            orderable: false,
            className: 'overview-table__filter-cell--width-pattern-widget',
            Filter: ProjectStatusFilter
        },
    ],
}

