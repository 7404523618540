import React from "react";
import {useParams} from "react-router-dom";
import {getFunction, getFunctionOptions} from "../queries/function_detail";
import {getClient} from "../queries/client_detail";
import {getProject} from "../queries/project_detail";
import {isFuwaterVbalans} from "../utils/constants";
import {useAuth} from "../utils/useAuth";

export const useFunctionEdit = () => {
    let { client_id, project_id, function_id } = useParams();

    const auth = useAuth();
    const client = getClient(client_id);
    const project = getProject(project_id);
    const function_options = getFunctionOptions()?.data?.actions?.POST;

    const canChangeCollectiveLabourAgreement = auth.hasPermission("base.change_collective_labour_agreement");
    const canChangeIfCollectiveLabourAgreement = canChangeCollectiveLabourAgreement || project?.data?.is_collective_labour_agreement === false;

    const current_function = getFunction(parseInt(function_id))

    const canChangeProtected = auth.hasPermission("base.change_function_protection");
    const canChangeIfProtected = canChangeProtected || !current_function?.data?.is_protected;

    const type_options = function_options?.type.choices
        .filter(o => (o.value === 'company' && !isFuwaterVbalans(project?.data?.method?.id)) || o.value !== 'company')
        .filter(o => auth.checkAccessList(
            'base.change_' + o.value + '_function',
            [
                [project?.data?.id  , 'project'],
                [client?.data?.id, 'client']
            ]
            )
        );

    return {
        canChangeIfCollectiveLabourAgreement,
        canChangeIfProtected,
        client_id: parseInt(client_id),
        client: client?.data,
        project_id: parseInt(project_id),
        project: project?.data,
        department: current_function?.data?.department,
        sub_department: current_function?.data?.sub_department,
        current_function: current_function?.data,
        function_id,
        type_options: type_options,
        describer_group: current_function?.data?.describer_group,
        applier_one_group: current_function?.data?.applier_one_group,
        applier_two_group: current_function?.data?.applier_two_group,
    };
}
