import useSWR from "swr";
import LoaderAnimation from "../../../patterns/atoms/LoaderAnimation";
import {DropdownFilter} from "../../../patterns/molecules/DropdownFilter";
import React from "react";

export default function LicenseFilter(props) {
    const { data: licenses } = useSWR('/api/licensetypes/');

    if(!licenses) {
        return (<LoaderAnimation />)
    }

    const options = licenses.results.map(license => {
        return { identifier: license.id.toString(), title: license.name }
    })

    return(
        <DropdownFilter
            {...props}
            prefix_identifier={ 'license_overview'}
            identifier={'license_type__in'}
            options={ options }
        />
    )
}
