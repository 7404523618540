import {DropdownFilter} from "../../../patterns/molecules/DropdownFilter";
import React from "react";
import {TextFilter} from "./TextFilter";
import {useTranslation} from "react-i18next";

export function NameSourcetypeFilter(props) {
    const {t} = useTranslation();

    return (
        <>
            <TextFilter identifier={"name"}
                        placeholder={"Naam"}
                        setFilter={props.setFilter}
                        state={props.state}
                        stateIdentifier={props.stateIdentifier}
            />

            <DropdownFilter identifier={"is_collective_labour_agreement"}
                            name={t("Soort")}
                            options={props?.filterOptions||[]}
                            setFilter={props.setFilter}
                            state={props.state}
            />
        </>
    )
}
