import i18n from "../utils/i18n";
import {useTranslation} from "react-i18next";
import Icon from "../patterns/atoms/Icon";
import React, {useState} from "react";
import cx from "classnames";
import ButtonWarning from "../patterns/atoms/ButtonWarning";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import Notification from "../patterns/molecules/Notification";

const map = {
    open: i18n.t("Je werkt op dit moment aan deze functie. Geef de functie vrij wanneer je klaar bent met deze functie."),
    closed: i18n.t("Op dit moment werkt niemand aan deze functie."),
    locked: i18n.t("werkt momenteel al aan deze functie. Je kunt daarom nu niet werken aan deze functie."),
    unknown: i18n.t("")
}

export function EditButton(props) {

    const {t, i18n} = useTranslation();
    const {editState, acquireLock, unlock} = props;
    const [modal, setModal] = useState(false);


    if(!editState?.state || editState?.immutable) {
        return null;
    }

    return (
        <>
            {
                !editState.hasEditRight ? (
                    <></>
                ) : (editState.state === 'locked') ? (
                    <>
                        <ButtonWarning onClick={() => setModal(!modal)} icon={"warning"} text={editState.locked.lockedByName + " " + t("werkt aan deze functie")}/>
                        {modal && (
                            <ConfirmModal
                                key={'function-locked'}
                                title={t("Werken aan deze functie niet mogelijk")}
                                body={t("Het is op dit moment niet mogelijk om aan deze functie te werken omdat al iemand anders aan deze functie werkt. Je kunt eventueel contact opnemen met de persoon die er op dit moment aan werkt")}
                                subject={`${editState.locked?.lockedByName} (${editState.locked?.lockedBy?.email|| t("email adres niet beschikbaar")})`}
                                singular={true}
                                confirmText={t("Sluiten")}
                                closeModal={() => setModal(false)} />
                        )}
                    </>
                ) : (editState.state === 'open') ? (
                    <>
                        <ButtonPrimary icon={"success"} onClick={unlock} text={t("Functie vrijgeven")}/>
                    </>
                ) : (editState.state === 'closed') ? (
                    <>
                        <ButtonPrimary icon={"edit"} active={"false"} onClick={acquireLock} text={t("Werk aan deze functie")} data-cy={"function-work-button"}/>
                        <div className={"button-unlock-function-container"}>
                            <div className={"button-unlock-function"}>
                                <div className={"button-unlock-function__primary"}>
                                    {t("Bewerk-modus staat")} <b>{t("uit")}</b>
                                </div>
                                <ButtonPrimary onClick={acquireLock} icon={"edit"} className={"button-unlock-function__secondary"}>
                                    {t("Werk nu aan deze functie")}
                                </ButtonPrimary>
                            </div>
                        </div>
                    </>
                ) :  (<></>)
            }
        </>
    )
}

export function Banner(props) {
    return (
        <div className={props.className}>
            {props.children}
        </div>
    )
}
