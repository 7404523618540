import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {InfoButton} from "../molecules/InfoButton";

export default function SimpleMetaDisplay(props) {

    const {t} = useTranslation();

    const {contextItems} = props;

    const [activeContext, setActiveContext] = useState(false);


    const contextItemsAllowed = (list) => {
        return list.filter(o => !('allowed' in o) || ('allowed' in o && (o.allowed === true || o.allowed() !== false)));
    }

    return (
        <div className={"meta-info"}>
            <div className={"meta-info__top"}>
                <div className={"meta-info__primary"}>
                    {props?.sub_name && (
                        <h2 className={"meta-info__pre-title"}
                            data-cy={"meta-info-sub-name"}> {t(props?.sub_name) || ""} </h2>
                    )}
                    <div className={"meta-info__title-container"}>
                        <h1 className={"meta-info__title"} data-cy={"meta-info-name"}>{t(props?.name) || ""}</h1>
                    </div>
                </div>


                {props?.contextItems?.length > 0 && (
                    <div className={"meta-info__secondary"}>
                        <div className={"meta-info__buttons"}>
                            <div className={"icon-button-group"}>
                                <InfoButton
                                    icon={"info"}
                                    title={t("Meer informatie")}
                                    popOverClickClass={"dropdown-toggle"}
                                    popOverClass={"dropdown"}
                                    popOverClassActive={"dropdown dropdown--active"}
                                    border={"true"}
                                >
                                    {props.children}
                                </InfoButton>

                                <InfoButton
                                    icon={"context-menu"} title={t("Contextmenu")}
                                    popOverClickClass={"dropdown-toggle"}
                                    popOverClass={"dropdown"}
                                    popOverClassActive={"dropdown dropdown--active"}
                                    border={"true"}
                                    data-cy={"context-menu"}
                                >
                                    <ul>
                                        {contextItemsAllowed(contextItems).map((obj, idx) => {
                                            return (
                                                <li key={obj.identifier} className={"dropdown-field"}
                                                    onClick={obj?.onClick ? obj?.onClick : null}
                                                >
                                                    {obj?.linkTitle ? (
                                                        <a className={"dropdown-field__label"} title={t(obj.linkTitle)}
                                                           onClick={() => setActiveContext(obj.identifier)}>
                                                            {t(obj.linkTitle)}
                                                        </a>
                                                    ) : (
                                                        <>{obj.link}</>
                                                    )}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </InfoButton>
                            </div>
                            {contextItemsAllowed(contextItems).map((obj, idx) => {
                                if (activeContext === obj.identifier) {
                                    return React.cloneElement(obj.Component, {
                                        key: idx,
                                        fallbackCloseModal: () => setActiveContext(false),
                                    })
                                }
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

}
