import React, {Fragment, useEffect, useState} from "react";
import {
    Link,
    useParams,
    useNavigate,
    useLocation,
    useRouteMatch,
    matchRoutes,
    resolvePath,
    matchPath
} from "react-router-dom";
import IconButton from "../molecules/IconButton";
import Icon from "../atoms/Icon";
import {useDispatch, useSelector} from "react-redux";
import {getProject} from "../../queries/project_detail";
import {breadcrumbResolvePairs, flatRouteList} from "../../components/FUWA";
import {getFunction} from "../../queries/function_detail";
import {getClient} from "../../queries/client_detail";
import useSWR from "swr";
import {getProjectGroup} from "../../queries/project_group";
import {getUser} from "../../queries/user";
import {setTitle} from "../../actions/site_info";
import {setBreadcrumbItem} from "../../actions/breadcrumbs";
import i18n from "../../utils/i18n";
import {useTranslation} from "react-i18next";

export const BreadcrumbsPatternState = {
    items: [
        {
            url: "/dashboard",
            name: i18n.t("Dashboard"),
        },
        {
            url: "/customer",
            name: i18n.t("Klanten"),
        },
        {
            url: "/project",
            name: i18n.t("Lindenhout PL"),
        },
        {
            url: "/functions",
            name: i18n.t("Projectoverzicht"),
        },
    ]
}

export const BreadcrumbsDefaultState = {
    // items: [
    //     {
    //         url: "/dashboard",
    //         name: "Dashboard",
    //     },
    //     {
    //         url: "/customer",
    //         name: "Klanten",
    //     },
    //     {
    //         url: "/project",
    //         name: "Lindenhout",
    //     },
    //     {
    //         url: "/functions",
    //         name: "Projectoverzicht",
    //     },
    // ]

}

const map = {
    "*": {
        name: i18n.t("Overzicht")
    },
    description: {
        name: i18n.t("Beschrijving")
    },
    client_id: {
        name: i18n.t("Klanten")
    },
    project_id: {
        name: i18n.t("Projectoverzicht"),
    },
    function_id: {
        name: i18n.t("Functies")
    },
    scoring: {
        name: i18n.t("Indelen")
    },
    rank: {
        name: i18n.t("Indelen")
    },
    summary: {
        name: i18n.t("Samenvatting")
    },
}

// Creates a list of URLs which we can match and find out if we can link to it
function pathsFromUrlList(urlList) {
    // duplicate list, and reverse it, and map it
    return [...urlList].reverse().map(item => {
        // join it to make url
        const ret = urlList.join('/');
        // strip last item for next iteration
        urlList.pop();
        return ret;
    }).reverse(); // reverse it again so we have list for the breadcrumbs
}

// Breadcrumb component to be used for displaying client name
function ClientName(props) {
    const params = useParams();
    const clientDetail = getClient(params.client_id);

    // const dispatch = useDispatch();
    // dispatch(setBreadcrumbItem("ClientName", clientDetail?.data?.name));

    return (
        <span>{clientDetail?.data?.name}</span>
    )
}

// Breadcrumb component to be used for displaying function name
function FunctionName(props) {
    const params = useParams();
    const functionDetail = getFunction(params.function_id);

    // const dispatch = useDispatch();
    // dispatch(setBreadcrumbItem("FunctionName", functionDetail?.data?.name));

    return (
        <span>{functionDetail?.data?.name}</span>
    )
}

// Breadcrumb component to be used for displaying project name
function ProjectName(props) {
    const params = useParams();
    const projectDetail = getProject(params.project_id);

    // const dispatch = useDispatch();
    // dispatch(setBreadcrumbItem("ProjectName", projectDetail?.data?.name));

    return (
        <span>{projectDetail?.data?.name}</span>
    )
}

function UserGroupName(props) {
    const params = useParams();
    const {data: userGroup} = useSWR(() => params?.user_group_id ? `api/usergroups/${params.user_group_id}/` : false);

    // const dispatch = useDispatch();
    // dispatch(setBreadcrumbItem("UserGroupName", userGroupName?.name));

    return (
        <span>{userGroup?.name}</span>
    )
}

function ProjectGroupName(props) {
    const {project_group_id} = useParams();
    const {data: projectGroupDetail} = getProjectGroup(project_group_id);

    // const dispatch = useDispatch();
    // dispatch(setBreadcrumbItem("ProjectGroupName", projectGroupDetail?.name));

    return (
        <span>{projectGroupDetail?.name}</span>
    )
}

function UserName(props) {
    const {user_id} = useParams();
    const {data: userDetail} = getUser(user_id);

    // const dispatch = useDispatch();
    // dispatch(setBreadcrumbItem("UserName", userDetail?.full_name));

    return (
        <span>{userDetail?.full_name}</span>
    )
}


// Component map to map a name to a component
const COMPONENTS = {
    'ClientName': ClientName,
    'ProjectName': ProjectName,
    'FunctionName': FunctionName,
    'UserGroupName': UserGroupName,
    'UserName': UserName,
    'ProjectGroupName': ProjectGroupName,
}

export default function BreadcrumbsDisplay(props) {

    let location = useLocation();
    let params = useParams();
    let navigate = useNavigate()
    let [pathItems, setPathItems] = useState([]);
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    let items = Object.entries(params).filter(obj => obj[0] !== '*');
    let current = items.pop();

    let urlList = location.pathname.substring(1).split('/').filter(o => o !== "");

    // the routelist is passed along so each item can be resolved to a name and URL
    let routeList = props?.routeList || flatRouteList; // make it possible to pass custom flatroutes (for nested urls!)

    // build pathlist only when the url changes
    useEffect(() => {

        // convert to list
        let paths = pathsFromUrlList(urlList);

        setPathItems(paths.map(url => {
            // match url based on the routeList for each individual part (this builds the information for a Link and a Breadcrumbname)
            let routes = matchRoutes(routeList, '/' + url);
            if (routes?.[0]?.params?.['*'] !== "") {
                return false;
            }
            return routes.length > 0 && routes?.[0]?.route?.breadCrumb ? routes[0] : false;
        }).filter(obj => obj !== false)); // make sure only elements are available which actually have info

    }, [location.pathname]);

    useEffect(() => {
        const item = pathItems[pathItems.length - 1];
        if (item) {
            dispatch(setTitle(item))
        }

    }, [pathItems[pathItems.length - 1]]);


    return (
        <div className={"content-wrapper content-wrapper--white content-wrapper--no-margin-bottom"}>
            <div className={"content-container content-container--no-margin"}>
                <div className={"breadcrumbs"}>
                    <ol className={"breadcrumbs__list"}>
                        {pathItems.map((item, idx) => {
                            let url = item.pathname;
                            let name = item.route.breadCrumb;
                            let Component = name.endsWith('Name') && name in COMPONENTS ? COMPONENTS[name] : false;  // determine if a breadcrumb might be a component which does stuff
                            let displayName = Component ? (<Component/>) : name;    // Set the display name to either a string or the component
                            let isLinked = idx < (pathItems.length - 1); // used to find out if we should link an item

                            return (
                                <Fragment key={idx}>
                                    <li key={url} className={"breadcrumbs__item"}>
                                        {isLinked && (
                                            <>
                                                <Link to={url} className={"breadcrumbs__link"}>{Component ? displayName : t(displayName)}</Link>
                                                <span className={"breadcrumbs__seperator"}></span>
                                            </>
                                        )}
                                        {!isLinked && (
                                            <span className={"breadcrumbs__link breadcrumbs__link--current"}>{Component ? displayName : t(displayName)}</span>
                                        )}
                                    </li>
                                </Fragment>
                            )
                        })}
                    </ol>
                </div>
            </div>
        </div>
    )
}

export function Breadcrumbs(props) {

    // const state = useSelector(state => state.BreadCrumbsState);
    let routeList = props?.routeList || flatRouteList; // make it possible to pass custom flatroutes (for nested urls!)

    return (
        <BreadcrumbsDisplay routeList={routeList}/>
    )

}
