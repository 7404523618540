import React, {useState, useEffect} from "react";
import FunctionGroupStructureDisplay from "../patterns/organisms/FunctionGroupStructure";
import {getFunctionGroupStructure, pdfFGS, saveFGS} from "../queries/function_group_structure";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {getFunctions} from "../queries/client_project_functions";
import axios from "axios";
import useSWR from "swr";
import {useDispatch, useSelector} from "react-redux";
import {
    setFGSDefaultSetting,
    setFGSGroupSetting,
    setFGSGroupSettingsAll,
    setFGSRecaluclateUsing
} from "../actions/function_group_structure";
import {useAuth} from "../utils/useAuth";
import {instantDownload} from "../utils/instantDownload";
import {AccessDenied} from "../patterns/organisms/AccessDenied";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import cx from "classnames";
import IconButton from "../patterns/molecules/IconButton";
import ButtonSecondary from "../patterns/atoms/ButtonSecondary";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import {generatePath, useNavigate} from "react-router-dom";

export const FunctionGroupStructureDefaultState = {

};


// Classify all functions for a project according to the groupstructure that is passed along
function functionToGroup(functions, groups) {

    let max = 0;

    let ret = groups.map( (obj,idx) => {

        // Just filter the list on the min points and max points
        // Note: when filtering comparison should be inclusive on bothe ends! so: min <= x <= max. This might feel
        // counterintuitive as the frontend displays for example: 44 -> 55 and 56 -> 60, but its not :)
        let fncRes = functions.filter(fnc => fnc.points  >= obj.points_min).filter(fnc => fnc.points <= obj.points_max);
        max = fncRes.length > max ? fncRes.length : max;

        return {
            ...obj,
            functions: fncRes,
            prevCount: functions.filter(fnc => fnc.function_group ===  obj.number).length
        }
    })


    return {
        max,
        groups: ret,
    };
}

// Sanitize the FGS definition, if something fails, fallback on initial values
// This is used so calculations can be done when no valid input was used in fields, but keeping their current value as-is (like for empty fields or '0.' values)
function sanitizeDefinition(fgsDefinition, currentFgs) {

    if(!currentFgs) {
        // we return if nog default is set. This is possible because the initial FGS (the 'currentFGS') is also parsed
        // we could do a more global sanitizing not based on the current FGS, like going for super defaults (like 1.25 for factor)
        return fgsDefinition;
    }

    let newDef = {
        ...fgsDefinition,
        group_start: isNaN(fgsDefinition.group_start) ? currentFgs.group_start : fgsDefinition.group_start,
        group_end: isNaN(fgsDefinition.group_end) ? currentFgs.group_end : fgsDefinition.group_end,
        points_start: isNaN(fgsDefinition.points_start) ? currentFgs.points_start : fgsDefinition.points_start,
        default_factor: isNaN(fgsDefinition?.default_factor) ? currentFgs.default_factor : fgsDefinition.default_factor,
    }

    return newDef;
}

// Function reads a FGS definition and builds the groups accordingly
export function ParseDefinition(fgsDefinition, currentFgs, forceRecalc=false) {

    // Here we overlay the 'current' on the 'original' definition, so we can use fgsDefinition a truth
    fgsDefinition = sanitizeDefinition(fgsDefinition, currentFgs);

    // Generate a lookup-map which uses the number as its key
    const groupMap = fgsDefinition?.groups?.reduce((map, obj, idx) => {
        map[obj.number] = obj;
        return map;
    }, {});

    let result = [];
    let points_start = fgsDefinition.points_start || 1;
    let last_points = 0;

    let lastGroupItem = false;

    // walk every number based on the definition of the FGS
    for(let groupNr=fgsDefinition.group_start; groupNr<=fgsDefinition.group_end;groupNr++){

        let groupItem = groupMap?.[groupNr];
        let points_min = last_points > 0 ? last_points + 1: points_start;
        let factor = fgsDefinition.default_factor;
        let points_max = Math.round(points_min * factor); // points are always rounded and shouldnt have fractions

        let groupDef = {
            factor,
            label: groupNr,
            number: groupNr,
            points_min,
            points_max,
            error: false,
        }

        // overlay a predefined group settings on the calculated (default) group settings
        if(!['points_start', 'default_factor'].includes(forceRecalc) && groupItem) {
            // when we are doing points_start / default_factor change, we should NOT use the overlay as we want to
            // completely recalculate the basics and thus overlay will be wrong

            let forcedGroupRecalc = {};

            switch(forceRecalc) {

                case 'factor':
                case 'points_min':
                    // if factor or minimum changes, we should recalculet the factor and the max
                    let group_factor = parseFloat(groupItem.factor);
                    forcedGroupRecalc = {
                        factor: group_factor,
                        points_max: Math.round(groupItem.points_min * group_factor)
                    }
                    break;
                case 'points_max':
                    // we can recalculate the factor based on a change in max (and identical min)
                    if(groupItem.points_max > 0 && groupItem.points_min > 0) {
                        forcedGroupRecalc = {
                            factor: ((groupItem.points_max) / (groupItem.points_min)).toFixed(3),
                        }
                    }
                    break;
            }
            // overlay defaults | previous items | forcefully changed items onto eachother
            groupDef = Object.assign(
                groupDef,
                {
                    ...groupItem,
                    factor: parseFloat(groupItem.factor),
                    number: parseInt(groupItem.number),
                    points_min: parseInt(groupItem.points_min),
                    points_max: parseInt(groupItem.points_max),
                    error: false,
                },
                forcedGroupRecalc);

            // determine if there is an indication of an offset error in points
            if(lastGroupItem && parseInt(lastGroupItem.points_max) + 1 !== parseInt(groupDef.points_min)) {
                groupDef.error = 'point_offset_error';
            }
        }


        // remember the points_max for the next iteration
        last_points = groupDef.points_max;

        // keep the newly parsed data for the next round so we can determine fault states
        lastGroupItem = groupDef;

        result.push(groupDef);
    }

    return result;

}

export default function FunctionGroupStructure(props) {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const auth = useAuth();
    const projectDetail = props?.projectDetail;

    const canSaveFGS = auth.hasPermission('base.change_functiongroupstructure', projectDetail?.id, 'project')
    const [doReset, setDoReset] = useState(false);
    const [downloadURL, setDownloadURL] = useState(false);
    const [pending, setPending] = useState(false);
    const [lastRecalculateUsing, setLastRecalculateUsing] = useState(null);


    // mainly used for pre-processing info and so we can fall back to it if required (ie: reset)
    const [currentFgs, setCurrentFgs] = useState({
        group_start: 0,
        group_end: 0,
        points_start: 1,
        default_factor: 1.25,
        groups:[],
    });

    const canViewPopup =  auth.hasPermission('base.can_view_fgs_popup', projectDetail.id, 'project');
    if(canViewPopup !== true && canViewPopup !== null) {
        return <AccessDenied />;

    }


    const fgsDetail = useSWR(() => projectDetail?.function_group_structure?.id ? `api/functiongroupstructures/${projectDetail?.function_group_structure?.id}/` : false);
    const functions = useSWR(() => projectDetail?.id ? `api/projects/${projectDetail?.id}/functions/?ordering=points&type=reference&scoring_status__in=determined,is-countered&page_size=1000` : false);


    // Fetch the state for this instance
    const fgsState = useSelector(state => state.FunctionGroupStructureState?.projects?.[projectDetail?.id]);
    const dispatch = useDispatch()

    const pdf = async () => {
        // let url = `/functiongroupstructure/pdf`;
        let url = `/api/export/project/report/${projectDetail?.id}/?pdf=1`;
        axios.post(url, Object.assign({}, fgsState, {
            project_name: projectDetail?.name,
            project_id: projectDetail?.id,
            function_list: true,
            project_header: true,
            fgs: true,
        })).then(result => {
                if(result?.data?.status === "ok") {
                    setPending(true);
                }
            }
        );
        // .then( response => {
        // const date = new Intl.DateTimeFormat("nl-NL", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric"
        //     // hour: 'numeric',
        //     // minute: 'numeric',
        // }).format(new Date());
        // const filename = `${projectDetail.client.name} - ${projectDetail.name} - ${t("Functiegroepenstructuur")} ${date} - FUWA Online.pdf`;
        // instantDownload(response.data, filename);
        // });
    }

    const save = async (pk, data) => {
       await saveFGS(pk, data);
       reset();
    }

    const reset = async () => {
        setDoReset(true);
        await fgsDetail.mutate();
        await functions.mutate();
    }

    // when the FGS Data and projectdata is available, set the information
    useEffect(() => {

        if(fgsDetail?.data && projectDetail?.id > 0) {
            let definition = {
                group_start: fgsDetail.data.group_start,
                group_end: fgsDetail.data.group_end,
                points_start: fgsDetail.data.points_start,
                default_factor: fgsDetail.data?.default_factor || 1.25,
                groups: fgsDetail.data?.groups||[],
            }
            setCurrentFgs(definition);

            // parse it to usable data and dispatch the defaults to Redux
            dispatch(setFGSDefaultSetting(projectDetail?.id, {
                ...definition,
                groups: ParseDefinition(definition)
            }))
            setDoReset(false);
        }

    }, [fgsDetail?.data, props?.projectDetail?.id, doReset]);

    useEffect(() => {
        if(fgsState) {
            // when groups are actually changed due to external influences (ie: it comes from the server) parse everything in a non-recalculative way
            dispatch(setFGSGroupSettingsAll(projectDetail.id, ParseDefinition(fgsState, currentFgs)));
        }

    }, [fgsState?.groups?.length])

    useEffect(() => {

        // Sometimes we need to forcefully recalculate certain data, this is hinted at by what we changed
        if(fgsState?.recalculateUsing) {
            // dispatch the event
            dispatch(setFGSGroupSettingsAll(projectDetail.id, ParseDefinition(fgsState, currentFgs, fgsState.recalculateUsing)));
            setLastRecalculateUsing(fgsState.recalculateUsing);
            // dispatch that we are currently not recalculating anymore
            dispatch(setFGSRecaluclateUsing(projectDetail.id, false));
        }

    },[fgsState?.recalculateUsing])

    // if either of the information is not yet parsed, return a loader
    if(!fgsDetail?.data || !functions?.data || !fgsState) {
        return (
            <LoaderAnimation />
        )
    }


    // we always want to recalculate in which group what function should be
    let groupedFunctions = functionToGroup(functions.data.results, fgsState.groups);

    return (
        <>
            <FunctionGroupStructureDisplay
                setFGSDefaultSetting={(identifier, value) => dispatch(setFGSDefaultSetting(projectDetail.id, {[identifier]: value}, identifier))}
                setFGSGroupSetting={(groupNumber, identifier, value) => dispatch(setFGSGroupSetting(projectDetail.id, groupNumber, {[identifier]: value}, identifier))}
                currentFgs={currentFgs}
                fgsForced={projectDetail.function_group_structure_source !== "project"}
                newFgs={fgsState}
                projectDetail={projectDetail}
                data={groupedFunctions}
                functionGroupStructureActive={props.functionGroupStructureActive}
                setFunctionGroupStructureActive={props.setFunctionGroupStructureActive}
                canSaveFGS={canSaveFGS}
                reset={reset}
                pdf={pdf}
                snapshots={fgsDetail?.data?.snapshots}
                downloadURL={downloadURL}
                saveFGS={() => {
                    if(canSaveFGS && projectDetail?.function_group_structure_source === "project") {
                        save(projectDetail?.function_group_structure?.id, fgsState)
                    }
                }}
                lastRecalculateUsing={lastRecalculateUsing}
            />
            {pending && (
                <>
                    <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"} bodyOpenClassName={"has-modal"}>
                        <div className={"modal-header"}>
                            <div className={"modal-header__top"}>
                                <h2 className={"modal-header__title"}>{ t("Download PDF")}</h2>
                                <ul className={"modal-header__controls"}>
                                    <li className={"modal-header__control"}>
                                        <IconButton icon={"close"} onClick={() => setPending(false)}/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={"modal__body"}>
                            <p className="paragraph">
                                {t("De PDF-rapportage wordt aangemaakt, dit kan enkele minuten duren...")}
                            </p>
                            <p className="paragraph">
                                {t("Je kunt het venster sluiten en terugkeren naar de FGS. Of direct naar het onderdeel Rapportages gaan. Wijzigingen aan de FGS worden niet opgeslagen.")}
                            </p>
                        </div>

                        <div className="modal-footer">
                            <ul className={"modal__buttons"}>
                                <li className={"modal__button"}>
                                    <ButtonSecondary onClick={() => setPending(false)}>{t("Sluit")}</ButtonSecondary>
                                </li>
                                <li className={"modal__button"}>
                                    <ButtonPrimary onClick={() => {
                                        navigate(generatePath('/reports/'));
                                    }}>{t("Ga naar rapportages")}</ButtonPrimary>
                                </li>

                            </ul>
                        </div>
                    </Modal>
                </>
            )}
        </>
    )
}
