import {generatePath, Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ProjectMeta from "./ProjectMeta";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function ProjectDetail(props) {

    const {t, i18n} = useTranslation();
    let { client_id, project_id } = useParams();
    let location = useLocation();
    let navigate = useNavigate()

    useEffect(() => {
        navigate(generatePath('/client/:client_id/project/:project_id/function', { client_id: client_id, project_id: project_id}));
    },[])

    return (
        <>
            <ProjectMeta
                client_id={project_id}/>
                <Link to={"function"}>{t("Functieoverzicht")}</Link>
        </>
    )
}
