import React from "react";
import {TextFilter} from "./Filters/TextFilter";
import {getMethodOverview, getMethodDetailOverview} from "../../queries/method_detail";
import {generatePath} from "react-router-dom";
import {getFunctionDescriptionTemplates} from "../../queries/function_description";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {DateFilter} from "./Filters/DateFilter";
import cx from "classnames";
import {Tag} from "../Tags";
import i18n from "../../utils/i18n";

export const DefaultTextOverviewDefinition = {
    title: i18n.t("Standaard-teksten"),
    identifier: 'default-text-overview',
    queryFn: getFunctionDescriptionTemplates,
    loadAllAllowed: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'creation_date',
    RowStructure: (props) => {

        return (
            <tr className={"overview-table__row"}>
                <td
                    onClick={e => {
                        props.navigate(generatePath("/default-text/:default_text_id", {
                            default_text_id: props.id,
                        }))
                    }}
                    className="overview-table__cell overview-table__cell--name overview-table__cell--interactive">
                    {props.title}
                </td>
                <td className="overview-table__cell">
                    <ul className={cx("tag-list")}>
                        {props.tags.map(tag => (<li key={tag.id} className={"tag-list__item"}>
                                <Tag tag={tag} edit={false} />
                            </li>)
                        )}
                    </ul>
                </td>
                <td>
                    {props.creation_date ? (
                        <time dateTime={props.creation_date}>
                            {new Intl.DateTimeFormat("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: false,
                            }).format(new Date(props.creation_date))}
                        </time>
                    ) : (
                        "-"
                    )}
                </td>

            </tr>
        )
    },
    columns: [
        {
            title: i18n.t('Naam'),
            identifier: 'name',
            orderable: false,
            className:'overview-table__filter-cell--width-name',
            placeholder: i18n.t('Klantnaam'),
            Filter: TextFilter
        },
        {
            title: i18n.t('Tags'),
            identifier: 'tags__icontains',
            orderable: false,
            className:'overview-table__filter-cell--width-name',
            Filter: TextFilter
        },
        {
            title: i18n.t('Aanmaakdatum'),
            identifier: 'creation_date',
            orderable: true,
            className:'overview-table__filter-cell--width-name',
            Filter: DateFilter
        },
    ]
}
