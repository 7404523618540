import React, {useEffect, useRef, useState} from "react";
import ProjectMeta from "./ProjectMeta";
import Quickview from "./Quickview";
import Overview from "./Overview";
import {UserGroupOverviewDefinition} from "./Overviews/UserGroupOverview";
import MultiFieldModal from "../patterns/organisms/MultiFieldModal";
import {getUserGroup, remoteAddUsersToUserGroup, remoteRemoveUsersFromUserGroup, remoteSetUserGroup} from "../queries/usergroups";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {UserOverviewDefinition} from "./Overviews/UserOverview";
import Drawer from "./Drawer";
import {getProject, remoteAddProject} from "../queries/project_detail";
import {ProjectSelectionDefinition} from "./Overviews/ProjectSelection";
import SingleFieldModal from "../patterns/organisms/SingleFieldModal";
import {remoteSetDepartment} from "../queries/department";
import {useSelector} from "react-redux";
import useSWR from "swr";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import {remoteRemoveProjectFromGroup} from "../queries/project_group";
import ClientMeta from "./ClientMeta";
import {useAuth} from "../utils/useAuth";

export function ProjectUserGroupDetail(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const { client_id, project_id, user_group_id} = useParams();
    const [selectUsers, setSelectUsers] = useState(false);
    const [removeUsers, setRemoveUsers] = useState(false);

    // create a new ref which can be passed to Overview and which will contain a reference to the overview's mutate
    const overviewRef = useRef(false)

    const {data: userGroup} = useSWR(() => user_group_id ? `api/usergroups/${user_group_id}/` : false);
    const {data: projectDetail} = getProject(project_id);

    const overviewIdentifier = "select-user-overview";
    const identKey = {
        client_id: client_id
    }
    const stateIdentifier = {
        identifier: overviewIdentifier,
        ...identKey,
    };
    const bulkState = useSelector(state => state.OverviewState?.[JSON.stringify(stateIdentifier)]?.bulk);

    if(!userGroup) {
        return <LoaderAnimation/>;
    }
    const hasAddRight = auth.checkAccessList('base.change_usergroup',
        [
            [client_id, 'client'],
        ]
    )
    const hasAddUserToGroupPrimary = hasAddRight ? {
        title: t("Gebruikers toevoegen"),
        identifier: 'add-users',
        action: () => setSelectUsers(true)
    }: {};

    return (
        <>
            {removeUsers && (
                <ConfirmModal
                    key={'remove-project-from-projectgroup'}
                    title={t("Verwijder gebruiker uit gebruikersgroep")}
                    body={t("Weet je zeker dat je de volgende gebruiker wilt verwijderen uit de gebruikersgroep") + " \"" + userGroup.name  + "\""}
                    subject={`${removeUsers.full_name}` }
                    confirmModal={async () => {
                        await remoteRemoveUsersFromUserGroup(user_group_id, [removeUsers.id]);
                        overviewRef.current.mutate();

                    }}
                    closeModal={() => setRemoveUsers(false)} />
            )}

            {selectUsers && (
                <>
                    <Drawer
                        identifier={"select-user"}
                        title={t("Selecteer gebruikers")}
                        readOnlyVal={obj => obj.name}
                        isForm={false}
                        noButton={true}
                        noReset={true}
                        initialOpen={true}

                        action={{
                            title: t("Toevoegen"),
                            Component: props => {
                                return (
                                    <a
                                        className={"button button--primary"}
                                        href={"#"} onClick={async e => {
                                        e.preventDefault();
                                        await remoteAddUsersToUserGroup(user_group_id, Object.entries(bulkState).filter(o => o[1] === true ).map(o => parseInt(o[0])));
                                        props.onClick();
                                        overviewRef.current.mutate();
                                    }}>{t("Geselecteerde gebruikers toevoegen")}</a>
                                )
                            }
                        }}

                        reset={() => {
                            // props.form.change('project_id', '');
                        }}

                        onClick={ async (obj) => {
                            // await remoteAddProject(project_id, obj.id);
                            // getProjectDetail.mutate();
                        }}

                        closeCallback={() => setSelectUsers(false) }

                        contentComponent={
                            <Overview
                                identifier={overviewIdentifier}
                                identKey={identKey}
                                contextData={{
                                    location,
                                }}
                                definition={{
                                    ...UserOverviewDefinition,
                                    primary: {},
                                    bulkEnabled: true,
                                    noHeader: true,
                                    noSearch: true,
                                    noContainer: true,
                                }}
                            />
                        }
                    />
                </>
            )}
            <div className={"content-container"}>
                <Quickview>

                    <Overview
                        // we pass a ref to the Overview which we customly handle and use. Normally ref's can't be passed to Functional Components
                        ref={overviewRef}
                        identifier={"project-users"}
                        quickview={{
                            identifier: "project-users",
                            name: t("Gebruikers")
                        }}
                        identKey={{client_id, project_id, user_group_id}}
                        contextData={{
                            location,
                            client_id,
                            user_group_id,
                            removeUser: o => {
                                setRemoveUsers(o);
                            }
                        }}
                        definition={{
                            ...UserOverviewDefinition,
                            columns: UserOverviewDefinition.columns.filter(o => o.identifier !== 'action' || hasAddRight),
                            title: `${userGroup.name}`,
                            primary: hasAddUserToGroupPrimary,
                        }}
                    />
                </Quickview>
            </div>
        </>
    )
}
