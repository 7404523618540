import {EditorDisplay} from "./RichtextManager";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getFunction, remoteSetPartial} from "../queries/function_detail";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useTranslation} from "react-i18next";
import Noty from "noty";

export default function FunctionMotivation(props) {

    const {t, i18n} = useTranslation();
    let [motivation, setMotivation] = useState(false);
    let [storedMotivation, setStoredMotivation] = useState(props.initialMotivation);

    let { function_id, project_id } = useParams();
    function_id = parseInt(function_id);
    const functionDetail = getFunction(function_id);

    useEffect(() => {
        setMotivation(functionDetail?.data?.ranking_motivation)

    },[functionDetail.isLoading === false && motivation === false])

    const motivationContent = (content) => {

        return {
            __html: content
        }
    }


    useEffect(async () => {
        if(props?.edit && motivation !== false && functionDetail?.data?.id && motivation !== storedMotivation) {
            let result = await remoteSetPartial(functionDetail?.data?.id, {
                ranking_motivation: motivation
            });
            setStoredMotivation(motivation);

            let noty = new Noty({
                theme: 'bootstrap-v4',
                layout: 'bottomRight',
                text: t("Je wijziging is automatisch opgeslagen!"),
                closeWith: ['click', 'button'],
                type: "success",
                timeout: 2000,
            }).show();
        }
    }, [motivation]);

    return (
        <div className={"description-editor description-editor--no-buttons"}>
            <div className="description-editor__item">
                <div className="description-editor__text">

                <h3 className={"description-editor__block-title"}>{props?.title || t("Onderbouwing")}</h3>
                {props?.edit ? (
                    <div className={"description-editor__rich-text"}>
                        {motivation !== false ? (
                            <EditorDisplay
                                content={motivation}
                                setMotivation={setMotivation}
                            />
                        ) : (
                            <LoaderAnimation/>
                        )}
                    </div>
                ) : (
                    <>
                        {
                            functionDetail?.data?.ranking_motivation !== "" ? (
                                <div dangerouslySetInnerHTML={motivationContent(functionDetail?.data?.ranking_motivation)}/>
                            ) : (
                                <>
                                    {t("Niet opgegeven")}
                                </>
                            )

                        }
                    </>
                )}
            </div>
            </div>
        </div>
    )
}
