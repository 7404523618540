import React, { useEffect, useState} from "react";
import FunctionMetaDisplay  from "../patterns/organisms/FunctionMeta";
import {getFunction, remoteSetFavorite, remoteSetPartial, remoteUnsetFavorite} from "../queries/function_detail";
import {getProject} from "../queries/project_detail";
import useSWR from "swr";
import axios from "axios";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useParams} from "react-router-dom";

// Values used by Redux State
export const FunctionMetaDefaultState = {
    project: {
        detail: {
            name: "",
        }
    },
    functionDetail: {
        data: {
            name: '',
            scores: [],
            pattern_list: [],
            visibility: false,
            protection: false,
        }
    }
}

export default function FunctionMeta(props) {

    const params = useParams();

    const functionDetail = getFunction(props.function_id);
    const projectDetail = getProject(props.project_id);

    const passProps = {
        editState: props?.editState,
        acquireLock: props?.acquireLock,
        unlock: props?.unlock,
    };

    const setLinked = async (state) => {
        await remoteSetPartial(props.function_id, {
            is_linked: state
        })
        functionDetail.mutate();
    }
    const setVisibility = async (state) => {
        await remoteSetPartial(props.function_id, {
            is_visible: state
        })
        functionDetail.mutate();
    }
    const setProtection = async (state) => {
        await remoteSetPartial(props.function_id, {
            is_protected: state
        })
        functionDetail.mutate();
    }
    const setFavorite = async (state) => {
        if(state) {
            await remoteSetFavorite(props.function_id, params?.project_id, params?.client_id)
        } else {
            await remoteUnsetFavorite(props.function_id, params?.project_id, params?.client_id)
        }
        functionDetail.mutate();
    }

    const edit = props.edit;

    if(!functionDetail.isLoading) {

        passProps.functionDetail = {
            ...FunctionMetaDefaultState.functionDetail,
            ...functionDetail,
            favorite: functionDetail?.data?.favorite,
        };

        passProps.functionDetail.setFavorite = () => {
            setFavorite(!functionDetail?.data?.favorite);

        }
        passProps.functionDetail.setLinked = () => {
            setLinked(!functionDetail?.data?.is_linked);
        };
        passProps.functionDetail.setVisibility = () => {
            setVisibility(!functionDetail?.data?.is_visible);
        };
        passProps.functionDetail.setProtection = () => {
            setProtection(!functionDetail?.data?.is_protected);
        };
    }

    if(!projectDetail.isLoading) {
        passProps.projectDetail = {
            ...FunctionMetaDefaultState.projectDetail,
            ...projectDetail
        };
    }

    passProps.client_id = props.client_id;

    if(!projectDetail.data || !functionDetail.data) {
        return <LoaderAnimation/>;
    }

    return (
        <FunctionMetaDisplay
            edit={props.edit}
            {...passProps}
        />
    )
}
