import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {singlePopOver, useState as popOverUseState} from "./PopOverHoC";
import {Icon} from "../patterns/atoms/Icon";
import cx from "classnames";

function FunctionGroupSelectorDisplay(props) {
    const {t} = useTranslation();
    let {function_id} = useParams();
    function_id = parseInt(function_id);

    const full_identifier = 'FunctionGroupSelector-' + function_id;
    const [popOverActive, setPopOverActive] = popOverUseState(props, full_identifier, false);

    return (
        <>
            <div className={"dropdown-toggle"}
                 onClick={() => {
                     setPopOverActive(!popOverActive, full_identifier);
                 }}
            >
                <span className={"dropdown-toggle__label"}>{t("Toon functies uit")}:&nbsp;</span>
                <span className={"dropdown-toggle__value"}>
                    {props.selectedGroup == "" ?
                        t("Alle groepen") :
                        <>
                            {props?.functionDetail?.type !== 'match' ?
                                <>
                                    {props?.functionDetail?.function_group_info?.number === props.selectedGroup ?
                                        `${t("Groepen rond de adviesgroep")} (${props.selectedGroup})`
                                        :
                                        `${t("Groepen rond groep")} ${props.selectedGroup}`
                                    }
                                </> :
                                <>
                                    {props?.functionDetail?.function_group !== null && props?.functionDetail?.function_group_info?.number === props.selectedGroup ?
                                        `${t("Dee adviesgroep")} (${props.selectedGroup - 1})`
                                        :
                                        `${t("Groep")} ${props.selectedGroup - 1}`
                                    }
                                </>

                            }

                        </>
                    }
                </span>

                <Icon icon={"angle-" + (popOverActive ? "up" : "down")}
                      sizeModifier={"xsmall"}
                      className={"dropdown-toggle__icon"}
                />
            </div>

            {props?.functionDetail?.type !== 'match' ?
                <div className={cx("dropdown dropdown--flip-right", {"dropdown--active": popOverActive})}>
                    <ul>
                        <li className={"dropdown-field"}
                            key={"0"} onClick={(e) => {
                            props.setGroupFilter("", true)
                            setPopOverActive(false);
                        }}>
                            <div className="dropdown-field__label">
                                {t("Alle groepen")}
                            </div>
                        </li>
                        {props.fgsDetail?.groups.map(group => {
                            return (
                                <li className={"dropdown-field"}
                                    key={group.number}
                                    onClick={e => {
                                        props.setGroupFilter(group.number)
                                        setPopOverActive(false);
                                    }}
                                >
                                    <div className="dropdown-field__label">
                                        {props?.functionDetail?.function_group_info?.number === group.number ?
                                            `${t("Groepen rond de adviesgroep")} (${group.label})`
                                            :
                                            `${t("Groepen rond groep")} ${group.label}`
                                        }
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div> :
                <div className={cx("dropdown", {"dropdown--active": popOverActive})}>
                    <ul>
                        <li key={"0"} onClick={(e) => {
                            props.setGroupFilter("", true)
                            setPopOverActive(false);
                        }}>
                            <div className="dropdown-field__label">
                                {t("Toon alle functies")}
                            </div>
                        </li>
                        {props.fgsDetail?.groups.map(group => {
                            return (
                                <li className={"dropdown-field"}
                                    key={group.number}
                                    onClick={e => {
                                        props.setGroupFilter(group.number, true)
                                        setPopOverActive(false);
                                    }}
                                >
                                    <div className="dropdown-field__label">
                                        {props?.functionDetail?.function_group !== null && props?.functionDetail?.function_group_info?.number === group.number ?
                                            `${t("Toon functies uit adviesgroep")} (${group.label})`
                                            :
                                            `${t("Toon functies uit groep")} ${group.label}`
                                        }
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default singlePopOver(FunctionGroupSelectorDisplay);
