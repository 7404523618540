import React from "react";
import Overview from "../Overview";
import {generatePath, useNavigate} from "react-router-dom";
import {TextFilter} from "./Filters/TextFilter";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import {withAccessRight} from "../../utils/withAccessHoC";
import LicenseFilter from "./Filters/LicenseFilter";
import {DateFormat} from "../../utils/DateFormat";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {getLicenses} from "../../queries/license_detail";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {IconButton} from "../../patterns/molecules/IconButton";

export const LicenseOverviewDefinition = {
    title: "Licentieoverzicht",
    identifier: 'license-overview',
    queryFn: getLicenses,
    loadAllAllowed: true,
    defaultOrderIdentifier: 'project__client__name',
    RowStructure: (props) => {
        const {t} = useTranslation();
        const endDateTime = props?.end_datetime ? DateFormat(props.end_datetime) : t("Niet gespecificeerd");
        const navigate = useNavigate();

        return (
            <tr className={"overview-table__row"}>
                <td className={"overview-table__cell overview-table__cell--interactive"}
                    onClick={() => {
                        props?.onClick ? props.onClick(props) : props.navigate(generatePath('/client/:client_id/', {
                            client_id: props.project?.client.id,
                        }));
                    }}
                >
                    {props.project?.client?.name}
                </td>

                <td className={"overview-table__cell overview-table__cell--interactive"}
                    onClick={() => {
                        props?.onClick ? props.onClick(props) : props.navigate(generatePath('/client/:client_id/project/:project_id/function', {
                            client_id: props.project?.client?.id,
                            project_id: props.project?.id
                        }));
                    }}
                >
                    {props.project?.name}
                </td>

                <td className="overview-table__cell">{endDateTime}</td>
                <td className="overview-table__cell">{props.license_name}</td>

                <td className={"overview-table__cell"}>
                    <IconButton title={"Wijzig Licentie"} icon={"edit"} sizeModifier={"medium"} data-cy={"edit-license-button"} onClick={() => {
                        navigate(`/backoffice/license/${props.id}/edit`)
                    }}/>
                </td>
            </tr>
        )
    },
    columns: [
        {
            title: 'Klantnaam',
            identifier: 'project__client__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-name',
            placeholder: 'Klantnaam',
            Filter: TextFilter
        },
        {
            title: 'Projectnaam',
            identifier: 'project__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-name',
            placeholder: 'Projectnaam',
            Filter: TextFilter
        },
        {
            title: 'Vervaldatum',
            identifier: 'end_datetime',
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: 'Vervaldatum',
            Filter: EmptyFilter,
        },
        {
            title: 'Licentienaam',
            identifier: 'license_type__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: 'Licentienaam',
            Filter: LicenseFilter,
        },
        {
            identifier: 'action',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ]
}

function LicenseOverview() {
    const {t} = useTranslation();

    return (
        <div className={"content-container"}>

            <Quickview>
                <Overview
                    identifier={"license-overview"}
                    definition={
                        LicenseOverviewDefinition
                    }
                    quickview={{
                        identifier: "license-overview",
                        name: t("Licenties")
                    }}
                />

                <Overview
                    identifier={"license-overview-expired"}
                    definition={
                        {...LicenseOverviewDefinition, defaultOrderIdentifier: "-end_datetime"}
                    }
                    quickview={{
                        identifier: "license-overview-expired",
                        name: t("Verlopen licenties")
                    }}
                    identKey={{end_datetime__lte: DateFormat(new Date())}}
                />

                <Overview
                    identifier={"license-overview-expired-soon"}
                    definition={
                        {...LicenseOverviewDefinition, defaultOrderIdentifier: "end_datetime"}
                    }
                    quickview={{
                        identifier: "license-overview-expired-soon",
                        name: t("Binnenkort verlopen licenties")
                    }}
                    identKey={{end_datetime__gte: DateFormat(new Date())}}
                />

                <Overview
                    identifier={"license-overview-without-end-datetime"}
                    definition={
                        LicenseOverviewDefinition
                    }
                    quickview={{
                        identifier: "license-overview-without-end-datetime",
                        name: t("Licenties zonder vervaldatum")
                    }}
                    identKey={{end_datetime__isnull: true}}
                />
            </Quickview>
        </div>
    )
}

export default withAccessRight(
    LicenseOverview,
    "base.view_license_overview",
)
