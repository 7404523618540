import axios from "axios";
import useSWR from "swr";

export function getBranches() {

    let url = `/api/branches/?page_size=100`;

    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}
