import React from 'react';
import cx from "classnames";
import Icon from "./Icon";
import i18n from "../../utils/i18n";

export const ButtonInvertedPatternState = {
    // default (no modifier) which is passed as props to the React Component
    text: i18n.t('Default Action'),
    disabled: false,

    plMod: {
        // :disabled modifier
        disabled: {
            disabled: true
        }
    }
}

export default function ButtonInverted(props) {

    return (
        <button
            onClick={props.onClick}
            className={cx("button button--inverted", props.className)}
            disabled={props.disabled}>
            {props.hasOwnProperty('icon') && <Icon icon={props.icon} sizeModifier={"small"} className={"button__icon"}/>}
            {props.text}{props.children}
        </button>
    )
}
