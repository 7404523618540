import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/reducers';
import * as Sentry from "@sentry/react";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// configure our ReduxStore and pass the initial state to it
export default function configureStore(initialState) {
    return createStore(
        rootReducer,                // the root (combined) reducer
        initialState,               // the initial state (if any)
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware,    // we use Redux Thunk to dispatch asynchronous Ajax calls. Redux is normally
                                    // not able to do async messaging
            ),
            sentryReduxEnhancer
        ),
    );
}
