import {useTranslation} from "react-i18next";
import Notification from "../molecules/Notification";
import React from "react";

export function AccessDenied(props) {

    const {t, i18n} = useTranslation();

    let additionalText = props?.additionalText || "";

    // console.log(props.error);
    // console.log(props.error.response.status);
    if(props?.error?.response?.status && !additionalText) {
        additionalText = "code: " + props.error.response.status;
    }

    return (
        <div>
            <Notification type={"error"} sizeModifier={"large"} text={t("Toegang geweigerd") +  (additionalText? ":": "") + " " + additionalText }/>
        </div>
    )
}

