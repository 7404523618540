import React, {Fragment, useEffect, useState} from "react";
import ScoringSidebarDisplay from "../patterns/organisms/ScoringSidebar";
import {getComparableFunctions} from "../queries/client_project_functions";
import Overview from "./Overview";
import {useParams} from "react-router-dom";
import SidebarFunction from "../patterns/molecules/SidebarFunction";
import IconButton from "../patterns/molecules/IconButton";
import {InfoButton} from "../patterns/molecules/InfoButton";
import cx from "classnames";
import {DescriptionModal} from "./Screens/Description";
import {FunctionInfoIconButton} from "./Overviews/FunctionInfoIconButton";
import {getRelatedFunctions} from "../queries/client_project_ranking";
import {useTranslation} from "react-i18next";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useDispatch, useSelector} from "react-redux";
import {setSidebarSearchOption} from "../actions/scoring";
import ToolTip from "./ToolTip";

export function SidebarOverview(props) {

    const {t, i18n} = useTranslation();
    const [description, setDescription] = useState(false);

    // if(props?.overview?.isValidating || props?.overview?.isValidating === undefined || !props?.overview?.data) {
    //     return (
    //         <div className={"side-bar-main"}>
    //         </div>
    //     )
    // }

    // console.log((props?.overview?.isValidating !== false || !Array.isArray(props?.overview?.data)));
    return (
        <>
            {/*{(props?.overview?.isValidating !== false || !Array.isArray(props?.overview?.data)) && (*/}
            {/*    <div style={{height: "80px"}}>*/}
            {/*        <LoaderAnimation*/}
            {/*            fullHeight={false}*/}
            {/*            small={true}/>*/}
            {/*    </div>*/}
            {/*)}*/}
            {props?.overview?.data?.map( (page, idx) => {
                return (
                    <Fragment key={idx}>
                        {page.results.map( (item, idx) => {

                            // active   compareTo   allowed
                            // ref      ref         yes
                            // ref      company     no          it might be allowed of we can compare on domain level
                            // company  ref         yes
                            // company  company     yes
                            const allowed = props.contextData.type === 'company' ? true : item.type ===  'reference'

                            return (
                                <SidebarFunction
                                    key={item.id}
                                    displayPoints={props?.contextData?.displayPoints}
                                    comparedPatternList={props?.contextData?.comparedPatternList}
                                    functionDetail={props?.contextData?.functionDetail}
                                    comparison={props?.contextData?.comparison}
                                    toggleHighlight={props?.contextData?.toggleHighlight}
                                    highlightActive={props?.contextData?.highlightActive}
                                    function={item}
                                >
                                    <div className={"side-bar-function__buttons"}>
                                        <div>
                                            {allowed && props?.contextData?.highlightActive && (
                                                <ToolTip tooltip={t("Score highlighten")}>
                                                    <IconButton
                                                        title={"Highlight score"}
                                                        className={cx("side-bar-function__button highlight-button", {
                                                            ['highlight-button--active-' + props?.contextData?.highlightActive(item.id)] : props?.contextData?.highlightActive(item.id)
                                                        })}
                                                        icon={"highlight"}
                                                        sizeModifier={"medium"}
                                                        onClick={() => props?.contextData?.toggleHighlight(item.id)}
                                                    />
                                                </ToolTip>
                                            )}
                                        </div>
                                        <div className={"icon-button-group"}>
                                            <ToolTip tooltip={t("Beschrijving vergelijken")}>
                                                <IconButton disabled={item.description_type !== 'normal'} title={item.description_type !== 'normal'? t("Geen beschrijving aanwezig") : t("Vergelijk beschrijving")}
                                                            className={"side-bar-function__button"}
                                                            icon={"compare-description"}
                                                            sizeModifier={"medium"}
                                                            onClick={() => setDescription(item.id)}
                                                />
                                            </ToolTip>
                                            { description === item.id && (
                                                <DescriptionModal
                                                    function_id={props?.contextData.function_id}
                                                    function_id_two={item.id}
                                                    description={description}
                                                    setDescription={setDescription}
                                                />
                                            )}
                                            <FunctionInfoIconButton
                                                functionInfo={item}
                                                small={true}
                                            />
                                        </div>
                                    </div>
                                </SidebarFunction>
                            )
                        })}
                    </Fragment>
                )
            })}
            {props.loadMore && (
                <p className={"overview-pagination overview-pagination--sidebar"}>
                    <button className={"button button--tertiary"} onClick={() => props.setSize(props.overview.size + 1)}>{t("Toon meer resultaten")}</button>
                </p>
            )}
        </>
    )
}

export const SidebarOverviewDefinition = {

    identifier: 'sidebar-function-overview-custom',
    queryFn: getComparableFunctions,
    displayOverride: SidebarOverview,
    defaultSize: 0,
    paginationSize: 50,
}

export const SidebarOverviewRelatedPatternFunctionDefinition = {
    ...SidebarOverviewDefinition,
    identifier: 'sidebar-function-overview-pattern',
    queryFn: getRelatedFunctions,
    defaultOrderIdentifier: 'distance',
    paginationSize: 50,
}

export const SidebarOverviewRelatedDomainFunctionDefinition = {
    ...SidebarOverviewDefinition,
    identifier: 'sidebar-function-overview-domain',
    queryFn: getRelatedFunctions,
    defaultOrderIdentifier: 'distance',
    paginationSize: 50,
}
export const SidebarOverviewRelatedAutomaticFunctionDefinition = {
    ...SidebarOverviewDefinition,
    identifier: 'sidebar-function-overview-automatic',
    queryFn: getRelatedFunctions,
    defaultOrderIdentifier: 'distance',
    paginationSize: 50,
}


export default function ScoringSidebar(props) {

    let { client_id, project_id, function_id } = useParams();
    const identKey = { client_id, project_id, function_id, identifier: 'sidebar-function-overview'}
    const dispatch = useDispatch();

    const searchOption = useSelector(state => state.ScoringState?.searchOption);

    useEffect(() => {
        if(searchOption === "" && props?.functionDetail) {
            dispatch( setSidebarSearchOption(props?.functionDetail?.type === 'reference' ? 'automatic': props?.functionDetail?.type === 'company' ? 'pattern' : 'custom'));
        }
    }, [searchOption]);

    if(searchOption === "") {
        return <LoaderAnimation/>;
    }

    return (
        <ScoringSidebarDisplay
            searchOption={searchOption}
            setSearchOption={(identifier) => dispatch(setSidebarSearchOption(identifier))}
            identKey={identKey}
            functionDetail={props.functionDetail}
            comparedPatternList={props.comparedPatternList}
            toggleHighlight={props.toggleHighlight}
            highlightActive={props.highlightActive}
            comparison={props.comparison}
            displayPoints={props.displayPoints} />
    )
}
