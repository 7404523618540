import Notification from "../molecules/Notification";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {withAccessRight} from "../../utils/withAccessHoC";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import DatePickerFilter from "../../components/Overviews/Filters/DatePickerFilter";
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {editEnvironment, getEnvironment, optionsEnvironment} from "../../queries/environments";
import {Input} from "../atoms/Input";
import {getYear} from "date-fns";

function EnvironmentEditDisplay() {
    const {t} = useTranslation();

    const navigate = useNavigate();
    const {environment_id} = useParams();
    const environment = getEnvironment(environment_id)?.data;
    const {register, handleSubmit, setError, watch, control, clearErrors, setValue, formState: {errors}} = useForm();
    const onSubmit = () => async (values) => {
        await editEnvironment(environment_id, values, navigate);
    }

     const {data} = optionsEnvironment();

    useEffect(() => {
        setValue("name", environment?.name);
        setValue("domain", environment?.domain);
        setValue("start_date", environment?.start_date ? new Date(environment?.start_date) : null);
        setValue("end_date", environment?.end_date ? new Date(environment?.end_date) : null);
    }, [environment]);

    return (
        <>
            <h1 className={"form-container__title"}>{t("Demo-omgeving aanpassen")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if(error.cause.message === "server-error-environment-edit" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Naam")}
                            placeholder={t("Naam")}
                            register={register}
                            required
                            error={errors.name}
                        />
                    </div>
                    <div className="input-group">
                        <div className={"input-group__before"}>{t(data?.environment?.prefix)}</div>
                        <Input
                            name={"domain"}
                            type={"text"}
                            disabled={true}
                            id={"domain"}
                            label={t("Domeinnaam")}
                            placeholder={t("Domeinnaam")}
                            register={register}
                            required
                            error={errors.domain}
                        />
                        <div className={"input-group__after"}>{t(data?.environment?.suffix)}</div>
                    </div>
                    <div className="input-group">
                        <Controller name={"start_date"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"start_date"}
                                            type={"text"}
                                            id={"start_date"}
                                            label={t("Startdatum")}
                                            placeholder={t("Startdatum")}
                                            maxDate={watch("end_date")}
                                            endYear={getYear(new Date()) + 11}
                                            onChange={onChange}
                                            selected={value}
                                            disabled={environment?.status === "active" ? true : false}
                                            helpText={environment?.status === "active" && t("De startdatum is al verstreken en kan daarom niet worden aangepast")}
                                        />
                                    )}
                        />
                    </div>
                    <div className="input-group">
                        <Controller name={"end_date"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"end_date"}
                                            type={"text"}
                                            id={"end_date"}
                                            label={t("Eind Datum")}
                                            placeholder={t("Eind Datum")}
                                            minDate={watch("start_date")}
                                            endYear={getYear(new Date()) + 11}
                                            onChange={onChange}
                                            selected={value}
                                        />
                                    )}
                        />
                    </div>
                    {errors.server &&
                        <Notification className={"form-container__error"} type={"error"}
                                      text={errors.server.message}/>
                    }
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"environment-edit-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const EnvironmentEdit = withAccessRight(
    EnvironmentEditDisplay,
    'base.can_setup_environments',
)
