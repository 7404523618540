import React from "react";
import {getClients} from "../../queries/client";
import {TextFilter} from "./Filters/TextFilter";
import {InfoButton} from "../../patterns/molecules/InfoButton";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {generatePath, useLocation} from "react-router-dom";
import Overview from "../Overview";
import {DateFilter} from "./Filters/DateFilter";
import {useAuth} from "../../utils/useAuth";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import TruncateMiddle from "../../utils/TruncateMiddle";
import i18n from "../../utils/i18n";


export const ClientOverviewDefinition = {

    title: i18n.t("Klantoverzicht"),
    identifier: 'client-overview',
    queryFn: getClients,
    loadAllAllowed: true,
    RowStructure: (props) => {

        const lengths = {
            small: 15,
            medium: 20,
            large: 25,
            xlarge: 30,
            full: 35,
        }

        return (
            <tr className={"overview-table__row"}>
                <td onClick={e => {
                        props.navigate(generatePath('/client/:client_id', { client_id: props.id}));
                    }}
                    className="overview-table__cell overview-table__cell--name overview-table__cell--interactive"
                    data-cy="overview-table-cell-name">
                    <TruncateMiddle length={lengths[props.breakpoint]} secondary_length={lengths[props.breakpoint]} subst={'…'}>
                        {props.name}
                    </TruncateMiddle>
                </td>
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.contact_name}>
                        {props.contact_name}
                    </div>
                </td>
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.contact_telephone}>
                        {props.contact_telephone}
                    </div>
                </td>
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.city}>
                        {props.city}
                    </div>
                </td>
                <td className={"overview-table__cell"}>
                    {props.creation_date  ? (

                        <div className={"overview-table__text"}>
                            <time dateTime={props.creation_date}>
                                {new Intl.DateTimeFormat("nl-NL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric"
                                }).format(new Date(props.creation_date))}
                            </time>
                        </div>
                    ) : (
                        <div className={"overview-table__skeleton"}/>
                    )}
                </td>
                {/*<td className={"overview-table__cell"}>*/}
                {/*    <InfoButton {...props}*/}
                {/*        sizeModifier={"medium"}>*/}
                {/*        {props.title}*/}
                {/*    </InfoButton>*/}
                {/*</td>*/}
            </tr>
        )
    },
    primary: {
    },
    secondary: {
        // title: "Bulk-actie",
        // identifier: 'bulk-action',
        // icon: 'list-icon'
    },
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: 'Naam',
            identifier: 'name',
            orderable: true,
            className:'overview-table__filter-cell--width-name',
            placeholder: i18n.t('Klantnaam'),
            Filter: TextFilter,

        },
        {
            title: 'Naam contactpers.',
            identifier: 'contact_name',
            orderable: true,
            className:'overview-table__filter-cell--width-xlarge',
            placeholder: i18n.t('Naam'),
            Filter: TextFilter,
        },
        {
            title: 'Tel. contactpers.',
            orderable: true,
            identifier: 'contact_telephone',
            className:'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Telefoonnummer'),
            Filter: TextFilter,
        },
        {
            title: 'Stad',
            identifier: 'city',
            orderable: true,
            className:'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Naam van de stad'),
            Filter: TextFilter,
        },
        {
            title: 'Aangemaakt op',
            identifier: 'creation_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter
        },
        // {
        //     identifier: i18n.t('info'),
        //     className: 'overview-table__filter-cell--width-icon-button',
        //     Title: i18n.t("EmptyTitle"),
        //     Filter: EmptyFilter
        // }
    ]
}

export default function ClientOverview(props) {

    let auth = useAuth();
    let location = useLocation();
    const {t, i18n} = useTranslation();

    const canCreateClient = auth.hasPermission('base.add_client');

    return (
        <div className={"content-container"}>

            <Quickview>

                <Overview
                    identifier={"client-overview"}
                    quickview={{
                        identifier: "client-overview",
                        name: t("Klanten")
                    }}
                    definition={{
                        ...ClientOverviewDefinition,
                        primary: {
                            title: t("Klant aanmaken"),
                            identifier: "create-client",
                            link: '/client/create/',
                            allowed: canCreateClient,
                            "data-cy": "client-create-button"
                        }
                    }}
                    contextData={{
                        location,
                    }}
                />
            </Quickview>
        </div>
    )
}
