import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {withAccessRight} from "../../utils/withAccessHoC";
import Notification from "../molecules/Notification";
import {language_options} from "../../utils/constants";
import {Controller, useForm} from "react-hook-form";
import {editClient} from "../../queries/client";
import {useNavigate, useParams} from "react-router-dom";
import {Input} from "../atoms/Input";
import Select from "../atoms/Select";
import {getBranches} from "../../queries/branch_detail";
import {getClient, getClientOptions} from "../../queries/client_detail";
import {useAuth} from "../../utils/useAuth";
import Dropzone from "react-dropzone";
import cx from "classnames";
import {Icon} from "../atoms/Icon";
import axios from "axios";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const ClientEditPatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function ClientEditDisplay() {
    const {client_id} = useParams();
    const client = getClient(parseInt(client_id))?.data;
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        watch,
        getValues,
        control,
        formState: {errors}
    } = useForm();

    useEffect(() => {
        if(client) {
            setValue("name", client.name);
            setValue("branch_id", client.branch_id);
            setValue("size", client.size);
            setValue("status", client.status);
            setValue("language", client.language);
            setValue("contact_name", client.contact_name);
            setValue("contact_email", client.contact_email);
            setValue("contact_telephone", client.contact_telephone);
            setValue("address", client.address);
            setValue("zip_code", client.zip_code);
            setValue("city", client.city);
            setValue("telephone_number", client.telephone_number);
            setValue("url", client.url);
            setValue("logo", client.logo)
        }
    }, [client])


    const branches = getBranches()?.data?.results;
    const client_options = getClientOptions()?.data?.actions?.POST;
    const size_options = client_options?.size.choices;
    const auth = useAuth();
    const canArchive = auth.hasPermission('base.can_archive_function');
    const status_options = client_options?.status.choices.filter(o => o.value !== "archived" || canArchive);

    const [invalidSize, setInvalidSize] = useState(false)
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onSubmit = () => async (values) => {
        delete values.logo

        await editClient(values, navigate, client_id).then(async (result) => {

            if (getValues("logo")) {
                let formData = new FormData();
                formData.append('logo', getValues("logo")[0]);

                await axios.put(`/api/clients/${result?.data?.id}/set_logo/`, formData, {
                    'content-type': 'multipart/form-data'
                })
            }

            if (!getValues("logo") && client.logo) {
                let formData = new FormData();
                formData.append('logo', null);

                await axios.put(`/api/clients/${result?.data?.id}/set_logo/`, formData, {
                    'content-type': 'multipart/form-data'
                })
            }

            navigate('../');
        });
    }

    // This useEffect is needed to show an error when a size in the database is not an option as a size in the select.
    // This useEffect can be removed when all the clients have correct sizes.
    useEffect(() => {
        const filterSizeOptions = size_options ? size_options?.some((size_option) => size_option.value === watch("size") || watch("size") === "") : false
        if(!filterSizeOptions && client) {
            setInvalidSize(true)
        } else {
            setInvalidSize(false)
        }
    }, [watch("size")])


    return (
        <>
            <h1 className={"form-container__title"}>{t("Klant bewerken")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server", "unique", "badRequest"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if(error.cause.message === "server-error-client-edit" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }

                    if(error.cause.message === "unique-error-client-edit") {
                        setError("unique", {
                            type: "unique",
                            message: t("Er is iets verkeerd gegaan. Mogelijk bestaat er al een klant met deze naam!")
                        })
                    }

                    setError("badRequest", {
                        type: "badRequest",
                        message: error.cause.message
                    })
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Naam")}
                            placeholder={t("Klantnaam")}
                            data-cy={"client-name-input"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Select
                            name={"branch_id"}
                            type={'select'}
                            options={branches}
                            id={"branch_id"}
                            label={t("Branche")}
                            placeholder={t("Kies een branche")}
                            register={register}
                            error={errors.branch_id}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Select
                            name={"size"}
                            type={'select'}
                            options={size_options}
                            id={"size"}
                            label={t("Aantal FTE")}
                            placeholder={t("Kies het aantal FTE")}
                            register={register}
                            error={invalidSize ? {message: t(`${client?.size} is een ongeldige keuze voor aantal FTE`)} : errors.size}
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"status"}
                            type={'select'}
                            options={status_options}
                            id={"status"}
                            label={t("Status")}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"language"}
                            type={'select'}
                            options={language_options}
                            id={"language"}
                            label={t("Taal")}
                            register={register}
                        />
                    </div>

                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Contactgegevens")}</legend>

                    <div className="input-group">
                        <Input
                            name={"contact_name"}
                            type={"text"}
                            id={"contact_name"}
                            label={t("Naam contactpersoon")}
                            placeholder={t("Naam contactpersoon")}
                            data-cy={"client-contact-name-input"}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"contact_email"}
                            type={"text"}
                            id={"contact_email"}
                            label={t("Emailadres contactpersoon")}
                            placeholder={t("Emailadres")}
                            data-cy={"client-contact-email-input"}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"contact_telephone"}
                            type={"text"}
                            id={"contact_telephone"}
                            label={t("Telefoonnummer contactpersoon")}
                            placeholder={t("012-3456789")}
                            data-cy={"client-contact-telephone-input"}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"address"}
                            type={"text"}
                            id={"address"}
                            label={t("Adres")}
                            placeholder={t("Straatnaam 12")}
                            data-cy={"client-address-input"}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"zip_code"}
                            type={"text"}
                            id={"zip_code"}
                            label={t("Postcode")}
                            placeholder={t("1234 AA")}
                            data-cy={"client-zip-code-input"}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"city"}
                            type={"text"}
                            id={"city"}
                            label={t("Stad")}
                            placeholder={t("Amsterdam")}
                            data-cy={"client-city-input"}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"telephone_number"}
                            type={"text"}
                            id={"telephone_number"}
                            label={t("Telefoonnummer")}
                            placeholder={t("012-3456789")}
                            data-cy={"client-telephone-number-input"}
                            register={register}
                        />
                    </div>
                </fieldset>

                {watch("logo") &&
                    <div className={"input-group"}>
                        <div className={"input-group__label-container"}>
                            <span className={"input-group__label"}>{t("Logo")}</span>
                        </div>
                        <div className={"client-logo"}>
                            <div className={"client-logo__img-container"}>
                                {watch("logo")?.[0].path ?
                                    <img className={"client-logo__img"}
                                         src={URL.createObjectURL(getValues('logo')[0])}/> :
                                    <img className={"client-logo__img"} src={watch("logo")}/>
                                }
                            </div>
                            <button className={"client-logo__remove"}
                                    type={"button"}
                                    onClick={() => {
                                        setValue("logo", null)
                                    }}
                            >
                                <Icon icon={"close"} sizeModifier={"xsmall"}/>
                            </button>
                        </div>
                    </div>
                }


                {!watch("logo") &&
                    <div className={"input-group"}>
                        <div className={"input-group__label-container"}>
                            <span className={"input-group__label"}>{t("Logo")}</span>
                        </div>
                        <p className={"input-group__help-text"}>
                            {t("Let op! Voor de resolutie van de afbeelding geldt het volgende advies. Bij verticaal georiënteerde logo's wordt een minimale hoogte van 1800 geadviseerd. Bij horizontaal georiënteerde logo's wordt een minimale breedte van 2250 geadviseerd. " +
                                "De geadviseerde bestandtypes zijn jpeg, .jpg of .png.")}
                        </p>
                        <Controller name={"logo"} control={control}
                                    render={({field: {onChange}}) => (
                                        <Dropzone multiple={false} maxFiles={1}
                                                  accept={['image/jpeg', 'image/jpg', 'image/png']}
                                                  onDrop={acceptedFiles => onChange(acceptedFiles)}>
                                            {({getRootProps, getInputProps, isDragActive}) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <div
                                                            className={cx("file-upload", {"file-upload--dragging": isDragActive})}>
                                                            <input {...getInputProps()} />
                                                            <Icon
                                                                className={"file-upload__icon"}
                                                                icon={cx({
                                                                    "success": isDragActive,
                                                                    "file": !isDragActive
                                                                })} sizeModifier={"small"}/>
                                                            <p className={"file-upload__description"}>
                                                                {
                                                                    isDragActive ? (
                                                                        <>
                                                                            {t("U kunt het bestand loslaten")}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {t("Sleep uw bestand hier naartoe of klik om te zoeken")}
                                                                        </>
                                                                    )
                                                                }
                                                            </p>
                                                            <ButtonPrimary
                                                                fullWidth={"true"}
                                                                type={"button"}>{t("Bestand zoeken")}</ButtonPrimary>
                                                        </div>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    )}
                        />
                    </div>
                }

                <fieldset className={"form-container__fieldset"}>
                    <div className="input-group">
                        <Input
                            name={"url"}
                            type={'text'}
                            id={"url"}
                            label={t("Website (URL)")}
                            register={register}
                        />
                    </div>
                    {errors.unique && <Notification type={"error"}
                                                    text={errors.unique.message}
                                                    data-cy={"error-unique"}
                                                    className={"form-container__error"}/>}
                    {errors.server && <Notification type={"error"}
                                                    text={errors.server.message}
                                                    className={"form-container__error"}/>}
                    {errors.badRequest && <Notification type={"error"}
                                                    text={errors.badRequest.message}
                                                    className={"form-container__error"}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"client-create-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const ClientEdit = withAccessRight(
    ClientEditDisplay,
    'base.change_client',
)
