import React from 'react';
import cx from "classnames";
import Icon from "./Icon";

export default function ButtonQuaternary(props) {
    return (
        <button
            onClick={props.onClick}
            className={cx("button button--quaternary", {'button--full-width': props.fullWidth}, props.className)}
            disabled={props.disabled}
            type={props.type}>
            {props.hasOwnProperty("icon") &&
                <Icon icon={props.icon} sizeModifier={"small"} className={"button__icon"}/>}
            {props.text}{props.children}
        </button>
    )
}
