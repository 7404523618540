import React from 'react';
import {useSelector} from "react-redux";
import HeaderDisplay from '../patterns/molecules/Header';
import i18n from "../utils/i18n";

// Values used by Redux State
export const HeaderDefaultState = {
    'title': i18n.t(''),
    items: [],
}

export default function Header(props) {

    // use a value from hello
    const state = useSelector(state => state.HeaderState);

    return (
        <HeaderDisplay items={state.items} title={state.title}/>
    );
}
