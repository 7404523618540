import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Overview from "../Overview";
import {ProjectSelectionDefinition} from "../Overviews/ProjectSelection";
import {useNavigate, useParams} from "react-router-dom";
import {withHook} from "../../utils/withHookHoC";
import {ClientSelectionDefinition} from "../Overviews/ClientSelection";
import {getUser} from "../../queries/user";
import ButtonPrimary from "../../patterns/atoms/ButtonPrimary";
import {Controller, useForm} from "react-hook-form";
import {getRoles} from "../../queries/role";
import {RadioInputs} from "../../patterns/atoms/Input";
import Drawer from "../Drawer";
import {assignRole} from "../../queries/users";
import Notification from "../../patterns/molecules/Notification";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {AccessDenied} from "../../patterns/organisms/AccessDenied";
import {useAuth} from "../../utils/useAuth";

export function RoleAddDisplay(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user_id} = useParams();

    const {
        register,
        handleSubmit,
        setError,
        reset,
        control,
        watch,
        clearErrors,
        setValue,
        formState: {errors}
    } = useForm();

    useEffect(() => {
        reset(formValues => ({
            ...formValues,
            type: null
        }))
    }, [watch("role_id")])

    useEffect(() => {
        setValue("user_id", props.user_id)
    }, [props.user_id])

    const onSubmit = () => async (values) => {
        await assignRole(values, navigate, user_id);
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Rol toewijzen")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error.cause.message === "server-error-user-assign-role" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <section className={"form-meta"}>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Gebruiker")}</span>
                        <span className={"form-meta__description"}
                              data-cy={"user-name"}>{props.user_name}</span>
                    </div>
                </section>

                <fieldset className={"form-container__fieldset"}>
                    <RadioInputs
                        id={"role_id"}
                        name={"role_id"}
                        options={props.roles}
                        label={t("Rol")}
                        helpText={t("Hieronder kunnen aanvullende rollen worden toegewezen waarmee een gebruiker meer mogelijkheden binnen de applicatie krijgt. Per rol worden deze mogelijkheden beschreven.")}
                        error={errors.role_id}
                        register={register}
                        selected={parseInt(watch("role_id"))}
                        setValue={setValue}
                        required
                    />
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Rol niveau")}</legend>

                    {props.type_options &&
                        <RadioInputs
                            id={"type"}
                            name={"type"}
                            options={props.type_options(watch("role_id"))}
                            label={t("Toewijzingsniveau")}
                            helpText={t("Voor de gekozen rol dient het juiste toewijzingsniveau opgegeven te worden. Het toewijzingsniveau kan afhankelijk van de gekozen rol beperkt zijn.")}
                            error={errors.type}
                            register={register}
                            selected={watch("type")}
                            setValue={setValue}
                            required
                        />
                    }

                    {watch("type") === "project" &&
                        <div className={"input-group"}>
                            <Controller name={"project_id"} control={control}
                                        rules={{required: t("Dit is een verplicht veld")}}
                                        render={({field: {onChange}}) => (
                                            <Drawer
                                                title={"Selecteer Project"}
                                                buttonText={"Zoek project"}
                                                label={t("Project")}
                                                initialReadOnlyVal={""}
                                                readOnlyVal={obj => obj.name}
                                                placeholder={t("Geen project gekozen")}
                                                id={"project_id_drawer"}
                                                isForm={true}
                                                disabled={!!props?.project}
                                                id={"project-id-drawer"}
                                                error={errors.project_id}
                                                required

                                                reset={() => {
                                                    onChange(null)
                                                }}

                                                onClick={(obj) => {
                                                    onChange(obj.id)
                                                }}

                                                contentComponent={
                                                    <Overview
                                                        identifier={"select-project-overview"}
                                                        identKey={{
                                                            client_id: props.client_id,
                                                            function_id: props.function_id,
                                                        }}
                                                        contextData={{
                                                            location,
                                                        }}
                                                        definition={ProjectSelectionDefinition}
                                                        initialFilters={{
                                                            status__in: ['concept', 'determined', 'expired']
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                            />
                        </div>
                    }

                    {watch("type") === "client" &&
                        <div className={"input-group"}>
                            <Controller name={"client_id"} control={control}
                                        rules={{required: t("Dit is een verplicht veld")}}
                                        render={({field: {onChange}}) => (
                                            <Drawer
                                                title={t("Selecteer Klant")}
                                                buttonText={"Zoek klant"}
                                                label={t("Klant")}
                                                initialReadOnlyVal={""}
                                                readOnlyVal={obj => obj.name}
                                                placeholder={t("Geen klant gekozen")}
                                                disabled={false}
                                                isForm={true}
                                                id={"client-id-drawer"}
                                                error={errors.client_id}
                                                required

                                                reset={() => {
                                                    onChange(null)
                                                }}

                                                onClick={(obj) => {
                                                    onChange(obj.id)
                                                }}

                                                contentComponent={
                                                    <Overview
                                                        identifier={"select-client-overview"}
                                                        identKey={{
                                                            client_id: props.client_id,
                                                            project_id: props.project_id
                                                        }}
                                                        contextData={{
                                                            location,
                                                        }}
                                                        definition={ClientSelectionDefinition}
                                                    />
                                                }
                                            />
                                        )}
                            />
                        </div>
                    }

                </fieldset>

                {errors.server &&
                    <Notification className={"form-container__error"} type={"error"}
                                  text={errors.server.message}/>}
                {Object.values(errors).find((error) => error.type === "required") &&
                    <Notification className={"form-container__error"} type={"error"}
                                  data-cy={"required-errors-summary"}
                                  text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                }
                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Rol Toewijzen")}
                        fullWidth={true}
                        data-cy={"assign-role-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const useRoleAdd = () => {
    const {t, i18n} = useTranslation();

    let {user_id} = useParams();
    const {data: userDetail} = getUser(user_id);
    const {data: roles} = getRoles();

    const formRoles = roles?.results?.map((role) => {
        if(role.id === 4 || role.id === 47) {
            return {
                ...role,
                "warning": true
            }
        }

        return role
    })

    const auth = useAuth();
    const canAddRole = auth.hasPermission('base.add_userrole', userDetail?.client?.id, 'client');

    if (!userDetail) {
        return <LoaderAnimation/>;
    }

    if (!canAddRole) {
        return <AccessDenied/>;
    }

    const type_options = (role_id) => {
        if (role_id === "5" || role_id === "46") {
            return [
                {
                    id: "global",
                    name: t("Systeem-breed"),
                },
                {
                    id: "client",
                    name: t("Klant-specifiek"),
                },
                {
                    id: "project",
                    name: t("Project-specifiek"),
                }
            ]
        }

        if (role_id === "11") {
            return [
                {
                    id: "global",
                    name: t("Systeem-breed"),
                    disabled: true
                },
                {
                    id: "client",
                    name: t("Klant-specifiek"),
                },
                {
                    id: "project",
                    name: t("Project-specifiek"),
                    disabled: true
                }
            ]
        }

        if (role_id === "12" || role_id === "48") {
            return [
                {
                    id: "global",
                    name: t("Systeem-breed"),
                    disabled: true
                },
                {
                    id: "client",
                    name: t("Klant-specifiek"),
                },
                {
                    id: "project",
                    name: t("Project-specifiek"),
                }
            ]
        }

        if (role_id === "44" || role_id === "4") {
            return [
                {
                    id: "global",
                    name: t("Systeem-breed"),
                },
                {
                    id: "client",
                    name: t("Klant-specifiek"),
                    disabled: true
                },
                {
                    id: "project",
                    name: t("Project-specifiek"),
                    disabled: true
                }
            ]
        }

        return [
            {
                id: "global",
                name: t("Systeem-breed"),
                disabled: true
            },
            {
                id: "client",
                name: t("Klant-specifiek"),
                disabled: true
            },
            {
                id: "project",
                name: t("Project-specifiek"),
                disabled: true
            }
        ]
    }

    return {
        type_options,
        user_name: userDetail?.full_name,
        user_id: userDetail?.id,
        roles: formRoles,
    }
}

export const UserAssignRole = withHook(
    RoleAddDisplay,
    useRoleAdd
)
