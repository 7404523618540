import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder, loadIfAllowed} from "../utils/constants";
import {useSWRInfinite} from "swr";

export function getReports(stateToDebounce) {

    const identifiers = ['reports', 'reports-download'];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            // fetchState?.identKey?.project_groups__not__id ? {
            //     project_groups__not__id: fetchState?.identKey?.project_groups__not__id
            // } : {},
            // fetchState?.identKey?.project_group_id ? {
            //     project_groups__id: fetchState?.identKey?.project_group_id
            // } : {},
            // fetchState?.identKey?.client_id ? {
            //     client__id: fetchState?.identKey?.client_id
            // } : {},
            // fetchState?.identKey?.projects ? {
            //     id: fetchState?.identKey?.projects.join(',')
            // } : {},
            // fetchState?.identKey?.user_id ? {
            //     user__id: fetchState?.identKey?.user_id
            // } : {},
            // fetchState?.identKey?.favorite ? {
            //     favorite: true,
            // } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/celerytasks/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index+1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}`: false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
            refreshInterval: 5000
        }
    );
}
