import {Icon} from "../atoms/Icon";
import React from "react";
import TruncateMiddle from "../../utils/TruncateMiddle";
import {useTranslation} from "react-i18next";
import cx from "classnames";

export default function OverviewName(props) {

    const {t, i18n} = useTranslation();

    let lengths = props?.lengths || {
        small: 15,
        medium: 20,
        large: 25,
        xlarge: 30,
        full: 35,
    };

    return (
        <div className={"overview-table__name overview-table__text"}>
            <TruncateMiddle length={lengths[props.breakpoint]} secondary_length={lengths[props.breakpoint]} subst={'…'}>
                { props.name ? props.name : (<div className={"overview-table__skeleton"}/>)}
            </TruncateMiddle>
            <div className={"overview-table__tags"}>
                {props.type !== 'unknown'  && props.fake !== true && (
                    <>
                        {props.version_number && (<span className={"overview-table__tag overview-table__version"}> v{props.version_number}</span>)}
                        {props.kind?.short && (<span className={cx("overview-table__tag", "status-tag",{ ["status-tag--" + props?.kind?.class] : props.kind?.class })}>{props.kind.short}</span>)}
                        {!props.is_visible && (<span className={"overview-table__tag"} title={t("Onzichtbaar: Normale gebruikers kunnen deze niet zien")}><Icon icon={"visibility-off"} sizeModifier={"xsmall"} className={"overview-table__icon"}/></span>)}
                        {props.is_protected && (<span className={"overview-table__tag"} title={t("Beschermd: Normale gebruikers kunnen deze niet wijzigen")}><Icon icon={"protection-on"} sizeModifier={"xsmall"} className={"overview-table__icon"}/></span>)}
                        {props.is_locked && (<span className={"overview-table__tag"} title={t("Vergrendeld: Een gebruiker werkt aan deze functie")}><Icon icon={"lock-filled"} sizeModifier={"xsmall"} className={"overview-table__icon"}/></span>)}
                        {props.is_linked && (<span className={"overview-table__tag"} title={t("Gekoppeld: Is beschikbaar binnen projectgroepen")}><Icon icon={"linked"} sizeModifier={"xsmall"} className={"overview-table__icon"}/></span>)}
                    </>
                )}
            </div>
        </div>
    )
}
