import axios from "axios";
import {useSWRInfinite} from "swr";
import {buildFilter, buildOrder, loadIfAllowed} from "../utils/constants";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";

export function getClients(stateToDebounce) {

    const identifiers = ['client-overview', 'user-client-overview', 'select-client-overview'];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.user_id ? {
                user__id: fetchState?.identKey?.user_id
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/clients/?page=${index + 1}&status=active&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export async function createClient(values, navigate) {
    return await axios.post("/api/clients/", values)
        .then(result => {
            if (result.status === 201) {
                if (result.data?.id) {
                    return result
                } else {
                    throw new Error("server-error-client-create", {cause: result});
                }
            } else if (result.data?.includes?.("unique constraint")) {
                throw new Error("unique-error-client-create", {cause: result});
            } else {
                throw new Error("server-error-client-create", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}

export async function editClient(values, navigate, client_id) {
    return await axios.patch("/api/clients/" + client_id + "/", values)
        .then(result => {
            if (result.status === 200) {
                return result
            } else if (result.data?.includes?.("unique constraint")) {
                throw new Error("unique-error-client-edit", {cause: result});
            } else if (result.status === 400) {
                if(result.data.size){
                    throw new Error(result.data.size[0])
                }
            }
            else {
                throw new Error("server-error-client-edit", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}
