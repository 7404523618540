import {useTranslation} from "react-i18next";
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import {archiveUser, getUser, switchToUser} from "../queries/user";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import SimpleMetaDisplay from "../patterns/organisms/SimpleMeta";
import cx from "classnames";
import React from "react";
import {useAuth} from "../utils/useAuth";
import {checkAccessList} from "../utils/withAccessHoC";
import {projectTabsProps, userTabsProps} from "../utils/constants";
import TabNavigation from "./TabNavigation";

export function UserMeta(props) {

    const {t, i18n} = useTranslation();
    const {user_id} = useParams();
    const {data: userDetail} = getUser(user_id);
    const client_id = userDetail?.client?.id;
    const auth = useAuth();
    const navigate = useNavigate();

    const hasChangeRight = checkAccessList('base.change_authuser', [
        ['client_id', 'client'],
        [userDetail?.client?.id, 'client']
    ]);
    const hasArchiveRight = checkAccessList('base.archive_user', [
        ['client_id', 'client'],
        [userDetail?.client?.id, 'client']
    ])

    const becomeUser = async () => {
        await auth.switchUser(userDetail?.id);
    }

    const hasBecomeUserRight = auth.hasPermission('base.become_user');

    if (!userDetail) {
        return <LoaderAnimation/>
    }

    const archive = async (id) => {
        await archiveUser(id);
        navigate("/user");
    }

    const contextItems = [
        {
            allowed: () => auth.hasPermission('base.add_userrole', userDetail?.client?.id, 'client'),
            identifier: 'assign-role',
            link: (
                <Link to={
                    generatePath('/user/:user_id/assign', {
                        user_id,
                    })}
                      className={"dropdown-field__label"} title={t("Rol toewijzen")}
                      data-cy={"context-menu-assign-role"}>
                    {t("Rol toewijzen")}
                </Link>

            )
        },
        {
            identifier: 'hr',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => hasChangeRight,
            identifier: 'change-user',
            link: (
                <Link to={
                    generatePath('/client/:client_id/user/:user_id/edit', {
                        user_id,
                        client_id,
                    })}
                      className={"dropdown-field__label"} title={t("Wijzig gebruikersgegevens")}
                      data-cy={"context-menu-edit-user"}>
                    {t("Wijzig gebruikersgegevens")}
                </Link>

            )
        },
        {
            allowed: () => hasArchiveRight,
            identifier: 'archive-function',
            linkTitle: "Archiveer gebruiker",
            Component: <ConfirmModal
                key={'archive-user'}
                title={("Gebruiker archiveren")}
                body={("Weet je zeker dat je deze gebruiker wilt archiveren?") + ":"}
                subject={userDetail?.full_name}
                confirmModal={() => archive(userDetail?.id)}/>
        },
        {
            allowed: () => false,
            identifier: 'test',
            linkTitle: "Test knopjes met modal",
            Component: <ConfirmModal
                confirmModal={() => console.log("Yay")}
            />
        },
        {
            allowed: () => hasBecomeUserRight,
            identifier: 'hr2',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => hasBecomeUserRight,
            identifier: 'test',
            linkTitle: t("Log in als deze gebruiker"),
            Component: <ConfirmModal
                title={t("Log in als deze gebruiker")}
                body={t(
                    "Let op! Als je op \"Log In\" klikt zal je automatisch worden ingelogd als deze gebruiker. " +
                    "Je zult zelf uit moeten loggen wanneer je klaar bent met het testen voor deze gebruiker. Na het " +
                    "uitloggen zul je opnieuw in moeten loggen als applicatiebeheerder.")
                }
                confirmText={t("Log in als deze gebruiker")}
                confirmModal={() => becomeUser()}
            />
        },
    ]

    return (
        <>
            <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
                <div className={"content-container"}>
                    <SimpleMetaDisplay name={userDetail?.full_name}
                                       // fixme: Mark needs to check if we let this stay or we don't
                                       // sub_name={userDetail?.client?.name}
                                       contextItems={contextItems}>
                        <div className={"popover popover--narrow popover--flip-right-item-left popover--active"}>
                            <div className={"popover__content"}>
                                <dl className={cx("popover-info")}>
                                    <div className={"meta-grid__column"}>
                                        <dt className={"meta-grid__key"}>{t("Emailadres")}</dt>
                                        <dd className={"meta-grid__value"} data-cy={"meta-info-email"}>
                                            {userDetail?.email || "-"}
                                        </dd>
                                        {hasChangeRight && (
                                            <>
                                                <dt className={"meta-grid__key"}>{t("Aangemaakt op")}</dt>
                                                <dd className={"meta-grid__value"}>
                                                    {userDetail?.creation_date ? (
                                                        <time dateTime={userDetail.creation_date}>
                                                            {new Intl.DateTimeFormat("nl-NL", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric"
                                                            }).format(new Date(userDetail.creation_date))}
                                                        </time>
                                                    ) : t("Onbekend")}
                                                </dd>
                                                <dt className={"meta-grid__key"}>{t("Laatst bewerkt")}</dt>
                                                <dd className={"meta-grid__value"}>
                                                    {userDetail?.modification_date ? (
                                                        <time dateTime={userDetail.modification_date}>
                                                            {new Intl.DateTimeFormat("nl-NL", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                                hour: 'numeric',
                                                                minute: 'numeric',
                                                            }).format(new Date(userDetail.modification_date))}
                                                        </time>
                                                    ) : t("Onbekend")}
                                                </dd>
                                                <dt className={"meta-grid__key"}>{t("Laatst ingelogd")}</dt>
                                                <dd className={"meta-grid__value"}>
                                                    {userDetail?.last_login ? (
                                                        <time dateTime={userDetail.last_login}>
                                                            {new Intl.DateTimeFormat("nl-NL", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric"
                                                            }).format(new Date(userDetail.last_login))}
                                                        </time>
                                                    ) : t("Onbekend")}
                                                </dd>
                                                <dt className={"meta-grid__key"}>{t("Wachtwoord laatst gewijzigd")}</dt>
                                                <dd className={"meta-grid__value"}>
                                                    {userDetail?.last_password_change ? (
                                                        <time dateTime={userDetail.last_password_change}>
                                                            {new Intl.DateTimeFormat("nl-NL", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric"
                                                            }).format(new Date(userDetail.last_password_change))}
                                                        </time>
                                                    ) : t("Onbekend")}
                                                </dd>
                                            </>
                                        )}
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </SimpleMetaDisplay>
                </div>
            </div>
            <div className={"content-container content-container--tabs"}>
                <TabNavigation tabs={userTabsProps(client_id, user_id)}/>
            </div>
        </>
    );
}
