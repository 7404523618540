import Overview from "../Overview";
import React from "react";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import {UserOverviewDefinition} from "../Overviews/UserOverview";
import {useAuth} from "../../utils/useAuth";
import {withAccessRight} from "../../utils/withAccessHoC";
import {generatePath, useParams} from "react-router-dom";


export default function UserOverviewScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();

    const { client_id: param_client_id } = useParams();
    const client_id = param_client_id || auth.user?.client?.id;

    const canCreateGlobalUser = auth.hasPermission('base.add_authuser');
    const canCreateClientUser = auth.hasPermission('base.add_authuser', client_id, 'client');

    const identKey = param_client_id ? {
        client_id: param_client_id,
    } : {};

    return (
        <div className={"content-container"}>

            <Quickview>

                <Overview
                    identifier={"global-user-overview"}
                    identKey={identKey}
                    quickview={{
                        identifier: "user",
                        name: t("Gebruikers")
                    }}
                    definition={{
                        ...UserOverviewDefinition,
                        interactive: true,
                        primary: {
                            title: t("Gebruiker aanmaken"),
                            identifier: 'create-user',
                            link: canCreateClientUser ? generatePath('/client/:client_id/user/create/', { client_id: client_id || auth.user?.client?.id}) : './create/',
                            allowed: canCreateClientUser || canCreateGlobalUser,
                            "data-cy": "user-create-button"
                        },
                        columns: UserOverviewDefinition.columns
                            .filter(o => o.identifier !== 'action')
                            .filter(o => o.identifier !== 'username' || canCreateGlobalUser)
                        ,
                    }}
                    initialFilters={{
                        is_active: ["true"]
                    }}

                />
            </Quickview>
        </div>
    )
}
