import cx from "classnames";
import React from "react";
import {useTranslation} from "react-i18next";

export function TextFilter(props) {
    const {t, i18n} = useTranslation();

    return (
        <input
            key={props.identifier}
            className={cx('text-filter', props.className)}
            type={"text"}
            placeholder={t(props.placeholder)}
            onChange={(e) => props.setFilter(props.identifier, e.target.value)}
            value={props.state?.[props.identifier] || ""}
            maxLength={props.maxLength || ""}
            data-cy={(props.stateIdentifier?.identifier || "undefined") + "-" + props.identifier}
        />
    )

}
