import Overview from "../Overview";
import React from "react";
import {useTranslation} from "react-i18next";
import {withAccessRight} from "../../utils/withAccessHoC";
import {ReportsOverviewDefinition} from "../Overviews/ReportsOverview";

export function ReportsOverview() {

    const {t} = useTranslation();

    return (
        <div className={"content-container"}>
            <Overview
                identifier={"reports"}
                quickview={{
                    identifier: "reports",
                    name: t("Rapportages")
                }}
                definition={{
                    ...ReportsOverviewDefinition,
                }}
            />
        </div>
    )
}

export default withAccessRight(
    ReportsOverview,
    'base.can_create_reports',
)
