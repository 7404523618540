import React from "react";
import {getStructure} from "../queries/scoring";
import {isBakkenistMNT, isFuwaterVbalans} from "../utils/constants";

export default function DomainTooltip(props) {
    const structure = getStructure(props.method_id, "reference")

    let domainInfo;

    if(isBakkenistMNT(props.method_id)) {
        domainInfo = structure?.data?.domains?.find((d, idx) => idx === props.domainIdx)
    } else if(isFuwaterVbalans(props.method_id)) {
        let domains = [];
        structure?.data?.domains.map(domain => domain.children[0].children.map(child => {
            domains = [...domains, {name: child.name}]
        }))
        domainInfo = domains?.find((d, idx) => idx === props.domainIdx)
    }

    return (
        <div>
            <div>{domainInfo?.name}</div>
        </div>
    )
}
