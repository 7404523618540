import React, {Fragment, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {getFunction} from "../queries/function_detail";
import {getProject} from "../queries/project_detail";
import {getStructure} from "../queries/scoring";
import FunctionGradingSummaryDisplay, {
    FunctionCompanyMatchDisplay,
    FunctionCompanySummaryDisplay,
    FunctionSummaryStatusDisplay
} from "../patterns/organisms/FunctionSummary";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import useSWR from "swr";
import {FunctionSource, isFuwaterVbalans} from "../utils/constants";
import {useAuth} from "../utils/useAuth";
import FunctionTypeIndicator from "../patterns/atoms/FunctionTypeIndicator";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import GroupPatternScore from "../patterns/molecules/GroupPatternScore";
import {InfoButton} from "../patterns/molecules/InfoButton";
import Icon from "../patterns/atoms/Icon";
import {AlternativeVersions, FunctionMetaInfo} from "../patterns/organisms/FunctionMeta";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import {Upload} from "./Upload";
import ButtonQuaternary from "../patterns/atoms/ButtonQuaternary";
import axios from "axios";
import Notification from "../patterns/molecules/Notification";

export function InsightSummary(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();

    let {function_id} = useParams();

    if(auth.user.insight_into_projects?.length < 1) {
        return null;
    }

    let project_id = auth.user.insight_into_projects[0].id;


    const navigate = useNavigate();
    const {data: functionDetail} = getFunction(function_id);
    const {data: projectDetail} = getProject(project_id);
    const {data: structureContent} = getStructure(projectDetail?.method.id, functionDetail?.type);
    const getStructureStatus = (structure_id) => functionDetail?.scores.find(obj => obj.structure_id === structure_id);

    const fgsDetail = useSWR(() => projectDetail?.function_group_structure?.id ? `api/functiongroupstructures/${projectDetail?.function_group_structure?.id}/` : false);

    function descriptionHtmlContent(item) {
        return {
            __html: item
        }
    }

    const descriptionType = functionDetail?.description_type || "normal";

    const handleClick = (e) => {
        let url = `/api/export/function/report/${function_id}/?pdf=1`;

        axios.post(url, Object.assign({}, {
            client_name: projectDetail?.client?.name,
            project_name: projectDetail?.name,
            function_header: true,
            meta: true,
            reference_functions: true,
            motivation: true,
            scoring: true,
            scoring_help: true,
            function_description: true,
            client_id: projectDetail.client?.id,
            project_id: projectDetail.id,
            function_id: functionDetail.id,
        })).then(result => {
                if(result?.data?.status === "ok") {
                    console.log("Klaar")
                }
            }
        )
    }


    return (
        <>
            <div className={"content-wrapper content-wrapper--white content-wrapper--no-margin-bottom"}>
                <div className={"content-container content-container--no-margin"}>
                    <div className={"breadcrumbs"}>
                        <ol className={"breadcrumbs__list"}>
                            <li className={"breadcrumbs__item"}>
                                <span className={"breadcrumbs__link breadcrumbs__link--return"}
                                      onClick={e => navigate("/home")}
                                >
                                    <Icon icon={"arrow-left"} sizeModifier={"xsmall"}/>
                                    {t("Terug naar overzicht")}
                                </span>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>

            <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
                <div className={"content-container"}>
                    <div className={"meta-info"}>
                        <div className={"meta-info__top"}>
                            <div className={"meta-info__primary"}>
                                <div className={"meta-info__title-container meta-info__title-container--function"}>
                                    {!isFuwaterVbalans(projectDetail?.method?.id) && (
                                        <FunctionTypeIndicator type={functionDetail?.type}/>
                                    )}
                                    <h1 className={"meta-info__title"}>{functionDetail?.name}</h1>
                                </div>
                                <div className={"meta-info__pattern-status-duo"}>
                                    <GroupPatternScore {...functionDetail} comparison={false}/>
                                </div>
                            </div>
                            <div className={"meta-info__button meta-info__insight-user-actions"}>
                                {props.buttonActiveState === "true" && <Notification
                                                text={"Rapportage wordt gemaakt"}/>}
                                <ButtonQuaternary icon={"file"}
                                                  text={t("Rapportage maken")}
                                                  onClick={() => {
                                                      if(props.setButtonActiveState) {
                                                          props.setButtonActiveState("true")
                                                      }
                                                      handleClick()}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<ButtonPrimary text={t("Terug")} icon={"arrow-left"} onClick={e => navigate("/home")}/>*/}

            {descriptionType === 'normal' && (
                <div className="description-editor">
                    {functionDetail?.descriptions?.map((item, idx) => {
                        return (
                            <div key={item.id} className="description-editor__item description-editor__item--read-only">
                                <div className={"description-editor__text"}>
                                    <h3 className={"description-editor__title"}>{item.title}</h3>
                                    <div className="description-editor__rich-text" key={item.title}>
                                        <div dangerouslySetInnerHTML={descriptionHtmlContent(item.content)}/>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {descriptionType === 'upload' && (
                <div className={"file-box"}>
                    <Upload
                        edit={props?.editState?.canEdit && !props?.editState?.description_immutable}
                        object_id={props.function_id}
                        files={functionDetail?.description_files}
                        mutate={() => true}
                    />
                </div>
            )}

            {functionDetail?.type === "match" && functionDetail?.ranking_motivation && (
                <FunctionSummary project_id={project_id}/>
            )}

        </>
    );
}

export default function FunctionSummary(props) {

    const auth = useAuth();
    let {function_id, project_id} = useParams();
    function_id = parseInt(function_id);

    // const functionDetail = props.functionDetail.data;
    const functionDetail = getFunction(function_id);
    const projectDetail = getProject(props.project_id ? props.project_id : project_id);

    const structure = getStructure(projectDetail?.data?.method.id, functionDetail?.data?.type);
    const structureCompany = getStructure(projectDetail?.data?.method.id, 'company');
    const getStructureStatus = (structure_id) => functionDetail?.data?.scores.find(obj => obj.structure_id === structure_id);

    const fgsDetail = useSWR(() => projectDetail?.data?.function_group_structure?.id ? `api/functiongroupstructures/${projectDetail?.data?.function_group_structure?.id}/` : false);
    const canChangeReference = auth.hasPermission('base.change_reference_function', functionDetail?.project?.id, 'project');


    if(projectDetail.isLoading || structure.isLoading || structureCompany.isLoading) {
        return <LoaderAnimation/>
    }
    // const structureContent = canChangeReference ? structure?.data : structureCompany?.data;
    const structureContent = structure?.data;

    return (
        <>

            {functionDetail?.data?.type === "reference" && (
                <FunctionGradingSummaryDisplay
                    functionDetail={functionDetail?.data}
                    fuwater={isFuwaterVbalans(projectDetail?.data?.method?.id)}
                    content={structureContent}
                    getStructureStatus={getStructureStatus}
                    fgsDetail={fgsDetail?.data}
                />
            )}
            {functionDetail?.data?.type === "company" && (
                <FunctionCompanySummaryDisplay
                    functionDetail={functionDetail?.data}
                    content={structureContent}
                    getStructureStatus={getStructureStatus}
                    fgsDetail={fgsDetail?.data}
                />
            )}
            {functionDetail?.data?.type === "match" && (
                <FunctionCompanyMatchDisplay
                    functionDetail={functionDetail?.data}
                    fgsDetail={fgsDetail?.data}
                />
            )}
        </>
    )
}
