import React, {useEffect, useState} from "react";
import ProjectMeta from "../ProjectMeta";
import {generatePath, useParams} from "react-router-dom";
import Quickview from "../Quickview";
import Overview from "../Overview";
import { UserOverviewDefinition } from "./UserOverview";
import { UserGroupOverviewDefinition } from "./UserGroupOverview";
import {useTranslation} from "react-i18next";
import MultiFieldModal from "../../patterns/organisms/MultiFieldModal";
import {remoteAddUsersToUserGroup, remoteRemoveUsersFromUserGroup, remoteSetUserGroup} from "../../queries/usergroups";
import {getProject} from "../../queries/project_detail";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {getProjectUsers, remoteAddUsersToProject, remoteRemoveUsersFromProject} from "../../queries/users";
import {getProjectUserGroups} from "../../queries/usergroups";
import {useAuth} from "../../utils/useAuth";
import Drawer from "../Drawer";
import {useSelector} from "react-redux";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import {withAccessRight} from "../../utils/withAccessHoC";
import {ProjectFileUploadBase} from "../Screens/ProjectFileUpload";
import i18n from "../../utils/i18n";

function ProjectUserOverviewBase(props) {

    const {t, i18n} = useTranslation();
    const { client_id, project_id } = useParams();
    const auth = useAuth();

    const {data: projectDetail, mutate} = getProject(project_id);

    const [selectUsers, setSelectUsers] = useState(false);
    const [removeUser, setRemoveUser] = useState(false);
    const overviewIdentifier = "select-user-overview";
    const identKey = {
        // client_id: client_id
    }
    const stateIdentifier = {
        identifier: overviewIdentifier,
        ...identKey,
    };
    const bulkState = useSelector(state => state.OverviewState?.[JSON.stringify(stateIdentifier)]?.bulk);


    if(!projectDetail) {
        return <LoaderAnimation/>;
    }

    const canChange = auth.checkAccessList('base.change_authuser',
        [
            [projectDetail?.id, 'project'],
            [projectDetail?.client?.id, 'client'],
        ]
    )

    const hasCreateUserPrimary =  canChange ? {
        title: t("Gebruikers toevoegen"),
        identifier: 'add-users',
        action: () => setSelectUsers(true)
    } : {};

    const hasCreateUserGroupPrimary = auth.hasPermission('base.change_usergroup', projectDetail?.id, 'project') ? {
        title: t("Gebruikersgroep aanmaken"),
        identifier: "usergroup-create-action",
        action: {
            Component: MultiFieldModal,
            remoteCall: o => {
                remoteSetUserGroup(project_id, o.name, o.description);
            },
            fields:[
                {
                    name: "name",
                    label: t("Gebruikersgroep"),
                    placeholder: t("Naam van de groep")
                },
                {
                    name: "description",
                    label: t("Beschrijving"),
                    placeholder: t("toelichting")
                }
            ]
        }
    } : {};

    return (
        <>
            <ProjectMeta/>
            {removeUser && (
                <ConfirmModal
                    key={'remove-users-from-project'}
                    title={t("Verwijder gebruiker uit project")}
                    body={t("Weet je zeker dat je de volgende gebruiker wilt verwijderen uit het project")}
                    subject={`${removeUser.first_name} ${removeUser?.insertion ? removeUser.insertion+" " : ""}${removeUser.last_name}` }
                    confirmModal={async () => {
                        await remoteRemoveUsersFromProject( {[removeUser.id]: true}, project_id);
                        mutate();
                    }}
                    closeModal={() => setRemoveUser(false)} />
            )}
            {selectUsers && (
                <>
                    <Drawer
                        identifier={"select-user"}
                        title={t("Selecteer gebruikers")}
                        readOnlyVal={obj => obj.name}
                        isForm={false}
                        noButton={true}
                        noReset={true}
                        initialOpen={true}

                        action={{
                            title: t("Toevoegen"),
                            Component: props => {
                                return (
                                    <a
                                        className={"button button--primary"}
                                        href={"#"} onClick={async e => {
                                        e.preventDefault();
                                        await remoteAddUsersToProject(bulkState, project_id);
                                        props.onClick();
                                    }}>{t("Geselecteerde gebruikers toevoegen")}</a>
                                )
                            }
                        }}

                        reset={() => {
                            // props.form.change('project_id', '');
                        }}

                        onClick={ async (obj) => {
                            // await remoteAddProject(project_id, obj.id);
                            // getProjectDetail.mutate();
                        }}

                        closeCallback={() => setSelectUsers(false) }

                        contentComponent={
                            <Overview
                                identifier={overviewIdentifier}
                                identKey={identKey}
                                contextData={{
                                    location,
                                }}
                                definition={{
                                    ...UserOverviewDefinition,
                                    primary: {},
                                    bulkEnabled: true,
                                    noHeader: true,
                                    noSearch: true,
                                    noContainer: true,
                                    columns: UserOverviewDefinition.columns.filter(o => o.identifier !== 'is_active'),
                                }}
                                initialFilters={{
                                    is_active: ["true"]
                                }}
                            />
                        }
                    />
                </>
            )}
            <div className={"content-container"}>

                <Quickview>
                    <Overview
                        identifier={"project-users"}
                        quickview={{
                            identifier: "project-users",
                            name: t("Externe gebruikers")
                        }}
                        identKey={{
                            // client_id,
                            project_id,
                        }}
                        contextData={{
                            location,
                            client_id,
                            project_id,
                            removeUser: async (props) => {
                                setRemoveUser(props);
                            }
                        }}
                        definition={{
                            ...UserOverviewDefinition,
                            title: t("Gebruikers van andere klanten"),
                            queryFn: getProjectUsers,
                            primary: hasCreateUserPrimary,
                            columns: UserOverviewDefinition.columns
                                .filter(c => (c.identifier !== 'action' || canChange) )
                                .filter(o=> o.identifier !== 'last_login'),
                        }}
                    />

                    {/*<Overview*/}
                    {/*    identifier={"project-usergroups"}*/}
                    {/*    quickview={{*/}
                    {/*        identifier: "project-usergroups",*/}
                    {/*        name: t("Externe gebruikersgroepen")*/}
                    {/*    }}*/}
                    {/*    identKey={{client_id, project_id}}*/}
                    {/*    contextData={{*/}
                    {/*        location,*/}
                    {/*        client_id,*/}
                    {/*        project_id,*/}
                    {/*    }}*/}
                    {/*    definition={{*/}
                    {/*        ...UserGroupOverviewDefinition,*/}
                    {/*        title: t("Extern gekoppelde gebruikersgroepen"),*/}
                    {/*        queryFn: getProjectUserGroups,*/}
                    {/*        primary: hasCreateUserGroupPrimary,*/}

                    {/*    }}*/}
                    {/*/>*/}
                </Quickview>
            </div>

        </>
    )
}

export const ProjectUserOverview =  withAccessRight(
    ProjectUserOverviewBase,
    "base.view_external_access",
    [
        ["project_id", "project"],
        ["client_id", "client"]
    ]
)

