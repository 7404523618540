import useSWR from "swr";
import axios from "axios";
import Noty from "noty";

export function getLinks() {
    const {data, error, mutate} = useSWR("/api/sitesettingslinks/");

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export async function createLink(values, t) {
    const result = await axios.post("/api/sitesettingslinks/", values)
        .then(result => {
            if (result.status === 201) {
                if (result.data?.id) {
                    return result
                } else {
                    throw new Error("server-error-link-create", {cause: result});
                }
            } else {
                throw new Error("server-error-link-create", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })

    return result
}

export async function updateLink(values, t) {
    await axios.patch(`/api/sitesettingslinks/${values.id}/`, values)
        .then(result => {
            if (result.status === 200) {
                if (result.data?.id) {
                    return result
                } else {
                    throw new Error("server-error-link-update", {cause: result});
                }
            } else {
                throw new Error("server-error-link-update", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}

export async function deleteLink(id, t) {
    return await axios.delete(`/api/sitesettingslinks/${id}/`).then(result => {
        if (result.status === 204) {
            new Noty({
                theme: 'bootstrap-v4',
                layout: 'bottomRight',
                text: t("De link is verwijderd!"),
                closeWith: ['click', 'button'],
                type: "success",
                timeout: 2000,
            }).show();
        } else {
            new Noty({
                theme: 'bootstrap-v4',
                layout: 'bottomRight',
                text: t("Er is iets verkeerd gegaan."),
                closeWith: ['click', 'button'],
                type: "error",
                timeout: 2000,
            }).show();
        }
    }).catch(function (error) {
        new Noty({
            theme: 'bootstrap-v4',
            layout: 'bottomRight',
            text: t("Er is iets verkeerd gegaan."),
            closeWith: ['click', 'button'],
            type: "error",
            timeout: 2000,
        }).show();
    });
}

export async function putLinks(values) {
    await axios.put(`/api/sitesettingslinks/bulk_replace/`, values)
        .then(result => {
            if (result.status === 201) {
                return result
            } else {
                throw new Error("server-error-link-update", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}
