import useSWR from "swr";
import axios from "axios";

export function getUser(pk = false) {

    let url = `/api/users/${pk}/`;

    const {data, error, mutate} = useSWR(() => pk ? url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export async function remoteRemoveUserRole(id) {
    await axios.delete(`/api/userroles/${id}/`).then(res => res);
}

export async function switchToUser(id) {
    const result = await axios.get(`/api/switch/?user=${id}`);
    return result;
}

export async function createUser(values, navigate) {
    await axios.post("/api/users/", values)
        .then(result => {
            if (result.status === 201) {
                if (result.data?.id) {
                    navigate('../' + result.data.id + '/roles');
                } else {
                    throw new Error("server-error-user-create", {cause: result});
                }
            } else {
                throw new Error("server-error-user-create", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}

export async function editUser(values, navigate, user_id) {
    let data = values;

    if (data?.email !== "") {
        data.email = data.email.replace(/\s+/g, '');
    }

    await axios.patch("/api/users/" + user_id + "/", data)
        .then(result => {
            if (result.status === 200) {
                navigate('../');
            } else {
                throw new Error("server-error-user-edit", {cause: result});
            }
        })
        .catch(error => {
            throw new Error(error.message ? error.message : "server-error", {cause: error.cause ? error.cause : error});
        })
}

export async function archiveUser(id) {
    return await axios.delete(`/api/users/${id}/`);
}
