import React from "react";
import cx from "classnames";
import {TextFilter} from "./Filters/TextFilter";
import {getReports} from "../../queries/reports";
import {useTranslation} from "react-i18next";
import {EmptyFilter} from "./Filters/EmptyFilter";
import Icon from "../../patterns/atoms/Icon";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {IconButton} from "../../patterns/molecules/IconButton";
import i18n from "../../utils/i18n";
import axios from "axios";
import ToolTip from "../ToolTip";

const StandardRowStructure = React.memo(props => {

    const {t, i18n} = useTranslation();

    const creation_date = props?.creation_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
    }).format(new Date(props.creation_date)) : "-";

    const modification_date = props?.modification_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric"
    }).format(new Date(props.modification_date)) : "-";

    const handleDownload = () => {
        axios.get(`/api/celerytasks/${props.id}/download/`, {
            responseType: "arraybuffer",
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data], {type: type, encoding: 'UTF-8'})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = props.filename
            link.click()
        })
    }


    return (
        <tr
            className={"overview-table__row"}
            onClick={() => {            // the onclick is passed along through the Drawer component
                // detailClick(props);
            }}
            key={props.id}
        >
            {props.columns.some(col => col.identifier === 'status') && (
                <td className="overview-table__cell overview-table__cell--center">
                    <div className={cx(
                        "overview-table__status-indicator",
                        {"overview-table__status-indicator--success": props.status === 'successful'},
                        {"overview-table__status-indicator--error": props.status === 'failed'}
                    )}>
                        {(props.status === 'pending' || props.status === 'processing') && (
                            <LoaderAnimation infinite={"true"} small={"true"}/>)}
                        {(props.status === 'successful') && (<Icon icon={"success"}/>)}
                        {(props.status === 'failed') && (<Icon icon={"error-filled"}/>)}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'filename') && (
                <td className={cx("overview-table__cell overview-table__cell--name", {"overview-table__cell--interactive": props?.filename && props?.status=== "successful"})}>
                    {props.filename ? (
                        <>
                            <div className="overview-table__text" title={props.filename} onClick={() => props?.file && handleDownload()}>
                                {props.filename}
                            </div>
                            {!props.columns.some(col => col.identifier === 'creation_date')  && (
                                <time className="reports-dropdown__date" dateTime={props.creation_date}>
                                    {new Intl.DateTimeFormat("nl-NL", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "numeric",
                                        hour: "numeric",
                                        minute: "numeric"
                                    }).format(new Date(props.creation_date))}
                                </time>
                            )}
                        </>
                    ) : (
                        <span className={"reports-dropdown__generating"}>{t("Bezig met genereren...")}</span>
                    )}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'project__client__name') && (
                <td className="overview-table__cell">
                    {props?.client?.name}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'project__name') && (
                <td className="overview-table__cell">
                    {props?.project?.name}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'user__first_name') && (
                <td className="overview-table__cell">
                    {props?.user?.get_full_name}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'label') && (
                <td className="overview-table__cell">
                    {t(props?.label)}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'creation_date') && (
                <td className="overview-table__cell">
                    {creation_date}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'modification_date') && (
                <td className="overview-table__cell">
                    {modification_date}
                </td>
            )}

            {props.columns.some(col => col.identifier === 'file') && (
                <td className={cx("overview-table__cell", {"overview-table__cell--interactive": props?.file})}>
                    {props.file ? (
                        <div className={"overview-table__status-indicator overview-table__status-indicator--action"}>
                            <Icon icon={cx("download")} sizeModifier={"small"} onClick={() => handleDownload()}/>
                        </div>
                    ) : (
                        <div className={"overview-table__status-indicator overview-table__status-indicator--error"}>
                            <ToolTip tooltip={t("Een probleem is opgetreden bij het verwerken van deze rapportage. Probeer het nogmaals of neem contact op.")}>
                                <Icon icon={"error-filled"}/>
                            </ToolTip>
                        </div>
                    )}
                </td>
            )}


            {props.columns.some(col => col.identifier === 'status_file') && (
                <td className={cx("overview-table__cell", {"overview-table__cell--interactive": props?.file})}>
                    <div className={cx(
                        "overview-table__status-indicator",
                        {"overview-table__status-indicator--success": props.status === 'successful' && props?.file},
                        {"overview-table__status-indicator--error": props.status === 'failed' || (props.status === 'successful' && !props?.file)}
                    )}>
                        {(props.status === 'pending' || props.status === 'processing') && (
                            <LoaderAnimation infinite={"true"} small={"true"}/>
                        )}
                        {(props.status === 'failed') && (
                            <Icon icon={"error-filled"}/>
                        )}
                        {(props.status === 'successful' && props?.file) && (
                            <div className={"overview-table__status-indicator overview-table__status-indicator--action"}>
                                <IconButton icon={cx("download")} border={"true"} sizeModifier={"medium"} onClick={() => handleDownload()}/>
                            </div>
                        )}
                        {props.status === 'successful' && !props?.file && (
                            <ToolTip tooltip={t("Een probleem is opgetreden bij het verwerken van deze rapportage. Probeer het nogmaals of neem contact op.")}>
                                <Icon icon={"error-filled"}/>
                            </ToolTip>
                        )}
                    </div>
                </td>
            )}
        </tr>
    )

});

export const ReportsOverviewDefinition = {

    title: i18n.t("Rapportages"),
    identifier: 'reports',
    queryFn: getReports,
    RowStructure: StandardRowStructure,
    loadAllAllowed: true,
    defaultOrderIdentifier: '-modification_date',
    columns: [

        {
            title: i18n.t('Bestandsnaam'),
            identifier: 'filename',
            orderable: true,
            className: 'overview-table__filter-cell--width-name',
            placeholder: i18n.t('Naam'),
            Filter: TextFilter,
        },
        // {
        //     title: i18n.t('Status'),
        //     identifier: 'status',
        //     orderable: false,
        //     className: 'overview-table__filter-cell--width-small',
        //     placeholder: i18n.t('tekst'),
        //     Filter: EmptyFilter,
        // },
        // {
        //     title: i18n.t('Gewijzigd op'),
        //     identifier: 'modification_date',
        //     orderable: true,
        //     className: 'overview-table__filter-cell--width-medium',
        //     placeholder: i18n.t('datum'),
        //     Filter: EmptyFilter,
        // },
        {
            title: i18n.t('Klant'),
            identifier: 'project__client__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t('Naam'),
            Filter: TextFilter,
        },
        {
            title: i18n.t('Project'),
            identifier: 'project__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t('Naam'),
            Filter: TextFilter,
        },
        // {
        //     title: i18n.t('Gebruiker'),
        //     identifier: 'user__first_name',
        //     orderable: true,
        //     className: 'overview-table__filter-cell--width-large',
        //     placeholder: i18n.t('tekst'),
        //     Filter: TextFilter,
        // },
        // {
        //     title: i18n.t('Type'),
        //     identifier: 'label',
        //     orderable: true,
        //     className: 'overview-table__filter-cell--width-large',
        //     placeholder: i18n.t('tekst'),
        //     Filter: TextFilter,
        // },
        {
            title: i18n.t('Aangemaakt op'),
            identifier: 'creation_date',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t('datum'),
            Filter: EmptyFilter,
        },
        {
            title: i18n.t('Download'),
            identifier: 'status_file',
            orderable: false,
            className: 'overview-table__filter-cell--width-small',
            placeholder: i18n.t('tekst'),
            Filter: EmptyFilter,
        },
    ]
}

export const ReportsOverviewDropdownDefinition = {
    title: i18n.t("Rapportages"),
    identifier: 'reports-dropdown',
    queryFn: getReports,
    RowStructure: StandardRowStructure,
    loadAllAllowed: true,
    defaultOrderIdentifier: '-modification_date',
    paginationSize: 5,
    columns: [
        {
            // title: i18n.t(''),
            identifier: 'filename',
            orderable: true,
            className: 'overview-table__filter-cell--width-name',
            // placeholder: i18n.t('tekst'),
            // Filter: TextFilter,
        },
        {
            // title: i18n.t(''),
            identifier: 'status_file',
            orderable: false,
            className: 'overview-table__filter-cell--width-icon-button',
            // placeholder: i18n.t('tekst'),
            // Filter: EmptyFilter,
        },
    ]
}
