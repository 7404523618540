import React from "react";
import {DropdownFilter} from "../../../patterns/molecules/DropdownFilter";
import Pip from "../../../patterns/atoms/Pip";
import {useTranslation} from "react-i18next";
import {functionStatus, projectStatus} from "../../../utils/constants";
import {useAuth} from "../../../utils/useAuth";
import {useParams} from "react-router-dom";

export function FunctionStatusFilter(props) {

    const auth = useAuth();
    let { project_id } = useParams();
    const canArchive = auth.hasPermission('base.can_archive_function', project_id, 'project');

    const {t, i18n} = useTranslation();

    let options = Object.entries(functionStatus).filter(o=> o[0] !== "archived" || canArchive).map( (item,idx) => {
        const [key, content] = item;
        return {
            title: <><Pip className="status-pip--with-text" type={key}/>{t(content)}</>,
            identifier: key
        }
    })

    return (
        <div className="overview-table__status">
            <DropdownFilter
                {...props}
                identifier={'description_status__in'}
                options={options}
            />
            <DropdownFilter
                {...props}
                identifier={'scoring_status__in'}
                options={options}
            />
        </div>
    )
}

export function ProjectStatusFilter(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const canArchive = auth.hasPermission('base.can_archive_function');

    let options = Object.entries(projectStatus).filter(o=> o[0] !== "archived" || canArchive).map( (item,idx) => {
        const [key, content] = item;
        return {
            title: <><Pip className="status-pip--with-text" type={key}/>{t(content)}</>,
            identifier: key
        }
    })

    return (
        <div className="overview-table__status">
            <DropdownFilter
                {...props}
                identifier={'status__in'}
                options={options}
            />
        </div>
    )
}
