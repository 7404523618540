import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import IconButton from "../patterns/molecules/IconButton";
import ButtonInverted from "../patterns/atoms/ButtonInverted";
import cx from "classnames";
import {Icon} from "../patterns/atoms/Icon";
import {InfoButton} from "../patterns/molecules/InfoButton";
import {Link} from "react-router-dom";
import ButtonSecondary from "../patterns/atoms/ButtonSecondary";
import ButtonTertiary from "../patterns/atoms/ButtonTertiary";

/*
    Annotated example usage can be found in organisms/FunctionCreate.jsx
 */


export default function Drawer(props) {

    const [active, setActive] = useState(false);             // an activated drawer means it is present in the HTML (but could be 'closed')
    const [open, setOpen] = useState(false);                 // an 'open' drawer means it is slided into view (closed is not)
    const [readOnlyVal, setReadOnlyVal] = useState(false);   // value to be used for the faux input
    const {t, i18n} = useTranslation();
    const autoClose = props?.autoClose !== false;                       // should the drawer automatically close on selection
    const scrollRef = useRef(null);

    // check if the drawer is disabled. This is used within forms, but is forced to false when it is not a form
    const disabled = props?.isForm === true ? (props?.disabled === true ? true : props?.disabled !== false) : false;


    if (props?.disabled !== false && props?.disabled !== true && props?.isForm) {
        console.log("Warning: Drawer \"" + props?.label + ":" + (props?.title || props?.id) + "\" is neither disabled nor enabled, but isForm is enabled!")
    }

    const type = props?.type && ["drawer", "side-drawer"].includes(props?.type) ? props.type : "drawer";

    useEffect(() => {
        setReadOnlyVal("")
    }, [props.resetAutocomplete])

    useEffect(() => {
        if ((readOnlyVal === false || readOnlyVal === "") && (props?.initialReadOnlyVal || props?.initialReadOnlyVal === "")) {
            setReadOnlyVal(props?.initialReadOnlyVal);
        }
    }, [props?.initialReadOnlyVal])


    // "Just close" (Closes the drawer without setting a result)
    const close = () => {
        setOpen(false);
        setTimeout(() => {
            setActive(false);
            if (props?.closeCallback) {
                props?.closeCallback();
            }
        }, 300);
    }

    // default onClick to be passed to a nested component.
    let _onClick = () => console.log("No click registered");

    if (props?.onClick) {

        if (autoClose) {
            // enrich the passed onClick with a close (as we want to close after a selection)
            _onClick = function (...args) {
                props.onClick(...args);
                if (props?.isForm && props?.readOnlyVal) {
                    setReadOnlyVal(props.readOnlyVal(...args));
                }
                if (props?.id === "user__in") {
                    setReadOnlyVal("")
                }
                close();
            }
        } else {
            _onClick = props.onClick;
        }
    }

    useEffect(() => {
        if (props?.initialOpen) {
            setTimeout(() => {
                setOpen(true);
                setActive(true);
            }, 300);
        }
    }, [])

    const buttons = (
        <>
            {!props?.noButton && (
                <button
                    className={cx(props?.className, "button", "button--tertiary")}
                    onClick={() => {
                        setActive(true);
                        setOpen(true);
                    }}
                >
                    {props?.buttonText || t("Zoek")}
                </button>
            )}

            {!props?.noReset && !disabled && (
                <button
                    className={"button button--secondary"}
                    disabled={props?.resetDisabled || false}
                    onClick={() => {
                        props.reset();
                    }}>
                    {props?.resetText || t("Wis selectie")}
                </button>
            )}

            {props?.advancedFilter && !disabled && (
                <>
                    <ButtonTertiary
                        text={t("Filteren")}
                        icon={"filter-sliders"}
                        className={cx(props?.className)}
                        onClick={() => {
                            setActive(true);
                            setOpen(true);
                        }}
                    />
                    <ButtonSecondary
                        text={t("Filters leegmaken")}
                        icon={"trash-alt"}
                        disabled={props?.resetDisabled || false}
                        onClick={() => {
                            props.reset();
                        }}
                    />
                </>
            )}
        </>
    )

    function handleKey(event) {
        if (event.code === "Enter" || event.code === "Space") {
            setOpen(true);
            setActive(true);
        }
    }

    const button_container = props?.isForm ? (
        <>
            <div className={"input-group__label-container"}>
                {props?.label && (
                    <label className={"input-group__label"} htmlFor={props?.id}>{props?.label}</label>
                )}
                {props?.required && (
                    <span className={"input-group__required"}>{t("Verplicht")}</span>
                )}
            </div>
            {props.helpText &&
                <p className={"input-group__help-text"}>{props.helpText}</p>
            }
            {props.error && (
                <div className={"input-group__error-message"}>
                    <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}
                          data-cy={props["data-cy"] ? props["data-cy"] + "-error-icon" : props.id + "-error-icon"}/>
                    <p data-cy={props["data-cy"] ? props["data-cy"] + "-error-message" : props.id + "-error-message"}>{props.error.message}</p>
                </div>
            )}
            <div className={cx("input-group__input", {"input-group__input--error": props.error})}>
                <input readOnly={true}
                       className={"drawer-input"}
                       id={props?.id}
                       data-cy={props?.id}
                       placeholder={props?.placeholder || t("Zoeken...")}
                       value={readOnlyVal || ""}
                       onKeyDown={handleKey}
                       onClick={() => {
                           if (!disabled) {
                               setActive(true);
                               setOpen(true);
                           }
                       }}/>
                {!disabled && !readOnlyVal && (
                    <Icon icon={"drawer-open"} sizeModifier={"small"} className={"drawer-input__icon"}/>
                )}
                {readOnlyVal && !disabled && (
                    <button
                        title={t("Wis selectie")}
                        data-cy={"drawer-reset-button"}
                        className={"drawer-input__reset"}
                        onClick={() => {
                            setReadOnlyVal("");
                            props.reset();
                        }}
                    >
                        <Icon icon={"close"} sizeModifier={"small"}/>
                    </button>
                )}
            </div>


        </>
    ) : (
        <>
            {buttons}
        </>
    );

    let action = null;

    if (props?.action && props?.action?.title) {
        if (props.action?.Component) {
            const Component = props.action.Component;
            action = <Component
                {...props.action}
                onClick={_onClick}
            />
        }
        if (props.action?.link) {
            action = <Link className={"button button--primary"}
                           to={(props.action.link instanceof Function && props.action.link(props)) || props.action.link}>
                {props.action.title}
            </Link>
        }
    }


    return (
        <>
            {button_container}

            {active && (
                <Modal
                    isOpen={active}
                    className={cx(type, {
                        [type + "--open"]: open,
                        [type + "--close"]: !open,
                    })}
                    onRequestClose={() => close()}
                    overlayClassName={cx("modal-background", {"modal-background--side-drawer": type === 'side-drawer'})}
                    bodyOpenClassName={"has-" + type}>
                    <div className={"drawer__inner"}>
                        <ButtonInverted className={type + "__button-close"} icon={"close"} text={t("Sluiten")}
                                        onClick={() => close()}/>
                        <div className={"modal-header"}>
                            <div className={"modal-header__top"}>
                                {props?.title && (
                                    <h2 className={"modal-header__title"}>{props.title}</h2>
                                )}
                                <div className="modal-header__action">
                                    {action}
                                </div>
                            </div>
                        </div>
                        <div className={type + "__body"} ref={scrollRef}>
                            {props?.contentComponent ?
                                React.cloneElement(props.contentComponent, {
                                    onClick: _onClick,
                                    isDrawer: true,
                                    scrollInsideRef: scrollRef,
                                    close: close
                                }) : props.children}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )

}
