import React from "react";
import {DropdownFilter} from "../../../patterns/molecules/DropdownFilter";

export default function TypeFilter(props) {
    return (
        <DropdownFilter
            {...props}
            identifier={'type__in'}
        />
    )
}
