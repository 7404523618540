import React from "react";
import {useTranslation} from "react-i18next";
import {getFunction} from "../queries/function_detail";
import ByNameDate from "../patterns/molecules/ByNameDate";
import Pip from "../patterns/atoms/Pip";
import {functionStatus} from "../utils/constants";

export default function StatusPipTooltip(props) {
    const {t} = useTranslation();
    const getFunctionDetail = getFunction(props.functionId)?.data;

    return (
        <div className={"status-pip-tooltip"}>
            <div className={"status-pip-tooltip__column"}>
                <div className={"status-pip-tooltip__item"}>
                    <h5 className={"status-pip-tooltip__heading"}>{t("Beschrijving")}</h5>
                    <div className={"status-pip-tooltip__status"}>
                        <Pip type={getFunctionDetail?.description_status} full={false} hideTooltip={true}/>
                        {functionStatus[getFunctionDetail?.description_status]}
                    </div>
                    <ByNameDate
                        get_full_name={getFunctionDetail?.description_status_modified_by?.get_full_name || false}
                        first_name={getFunctionDetail?.description_status_modified_by?.first_name}
                        last_name={getFunctionDetail?.description_status_modified_by?.last_name}
                        date={getFunctionDetail?.description_status_modification_date}
                    />
                </div>
            </div>
            <div className={"status-pip-tooltip__column"}>
                <div className={"status-pip-tooltip__item"}>
                    <h5 className={"status-pip-tooltip__heading"}>{t("Indeling")}</h5>
                    <div className={"status-pip-tooltip__status"}>
                        <Pip type={getFunctionDetail?.scoring_status} full={false} hideTooltip={true}/>
                        {functionStatus[getFunctionDetail?.scoring_status]}
                    </div>
                    <ByNameDate
                        get_full_name={getFunctionDetail?.scoring_status_modified_by?.get_full_name || false}
                        first_name={getFunctionDetail?.scoring_status_modified_by?.first_name}
                        last_name={getFunctionDetail?.scoring_status_modified_by?.last_name}
                        date={getFunctionDetail?.scoring_status_modification_date}
                    />
                </div>
            </div>
        </div>
    )
}
