import Pip from "../atoms/Pip";
import {functionStatus} from "../../utils/constants";
import ByNameDate from "../molecules/ByNameDate";
import React from "react";
import {useTranslation} from "react-i18next";
import {StatusSelector} from "./FunctionStatusNavigation";

export function StatusByNameDate(props) {

    const {t, i18n} = useTranslation();

    const description = (<>{t(functionStatus[props?.status])  || t("Onbekend")}</>);
    const title = props?.title ? props.title : description;

    const status = {
        identifier:  props.identifier,
        label: props.title,
        type: props.type,
    }


    return (
        <>
            <div className={"status-pip-container"} data-cy={props?.["data-cy"]}>
                <StatusSelector
                    canEdit={props?.canEdit}
                    status={status}
                    data-cy={props?.["data-cy"]}/>

                {/*<Pip className="status-pip--with-text"*/}
                {/*     type={props?.identifier}*/}
                {/*/>*/}
                {/*{title}*/}
            </div>
            <ByNameDate
                get_full_name={props?.get_full_name || false}
                first_name={props?.first_name}
                last_name={props?.last_name}
                date={props.date}
            />
        </>
    )
}
