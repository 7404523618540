import React from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Select from "../atoms/Select";
import {withAccessRight} from "../../utils/withAccessHoC";
import Notification from "../molecules/Notification";
import {Controller, useForm} from "react-hook-form";
import {getBranches} from "../../queries/branch_detail";
import {getClientOptions} from "../../queries/client_detail";
import {useNavigate} from "react-router-dom";
import {createClient} from "../../queries/client";
import {Input} from "../atoms/Input";
import {language_options} from "../../utils/constants";
import Dropzone from "react-dropzone";
import cx from "classnames";
import {Icon} from "../atoms/Icon";
import axios from "axios";
import ButtonTertiary from "../atoms/ButtonTertiary";

function ClientCreateDisplay() {
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        control,
        watch,
        getValues,
        setValue,
        formState: {errors}
    } = useForm();

    const navigate = useNavigate();
    const branches = getBranches().data?.results;
    const size_options = getClientOptions()?.data?.actions?.POST.size.choices;

    const onSubmit = () => async (values) => {

        await createClient(values, navigate).then(async (result) => {
            if(getValues("logo")) {
                let formData = new FormData();
                formData.append('logo', getValues("logo")[0]);

                await axios.put(`/api/clients/${result.data.id}/set_logo/`, formData, {
                    'content-type': 'multipart/form-data'
                })
            }

            navigate('../' + result.data.id);
        });
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Klant aanmaken")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server", "unique"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if(error.cause.message === "server-error-client-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }

                    if(error.cause.message === "unique-error-client-create") {
                        setError("unique", {
                            type: "unique",
                            message: t("Er is iets verkeerd gegaan. Mogelijk bestaat er al een klant met deze naam!")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Naam")}
                            placeholder={t("Klantnaam")}
                            data-cy={"client-name-input"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"branch_id"}
                            type={'select'}
                            options={branches}
                            id={"branch_id"}
                            label={t("Branche")}
                            placeholder={t("Kies een branche")}
                            register={register}
                            error={errors.branch_id}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"size"}
                            type={'select'}
                            options={size_options}
                            id={"size"}
                            label={t("Aantal FTE")}
                            placeholder={t("Kies het aantal FTE")}
                            register={register}
                            error={errors.size}
                        />
                    </div>
                    <div className="input-group">
                        <Select
                            name={"language"}
                            type={'select'}
                            options={language_options}
                            id={"language"}
                            label={t("Taal")}
                            register={register}
                        />
                    </div>
                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Contactgegevens")}</legend>
                    <div className="input-group">
                        <Input
                            name={"contact_name"}
                            type={"text"}
                            id={"contact_name"}
                            label={t("Naam contactpersoon")}
                            placeholder={t("Naam contactpersoon")}
                            data-cy={"client-contact-name-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"contact_email"}
                            type={"text"}
                            id={"contact_email"}
                            label={t("Emailadres contactpersoon")}
                            placeholder={t("Emailadres")}
                            data-cy={"client-contact-email-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"contact_telephone"}
                            type={"text"}
                            id={"contact_telephone"}
                            label={t("Telefoonnummer contactpersoon")}
                            placeholder={t("012-3456789")}
                            data-cy={"client-contact-telephone-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"address"}
                            type={"text"}
                            id={"address"}
                            label={t("Adres")}
                            placeholder={t("Straatnaam 12")}
                            data-cy={"client-address-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"zip_code"}
                            type={"text"}
                            id={"zip_code"}
                            label={t("Postcode")}
                            placeholder={t("1234 AA")}
                            data-cy={"client-zip-code-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"city"}
                            type={"text"}
                            id={"city"}
                            label={t("Stad")}
                            placeholder={t("Amsterdam")}
                            data-cy={"client-city-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"telephone_number"}
                            type={"text"}
                            id={"telephone_number"}
                            label={t("Telefoonnummer")}
                            placeholder={t("012-3456789")}
                            data-cy={"client-telephone-number-input"}
                            register={register}
                        />
                    </div>

                    {watch("logo") &&
                        <div className={"input-group"}>
                            <div className={"input-group__label-container"}>
                                <span className={"input-group__label"}>{t("Logo")}</span>
                            </div>
                            <div className={"client-logo"}>
                                <div className={"client-logo__img-container"}>
                                    <img className={"client-logo__img"}
                                         src={URL.createObjectURL(getValues('logo')[0])}/>
                                </div>
                                <button className={"client-logo__remove"}
                                        type={"button"}
                                        onClick={() => {
                                            setValue("logo", null)
                                        }}
                                >
                                    <Icon icon={"close"} sizeModifier={"xsmall"}/>
                                </button>
                            </div>
                        </div>
                    }

                    {!watch("logo") &&
                        <div className={"input-group"}>
                            <div className={"input-group__label-container"}>
                                <span className={"input-group__label"}>{t("Logo")}</span>
                            </div>
                            <p className={"input-group__help-text"}>{t("De maximale dimensie van het bestand mag uiterlijk 1500x1500 zijn. De toegestaande bestandtypes zijn jpeg, .jpg of .png.")}</p>
                            <Controller name={"logo"} control={control}
                                        render={({field: {onChange}}) => (
                                            <Dropzone multiple={false} maxFiles={1}
                                                      accept={['image/jpeg', 'image/jpg', 'image/png']}
                                                      onDrop={acceptedFiles => onChange(acceptedFiles)}>
                                                {({getRootProps, getInputProps, isDragActive}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <div
                                                                className={cx("file-upload", {"file-upload--dragging": isDragActive})}>
                                                                <input {...getInputProps()} />
                                                                <Icon
                                                                    className={"file-upload__icon"}
                                                                    icon={cx({
                                                                        "success": isDragActive,
                                                                        "file": !isDragActive
                                                                    })} sizeModifier={"small"}/>
                                                                <p className={"file-upload__description"}>
                                                                    {
                                                                        isDragActive ? (
                                                                            <>
                                                                                {t("U kunt het bestand loslaten")}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {t("Sleep uw bestand hier naartoe of klik om te zoeken")}
                                                                            </>
                                                                        )
                                                                    }
                                                                </p>
                                                                <ButtonTertiary fullWidth={"true"}
                                                                                type={"button"}>{t("Bestand zoeken")}</ButtonTertiary>
                                                            </div>
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        )}
                            />
                        </div>
                    }

                    {errors.unique && <Notification type={"error"}
                                                    text={errors.unique.message}
                                                    data-cy={"error-unique"}
                                                    className={"form-container__error"}/>}
                    {errors.server && <Notification type={"error"}
                                                    text={errors.server.message}
                                                    className={"form-container__error"}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Aanmaken")}
                        fullWidth={true}
                        data-cy={"client-create-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const ClientCreate = withAccessRight(
    ClientCreateDisplay,
    'base.change_client',
)
