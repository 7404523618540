import React from "react";
import {useAuth} from "./useAuth";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {AccessDenied} from "../patterns/organisms/AccessDenied";
import {useParams} from "react-router-dom";


// const rot = s => s.replace(/[a-zA-Z]/g,function(c){return String.fromCharCode((c<="Z"?90:122)>=(c=c.charCodeAt(0)+13)?c:c-26);});
const rot = s => s;

const checkAccessRight = (props) => {
    console.log("Obsolete call, call auth.checkAccessRight instead");
    const auth = useAuth();
    return auth.checkAccessRight(props);
}


export function WithAccessRight(props) {

    const auth = useAuth();
    const canAccess = auth.checkAccessRight(props);

    console.log("checking right:", props.right, props?.object_id, props?.type || 'project', "-->", canAccess)
    if(canAccess === null) return <LoaderAnimation/>;
    if(canAccess === false) return <AccessDenied additionalText={` (code: ${rot(props?.right)}:${rot(props?.object_id)}:${rot(props?.type)} )`}/>;

    return (
        <>
            {props.children}
        </>
    )
}

export function withGlobalAccessRight(Wrapped, right) {

    return function(props) {

        const auth = useAuth();
        const canAccess = auth?.hasPermission(right);

        console.log("checking global right:", right, "-->", canAccess);
        if(canAccess === null) return <LoaderAnimation/>;
        if(canAccess === false) return <AccessDenied additionalText={` (code: ${rot(right)})`}/>;

        return (
            <Wrapped {...props} />
        )
    }
}

export function checkAccessList(right, list) {

    const auth = useAuth();
    console.log("Obsolete call, call auth.checkAccessList instead");
    return auth.checkAccessList(right, list);
}


export function withAccessRight(Wrapped, right, param, type) {

    return function(props) {

        const auth = useAuth();
        let canAccess = false;

        if(Array.isArray(param) && param.length > 0) {
            // List based access
            canAccess = auth.checkAccessList(right, param);

        } else {

            // Fallback to previous implementation
            console.log("Fallback check right:", right, param, props?.type || 'project', "-->", canAccess)
            canAccess = auth.checkAccessRight({right: right, param: param, type: type});

        }

        if(canAccess === null) return <LoaderAnimation/>;
        if(canAccess === false) return <AccessDenied additionalText={` (code: ${rot(right)}:${rot(param)}:${rot(type)} )`}/>;

        return (
            <Wrapped {...props}/>
        )
    }
}
