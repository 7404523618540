import React, {useState, Fragment, useEffect} from "react";
import {singlePopOver, useState as usePopoverState} from "../../components/PopOverHoC";
import GroupPatternScore from "../molecules/GroupPatternScore";
import IconButton from "../molecules/IconButton";
import cx from "classnames";
import Icon from "../atoms/Icon";
import {IconPopoverButton, InfoButton} from "../molecules/InfoButton";
import {FGSItem} from "./ProjectMeta";
import {useTranslation} from "react-i18next";
import {FunctionSource, functionStatus, isFuwaterVbalans} from "../../utils/constants";
import Pip from "../atoms/Pip";
import {
    getFunction,
    remoteCreateNewVersion,
    remoteExpireFunction,
    archiveFunction
} from "../../queries/function_detail";
import LoaderAnimation from "../atoms/LoaderAnimation";
import useSWR from "swr";
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import Modal from "react-modal";
import ButtonPrimary from "../atoms/ButtonPrimary";
import ButtonSecondary from "../atoms/ButtonSecondary";
import ConfirmModal from "./ConfirmModal";
import {getProject} from "../../queries/project_detail";
import FunctionTypeIndicator from "../atoms/FunctionTypeIndicator";
import {StatusByNameDate} from "./StatusByNameDate";
import {useAuth} from "../../utils/useAuth";
import ToolTip from "../../components/ToolTip";

export function NewVersionModal(props) {

    const {t, i18n} = useTranslation();
    const [expireOldVersion, setExpireOldVersion] = useState(true);

    const save = async () => {

        props.newVersionOf(
            props.functionId,
            'concept',
            expireOldVersion
        );
    }

    return (
        <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"}
               bodyOpenClassName={"has-modal"}>
            <div className={"modal-header"}>
                <div className={"modal-header__top"}>
                    <h2 className={"modal-header__title"}>{t("Nieuwe versie maken")}</h2>
                    <ul className={"modal-header__controls"}>
                        <li className={"modal-header__control"}>
                            <IconButton icon={"close"} onClick={() => props.closeModal()}/>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"modal__body"}>
                <p className="paragraph">
                    {t("Weet je zeker dat je een nieuwe versie wilt maken van onderstaande functie?")}
                </p>
                <p className={"paragraph"}>
                    <strong>{props?.subject}</strong>
                    <span className="version version--padded version--no-margin">V{props?.version_number || 1}</span>
                </p>
            </div>
            <div className="modal-footer">
                <ul className={"modal__buttons"}>
                    <li className={"modal__button"}>
                        <ButtonSecondary
                            onClick={() => props.closeModal()}>
                            {t("Annuleren")}
                        </ButtonSecondary>
                    </li>
                    <li className={"modal__button"}>
                        <ButtonPrimary
                            onClick={() => {
                                save();
                            }}>
                            {t("Nieuwe versie aanmaken")}
                        </ButtonPrimary>
                    </li>
                </ul>
            </div>
        </Modal>
    )
}

export const FunctionMetaPatternState = {
    project: {
        name: "Jeugdzorg Lindenhout 2019"
    },
    functionDetail: {
        name: "Administratief Medewerker A",
        points: 256,
        function_group: 4,
        pattern_list: [
            {score: 'H', points: 123},
            {score: 'F', points: 123},
            {score: 'F', points: 123},
            {score: 'X', points: 123},
            {score: 'E', points: 123},
            {score: 'A', points: 123},
            {score: 'H', points: 123},
            {score: 'G', points: 123},
            {score: 'G', points: 123},
        ],
    }
}

export function AlternativeVersionsDisplay(props) {

    if(!props?.function_id) {
        return null;
    }
    const {t, i18n} = useTranslation();
    const full_identifier = 'alt-versions';

    let navigate = useNavigate();
    const functionDetail = getFunction(props.function_id);

    const versions = useSWR(() => props?.function_id ? `api/functions/${props?.function_id}/versions/?page_size=1000&ordering=-creation_date` : false);

    if(!functionDetail?.data || !versions?.data) {
        return <LoaderAnimation/>;
    }

    const expireVersion = async (id) => {
        await remoteescriptionTemplate(id);
        mutate();
    }

    return (
        <>
            <InfoButton
                icon={"info"}
                popOverClass={"dropdown"}
                popOverClassActive={"dropdown dropdown--active"}
                border={"true"}
                clickContent={<ButtonSecondary icon={"info"}
                                               className={"dropdown-toggle"}>{t("Versie-informatie")}</ButtonSecondary>}
            >
                {versions.data.results.length <= 0 ? (
                    <div className={"dropdown-empty"}>
                        {t("Geen andere versies")}
                    </div>

                ) : (
                    <ol>
                        {versions.data.results.map(version => {
                            const modification_date = version.modification_date ? new Intl.DateTimeFormat("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            }).format(new Date(version.modification_date)) : "";
                            return (
                                <li className={cx("dropdown-field", {
                                    "dropdown-field--disabled": version.id === props.function_id
                                })} key={version.id} onClick={
                                    () => navigate(generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                                        client_id: props.client_id,
                                        project_id: props.project_id,
                                        function_id: version.id
                                    }))
                                }>
                                    <div className={"dropdown-field__label"}>
                                            <span className={cx("version", "version--padded", {
                                                "version--accent": version.id === props.function_id
                                            })}>V{version.version_number}</span>&nbsp;{t("Aangemaakt op")} {modification_date}
                                    </div>
                                </li>
                            )

                        })}
                    </ol>
                )}
            </InfoButton>
        </>

    )
}

export const AlternativeVersions = singlePopOver(AlternativeVersionsDisplay);


export function FunctionMetaInfo(props) {

    const {t, i18n} = useTranslation();
    const {client_id, project_id} = useParams();

    const {data: versionOf} = useSWR(props.functionDetail?.version_of?.id ? `/api/functions/${props.functionDetail.version_of.id}/` : null);
    const {data: versionOfRoot} = useSWR(props.functionDetail?.version_of_root?.id ? `/api/functions/${props.functionDetail.version_of_root.id}/` : null);

    const basedOn = props.functionDetail?.based_on || versionOfRoot?.based_on || false;

    const auth = useAuth();
    const canViewSourceProject = auth.checkAccessList('base.view_project', [
        [props.functionProjectDetail?.id, 'project'],
        [props.functionProjectDetail?.client?.id, 'client'],
    ])

    return (
        <dl className={cx("meta-grid", {"meta-grid--wide": props.wide})}>
            <div className={"meta-grid__column"}>
                <dt className={"meta-grid__key"}>{t("Vorige versie")}</dt>
                <dd className={"meta-grid__value"}>
                    {versionOf ? (
                        <Link
                            to={generatePath("/client/:client_id/project/:project_id/function/:function_id/description/", {
                                client_id: client_id,
                                project_id: project_id,
                                function_id: versionOf.id,
                            })}>{versionOf.name} (v{versionOf.version_number})</Link>
                    ) : (
                        "-"
                    )}
                </dd>

                <dt className={"meta-grid__key"}>{t("Gebaseerd op")}</dt>
                <dd className={"meta-grid__value"} data-cy={"meta-value-based-on"}>
                    {basedOn ? (
                        <Link
                            to={generatePath("/client/:client_id/project/:project_id/function/:function_id/description/", {
                                client_id: client_id,
                                project_id: project_id,
                                function_id: basedOn.id,
                            })}>{basedOn.name} (v{basedOn.version_number})</Link>

                    ) : (
                        "-"
                    )}
                </dd>

                <dt className={"meta-grid__key"}>{t("Project")}</dt>
                <dd className={"meta-grid__value"} data-cy={"meta-value-project"}>
                    {canViewSourceProject ?
                        <Link to={generatePath("/client/:client_id/project/:project_id/function", {
                            client_id: props?.functionProjectDetail?.client?.id || 0,
                            project_id: props?.functionProjectDetail?.id || 0,
                        })}>
                            {props.functionProjectDetail?.name || "-"}
                        </Link> : props.functionProjectDetail?.name || "-"}
                </dd>
            </div>

            <div className={"meta-grid__column"}>
                <dt className={"meta-grid__key"}>{t("Beschrijver")}</dt>
                {props.functionDetail?.describer_group ? (
                    <dd className={"meta-grid__value"}
                        data-cy={"meta-value-describer"}>{props.functionDetail?.describer_group?.name} </dd>
                ) : (
                    <dd className={"meta-grid__value"}
                        data-cy={"meta-value-describer"}>{props.functionDetail?.describer?.get_full_name || "-"}</dd>
                )}

                <dt className={"meta-grid__key"}>{t("Toepasser")}</dt>
                {props.functionDetail?.applier_one_group ? (
                    <dd className={"meta-grid__value"}
                        data-cy={"meta-value-applier-one"}>{props.functionDetail?.applier_one_group?.name} </dd>
                ) : (
                    <dd className={"meta-grid__value"}
                        data-cy={"meta-value-applier-one"}>{props.functionDetail?.applier_one?.get_full_name || "-"}</dd>
                )}

                <dt className={"meta-grid__key"}>{t("Toetser")}</dt>
                {props.functionDetail?.applier_two_group ? (
                    <dd className={"meta-grid__value"}
                        data-cy={"meta-value-applier-two"}>{props.functionDetail?.applier_two_group?.name} </dd>
                ) : (
                    <dd className={"meta-grid__value"}
                        data-cy={"meta-value-applier-two"}>{props.functionDetail?.applier_two?.get_full_name || "-"}</dd>
                )}
            </div>

            <div className={"meta-grid__column"}>
                <dt className={"meta-grid__key"}>{t("Klant")}</dt>
                <dd className={"meta-grid__value"} data-cy={"meta-value-client"}>
                    {
                        canViewSourceProject ?
                            <Link to={generatePath("/client/:client_id", {
                                client_id: props.functionProjectDetail?.client?.id || 0,
                            })}>
                                {props.functionProjectDetail?.client?.name || "-"}
                            </Link> : props.functionProjectDetail?.client.name || "-"
                    }
                </dd>

                <dt className={"meta-grid__key"}>{t("Hoofdafdeling")}</dt>
                <dd className={"meta-grid__value"}
                    data-cy={"meta-value-department"}>{props.functionDetail?.department?.name || "-"}</dd>

                <dt className={"meta-grid__key"}>{t("Subafdeling")}</dt>
                <dd className={"meta-grid__value"}
                    data-cy={"meta-value-sub-department"}>{props.functionDetail?.sub_department?.name || "-"}</dd>
            </div>

            <div className={"meta-grid__column"}>
                <dt className={"meta-grid__key"}>{t("Aanmaakdatum")}</dt>
                <dd className={"meta-grid__value"}>
                    {props.functionDetail?.creation_date ? (
                        <time dateTime={props.functionDetail?.creation_date}>
                            {new Intl.DateTimeFormat("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            }).format(new Date(props.functionDetail?.creation_date))}
                        </time>
                    ) : (
                        "-"
                    )}
                </dd>
                <dt className={"meta-grid__key"}>{t("Aangemaakt door")}</dt>
                <dd className={"meta-grid__value"}>{props.functionDetail?.created_by?.get_full_name || "-"}</dd>

                <dt className="meta-grid__key">{t("Functiecode")}</dt>
                <dd className="meta-grid__value" data-cy={"meta-value-code"}>{props?.functionDetail.code || '-'}</dd>
            </div>

            <div className={"meta-grid__column"}>
                <dt className={"meta-grid__key"}>{t("Laatst bewerkt")}</dt>
                <dd className={"meta-grid__value"}>
                    {props.functionDetail?.creation_date ? (
                        <time dateTime={props.functionDetail?.modification_date}>
                            {new Intl.DateTimeFormat("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric",
                                hour: 'numeric',
                                minute: 'numeric',
                            }).format(new Date(props.functionDetail?.modification_date))}
                        </time>
                    ) : (
                        "-"
                    )}
                </dd>

                <dt className={"meta-grid__key"}>{t("Bewerkt door")}</dt>
                <dd className={"meta-grid__value"}>{props.functionDetail?.modified_by?.get_full_name || "-"}</dd>

                <dt className="meta-grid__key">{t("Aanvullende informatie")}</dt>
                <dd className="meta-grid__value"
                    data-cy={"meta-value-additional_information"}>{props?.functionDetail.additional_information || '-'}</dd>
            </div>
        </dl>

    )
}


export default function FunctionMetaDisplay(props) {

    const projectDetail = props?.projectDetail?.data;
    const functionDetail = props?.functionDetail?.data;

    const functionProjectDetail = getProject(functionDetail?.project.id)?.data;

    const {t, i18n} = useTranslation();

    const auth = useAuth();
    const [activeContext, setActiveContext] = useState(false);
    const [metaInfoHidden, setMetaInfoHidden] = useState(true);
    const navigate = useNavigate();

    // list of levels to check the access against
    const permLevels = [
        [projectDetail?.id, 'project'],
        [projectDetail?.client?.id, 'client'],
    ]

    const canChangeCollectiveLabourAgreement = auth.checkAccessList("base.change_collective_labour_agreement", permLevels);
    const canChangeIfCollectiveLabourAgreement = canChangeCollectiveLabourAgreement || projectDetail.is_collective_labour_agreement === false;

    const expire = async (id) => {
        await remoteExpireFunction(id);
        props.functionDetail.mutate();
    }

    const archive = async (id) => {
        await archiveFunction(id);
        navigate(generatePath(`/client/:client_id/project/:project_id/`, {
                client_id: projectDetail.client.id,
                project_id: projectDetail.id,
            }),
            {
                replace: true,
                state: {}
            });
    }

    const baseNewFunctionOn = (id, name) => {
        navigate(generatePath(`/client/:client_id/project/:project_id/function/create/`, {
                client_id: projectDetail.client.id,
                project_id: projectDetail.id,
            }),
            {
                replace: true,
                state: {
                    based_on_id: id,
                    based_on_name: name,
                    cancel: generatePath('/client/:client_id/project/:project_id/function/:function_id/', {
                        client_id: projectDetail.client.id,
                        project_id: projectDetail.id,
                        function_id: functionDetail.id,
                    })
                }
            });
    }

    const newVersionOf = async (id, new_status = 'concept', expireOldVersion = false) => {

        const newFunction = await remoteCreateNewVersion(id, new_status, expireOldVersion);

        // let client_id = newFunction.data.client.id; // in some cases this is not our own project (ie: when a project was moved!)
        const client_id = functionProjectDetail?.client?.id;
        // let project_id = newFunction.data.project.id; // in some cases this is not our own project (ie: when a project was moved!)
        const project_id = functionProjectDetail.id;

        const function_id = newFunction.data.id;

        navigate(generatePath('/client/:client_id/project/:project_id/function/:function_id/', {
            client_id: client_id,
            project_id: project_id,
            function_id: function_id,
        }));
    }

    const contextItemsAllowed = (list) => {
        return list.filter(o => !('allowed' in o) || ('allowed' in o && (o.allowed === true || o.allowed() !== false)));
    }

    const description_status_by = functionDetail?.description_status_modified_by || t("onbekend");
    const scoring_status_by = functionDetail?.scoring_status_modified_by || t("onbekend");

    const description_status_modification_date = functionDetail?.description_status_modification_date || false;
    const scoring_status_modification_date = functionDetail?.scoring_status_modification_date || false;

    const contextItems = [
        {
            allowed: () => !functionDetail?.immutable && props.editState.hasEditRight && (functionDetail?.is_protected === false || canChangeProtection) && canChangeIfCollectiveLabourAgreement && !(functionDetail.project.id !== projectDetail.id),
            identifier: 'edit',
            link: (
                <Link to={
                    generatePath('/client/:client_id/project/:project_id/function/:function_id/edit', {
                        client_id: functionDetail?.client?.id || 0,
                        project_id: projectDetail?.id || 0,
                        function_id: functionDetail?.id || 0,
                    })}
                      className={"dropdown-field__label"} title={t("Wijzig eigenschappen")}
                      data-cy={"context-menu-edit-function"}>
                    {t("Wijzig eigenschappen")}
                </Link>

            )
        },
        {
            allowed: () => props.editState.hasEditRight && (functionDetail?.is_protected === false || canChangeProtection) && canChangeIfCollectiveLabourAgreement && !(functionDetail.project.id !== projectDetail.id),
            identifier: 'new-version',
            link: (
                <a className={"dropdown-field__label"} title={t("Baseer nieuwe functie op deze functie")}
                   onClick={() => setActiveContext('new-version')}>
                    {t("Maak nieuwe versie")}
                </a>
            ),
            Component: <NewVersionModal
                key={'new-version'}
                subject={functionDetail?.name}
                version_number={functionDetail.version_number || 1}
                functionId={functionDetail?.id}
                newVersionOf={newVersionOf}
                closeModal={() => setActiveContext(false)}/>
        },
        {
            identifier: 'base-new-function',
            link: (
                <a className={"dropdown-field__label"} title={t("Baseer nieuwe functie op deze functie")}
                   onClick={() => setActiveContext('base-new-function')} data-cy={"context-menu-based-on"}>
                    {t("Baseer nieuwe functie op deze functie")}
                </a>
            ),
            Component: <ConfirmModal
                key={'base-new-function'}
                title={t("Baseer op deze functie")}
                body={t("Weet je zeker dat je een nieuwe functie wilt maken en baseren op") + ":"}
                subject={functionDetail?.name + " v" + functionDetail.version_number || 1}
                confirmModal={() => baseNewFunctionOn(functionDetail?.id, functionDetail?.name)}
                closeModal={() => setActiveContext(false)}/>
        },
        {
            identifier: 'hr',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => props.editState.hasEditRight && (functionDetail?.is_protected === false || canChangeProtection) && canChangeIfCollectiveLabourAgreement && !(functionDetail?.description_status === 'expired' || functionDetail?.scoring_status === 'expired') && !(functionDetail.project.id !== projectDetail.id),
            identifier: 'expire-function',
            link: (
                <a className={"dropdown-field__label"} title={t("Laat deze functie vervallen")}
                   onClick={() => setActiveContext('expire-function')}>
                    {t("Functie laten vervallen")}
                </a>
            ),
            Component: <ConfirmModal
                key={'expire-version'}
                title={("Functie laten vervallen")}
                body={t("Weet je zeker dat je deze functie wilt laten vervallen") + ":"}
                subject={functionDetail?.name}
                confirmModal={() => expire(functionDetail?.id)}
                closeModal={() => setActiveContext(false)}/>
        },
        {
            allowed: () => props.editState.hasArchiveRight && !(functionDetail.project.id !== projectDetail.id),
            identifier: 'archive-function',
            link: (
                <a className={"dropdown-field__label"} title={t("Archiveer deze functie")}
                   onClick={() => setActiveContext('archive-function')}>
                    {t("Functie archiveren")}
                </a>
            ),
            Component: <ConfirmModal
                key={'archive-function'}
                title={("Functie archiveren")}
                body={("Weet je zeker dat je deze functie wilt archiveren?") + ":"}
                subject={functionDetail?.name}
                confirmModal={() => archive(functionDetail?.id)}
                closeModal={() => setActiveContext(false)}/>
        },
        {
            identifier: 'hr2',
            link: (
                <hr key={'hr2'}/>
            )
        },
        {
            // allowed: () => projectDetail.permissions.includes('base.view_functiongroupstructure'),
            allowed: () => auth.hasPermission('base.can_view_fgs_popup', projectDetail.id, 'project'),

            identifier: 'fgs',
            link: (
                <a className={"dropdown-field__label"} title={t("Functiegroepenstructuur")}
                   onClick={() => setActiveContext('fgs')}>
                    {t("Functiegroepenstructuur")}
                </a>
            ),
            Component: <FGSItem key={'fgs'} projectDetail={projectDetail}
                                functionGroupStructureActive={activeContext === 'fgs'}
                                setFunctionGroupStructureActive={(activeState) => setActiveContext(activeState ? 'fgs' : false)}/>
        },
    ]

    const canChangeVisibility = auth.hasPermission('base.change_function_visibility', projectDetail.id, 'project');
    const canChangeProtection = auth.hasPermission('base.change_function_protection', projectDetail.id, 'project');
    const canCreateReport = auth.hasPermission('base.can_create_reports');
    const canViewSourceProject = auth.checkAccessList('base.view_project', [
        [functionProjectDetail?.id, 'project'],
        [functionProjectDetail?.client?.id, 'client'],
    ])
    return (
        <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom content-wrapper--no-margin-bottom"}>
            <div className={"content-container"}>
                {functionDetail.project.id !== projectDetail.id && canViewSourceProject &&
                    <Link className={"breadcrumbs__link breadcrumbs__link--source"}
                          to={generatePath("/client/:client_id/project/:project_id/function/:function_id/description", {
                              client_id: functionProjectDetail?.client?.id || 0,
                              project_id: functionProjectDetail?.id || 0,
                              function_id: functionDetail?.id
                          })}
                    >
                        {t("Bekijk functie in bronproject")}
                        <Icon icon={"arrow-right"} sizeModifier={"xsmall"}></Icon>
                    </Link>
                }
                <div className={"meta-info"}>
                    <div className={"meta-info__top"}>
                        <div className={"meta-info__primary"}>
                            <div className={"meta-info__title-container"}>
                                {!isFuwaterVbalans(projectDetail?.method?.id) && (
                                    <FunctionTypeIndicator type={functionDetail?.type}/>
                                )}
                                <h1 className={"meta-info__title"} data-cy={"meta-info-function"}>
                                    {functionDetail?.name} <span
                                    className="version version--padded version--no-margin">V{functionDetail?.version_number}</span>
                                    {functionDetail.is_protected && !canChangeProtection && (
                                        <span title={t("Beschermd: Deze functie mag niet gewijzigd worden")}><Icon
                                            icon={"protection-on"} sizeModifier={"small"}
                                            className={"overview-table__icon"}/></span>)}
                                    {functionDetail?.is_collective_labour_agreement ?
                                        (<span
                                            className={"overview-table__tag status-tag status-tag--cao"}>{t(FunctionSource['cao'].short)}</span>) : (
                                            functionDetail?.source ?
                                                (<span
                                                    className={cx("overview-table__tag", "status-tag", {["status-tag--" + FunctionSource[functionDetail.source].class]: FunctionSource[functionDetail.source].class})}>{t(FunctionSource[functionDetail.source].short)}</span>) : ''
                                        )}
                                </h1>
                            </div>
                            {functionDetail.project.id !== projectDetail.id &&
                                <span className={"meta-info__post-title"}>{t("Bron")}:&nbsp;
                                    {canViewSourceProject ?
                                        <Link className={"link"}
                                              to={generatePath("/client/:client_id", {
                                                  client_id: functionProjectDetail?.client?.id || 0,
                                              })}
                                        >
                                            {functionProjectDetail?.client.name}
                                        </Link> : functionProjectDetail?.client.name
                                    }
                                    &nbsp;/&nbsp;
                                    {canViewSourceProject ?
                                        <Link className={"link"}
                                              to={generatePath("/client/:client_id/project/:project_id/function", {
                                                  client_id: functionProjectDetail?.client?.id || 0,
                                                  project_id: functionProjectDetail?.id || 0,
                                              })}
                                        >
                                            {functionDetail?.project.name}
                                        </Link> : functionDetail?.project.name}
                                </span>
                            }
                            <div className={"meta-info__pattern-status-duo"}>
                                {functionDetail?.type !== 'match' && (
                                    <GroupPatternScore {...functionDetail} comparison={false}/>
                                )}
                                <div className={"status-duo"}>
                                    <div className={"status-duo__item"}>
                                        <StatusByNameDate
                                            canEdit={props?.editState.canEdit}
                                            editState={props.editState}
                                            title={t("Beschrijving")}

                                            identifier={functionDetail.description_status}
                                            label={functionStatus[functionDetail.description_status]}
                                            type={'description_status'}

                                            get_full_name={description_status_by?.get_full_name || false}
                                            first_name={description_status_by?.first_name}
                                            last_name={description_status_by?.last_name}
                                            date={description_status_modification_date}
                                            data-cy={"status-by-name-date-description"}
                                        />
                                    </div>

                                    <div className={"status-duo__item"}>

                                        <StatusByNameDate
                                            canEdit={props?.editState.canEdit}
                                            editState={props.editState}
                                            title={functionDetail?.type === 'reference' ? t("Gradering") : t("Indeling")}

                                            identifier={functionDetail.scoring_status}
                                            label={functionStatus[functionDetail.scoring_status]}
                                            type={'scoring_status'}

                                            get_full_name={scoring_status_by?.get_full_name || false}
                                            first_name={scoring_status_by?.first_name}
                                            last_name={scoring_status_by?.last_name}
                                            date={scoring_status_modification_date}
                                            data-cy={"status-by-name-date-scoring"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"meta-info__secondary"}>
                            <div className={"meta-info__buttons"}>
                                {projectDetail?.project_groups?.length > 0 && (
                                    <ToolTip tooltip={t("Projectgroep")}>
                                        <IconButton icon={"linked"}
                                                    active={functionDetail?.is_linked && 'true'}
                                                    title={t("Beschikbaar in projectgroep")}
                                                    border={"true"}
                                                    onClick={e => {
                                                        props.functionDetail?.setLinked();
                                                    }}
                                        />
                                    </ToolTip>
                                )}

                                <div className={"icon-button-group"}>
                                    {props.functionDetail?.favorite ? (
                                        <ToolTip tooltip={t("Favoriet")}>
                                            <IconButton icon={"favorite-on"} className={"icon-button--favorite"}
                                                        title={t("Favoriet")} active={'true'}
                                                        border={"true"}
                                                        onClick={e => {
                                                            props?.functionDetail?.setFavorite();
                                                        }}
                                            />
                                        </ToolTip>
                                    ) : (
                                        <ToolTip tooltip={t("Favoriet")}>
                                            <IconButton icon={"favorite-off"} title={t("Favoriet")}
                                                        border={"true"}
                                                        onClick={e => {
                                                            props?.functionDetail?.setFavorite();
                                                        }}
                                            />
                                        </ToolTip>

                                    )}
                                    {canChangeVisibility && (
                                        <ToolTip tooltip={t("Zichtbaarheid")}>
                                            <IconButton icon={"visibility-off"}
                                                        active={!functionDetail?.is_visible && 'true'}
                                                        title={t("Onzichtbaar")}
                                                        data-cy={"meta-info-visibility"}
                                                        border={"true"}
                                                        onClick={e => {
                                                            props.functionDetail?.setVisibility();
                                                        }}
                                            />
                                        </ToolTip>
                                    )}

                                    {canChangeProtection && (
                                        <ToolTip tooltip={t("Bescherming")}>
                                            <IconButton
                                                icon={"protection-" + (functionDetail?.is_protected ? "on" : "off")}
                                                active={functionDetail?.is_protected && 'true'}
                                                title={t("Beschermd")}
                                                data-cy={"meta-info-protected"}
                                                border={"true"}
                                                onClick={e => {
                                                    props.functionDetail?.setProtection();
                                                }}
                                            />
                                        </ToolTip>
                                    )}
                                </div>

                                <div className={"icon-button-group"}>
                                    {canCreateReport && (
                                        <ToolTip tooltip={props.editState.state === "closed" ? t("Functie rapportage") : t("Rapportage genereren niet mogelijk wanneer je in een functie aan het werk bent")}>
                                            <IconButton border={"true"} icon={"file-pdf"} text={t("Rapportage")}
                                                        data-cy={"create-report-button"} disabled={props.editState.state !== "closed"} onClick={() => {
                                                navigate(generatePath('/client/:client_id/project/:project_id/function/:function_id/report', {
                                                    client_id: projectDetail?.client?.id,
                                                    project_id: projectDetail?.id,
                                                    function_id: functionDetail.id
                                                }))
                                            }}/>
                                        </ToolTip>
                                    )}
                                    <InfoButton
                                        icon={"context-menu"} title={"Contextmenu"}
                                        popOverClickClass={"dropdown-toggle"}
                                        popOverClass={"dropdown"}
                                        popOverClassActive={"dropdown dropdown--active"}
                                        border={"true"}
                                        data-cy={"context-menu"}
                                    >
                                        <ul>
                                            {contextItemsAllowed(contextItems).map((obj, idx) => {
                                                if(obj.identifier === "hr2" && contextItemsAllowed(contextItems)[idx - 1].identifier === "hr"){
                                                    return
                                                }

                                                return (
                                                    <li key={obj.identifier} className={"dropdown-field"}>
                                                        {obj.link}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </InfoButton>
                                    {contextItemsAllowed(contextItems).map((obj, idx) => {
                                        if(activeContext === obj.identifier) {
                                            return obj.Component;
                                        }
                                    })}
                                </div>
                            </div>
                            <button data-cy={"meta-info-expand-button"}
                                    className={"meta-info__show-more"}
                                    onClick={() => setMetaInfoHidden(!metaInfoHidden)}>{t("Informatie")} {metaInfoHidden === false ? t("verbergen") : t("tonen")}
                                <Icon icon={"angle-" + (metaInfoHidden === false ? "up" : "down")}
                                      sizeModifier={"xsmall"} className={"meta-info__show-more-icon"}/>
                            </button>
                        </div>
                    </div>
                    <div className={cx("meta-info__extra", {'meta-info__extra--open': !metaInfoHidden})}>

                        <FunctionMetaInfo
                            functionDetail={functionDetail}
                            functionProjectDetail={functionProjectDetail}
                        />

                        <div className={"meta-info__version-history"}>
                            <AlternativeVersions function_id={functionDetail?.id} project_id={functionProjectDetail?.id}
                                                 client_id={props?.client_id}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
