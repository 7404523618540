import FilterLabel from "./FilterLabel";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import React, {useEffect, useState} from "react";
import {IconButton} from "../patterns/molecules/IconButton";

export default function HorizontalFilterMenu(props) {
    const [isFirstItemVisibleState, setIsFirstItemVisibleState] = useState();
    const [isLastItemVisibleState, setIsLastItemVisibleState] = useState();

     function LeftArrow() {
        const { isFirstItemVisible, isLastItemVisible, getPrevItem, scrollToItem } = React.useContext(VisibilityContext);

        useEffect(() => {
            setIsFirstItemVisibleState(isFirstItemVisible);
            setIsLastItemVisibleState(isLastItemVisible)
        }, []);

        return (
            <>
                {isFirstItemVisible && isLastItemVisible &&
                    <></>
                }
                {(!isFirstItemVisible || (isFirstItemVisible && !isLastItemVisible)) &&
                    <IconButton
                        className={"overview-filter-container__navigation overview-filter-container__navigation--left icon-button--medium"}
                        icon={"angle-right"}
                        disabled={isFirstItemVisible}
                        onClick={() => scrollToItem(getPrevItem()?.entry?.target, "smooth", "start")}
                    />
                }
            </>
        )
    }

    function RightArrow() {
        const { isLastItemVisible, isFirstItemVisible, getNextItem, scrollToItem } = React.useContext(VisibilityContext);

        useEffect(() => {
            setIsFirstItemVisibleState(isFirstItemVisible);
            setIsLastItemVisibleState(isLastItemVisible)
        }, []);

        return (
            <>
                {isLastItemVisible && isFirstItemVisible &&
                    <></>
                }
                {(!isLastItemVisible || (isLastItemVisible && !isFirstItemVisible)) &&
                    <IconButton className={"overview-filter-container__navigation overview-filter-container__navigation--right icon-button--medium"}
                                icon={"angle-right"}
                                disabled={isLastItemVisible}
                                onClick={() => scrollToItem(getNextItem()?.entry?.target, "smooth", "end")}
                    />
                }
            </>
        )
    }

   return (
       <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} scrollContainerClassName={!isFirstItemVisibleState || !isLastItemVisibleState && "react-horizontal-scrolling-menu--scroll-container-with-margin"}>
           {props.filterLabels(props)?.map((filterLabel) => {
               return filterLabel
           })}
       </ScrollMenu>
   )
}
