import React, { useEffect, useState} from "react";
import FunctionStatusNavigationDisplay  from "../patterns/organisms/FunctionStatusNavigation";
import {getFunction} from "../queries/function_detail";
import {useNavigate, useParams, useRouteMatch, useLocation} from "react-router-dom";
import {functionStatus} from "../utils/constants";
import {useSelector} from "react-redux";
import {useAuth} from "../utils/useAuth";
import i18n from "../utils/i18n";
import {useTranslation} from "react-i18next";

// Values used by Redux State
export const FunctionStatusNavigationDefaultState = {
    title: "",
    status: false,
    statusIdentifier: "concept",
    quickview: 'description',
    functionDetail: {
        description_status: '',
    },
    current_view: {},
    quickviews: [],
    quickviewsReference: [
        {
            identifier: "description",
            title: i18n.t("Beschrijving"),
            noMargin: true,
        },
        {
            identifier: "scoring",
            title: i18n.t("Gradering"),
        },
        {
            identifier: "motivation",
            title: i18n.t("Functiebeeld"),
        },
        {
            identifier: "summary",
            title: i18n.t("Samenvatting"),
        },
    ],
    quickviewsCompany: [
        {
            identifier: "description",
            title: i18n.t("Beschrijving"),
            noMargin: true,
        },
        {
            identifier: "scoring",
            title: i18n.t("Waardering"),
        },
        {
            identifier: "rank",
            title: i18n.t("Indeling"),
        },
        {
            identifier: "motivation",
            title: i18n.t("Onderbouwing"),
        },
        {
            identifier: "summary",
            title: i18n.t("Samenvatting"),
        },
    ],
    quickviewsMatch: [
        {
            identifier: "description",
            title: i18n.t("Beschrijving"),
            noMargin: true,
        },
        {
            identifier: "rank",
            title: i18n.t("Inpassen"),
        },
        {
            identifier: "motivation",
            title: i18n.t("Onderbouwing"),
        },
        {
            identifier: "summary",
            title: i18n.t("Samenvatting"),
        },
    ],
}

export default function FunctionStatusNavigation(props) {

    const functionDetail = getFunction(props.function_id);

    const navigate = useNavigate();
    const params = useParams();

    let passProps = Object.assign({}, FunctionStatusNavigationDefaultState, props); // should probably be handled by default REduxSTate above (but no reducers are present yet)

    const auth = useAuth();

    // old style
    // const canChangeReference = auth.hasPermission('base.change_reference_function', parseInt(params.project_id), 'project');

    // new style
    const canChangeReference = auth.checkAccessList('base.change_reference_function',[
        ['project_id', 'project'],
        ['client_id', 'client'],
    ]);


    let quickview = params?.['*'];

    useEffect(() => {
        if(!quickview) {
            navigate('description')
        }
    },[]);


    if(!functionDetail.isLoading) {
        passProps.functionDetail = functionDetail;
    }

    let scoringIdx = passProps.quickviews.findIndex(x => x.identifier === 'scoring')
    switch(passProps?.functionDetail?.data?.type) {
        case 'company':
            passProps.quickviews = FunctionStatusNavigationDefaultState.quickviewsCompany;
            break;
        case 'reference':
            passProps.quickviews = FunctionStatusNavigationDefaultState.quickviewsReference.filter(o => o.identifier !== "scoring" || canChangeReference);
            break;
        case 'fit':
        case 'match':
            passProps.quickviews = FunctionStatusNavigationDefaultState.quickviewsMatch;
            break;
        default:
            break;
    }

    passProps.current_view = passProps.quickviews.find(element => element.identifier === quickview);

    passProps.editState = props?.editState;
    passProps.acquireLock = props?.acquireLock;
    passProps.unlock = props?.unlock;

    const {t} = useTranslation();

    passProps.descriptionTypeOptions = {
        ["normal"]: {
            identifier: "normal",               // uses CK Editor (empty or based on template)
            short: t("Zelf beschrijven"),
            description: t("Beschrijving wordt via het systeem gemaakt"),
        },
        ["upload"]: {
            identifier: "upload",             // description is present as downloadable file
            short: t("Bestand toevoegen"),
            description: t("Beschrijving is beschikbaar middels een bestand")
        },

        ["not-present"]: {
            identifier: "not-present",          // no description is present at all
            short: t("Beschrijving niet aanwezig"),
            description: t("Er is geen beschrijving aanwezig"),
            placeholder: t("Toelichting waarom de beschrijving niet aanwezig is")
        },
        ["postponed"]: {
            identifier: "postponed",            // description not provided at request of the client
            short: t("Wordt later toegevoegd"),
            description: t("Beschrijving wordt op een later moment toegevoegd"),
            placeholder: t("Toelichting waarom de beschrijving uitgesteld is")
        },
        ["offline"]: {
            identifier: "offline",      // description is present in physical form
            short: t("Fysiek aanwezig"),
            description: t("Beschrijving is alleen offline / in fysieke vorm aanwezig"),
            placeholder: t("Toelichting waar en/of bij wie de beschrijving op te vragen is")
        },


    }


    /*
    if(['scoring','rank','motivation'].includes(passProps?.current_view?.identifier)) {
        passProps.status = {
            identifier: passProps.functionDetail.scoring_status,
            label: functionStatus[passProps.functionDetail.scoring_status],
            type: 'scoring_status'
        }
    }
    if(passProps?.current_view?.identifier === 'description') {
        passProps.status = {
            identifier: passProps.functionDetail.description_status,
            label: functionStatus[passProps.functionDetail.description_status],
            type: 'description_status'
        }
    }
    */

    return (
        <FunctionStatusNavigationDisplay
            {...passProps}
        />
    )
}
