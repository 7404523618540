import React from "react";
import {generatePath, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Notification from "./Notification";
import ButtonPrimary from "../atoms/ButtonPrimary";

export function ReportGeneratingMessage(props) {

    const {t, i18n} = useTranslation();

    return (
        <>
            <Notification type={"warning"} sizeModifier={"large"} text={t("De rapportage wordt aangemaakt. Ga naar het rapportageoverzicht om de rapportage in te kunnen zien")}/>
            <Link to={generatePath('/reports', {})} title={t("Rapportages")}>
                <ButtonPrimary text={t("Ga naar rapportages")}
                               fullWidth={true}
                >
                </ButtonPrimary>
            </Link>
        </>
    )
}
