import {useTranslation} from "react-i18next";
import {generatePath, Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../utils/useAuth";
import axios from "axios";
import {getClient} from "../../queries/client_detail";
import {withHook} from "../../utils/withHookHoC";
import React, {useEffect, useState} from "react";
import {getProject} from "../../queries/project_detail";
import ButtonPrimary from "../atoms/ButtonPrimary";
import LoaderAnimation from "../atoms/LoaderAnimation";
import Notification from "../molecules/Notification";
import {useForm} from "react-hook-form";
import {CheckboxInput, SimpleRadioInputs} from "../atoms/Input";
import Select from "../atoms/Select";
import {isFuwaterVbalans} from "../../utils/constants";
import {FunctionReportTable} from "./FunctionReport";
import useSWR from "swr";
import {ReportGeneratingMessage} from "../molecules/ReportGeneratingMessage";

export function ProjectReportDisplay(props) {
    const {
        register, handleSubmit, setError, clearErrors, setValue, watch, formState: {errors}
    } = useForm({
        defaultValues: {
            meta: true,
            reference_meta: true,
            company_meta: true,
            match_meta: true,
            company_motivation: true,
            match_motivation: true,
            company_confirmed_group: true,
            company_reference_functions: true,
            determined_functions: "determined",
            reference_scoring_radio: "reference_scoring_as_basic"
        }, shouldUnregister: true
    });

    useEffect(() => {
        if(props.client_id) {
            setValue("client_id", props.client_id)
        }

        if(props.project_id) {
            setValue("project_id", props.project_id)
        }
    }, [props.client_id, props.project_id])

    const {t} = useTranslation();

    if(!props?.onSubmit) {
        return <LoaderAnimation/>;
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Projectrapportage maken")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(props.onSubmit)(e).catch((error) => {
                    if(error.cause.message === "server-error-project-report" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <section className={"form-meta"}>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Project")}</span>
                        <span className={"form-meta__description"} data-cy={"project-name"}>{props?.project_name}</span>
                    </div>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Klant")}</span>
                        <span className={"form-meta__description"} data-cy={"client-name"}>{props.client_name}</span>
                    </div>
                </section>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>
                        {t("Welke onderdelen wilt u meenemen?")}
                    </legend>

                    <div className="input-group">
                        {props.canViewFGS &&
                            <CheckboxInput
                                key={"fgs"}
                                name={"fgs"}
                                id={"fgs"}
                                type={"checkbox"}
                                label={t("Functiegroepenstructuur")}
                                register={register}
                            />
                        }

                        <CheckboxInput
                            key={"function_list"}
                            name={"function_list"}
                            id={"function_list"}
                            type={"checkbox"}
                            label={t("Functieoverzicht")}
                            register={register}
                        />

                        <CheckboxInput
                            key={"book"}
                            name={"book"}
                            id={"book"}
                            type={"checkbox"}
                            label={t("Functieboek")}
                            register={register}
                            helpText={t("Neem voor elke functie een functierapportage op.")}
                        />

                        <CheckboxInput
                            key={"meta"}
                            name={"meta"}
                            id={"meta"}
                            type={"checkbox"}
                            label={t("Meta-informatie")}
                            register={register}
                            helpText={"Toont aanvullende informatie van het project op het voorblad."}
                        />

                        <CheckboxInput
                            key={"definition_sheet"}
                            name={"definition_sheet"}
                            id={"definition_sheet"}
                            type={"checkbox"}
                            label={t("Toon informatieblad")}
                            register={register}
                            helpText={t("Het informatieblad geeft verdiepende informatie over domeinen en hun definitie.")}
                        />
                    </div>
                </fieldset>

                {(watch("fgs") || watch("function_list") || watch("book")) &&
                    <fieldset className={"form-container__fieldset"}>
                        <legend className={"form-container__legend"}>
                            {t("Wat voor type functies wilt u weergeven?")}
                        </legend>

                        <div className="input-group">
                            <CheckboxInput
                                key={"reference_functions"}
                                name={"reference_functions"}
                                id={"reference_functions"}
                                type={"checkbox"}
                                label={t("Referentiefuncties")}
                                register={register}
                            />

                            {!isFuwaterVbalans(props.method_id) &&
                                <CheckboxInput
                                    key={"company_functions"}
                                    name={"company_functions"}
                                    id={"company_functions"}
                                    type={"checkbox"}
                                    label={t("Bedrijfseigen functies")}
                                    register={register}
                                />
                            }

                            {props.methodDetail?.allow_function_matching &&
                                <CheckboxInput
                                    key={"match_functions"}
                                    name={"match_functions"}
                                    id={"match_functions"}
                                    type={"checkbox"}
                                    label={t("Ingepaste functies")}
                                    register={register}
                                />
                            }
                        </div>
                    </fieldset>
                }

                {(watch("reference_functions") || watch("company_functions") || watch("match_functions")) &&
                    <fieldset className={"form-container__fieldset"}>
                        <legend className={"form-container__legend"}>
                            {t("Welke status mogen deze functies hebben?")}
                        </legend>

                        <div className="input-group">
                            <SimpleRadioInputs
                                id={"determined_functions"}
                                name={"determined_functions"}
                                options={[
                                    {
                                        id: "determined",
                                        name: "Vastgestelde functies"
                                    },
                                    {
                                        id: "both",
                                        name: "Vastgestelde en niet-vastgestelde functies"
                                    },
                                    {
                                        id: "non_determined",
                                        name: "Niet-vastgestelde functies"
                                    }
                                ]}
                                register={register}
                                setValue={setValue}
                            />
                        </div>
                    </fieldset>
                }

                {(watch("reference_functions") || watch("company_functions") || watch("match_functions")) && (props.canViewCompanyFunctionPoints || props.canViewReferenceFunctionPoints) &&
                    <fieldset className={"form-container__fieldset"}>
                        <legend className={"form-container__legend"}>
                            {t("Waar mogen punten getoond worden?")}
                        </legend>

                        <div className="input-group">
                            {props.canViewReferenceFunctionPoints &&
                                <CheckboxInput
                                    key={"reference_points"}
                                    name={"reference_points"}
                                    id={"reference_points"}
                                    type={"checkbox"}
                                    label={t("Toon punten bij referentiefuncties")}
                                    register={register}
                                />
                            }

                            {(props.canViewCompanyFunctionPoints && watch("company_functions")) &&
                                <CheckboxInput
                                    key={"company_points"}
                                    name={"company_points"}
                                    id={"company_points"}
                                    type={"checkbox"}
                                    label={t("Toon punten bij bedrijfseigen functies")}
                                    register={register}
                                />
                            }
                        </div>
                    </fieldset>
                }

                {watch("book") && (watch("reference_functions") || watch("company_functions") || watch("match_functions")) &&
                    <fieldset className={"form-container__fieldset"}>
                        <legend className={"form-container__legend"}>
                            {t("Welke onderdelen wilt u meenemen in het functieboek?")}
                        </legend>

                        <FunctionReportTable register={register} project_id={props.project_id}
                                             isMulti={true} watch={watch} setValue={setValue}/>
                    </fieldset>
                }

                {(watch("book") || watch("function_list")) &&
                    <fieldset className={"form-container__fieldset"}>
                        <legend className={"form-container__legend"}>
                            {t("Overige weergave opties")}
                        </legend>
                        <div className="input-group">
                            <div className={"input-group__label-container"}>
                                <label className={"input-group__label"}>{t("Sortering")}</label>
                            </div>
                            <p className={"input-group__help-text"}>
                                {t("Selecteer de wijze waarop het functieoverzicht en het functieboek gesorteerd moeten worden.")}
                            </p>
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    <Select
                                        name={"order_by"}
                                        type={"select"}
                                        options={props.order_by_options}
                                        id={"order_by"}
                                        register={register}
                                        error={errors.order_by}
                                    />
                                </div>
                                <div className={"input-range__input"}>
                                    <Select
                                        name={"order_by_direction"}
                                        type={"select"}
                                        options={props.order_by_direction_options}
                                        id={"order_by_direction"}
                                        register={register}
                                        error={errors.order_by}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                }
                {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                text={errors.server.message}/>}

                <fieldset className={"form-container__fieldset"}>
                    {props.pending === false ? (
                        <ButtonPrimary
                            text={t("Rapportage maken")}
                            fullWidth={true}
                            disabled={props.generating}
                        >
                            {props.generating && (
                                <div className={"button__loader"}>
                                    <LoaderAnimation small={true} infinite={true}/>
                                </div>
                            )}
                        </ButtonPrimary>
                    ) : (
                        <ReportGeneratingMessage/>
                    )}
                </fieldset>
            </form>
        </>
    )
}

export const useProjectReport = (props) => {

    const {t, i18n} = useTranslation();

    let navigate = useNavigate();
    let location = useLocation();

    let {client_id, project_id, function_id} = useParams();
    const {data: clientDetail} = getClient(client_id);
    const {data: projectDetail} = getProject(project_id);
    const {data: methodDetail} = useSWR(() => projectDetail?.method?.id ? `/api/methods/${projectDetail?.method?.id}/` : false);

    const [selection, setSelection] = useState([]);
    const [generating, setGenerating] = useState(false);
    const [pending, setPending] = useState(false);


    useEffect(() => {
        setSelection(Object.entries(location.state || {}).filter(o => o[1] === true).map(o => parseInt(o[0])));
    }, [location.state])

    const auth = useAuth();

    if(!clientDetail || !projectDetail) {
        return {};
    }

    const authList =  [
        [project_id, 'project'],
        [client_id, 'client'],
    ]

    const canViewReferenceFunctionPoints = auth.checkAccessList('base.view_reference_function_points', authList);
    const canViewCompanyFunctionPoints = auth.checkAccessList('base.view_company_function_points', authList);
    const canViewFGS = auth.checkAccessList('base.can_view_fgs_popup', authList);

    const order_by_options = [
        {
            value: "function_group",
            display_name: "Groep"
        },
        {
            value: "name",
            display_name: "Naam"
        },
        {
            value: "modification_date",
            display_name: "Wijzigingsdatum"
        }
    ]

    const order_by_direction_options = [
        {
            value: "asc",
            display_name: "Oplopend"
        },
        {
            value: "desc",
            display_name: "Aflopend"
        }
    ]

    return {

        t,
        i18n,

        generating,
        pending,
        order_by_options,
        order_by_direction_options,
        canViewFGS,
        canViewReferenceFunctionPoints,
        canViewCompanyFunctionPoints,
        methodDetail,
        selection: selection || [],
        client_id: clientDetail?.id,
        project_id: projectDetail?.id,
        method_id: projectDetail?.method?.id,
        client_name: clientDetail?.name,
        project_name: projectDetail?.name,

        onSubmit: async (values) => {
            setGenerating(true);

            let url = `/api/export/project/report/${projectDetail.id}/?pdf=1`;

            let submitValues = {...values}

            const mapping = {
                "company_functions": ["fgs_company_functions", "function_list_company_functions", "book_company_functions"],
                "reference_functions": ["fgs_reference_functions", "function_list_reference_functions", "book_reference_functions"],
                "match_functions": ["fgs_match_functions", "function_list_match_functions", "book_match_functions"],
                "reference_points": ["company_reference_points", "match_reference_points", "function_list_reference_points"],
                "company_points": ["function_list_company_points"],
                "order_by": ["function_list_order_by", "book_order_by"],
                "order_by_direction": ["function_list_order_by_direction", "book_order_by_direction"]
            }

            Object.keys(mapping).forEach((mappingKey) => {
                const key = mapping[mappingKey];

                key.forEach((listVal, idx) => {
                    submitValues[listVal] = values[mappingKey];
                })
            });

            if(values.determined_functions) {
                if(values.determined_functions === "both") {
                    submitValues.fgs_determined = true;
                    submitValues.function_list_determined = true;
                    submitValues.book_determined = true;

                    submitValues.fgs_non_determined = true;
                    submitValues.function_list_non_determined = true;
                    submitValues.book_non_determined = true;
                }

                if(values.determined_functions === "determined") {
                    submitValues.fgs_determined = true;
                    submitValues.function_list_determined = true;
                    submitValues.book_determined = true;
                }

                if(values.determined_functions === "non_determined") {
                    submitValues.fgs_non_determined = true;
                    submitValues.function_list_non_determined = true;
                    submitValues.book_non_determined = true;
                }
            }

            if(values.reference_scoring_radio) {
                if(values.reference_scoring_radio === "reference_scoring_as_basic") {
                    submitValues.reference_scoring_as_basic = true;
                }

                if(values.reference_scoring_radio === "reference_scoring") {
                    submitValues.reference_scoring = true
                }
            }

            delete submitValues["reference_functions"]
            delete submitValues["company_functions"]
            delete submitValues["match_functions"]
            delete submitValues["determined_functions"]
            delete submitValues["order_by"]
            delete submitValues["order_by_direction"]
            delete submitValues["reference_scoring_radio"]
            delete submitValues["reference_scoring_checkbox"]

            axios.post(url, Object.assign({}, {
                client_name: clientDetail?.name,
                project_name: projectDetail?.name,
                selection,
                ...submitValues,
            })).then(result => {
                    if(result?.data?.status === "ok") {
                        setPending(true);
                    }
                }
            )

            //     {
            //     responseType: 'blob',
            // }).then( response => {
            //     const date = new Intl.DateTimeFormat("nl-NL", {
            //         day: "2-digit",
            //         month: "2-digit",
            //         year: "numeric"
            //         // hour: 'numeric',
            //         // minute: 'numeric',
            //     }).format(new Date());
            //     const extension = response.headers['content-type'] === "application/x-zip-compressed" ? "zip" : "pdf"
            //     const filename = `${projectDetail?.client?.name} - ${projectDetail?.name} - ${t('Projectrapportage')} ${date} - FUWA Online.${extension}`;
            //     instantDownload(response.data, filename);
            //     setGenerating(false);
            // });

            // PDF call

        },
    }
}

export const ProjectReport = withHook(
    ProjectReportDisplay,
    useProjectReport,
)


