import useSWR from "swr";
import axios from "axios";

export function getTags(objectType, objectId) {

    const {data, mutate} = useSWR(() => objectType && objectId ? `/api/tags/?object=${objectType}&object_id=${objectId}`: false);

    return {
        data,
        mutate,
    }
}

export async function remoteSetTag(objectType, objectId, tag, remove=false) {

    const type = remove ? 'untag': 'tag';

    let url = '/api';

    switch(objectType) {
        case 'FunctionDescription':
            url = `/api/functiondescriptions/${objectId}/${type}/`
            break;
        case 'DefaultFunctionDescriptionTemplate':
            url = `/api/functiondescriptiontemplates/${objectId}/${type}/`
            break;
    }

    await axios.patch(url, {
        name: tag
    });

}

