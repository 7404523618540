import ApplicationHeader, {ContextMenuUser, Downloads} from "../../patterns/organisms/ApplicationHeader";
import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SVG from "../../utils/SVG";
import useSWR from "swr";
import hcglogo from 'IMAGES/logo-humancapitalgroup.svg';
import {useTranslation} from "react-i18next";
import {useAuth} from "../../utils/useAuth";
import ButtonPrimary from "../../patterns/atoms/ButtonPrimary";

export function InsightScreen(props) {
    const {data: getProjects} = useSWR('/api/projects/');
    const {data: getClient} = useSWR(auth?.user?.client?.id ? `/api/clients/${auth.user.client.id}/` : false);
    const {data: getManager} = useSWR(getClient?.manager ? `/api/users/${getClient.manager}/` : false)
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const auth = useAuth();
    const [buttonActiveState, setButtonActiveState] = useState("false");

    useEffect(() => {
        if(location.pathname === '/client') {
            navigate('/home');
        }
    }, [location]);

    if(getProjects?.results?.length <= 0) {
        return (<div style={{
            marginTop: 96 + "px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 24 + "px"
        }}>
            <img src={hcglogo} alt={"Human Capital Group"} className={"login-container__logo"}/>
            <div className={"tool"}>
                <div className={"tool-header"}>
                    <h1 className={"tool-header__title"}>{t("Geen gegevens gevonden")}</h1>
                </div>
                <div className={"tool__content"}>
                    <p className={"paragraph"} style={{ whiteSpace: "pre-wrap" }}>
                        {t(`Voor de gebruiker waarmee u bent ingelogd \"${auth?.user?.full_name}\" zijn geen gegevens gevonden.\n`)}
                        <br/>
                        {t("Is dit niet correct? Neem dan contact op met de HR afdeling bij uw bedrijf.\n")}
                    </p>
                    {getManager && (
                        <p className={"paragraph"}>
                            {t("Uw FUWA Online beheerder")}:
                            <br/>
                            <b>{getManager?.full_name}</b><br/>
                            <a href={"mailto:" + getManager?.email} className={"link"}>{getManager?.email}</a>
                        </p>
                    )}
                        {!getManager && (
                            <>
                                <p className={"paragraph"}>
                                    {t("Is er geen contactpersoon bekend dan kunt u contact opnemen met")}:
                                </p>
                                <p className={"paragraph"}>
                                    <a href={"mailto:support@humancapitalgroup.nl"}
                                       className={"link"}>support@humancapitalgroup.nl</a> {t("voor technische vragen")}<br/>
                                </p>
                            </>
                        )}
                    <p className={"paragraph"}>
                        {t("of telefonisch via")}:<br/>
                        <a href={"tel:+31302193960"} className={"link"}>030 219 39 60</a>
                    </p>
                    <ButtonPrimary onClick={() => auth.logout()}>{t("Uitloggen")}</ButtonPrimary>
                </div>
            </div>
        </div>)
    } else {
        return (
            <>
                <header className={"application-header"}>
                    <div className={"content-container"}>
                        <div className={"application-header__container"}>
                            <div className={"application-header__left"}>
                                <div className={"application-header__logo-container"}>
                                    <Link to={"/client"}>
                                        <SVG src={"logo-humancapitalgroup.svg"}
                                             className={"application-header__logo"}/>
                                    </Link>
                                </div>
                            </div>
                            <div className={"application-header__right"}>
                                <div className={"user-actions"}>
                                    <div className="user-actions__item">
                                        <Downloads buttonActiveState={buttonActiveState} setButtonActiveState={setButtonActiveState}/>
                                    </div>
                                </div>
                                <ContextMenuUser/>
                            </div>
                        </div>
                    </div>
                </header>
                {props.children && React.cloneElement(props.children, {buttonActiveState: buttonActiveState, setButtonActiveState: setButtonActiveState})}
            </>
        )
    }
}

export default function DefaultScreen(props) {
    const {data: getProjects} = useSWR('/api/projects/');
    const {t} = useTranslation();
    const auth = useAuth();

    const {data: getClient} = useSWR(auth?.user?.client?.id ? `/api/clients/${auth.user.client.id}/` : false);
    const {data: getManager} = useSWR(getClient?.manager ? `/api/users/${getClient.manager}/` : false)

    if(getProjects?.results?.length <= 0) {
        return (<div style={{
            marginTop: 96 + "px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 24 + "px"
        }}>
            <img src={hcglogo} alt={"Human Capital Group"} className={"login-container__logo"}/>
            <div className={"tool"}>
                <div className={"tool-header"}>
                    <h1 className={"tool-header__title"}>{t("Geen projecten toegewezen")}</h1>
                </div>
                <div className={"tool__content"}>
                    <p className={"paragraph"} style={{ whiteSpace: "pre-wrap" }}>
                        {t(`De gebruiker waarmee u bent ingelogd \"${auth?.user?.full_name}\" heeft geen toegewezen projecten.\n`)}
                        <br/>
                        {t("Is dit niet correct? Neem dan contact op met de HR afdeling bij uw bedrijf.\n")}
                    </p>
                    {getManager && (
                        <p className={"paragraph"}>
                            {t("Uw FUWA Online beheerder")}:
                            <br/>
                            <b>{getManager?.full_name}</b><br/>
                            <a href={"mailto:" + getManager?.email} className={"link"}>{getManager?.email}</a>
                        </p>
                    )}
                        {!getManager && (
                            <>
                                <p className={"paragraph"}>
                                    {t("Is er geen contactpersoon bekend dan kunt u contact opnemen met")}:
                                </p>
                                <p className={"paragraph"}>
                                    <a href={"mailto:support@humancapitalgroup.nl"}
                                       className={"link"}>support@humancapitalgroup.nl</a> {t("voor technische vragen")}<br/>
                                    <a href={"mailto:info@humancapitalgroup.nl"}
                                       className={"link"}>info@humancapitalgroup.nl</a> {t("voor algemene vragen")}<br/>
                                </p>
                            </>
                        )}
                    <p className={"paragraph"}>
                        {t("of telefonisch via")}:<br/>
                        <a href={"tel:+31302193960"} className={"link"}>030 219 39 60</a>
                    </p>
                    <ButtonPrimary onClick={() => auth.logout()}>{t("Uitloggen")}</ButtonPrimary>
                </div>
            </div>
        </div>)
    } else {
        return (
            <>
                <ApplicationHeader/>
                {props.children}
            </>
        )
    }

}
