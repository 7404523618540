import axios from "axios";
import useSWR, {useSWRInfinite} from "swr";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder, loadIfAllowed} from "../utils/constants";

export function getRelatedFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            {
                scoring_status: 'determined',
            }
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/functions/${fetchState?.identKey?.function_id}/relative_distance/?page=${index+1}&page_size=${fetchState.pagination[fetchState.type].size}&ordering=${ordering}${filtering}`: false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
            dedupingInterval: 60000,
        }
    );
}

export function getRefFunctionsForRanking(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const allowed_types =  fetchState?.identKey?.match ? ["reference", "company"] : ["reference"];
    const filtered_types = fetchState?.filter?.type__in?.filter(f => allowed_types.includes(f));
    const types = !Array.isArray(filtered_types) || filtered_types?.length <= 0 ? allowed_types : filtered_types;

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            {
                type__in: types.join(","),
            },
            {
                scoring_status: 'determined',
            }
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/functions/${fetchState?.identKey?.function_id}/relative_distance/?page=${index+1}&page_size=${fetchState.pagination[fetchState.type].size}&view=rank&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}`: false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}
