import React, {Suspense, useEffect, useState as useStateReact} from "react";
import Pip from "../atoms/Pip";
import cx from "classnames";
import {generatePath, Link, useNavigate, useParams} from "react-router-dom";
import {singlePopOver, useState} from "../../components/PopOverHoC";
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import ButtonSecondary from "../atoms/ButtonSecondary";
import {functionStatus} from "../../utils/constants";
import {getStatusChangePreCheck, remoteSetStatus, remoteUnlock} from "../../queries/function_detail";
import LoaderAnimation from "../atoms/LoaderAnimation";
import {Provider} from "react-redux";
import Modal from "react-modal";
import Icon from "../atoms/Icon";
import {IconButton} from "../molecules/IconButton";
import {mutate} from 'swr';
import {PreloadAndDisplay} from "./SelectedFunctions";
import Notification from "../molecules/Notification";
import SVG from "../../utils/SVG";
import i18n from "../../utils/i18n";
import {EditButton} from "../../components/Banners";
import PrintOrientationSelector from "../../components/printOrientationSelector";
import {DescriptionSelector} from "../../components/Description";

export const FunctionStatusNavigationPatternState = {
    title: "Beschrijving",
    status: "Te toetsen",
    statusIdentifier: "to-counter",
    quickviews: [
        {
            identifier: "description",
            title: i18n.t("Beschrijving"),
            noMargin: true,
        },
        {
            identifier: "grading",
            title: i18n.t("Gradering"),
            noMargin: true,
        },
        {
            identifier: "scoring",
            title: i18n.t("Waardering"),
        },
        {
            identifier: "indeling",
            title: i18n.t("Indeling"),
        },
    ],
    quickview: 'description',

    functionDetail: {
        description_status: 'to-counter',
    },
    current_view: {
        identifier: "grading",
        title: i18n.t("Gradering"),
        noMargin: true
    },
}

function StatusChangePreCheck(props) {

    const {t, i18n} = useTranslation();
    const {data: preCheckDetails} = getStatusChangePreCheck(props.functionId, props.status, props.type)
    let navigate = useNavigate();
    const typeText = {
        description_status: t("Weet je zeker dat je de status van de beschrijving wilt wijzigen naar"),
        scoring_status: t("Weet je zeker dat je de status van de indeling wilt wijzigen naar"),
    }

    const confirm = async () => {
        let result = await remoteSetStatus(props.functionId, props.status, props.type);

        result = result?.data;

        if (result?.id) {
            navigate(generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                client_id: result?.client?.id,
                project_id: result?.project?.id,
                function_id: result?.id,
            }));
        } else {
            // check if both (new) statusses are 'determined', if so we must force an unlock
            if (result?.scoring_status === "determined" && result?.description_status === "determined") {
                let unlock = await remoteUnlock(props.functionId);
            }

            // we must invalidate function details here
            let key = `/api/functions/${props.functionId}/`;
            mutate(key)
            props.close();
        }
    }


    if (!preCheckDetails) {
        return <Modal><LoaderAnimation/></Modal>
    }

    // console.log(preCheckDetails);

    const toCheck = [
        {
            identifier: "description_determined",
            title: t("Beschrijving"),
            type: "success",
            description: {
                warning: t("Beschrijving niet vastgesteld of geen reden voor afwezigheid opgegeven"),
                success: t("Beschrijving is ingevuld en vastgesteld"),
            }
        },
        {
            identifier: "scoring_incomplete",
            type: "success",
            title: t("Indeling"),
            description: {
                warning: t("Niet alle scores zijn ingevuld of sommige scores zijn mogelijk verkeerd"),
                success: t("Alle scores zijn ingevuld en controles zijn goed doorlopen")
            }
        },
        {
            identifier: "scoring_no_references",
            type: "success",
            title: t("Onderbouwing"),
            description: {
                warning: t("Er is geen functiegroep gekozen of er zijn geen referentiefuncties gekozen"),
                success: t("Referentiefuncties zijn toegevoegd als onderbouwing"),
            }
        },
        {
            identifier: "re_open",
            title: t("Functie heropenen"),
            type: "warning",
            description: {
                warning: t("Deze wijziging zal een nieuwe versie maken")
            }
        },
        {
            identifier: "expired",
            title: t("Functie laten vervallen"),
            type: "warning",
            description: {
                warning: t("Deze wijziging zal de functie laten vervallen en onbruikbaar maken")
            }
        },
    ]

    return (
        <Modal isOpen={true} className={"modal modal--medium"} overlayClassName={"modal-background"}
               bodyOpenClassName={"has-modal"}>
            <div className={"modal-header"}>
                <div className={"modal-header__top"}>
                    <h2 className={"modal-header__title"}>{t("Status wijzigen")}</h2>
                    <ul className={"modal-header__controls"}>
                        <li className={"modal-header__control"}>
                            <IconButton icon={"close"} onClick={() => props.close()}/>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={"modal__body"}>
                <Notification type={"warning"} sizeModifier={"large"}
                              text={t("U staat op het punt de status van dit onderdeel te veranderen.")}/>
                {/*<Notification type={"warning"} sizeModifier={"large"} text={t("U staat op het punt de status van dit onderdeel te veranderen. Let extra goed op de volgende onderdelen")}/>*/}
                {/*<ul className={"check-list"}>*/}
                {/*    {toCheck.map(item => (*/}
                {/*        <li key={item.identifier} className={cx('check-list__item', {*/}
                {/*            'check-list__item--warning': item.type === 'warning',*/}
                {/*            'check-list__item--success': item.type === 'success'*/}
                {/*        })}>*/}
                {/*            <Icon icon={item.type} sizeModifier={"small"} className={"check-list__icon"} />*/}
                {/*            {item.description[item.type]}*/}
                {/*        </li>*/}
                {/*    ))}*/}
                {/*</ul>*/}
                <p className={"paragraph"}>
                    {t(typeText[props.type])}: <strong>{functionStatus[props.status]}</strong>?
                </p>
            </div>
            <div className="modal-footer">
                <ul className={"modal__buttons"}>
                    <li className={"modal__button"}>
                        <ButtonSecondary
                            onClick={props.close}>
                            {t("Annuleren")}
                        </ButtonSecondary>
                    </li>
                    <li className={"modal__button"}>
                        <ButtonPrimary
                            onClick={confirm}
                            data-cy={"function-status-submit-button"}
                        >
                            {t("Bevestigen")}
                        </ButtonPrimary>
                    </li>
                </ul>
            </div>

        </Modal>
    )
}


function StatusSelectorDisplay(props) {
    let {function_id, project_id} = useParams();
    function_id = parseInt(function_id);

    const full_identifier = 'StatusSwitcher-' + function_id;

    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [popOverActive, setPopOverActive] = useState(props, full_identifier, false);
    const [precheckStatus, setPrecheckStatusActive] = useState(false);

    const {t, i18n} = useTranslation();

    if (props?.canEdit !== true) {
        return (
            <div className={cx("dropdown-toggle", "dropdown-toggle--disabled")}>
                <Pip className="status-pip--with-text" type={props.status.identifier}/>
                {props.status.label}
            </div>
        )
    }


    return (
        <>
            <div className={cx("dropdown-toggle")}
                 data-cy={`${props?.["data-cy"]}-dropdown-toggle`}
                 onClick={() => setPopOverActive(!popOverActive, full_identifier)}
            >
                <Pip className="status-pip--with-text" type={props.status.identifier}/>
                {props.status.label}
                <Icon icon={"angle-" + (popOverActive ? "up" : "down")} sizeModifier={"xsmall"}
                      className={"dropdown-toggle__icon"}/>
            </div>
            {precheckStatus && (
                <StatusChangePreCheck
                    functionId={function_id}
                    status={precheckStatus}
                    type={props.status.type}
                    close={() => {
                        setPrecheckStatusActive(false);
                    }}
                />
            )}
            {popOverActive && (
                <div className={cx("dropdown", {"dropdown--active": popOverActive})}>
                    <ul>
                        {Object.entries(functionStatus).filter(obj => obj[0] !== 'archived').map((obj, idx) => {
                            let [key, val] = obj;
                            return (
                                <li className={"dropdown-field"} key={key}
                                    onClick={() => {
                                        setPrecheckStatusActive(key);
                                        setPopOverActive(false);
                                    }}
                                    data-cy={key}
                                >
                                    <div className={"dropdown-field__label"}>
                                        <Pip className="status-pip--with-text" type={key}/>{t(val)}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )}
        </>
    )
}

export const StatusSelector = singlePopOver(StatusSelectorDisplay);

export default function FunctionStatusNavigation(props) {
    const {t, i18n} = useTranslation();

    return (
        <div className={"function-status-navigation"}>
            <div className={"function-status-navigation__left"}>
                <div className={"tab-navigation"}>
                    {props.quickviews.map((quickviewItem, idx) => {
                        return (
                            <div className={"tab-navigation__item"} key={quickviewItem.identifier}>
                                <Link to={`${quickviewItem.identifier}`}>
                                    <button
                                        className={cx("tab-navigation__tab", {"tab-navigation__tab--active": quickviewItem.identifier === props?.current_view?.identifier})}
                                        key={quickviewItem.identifier}
                                        data-cy={quickviewItem.identifier}>{t(quickviewItem.title)}</button>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className={"function-status-navigation__right"}>
                <EditButton editState={props.editState} acquireLock={props.acquireLock} unlock={props.unlock}/>
            </div>
        </div>
    )
}
