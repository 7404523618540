import axios from "axios";
import useSWR from "swr";

// function to get the Scoring Structure for a specific method
export function getStructure(method_id=false, type=false) {

    let url = `/api/methods/${method_id}/score/?type=${type}`;

    const fetcher = async (url) => {
        let res = await axios.get(url).then(res => res.data);
        let structureIdToDomain = {};

        if(res) {
            res.map( domain => {
                domain.children.map( section => {
                    section.children.map(structure => {
                        structureIdToDomain[structure.pk] = domain.pk;
                    })
                } )
            });
        }

        return {
            domains: res,
            structureIdToDomain,
        }
    }

    const {data, error} = useSWR(() => method_id && type ? url : false, fetcher, {
        dedupingInterval: 3600000,    // ignores additional calls with the same key (prevents hammering) 3600 sec = 1 hour
    });

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
    }
}


// function to get the scores for a specific function
export function getScores(function_id) {

    let url = `/api/functions/${function_id}/score/`;

    const {data, error, mutate} = useSWR(() => function_id ? url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}
