export const ADD_CHANGE = 'ADD_CHANGE';

export function addChange(form, name, value) {
    return {
        type: ADD_CHANGE,
        form,
        name,
        value
    }
}


