import React from 'react';
import cx from "classnames";
import Icon from "../atoms/Icon";
import i18n from "../../utils/i18n";

export const IconButtonPatternState = {
    icon: 'hamburger',
    type: '',
    title: i18n.t(''),
    onClick: () => console.log('Clicked!'),
    disabled: false,
    plMod: {
        subtle: {
            active: 'false',
            sizeModifier: 'medium',
        },
    }
}

export function IconButton(props) {

    return (
        <button
            onClick={ e => { e.preventDefault(); props?.onClick && props.onClick(e); }}
            className={cx(
                'icon-button',
                {'icon-button--border': props.border === 'true'},
                {'icon-button--active': props.active === 'true'}, // active button is blue
                {'icon-button--medium': props.sizeModifier === 'medium'}, // denotes info on size ('smaller icon')
                {'icon-button--small': props.sizeModifier === 'small'}, // denotes info on size ('smaller icon')
                {'icon-button--notification': props.notification === 'true'},
                props.className
            )}
            disabled={props.disabled}
            title={props?.title}
            data-cy={props?.["data-cy"]}>

            <Icon
                icon={props.icon}
                sizeModifier={cx(
                    {'small': props.sizeModifier === 'medium'}, // smaller size means smaller icon
                    {'xsmall': props.sizeModifier === 'small'} // smaller size means smaller icon
                )}
            />

        </button>
    )
}

export default React.memo(IconButton)
