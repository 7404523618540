import axios from "axios";
import React from "react";
import {generatePath} from "react-router-dom";
import {useAuth} from "./useAuth";

export const helpText = {
    "beschrijver": "De 'beschrijver' beschrijft de functie of heeft de functie beschreven.",
    "toepasser": "De 'toepasser' waardeert de functie, deelt of past deze in en onderbouwt - indien van toepassing - de indeling of inpassing.",
    "toetser": "De 'toetser' toetst de waardering, indeling onderbouwing of inpassing aan de hand van functiebeschrijving en bepaalt of de waardering conform de normering van de toegepaste methode is.",
}

export const functionStatus = {
    "concept":      "Concept",
    "to-counter":   "Te toetsen",
    "is-countered": "Getoetst",
    "determined":   "Vastgesteld",
    "expired":      "Vervallen",
    "archived":     "Gearchiveerd"
}

export const projectStatus = {
    "concept":      "Concept",
    "determined":   "Vastgesteld",
    "expired":      "Vervallen",
    "archived":     "Gearchiveerd"
}

export const clientStatus = {
    "active":       "Actief",
    "expired":      "Vervallen",
    "archived":     "Gearchiveerd",
    "error":        "Technische fout"
}

export const environmentStatus = {
    "active":       "Actief",
    "inactive":     "Nog niet actief",
    "closed":       "Verlopen",
    "error":        "Fout",
    "starting":     "Opstarten"
}

export const MNTLetters = [
    'X',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
]

export const BKNScores = [
    '0',
    'A',
    'b',
    'B',
    'c',
    'C',
    'd',
    'D',
];

export const FUWATERScores = [
    '1',
    '2',
    '3',
    '4',
    '5',
]

export const MNTDomains = [
    {
        number: 1,
        identifier: 'domain1',
        short: 'K',
    },
    {
        number: 2,
        identifier: 'domain2',
        short: 'P',
    },
    {
        number: 3,
        identifier: 'domain3',
        short: 'S',
    },
    {
        number: 4,
        identifier: 'domain4',
        short: 'R',
    },
    {
        number: 5,
        identifier: 'domain5',
        short: 'U',
    },
    {
        number: 6,
        identifier: 'domain6',
        short: 'M',
    },
    {
        number: 7,
        identifier: 'domain7',
        short: 'O',
    },
    {
        number: 8,
        identifier: 'domain8',
        short: 'O',
    },
    {
        number: 9,
        identifier: 'domain9',
        short: 'B',
    }
]

export const MNTDomainsGte = [
    {
        number: 1,
        identifier: 'domain1__gte',
        short: 'K',
    },
    {
        number: 2,
        identifier: 'domain2__gte',
        short: 'P',
    },
    {
        number: 3,
        identifier: 'domain3__gte',
        short: 'S',
    },
    {
        number: 4,
        identifier: 'domain4__gte',
        short: 'R',
    },
    {
        number: 5,
        identifier: 'domain5__gte',
        short: 'U',
    },
    {
        number: 6,
        identifier: 'domain6__gte',
        short: 'M',
    },
    {
        number: 7,
        identifier: 'domain7__gte',
        short: 'O',
    },
    {
        number: 8,
        identifier: 'domain8__gte',
        short: 'O',
    },
    {
        number: 9,
        identifier: 'domain9__gte',
        short: 'B',
    }
]

export const MNTDomainsLte = [
    {
        number: 1,
        identifier: 'domain1__lte',
        short: 'K',
    },
    {
        number: 2,
        identifier: 'domain2__lte',
        short: 'P',
    },
    {
        number: 3,
        identifier: 'domain3__lte',
        short: 'S',
    },
    {
        number: 4,
        identifier: 'domain4__lte',
        short: 'R',
    },
    {
        number: 5,
        identifier: 'domain5__lte',
        short: 'U',
    },
    {
        number: 6,
        identifier: 'domain6__lte',
        short: 'M',
    },
    {
        number: 7,
        identifier: 'domain7__lte',
        short: 'O',
    },
    {
        number: 8,
        identifier: 'domain8__lte',
        short: 'O',
    },
    {
        number: 9,
        identifier: 'domain9__lte',
        short: 'B',
    }
]

export const FuwaterDomains = [
    {
        number: 1,
        identifier: 'domain1',
        short: 'A',
    },
    {
        number: 2,
        identifier: 'domain2',
        short: 'B',
    },
    {
        number: 3,
        identifier: 'domain3',
        short: 'C',
    },
    {
        number: 4,
        identifier: 'domain4',
        short: 'D',
    },
    {
        number: 5,
        identifier: 'domain5',
        short: 'E',
    },
    {
        number: 6,
        identifier: 'domain6',
        short: 'F',
    },
    {
        number: 7,
        identifier: 'domain7',
        short: 'G',
    },
    {
        number: 8,
        identifier: 'domain8',
        short: 'H',
    },
    {
        number: 9,
        identifier: 'domain9',
        short: 'I',
    },
    {
        number: 10,
        identifier: 'domain10',
        short: 'J',
    },
    {
        number: 11,
        identifier: 'domain11',
        short: 'K',
    },
    {
        number: 12,
        identifier: 'domain12',
        short: 'L',
    },
    {
        number: 13,
        identifier: 'domain13',
        short: 'M',
    },
    {
        number: 14,
        identifier: 'domain14',
        short: 'N',
    },
]

export const FuwaterDomainsGte = [
    {
        number: 1,
        identifier: 'domain1__gte',
        short: 'A',
    },
    {
        number: 2,
        identifier: 'domain2__gte',
        short: 'B',
    },
    {
        number: 3,
        identifier: 'domain3__gte',
        short: 'C',
    },
    {
        number: 4,
        identifier: 'domain4__gte',
        short: 'D',
    },
    {
        number: 5,
        identifier: 'domain5__gte',
        short: 'E',
    },
    {
        number: 6,
        identifier: 'domain6__gte',
        short: 'F',
    },
    {
        number: 7,
        identifier: 'domain7__gte',
        short: 'G',
    },
    {
        number: 8,
        identifier: 'domain8__gte',
        short: 'H',
    },
    {
        number: 9,
        identifier: 'domain9__gte',
        short: 'I',
    },
    {
        number: 10,
        identifier: 'domain10__gte',
        short: 'J',
    },
    {
        number: 11,
        identifier: 'domain11__gte',
        short: 'K',
    },
    {
        number: 12,
        identifier: 'domain12__gte',
        short: 'L',
    },
    {
        number: 13,
        identifier: 'domain13__gte',
        short: 'M',
    },
    {
        number: 14,
        identifier: 'domain14__gte',
        short: 'N',
    },
]

export const FuwaterDomainsLte = [
    {
        number: 1,
        identifier: 'domain1__lte',
        short: 'A',
    },
    {
        number: 2,
        identifier: 'domain2__lte',
        short: 'B',
    },
    {
        number: 3,
        identifier: 'domain3__lte',
        short: 'C',
    },
    {
        number: 4,
        identifier: 'domain4__lte',
        short: 'D',
    },
    {
        number: 5,
        identifier: 'domain5__lte',
        short: 'E',
    },
    {
        number: 6,
        identifier: 'domain6__lte',
        short: 'F',
    },
    {
        number: 7,
        identifier: 'domain7__lte',
        short: 'G',
    },
    {
        number: 8,
        identifier: 'domain8__lte',
        short: 'H',
    },
    {
        number: 9,
        identifier: 'domain9__lte',
        short: 'I',
    },
    {
        number: 10,
        identifier: 'domain10__lte',
        short: 'J',
    },
    {
        number: 11,
        identifier: 'domain11__lte',
        short: 'K',
    },
    {
        number: 12,
        identifier: 'domain12__lte',
        short: 'L',
    },
    {
        number: 13,
        identifier: 'domain13__lte',
        short: 'M',
    },
    {
        number: 14,
        identifier: 'domain14__lte',
        short: 'N',
    },
]

export const VBalansDomains = FuwaterDomains;

export const optionsFetcher = (url) => {
    return axios.options(url).then(res => res.data);
};

export const loadIfAllowed = (state) => {

    if(state?.pagination?.[state?.type]?.loadAllAllowed && state?.loadAll) {
        return "&page_size=10000";
    }
    return '';
};

export const buildFilter = (dict) => {
    return '&' + Object.entries(dict).map( (obj, idx) => {
        const [key, value] = obj;
        // return Array.isArray(value) ? value.map( o => `${key}=${o}`).join('&') : key+"="+value;
        return key+"="+encodeURIComponent(value);
    }).join('&');
};

export const buildOrder = (dict) => {
    return  dict ? (dict?.columnIdentifier !== "" ? (dict?.direction || '') + (dict?.columnIdentifier) : "") : "";
};




export const FunctionSource = {
    cao: {
        short: "CAO",
        class: "cao",
    },
    project: {
        short: ""
    },
    based_on: {
        short: "",
    },
    uses: {
        short: "",
    },
    method: {
        short: "METHODE",
    },
    example: {
        short: "VOORBEELD",
        class: "example"
    }
}


export const isFuwaterVbalans = (methodId) => {

    // 42 FuwaProv + Inpassen
    // 40 FuwaProv
    // 39 Fuwater
    // 36 VBalans+
    // 35 VBalans klein
    // 42 FUWA PROV + inpassen
    // 43 Fuwater + inpassen
    // 44 VBalans+ + inpassen
    // 45 VBalans klein + inpassen
    return(window._v_methods.includes(methodId));
}

export const isBakkenistMNT = (methodId) => {
    // 16 MNT
    // 29 Bakkenist analytisch
    // 41 Bakkenist analytisch + MNT + Inpassen
    return(window._b_methods.includes(methodId));
}

export const SkeletonDefaults = {
    td: "overview-table__cell",
    tr: "overview-table__row",
}

export function typeToName(type) {
    return type === 'company' ? 'Bedrijfseigenfunctie' : type === 'reference' ? 'Referentiefunctie' : 'Ingepaste functie'
}
export function typeToLetter(type) {
    return type === 'company' ? 'B': (type === 'reference') ? 'R': (type === 'match') ? 'I' : '-';
}

export const language_options = [
    {value: "nl", display_name: "Nederlands"},
    {value: "en", display_name: "Engels"}
];
export const months = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December"
];

export const function_type_options_fun = ({auth, t, projectId}) => {
    const canViewReferenceFunctionPoints = auth.hasPermission('base.view_reference_function_points', projectId, 'project');
    const canViewCompanyFunctionPoints = auth.hasPermission('base.view_company_function_points', projectId, 'project');
    const canViewReferenceFunctionScoring = auth.hasPermission('base.view_reference_function_scoring', projectId, 'project');
    const canViewCompanyFunctionScoring = auth.hasPermission('base.view_company_function_scoring', projectId, 'project');
    const canViewReferenceFunctionScoringAsBasic = auth.hasPermission('base.view_reference_function_scoring_as_basic', projectId, 'project');
    return [
        {
            value: "meta",
            display_name: t("Meta-informatie"),
            reference: true,
            company: true,
            match: true
        },
        {
            value: "function_description",
            display_name: t("Beschrijving"),
            reference: true,
            company: true,
            match: true
        },
        {
            value: "company_group_advice_bar",
            display_name: t("Functiegroep indicatie (balk)"),
            reference: false,
            company: true,
            match: false
        },
        {
            value: "company_group_advice",
            display_name: t("Indelingsadvies"),
            reference: false,
            company: true,
            match: false
        },
        {
            value: "confirmed_group",
            display_name: t("Indeling - keuze"),
            reference: false,
            company: true,
            match: true
        },
        {
            value: "reference_functions",
            display_name: t("Gekozen referentiefuncties"),
            reference: false,
            company: true,
            match: true
        },
        {
            value: "scoring",
            display_name: t("Waardering of gradering"),
            reference: true,
            company: true,
            match: false
        },
        {
            value: "scoring_as_basic",
            display_name: t("Gradering als waardering"),
            reference: true,
            company: false,
            match: false
        },
        {
            value: "reference_scoring_compact",
            display_name: t("Gradering - A4 formaat"),
            reference: true,
            company: false,
            match: false
        },
        {
            value: "motivation",
            display_name: t("Onderbouwing / toelichting"),
            reference: true,
            company: true,
            match: true
        },
        {
            value: "reference_points",
            display_name: t("Toon punten bij referentiefuncties"),
            reference: true,
            company: true,
            match: true
        },
        {
            value: "company_points",
            display_name: t("Toon punten bij bedrijfseigenfuncties"),
            reference: true,
            company: true,
            match: true
        },
    ].filter(o => {
        switch(o.value) {
            case "scoring":
                return canViewReferenceFunctionScoring || canViewCompanyFunctionScoring;
            case "scoring_as_basic":
                return canViewReferenceFunctionScoringAsBasic
            case "reference_scoring_compact":
                return canViewReferenceFunctionScoring
            case "reference_points":
                return canViewReferenceFunctionPoints
            case "company_points":
                return canViewCompanyFunctionPoints
        }
        return true;
    })
}

export const adminTabsProps = {
    tabs: [
        {url: "/environment", displayName: "Demo-omgevingen"},
        {url: "/methods", displayName: "Methodeteksten"},
        {url: "/default-text", displayName: "Standaardteksten"},
        {url: "/login-admin", displayName: "Log-in pagina"}
    ],
    title: "Beheer"
}

export const clientTabsProps = (client_id) => {
    const auth = useAuth();

    const canChangeUser = auth.checkAccessList('base.change_authuser', [
        ['client_id', 'client']
    ])
    const canEditClient = auth.checkAccessList('base.change_client', [
        ['client_id', 'client']
    ])
    const canChangeClientfile = auth.checkAccessList('base.change_clientfile', [
        ['client_id', 'client'],
    ])

    return [
        {allowed: () => canEditClient, url: generatePath('/client/:client_id', { client_id: client_id}), displayName: "Projecten"},
        {allowed: () => canChangeUser, url: generatePath('/client/:client_id/user', { client_id: client_id}), displayName: "Gebruikers"},
        {allowed: () => canChangeUser, url: generatePath('/client/:client_id/usergroup', { client_id: client_id}), displayName: "Gebruikersgroepen"},
        {allowed: () => canChangeClientfile, url: generatePath('/client/:client_id/files', { client_id: client_id }), displayName: "Klantdocumenten en -bestanden"}
    ]
}
export const projectTabsProps = (client_id, project_id, auth, projectDetail) => {
    const permLevels = [
        [project_id, 'project'],
        [client_id, 'client'],
    ]

    const canChangeCollectiveLabourAgreement = auth.checkAccessList("base.change_collective_labour_agreement", permLevels);
    const canChangeIfCollectiveLabourAgreement = canChangeCollectiveLabourAgreement || projectDetail?.is_collective_labour_agreement === false;

    return [
        {url: generatePath('/client/:client_id/project/:project_id/function', { client_id: client_id, project_id: project_id}), displayName: "Functieoverzicht"},
        {url: generatePath('/client/:client_id/project/:project_id/projects', { client_id: client_id, project_id: project_id}), displayName: "Gekoppelde projecten"},
        {allowed: () => auth.checkAccessList('base.view_external_access', permLevels), url: generatePath('/client/:client_id/project/:project_id/users', { client_id: client_id, project_id: project_id}), displayName: "Externe toegang"},
        {allowed: () => auth.checkAccessList('base.change_projectfile', permLevels) && canChangeIfCollectiveLabourAgreement, url: generatePath('/client/:client_id/project/:project_id/files', { client_id: client_id, project_id: project_id}), displayName: "Projectdocumenten en -bestanden"}
    ]
}

export const userTabsProps = (client_id, user_id) => {
    return [
        {url: generatePath('/client/:client_id/user/:user_id/', {client_id: client_id, user_id: user_id}), displayName: "Toegankelijke projecten"},
        {url: generatePath('/client/:client_id/user/:user_id/roles', {client_id: client_id, user_id: user_id}), displayName: "Toegewezen rollen"},
    ]
}
