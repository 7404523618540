import React from 'react';
import {getUser} from "../queries/user";
import cx from "classnames";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useTranslation} from "react-i18next";

export function UserBadge(props) {

    const user = getUser(props.userId);

    if(!user?.data) {
        return (
            <LoaderAnimation small={true}/>
        )
    }

    return (
        <>
            <div className={cx("user-badge", props?.className)}
                 onClick={props?.onClick}
                 data-cy={props["data-cy"]}
            >
                <span className={"user-badge__initials"}>{user?.data?.initials}</span>
            </div>
        </>
    )

}


export default function UserName(props) {
    const {t, i18n} = useTranslation();
    const user = getUser(props.userId);

    if(!user?.data) {
        return (
            <span>{t("Laden...")}</span>
        )
    }

    return (
        <>
            {user?.data?.get_full_name}
        </>
    )
}
