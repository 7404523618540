import Overview from "../Overview";
import {ClientProjectOverviewDefinition} from "../Overviews/ClientProjectOverview";
import React, {useRef, useState} from "react";
import Quickview from "../Quickview";
import {useTranslation} from "react-i18next";
import SimpleMetaDisplay from "../../patterns/organisms/SimpleMeta";
import {getProjectGroup, remoteAddProjectsToGroup, remoteRemoveProjectFromGroup} from "../../queries/project_group";
import {useParams} from "react-router-dom";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import Drawer from "../Drawer";
import {UserOverviewDefinition} from "../Overviews/UserOverview";
import {useSelector} from "react-redux";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import {useAuth} from "../../utils/useAuth";

export function ProjectGroupDetailScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();
    const {project_group_id} = useParams();
    const [selectProjects, setSelectProjects] = useState(false);
    const [removeProjects, setRemoveProjects] = useState(false);

    // create a new ref which can be passed to Overview and which will contain a reference to the overview's mutate
    const overviewRef = useRef(false)

    const {data: projectGroupDetail}= getProjectGroup(project_group_id);

    const canAddProjectgroup = auth.hasPermission("base.add_projectgroup");
    const overviewIdentifier = "select-project-overview";
    const identKey = {
        // project_group_id: project_group_id
        project_groups__not__id: project_group_id,
    }
    const stateIdentifier = {
        identifier: overviewIdentifier,
        ...identKey,
    };

    const bulkState = useSelector(state => state.OverviewState?.[JSON.stringify(stateIdentifier)]?.bulk);
    if(!projectGroupDetail) {
        return <LoaderAnimation/>
    }

    return (
        <>
            <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
                <div className={"content-container"}>
                    <SimpleMetaDisplay name={projectGroupDetail?.name}
                                       // sub_name={t("Projectgroep")}
                    />
                </div>
            </div>

            {removeProjects && (
                <ConfirmModal
                    key={'remove-project-from-projectgroup'}
                    title={t("Verwijder project uit projectgroep")}
                    body={t("Weet je zeker dat je het volgende project wilt verwijderen uit de projectgroep") + " \"" + projectGroupDetail.name  + "\""}
                    subject={`${removeProjects.name}` }
                    confirmModal={async () => {
                        await remoteRemoveProjectFromGroup(project_group_id, [removeProjects.id]);
                        overviewRef.current.mutate();
                    }}
                    closeModal={() => setRemoveProjects(false)} />
            )}

            {selectProjects && (
                <>
                    <Drawer
                        identifier={"select-project"}
                        title={t("Selecteer projecten")}
                        readOnlyVal={obj => obj.name}
                        isForm={false}
                        noButton={true}
                        noReset={true}
                        initialOpen={true}

                        action={{
                            title: t("Toevoegen"),
                            Component: props => {
                                return (
                                    <a
                                        className={"button button--primary"}
                                        href={"#"} onClick={async e => {
                                        e.preventDefault();
                                        await remoteAddProjectsToGroup(project_group_id, Object.entries(bulkState).filter(o => o[1] === true ).map(o => parseInt(o[0])));
                                        overviewRef.current.mutate();
                                        props.onClick();
                                    }}>{t("Geselecteerde projecten toevoegen")}</a>
                                )
                            }
                        }}

                        reset={() => {
                            // props.form.change('project_id', '');
                        }}

                        onClick={ async (obj) => {
                            // await remoteAddProject(project_id, obj.id);
                            // getProjectDetail.mutate();
                        }}

                        closeCallback={() => setSelectProjects(false) }

                        contentComponent={
                            <Overview
                                identifier={overviewIdentifier}
                                identKey={identKey}
                                contextData={{
                                    location,
                                }}
                                definition={{
                                    ...ClientProjectOverviewDefinition,
                                    identifier: overviewIdentifier,
                                    interactive: false,
                                    primary: {},
                                    bulkEnabled: true,
                                    noHeader: true,
                                    noSearch: true,
                                    noContainer: true,
                                }}
                            />
                        }
                    />
                </>
            )}

            <div className={"content-container"}>
                <Quickview>

                    <Overview
                        identifier={"projectgroup-projects"}
                        identKey={{project_group_id}}
                        ref={overviewRef}
                        quickview={{
                            identifier: "projectgroup-projects",
                            name: t("Gekoppelde projecten")
                        }}
                        contextData={{
                            canAddProjectgroup,
                            removeProject: o => {
                                setRemoveProjects(o);
                            }
                        }}
                        definition={{
                            ...ClientProjectOverviewDefinition,
                            interactive: true,
                            identifier: 'projectgroup-projects',
                            columns: ClientProjectOverviewDefinition.columns.filter(c => c.identifier === 'action' && canAddProjectgroup || c.identifier !== 'action').filter(c => c.identifier !== 'source_project__name'),
                            primary: {
                                allowed: canAddProjectgroup || false,
                                title: t("Projecten toevoegen"),
                                identifier: 'add-projects',
                                action: () => setSelectProjects(true)
                            },
                        }}
                    />
                </Quickview>
            </div>
        </>
    )
}
