import React, {useEffect, useState, useMemo} from "react";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";


export default function Quickview(props) {

    const [ quickview, setQuickview] = useState(false);
    const [ quickviews, setQuickviews] = useState([]);

    let children = (props.children?.length >= 0 ? props.children : [ props.children ]).filter(o => !!o);

    useEffect(() => {

        const parsedViews = children.map(o => {
            if(o?.props.quickview && o?.props.quickview?.identifier && o?.props.quickview?.name) {
                return o.props.quickview;
            } else {
                console.log("quickview found, but insufficient information provided (is identifier or name missing?)")
            }
        });
        setQuickviews(parsedViews);
        setQuickview(parsedViews[0].identifier);

    },[]);


    if(!quickview) {
        return <LoaderAnimation/>
    }

    const currentQuickview = quickviews.find(o => o.identifier === quickview);

    return (
        <>
            {children.filter(o => o.props.quickview.identifier === currentQuickview.identifier).map((child, childIdx) => {
                return React.cloneElement(child, {
                    key: childIdx,
                    onClick: child?.props?.onClick || props?.onClick,
                    isDrawer: props?.isDrawer,
                    quickview: {
                        ...child.props.quickview,
                        quickviews,
                        quickview,
                        setQuickview,
                    }
                })
            })}
        </>
    )
}
