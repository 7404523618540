import axios from "axios";
import useSWR from "swr";
import {optionsFetcher} from "../utils/constants";

export function getProject(pk = false) {

    let url = `/api/projects/${pk}/`;

    const res = useSWR(() => pk ? url : false);

    const {data, error, mutate} = res;

    if (res?.error?.noAccess) {
        return {
            data: {
                noAccess: res.error.noAccess,
            }
        }
    }

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getProjectInsight(pk = false) {

    let url = `/api/projects/${pk}/insight/`;

    const res = useSWR(() => pk ? url : false);

    const {data, error, mutate} = res;

    if (res?.error?.noAccess) {
        return {
            data: {
                noAccess: res.error.noAccess,
            }
        }
    }

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getProjectOptions() {
    let url = `/api/projects/?options`;

    const {data, error, mutate} = useSWR(url, optionsFetcher);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

// Getting all projects to f.ex use as source project during Project creation
export function getProjects() {

    let url = `/api/projects/?page_size=1000`;


    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export async function remoteAddProject(pk, project_id) {

    if (!pk && !project_id) {
        return false;
    }

    return await axios.patch(`/api/projects/${pk}/add_project/`, {
        project_id,
    })
        .then(result => result);

}

export async function remoteSetFavorite(pk) {
    return await axios.patch(`/api/projects/${pk}/favorite/`, {});
}

export async function remoteUnsetFavorite(pk) {
    return await axios.patch(`/api/projects/${pk}/unfavorite/`, {});
}

export async function archiveProject(id) {
    return await axios.delete(`/api/projects/${id}/`);
}

export async function createProject(values, navigate) {
    await axios.post("/api/projects/", values)
        .then(result => {
            if (result.status === 201) {
                if (result.data?.id) {
                    navigate(`/client/${result.data.client.id}/project/${result.data.id}/function`);
                } else {
                    throw new Error("server-error-project-create", {cause: result});
                }
            } else {
                throw new Error("server-error-project-create", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}

export async function updateProject(values, navigate, project_id) {
    await axios.patch("/api/projects/" + project_id + "/", values)
        .then(result => {
            if (result.status === 200) {
                navigate(`../`);
            } else {
                throw new Error("server-error-project-edit", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}
