import React, {Fragment} from "react";
import Notification from "../molecules/Notification";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {withAccessRight} from "../../utils/withAccessHoC";
import {useTranslation} from "react-i18next";
import {getYear} from "date-fns";
import {createEnvironment, optionsEnvironment} from "../../queries/environments";
import {useNavigate, useParams} from "react-router-dom";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {CheckboxInput, Input} from "../atoms/Input";
import DatePickerFilter from "../../components/Overviews/Filters/DatePickerFilter";
import Drawer from "../../components/Drawer";
import Overview from "../../components/Overview";
import {ClientSelectionDefinition} from "../../components/Overviews/ClientSelection";
import {getClient} from "../../queries/client_detail";
import {ProjectSelectionDefinition} from "../../components/Overviews/ProjectSelection";
import {getRoles} from "../../queries/role";
import Select from "../atoms/Select";
import ButtonTertiary from "../atoms/ButtonTertiary";
import {IconButton} from "../molecules/IconButton";
import {getProject} from "../../queries/project_detail";

function EnvironmentCreateDisplay() {
    const {data: roles} = getRoles();
    const {client_id, project_id} = useParams();
    const client = getClient(client_id).data;
    const project = getProject(project_id).data;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {register, handleSubmit, setError, watch, control, clearErrors, formState: {errors}}
        = useForm({defaultValues: {client_id: client_id, project_id: project_id}});
    const onSubmit = () => async (values) => {
        let multipleUsers = [];
        if(values.count){
            for(let i = 0; i < values.count; i++) {
                let formattedNumber = i < 10 ? "0" + (i + 1) : i + 1
                multipleUsers = [...multipleUsers, {email: `demo${formattedNumber}@fuwa.nl`, password: `${values?.password_prefix}${formattedNumber}`, first_name: `demo${formattedNumber}`, last_name: "demo", roles: [{"role_id": 44},{"role_id": values.role_id}]}]
            }
        }

        const submitUsers = values.users.map((user, i) => {
            let formattedNumber = i < 10 ? "0" + (i + 1) : i + 1
            return {...user, first_name: `gebruiker${formattedNumber}`, last_name: "demo", roles: [{"role_id": 44},{"role_id": user.role_id}]}
        })

        const submitValues = {...values, domain: values?.domain?.toLowerCase(), users: [...submitUsers, ...multipleUsers]}
        await createEnvironment(submitValues, navigate);
    }

    const {data} = optionsEnvironment();

    const {fields, append, remove} = useFieldArray({
        control,
        name: "users"
    });

    const roles_options = roles?.results.filter(role => role.id === 11 || role.id === 12)

    let formattedCount = watch("count") < 10 ? "0" + watch("count") : watch("count");

    return (
        <>
            <h1 className={"form-container__title"}>{t("Demo-omgeving aanmaken")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if(error.cause.message === "server-error-environment-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Basisgegevens")}</legend>
                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Naam")}
                            placeholder={t("Naam")}
                            register={register}
                            required
                            error={errors.name}
                            data-cy={"name-input"}
                        />
                    </div>
                    <div className="input-group">
                        <div className={"input-group__before"}>{t(data?.environment?.prefix)}</div>
                        <Input
                            name={"domain"}
                            type={"text"}
                            id={"domain"}
                            label={t("Domeinnaam")}
                            placeholder={t("Domeinnaam")}
                            helpText={t('De domeinnaam moet met een letter beginnen, mag alleen letters, cijfers en “-” (minteken) bevatten. Na het aanmaken kan de domeinnaam niet meer worden aangepast.')}
                            register={register}
                            required
                            error={errors.domain}
                            data-cy={"domain-input"}
                            maxLength={15}
                            pattern={{
                                value: /^[a-z]{1}[a-z0-9\-]+$/i,
                                message: "De domeinnaam is ongeldig"
                            }}
                        />
                        <div className={"input-group__after"}>{t(data?.environment?.suffix)}</div>
                    </div>

                    <div className="input-group">
                        <Controller name={"start_date"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"start_date"}
                                            type={"text"}
                                            id={"start_date"}
                                            label={t("Startdatum")}
                                            placeholder={t("Startdatum")}
                                            maxDate={watch("end_date")}
                                            endYear={getYear(new Date()) + 11}
                                            onChange={onChange}
                                            selected={value}
                                        />
                                    )}
                        />
                    </div>

                    <div className="input-group">
                        <Controller name={"end_date"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"end_date"}
                                            type={"text"}
                                            id={"end_date"}
                                            label={t("Eind Datum")}
                                            placeholder={t("Eind Datum")}
                                            minDate={watch("start_date")}
                                            endYear={getYear(new Date()) + 11}
                                            onChange={onChange}
                                            selected={value}
                                        />
                                    )}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Toegang")}</legend>

                    <div className={"input-group"}>
                        <Controller name={"client_id"} control={control}
                                    rules={{required: t("Dit is een verplicht veld")}}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer Klant")}
                                            buttonText={t("Zoek klant")}
                                            label={t("Klant")}
                                            initialReadOnlyVal={client?.name}
                                            readOnlyVal={obj => obj.name}
                                            placeholder={t("Geen klant gekozen")}
                                            disabled={false}
                                            id={"client-drawer"}
                                            error={errors.client_id}
                                            required

                                            isForm={true}

                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-client-overview"}
                                                    identKey={{
                                                        client_id: watch("client_id"),
                                                    }}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ClientSelectionDefinition}
                                                />
                                            }
                                        />)}
                        />
                    </div>

                    <div className={"input-group"}>
                        <Controller name={"project_id"} control={control}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer Project")}
                                            buttonText={t("Zoek project")}
                                            label={t("Project")}
                                            initialReadOnlyVal={project?.name}
                                            readOnlyVal={obj => obj.name}
                                            placeholder={t("Geen project gekozen")}
                                            isForm={true}
                                            disabled={false}
                                            id={"project-id-drawer"}
                                            error={errors.project_id}

                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-project-overview"}
                                                    identKey={{
                                                        client_id: client_id,
                                                    }}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ProjectSelectionDefinition}
                                                    initialFilters={{
                                                        status__in: ['concept', 'determined', 'expired']
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Gebruikers toevoegen")}</legend>

                    {fields.map((link, index) => (
                        <Fragment key={index}>
                            <div className={"input-group"}>
                                <Input
                                    name={`users.${index}.email`}
                                    type={"text"}
                                    id={"email"}
                                    label={t("E-mailadres")}
                                    data-cy={"email-input"}
                                    register={register}
                                    error={errors.users?.[index]?.email}
                                    required
                                />
                            </div>
                            <div className={"input-group"}>
                                <Input
                                    name={`users.${index}.password`}
                                    type={"text"}
                                    id={"password"}
                                    label={t("Wachtwoord")}
                                    data-cy={"password-input"}
                                    register={register}
                                    error={errors.users?.[index]?.password}
                                    required
                                />
                            </div>
                            <div className={"input-group"}>
                                <Select
                                    name={`users.${index}.role_id`}
                                    type={'select'}
                                    options={roles_options}
                                    id={"role_id"}
                                    label={t("Rol")}
                                    placeholder={t("Kies een rol")}
                                    register={register}
                                    error={errors.users?.[index]?.role_id}
                                    required
                                />
                            </div>
                            <div>
                                <hr className={"input-divider"} />
                                <IconButton
                                    className={"input-delete"}
                                    icon={"trash"}
                                    data-cy={"remove-user-button"}
                                    onClick={() => remove(index)}
                                    border={"true"}
                                    // type={"button"}
                                />
                            </div>
                        </Fragment>
                    ))}

                    <ButtonTertiary
                        icon={"add"}
                        text={t("Gebruiker toevoegen")}
                        data-cy={"add-user-button"}
                        onClick={() => append()}
                        type={"button"}
                        fullWidth={true}
                    />

                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Meerdere gebruikers toevoegen")}</legend>

                    <div className="input-group">
                        <Input
                            name={"count"}
                            id={"count"}
                            label={t("Aantal")}
                            type={"number"}
                            register={register}
                            error={errors.count}
                            data-cy={"count-input"}
                            helpText={t("Vul een getal in vanaf 1.")}
                        />
                    </div>

                    {watch("count") &&
                        <>
                            <div className="input-group">
                                <Input
                                    name={"password_prefix"}
                                    id={"password_prefix"}
                                    label={t("Wachtwoord voorvoegsel")}
                                    register={register}
                                    error={errors.password_prefix}
                                    data-cy={"password_prefix-input"}
                                    required
                                />
                            </div>

                            <div className="input-group">
                                <Select
                                    name={"role_id"}
                                    type={'select'}
                                    options={roles_options}
                                    id={"role_id"}
                                    label={t("Rol")}
                                    placeholder={t("Kies een rol")}
                                    register={register}
                                    error={errors.role_id}
                                    required
                                />
                            </div>

                            <section className={"form-meta"}>
                                <div className={"form-meta__item"}>
                                    <p className={"paragraph"}>
                                        {t("Het e-mailadres en het wachtwoord om in te loggen worden aangevuld met een getal op basis van het aantal dat is ingevuld:")}
                                    </p>
                                    <ul className={"default"}>
                                        <li>
                                            {t(`demo`)}<strong>01</strong>{t(`@fuwa.nl t/m demo`)}<strong>{formattedCount}</strong>{t(`@fuwa.nl\n`)}
                                        </li>
                                        <li>
                                            {t(`abc`)}<strong>01 </strong>{t(`t/m abc`)}<strong>{formattedCount}</strong>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        </>
                    }
                </fieldset>


                <Notification className={"form-container__error"} type={"info"}
                              text={"Let op! Het duurt enige tijd voordat een demo omgeving klaar staat. Dit kan tot ongeveer 15 minuten duren na ingestelde startdatum."}/>
                {errors.server &&
                    <Notification className={"form-container__error"} type={"error"}
                                  text={errors.server.message}/>
                }
                {Object.values(errors).find((error) => error.type === "required") &&
                    <Notification className={"form-container__error"} type={"error"}
                                  data-cy={"required-errors-summary"}
                                  text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                }
                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Omgeving aanmaken")}
                        fullWidth={true}
                        data-cy={"environment-create-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const EnvironmentCreate = withAccessRight(
    EnvironmentCreateDisplay,
    'base.can_setup_environments',
)
