import axios from "axios";
import useSWR, {useSWRInfinite} from "swr";
import {buildOrder, buildFilter} from "../utils/constants";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {loadIfAllowed} from "../utils/constants";

export function getDepartments(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client_id: parseInt(fetchState?.identKey?.client_id)
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => `/api/departments/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index+1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}`,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export async function remoteSetDepartment(client_id, department) {
    return await axios.post('/api/departments/', {
        name: department,
        client_id,
    }).then(result => result);
}
