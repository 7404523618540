import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {CheckboxInput, Input} from "../atoms/Input";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Select from "../atoms/Select";
import {withAccessRight} from "../../utils/withAccessHoC";
import {UserGroupFormItem} from "../../components/UserGroupFormItemNew";
import {helpText} from "../../utils/constants";
import Notification from "../molecules/Notification";
import {Controller, useForm} from "react-hook-form";
import {getProject, getProjectOptions, updateProject} from "../../queries/project_detail";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../utils/useAuth";
import {getMethods} from "../../queries/method_detail";
import {getLicenseTypes} from "../../queries/license_detail";
import DatePickerFilter from "../../components/Overviews/Filters/DatePickerFilter";
import Drawer from "../../components/Drawer";
import Overview from "../../components/Overview";
import {ClientSelectionDefinition} from "../../components/Overviews/ClientSelection";
import Quickview from "../../components/Quickview";
import {ProjectSelectionDefinition} from "../../components/Overviews/ProjectSelection";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const ProjectEditPatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function ProjectEditDisplay(props) {
    const {t} = useTranslation();

    const auth = useAuth();
    const {client_id, project_id} = useParams();

    const canArchive = auth.hasPermission('base.can_archive_function');
    const canChangeMethod = auth.checkAccessList('base.change_project_method', [
        [project_id, 'project'],
        [client_id, 'client'],
    ]);
    const canAddLicense = auth.hasPermission('base.add_license');
    const canForceHidePoints = auth.hasPermission("base.can_force_hide_points");
    const canChangeProjectClient = auth.hasPermission("base.change_project_client")
    const canChangeProjectSourceProject = auth.hasPermission("base.change_project_source_project");
    const canOverrideFGSEnforcement = auth.hasPermission("base.can_override_fgs_enforcement");

    const licenses = getLicenseTypes().data?.results;
    const methods = getMethods().data?.results;
    const project = getProject(parseInt(project_id)).data;
    const project_options = getProjectOptions()?.data?.actions?.POST;
    const source_project_options = project_options?.source_project_usage.choices;
    const based_on_fgs_options = project_options?.source_project_function_group_structure_usage.choices;
    const status_options = project_options?.status.choices.filter(obj => obj.value !== 'expired').filter(o => o.value !== "archived" || canArchive);

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        control,
        watch,
        clearErrors,
        formState: {errors, dirtyFields}
    } = useForm();

    useEffect(() => {
        setValue("client_id", project?.client.id);
        setValue("client_name", project?.client.name);
        setValue("name", project?.name);
        setValue("method", project?.method?.name);
        setValue("method_id", project?.method?.id);
        setValue("status", project?.status);
        setValue("license_type", project?.license?.license_type);
        setValue("end_datetime", project?.license?.end_datetime ? new Date(project?.license?.end_datetime) : null);

        setValue("method_functions_as_reference", project?.method_functions_as_reference);
        setValue("is_collective_labour_agreement", project?.is_collective_labour_agreement);
        setValue("force_function_group_structure", project?.force_function_group_structure);
        setValue("hide_points", project?.hide_points);
        setValue("source_project_id", project?.source_project?.id);
        setValue("source_project_usage", project?.source_project_usage);
        setValue("source_project_function_group_structure_usage", project?.source_project_function_group_structure_usage);

        setValue("default_describer_group_id", project?.default_describer_group?.id);
        setValue("default_applier_one_group_id", project?.default_applier_one_group?.id);
        setValue("default_applier_two_group_id", project?.default_applier_two_group?.id);
    }, [project, licenses, methods, project_options]);

    const navigate = useNavigate();

    const onSubmit = () => async (values) => {
        let submitValues = {}
        Object.keys(dirtyFields).map(dirty=> {return dirty}).forEach((x) => submitValues[x] = values[x])

        if(submitValues.end_datetime !== undefined || submitValues.license_type !== undefined){
            submitValues.license = {
                license_type: submitValues.license_type ? submitValues.license_type : watch("license_type"),
                end_datetime: submitValues.end_datetime ? submitValues.end_datetime : watch("end_datetime")
            }
        }

        delete submitValues["end_datetime"]
        delete submitValues["license_type"]

        await updateProject(submitValues, navigate, project_id);
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Project bewerken")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error.cause.message === "server-error-project-edit" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>

                {!canChangeProjectClient &&
                    <section className={"form-meta"}>
                        <div className={"form-meta__item"}>
                            <span className={"form-meta__label"}>{t("Klant")}</span>
                            <span className={"form-meta__description"}
                                  data-cy={"client-name"}>{project?.client?.name}</span>
                        </div>
                    </section>
                }

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>

                    {canChangeProjectClient &&
                        <div className={"input-group"}>
                            <Controller name={"client_id"} control={control}
                                        rules={{required: t("Dit is een verplicht veld")}}
                                        render={({field: {onChange}}) => (
                                            <Drawer
                                                title={t("Selecteer Klant")}
                                                buttonText={t("Zoek klant")}
                                                label={t("Klant")}
                                                initialReadOnlyVal={project?.client?.name}
                                                readOnlyVal={obj => obj.name}
                                                placeholder={t("Geen klant gekozen")}
                                                id={"client-drawer"}
                                                error={errors.client_id}
                                                required

                                                isForm={true}
                                                disabled={false}

                                                reset={() => {
                                                    onChange(null)
                                                }}

                                                onClick={(obj) => {
                                                    onChange(obj.id)
                                                }}

                                                contentComponent={
                                                    <Overview
                                                        identifier={"select-client-overview"}
                                                        identKey={{
                                                            client_id: client_id,
                                                            project_id: project_id
                                                        }}
                                                        contextData={{
                                                            location,
                                                        }}
                                                        definition={ClientSelectionDefinition}
                                                    />
                                                }
                                            />)}
                            />
                        </div>
                    }

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Projectnaam")}
                            placeholder={t("Projectnaam")}
                            data-cy={"project-name-input"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>

                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Licentie instellingen")}</legend>
                    <div className="input-group">
                        <Select
                            name={"license_type"}
                            type={'select'}
                            options={licenses}
                            id={"license_type"}
                            label={t("Licentie")}
                            data-cy={"license-type-input"}
                            register={register}
                            error={errors.license_type}
                            disabled={!canAddLicense}
                        />
                    </div>
                    <div className="input-group">
                        <Controller name={"end_datetime"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"end_datetime"}
                                            type={"text"}
                                            id={"end_datetime"}
                                            onChange={onChange}
                                            error={errors.end_datetime}
                                            selected={value}
                                            label={t("Vervaldatum")}
                                        />)}/>
                    </div>
                </fieldset>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Projectinstellingen")}</legend>

                    <div className="input-group">
                        {project?.source_project_usage === "based_on" &&
                            <Controller name={"source_project_id"} control={control}
                                        rules={{required: t("Dit is een verplicht veld")}}
                                        render={({field: {onChange}}) => (
                                            <Drawer
                                                title={t("Baseer op project")}
                                                name={"source_project_id"}
                                                buttonText={t("Zoek project")}
                                                id={"source-project-drawer"}
                                                label={t("Bronproject")}
                                                initialReadOnlyVal={project?.source_project?.name}
                                                readOnlyVal={obj => obj.name}
                                                placeholder={t("Kies een project...")}
                                                disabled={!canChangeProjectSourceProject}
                                                error={errors.source_project_id}

                                                isForm={true}

                                                reset={() => {
                                                    onChange(null)
                                                }}

                                                onClick={(obj) => {
                                                    onChange(obj.id)
                                                }}

                                                contentComponent={
                                                    <Quickview>
                                                        {props?.client_id && (
                                                            <Overview
                                                                identifier={"select-project-overview"}
                                                                quickview={{
                                                                    identifier: "client-projects",
                                                                    name: t("Van deze klant")
                                                                }}
                                                                identKey={{
                                                                    client_id: client_id,
                                                                }}
                                                                contextData={{
                                                                    location,
                                                                }}
                                                                definition={ProjectSelectionDefinition}
                                                                initialFilters={{
                                                                    status__in: ['concept', 'determined']
                                                                }}
                                                            />
                                                        )}
                                                        <Overview
                                                            identifier={"select-project-overview-global"}
                                                            quickview={{
                                                                identifier: "projects",
                                                                name: t("Alle projecten")
                                                            }}
                                                            identKey={{}}
                                                            contextData={{
                                                                location,
                                                            }}
                                                            definition={ProjectSelectionDefinition}
                                                            initialFilters={{
                                                                status__in: ['concept', 'determined']
                                                            }}
                                                        />
                                                    </Quickview>
                                                }
                                            />
                                        )}
                            />
                        }
                    </div>

                    {watch("source_project_id") && project?.source_project_usage === "based_on" &&
                        <div className="input-group">
                            <Select
                                name={"source_project_usage"}
                                type={'select'}
                                options={source_project_options}
                                id={"source_project_usage"}
                                label={t("Het project zal")}
                                placeholder={t("Maak een keuze")}
                                register={register}
                                error={errors.source_project_usage}
                                disabled={true}
                            />
                        </div>
                    }

                    {watch("source_project_id") && watch("source_project_usage") === "based_on" &&
                        <div className="input-group">
                            <Select
                                name={"source_project_function_group_structure_usage"}
                                type={'select'}
                                options={based_on_fgs_options}
                                id={"source_project_function_group_structure_usage"}
                                label={t("De functiegroepenstructuur vanuit het bronproject zal")}
                                placeholder={t("Maak een keuze")}
                                register={register}
                                error={errors.source_project_function_group_structure_usage || (canOverrideFGSEnforcement && project?.source_project?.force_function_group_structure) && {message: "Let Op! De functiegroepenstructuur wordt geforceerd vanuit de CAO. Wijzigen is mogelijk. Let in dat geval op dat de projectnaam hier voldoende goed rekening mee houdt"}}
                                disabled={!canOverrideFGSEnforcement}
                            />
                        </div>
                    }

                    <div className="input-group">
                        <Select
                            name={"method_id"}
                            type={'select'}
                            options={methods}
                            id={"method_id"}
                            label={t("Methode")}
                            placeholder={t("Selecteer een methode")}
                            data-cy={"method-select"}
                            register={register}
                            disabled={!canChangeMethod}
                            error={errors.method_id}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Select
                            name={"status"}
                            type={'select'}
                            options={status_options}
                            id={"status"}
                            label={t("Status")}
                            data-cy={"status-select"}
                            register={register}
                            error={errors.status}
                        />
                    </div>

                    <div className="input-group">
                        <CheckboxInput
                            name={"method_functions_as_reference"}
                            type={"checkbox"}
                            id={"method_functions_as_reference"}
                            label={t("Methode-functies bruikbaar als referentiefuncties")}
                            placeholder={t("")}
                            data-cy={"method-functions-as-reference"}
                            register={register}
                            error={errors.method_functions_as_reference}
                        />
                    </div>

                    {canForceHidePoints && project?.is_collective_labour_agreement && (
                        <div className="input-group">
                            <CheckboxInput
                                name={"hide_points"}
                                type={"checkbox"}
                                id={"hide_points"}
                                label={t("Verberg punten")}
                                data-cy={"hide-points"}
                                register={register}
                                error={errors.hide_points}
                            />
                        </div>
                    )}
                </fieldset>

                <fieldset className={"form-container__fieldset"}>

                    <Controller name={"default_describer_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={project?.default_describer_group}
                                        client_id={watch("client_id") ? watch("client_id") : project?.client?.id}
                                        id_prefix={"default_describer_group"}
                                        label={t("Beschrijver")}
                                        helpTitle={t("Wat is een \"beschrijver\"?")}
                                        helpText={t(helpText.beschrijver)}
                                        onChange={onChange}
                                    />)}
                    />

                    <Controller name={"default_applier_one_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={project?.default_applier_one_group}
                                        client_id={watch("client_id") ? watch("client_id") : project?.client?.id}
                                        id_prefix={"default_applier_one_group"}
                                        label={t("Toepasser")}
                                        helpTitle={t("Wat is een \"toepasser\"?")}
                                        helpText={t(helpText.toepasser)}
                                        onChange={onChange}
                                    />)}
                    />

                    <Controller name={"default_applier_two_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={project?.default_applier_two_group}
                                        client_id={watch("client_id") ? watch("client_id") : project?.client?.id}
                                        id_prefix={"default_applier_two_group"}
                                        label={t("Toetser")}
                                        helpTitle={t("Wat is een \"Toetser\"?")}
                                        helpText={t(helpText.toetser)}
                                        onChange={onChange}
                                    />)}
                    />

                    {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                    text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>


                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"edit-project-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const ProjectEdit = withAccessRight(
    ProjectEditDisplay,
    'base.change_project',
    [
        ['project_id', 'project'],
        ['client_id', 'client']
    ]
)
