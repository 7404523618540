import React, { useState, useEffect} from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {getFunction} from "../queries/function_detail";
import IconButton from "../patterns/molecules/IconButton";
import cx from "classnames";

import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import List from '@ckeditor/ckeditor5-list/src/list';
import Link from '@ckeditor/ckeditor5-link/src/link';
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Code from '@ckeditor/ckeditor5-basic-styles/src/code';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";

import {
    addDescription,
    addFromDescription, createFunctionDescriptionTemplate,
    deleteDescription,
    remoteSetFunctionDescription
} from "../queries/function_description";
import {useDebounce} from "../utils/useDebounce";
import Modal from "react-modal";
import {functionStatus} from "../utils/constants";
import ButtonSecondary from "../patterns/atoms/ButtonSecondary";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import Drawer from "./Drawer";
import Overview from "./Overview";
import {FunctionSelectionDefinition} from "./Overviews/FunctionSelection";
import {useParams} from "react-router-dom";
import {PreloadAndDisplay} from "../patterns/organisms/SelectedFunctions";
import {FunctionInfoIconButton} from "./Overviews/FunctionInfoIconButton";
import {DescriptionModal} from "./Screens/Description";
import SidebarDescriptionSelection, {DescriptionSelection} from "./Overviews/DescriptionSelection";
import {useTranslation} from "react-i18next";
import {getComparableFunctions} from "../queries/client_project_functions";
import {SidebarOverview} from "./ScoringSidebar";
import {SidebarDefaultDescriptionSelection} from "./Overviews/DescriptionSelection";
import {getProject} from "../queries/project_detail";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import Noty from "noty";
import {useAuth} from "../utils/useAuth";
import {TagEditor} from "./Tags";
import ToolTip from "./ToolTip";

// For adding classes to editor content see:
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/conversion/conversion-extending-output.html

// For creating logic that convertes existing (Source HTML in conclusionDB) to CK5 usable content see:
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/conversion/conversion-preserving-custom-content.html

// For other info regarding subject see:
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/conversion/custom-element-conversion.html
// and
// https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/conversion/conversion-introduction.html
// config object to be passed to CKEditor5
export const editorConfiguration = {
    plugins: [
        Essentials,
        Autoformat,
        AutoLink,
        Paragraph,
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Code,
        Subscript,
        Superscript,
        Heading,
        List,
        Link,
        PasteFromOffice,
        Table,
        TableToolbar,
        TableProperties,
        TableCellProperties
    ],
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'code',
        'subscript',
        'superscript',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        // 'indent',
        // 'outdent',
        // '|',
        // 'imageUpload',
        // 'blockQuote',
        'insertTable',
        // 'mediaEmbed',
        'undo',
        'redo'
    ],
    table: {
        contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
        ],
        tableProperties: {
            // width: "100%",
            tableWidth: "100%",
            // tableWidthInput: "100%",
            // widthInput: "100%",
            // borderColors: [
            //     {
            //         color: 'hsl(0, 0%, 90%)',
            //         label: 'Light grey'
            //     },
            // ],
            // backgroundColors: [
            //     {
            //         color: 'hsl(120, 75%, 60%)',
            //         label: 'OtherGreen2'
            //     },
            //     {
            //         color: 'hsl(120, 75%, 60%)',
            //         label: 'OtherGreen3'
            //     },
            //     {
            //         color: 'hsl(120, 75%, 60%)',
            //         label: 'OtherGreen1'
            //     },
            // ]
        }
    },
    language: 'nl'
}

export function EditorDisplay(props) {

    const {t, i18n} = useTranslation();
    const [richTextContent, setRichTextContent] = useState(props.content);
    const [debouncedRichTextContent, setDebouncedRichTextContent] = useDebounce(richTextContent);

    useEffect(() => {
        props.setMotivation(debouncedRichTextContent)
    }, [debouncedRichTextContent]);

    return (
        <CKEditor
            editor={InlineEditor}
            config={{
                ...editorConfiguration,
                placeholder: props?.placeholder ? t(props.placeholder) : t("Typ hier uw tekst..."),
            }}
            data={richTextContent}
            onChange={(e, ckEditor) => {
                let data = ckEditor.getData();
                setRichTextContent(data);
                setDebouncedRichTextContent(data);
            }}
            onReady={ ckEditor => {
                // ckEditor.execute( 'tableWidth', {
                //     value: '400px'
                // });
                // console.log("attaching");
                // CKEditorInspector.attach( editor );
            }}
        />
    )
}


function DescriptionEditorDisplay(props) {

    const {t, i18n} = useTranslation();
    const [initialContent, setInitialContent] = useState(props.item.content);
    const [initialTitle, setInitialTitle] = useState(props.item.title);
    const [richTextContent, setRichTextContent] = useState(props.item.content);
    const [title, setTitle] = useState(props.item.title);
    const [debouncedTitle, setDebouncedTitle] = useDebounce(title);
    const [debouncedRichTextContent, setDebouncedRichTextContent] = useDebounce(richTextContent);

    // const undo = () => {
    //     setTitle(initialTitle);
    //     setDebouncedTitle(initialTitle);
    //     setRichTextContent(initialContent);
    //     setDebouncedRichTextContent(initialContent);
    // }

    const save = (title, content) => {

        if(props?.item && props?.item?.id) {
            remoteSetFunctionDescription(props.item.id,{
                ...props.item,
                content: content,
                title: title,
                order: props?.newOrder,
            });
        }

        if(props?.save) {
            props.save(title, content);
        }
    }


    useEffect(() => {
        if((title !== initialTitle || richTextContent !== initialContent) || (props?.newOrder !== props.item.order && props?.newOrder >= 0)) {

            save(title, richTextContent)

            if(title!== initialTitle || richTextContent !== initialContent) {
                let noty = new Noty({
                    theme: 'bootstrap-v4',
                    layout: 'bottomRight',
                    text: t("Je wijziging is automatisch opgeslagen!"),
                    closeWith: ['click', 'button'],
                    type: "success",
                    timeout: 2000,
                }).show();
            }
        }
    }, [debouncedTitle, debouncedRichTextContent, props.newOrder]);

    return (
        <>
            {title !== false && (
              <input className={"description-editor__title-input"} placeholder={t("Titel")} value={title} onChange={ e => {
                  setTitle(e.target.value);
                  setDebouncedTitle(e.target.value);
              }} />
            )}
            <CKEditor
                editor={InlineEditor}
                config={{
                    ...editorConfiguration,
                    placeholder: props?.placeholder ? t(props.placeholder) : t("Typ hier uw tekst..."),
                }}
                data={richTextContent}
                onChange={(e, ckEditor) => {
                    let data = ckEditor.getData();
                    setRichTextContent(data);
                    setDebouncedRichTextContent(data);
                }}
                onReady={ ckEditor => {
                    // props.setLoaded(props.item.id);
                    // console.log(ckEditor);
                    // console.log("attaching");
                    // console.log("attaching");
                    // CKEditorInspector.attach( editor );
                }}
            />
            {/*<a href={"#"} onClick={(e) => {*/}
            {/*    e.preventDefault();*/}
            {/*    undo();*/}
            {/*}}>{t("Maak wijziging ongedaan")}</a>*/}

        </>
    )
}


export const DescriptionEditor = React.memo(DescriptionEditorDisplay);

export default function RichTextManager(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();

    function descriptionHtmlContent(item) {
        return {
            __html: item
        }
    }

    const [ descriptionFunction, setDescriptionFunction] = useState(false);
    const [ loadedEditors, setLoadedEditors] = useState([]);
    const [ editors, setEditors ] = useState([]);
    const [ modalCheck, setModalCheck ] = useState(false);

    let { client_id, project_id } = useParams();
    const functionDetail = ('getFunction' in props) ?  props.getFunction() : getFunction(props.function_id);
    const projectDetail = props?.projectDetail;

    const resetLoaded = () => {
        setLoadedEditors(functionDetail.data.descriptions.map(item => { return {id: item.id, loaded:false }}));
    }

    useEffect(() => {

        if(functionDetail?.data?.descriptions?.length > 0 && editors.length !== functionDetail?.data?.descriptions?.length > 0) {
            resetLoaded();
            setEditors(functionDetail.data.descriptions);
        }
    },[functionDetail?.data?.descriptions?.length]);

    useEffect(()=>{
        if(props.edit) {
            resetLoaded()
        }
    }, [props.edit]);

    const setLoaded = id => {
        setLoadedEditors(state => {
            const newState = [ ...state ];

            let idx = newState.findIndex(obj => obj.id === id);
            if(idx >= 0) {
                newState[idx].loaded = true;
            }
            return newState;
        });
    }

    function swap(list, iA, iB) {
        [list[iA], list[iB]] = [list[iB], list[iA]];
        return list;
    }

    const swapOrder = (idx, up=false) => {
        if(idx >= 0 && idx <= editors.length - 1) {
            if(up && idx > 0) {
                setEditors(swap([...editors], idx, idx - 1));
            }
            if(!up && idx < editors.length - 1) {
                setEditors(swap([...editors], idx +1, idx));
            }
        }
    }

    const del = async (idx) =>{
        await deleteDescription(editors[idx].id);
        setEditors(currentState => {
            let newState = [...currentState];
            newState.splice(idx, 1);
            return newState;
        })
    }

    const add = async (title=t("Nieuw blok"), content='', tags=[], order=99) =>{
        let data = await _add(title,content,tags,order);
        setEditors([
            ...editors,
            data,
        ])
    }

    const _add = async (title=t("Nieuw blok"), content='', tags=[], order=99) =>{
        let newDescription = await addDescription(functionDetail?.data?.id, title, content, tags, order);
        let data = Object.assign(
            {
                id:null,
                order:99,
                title:'',
                content: '',
            }, newDescription?.data
        );
        return data;
    }

    const saveDefault = async(title, content, tags=[], order=0) => {
        let result = await createFunctionDescriptionTemplate(title, content, tags, order);

        let noty = new Noty({
            theme: 'bootstrap-v4',
            layout: 'bottomRight',
            text: t("Je hebt het tekstblok met kopje") + " <strong>"+ title + "</strong> " +
                t("opgeslagen als standaardtekst."),
            closeWith: ['click', 'button'],
            type: "success",
            timeout: 5000,
        }).show();
    }

    const ConfirmDelete = props => {

        const modalContent = props?.content;

        return (
            <>
                <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"} bodyOpenClassName={"has-modal"}>
                    <div className={"modal-header"}>
                        <div className={"modal-header__top"}>
                            <h2 className={"modal-header__title"}>{t("Verwijder beschrijving")}</h2>
                            <ul className={"modal-header__controls"}>
                                <li className={"modal-header__control"}>
                                    <IconButton icon={"close"} onClick={() => setModalCheck(false)}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"modal__body"}>
                        <p className={"paragraph"}>
                            {t("Weet je zeker dat je de beschrijving met onderstaande titel wilt verwijderen?")}
                        </p>
                        <strong>{modalContent.item.title}</strong>
                    </div>
                    <div className="modal-footer">
                        <ul className={"modal__buttons"}>
                            <li className={"modal__button"}>
                                <ButtonSecondary
                                    onClick={() => setModalCheck(false)}>
                                    {t("Annuleren")}
                                </ButtonSecondary>
                            </li>
                            <li className={"modal__button"}>
                                {/* do NOT use modalContent.item.id as we want to remove the item from the js list */}
                                <ButtonPrimary
                                    onClick={() => {
                                        setModalCheck(false);
                                        del(modalContent.idx)
                                    }}
                                >
                                    {t("Bevestigen")}
                                </ButtonPrimary>
                            </li>
                        </ul>
                    </div>
                </Modal>
            </>
        );
    }

    if(!projectDetail.data ) {
        return <LoaderAnimation/>;
    }

    // console.log(editors.length , (props.edit && loadedEditors.some(o => o.loaded === false)) );
    // console.log(loadedEditors?.[0]);

    return (
        <div className={cx("description-editor", {
            // Something strange is going on with initting the CK Editors. loadedEditors should return that some are loading and thus adding class
            // however it seems rendering is halted or something, because the edit went to 'true' but editors not so the "adde template" is shown
            // but that should not be the case. Skiped for now, it looks strange tho
            // "hideEditors" : (props.edit && loadedEditors.some(o => o.loaded === false))
        })}>
            {editors.length === 0 && (
                <div className={"no-description-form"}>
                    <h3 className={"no-description-form__title"}>{t('Nog geen teksten toegevoegd')}</h3>
                    {props?.edit ? (

                        <ButtonPrimary
                            fullWidth={"true"}
                            onClick={ async e => {
                                let newData = await projectDetail?.data?.function_description_templates?.map( async obj => {
                                    let ret = await _add(obj?.title||"", obj?.content||"", obj?.tags||[], obj?.order||99);
                                    return ret;
                                })

                                // setEditors([
                                //     ...editors,
                                //     newData,
                                // ])
                                // projectDetail.mutate();
                                functionDetail.mutate();
                            }}
                            data-cy={"standard-template-button"}
                        >{t('Standaard template gebruiken')}</ButtonPrimary>
                    ) : (
                        <>
                            {t("Aan deze functie is nog geen beschrijving toegevoegd.")}
                        </>
                    )}
                </div>
            )}

            {editors.map( (item, idx) => {
                return (
                    <div key={item.id}  className={cx("description-editor__item", {
                        "description-editor__item--landscape": functionDetail.data.print_orientation === "landscape"
                    })}>
                        {props.edit ?

                            // EDIT
                            (
                                <>
                                    <div className={"description-editor__text"} key={item.title}>
                                        <div className="description-editor__rich-text">
                                            <DescriptionEditor
                                                setLoaded={setLoaded}
                                                item={item}
                                                newOrder={idx}
                                            />
                                        </div>
                                        <div className={"description-editor__tags"}>
                                            <TagEditor
                                                objectId={item.id}
                                                objectType={"FunctionDescription"}
                                                edit={true}
                                            />
                                        </div>
                                    </div>
                                    <div className={"description-editor__buttons"}>
                                        <ToolTip tooltip={t("Verplaats tekstblok omhoog")}>
                                            <IconButton title={t("Verplaats tekstblok omhoog")}
                                                        icon={"arrow-up"}
                                                        className={"description-editor__button"}
                                                        onClick={() => swapOrder(idx, true)}
                                            />
                                        </ToolTip>
                                        <ToolTip tooltip={t("Verplaats tekstblok omlaag")}>
                                            <IconButton title={t("Verplaats tekstblok omlaag")}
                                                        icon={"arrow-down"}
                                                        className={"description-editor__button"}
                                                        onClick={() => swapOrder(idx, false)}
                                            />
                                        </ToolTip>
                                        <ToolTip tooltip={t("Verwijder tekstblok")}>
                                            <IconButton title={t("Verwijder tekstblok")}
                                                        icon={"trash"}
                                                        className={"description-editor__button"}
                                                        onClick={() => setModalCheck({ idx: idx, item: item})}
                                            />
                                        </ToolTip>
                                    </div>
                                    { modalCheck?.idx === idx && (
                                        <ConfirmDelete
                                            content={modalCheck}
                                        />
                                    )}
                                </>
                            )
                                :
                            // NON EDIT
                            (
                            <>
                                <div className={"description-editor__text"} key={item.title}>
                                    <h3 className={"description-editor__title"}>{item.title}</h3>
                                    <div className={"description-editor__rich-text"} dangerouslySetInnerHTML={descriptionHtmlContent(item.content)}/>
                                    <div className={"description-editor__tags"}>
                                        <TagEditor
                                            objectId={item.id}
                                            objectType={"FunctionDescription"}
                                            edit={false}
                                        />
                                    </div>

                                </div>
                                <div className={"description-editor__buttons"}>
                                    {auth.hasPermission('base.add_defaultfunctiondescriptiontemplate', [client_id, 'client']) && (
                                        <ToolTip tooltip={t("Opslaan als standaardtekst")}>
                                            <IconButton icon={"bookmark"}
                                                        title={t("Opslaan als standaardtekst")}
                                                        className={"description-editor__button"}
                                                        onClick={() => setModalCheck({idx, item})}>
                                                {t("Opslaan als standaardtekst")}
                                            </IconButton>
                                        </ToolTip>
                                    )}
                                    { modalCheck?.idx === idx && ( (modalCheck?.item?.title && modalCheck?.item?.content) ? (
                                        <ConfirmModal
                                            key={'default-text'}
                                            title={t("Opslaan als standaardtekst")}
                                            body={t("Weet je zeker dat je de tekst met onderstaande titel wilt opslaan als standaardtekst") + ":"}
                                            subject={modalCheck.item.title || t("Geen titel opgegeven")}
                                            confirmModal={() => {
                                                if(modalCheck.item?.title && modalCheck.item?.content) {
                                                    saveDefault(modalCheck.item?.title, modalCheck.item.content, modalCheck.item.tags.map(o => o.name)); // remove the .map if we want to push full tag objects
                                                }
                                            }}
                                            closeModal={() => setModalCheck(false)} />
                                    ) : (
                                        <ConfirmModal
                                            key={'default-text-empty'}
                                            singular={true}
                                            title={t("Fout bij opslaan als standaardtekst")}
                                            body={t("Deze tekst heeft geen titel en/of inhoud en kan daarom niet worden opgeslagen als standaardtekst.")}
                                            closeModal={() => setModalCheck(false)}
                                        />

                                    ))}
                                </div>
                            </>
                            )}

                    </div>

                )
            })}

            {props.edit && (
                <div className={"description-editor__options"}>
                    <button
                        className={"button button--tertiary"}
                        onClick={() => add()}
                    >{t("Maak eigen tekst")}</button>

                    <Drawer
                        title={t("Kies een functie om de beschrijving op te baseren")} // Title shown in the drawer

                        buttonText={t("Baseer tekst op bestaande beschrijving")} // Text displayed on (selection) button
                        buttonClassname={"button button--tertiary"}
                        noReset={true}

                        // autoClose={false}        // Optionally let the drawer know it should not close (default is true)

                        onClick={ (obj) => {        // call to set the (form) result. The passing through Drawer support  multiple arguments instead of one. eg: (arg1, arg2, arg3) works too
                            // props.form.change('based_on_id', obj.id);
                            // props.form.change('based_on_faux_input', obj.name);
                            setDescriptionFunction(obj.id);
                        }}

                        contentComponent={          // Pass a component (useful because this component gets passed an onClick which handles the closing of the drawer)
                            <Overview
                                identifier={"describe-select-function-drawer"}
                                identKey={{
                                    client_id: client_id,
                                    project_id: project_id,
                                    id__not: props.function_id,
                                    description_type: 'normal',
                                }}
                                contextData={{
                                    location,
                                    client_id,
                                    project_id
                                }}
                                definition={FunctionSelectionDefinition}
                            />
                        }
                    />
                    {descriptionFunction && (
                        <>
                            <Drawer
                                title={t("Baseren op beschrijving")} // Title shown in the drawer

                                initialOpen={true}
                                noReset={true}
                                noButton={true}
                                autoClose={false}           // Optionally let the drawer know it should not close (default is true)
                                type={"side-drawer"}

                                onClick={ (obj) => {        // call to set the (form) result. The passing through Drawer support  multiple arguments instead of one. eg: (arg1, arg2, arg3) works too
                                    add(obj?.title||"", obj?.content||"", obj?.tags||[]);
                                }}

                                closeCallback={() => {
                                    setDescriptionFunction(false);
                                }}

                                contentComponent={          // Pass a component (useful because this component gets passed an onClick which handles the closing of the drawer)
                                    <SidebarDescriptionSelection
                                        function_id={props.function_id}
                                        function_id_two={descriptionFunction}
                                    />
                                }
                            />


                        </>
                    )}

                    <Drawer
                        title={t("Kies een standaard-tekst")} // Title shown in the drawer

                        buttonText={t("Kies een standaard-tekst")} // Text displayed on (selection) button
                        buttonClassname={"button button--tertiary"}

                        noReset={true}
                        noButton={false}
                        autoClose={false}        // Optionally let the drawer know it should not close (default is true)
                        type={"side-drawer"}

                        onClick={ (obj) => {        // call to set the (form) result. The passing through Drawer support  multiple arguments instead of one. eg: (arg1, arg2, arg3) works too
                            // setDescriptionFunction(obj.id);
                            add(obj?.title||"", obj?.content||"", obj?.tags||[]);
                        }}

                        contentComponent={          // Pass a component (useful because this component gets passed an onClick which handles the closing of the drawer)
                            <Overview
                                identifier={"decsribe-select-default-text"}
                                identKey={{
                                    client_id: client_id,
                                    project_id: project_id
                                }}
                                contextData={{
                                    location,
                                    client_id,
                                    project_id
                                }}
                                definition={SidebarDefaultDescriptionSelection}
                            />
                        }
                    />

                </div>
            )}


        </div>
    )
}
