import useSWR from "swr";

export function getRoles() {
    const {data, error, mutate} = useSWR(`/api/roles/?ordering=order`);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}
