import React from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useSWR from "swr";
import {optionsFetcher} from "../utils/constants";

export const useLicenseCreate = () => {
    let navigate = useNavigate()
    const {t} = useTranslation();

    const {data: licenseCreateOptionsData} = useSWR("/api/licenserequests/", optionsFetcher);
    const size_options = licenseCreateOptionsData?.actions?.POST?.company_size?.choices;
    const license_options = [
        {value: "", display_name: t("Maak een keuze...")},
        ...(licenseCreateOptionsData?.actions?.POST?.license_type?.choices || []),
    ];
    const based_on_options = [
        {
            value: "maak een keuze",
            display_name: t("Maak een keuze..."),
        },
        {
            value: "geen",
            display_name: t("Geen"),
        },
        {
            value: "CAO Jeugdzorg",
            display_name: t("CAO Jeugdzorg"),
        },
        {
            value: "CAO Kinderopvang",
            display_name: t("CAO Kinderopvang"),
        },
        {
            value: "CAO Sociaal werk",
            display_name: t("CAO Sociaal werk"),
        },
        {
            value: "Gemeente",
            display_name: t("Gemeente"),
        },
        {
            value: "Goede Doelen",
            display_name: t("Goede Doelen"),
        },
        {
            value: "Handel in bouwmaterialen",
            display_name: t("Handel in bouwmaterialen"),
        },
        {
            value: "Waterschappen",
            display_name: t("Waterschappen"),
        },
        {
          value: "Provincie",
          display_name: t("Provincie"),
        },
        {
            value: "anders",
            display_name: t("Anders, namelijk..."),
        },
    ];

    return {
        size_options: size_options,
        license_options,
        based_on_options,
        onSubmit: async (values) => {
            const newValues = {
                ...values,
                based_on_project: values?.based_on_project === "anders" ? values?.other_based_on ? values?.other_based_on : "niets opgegeven..." : values?.based_on_project,
            }
            await axios.post("/api/licenserequests/", newValues)
                .then(result => {
                    if (result.status === 201) {
                        if (result.data?.id) {
                            navigate('/license/thanks');
                        } else {
                            throw new Error("server-error-license-request-create", {cause: result});
                        }
                    } else {
                        throw new Error("server-error-license-request-create", {cause: result});
                    }
                })
                .catch(function (error) {
                    throw new Error("server-error", {cause: error});
                })
        },
    };
}
