import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getFunction} from "../queries/function_detail";
import ButtonSecondary from "../patterns/atoms/ButtonSecondary";
import {remoteSetFunctionDescriptionTag} from "../queries/function_description";
import {getTags, remoteSetTag} from "../queries/tags";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useTranslation} from "react-i18next";
import ButtonTertiary from "../patterns/atoms/ButtonTertiary";
import cx from "classnames";
import {Icon} from "../patterns/atoms/Icon";

export function Tag(props) {

    return (
        <span className={cx("tag", {"tag--editable": props?.edit && props?.remove})}>
            #{props.tag.name}
            {props?.edit && props?.remove && (

                <button className={"tag__remove"} onClick={ () => props.remove()}>
                    <Icon icon={"close"} sizeModifier={"xsmall"}/>
                </button>
            )}
        </span>
    );
}

export function TagEditor(props) {

    const {t, i18n} = useTranslation();

    const { objectId, objectType } = props;

    const {data, mutate} = getTags(props.objectType, props.objectId)

    const tags = data?.results || [];

    const [ newTag, setNewTag ] = useState("");

    const remove = async tag => {
        await remoteSetTag(props.objectType, props.objectId, tag, true);
        mutate();
    }

    const save = async () => {
        // await remoteSetFunctionDescriptionTag(newTag);
        await remoteSetTag(props.objectType, props.objectId, newTag)
        setNewTag("");
        mutate();
    }

    if(!tags) {
        return <LoaderAnimation/>;
    }

    return (
        <>
            <ul className={cx("tag-list", {"tag-list--editable": props?.edit && props?.remove})}>
                {tags.map(tag => (<li key={tag.id} className={"tag-list__item"}>
                        <Tag tag={tag} edit={props.edit} remove={() => remove(tag.name)}/>
                    </li>)
                )}
            </ul>
            {props?.edit === true && (

                <>
                    <label className={"label"} htmlFor={"add-tag"}>{t("Tags toevoegen")}</label>
                    <div className={"tag-submit"}>
                        <input type={"text"} placeholder={t("Tagnaam")} name={"tag"} id={"add-tag"} value={newTag} onChange={e => setNewTag(e.target.value)} />
                        <ButtonTertiary text={t("Toevoegen")} onClick={e => save()}/>
                    </div>
                </>
            )}
        </>
    )

}
