import React from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getProject, getProjectInsight} from "../queries/project_detail";
import useSWR from "swr";

export const useProjectInsight = () => {
    let navigate = useNavigate()
    let {client_id, project_id} = useParams();
    const {t} = useTranslation();

    const project = getProject(parseInt(project_id));
    const {data: projectInsight, mutate: mutateProjectInsight} = getProjectInsight(parseInt(project_id));
    const method = useSWR(() => `/api/methods/${project.data?.method?.id}/`);

    return {
        t,
        project_id: parseInt(project_id),
        client_id: parseInt(client_id),
        project: project?.data,
        client: project?.data?.client,
        projectInsight: projectInsight,
        allow_function_matching: method?.data?.allow_function_matching,

        onSubmit: async (values) => {
            let data = values;

            if (data?.email !== "" && data?.email) {
                data.email = data.email.replace(/\s+/g, '');
            }

            await axios.post("/api/projects/" + project_id + "/insight/", data)
                .then(result => {
                    if (result.status === 200) {
                        mutateProjectInsight();
                        navigate(`../`);
                    } else if (result.data?.detail?.includes?.("unique constraint")) {
                        throw new Error("unique-error-project-insight", {cause: result});
                    } else {
                        throw new Error("server-error-project-insight", {cause: result});
                    }
                })
                .catch(function (error) {
                    throw new Error("server-error", {cause: error});
                })
        },
    };
}
