import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {withAccessRight} from "../../utils/withAccessHoC";
import Notification from "../molecules/Notification";
import {useForm, Controller} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import DatePickerFilter from "../../components/Overviews/Filters/DatePickerFilter";
import {editLicense, getLicense, getLicenseTypes} from "../../queries/license_detail";
import Select from "../atoms/Select";
import {getYear} from "date-fns";

function LicenseEditDisplay(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {license_id} = useParams();
    const license = getLicense(license_id).data;
    const licenseTypes = getLicenseTypes().data?.results;
    const {
        register, handleSubmit, setError, setValue, control, formState: {errors}
    } = useForm();

    const onSubmit = () => async (values) => {
        await editLicense(license_id, values, navigate, setError, t);
    }

    // Can't use initialValues from react-hook-form because the api call is not done yet when the form is rendering.
    useEffect(() => {
        if (license && !license.isLoading) {
            setValue("license_type", license.license_type)
            setValue("end_datetime", license.end_datetime ? new Date(license.end_datetime) : null)
        }
    }, [license])

    return (
        <>
            <h1 className={"form-container__title"}>{t("Licentie Aanpassen")}</h1>
            <form onSubmit={handleSubmit(onSubmit())}>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>
                    <div className="input-group">
                        <div className={"input-group__label-container"}>
                            <label className={"input-group__label"}>{t("Klant")}</label>
                        </div>
                        <input className={"input-group__input"} defaultValue={license?.project.client.name}
                               disabled={true} data-cy={"client-name-input"}/>
                    </div>
                    <div className="input-group">
                        <div className={"input-group__label-container"}>
                            <label className={"input-group__label"}>{t("Projectnaam")}</label>
                        </div>
                        <input className={"input-group__input"} defaultValue={license?.project.name}
                               disabled={true} data-cy={"project-name-input"}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Licentie instellingen")}</legend>
                    <div className="input-group">
                        <Select
                            name={"license_type"}
                            type={'select'}
                            options={licenseTypes}
                            id={"license_type"}
                            label={t("Licentie")}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <div className={"input-group__label-container"}>
                            <label className={"input-group__label"}>{t("Vervaldatum")}</label>
                        </div>
                        <Controller name={"end_datetime"} control={control}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerFilter
                                            name={"end_datetime"}
                                            type={"text"}
                                            id={"end_datetime"}
                                            onChange={onChange}
                                            selected={value}
                                            endYear={getYear(new Date()) + 11}
                                        />)}
                        />
                    </div>
                </fieldset>

                {errors.server && <Notification type={"error"} text={errors.server.message}/>}

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"edit-license-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}


export const LicenseEdit = withAccessRight(
    LicenseEditDisplay,
    'base.change_license',
);
