import React from "react";
import Drawer from "./Drawer";
import Overview from "./Overview";
import {UserGroupSelectionDefinition, UserGroupSelectionDefinitionGlobal} from "./Overviews/UserGroupOverview";
import {useTranslation} from "react-i18next";
import Quickview from "./Quickview";

export function UserGroupFormItem(props) {
    const {t} = useTranslation();

    return (
        <div className="input-group">
            <Drawer
                title={"Selecteer gebruikersgroep"}
                buttonText={"Zoek gebruikersgroep"}
                label={props.label}
                initialReadOnlyVal={props?.obj ? `${props.obj?.name} (${t("Aantal")}: ${props.obj?.user_count || 0})` : false}
                placeholder={t("Geen gebruikersgroep gekozen")}
                readOnlyVal={obj => `${obj.name} (${obj.user_count})`}

                disabled={false}
                isForm={true}

                id={props.id_prefix + "_id_drawer"}
                helpTitle={props.helpTitle}
                helpText={props.helpText}
                error={props.error}

                reset={() => {
                    props.onChange(null);
                }}

                onClick={(obj) => {
                    props.onChange(obj.id);
                }}

                contentComponent={
                    <Quickview>
                        {props?.client_id && (
                            <Overview
                                identifier={"select-usergroup-overview"}
                                quickview={{
                                    identifier: "select-usergroup-overview",
                                    name: "Huidige klant"
                                }}
                                identKey={{
                                    client_id: props.client_id,
                                }}
                                contextData={{
                                    location,
                                }}
                                definition={UserGroupSelectionDefinition}
                            />
                        )}
                        <Overview
                            identifier={"select-usergroup-overview-global"}
                            quickview={{
                                identifier: "select-usergroup-overview-global",
                                name: "Alle gebruikersgroepen"
                            }}
                            identKey={{}}
                            contextData={{
                                location,
                            }}
                            definition={UserGroupSelectionDefinitionGlobal}
                        />
                    </Quickview>
                }
            />
        </div>
    )
}
