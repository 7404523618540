import React, {useEffect, useMemo, useState as useStateReact} from "react";
import cx from "classnames";
import {useState, singlePopOver} from "../../components/PopOverHoC";
import Icon from "../atoms/Icon";
import {useSelector} from "react-redux";
import {createOverviewInstanceStateSelector} from "../../components/Overview";
import i18n from "../../utils/i18n";
import {useTranslation} from "react-i18next";

export const DropdownFilterPatternState = {
    prefix_identifier: 'prefix-',
    identifier: 'identifier-',
    options: [
        {
            identifier: 'checkbox-1',
            title: i18n.t('item 1')
        },
        {
            identifier: 'checkbox-2',
            title: i18n.t('item 2')
        }
    ]
}

export default function DefaultDropdownFilter(props) {
    const {t, i18n} = useTranslation();
    const full_identifier = props.prefix_identifier + props.identifier;

    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [ popOverActive, setPopOverActive ] =  useState(props, full_identifier, false);

    const state = props.state?.[props.identifier] || [];

    return (
        <div key={props.prefix_identifier} className={props.className}>
            <div
                className={cx(
                    {"function-type-selector dropdown-toggle": props.identifier === 'type__in'},
                    {"status-pip dropdown-toggle": (props.identifier === 'description_status__in' || props.identifier === 'scoring_status__in')},
                    {"faux-text-filter": props.identifier === 'is_collective_labour_agreement'},
                )}
                onClick={ () => setPopOverActive(!popOverActive, full_identifier) }>
                {props?.name}
                {(props.identifier !== 'scoring_status__in' && props.identifier !== 'description_status__in') && (
                    <>
                        {state.length > 0 ? (
                            <div className="dropdown-toggle__counter">
                                {state.length}
                            </div>
                        ) : (
                            <>
                                <Icon icon={"angle-" + (popOverActive ? "up" : "down")} sizeModifier={"small"}/>
                            </>
                        )}
                    </>
                )}
            </div>

            <div className={cx("dropdown", {"dropdown--active": popOverActive})}>
                {props.options?.map(item => {
                    return (
                        <div className={"dropdown-field dropdown-field--checkbox"} key={full_identifier + '-' + item.identifier}>
                            <input
                                id={full_identifier + '-' + item.identifier}
                                className={"dropdown-field__input"}
                                onChange={(e) => {
                                    let newOpts = e.target.checked ? !state.includes(e.target.value) ? [...state, e.target.value] : [...state] : state.filter(o => o !== e.target.value);
                                    props.setFilter(props.identifier, newOpts);
                                }}
                                checked={state.includes(item.identifier)}
                                type={"checkbox"}
                                value={item.identifier}/>
                            <label className={"dropdown-field__label"} htmlFor={full_identifier + '-' + item.identifier}>
                                {item?.children}
                                {(typeof item.title === "string") ? t(item.title) : item.title}
                            </label>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export const DropdownFilter = singlePopOver(DefaultDropdownFilter);
