import React from 'react';
import cx from "classnames";

export const IconPatternState = {
    icon: 'hamburger',
    onClick: () => console.log('Clicked!')
}

export function Icon(props) {

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             onClick={props.onClick}
             data-cy={props["data-cy"]}
             className={cx(
                'icon',
                {'icon--large': props.sizeModifier === 'large'},
                {'icon--small': props.sizeModifier === 'small'},
                {'icon--xsmall': props.sizeModifier === 'xsmall'},
                {'icon--tiny': props.sizeModifier === 'tiny'},
                props.className
            )}>
            <use href={`#${props.icon}`}/>
        </svg>
    )
}

export default React.memo(Icon)
