import React from "react";
import useSWR from "swr";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import SelectTemp from "../patterns/atoms/SelectTemp";

export default function FunctionGroupDropdownFilter(props) {
    const {data: getProject} = useSWR(`/api/projects/${props.project_id}/`);

    if(!getProject) {
        return <LoaderAnimation/>;
    }

    const {data: getFunctionGroupStructure} = useSWR(getProject.function_group_structure.detail_url);

    if(!getFunctionGroupStructure) {
        return <LoaderAnimation/>;
    }

    const options = getFunctionGroupStructure.groups.map((group) => {
        return {
            display_name: group.label,
            value: group.number
        }
    })

    return (
        <SelectTemp options={options}
                value={props.state?.[props.identifier] || ""}
                placeholder={true}
                input={{ onChange: (e) => {props.setFilter(props.identifier, e.target.value)}}}/>
    )
}
