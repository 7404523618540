import React from "react";
import {TextFilter} from "./TextFilter";
import cx from "classnames";
import {FUWATERScores, MNTLetters} from "../../../utils/constants";
import {NumberFilter} from "./NumberFilter";

export function MNTFilter(props) {

    const keyOptions = [...(props?.filterOptions?.type === "vbalans" ? FUWATERScores : MNTLetters), "", "-"];

    return (
        <>
            {!props.contextData?.noFunctionGroup &&
                <TextFilter identifier={"function_group"}
                            placeholder={"-"}
                            maxLength={4}
                            setFilter={props.setFilter}
                            state={props.state}
                            className={"pattern-filter__group"}
                />
            }

            <div className={cx("pattern-filter__domain", {
                "pattern-filter__domain--vbalans": props?.filterOptions?.type === "vbalans",
                "pattern-filter__domain--no-margin": props.patternFilterNoMargin === true
            })}>
                {props?.domains.map( (item,idx) => {
                    const val = props.state?.[item.identifier] || '';
                    return (
                        <input
                            key={item.identifier}
                            className={cx('text-filter', "pattern-filter__domain-letter", {
                                "pattern-filter__domain-letter--wide": (!props.advancedFilterClass && !props.contextData.canViewPoints),
                                "pattern-filter__domain-letter--sidebar": props.advancedFilterClass
                            })}
                            type={"text"}
                            placeholder={"-"}
                            onChange={(e) => {
                                const val = e.target.value.toUpperCase();
                                if(keyOptions.includes(val)) {
                                    props.setFilter(item.identifier, val);
                                }
                            }}
                            value={val}
                            maxLength={1}
                        />
                    )
                })}
            </div>

            {props.contextData.canViewPoints ? (
                <NumberFilter identifier={"points"}
                            placeholder={"-"}
                            maxLength={3}
                            setFilter={props.setFilter}
                            state={props.state}
                            className={"pattern-filter__points"}
                            hideControls={true}
                />
            ) : null}
        </>
    )

}

export function MNTFilterGlobal(props) {

    return (
        <>
            <TextFilter  identifier={"function_group"}
                         placeholder={"-"}
                         maxLength={3}
                         setFilter={props.setFilter}
                         state={props.state}
                         className={"pattern-filter__group"}
            />

            <div className={cx("pattern-filter__domain pattern-filter__domain--placeholder", {
                "pattern-filter__domain--vbalans": props?.filterOptions?.type === "vbalans"
            })} />

            {props.contextData.canViewPoints ? (
                <TextFilter identifier={"points"}
                            placeholder={"-"}
                            maxLength={3}
                            setFilter={props.setFilter}
                            state={props.state}
                            className={"pattern-filter__points"}
                />
            ): null }
        </>
    )

}
