import React, { useEffect, useState} from "react";
import {generatePath, useLocation, useNavigate, useParams} from "react-router-dom";
import {getClient} from "../queries/client_detail";
import {getProject} from "../queries/project_detail";
import {getProjectGroupFunctions} from "../queries/client_project_functions";
import axios from "axios";
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import cx from "classnames";
import Icon from "../patterns/atoms/Icon";
import Notification from "../patterns/molecules/Notification";
import {instantDownload} from "../utils/instantDownload";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";

export default function Export(props) {

    const {t, i18n} = useTranslation();
    let location = useLocation();
    let { client_id, project_id } = useParams();
    const client = getClient(client_id);
    const project = getProject(project_id);
    let navigate = useNavigate();


    const [selection, setSelection] = useState([]);
    const [functions, setFunctions] = useState([]);
    const [downloadURL, setDownloadURL] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setSelection( Object.entries(location.state || {}) .filter(o => o[1] === true) .map(o => parseInt(o[0]) ) );
    },[location.state])

    useEffect(() => {
        if(project?.data?.id && selection.length > 0) {
            (async () => {
                let url = `/api/projects/${project?.data?.id}/functions/?page_size=1000`;
                const response = await axios.get(url + "&id__in=" + selection.slice(0,15).join(',')).then(result => result);
                setFunctions(response?.data?.results);

            })();
        }

    },[project?.data?.id, selection]);

    let title = t("Exporteren naar Excel");
    const model = props?.model ? props.model : 'export_function_list';
    const type  = props?.type ? props.type : 'xlsx';
    let endpoint = `/export/${model}/${type}/`;
    let ctaGenerate = t("Genereer Excel-bestand");
    let ctaDownload = t("Download Excel-bestand");

    if(props?.type === 'pdf') {
        title = t("Exporteren naar PDF")
        ctaGenerate = t("Genereer PDF-bestand");
        ctaDownload = t("Download PDF-bestand");
    }

    let data = {
        id__in: selection?.map(o => o) || [],
    };

    if(client_id) {
        data['client_id'] = parseInt(client_id);
    }

    if(project_id) {
        data['project_id'] = parseInt(project_id);
    }

    const extension =  props?.type === 'pdf' ? 'pdf':'xlsx';
    const date = new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
        // hour: 'numeric',
        // minute: 'numeric',
    }).format(new Date());

    const config = {
        export_client_list: {
            type_title: t("Klant"),
            filename: `${t("Klanten")} ${date} - FUWA Online.${extension}`,
        },
        export_user_list: {
            type_title: t("Gebruiker"),
            filename: `${project?.data?.client?.name} - ${t("Gebruikers")} ${date} - FUWA Online.${extension}`,

        },
        export_project_list: {
            type_title: t("Project"),
            filename: `${client?.data?.name} - ${t("Projecten")} ${date} - FUWA Online.${extension}`,

        },
        export_function_list : {
            type_title: t("Functie"),
            filename: `${project?.data?.client?.name} - ${project?.data?.name} - ${t("Functies")} ${date} - FUWA Online.${extension}`,
        }
    }

    const currentConfig = config[model];

    const generateExport = async () => {

        axios.post(endpoint, data, {
            responseType: 'blob',
        }).then( response => {
            // setDownloadURL(window.URL.createObjectURL(new Blob([response.data], {type: response.data?.type})));

            instantDownload(response.data, currentConfig.filename);
            setGenerating(false);
            setDisabled(true);
        })
    };

    return (
        <>
            <div className={"form-container__header"}>
                <h1 className={"form-container__title"}>{currentConfig.type_title}: {title}</h1>
                <h1 className={"form-container__sub-title"}>{project?.data?.name}</h1>
            </div>
            {functions?.length > 0 && (
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>De volgende {selection.length} items worden geëxporteerd</legend>
                    <ul className={"export-list"}>
                        {functions.slice(0,15).map( o => (
                                <li className={"export-list__item"} key={o.id}>
                                    {o.name}
                                </li>
                            )
                        )}
                    </ul>
                    {!(disabled &&!generating) && selection?.length > 15 && (
                        <Notification type="info" text={t("Alleen de eerste 15 geselecteerde functies worden weergegeven.")}/>
                    )}
                    { (disabled && !generating) && (
                        <Notification type="warning" text={t("Het bestand is gereed en wordt gedownload. U kunt dit venster sluiten")}/>
                    )}
                </fieldset>
            )}

            {downloadURL ? (
                <ButtonPrimary fullWidth={"true"} icon={"download"}>{ctaDownload}</ButtonPrimary>
            ) : !(disabled && !generating) ? (
                <button
                    className={cx('button', 'button--primary button--full-width')}
                    disabled={disabled}
                    onClick={e => {
                        e.preventDefault();
                        generateExport();
                        setDisabled(true);
                        setGenerating(true);
                    }}
                >
                    {ctaGenerate}
                    {generating && (
                        <div className={"button__loader"}>
                            <LoaderAnimation small={true} infinite={true}/>
                        </div>
                    )}
                </button>
            ) : (
                    <ButtonPrimary fullWidth={"true"} icon={"close"} onClick={() => {
                        if(location?.state?.cancel) {
                            navigate(location.state.cancel);
                        } else {
                            navigate('../../');
                        }
                    }}>
                        {t("Sluiten")}
                    </ButtonPrimary>

                )}

        </>
    )
}
