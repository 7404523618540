import { ADD } from '../actions/patternlab.jsx';

const defaultState = {
    atoms: {},
    molecules: {},
    organisms: {},
    templates: {},
    pages: {}
};

export function PatternStructureState(state=defaultState, action) {

    switch(action.type) {
        case ADD:
            let newState = {
                ...state,
                [action.cat]:  {
                    ...state[action.cat],
                    [action.name]: {
                        name: action.name,
                        props: action.props,
                    }
                }
            }
            return newState;

        default:
            return state;
    }

}

