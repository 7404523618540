import React from "react";
import Overview from "./Overview";
import { UserSelectionDefinition } from "./Overviews/UserSelection";
import Drawer from "./Drawer";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import { getUser } from "../queries/user";

export default function AutocompleteUserDrawer(props) {
    let initialReadOnlyVal;

    if(props?.filter && props?.filter !== "user__in" && props?.filterState[props.filter]) {
        const {data: user, isLoading} = getUser(props.filterState[props.filter]);

         if(isLoading) {
            return <LoaderAnimation />;
         }

        initialReadOnlyVal = props.filterState[props.filter] ? user?.full_name : props.filterState[props.filter]
    }

    return (
         <Drawer
            title={props.title}
            label={props.label}
            initialReadOnlyVal={initialReadOnlyVal}
            readOnlyVal={obj => {
                if(obj.insertion){
                   return obj.first_name + " "  + obj.insertion + " " + obj.last_name
                } else {
                    return obj.first_name + " " + obj.last_name
                }}
            }
            placeholder={" "}
            id={props.filter}
            isForm={true}
            disabled={false}
            resetAutocomplete={props.resetAutocomplete}

            reset={() => {
                props.setFilter(props.filter, "")
            }}

            onClick={ (obj) => {
                if(props.filter === "user__in"  && props.filterState[props.filter]){
                    if(!props.filterState[props.filter].includes(obj.id)) {
                        props.setFilter(props.filter, [...props.filterState[props.filter], obj.id])
                    }
                } else {
                    if(typeof props?.setFilter === "function"){
                        props.setFilter(props.filter, [obj.id])
                    }
                }
            }}

            contentComponent={
                <Overview
                    identifier={"function-advanced-filter-" + props.filter}
                    definition={UserSelectionDefinition}
                    initialFilters={{
                        is_active: ['true', 'false']
                    }}
                />
            }
         />
    )
}
