import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {clearFilter, clearFilters, initState, setFilter, setFilters, setOption} from "../actions/overview";
import OverviewDisplay from "../patterns/organisms/Overview";

// determine if we want to abstract useNavigate as NIBHV doesnt have newest react-router-dom
import {useNavigate} from "react-router-dom";


// Should be provided using default fetcher in app enclosing <SWRConfig/>
export const fetcher = url => axios.get(url).then(res => res.data);

// Default state for an Overview
const defaultState = {
    identifier: false,
    bulkEnabled: false,
    bulk: {},
    filter: {},
    ordering: {
        columnIdentifier: "",
        direction: "",
    },
    type: 'indexInfinite',
    pagination: {
        cursorPaginator: {
            offset: 0,
            size: 10,
        },
        indexPaginator: {
            page: 1,
            size: 1,
        },
        cursorInfinite: {
            offset: 0,
            size: 10,
            sets: 1,                // the amount of sets (pages) to load (or have been loaded)
            loadAllAllowed: false,
        },
        indexInfinite: {
            page: 1,
            size: 10,
            sets: 1,                // the amount of sets (pages) to load (or have been loaded)
            loadAllAllowed: false,
        }
    }
};

   // Set a filter so the results will be filtered
    export const setStateFilterPure = (columnIdentifier, passedValue, localStateIdentifier, dispatch) => {
        let value = false;

        // provide backwardscompatibility
        if(typeof passedValue === 'string' || Array.isArray(passedValue) || typeof passedValue === 'boolean' || typeof passedValue === "number") {
            value = passedValue;
        } else {
            if(('target' in passedValue && 'value' in passedValue.target)) {
                console.log("Event passed instead of value, please fix")
                value = passedValue?.target?.value ? passedValue.target.value : "";
            }
        }

        dispatch(setOption(localStateIdentifier, {
            offset: 0,
        }));
        dispatch(setFilter(localStateIdentifier, {
            columnIdentifier,
            value,
        }));
    }

// This is where the magic happens. It handles all logic for the Overview component
function Overview(props, ref) {

    // create a ref for accurate (real) dom access (like scrolling when filter is reset)
    const titleRef = useRef();

    // create an overviewRef which can be passed back to the parent so we can trigger a mutate
    const overviewRef = useRef();

    // creates a custom handle for this component which can handle the passed ref (in this components props) and assign something to it
    useImperativeHandle(ref, () => ({

        // We expose a mutate funciton, which triggers the mutate on the overview. This is useful when a parent needs to trigger an update but nothing more
        mutate: () => overviewRef.current.mutate()
    }));

    // determine if a different ref is passed for scrolling (like scrolling within a div instead of window)
    const scrollRef = props?.scrollInsideRef ? props.scrollInsideRef : titleRef;

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const stateIdentifier = { // A (serializable) json object which will be used to identify the (instance) state
        identifier: props.identifier,
        ...props?.identKey
    };

    // (de)serialization only happens when entering state (reducer) and retrieving the state (here)
    let state = useSelector(state => state.OverviewState?.[JSON.stringify(stateIdentifier)]);

    // Initialize a state for this instance of Overview but only when identifier has changed
    useEffect(() => {
        if(!state) {
            if(!props?.identifier) {
                console.log("No identifier set!")
            }
            const type = Object.keys(defaultState.pagination).includes(props?.type) ? props.type : defaultState.type;
            // We overlay objects to create a final initial state
            let initialState = Object.assign(
                {},
                defaultState,                                                       // low-level defaults
                {
                    identifier: props.identifier,                                   // overlay the identifier (NOT the stateIdentifier!)
                    identKey: props?.identKey,                                      // additional vital information required for querying
                    type: type,
                },
                props.definition?.defaultOrderIdentifier ? {
                    ordering: {
                        ...defaultState.ordering,
                        columnIdentifier: props.definition.defaultOrderIdentifier
                    }
                } : {},
                {
                    pagination: {
                        ...defaultState.pagination,
                        [type]: {
                            ...defaultState.pagination[type],
                            loadAllAllowed: props?.definition?.loadAllAllowed === true || defaultState.pagination[type].loadAllAllowed,
                            size: props?.definition?.paginationSize || defaultState.pagination[type].size,
                        }
                    }
                },
                props.definition?.defaults ? {...props.definition.defaults} : {},   // overlay defaults if provided by the definition
                props?.initialFilters ? {
                    filter: {
                        ...defaultState.filter,
                        ...props.initialFilters
                    }
                } : {},        // overlay initialFilters if available
            );

            if(props?.definition?.noHeader === true && props?.definition?.bulkEnabled === true) {
                initialState.bulkEnabled = true;
            }

            dispatch(initState(stateIdentifier, initialState));
        }
        return () => {
            // console.log("Unmounting identity", props?.identifier);
        }
    }, [props?.identifier]);  // when identity changes, recheck. We don't do this in [] (one time only) as it could produce strange results

    // We can force a filter which is not override-able by the user/overview itself
    useEffect(() => {
        if(props.forceFilter) {
            props.forceFilter.map((o,idx) => {
                dispatch(setFilter(stateIdentifier, {
                    identifier: o.identifier,
                    value: o.value
                }));
            });
        }
        return () => {
        }

    },[props?.forceFilter]);

    // we reset the size forcefully (when a parent component can not have direct access to the overview)
    useEffect(() => {
        if(props.forceResetSize) {
            setSize(1);
        }
        return () => {
        }
    },[props?.forceResetSize])

    // Function to load all pages at once (actually sets current resets current set size and does a fetch for 10.000 sets)
    const setLoadAll = () => {
        overview.setSize(1);
        dispatch(setOption(stateIdentifier, {
            size: 1,
            loadAll: true
        }));
    };

    const setBulk = (key) => {
        dispatch(setOption(stateIdentifier, {
            bulk: {
                ...state.bulk,
                [key]: !state.bulk[key],
            }
        }))

    };

    const setMultiBulk = (list, state=true) => {
        dispatch(setOption(stateIdentifier,
            {
                bulk: {
                    ...state.bulk,
                    ...Object.assign({}, ...list.map(o => ({[o]: state}))),
                }
            }
        ));
    }

    const toggleBulk = () => {
        if(state.bulkEnabled) {
            dispatch(setOption(stateIdentifier,
                {
                    bulk: {}
                })
            );
        }
        dispatch(setOption(stateIdentifier, {
            bulkEnabled: !state.bulkEnabled,
        }));
    };

    // initiate ordering of the result
    const setOrder = (columnIdentifier) => {
        if(columnIdentifier) {
            dispatch(setOption(stateIdentifier, {
                ordering: {
                    columnIdentifier,
                    direction: (state.ordering.columnIdentifier === columnIdentifier ? (state.ordering.direction === "-") ? "" : "-": "")
                }
            }));
        }
    };

    // Wrapper function for setStateFilterPure
    const setStateFilter = (columnIdentifier, passedValue) => {
        setStateFilterPure(columnIdentifier, passedValue, stateIdentifier, dispatch)
    };

    const setStateFilters = (filters) => {
        dispatch(setOption(stateIdentifier, {
            offset: 0,
        }));
        dispatch(setFilters(stateIdentifier, {
            ...filters
        }))
    }

    const setClearFilter = () => {
        dispatch(clearFilter(stateIdentifier));
    };

    const setClearFilters = (filters) => {
        dispatch(clearFilters(stateIdentifier, {
            ...filters
        }))
    }

    // Set the cursor offset
    const setOffset = offset => {
        dispatch(setOption(stateIdentifier, {
            offset,
        })) ;
    };

    // Set the size of the overview in 'pages'. A setSize(3) results in 3 pages being loaded next (if 1 and 2 are already present only 3 is loaded)
    const setSize = (val) => {
        overview.setSize(val); // SWR uses 'size' to determine the pages but this somewhat classes as we use 'size' for determining how many items exist ín a set
        dispatch(setOption(stateIdentifier, {
            sets: val,
        }))
    };

    // run the actual querying function and pass the state to it
    const overview = props.definition.queryFn(state);

    // assign overview
    overviewRef.current = overview;

    const count = (overview.data && !overview.error && overview.data[0] && 'count' in overview.data[0]) ? overview.data[0].count : false;

    const size = state?.pagination[state.type].size;

    const setsLoaded = overview?.data?.length;
    const setIdx = setsLoaded ? setsLoaded - 1 : false;
    const loadMore = !!overview?.data?.[setIdx]?.next;

    // we create an object which contains information for the "view" portion of the component. We only provide what it needs
    let passProps = {
        bulkEnabled: state?.bulkEnabled,
        bulkOptions: props.definition?.bulkOptions || false,
        bulkState: state?.bulk || {},
        bulkValue: props.definition?.bulkValue || false,
        columns: props.definition.columns,
        contextData: props?.contextData || {},
        count: count,
        children: props?.children,
        filterState: state?.filter || {},
        loadMore: loadMore,
        loadAllAllowed: state?.pagination[state.type].loadAllAllowed,
        maxSize: count > 0 ? Math.ceil(count / size) : 1,
        navigate: navigate,
        orderingState: state?.ordering || {},
        onClick: props?.onClick || false,
        overview: overview,
        quickview: props?.quickview,
        search: props.definition?.search,
        size: size,
        stateIdentifier: stateIdentifier,
        sidebar: Array.isArray(props.definition?.sidebar) && props.definition.sidebar.length > 0 ? props.definition.sidebar : false,
        titleRef: titleRef,



        interactive: props.definition?.interactive || false,
        noHeader: props.definition?.noHeader || false,
        noOrder: props.definition?.noOrder || false,
        noSearch: props.definition?.noSearch || false,
        noContainer: props.definition?.noContainer || false,
        noFilter : props.definition?.noFilter || false,
        noPagination: props.definition?.noPagination || false,
        noSticky: props.definition?.noSticky || false,
        isDrawer: props?.isDrawer || false,
        // prefix_identifier: 'prefix-IDENT',
        // state: state,
        // definition: definition,
        // url: props.url,

        title: props.definition?.title,
        primary: props.definition?.primary,
        secondary: props.definition?.secondary,
        advancedFilter: props.definition?.advancedFilter,



        RowStructure: props.definition.RowStructure,

        filter: (...args) => {console.log("use setFilter"); setStateFilter(...args)},
        loadAll: (opt) => { console.log('use setLoadAll'); setLoadAll(opt);},
        order: (opt) => { console.log('use setOrder'); setOrder(opt);},
        refresh: () => overview.mutate(),
        setFilter: setStateFilter,
        setFilters: setStateFilters,
        setLoadAll,
        setOffset,
        setOrder,
        setSize,
        toggleBulk,
        setBulk,
        setMultiBulk,
        clearFilter: setClearFilter,
        clearFilters: setClearFilters
    };

    useEffect(() => {
        const count = overview?.data?.[0]?.count;
        if(count >= 0 && scrollRef.current) {
            // use scrollRef as it always points to a ref (only use props?.scrollInsideRef to determine action, not the ref)
            if(props?.scrollInsideRef) {
                scrollRef.current.scrollTop = 0;
            } else {
                let rect = scrollRef.current.getBoundingClientRect();
                if (rect.top < 0) {
                    scrollRef.current.scrollIntoView();
                }
            }
        }
    },[overview?.data?.[0]?.count]);


    // If no data was received (when component is first rendered) create a fake result set which can contain skeleton placeholders
    if(!overview?.data ) {
        const results = [...Array(size).keys()].map((obj,idx) => {return {id: idx, fake: true}; });
        if(['indexPaginator', 'cursorPaginator'].includes(state?.type)) {
            passProps.overview = {
                data: {
                    offset: 0,
                    count: size,
                    results: results,
                }
            };
        } else {
            passProps.overview = {
                size: 1,
                isValidating: true,
                data: [
                    {
                        results: results,
                    }
                ]
            };
        }
    }

    passProps.additions = [];

    // If there was data, but it does not match the default size for our overview, create additional rows for a more uniform experience
    if(['indexPaginator', 'cursorPaginator'].includes(state?.type)) {
        if (overview?.data && overview.data.count < passProps.size) {
            passProps.additions = [...Array((passProps.size - (overview.data.count || 0))).keys()];
        }
    } else {
        if(overview?.size === 1 && overview?.data?.[0]?.results.length < size ) {
            passProps.additions = [ ...Array((passProps.size - (overview?.data?.[0]?.results.length|| 0))).keys()];
        }
    }


    const OverviewTemplate = props?.definition?.displayOverride || OverviewDisplay;

    return (
        <>
            <OverviewTemplate
                {...passProps}
            />
        </>
    );
}

// forwardRef must be used because functional components do not support refs. We use the forwarded ref to expose the mutate function to a parent
export default Overview = forwardRef(Overview);
