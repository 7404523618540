import React from 'react';

export const GroupPatternState = {
    group: 3
}
export default function Group(props) {

    return (
        <div className={"pattern-group"} data-cy={"pattern-group"}>
            {props.group || "-"}
        </div>
    )
}
