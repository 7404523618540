import React, {useEffect, useState} from "react";
import ProjectMeta from "../ProjectMeta";
import {generatePath, useParams} from "react-router-dom";
import Quickview from "../Quickview";
import Overview from "../Overview";
import {ClientProjectOverviewDefinition} from "./ClientProjectOverview";
import {useTranslation} from "react-i18next";
import {getProject, remoteAddProject} from "../../queries/project_detail";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import Drawer from "../Drawer";
import {ProjectSelectionDefinition} from "./ProjectSelection";
import {getProjectProjects} from "../../queries/project_projects";
import {useAuth} from "../../utils/useAuth";

export function ProjectProjectsOverview(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const { client_id, project_id } = useParams();
    const [ selectProject, setSelectProject ] = useState(false);
    const {data: projectDetail, mutate} = getProject(project_id);


    /// TODO: client/project is passed, but endpoint might be wrong as it is the basic call for projects, which does not
    /// TODO: yet find related projects. This was only done so the page exists

    /// TODO: make sure "linked" ("PRojectgroepen") projects are correctly identified
    /// TODO: make sure own project is not displayed

    if(!projectDetail) {
        return <LoaderAnimation/>
    }


    const canChangeProject = auth.hasPermission("base.change_project", projectDetail.id, 'project');
    const hasAddProjectPrimary = canChangeProject ? {
        title: t("Extra project koppelen"),
        identifier: 'link-project',
        action: () => setSelectProject(true)
    } : {};

    return (
        <>
            <ProjectMeta/>

            {selectProject && (
                <Drawer
                    title={t("Selecteer Project")}
                    buttonText={t("Zoek project")}
                    label={t("Project")}
                    initialReadOnlyVal={props?.project?.name}
                    readOnlyVal={obj => obj.name}
                    placeholder={t("Geen project gekozen")}
                    isForm={false}
                    noButton={true}
                    noReset={true}
                    initialOpen={true}

                    reset={() => {
                        // props.form.change('project_id', '');
                    }}

                    onClick={ async (obj) => {
                        // props.form.change('project_id', obj.id);
                        await remoteAddProject(project_id, obj.id);
                        mutate();
                    }}

                    closeCallback={() => setSelectProject(false) }

                    contentComponent={
                        <Overview
                            identifier={"project-projects-select-project"}
                            identKey={{
                                client_id: props.client_id,
                            }}
                            contextData={{
                                location,
                            }}
                            definition={ProjectSelectionDefinition}
                            initialFilters={{
                                status__in: ['concept', 'determined', 'expired']
                            }}
                        />
                    }
                />
            )}

            <div className={"content-container"}>

                <Quickview>
                    <Overview
                        identifier={"project-projects"}
                        quickview={{
                            identifier: "project-projects",
                            name: t("Gekoppelde projecten")
                        }}
                        identKey={{
                            client_id,
                            project_id,
                        }}
                        contextData={{
                            location,
                            client_id,
                            project_id,
                        }}
                        onClick={() => false}
                        definition={{
                            ...ClientProjectOverviewDefinition,
                            identifier: 'project-projects-overview',
                            queryFn: getProjectProjects,
                            primary: hasAddProjectPrimary,
                            columns: ClientProjectOverviewDefinition.columns.filter(o =>  o.identifier!== "action" || canChangeProject)
                        }}
                    />
                </Quickview>
            </div>
        </>
    )
}
