import React, {useEffect, useState} from "react";
import {
    ClientProjectFunctionOverviewDefinitionDefault,
    FunctionStatusChangeModal
} from "./ClientProjectFunctionOverview";
import {getAllFunctions} from "../../queries/client_project_functions";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import {NameSourcetypeFilter} from "./Filters/NameSourcetypeFilter";
import {MNTTitle, MNTTitleGlobal} from "./Titles/MNTTitle";
import {FunctionSource, MNTDomains, VBalansDomains} from "../../utils/constants";
import {MNTFilter, MNTFilterGlobal} from "./Filters/MNTFilter";
import {FunctionStatusFilter} from "./Filters/StatusFilter";
import {DateFilter} from "./Filters/DateFilter";
import {TextFilter} from "./Filters/TextFilter";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {useTranslation} from "react-i18next";
import {generatePath} from "react-router-dom";
import {prefetchFunction} from "../../queries/function_detail";
import cx from "classnames";
import OverviewName from "../../patterns/molecules/OverviewName";
import GroupPatternScore from "../../patterns/molecules/GroupPatternScore";
import {FunctionStatus} from "../../patterns/atoms/Pip";
import {InfoButton} from "../../patterns/molecules/InfoButton";
import FunctionTypeIndicator from "../../patterns/atoms/FunctionTypeIndicator";
import i18n from "../../utils/i18n";
import MethodFilter from "./Filters/MethodFilter";
import TypeFilter from "./Filters/TypeFilter";

export const AllFunctionRowStructure = React.memo(tempProps => {

    const {t, i18n} = useTranslation();

    let props = Object.assign(
        {
            id: false,
            type: 'unknown',
            name: t(""),
            pattern_list: [],
            version_number: "",
            project: {
                name: (<>&nbsp;</>)
            },
            function_group_label: "-",
            points: "-",
            displayPoints: false,
            comparison: false,
            modification_date: false,
        },
        tempProps,
    );
    // may be passed to OverviewName but this is the default in OverviewName  as well
    // const lengths = {
    //     small: 15,
    //     medium: 20,
    //     large: 25,
    //     xlarge: 30,
    //     full: 35,
    // }

    let detailUrl = props?.client?.id ? generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
        client_id: props.client.id,
        project_id: props.project.id,
        function_id: props.id
    }) : false;

    if(props?.favorite_info) {

        detailUrl = generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
            client_id: props?.favorite_info.client_id,
            project_id: props?.favorite_info.project_id,
            function_id: props?.favorite_info.id
        })
    }

    const detailClick = props?.onClick ? () => props.onClick(props) : (e) => {
        props.navigate( detailUrl );

    }
    const kind = () => {
        if(props.is_collective_labour_agreement) {
            return FunctionSource["cao"]
        }

        // project, based_on, uses, method, example
        if(props.source) {
            return FunctionSource[props.source];
        }
    }

    const creation_date = props.creation_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date(props.creation_date)) : false;
    const modification_date = props.modification_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date(props.modification_date)) : false;

    return (
        <tr
            onMouseEnter={() => !props?.fake && prefetchFunction(props.id)}
            key={props.id}
            className={"overview-table__row"}
        >

            {props?.bulkColumn && props.bulkColumn }

            {props.columns.some(col => col.identifier === 'type') && (
                <td className={"overview-table__cell"}>
                    <FunctionTypeIndicator type={props.type}/>
                </td>
            )}

            <td className={cx("overview-table__cell overview-table__cell--name", {"overview-table__cell--interactive": detailUrl !== false })}
                onClick={detailClick}
            >
                <OverviewName
                    {...props}
                    kind={kind()}
                />
            </td>
            {props.columns.some(col => ['vbalans', 'mnt'].includes(col.identifier)) && (
                <td className={"overview-table__cell"}>
                    <GroupPatternScore {...props} comparison={false} displayPoints={false} type={props.type}/>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'description_status__in') && (
                <td className={"overview-table__cell overview-table__cell--no-padding"}>
                    <FunctionStatus description_status={props.description_status}
                                    scoring_status={props.scoring_status}
                                    functionId={props.id}/>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'method__name') && (
                <td className={"overview-table__cell"}>
                    <div className="overview-table__text" title={props?.method?.name}>
                        {props?.method?.name}
                    </div>
                </td>
            )}

            {props.columns.some(col => col.identifier === 'client__name') && (
                <td className={"overview-table__cell overview-table__cell--interactive"}
                    onClick={() => {
                        props?.onClick ? props.onClick(props) : props.navigate(generatePath('/client/:client_id/', {
                            client_id: props.client.id,
                        }));
                    }}
                >
                    <div className={"overview-table__text"} title={props?.client?.name}>
                        {props?.client?.name}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'project__name') && (
                <td className={"overview-table__cell overview-table__cell--interactive"}
                    onClick={() => {
                        props?.onClick ? props.onClick(props) : props.navigate(generatePath('/client/:client_id/project/:project_id/', {
                            client_id: props.client.id,
                            project_id: props.project.id,
                        }));
                    }}
                >
                    <div className={"overview-table__text"} title={props?.project?.name}>
                        {props?.project?.name}
                    </div>
                </td>
            )}
            {props.columns.some(col => col.identifier === 'modification_date') && (
                <td className={"overview-table__cell"}>
                    {props.modification_date  ? (

                        <div className={"overview-table__text"}>
                            <time dateTime={props.modification_date}>
                                {new Intl.DateTimeFormat("nl-NL", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric"
                                }).format(new Date(props.modification_date))}
                            </time>
                        </div>
                    ) : (
                        <div className={"overview-table__skeleton"}/>
                    )}
                </td>
            )}
            {props.columns.some(col => col.identifier === 'info') && (
                <td className={"overview-table__cell overview-table__cell--buttons"}>
                    <InfoButton
                        icon={"info"}
                        sizeModifier={"medium"}
                        prefix_identifier={"client-project-overview"}
                        id={props.id}
                        title={t("Meer informatie")}
                    >
                        <div className={"popover__content"}>
                            <div className={"popover__title"} title={props.name}> {props.name} </div>
                        </div>
                        <div className={"popover__content"}>
                            <dl className={"popover-info popover-info--duo"}>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Versie")}</dt>
                                    <dd className={"popover-info__value"}>{props.version_number || "-"}</dd>

                                    <dt className={"popover-info__key"}>{t("Aanmaakdatum")}</dt>
                                    <dd className={"popover-info__value"}>{creation_date || "-"}</dd>

                                    <dt className={"popover-info__key"}>{t("Project")}</dt>
                                    <dd className={"popover-info__value"}>{props?.project?.name || "-"} </dd>

                                </div>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Functiecode")}</dt>
                                    <dd className={"popover-info__value"}>{props.code || "-"} </dd>

                                    <dt className={"popover-info__key"}>{t("Wijzigingsdatum")} op</dt>
                                    <dd className={"popover-info__value"}>{modification_date || "-"} </dd>

                                    <dt className={"popover-info__key"}>{t("Klant")}</dt>
                                    <dd className={"popover-info__value"}>{props?.client?.name || "-"}</dd>
                                </div>
                            </dl>
                        </div>
                        <div className={"popover__content"}>
                            <dl className={"popover-info popover-info--duo"}>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Hoofdafdeling")}</dt>
                                    <dd className={"popover-info__value"}>{props?.department?.name || "-"}</dd>

                                </div>
                                <div className={"popover-info__column"}>

                                    <dt className={"popover-info__key"}>{t("Subafdeling")}</dt>
                                    <dd className={"popover-info__value"}>{props?.sub_department?.name || "-"}</dd>
                                </div>
                            </dl>
                        </div>

                        <div className={"popover__content"}>
                            <dl className={"popover-info popover-info--duo"}>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
                                    <dd className={"popover-info__value"}>
                                        {props.describer_group ?
                                            props.describer_group?.name
                                            :
                                            props?.describer?.get_full_name || "-"
                                        }
                                    </dd>
                                </div>
                                <div className={"popover-info__column"}>
                                    <dt className={"popover-info__key"}>{t("Toepasser")}</dt>
                                    <dd className={"popover-info__value"}>
                                        {props.applier_one_group ?
                                            props.applier_one_group?.name
                                            :
                                            props?.applier_one?.get_full_name || "-"
                                        }
                                    </dd>

                                    <dt className={"popover-info__key"}>{t("Toetser")}</dt>
                                    <dd className={"popover-info__value"}>
                                        {props.applier_two_group ?
                                            props.applier_two_group?.name
                                            :
                                            props?.applier_two?.get_full_name || "-"
                                        }
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </InfoButton>
                </td>
            )}
        </tr>
    )
});

export const FunctionOverviewDefinition = {
    ...ClientProjectFunctionOverviewDefinitionDefault,
    loadAllAllowed: false,
    identifier: 'function-overview',
    queryFn: getAllFunctions,
    RowStructure: AllFunctionRowStructure,
    bulkOptions: false,
    columns: [
        {
            title: i18n.t('Type'),
            identifier: 'type',
            type: 'dropdown',
            className: 'overview-table__filter-cell--width-type',
            orderable: true,
            Filter: TypeFilter,
            options: [
                {
                    title: i18n.t('Referentie'),
                    identifier: 'reference'
                },
                {
                    title: i18n.t('Bedrijfseigen'),
                    identifier: 'company',
                },
                {
                    title: i18n.t('Inpasbare functie'),
                    identifier: 'match',
                },
            ],
        },
        {
            title: i18n.t("Naam"),
            identifier: 'name',
            type: "name-kind",
            orderable: true,
            Filter: NameSourcetypeFilter,
            FilterOptions: [
                {
                    title: i18n.t('CAO'),
                    identifier: 'true'
                },
                {
                    title: i18n.t('Niet CAO'),
                    identifier: 'false'
                },
            ]
        },
        {
            type: 'mnt',
            identifier: 'mnt',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: { domains: MNTDomains},
            Filter: MNTFilter,
            FilterOptions: { domains: MNTDomains},
            Skeleton: GroupPatternScore,
        },
        {
            type: 'vbalans',
            identifier: 'vbalans',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: {
                domains: VBalansDomains,
                type: "vbalans",
            },
            Filter: MNTFilter,
            FilterOptions: {
                domains: VBalansDomains,
                type: "vbalans",
            },

        },
        {
            title: i18n.t('Stat.'),
            identifier: 'description_status__in',
            type: 'status-filter',
            orderable: false,
            className: 'overview-table__filter-cell--width-status',
            Filter: FunctionStatusFilter
        },
        {
            title: i18n.t('Methode'),
            identifier: 'method__name',
            type: 'dropdown',
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Methode'),
            Filter: MethodFilter,
        },
        {
            title: i18n.t('Klant'),
            identifier: 'client__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Naam'),
            type: TextFilter,
        },
        {
            title: i18n.t('Project'),
            identifier: 'project__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Naam'),
            type: TextFilter,
        },
        {
            title: i18n.t('Bijgewerkt'),
            identifier: 'modification_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter
        },
        {
            identifier: 'info',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],
}
