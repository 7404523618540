import React from "react";

import {TextFilter} from "./Filters/TextFilter";
import {ProjectStatusFilter} from "./Filters/StatusFilter";
import {getDepartments} from "../../queries/department";
import {defaultPagination} from "../Overview";
import i18n from "../../utils/i18n";


const StandardRowStructure = React.memo(tempProps => {

    let props = Object.assign(
        {
            id: false,
            name: "",
            method: "",
            status: "",
        },
        tempProps,
    );

    const detailClick = props?.onClick;

    return (
        <tr key={props.id}
            onClick={() => {
                detailClick(props);
            }}
            className={"overview-table__row"}
        >
            <td className={"overview-table__cell overview-table__cell--name overview-table__cell--interactive"} data-cy={"overview-table-cell-name"}>{ props.name ? props.name : ''}</td>
            <td className={"overview-table__cell overview-table__cell--name"}>{ props.client?.name ? props.client.name : ''}</td>
        </tr>
    )
});

export const DepartmentSelectionDefinition = {

    title: i18n.t("Afdelingen"),
    identifier: 'department-overview',
    queryFn: getDepartments,
    RowStructure: StandardRowStructure,
    noHeader: true,
    noSearch: true,
    noContainer: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: i18n.t('Naam'),
            orderable: true,
            identifier: 'name',
            className:'overview__name',
            filter: TextFilter,
        },
        {
            title: i18n.t('Klant'),
            orderable: true,
            identifier: 'client__name',
            className: 'overview-table__filter-cell--width-pattern-widget',
            filter: TextFilter,
        },
    ],
    paginationSize: 5,
    loadAllAllowed: true,
}

