import React, {Fragment, useEffect} from "react";
import {getLinks, putLinks} from "../queries/sitesettingslinks";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {CheckboxInput, Input} from "../patterns/atoms/Input";
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import Noty from "noty";
import {
    getAgendaItems,
    putAgendaItems,
} from "../queries/sitesettingsagendaitems";
import ButtonQuaternary from "../patterns/atoms/ButtonQuaternary";
import DatePickerFilter from "./Overviews/Filters/DatePickerFilter";
import {getYear} from "date-fns";

export default function LoginAdmin() {
    return (
        <>
            <Links/>
            <AgendaItems/>
        </>
    )
}

function Links() {
    const {data: links, mutate: linksMutate} = getLinks();
    const {
        register,
        handleSubmit,
        setError,
        control,
        clearErrors,
        reset,
        setValue,
        watch,
        formState: {errors}
    } = useForm();
    const {t} = useTranslation();

    const {fields, append, remove} = useFieldArray({
        control,
        name: "links"
    });

    useEffect(() => {
        if (links) {
            reset({"links": links.results})
        }
    }, [links])

    const onSubmit = () => async (values) => {
        putLinks(values.links.filter(value => !value.delete)).then((result) => {
            linksMutate();
            fields.map((link, index) => {
                setValue(`links.${index}.delete`, null)
            })

            new Noty({
                theme: 'bootstrap-v4',
                layout: 'bottomRight',
                text: t("Je wijziging is opgeslagen!"),
                closeWith: ['click', 'button'],
                type: "success",
                timeout: 2000,
            }).show();
        })
    }

    return (
        <form onSubmit={(e) => {
           const removeEmptyFields = watch("links").reduce((acc, curr, index) => {
                if(!curr.label && !curr.order && !curr.target && curr.delete) {
                    acc.push(index)
                }
                return acc
            }, []);

            remove(removeEmptyFields);

            clearErrors("server");

            handleSubmit(onSubmit())(e).catch((error) => {
                if (error.cause.message === "server-error-link-create" || error.cause.message === "server-error") {
                    setError("server", {
                        type: "server",
                        message: t("Er is iets verkeerd gegaan.")
                    })
                }
            })
        }}>
            <div className={"tool"}>
                <div className={"tool-header tool-header--border"}>
                    <h1 className={"tool-header__title"}>{t("Links")}</h1>
                </div>
                <div className={"tool__content"}>
                    <div className={"login-admin"}>
                        {fields.map((link, index) => (
                            <Fragment key={index}>
                                <div className={"login-admin__item"}>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`links.${index}.target`}
                                            type={"text"}
                                            id={"target"}
                                            label={t("Url")}
                                            data-cy={"target-input"}
                                            register={register}
                                            error={errors.links?.[index]?.target}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`links.${index}.label`}
                                            type={"text"}
                                            id={"label"}
                                            label={t("Zichtbare naam")}
                                            data-cy={"label-input"}
                                            register={register}
                                            error={errors.links?.[index]?.label}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`links.${index}.order`}
                                            type={"number"}
                                            id={"order"}
                                            label={t("Volgorde")}
                                            data-cy={"order-input"}
                                            register={register}
                                            error={errors.links?.[index]?.order}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <CheckboxInput
                                            name={`links.${index}.delete`}
                                            type={"checkbox"}
                                            id={`links.${index}.delete`}
                                            label={t("Verwijderen")}
                                            data-cy={"delete-input"}
                                            register={register}
                                            error={errors.links?.[index]?.delete}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className={"tool__footer tool__footer--split-layout"}>
                    <ButtonQuaternary
                        icon={"add"}
                        text={t("Link toevoegen")}
                        data-cy={"add-link-button"}
                        onClick={() => append({target: "", label: "", order: "", delete: false})}
                        type={"button"}
                    />
                    <ButtonPrimary
                        text={t("Opslaan")}
                        data-cy={"link-submit-button"}
                    />
                </div>
            </div>
        </form>
    )
}

function AgendaItems() {
    const {data: agendaItems, mutate: agendaItemsMutate} = getAgendaItems();
    const {
        register,
        handleSubmit,
        setError,
        control,
        clearErrors,
        reset,
        setValue,
        watch,
        formState: {errors}
    } = useForm();
    const {t} = useTranslation();

    const {fields, append, remove} = useFieldArray({
        control,
        name: "agendaItems"
    });

    useEffect(() => {
        if (agendaItems) {
            reset({
                "agendaItems": agendaItems.results.map((result) => {
                    return {
                        ...result,
                        start_date: result.start_date ? new Date(result.start_date) : null,
                        end_date: result.end_date ? new Date(result.end_date) : null
                    }
                })
            })
        }
    }, [agendaItems])

    const onSubmit = () => async (values) => {
        putAgendaItems(values.agendaItems.filter(value => !value.delete))
            .then((result) => {
                agendaItemsMutate();
                fields.map((link, index) => {
                    setValue(`agendaItems.${index}.delete`, null)
                })

                new Noty({
                    theme: 'bootstrap-v4',
                    layout: 'bottomRight',
                    text: t("Je wijziging is opgeslagen!"),
                    closeWith: ['click', 'button'],
                    type: "success",
                    timeout: 2000,
                }).show();
            })
    }

    return (
        <form onSubmit={(e) => {
             const removeEmptyFields = watch("agendaItems").reduce((acc, curr, index) => {
                if(!curr.title && !curr.start_date && !curr.end_date && !curr.target && !curr.label && !curr.order && curr.delete) {
                    acc.push(index)
                }
                return acc
            }, []);

            remove(removeEmptyFields);

            clearErrors("server");

            handleSubmit(onSubmit())(e).catch((error) => {
                if (error.cause.message === "server-error-agenda-item-create" || error.cause.message === "server-error") {
                    setError("server", {
                        type: "server",
                        message: t("Er is iets verkeerd gegaan.")
                    })
                }
            })
        }}>
            <div className={"tool"}>
                <div className={"tool-header tool-header--border"}>
                    <h1 className={"tool-header__title"}>{t("Trainingen")}</h1>
                </div>
                <div className={"tool__content"}>
                    <div className={"login-admin"}>
                        {fields.map((agendaItem, index) => (
                            <Fragment key={index}>
                                <div className={"login-admin__item"}>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`agendaItems.${index}.title`}
                                            type={"text"}
                                            id={"title"}
                                            label={t("Titel")}
                                            data-cy={"title-input"}
                                            register={register}
                                            error={errors.agendaItems?.[index]?.title}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Controller name={`agendaItems.${index}.start_date`} control={control}
                                                    rules={{required: t("Dit is een verplicht veld")}}
                                                    render={({field: {onChange, value}}) => (
                                                        <DatePickerFilter
                                                            name={`agendaItems.${index}.start_date`}
                                                            type={"text"}
                                                            id={`agendaItems.${index}.start_date`}
                                                            label={t("Publicatiedatum")}
                                                            placeholder={t("Publicatiedatum")}
                                                            endYear={getYear(new Date()) + 1}
                                                            onChange={onChange}
                                                            selected={value}
                                                            error={errors.agendaItems?.[index]?.start_date}
                                                            required
                                                        />
                                                    )}
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Controller name={`agendaItems.${index}.end_date`} control={control}
                                                    rules={{required: t("Dit is een verplicht veld")}}
                                                    render={({field: {onChange, value}}) => (
                                                        <DatePickerFilter
                                                            name={`agendaItems.${index}.end_date`}
                                                            type={"text"}
                                                            id={`agendaItems.${index}.end_date`}
                                                            label={t("Depublicatiedatum")}
                                                            placeholder={t("Depublicatiedatum")}
                                                            endYear={getYear(new Date()) + 1}
                                                            onChange={onChange}
                                                            selected={value}
                                                            error={errors.agendaItems?.[index]?.end_date}
                                                            required
                                                        />
                                                    )}
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`agendaItems.${index}.target`}
                                            type={"text"}
                                            id={"target"}
                                            label={t("Url")}
                                            data-cy={"target-input"}
                                            register={register}
                                            error={errors.agendaItems?.[index]?.target}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`agendaItems.${index}.label`}
                                            type={"text"}
                                            id={"label"}
                                            label={t("Naam")}
                                            data-cy={"label-input"}
                                            register={register}
                                            error={errors.agendaItems?.[index]?.label}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <Input
                                            name={`agendaItems.${index}.order`}
                                            type={"number"}
                                            id={"order"}
                                            label={t("Volgorde")}
                                            data-cy={"order-input"}
                                            register={register}
                                            error={errors.agendaItems?.[index]?.order}
                                            required
                                        />
                                    </div>
                                    <div className={"login-admin__input"}>
                                        <CheckboxInput
                                            name={`agendaItems.${index}.delete`}
                                            type={"checkbox"}
                                            id={`agendaItems.${index}.delete`}
                                            label={t("Verwijderen")}
                                            data-cy={"delete-input"}
                                            register={register}
                                            error={errors.agendaItems?.[index]?.delete}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className={"tool__footer tool__footer--split-layout"}>
                    <ButtonQuaternary
                        icon={"add"}
                        text={t("Training toevoegen")}
                        data-cy={"add-agenda-item-button"}
                        onClick={() => append({title: "", label: "", target: "", order: ""})}
                        type={"button"}
                    />
                    <ButtonPrimary
                        text={t("Opslaan")}
                        data-cy={"agenda-item-submit-button"}
                    />
                </div>
            </div>
        </form>
    )
}
