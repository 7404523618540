import RichTextManager from "./RichtextManager";
import React, {useCallback, useEffect, useState} from "react";
import {getFunction, remoteSetPartial} from "../queries/function_detail";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getProject} from "../queries/project_detail";

import {singlePopOver, useState as popOverUseState} from "./PopOverHoC";
import cx from "classnames";
import Icon from "../patterns/atoms/Icon";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import {useDropzone} from "react-dropzone";
import axios from "axios";
import {useDebounce, useDebounce2} from "../utils/useDebounce";
import Notification from "../patterns/molecules/Notification";
import {Upload} from "./Upload";
import PrintOrientationSelector from "./printOrientationSelector";


function DescriptionSelectorDisplay(props) {

    const {t, i18n} = useTranslation();
    let {function_id, project_id} = useParams();
    function_id = parseInt(function_id);

    const full_identifier = 'DescriptionSwitcher-' + function_id;

    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [popOverActive, setPopOverActive] = popOverUseState(props, full_identifier, false);

    return (
        <>
            <div className={cx("dropdown-toggle", {"dropdown-toggle--disabled": !props?.edit})}
                 onClick={() => {
                     if(props?.edit) {
                         setPopOverActive(!popOverActive, full_identifier);
                     }
                 }}
            >
                <span className={"dropdown-toggle__label"}>{t("Wijze van beschrijven")}:&nbsp;</span>
                <span className={"dropdown-toggle__value"}>
                    <span>{props.current}</span>
                    {props?.edit && (
                        <Icon icon={"angle-" + (popOverActive ? "up" : "down")} sizeModifier={"xsmall"}
                              className={"dropdown-toggle__icon"}/>
                    )}
                </span>
            </div>
            <div className={cx("dropdown", {"dropdown--active": popOverActive})}>
                <ul>
                    {Object.values(props.descriptionTypeOptions).map(obj => {
                        return (
                            <li className={"dropdown-field"}
                                key={obj.identifier}
                                onClick={async e => {
                                    if(props.getFunctionDetail?.data?.description_type !== obj.identifier) {
                                        await remoteSetPartial(function_id, {
                                            description_type: obj.identifier,
                                        })
                                        props.getFunctionDetail.mutate();
                                    }
                                    setPopOverActive(false);
                                }}
                            >
                                <div className="dropdown-field__label">
                                    {obj.short}
                                </div>
                            </li>
                        )

                    })}
                </ul>
            </div>
        </>
    )
}

export const DescriptionSelector = singlePopOver(DescriptionSelectorDisplay);


export function DescriptionDisplay(props) {


    const {t, i18n} = useTranslation();
    const getFunctionDetail = getFunction(props.function_id);
    const getProjectDetail = getProject(props.project_id);
    const [descriptionType, setDescriptionType] = useState('normal');
    const [descriptionExplanation, setDescriptionExplanation] = useState("");
    const [printOrientation, setPrintOrientation] = useState("portrait");

    const save = async (explanation) => {
        if(explanation !== false && getFunctionDetail?.data?.id) {
            let result = await remoteSetPartial(getFunctionDetail?.data?.id, {
                description_explanation: explanation
            });
        }
    }

    const debouncedSave = useDebounce2(value => save(value), 1000)

    const handleDescriptionExplanation = (value) => {
        setDescriptionExplanation(value);
        debouncedSave(value);
    };

    const descriptionTypeOptions = {
        ["normal"]: {
            identifier: "normal",               // uses CK Editor (empty or based on template)
            short: t("Zelf beschrijven"),
            description: t("Beschrijving wordt via het systeem gemaakt"),
        },
        ["upload"]: {
            identifier: "upload",             // description is present as downloadable file
            short: t("Bestand toevoegen"),
            description: t("Beschrijving is beschikbaar middels een bestand")
        },

        ["not-present"]: {
            identifier: "not-present",          // no description is present at all
            short: t("Beschrijving niet aanwezig"),
            description: t("Er is geen beschrijving aanwezig"),
            placeholder: t("Toelichting waarom de beschrijving niet aanwezig is")
        },
        ["postponed"]: {
            identifier: "postponed",            // description not provided at request of the client
            short: t("Wordt later toegevoegd"),
            description: t("Beschrijving wordt op een later moment toegevoegd"),
            placeholder: t("Toelichting waarom de beschrijving uitgesteld is")
        },
        ["offline"]: {
            identifier: "offline",      // description is present in physical form
            short: t("Fysiek aanwezig"),
            description: t("Beschrijving is alleen offline / in fysieke vorm aanwezig"),
            placeholder: t("Toelichting waar en/of bij wie de beschrijving op te vragen is")
        },


    }


    useEffect(() => {
        if(getFunctionDetail.data?.description_type in descriptionTypeOptions) {
            setDescriptionType(getFunctionDetail.data?.description_type);
        }
        if(getFunctionDetail.data?.description_explanation) {
            setDescriptionExplanation(getFunctionDetail.data?.description_explanation);
        }
        if(getFunctionDetail?.data?.print_orientation) {
            setPrintOrientation(getFunctionDetail?.data?.print_orientation);
        }
    }, [getFunctionDetail.data?.description_type, getFunctionDetail.data?.description_explanation, getFunctionDetail?.data?.print_orientation])


    if(!getFunctionDetail?.data || !getProjectDetail?.data) {
        return <LoaderAnimation/>;
    }

    const functionDetail = getFunctionDetail.data;
    const projectDetail = getProjectDetail.data;

    const descriptionForm = (
        <>
            {props?.editState.canEdit && !props?.editState?.description_immutable ? (
                <textarea
                    placeholder={descriptionTypeOptions[descriptionType].placeholder}
                    onChange={(e) => handleDescriptionExplanation(e.target.value)}
                    value={descriptionExplanation}/>
            ) : (
                <>
                    {descriptionExplanation || t("Geen motivatie opgegeven")}
                </>

            )}
        </>
    );

    return (
        <>
            {props.editState.canEdit && props.editState.description_immutable && (
                <Notification type={"warning"} sizeModifier={"large"}
                              text={t("De beschrijving is al vastgesteld en kan daarom niet gewijzigd worden")}/>
            )}

            <div className={"description-settings"}>
                <DescriptionSelector
                    edit={props?.editState.canEdit && !props?.editState?.description_immutable}
                    current={descriptionTypeOptions?.[descriptionType]?.short}
                    descriptionTypeOptions={descriptionTypeOptions}
                    setDescriptionType={setDescriptionType}
                    getFunctionDetail={getFunctionDetail}
                />
                {descriptionType === "normal" &&
                    <PrintOrientationSelector
                        edit={props?.editState.canEdit}
                        printOrientation={printOrientation}
                        getFunctionDetail={getFunctionDetail}
                    />
                }
            </div>

            {descriptionType === 'not-present' && (
                <div className={"description-editor"}>
                    <div className={"no-description-form"}>
                        <h3 className={"no-description-form__title"}>{descriptionTypeOptions[descriptionType].description}</h3>
                        {descriptionForm}
                    </div>
                </div>
            )}

            {descriptionType === 'postponed' && (
                <div className={"description-editor"}>
                    <div className={"no-description-form"}>
                        <h3 className={"no-description-form__title"}>{descriptionTypeOptions[descriptionType].description}</h3>
                        {descriptionForm}
                    </div>
                </div>
            )}

            {descriptionType === 'offline' && (
                <div className={"description-editor"}>
                    <div className={"no-description-form"}>
                        <h3 className={"no-description-form__title"}>{descriptionTypeOptions[descriptionType].description}</h3>
                        {descriptionForm}
                    </div>
                </div>
            )}

            {descriptionType === 'normal' && (
                <RichTextManager function_id={props.function_id} projectDetail={getProjectDetail}
                                 edit={props?.editState?.canEdit && !props?.editState?.description_immutable}/>
            )}

            {descriptionType === 'upload' && (
                <div className={"file-box"}>
                    <Upload
                        edit={props?.editState?.canEdit && !props?.editState?.description_immutable}
                        object_id={props.function_id}
                        files={functionDetail?.description_files}
                        mutate={() => getFunctionDetail.mutate()}
                    />
                </div>
            )}
            {descriptionType === 'unknown' && (
                <div className={"description-editor"}>
                    <div className={"no-description-form"}>
                        {t("Het is belangrijk dat er een beschrijving aanwezig is in het systeem. Kies een van de mogelijkheden van beschrijven")}
                    </div>
                </div>
            )}
        </>
    )
}

export default React.memo(DescriptionDisplay);

