import React from "react";
import {useAuth} from "../../utils/useAuth";
import {useTranslation} from "react-i18next";
import Quickview from "../Quickview";
import Overview from "../Overview";
import {MethodDetailDefinition} from "../Overviews/MethodOverview";
import SimpleMetaDisplay from "../../patterns/organisms/SimpleMeta";
import useSWR from "swr";
import {useParams} from "react-router-dom";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {TagEditor} from "../Tags";
import cx from "classnames";
import {DescriptionEditor} from "../RichtextManager";
import {remoteSetScoringStructureTitleDescription, remoteSetScoringStructureScoreDescription} from "../../queries/method_detail";

export default function MethodDetailScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();
    const { method_id } = useParams();
    const {data: methodDetail} = useSWR(() => method_id ? `/api/scoringstructures/${method_id}/`: false);

    const {data: children} = useSWR(() => method_id ? `/api/scoringstructures/?page_size=1000&parent_id=${method_id}`: false);

    const {data: scores } = useSWR(() => true ? `/api/scoringstructurescores/?page_size=1000&scoring_structure_id=${method_id}` : false);

    function description(content) {
        return {
            __html: content
        }
    }

    if(!methodDetail || !children) {
        return <LoaderAnimation/>
    }

    if(children.count === 0 && !scores) {
        return <LoaderAnimation/>
    }

    // Bevat scoringstructures en/of SCores

    // Toon:
        // Section within Parent (FK) (hoe wijzigen hiervan?)
        // Nummer + Titel + Beschrijving
        // Te wijzigen: Titel + Beschrijving

    // indien het item GEEN child scoringstructures bevat moeten de scores getoond worden:
    // Scores
        // per score:
            // Label (0|A|...)   Niet te wijzigen
            // Description (RichText) Wel te wijzigen

    // Opslaan dmv "Save" knop per item dat wijzigbaar is


    return (
        <>
            <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
                <div className={"content-container"}>
                    <SimpleMetaDisplay
                        name={t("Methode-editor")}
                    />
                </div>
            </div>

            <div className={"content-container"}>
                <div className="description-editor">
                    <div className={"description-editor__item"}>
                        <div className={"description-editor__text"}>
                            <div className="description-editor__rich-text">
                                {/*{methodDetail.number} : {methodDetail.name}*/}
                                {/*<div dangerouslySetInnerHTML={description(methodDetail.description)} />*/}
                                <DescriptionEditor
                                  setLoaded={() => false}
                                  save={(title, content) => remoteSetScoringStructureTitleDescription(methodDetail.id, title, content)}
                                  item={{
                                      title: methodDetail.name,
                                      content: methodDetail.description
                                  }}
                                />
                            </div>
                        </div>
                    </div>

                { scores?.count > 0 && scores?.results?.map(score => {
                    return (
                        <div className={"description-editor__item"} key={score.id}>
                            <div className="description-editor__text">
                                <h3 className={"description-editor__title"}>{score.score}:{score.points}</h3>
                                <div className="description-editor__rich-text">
                                    <DescriptionEditor
                                        placeholder={t("Toelichting op dit aspect...")}
                                        setLoaded={() => false}
                                        save={(title, content) => remoteSetScoringStructureScoreDescription(score.id, content)}
                                        item={{
                                            title: false,
                                            content: score.description
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>

                {children.count > 0 && (

                    <div className={"content-container"}>

                        <Quickview>

                            <Overview
                                identifier={"method-detail-overview"}
                                identKey={{
                                    parent_id: method_id,
                                }}
                                quickview={{
                                    identifier: "method-detail-overview",
                                    name: t("Methoden")
                                }}
                                definition={{
                                    ...MethodDetailDefinition,
                                    // interactive: true,
                                    // primary: {
                                    //     title: t("Gebruiker aanmaken"),
                                    //     identifier: 'create-user',
                                    //     link: './create/',
                                    //     allowed: canCreateUser,
                                    // },
                                    // columns: UserOverviewDefinition.columns.filter(o => o.identifier !== 'action'),
                                }}

                            />
                        </Quickview>
                    </div>
                )}
            </div>
        </>
    )
}
