import React, {useState} from 'react';
import cx from "classnames";
import ConfirmModal from "../organisms/ConfirmModal";
import i18n from "../../utils/i18n";

export default function LoaderAnimation(props) {

    const timer = React.useRef(null);
    const counter = React.useRef(0);
    const [ displayMessage , setDisplayMessage ] = useState(false);

    React.useEffect(() => {
        if(!props?.infinite === true) {

            timer.current = setInterval(() => {
                counter.current = counter.current + 1;
                if(counter.current === 20) {
                    setDisplayMessage(true);
                    clearInterval(timer.current);
                }
            }, 1000)  ;
            return () => clearInterval(timer.current);
        }
    },[]);

    if(displayMessage) {
        return (
            <ConfirmModal
                title={i18n.t("Applicatie reageert niet")}
                body={i18n.t("Het lijkt er op dat een onderdeel van de applicatie niet goed kan laden. Kies voor \"Opnieuw laden\" om het nogmaals te proberen. Klik op \"Annuleren\" om te wachten")}
                confirmText={i18n.t("Opnieuw laden")}
                closeModal={() => setDisplayMessage(false)}
                confirmModal={() => {
                    window.location.href = window.location.href;
                }}
            />
        )
    }

    return (
        <div className={cx('loader-animation', {
            'loader-animation--full-height': props.fullHeight,
            'loader-animation--small': props.small,
            'loader-animation--xsmall': props.xsmall,
            'loader-animation--inverted': props.inverted
        })}>
            <svg xmlns="http://www.w3.org/2000/svg" className={"loader-animation__image"} viewBox={"0 0 100 100"}>
                <g transform="rotate(0 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(30 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(60 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(90 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(120 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(150 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(180 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(210 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(240 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(270 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(300 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"/>
                    </rect>
                </g>
                <g transform="rotate(330 50 50)">
                    <rect x="48" y="18" rx="2" ry="2.24" width="4" height="16">
                        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"/>
                    </rect>
                </g>
            </svg>
        </div>
    )
}
