import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {removeHeartbeat, updateHeartbeat} from "../actions/heartbeat";
import Noty from "noty";
import {useTranslation} from "react-i18next";
import {remoteAcquireLock, remotePing, remoteUnlock} from "../queries/function_detail";
import {useNavigate} from "react-router-dom";

// Heartbeat monitoring should be done rather high up the application tree
export function MonitorHeartbeat(props) {
    const {t, i18n} = useTranslation();
    const heartbeat = useSelector(state => state.HeartbeatState);
    const [update, setUpdate] = useState(0);
    // const navigate = useNavigate();
    let dispatch = useDispatch();

    // Effect which enables an update interval to monitor for heartbeat changes
    useEffect(() => {
        const interval = setInterval(() => {
            setUpdate(update => update+1);
        }, 5000);
        return () => clearInterval(interval);
    }, []);


    // check all active heartbeats and act on it
    useEffect(() => {

        const remoteUpdate = update % 3 === 0;

        Object.entries(heartbeat).map( obj => {
            let [ key, value ] = obj;
            if(value.checkInTime) {
                let now = new Date();
                let timePassed = (now - value.checkInTime);

                // Only when the provided timeout has passed, do somethingwith it
                if(timePassed > value.timeout) {

                    const actionTimeout = 20000; //timeout of the notification (should be identical to the timeout of the action to take

                    // display a notification
                    let noty = new Noty({
                        id: key,
                        theme: 'bootstrap-v4',
                        layout: 'bottomRight',
                        text: t("Het lijkt er op dat je de functie ") + " <strong>"+ value.short + "</strong> " +
                            t("hebt verlaten zonder deze vrij te geven. De functie is automatisch vrijgegeven en de " +
                                "wijzigingen zijn opgeslagen"),
                        closeWith: ['click', 'button'],
                        type: "warning",
                        timeout: actionTimeout,
                    }).show();

                    // remove the heartbeat as we have notified the user of its issue
                    dispatch(removeHeartbeat("edit" + value.id))

                    // and we have dispatched a timeout for resolving the issue
                    setTimeout(() => {
                        let lock = remoteUnlock(value.id);
                    }, 4000);

                    // when user re-enters the function the heartbeat is restarted again so we do not need to do anything
                }
            }
            if(remoteUpdate) {
                // remote heartbeat
                // console.log('remote heartbeat update', value.id);
                remotePing(value.id);
            }
        })

    }, [update])

    return null;
}

export function Heartbeat(props) {

    const heartbeat = useSelector(state => state.HeartbeatState);
    let dispatch = useDispatch();

    useEffect(() => {
        // Provide the heartbeat with info on wether to monitor or not
        if(props.running) {
            const interval = setInterval(() => {
                dispatch(updateHeartbeat("edit" + props.function_id, {
                    checkInTime: new Date(),
                    short: props?.short,
                    type: "function",
                    id: props.function_id,
                    timeout: 4000,          // interval after which time of inactivity something should happen
                }));
            }, 4000);               // interval at which the heartbeat is done
            return () => clearInterval(interval);
        } else {
            if(heartbeat["edit" + props.function_id]) {
                dispatch(removeHeartbeat("edit" + props.function_id))   // If we want to disable the heartbeat remove it
            }
        }
    },[props.running]);

    return null;
}
