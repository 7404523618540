import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {singlePopOver, useState as popOverUseState} from "./PopOverHoC";
import cx from "classnames";
import {Icon} from "../patterns/atoms/Icon";
import {setPrintOrientationFunction} from "../queries/function_detail";

function PrintOrientationSelectorDisplay(props) {
    const {t} = useTranslation();
    let {function_id} = useParams();
    function_id = parseInt(function_id);

    const full_identifier = 'PrintOrientationSelector-' + function_id;
    const [popOverActive, setPopOverActive] = popOverUseState(props, full_identifier, false);

    const printOrientationOptions = {
        ["portrait"]: {
            identifier: "portrait",
            title: t("Portret"),
        },
        ["landscape"]: {
            identifier: "landscape",
            title: t("Landschap"),
        }
    }

    return (
        <>
            <div className={cx("dropdown-toggle", {"dropdown-toggle--disabled": !props?.edit})}
                 onClick={() => {
                     if (props?.edit) {
                         setPopOverActive(!popOverActive, full_identifier);
                     }
                 }}
            >
                <span className={"dropdown-toggle__label"}>{t("Printoriëntatie")}:&nbsp;</span>
                <div className={"dropdown-toggle__value"}>
                    <span>{printOrientationOptions?.[props.printOrientation]?.title}</span>
                    {props?.edit && (
                        <Icon icon={"angle-" + (popOverActive ? "up" : "down")} sizeModifier={"xsmall"}
                              className={"dropdown-toggle__icon"}/>
                    )}
                </div>
            </div>

            <div className={cx("dropdown", {"dropdown--active": popOverActive})}>
                <ul>
                    {Object.values(printOrientationOptions).map(obj => {
                        return (
                            <li className={"dropdown-field"}
                                key={obj.identifier}
                                onClick={async e => {
                                    if (props.printOrientation !== obj.identifier) {
                                        await setPrintOrientationFunction(function_id, obj.identifier);
                                        props.getFunctionDetail.mutate();
                                    }
                                    setPopOverActive(false);
                                }}
                            >
                                <div className="dropdown-field__label">
                                    {obj.title}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default singlePopOver(PrintOrientationSelectorDisplay);
