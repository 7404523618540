import {REMOVE_HEARTBEAT, UPDATE_HEARTBEAT} from "../actions/heartbeat";

const defaultState = {

}

export function HeartbeatState(state=defaultState, action) {

    switch(action.type) {
        case UPDATE_HEARTBEAT:
            return {
                ...state,
                [action.identifier]:  {
                    ...action.data,
                }
            }
        case REMOVE_HEARTBEAT:
            let ret = {
                ...state
            }
            delete ret[action.identifier];
            return ret;
        default:
            return state;
    }
}
