import axios from "axios";
import { useSWRInfinite } from "swr";
import {loadIfAllowed} from "../utils/constants";
import {buildOrder, buildFilter} from "../utils/constants";
import {useEffect, useRef} from "react";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";

export function getAllFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    // check on 10 chars is so we dont keep on appending the time after YYYY-MM-DD (which is 10 chars long)
    if(fetchState?.filter?.creation_date_before && fetchState.filter.creation_date_before.length <= 10){
        fetchState.filter.creation_date_before = fetchState.filter.creation_date_before + ' 23:59:59';
    }

    // check on 10 chars is so we dont keep on appending the time after YYYY-MM-DD (which is 10 chars long)
    if(fetchState?.filter?.modification_date_before && fetchState.filter.modification_date_before.length <= 10){
        fetchState.filter.modification_date_before = fetchState.filter.modification_date_before + ' 23:59:59';
    }

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.favorite ? {
                favorite: true,
            } : {},
            fetchState?.identKey?.method_group ? {
                method_group: fetchState.identKey.method_group,
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/functions/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index + 1}&ordering=${ordering}${filtering}` : false,
    {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}


export function getFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    if(fetchState?.filter?.creation_date_before && fetchState.filter.creation_date_before.length <= 10){
        fetchState.filter.creation_date_before = fetchState.filter.creation_date_before + ' 23:59:59';
    }

    // check on 10 chars is so we dont keep on appending the time after YYYY-MM-DD (which is 10 chars long)
    if(fetchState?.filter?.modification_date_before && fetchState.filter.modification_date_before.length <= 10){
        fetchState.filter.modification_date_before = fetchState.filter.modification_date_before + ' 23:59:59';
    }

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.id__not ? {
                id__not: parseInt(fetchState?.identKey?.id__not)
            } : {}
        ));

    const ordering = buildOrder(Object.assign({}, {...fetchState},
        fetchState?.ordering?.columnIdentifier === 'type__in' ? {
            ...fetchState?.ordering,
            columnIdentifier: 'type',
        }: {
            ...fetchState?.ordering
        }
    ));

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/projects/${fetchState?.identKey?.project_id}/functions/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index + 1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false ,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getOwnFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id: parseInt(fetchState?.identKey?.client_id)
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/projects/${fetchState.identKey.project_id}/functions/?page=${index + 1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getOtherFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id__not: parseInt(fetchState?.identKey?.client_id)
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/projects/${fetchState?.identKey?.project_id}/functions/?view=other&page=${index + 1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getProjectGroupFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id__not: parseInt(fetchState?.identKey?.client_id)
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/projects/${fetchState?.identKey?.project_id}/functions/?view=projectgroup&page=${index + 1}&ordering=${ordering}${filtering}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getBasedOnFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.id__not ? {
                id__not: parseInt(fetchState?.identKey?.id__not)
            } : {},
            fetchState?.identKey?.description_type ? {
                description_type: fetchState?.identKey?.description_type
            } : {},
            fetchState?.identKey?.scoring_structure_id?.length > 0 ? {
                scoring_structure_id: fetchState?.identKey?.scoring_structure_id
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);


    let endpoint = '/api/functions/';
    if(fetchState?.identKey?.client_id) {
        endpoint = `/api/projects/${fetchState?.identKey?.project_id}/functions/`;
    }


    return useSWRInfinite(
        index => fetchState?.identifier ?  endpoint + `?view=base&page=${index + 1}&ordering=${ordering}${filtering}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getComparableFunctions(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);
    const identifiers = ['sidebar-function-overview-custom'];

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            {
                scoring_status: 'determined'
            },
            fetchState?.identKey?.function_id ? {
                id__not: parseInt(fetchState?.identKey?.function_id)
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/projects/${fetchState?.identKey?.project_id}/functions/?page=${index + 1}&page_size=${fetchState.pagination[fetchState.type].size}&ordering=${ordering}${filtering}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}
