import React, {useState} from "react";
import cx from "classnames";

import GroupPatternScore from "../../patterns/molecules/GroupPatternScore";
import {NameSourcetypeFilter} from "./Filters/NameSourcetypeFilter";
import {MNTFilter} from "./Filters/MNTFilter";
import {MNTTitle} from "./Titles/MNTTitle";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import IconButton from "../../patterns/molecules/IconButton";
import OverviewName from "../../patterns/molecules/OverviewName";
import {getRefFunctionsForRanking, getRelatedFunctions} from "../../queries/client_project_ranking";
import {DescriptionModal} from "../Screens/Description";
import {FunctionInfoIconButton} from "./FunctionInfoIconButton";
import {MNTDomains, VBalansDomains} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import FunctionTypeIndicator from "../../patterns/atoms/FunctionTypeIndicator";
import FunctionSimilarity from "../FunctionSimilarity";
import ReactDOM from "react-dom";
import {useAuth} from "../../utils/useAuth";
import ToolTip from "../ToolTip";
import {generatePath, useNavigate} from "react-router-dom";
import i18n from "../../utils/i18n";


export const RankingRowStructure = React.memo(tempProps => {
    const [isFunctionSimilarityShown, setIsFunctionSimilarityShown] = useState(false);
    const {t, i18n} = useTranslation();
    let props = Object.assign(
        {},
        tempProps,
    );

    // may be passed, but is defaulted in OverviewName
    // let lengths = {
    //     small: 15,
    //     medium: 20,
    //     large: 25,
    //     xlarge: 30,
    //     full: 35,
    // }

    const interactive = typeof props?.contextData?.selectFunction === 'function';

    const navigate = useNavigate();

    const kind = () => {
        if(props.is_collective_labour_agreement) {
            return "CAO";
        }
    }

    const [description, setDescription] = useState(false);

    const auth = useAuth();
    const canViewPoints = auth.hasPermission('base.view_reference_function_points') && auth.hasPermission('base.view_company_function_points') && props.contextData?.functionDetail?.type !== "match";

    const FunctionSimilarityFun = () => <FunctionSimilarity {...props}/>

    return (
        <tr
            key={props.id}
            className={"overview-table__row"}
        >
            <td className={cx("overview-table__cell", {
                "overview-table__cell--row-selected": props.contextData.referenceFunctions?.find(o => o.id === props.id),
                "overview-table__cell--interactive": interactive,
            })}
            >
                <FunctionTypeIndicator type={props.type}/>
            </td>
            <td className={"overview-table__cell overview-table__cell--name overview-table__cell--interactive"}
                data-cy={"overview-table-cell-name"}
                onClick={() => {
                    props?.contextData?.summary !== true ?
                        navigate(generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                            client_id: props.client?.id || 0,
                            project_id: props.project?.id || 0,
                            function_id: props.id
                        })) : null
                }}
            >
                <OverviewName {...props}
                              kind={kind()}
                />
            </td>
            <td className={"overview-table__cell"}
            >
                <GroupPatternScore {...props}
                                   comparedPatternList={props?.contextData?.patternList}
                                   comparison={props.contextData.comparison}
                />
            </td>
            {canViewPoints && props?.contextData?.summary !== true &&
                <td className={"overview-table__cell overview-table__cell--similarity"}
                    onMouseEnter={() => setIsFunctionSimilarityShown(true)}
                    onMouseLeave={() => setIsFunctionSimilarityShown(false)}
                >
                    {props.points && props.analytical_difference && props.analytical_distance && props.contextData?.functionDetail?.points &&
                        <ToolTip component={FunctionSimilarityFun}>
                            <span
                                className={cx("function-similarity-table-data", {"function-similarity-table-data--hover": isFunctionSimilarityShown})}>
                                {props.analytical_difference}
                            </span>
                        </ToolTip>
                    }
                </td>
            }
            {canViewPoints && props?.contextData?.summary !== true &&
                <td className={"overview-table__cell overview-table__cell--similarity"}
                    onMouseEnter={() => setIsFunctionSimilarityShown(true)}
                    onMouseLeave={() => setIsFunctionSimilarityShown(false)}>
                    {props.points && props.analytical_difference && props.analytical_distance && props.contextData?.functionDetail?.points &&
                        <ToolTip component={FunctionSimilarityFun}>
                            <span
                                className={cx("function-similarity-table-data", {"function-similarity-table-data--hover": isFunctionSimilarityShown})}>
                                {props.analytical_distance}
                            </span>
                        </ToolTip>
                    }
                </td>
            }
            {props.contextData?.distance && props?.contextData?.summary !== true && (
                <td className={"overview-table__cell"}>
                    <div className={cx("function-distance-indicator",
                        {'function-distance-indicator--too-far': (props.distance_perc <= 59)},
                        {'function-distance-indicator--highest': (props.distance_perc > 59 && props.distance_perc <= 80)},
                        {'function-distance-indicator--high': (props.distance_perc > 80 && props.distance_perc <= 90)},
                        {'function-distance-indicator--medium': (props.distance_perc > 90 && props.distance_perc <= 94)},
                        {'function-distance-indicator--low': (props.distance_perc > 94 && props.distance_perc <= 97)},
                        {'function-distance-indicator--lowest': props.distance_perc > 97}
                    )}>
                        <div className={"function-distance-indicator__fill"}
                             style={{width: props.distance_perc + '%'}}>{false && props?.contextData?.canViewPoints && <>{props.distance}</>}</div>
                    </div>
                </td>
            )}
            {props?.contextData?.summary !== true && (
                <td className={"overview-table__cell overview-table__cell--buttons overview-table__cell--name"}>
                    <div className={"icon-button-group"}>
                        <ToolTip tooltip={props.description_type !== 'normal' ? t("Geen beschrijving aanwezig") : t("Beschrijving vergelijken")}>
                            <IconButton disabled={props.description_type !== 'normal'}
                                        icon={"compare-description"} sizeModifier={"medium"}
                                        onClick={() => setDescription(props.id)}
                                        border={"true"}
                            />
                        </ToolTip>
                        {description === props.id && (
                            <DescriptionModal
                                function_id={props?.contextData.function_id}
                                function_id_two={props.id}
                                comparison={props?.contextData?.functionDetail?.type !== "match"}

                                description={description}
                                setDescription={setDescription}
                            />
                        )}

                        <FunctionInfoIconButton
                            // function_id={props.id}
                            functionInfo={props}
                            border={"true"}
                        />
                    </div>

                    {props?.contextData?.edit && (
                        props.contextData.referenceFunctions.some(o => o.id === props.id) ? (

                            <ToolTip tooltip={"Referentiefunctie verwijderen"}>
                                <IconButton icon={"close"}
                                            sizeModifier={"medium"}
                                            onClick={() => props.contextData.removeFunction(props.id)}
                                            border={"true"}
                                />
                            </ToolTip>

                        ) : (

                            props?.contextData?.referenceFunctionCount >= 3 ? (
                                <ToolTip tooltip={t("Je kunt maximaal 3 functies als referentie toevoegen")}>
                                    <IconButton disabled={true}
                                                icon={"add"}
                                                sizeModifier={"medium"}
                                                border={"true"}
                                                onClick={() => false}
                                    />
                                </ToolTip>
                            ) : (
                                <ToolTip tooltip={t("Voeg functie als referentiefunctie toe")}>
                                    <IconButton icon={"add"}
                                                sizeModifier={"medium"}
                                                onClick={() => props.contextData.selectFunction(props.id)}
                                                border={"true"}
                                                data-cy={"add-reference-function-button"}
                                    />
                                </ToolTip>
                            )

                        )
                    )}
                </td>
            )}
        </tr>
    )

});


export const ClientProjectRankingFunctions = {

    title: "Referentiefuncties",
    identifier: 'ranking-reference-function-overview',
    queryFn: getRefFunctionsForRanking,
    loadAllAllowed: true,
    RowStructure: RankingRowStructure,
    primary: {},
    secondary: {},
    noSearch: true,
    defaultOrderIdentifier: 'distance',
    defaultSize: 5,
    columns: [
        {
            title: 'Type',
            identifier: 'type__in',
            type: 'dropdown',
            className: 'overview-table__filter-cell--width-type',
            orderable: true,
            Filter: DropdownFilter,
            options: [
                {
                    title: 'Referentie',
                    identifier: 'reference'
                },
                {
                    title: 'Bedrijfseigen',
                    identifier: 'company',
                },
            ],
            Skeleton: FunctionTypeIndicator,
        },
        {
            title: "Naam",
            identifier: 'name',
            type: "name-kind",
            orderable: true,
            Filter: NameSourcetypeFilter,
            FilterOptions: [
                {
                    title: 'CAO',
                    identifier: 'true'
                },
                {
                    title: i18n.t('Niet CAO'),
                    identifier: 'false'
                },
            ]
        },
        {
            type: 'mnt',
            identifier: 'mnt',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: {domains: MNTDomains},
            Filter: MNTFilter,
            FilterOptions: {domains: MNTDomains},
        },
        {
            type: 'vbalans',
            identifier: 'vbalans',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: {
                domains: VBalansDomains,
                type: "vbalans",
            },
            Filter: MNTFilter,
            FilterOptions: {
                domains: VBalansDomains,
                type: "vbalans",
            },
        },
        {
            title: 'Verschil',
            identifier: 'difference',
            className: 'overview-table__filter-cell--width-small',
            placeholder: "Verschil",
            Filter: EmptyFilter
        },
        {
            title: 'Afstand',
            identifier: 'distance-points',
            className: 'overview-table__filter-cell--width-small',
            placeholder: "Afstand",
            Filter: EmptyFilter
        },
        {
            title: 'Overeenk.',
            identifier: 'distance',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-small',
            placeholder: "Afstand",
            Filter: EmptyFilter
        },
        {
            identifier: 'info',
            className: 'overview-table__filter-cell--width-button-trio',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],
}
