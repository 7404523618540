import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {getMonth, getYear, addDays, subDays} from 'date-fns';
import range from "lodash/range";
import {Icon} from "../../../patterns/atoms/Icon";
import IconButton from "../../../patterns/molecules/IconButton";
import {useTranslation} from "react-i18next";
import {months} from "../../../utils/constants";
import cx from "classnames";

export default function DatePickerFilter(props) {
    const {t, i18n} = useTranslation();
    const [date, setDate] = useState(props.state?.[props.identifier] ? new Date(props.state?.[props.identifier]) : null);
    const years = range(props.beginYear ? props.beginYear : 2000, props.endYear ? props.endYear : getYear(new Date()) + 5, 1);

    useEffect(() => {
        if (!props.state?.[props.identifier]) {
            setDate(null)
        }
    }, [props.state?.[props.identifier]])

    return (
        <>
            {props.error && (
                <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}
                      data-cy={props["data-cy"] + "-error-icon"}/>
            )}
            <div className={"input-group__label-container"}>
                <label className={"input-group__label"}>{props.label}</label>
                {props.required &&
                    <span className={"input-group__required"}>{t("Verplicht")}</span>
                }
            </div>
            {props.helpText &&
                <p className={"input-group__help-text"}>{props.helpText}</p>
            }
            {props.error && (
                <p className={"input-group__error-message"}
                   data-cy={props["data-cy"] + "-error-message"}>{props.error.message}</p>
            )}
            <div className={cx("input-group__input", {"input-group__input--error": props.error})}>
                <DatePicker
                    disabled={props.disabled}
                    isClearable={!props.disabled}
                    dateFormat="dd-MM-yyyy"
                    popperPlacement="top"
                    calendarStartDay={1}
                    minDate={addDays(new Date(props.minDate), 1)}
                    maxDate={subDays(new Date(props.maxDate), 1)}
                    // todayButton="Vandaag"
                    selected={props.selected ? props.selected : date}
                    onChange={props.onChange ? props.onChange : (date) => {
                        if (date) {
                            props.setFilter(props.identifier, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
                        } else {
                            props.setFilter(props.identifier, '')
                        }

                        setDate(date)

                    }}

                    renderCustomHeader={({
                                             date,
                                             changeYear,
                                             changeMonth,
                                             decreaseMonth,
                                             increaseMonth,
                                         }) => (
                        <div className={"react-datepicker__custom-navigation"}>

                            <IconButton className={"react-datepicker__custom-navigation-button"}
                                        icon={"arrow-left"}
                                        title={t("Previous month")}
                                        active={"true"}
                                        onClick={decreaseMonth}
                            />

                            <div className="select-container react-datepicker__custom-navigation-month">
                                <select
                                    value={months[getMonth(date)]}
                                    onChange={({target: {value}}) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {t(option)}
                                        </option>
                                    ))}
                                </select>
                                <Icon icon={"angle-down"}
                                      sizeModifier={"small"}
                                      className={"select-container__icon"}/>
                            </div>

                            <div className="select-container react-datepicker__custom-navigation-year">
                                <select
                                    value={getYear(date)}
                                    onChange={({target: {value}}) => changeYear(value)}
                                >
                                    {years.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <Icon icon={"angle-down"}
                                      sizeModifier={"small"}
                                      className={"select-container__icon"}/>
                            </div>

                            <IconButton className={"react-datepicker__custom-navigation-button"}
                                        icon={"arrow-right"}
                                        title={t("Volgende maand")}
                                        active={"true"}
                                        onClick={increaseMonth}
                            />

                        </div>
                    )}
                />
            </div>
        </>
    )
}
