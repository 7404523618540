import React from "react";
import Quickview from "./Quickview";
import Overview from "./Overview";
import {FunctionOverviewDefinition} from "./Overviews/FunctionOverview";
import {useTranslation} from "react-i18next";
import {AllFunctionRowStructure} from "./Overviews/FunctionOverview";
import {ClientProjectOverviewDefinition} from "./Overviews/ClientProjectOverview";
import {generatePath, Link, useNavigate} from "react-router-dom";
import {DashboardRowStructure} from "./Overviews/ClientProjectOverview";
import useSWR from "swr";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import cx from "classnames";
import {projectStatus} from "../utils/constants";
import ButtonTertiary from "../patterns/atoms/ButtonTertiary";
import {getAllFunctions} from "../queries/client_project_functions";

export default function Dashboard(props) {
    const {t, i18n} = useTranslation();

    const {data: getProjects} = useSWR('/api/projects/?page_size=1');
    const {data: getClients} = useSWR('/api/clients/?page_size=1');

    const navigate = useNavigate();

    if(!getProjects || !getClients) {
        return <LoaderAnimation/>;
    }

    const projectDetail = getProjects.results[0];
    const projectPath = projectDetail?.source_project? (projectDetail?.source_project?.client?.id ?
        generatePath("/client/:client_id/project/:project_id/function", {
            client_id: projectDetail?.source_project?.client?.id ,
            project_id: projectDetail?.source_project?.id,
        }) :
        generatePath("/project/:project_id/function", {
            project_id: projectDetail?.source_project?.id,
        })) : false;

    const columns = getClients.count > 1 ?
        ["type", "name", "description_status__in", "client__name"]
        :
        ["type", "name", "description_status__in"]

    const projectColumns = getClients.count > 1 ?
        ["name", "client__name"]
        :
        ["name"];


    return (
        <div className={"content-container"}>
            <div className={"dashboard"}>

                <div className={"dashboard__column"}>
                    <div className={"dashboard__item"} data-cy={"dashboard-function-favorite"}>
                        {/*LINKS BOVEN*/}
                        <Quickview>
                            <Overview
                                identifier={"dashboard-function-favorite"}
                                quickview={{
                                    identifier: "function-favorite",
                                    name: t("Functies")
                                }}
                                identKey={{
                                    favorite: true,
                                }}
                                definition={{
                                    ...FunctionOverviewDefinition,
                                    noSearch: true,
                                    noOrder: true,
                                    noFilter: true,
                                    noPagination: true,
                                    noSticky: true,
                                    title: t("Favoriete functies"),
                                    columns: FunctionOverviewDefinition.columns.filter(o => columns.includes(o.identifier)),
                                    RowStructure: AllFunctionRowStructure,
                                    primary: {},
                                    paginationSize: 5
                                }}
                            />
                        </Quickview>
                    </div>

                    <div className={"dashboard__item"} data-cy={"dashboard-function-recent"}>
                        {/*LINKS ONDER*/}
                        <Quickview>
                            <Overview
                                identifier={"dashboard-function-recent"}
                                quickview={{
                                    identifier: "functions-recent",
                                    name: t("Functies")
                                }}
                                definition={{
                                    ...FunctionOverviewDefinition,
                                    queryFn: getAllFunctions,
                                    defaultOrderIdentifier: '-modification_date',
                                    noSearch: true,
                                    noOrder: true,
                                    noFilter: true,
                                    noPagination: true,
                                    noSticky: true,
                                    title: t("Recente functies"),
                                    columns: FunctionOverviewDefinition.columns.filter(o => columns.includes(o.identifier)),
                                    RowStructure: AllFunctionRowStructure,
                                    primary: {},
                                    paginationSize: 4
                                }}
                                initialFilters={{
                                    without_extended: true
                                }}
                            />
                        </Quickview>

                    </div>
                </div>

                <div className={"dashboard__column dashboard__column--narrow"}>
                    {getProjects.count > 1 ? (
                        <>
                            <div className={"dashboard__item"} data-cy={"dashboard-projects-favorite"}>
                                {/*RECHTS BOVEN*/}
                                <Quickview>

                                    <Overview
                                        identifier={"dashboard-projects-favorite"}
                                        quickview={{
                                            identifier: "projects-favorite",
                                            name: t("Projecten")
                                        }}
                                        identKey={{
                                            favorite: true,
                                        }}
                                        definition={{
                                            ...ClientProjectOverviewDefinition,
                                            RowStructure: DashboardRowStructure,
                                            noOrder: true,
                                            noSearch: true,
                                            noFilter: true,
                                            noPagination: true,
                                            noSticky: true,
                                            title: t("Favoriete projecten"),
                                            columns: ClientProjectOverviewDefinition.columns.filter(o => projectColumns.includes(o.identifier)),
                                            primary: {},
                                            paginationSize: 5
                                        }}
                                        initialFilters={{
                                            status__in: ['concept', 'determined', 'expired']
                                        }}
                                    />
                                </Quickview>
                            </div>
                            <div className={"dashboard__item"} data-cy={"dashboard-projects-recent"}>
                                {/*RECHTS ONDER*/}
                                <Quickview>
                                    <Overview
                                        identifier={"dashboard-projects-recent"}
                                        quickview={{
                                            identifier: "projects-recent",
                                            name: t("Projecten")
                                        }}
                                        definition={{
                                            ...ClientProjectOverviewDefinition,
                                            defaultOrderIdentifier: '-modification_date',
                                            RowStructure: DashboardRowStructure,
                                            noSearch: true,
                                            noOrder: true,
                                            noFilter: true,
                                            noPagination: true,
                                            noSticky: true,
                                            title: t("Recente projecten"),
                                            columns: ClientProjectOverviewDefinition.columns.filter(o => projectColumns.includes(o.identifier)),
                                            primary: {},
                                            paginationSize: 4
                                        }}
                                        initialFilters={{
                                            status__in: ['concept', 'determined', 'expired'],
                                            without_extended: true
                                        }}
                                    />
                                </Quickview>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={"my-project"}>
                                <div className={"my-project__content my-project__content--border"}>
                                    <h2 className={"my-project__title"}>{t("Uw project")}</h2>
                                </div>
                                <div className={"my-project__content my-project__content--border"}>
                                    <h2 className={"my-project__client"}>{projectDetail?.client?.name}</h2>
                                    <div className={"meta-info__title-container"}>
                                        <h1 className={"my-project__project-name"}>{projectDetail?.name}</h1>
                                        <span className={cx("meta-info__status-tag", {
                                            "status-tag status-tag--neutral": ['concept', undefined].includes(projectDetail?.status),
                                            "status-tag status-tag--active": ['determined'].includes(projectDetail?.status),
                                            "status-tag status-tag--negative": ['expired', 'archived'].includes(projectDetail?.status)
                                        })}>{t(projectStatus[projectDetail?.status])}</span>
                                        {projectDetail?.is_collective_labour_agreement && <span className={"meta-info__status-tag status-tag"}>{t("CAO")}</span>}
                                    </div>

                                    {projectDetail?.source_project && (
                                        <p className={"meta-info__post-title"}>
                                            {projectDetail?.source_project_usage === 'based_on' ? t('Gebaseerd op ') : t('Gekopieerd van ')}
                                            {/*<Link to={projectPath} className={"link"}>{projectDetail?.source_project.name}</Link>*/}
                                            {projectDetail?.source_project.name}
                                        </p>
                                    )}
                                    {projectDetail?.project_groups?.length > 0 && (
                                        <p className={"meta-info__post-title"}>
                                            {t('Projectgroep')}: {projectDetail?.project_groups[0].name}
                                        </p>
                                    )}
                                </div>
                                <div className={"my-project__content"}>

                                    <dl className={cx("meta-grid", {"meta-grid--wide": props.wide})}>
                                        <div className={"meta-grid__column"}>
                                            <dt className={"meta-grid__key"}>{t("Methode")}</dt>
                                            <dd className={"meta-grid__value"}>
                                                {projectDetail?.method?.name || "-"}
                                            </dd>
                                        </div>
                                        <div className={"meta-grid__column"}>
                                            <dt className={"meta-grid__key"}>{t("Laatst bewerkt")}</dt>
                                            <dd className={"meta-grid__value"}>
                                                {projectDetail?.modification_date ? (
                                                    <time dateTime={projectDetail.modification_date}>
                                                        {new Intl.DateTimeFormat("nl-NL", {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric"
                                                        }).format(new Date(projectDetail.modification_date))}
                                                    </time>
                                                ) : (
                                                    "-"
                                                )}
                                            </dd>
                                        </div>
                                    </dl>


                                </div>

                                <div className={"my-project__content"}>
                                    <ButtonTertiary text={"Naar project"} className={"button--full-width"} onClick={() => {
                                        navigate(generatePath("/client/:client_id/project/:project_id/function", {
                                            client_id: projectDetail?.client?.id ,
                                            project_id: projectDetail?.id,
                                        }));
                                    }}/>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
