import React from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";
import {typeToName, typeToLetter} from "../../utils/constants";

export const FunctionTypeIndicatorPatternState = {
    full: false,
    plMod: {
        match: {
            type: "match"
        },
        company: {
            type: "company"
        },
        reference: {
            type: "reference"
        }
    }
}


export default function FunctionTypeIndicator(props) {
    const {t, i18n} = useTranslation();
    return (
        <span
            data-cy={"function-type-indicator"}
            className={cx(
                'function-type-indicator',
                {'function-type-indicator--skeleton': !props?.type},
                {'function-type-indicator--match': props.type === 'match'},
                {'function-type-indicator--company': props.type === 'company'},
                {'function-type-indicator--reference': props.type === 'reference'},
                {'function-type-indicator--small': props.sizeModifier === 'small'}
            )}
            title={t(typeToName(props.type))}
        >
            {t(typeToLetter(props.type))}
        </span>
    )
}
