import axios from "axios";
import useSWR, {mutate} from "swr";
import React from "react";
import {functionStatus, optionsFetcher} from "../utils/constants";
import {generatePath} from "react-router-dom";

// Function is used to prefetch data for a function. This is usefull for prepopulating the cache  so we load funcitons faster
export function prefetchFunction(pk, cancelTokenRef = []) {
    // console.log(cancelTokenRef);

    // FIXME: Disabled prefetcher as it poses issues with infinite loaders
    // TODO: Possible fix could be to monitor function loading and when it takes > 5 sec, issue a mutate on the object to do a force reload
    return false;

    let url = `/api/functions/${pk}/`;

    return mutate(url, obj => {
        if (!obj) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const token = source.token;
            // cancelTokenRef.push(token);

            return axios.get(
                url, {
                    cancelToken: token
                }
            ).then(res => res.data).catch(error => {
                if (error.response.status === 404 || error.response.status === 403) {
                    // console.log(error.response);
                    // silently fail TODO: should actually make sure it cant happen in subsequent requests, but no idea right now how to do so
                    return null
                }
            })
        }
        return obj;
    })
}

// Get the actual function details (if it was prefetched, the prefetched data is used)
export function getFunction(pk) {

    let url = `/api/functions/${pk}/`;

    const res = useSWR(() => pk ? url : false);
    // console.log(res);
    const {data, error, mutate} = res;

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getFunctionOptions() {
    let url = `/api/functions/`;

    const {data, error, mutate} = useSWR(url, optionsFetcher);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getFunctions() {
    let url = `/api/functions/?page_size=1000`;

    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getProjectFunctions(project_pk) {

    // fixme: Also needs to return functions of related projects, method and example functions.
    let url = `/api/functions/?project__id=${project_pk}&page_size=1000`;

    const {data, error, mutate} = useSWR(() => project_pk ? url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

// Get information on a function on if there are any open issues with the function.
export function getStatusChangePreCheck(pk, status, type) {
    if (!status || !Object.entries(functionStatus).some(obj => obj[0] === status)) {
        console.log('wrong status')
    }

    let url = `/api/functions/${pk}/precheck/?` + type + '=' + status;


    const {
        data,
        error,
        mutate
    } = useSWR(() => pk && Object.entries(functionStatus).some(obj => obj[0] === status) && type ? url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

// Actually request the server to update the status of a function
export async function remoteSetStatus(pk, status, type) {
    if (!status || !Object.entries(functionStatus).some(obj => obj[0] === status)) {
        console.log('wrong status')
        return false;
    }

    return await axios.patch(`/api/functions/${pk}/status/`, {
        [type]: status
    }).then(result => {
        return result;
    });
}

export async function remoteSetBulkStatus(statusDict, selection) {

    const data = Object.assign({}, statusDict, {selection});
    return await axios.patch(`/api/functions/bulk_status/`, data).then(result => {
        return result;
    });
}

export async function remoteCopyBulkFunction(projectId, selection) {

    return await axios.patch(`/api/functions/copy/`, {
        project_id: projectId,
        selection,
    }).then(result => {
        return result;
    });
}


// Request a lock for this function so we can edit it
export async function remoteAcquireLock(pk = false) {
    if (!pk) {
        return false;
    }
    return await axios.patch(`/api/functions/${pk}/acquire_lock/`)
        .then(result => result);
}

// Request a lock for this function so we can edit it
export async function remoteUnlock(pk = false) {
    if (!pk) {
        return false;
    }
    return await axios.patch(`/api/functions/${pk}/unlock/`)
        .then(result => result);
}

export async function remotePing(pk) {
    return await axios.patch(`/api/functions/${pk}/ping/`);
}

export async function remoteSetPartial(pk = false, data) {
    if (!pk) {
        return false;
    }

    return await axios.patch(`/api/functions/${pk}/`, data)
        .then(result => result);
}

export async function remoteCreateNewVersion(pk, new_status = 'concept', expireOldVersion = false) {
    if (!pk) {
        return false;
    }

    return await axios.patch(`/api/functions/${pk}/new_version/`, {
        new_status,
        expire_old_version: expireOldVersion
    })
        .then(result => result);

}

export async function remoteSetFavorite(pk, project_id = false, client_id = false) {
    return await axios.patch(`/api/functions/${pk}/favorite/`, {
        client_id,
        project_id
    });
}

export async function remoteUnsetFavorite(pk, project_id = false, client_id = false) {
    return await axios.patch(`/api/functions/${pk}/unfavorite/`, {
        client_id,
        project_id
    });
}

export async function remoteExpireFunction(pk) {
    return await axios.patch(`/api/functions/${pk}/`, {
        'scoring_status': 'expired',
        'description_status': 'expired'
    });
}

export async function archiveFunction(pk) {
    return await axios.delete(`/api/functions/${pk}/`);
}

export async function archiveFunctions(ids) {
    return axios.delete(`/api/functions/bulk_archive/?id__in=${ids}`)
}

export async function setPrintOrientationFunction(function_id, printOrientation) {
    return await axios.patch(`/api/functions/${function_id}/`, {
        print_orientation: printOrientation
    });
}

export async function createFunction(values, navigate) {
    await axios.post("/api/functions/", {
        ...values,
        // reverse visibility since the form toggles invisibility
        is_visible: !values.is_invisible,
    })
        .then(result => {
            if (result.status === 201) {
                if (result.data?.id) {
                    if (result?.data?.client && result?.data?.project && result?.data?.id) {
                        navigate(generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                            client_id: result.data.client.id,
                            project_id: result.data.project.id,
                            function_id: result.data.id
                        }));
                    }
                } else {
                    throw new Error("server-error-function-create", {cause: result});
                }
            } else {
                throw new Error("server-error-function-create", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}

export async function editFunction(values, navigate, function_id) {
    await axios.patch("/api/functions/" + function_id + "/", values)
        .then(result => {
            if (result.status === 200) {
                navigate('../');
            } else {
                throw new Error("server-error-function-edit", {cause: result});
            }
        })
        .catch(function (error) {
            throw new Error("server-error", {cause: error});
        })
}

export function getSelectedReferenceFunctions(id, refIds) {
    let url = `/api/functions/${id}/relative_distance/?page=1&page_size=10&view=rank&ordering=distance&type__in=reference&scoring_status=determined&id__in=${refIds}`;

    const {data, error, mutate} = useSWR(() => (id && refIds.length) ? url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}
