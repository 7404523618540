import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {useFunctionCreate} from "../../components/FunctionCreate";
import {withHook} from "../../utils/withHookHoC";
import Drawer from "../../components/Drawer";
import Overview from "../../components/Overview";
import {
    FunctionSelectionDefinition,
    FunctionSelectionDefinitionAll
} from "../../components/Overviews/FunctionSelection";
import {DepartmentSelectionDefinition} from "../../components/Overviews/DepartmentSelection";
import {ProjectSelectionDefinition} from "../../components/Overviews/ProjectSelection";
import SingleFieldModal from "./SingleFieldModal";
import {remoteSetDepartment} from "../../queries/department";
import {AccessDenied} from "./AccessDenied";
import LoaderAnimation from "../atoms/LoaderAnimation";
import Quickview from "../../components/Quickview";
import {withAccessRight} from "../../utils/withAccessHoC";
import {UserGroupFormItem} from "../../components/UserGroupFormItemNew";
import {helpText} from "../../utils/constants";
import {isFuwaterVbalans} from "../../utils/constants";
import {Controller, useForm} from "react-hook-form";
import {Input, CheckboxInput} from "../atoms/Input";
import Select from "../atoms/Select";
import {useNavigate} from "react-router-dom";
import {createFunction} from "../../queries/function_detail";
import Notification from "../molecules/Notification";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const FunctionCreatePatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function FunctionCreateDisplay(props) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        setError,
        clearErrors,
        formState: {errors}
    } = useForm({
        defaultValues: {
            copy_description: true,
            copy_scoring: true,
        },
        shouldUnregister: true
    });

    useEffect(() => {
        if (props.project) {
            setValue("type", (isFuwaterVbalans(props.project?.method?.id)) ? 'reference' : props.type_options?.some(o => o.value === 'company') ? 'company' : props.type_options?.[0].value || 'reference')
            setValue("describer_group_id", props.project?.default_describer_group?.id);
            setValue("applier_one_group_id", props.project?.default_applier_one_group?.id);
            setValue("applier_two_group_id", props.project?.default_applier_two_group?.id);
        }

        if (props.basedOnFunction?.data) {
            setValue("based_on_id", props.basedOnFunction?.data?.id);
            setValue("type", props.basedOnFunction?.data?.type);
        }
    }, [props.project, props.basedOnFunction])

    const {t} = useTranslation();
    const navigate = useNavigate();

    const onSubmit = () => async (values) => {
        const submitValues = {...values, project_id: props.project_id, client_id: props.client_id}
        await createFunction(submitValues, navigate);
    }

    if (!Array.isArray(props.type_options)) {
        return <LoaderAnimation/>;
    }
    if (!props?.canChangeIfCollectiveLabourAgreement) {
        return <AccessDenied additionalText={t("Onvoldoende rechten om een functie binnen dit project aan te maken")}/>;
    }
    if (props.type_options && props.type_options?.length < 1) {
        return <AccessDenied additionalText={t("geen toegang tot functietypen")}/>;
    }

    let scoring_structure_id = [];
    if (props?.method?.company_function_scoring_structure_id) {
        scoring_structure_id.push(props?.method?.company_function_scoring_structure_id);
    }
    if (props?.method?.ref_function_scoring_structure_id) {
        scoring_structure_id.push(props?.method?.ref_function_scoring_structure_id);
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Functie aanmaken")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error.cause.message === "server-error-function-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>

                <section className={"form-meta"}>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Project")}</span>
                        <span className={"form-meta__description"} data-cy={"project-name"}>{props?.project?.name}</span>
                    </div>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Klant")}</span>
                        <span className={"form-meta__description"} data-cy={"client-name"}>{props.client?.name}</span>
                    </div>
                </section>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Functiegegevens")}</legend>

                    {props.based_on_name &&
                        <section className={"form-meta"}>
                            <div className={"form-meta__item"}>
                                <span className={"form-meta__label"}>{t("Gebaseerd op")}</span>
                                <span className={"form-meta__description"} data-cy={"based-on-name"}>{props.based_on_name}</span>
                            </div>
                        </section>
                    }

                    {!props.based_on_name &&
                        <div className="input-group">
                            <Controller name={"based_on_id"} control={control}
                                        render={({field: {onChange}}) => (
                                            <Drawer
                                                title={t("Selecteer functie")}
                                                label={t("Baseer op bestaande functie")}
                                                initialReadOnlyVal={props?.based_on_name}
                                                placeholder={t("Geen functie gekozen")}
                                                readOnlyVal={obj => obj.name}
                                                id={"based_on_id_drawer"}
                                                disabled={false}
                                                error={errors.based_on_id}
                                                isForm={true}
                                                reset={() => {
                                                    onChange(null)
                                                }}
                                                onClick={(obj) => {
                                                    onChange(obj.id);
                                                }}
                                                contentComponent={
                                                    <Quickview>
                                                        {props?.client_id && (
                                                            <Overview
                                                                identifier={"select-function-overview"}
                                                                quickview={{
                                                                    identifier: "select-function-overview",
                                                                    name: "Huidig project"
                                                                }}
                                                                identKey={{
                                                                    client_id: props.client_id,
                                                                    project_id: props.project_id
                                                                }}
                                                                contextData={{
                                                                    location,
                                                                    client_id: props.client_id,
                                                                    project_id: props.project_id,
                                                                }}
                                                                definition={FunctionSelectionDefinition}
                                                            />
                                                        )}
                                                        <Overview
                                                            identifier={"select-function-overview-global"}
                                                            quickview={{
                                                                identifier: "select-function-overview-global",
                                                                name: "Alle projecten"
                                                            }}
                                                            identKey={{
                                                                project_id: props.project_id,
                                                                scoring_structure_id,
                                                            }}
                                                            contextData={{
                                                                location,
                                                                client_id: props.client_id,
                                                                project_id: props.project_id,
                                                            }}
                                                            definition={{
                                                                ...FunctionSelectionDefinitionAll,
                                                                columns: FunctionSelectionDefinitionAll.columns.filter(o => !['vbalans', 'mnt'].includes(o.identifier) || o.identifier === (isFuwaterVbalans(props?.method?.id) ? 'vbalans' : 'mnt'))
                                                            }}
                                                        />
                                                    </Quickview>
                                                }
                                            />
                                        )}
                            />
                        </div>
                    }

                    {watch("based_on_id") && (
                        <div className="input-group">
                            <CheckboxInput
                                name={"copy_description"}
                                type={"checkbox"}
                                id={"copy_description"}
                                label={t("Beschrijving overnemen")}
                                helpTitle={t("Wat doet beschrijving overnemen?")}
                                helpText={t("Deze optie neemt de beschrijving over van de functie gekozen " +
                                    "die is gekozen. Hij wordt in zijn geheel overgenomen. " +
                                    "Wil je alleen een deel overnemen? Kies deze optie dan niet, maar kies bij " +
                                    "het beschrijven van de functie voor 'Baseer op bestaande functie'")}
                                register={register}
                            />
                        </div>
                    )}
                    {watch("based_on_id") && (
                        <div className="input-group">
                            <CheckboxInput
                                name={"copy_scoring"}
                                type={"checkbox"}
                                id={"copy_scoring"}
                                label={t("Indeling overnemen")}
                                helpTitle={t("Wat doet indeling overnemen?")}
                                helpText={t("Deze optie zorgt er voor dat de onderdelen die te maken hebben " +
                                    "met de indeling worden overgenomen in de nieuwe functie. " +
                                    "Wanneer het functietype niet, of maar gedeeltelijk overeenkomt worden " +
                                    "alleen de delen overgenomen die overeenkomen.")}
                                register={register}
                            />
                        </div>
                    )}

                    <div className="input-group">
                        <Select
                            name={"type"}
                            type={"select"}
                            options={props.type_options?.filter(o => o.value !== 'match' || (o.value === "match" && props?.method?.allow_function_matching === true))}
                            id={"type"}
                            label={t("Functietype")}
                            register={register}
                            error={errors.type}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"name"}
                            type={"text"}
                            id={"name"}
                            label={t("Functienaam")}
                            placeholder={t("Functienaam")}
                            data-cy={"function-create-name"}
                            register={register}
                            error={errors.name}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"code"}
                            type={"text"}
                            id={"code"}
                            label={t("Functiecode")}
                            placeholder={t("Functiecode")}
                            error={errors.code}
                            register={register}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"additional_information"}
                            type={"text"}
                            id={"additional_information"}
                            label={t("Aanvullende informatie")}
                            placeholder={t("Aanvullende informatie over deze functie\.\.\.")}
                            error={errors.additional_information}
                            register={register}
                        />
                    </div>

                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Overige gegevens")}</legend>

                    {props?.canChangeVisibility && (
                        <div className="input-group">
                            <CheckboxInput
                                name={"is_invisible"}
                                type={"checkbox"}
                                id={"is_invisible"}
                                label={t("Functie is onzichtbaar")}
                                helpTitle={t("Wat is een onzichtbare functie?")}
                                helpText={t("Een 'onzichtbaar' functie is alleen zichtbaar voor adviseurs van " +
                                    "Human Capital Group, niet voor externen. Een 'onzichtbare' functie kan " +
                                    "altijd weer op 'zichtbaar' worden gezet." +
                                    "")}
                                register={register}
                            />
                        </div>
                    )}

                    {props?.canChangeProtection && (
                        <div className="input-group">
                            <CheckboxInput
                                name={"is_protected"}
                                type={"checkbox"}
                                id={"is_protected"}
                                label={t("Functie is beschermd")}
                                helpTitle={"Wat is een beschermde functie?"}
                                helpText={t("Door de functie te 'beschermen' kunnen er geen wijzigingen door " +
                                    "gebruikers met een andere rol dan 'Adviseur' aangebracht worden. Een " +
                                    "beschermde functie kan altijd weer op 'Niet beschermd' worden gezet.")}
                                register={register}
                            />
                        </div>
                    )}

                    <div className="input-group">
                        <Controller name={"department_id"} control={control}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={"Selecteer hoofdafdeling"}
                                            label={t("Hoofdafdeling")}
                                            initialReadOnlyVal={props?.department_name}
                                            placeholder={t("Geen hoofdafdeling gekozen")}
                                            readOnlyVal={obj => obj.name}
                                            id={"department_id_drawer"}
                                            disabled={false}
                                            action={{
                                                title: "Nieuwe afdeling aanmaken",
                                                Component: SingleFieldModal,
                                                remoteCall: name => remoteSetDepartment(props.client_id, name),
                                                field: {
                                                    name: "department",
                                                    label: t("Afdeling"),
                                                    placeholder: t("Naam van de afdeling")
                                                }
                                            }}
                                            error={errors.department_id}
                                            isForm={true}
                                            reset={() => {
                                                onChange(null)
                                            }}
                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}
                                            contentComponent={
                                                <Overview
                                                    identifier={"select-department-overview"}
                                                    identKey={{
                                                        client_id: props.client_id,
                                                        project_id: props.project_id
                                                    }}
                                                    contextData={{
                                                        location,
                                                        client_id: props.client_id,
                                                        project_id: props.project_id,
                                                    }}
                                                    definition={DepartmentSelectionDefinition}
                                                />
                                            }
                                        />
                                    )}
                        />
                    </div>


                    <div className="input-group">
                        <Controller name={"sub_department_id"} control={control}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={"Selecteer subafdeling"}
                                            label={t("Subafdeling")}
                                            initialReadOnlyVal={props?.sub_department_name}
                                            placeholder={t("Geen subafdeling gekozen")}
                                            readOnlyVal={obj => obj.name}
                                            id={"sub_department_id_drawer"}
                                            disabled={false}
                                            action={{
                                                title: "Nieuwe afdeling aanmaken",
                                                Component: SingleFieldModal,
                                                remoteCall: name => remoteSetDepartment(props.client_id, name),
                                                field: {
                                                    name: "department",
                                                    label: t("Afdeling"),
                                                    placeholder: t("Naam van de afdeling")
                                                }
                                            }}
                                            error={errors.sub_department_id}
                                            isForm={true}
                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id);
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-sub-department-overview"}
                                                    identKey={{
                                                        client_id: props.client_id,
                                                        project_id: props.project_id
                                                    }}
                                                    contextData={{
                                                        location,
                                                        client_id: props.client_id,
                                                        project_id: props.project_id,
                                                    }}
                                                    definition={DepartmentSelectionDefinition}
                                                />
                                            }
                                        />
                                    )}
                        />
                    </div>

                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Rollen toekennen")}</legend>

                    <Controller name={"describer_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.describer_group}
                                        client_id={props.client?.id}
                                        id_prefix={"describer_group"}
                                        label={"Beschrijver"}
                                        helpTitle={"Wat is een \"beschrijver\"?"}
                                        helpText={t(helpText.beschrijver)}
                                        onChange={onChange}
                                        error={errors.describer_group}
                                    />
                                )}
                    />

                    <Controller name={"applier_one_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.applier_one_group}
                                        client_id={props.client?.id}
                                        id_prefix={"applier_one_group"}
                                        label={"Toepasser"}
                                        helpTitle={t("Wat is een \"toepasser\"?")}
                                        helpText={t(helpText.toepasser)}
                                        onChange={onChange}
                                        error={errors.applier_one_group}
                                    />
                                )}
                    />

                    <Controller name={"applier_two_group_id"} control={control}
                                render={({field: {onChange}}) => (
                                    <UserGroupFormItem
                                        obj={props.applier_two_group}
                                        client_id={props.client?.id}
                                        id_prefix={"applier_two_group"}
                                        label={"Toetser"}
                                        helpTitle={t("Wat is een \"Toetser\"?")}
                                        helpText={t(helpText.toetser)}
                                        onChange={onChange}
                                        error={errors.applier_two_group}
                                    />
                                )}
                    />

                    {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                    text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Aanmaken")}
                        fullWidth={true}
                        data-cy={"function-create-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const FunctionCreate = withAccessRight(
    withHook(
        FunctionCreateDisplay,
        useFunctionCreate
    ),
    'base.change_function',
    [
        ['project_id', 'project'],
        ['client_id', 'client']

    ]
)
