import axios from "axios";
import { useSWRInfinite } from "swr";
import {buildFilter, buildOrder, loadIfAllowed} from "../utils/constants";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";

export function getProjects(stateToDebounce) {

    const identifiers = ['projects', 'user-projects', 'client-projects', 'project-overview', 'select-project-overview',
        'project-projects-select-project', 'projectgroup-projects', 'select-project-overview-global',
        'dashboard-projects-favorite', 'dashboard-projects-recent', 'select-project-overview', 'function-create-select-project', 'license-overview',
        'license-overview-with-license-expiration-date', 'license-overview-without-license-expiration-date'];

    const fetchState = useDebounce(stateToDebounce);

    const allowed_statuses = [
        'concept', 'determined', 'expired'
    ];

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.project_groups__not__id ? {
                project_groups__not__id: fetchState?.identKey?.project_groups__not__id
            } : {},
            fetchState?.identKey?.project_group_id ? {
                project_groups__id: fetchState?.identKey?.project_group_id
            } : {},
            fetchState?.identKey?.client_id ? {
                client__id: fetchState?.identKey?.client_id
            } : {},
            fetchState?.identKey?.projects ? {
                id: fetchState?.identKey?.projects.join(',')
            } : {},
            fetchState?.identKey?.user_id ? {
                user__id: fetchState?.identKey?.user_id
            } : {},
            fetchState?.filter?.status__in  && fetchState.filter.status__in.length > 0 ? {
                status__in: allowed_statuses.filter(o => fetchState?.filter?.status__in?.includes(o)),
            } : {
                status__in: allowed_statuses,
            },
            fetchState?.identKey?.favorite ? {
                favorite: true,
            } : {},
            fetchState?.identKey?.without_extended ? {
                without_extended: true,
            } : {},
            typeof fetchState?.identKey?.license_expiration_date !== "undefined" ? {
                license_expiration_date__isnull: fetchState?.identKey?.license_expiration_date
            } : {},
            fetchState?. identKey?.is_collective_labour_agreement ? {
                is_collective_labour_agreement: fetchState.identKey?.is_collective_labour_agreement,
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/projects/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index+1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}`: false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}
