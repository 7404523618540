import React from "react";

import {getClients} from "../../queries/client";
import {DateFilter} from "./Filters/DateFilter";
import {TextFilter} from "./Filters/TextFilter";
import i18n from "../../utils/i18n";


const StandardRowStructure = React.memo(tempProps => {

    let props = Object.assign(
        {
            id: false,
            name: "",
            constact_name: "",
            contact_telephone: "",
            creation_date: "",
            city: "",
        },
        tempProps,
    );

    const detailClick = props?.onClick;

    const creation_date = props.creation_date ? new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date(props.creation_date)) : false;

    return (
        <tr
            className={"overview-table__row"}
            onClick={() => {            // the onclick is passed along through the Drawer component
                detailClick(props);
            }}
            key={props.id}
        >
            <td className="overview-table__cell overview-table__cell--name overview-table__cell--interactive" data-cy="overview-table-cell-name">
                {props.name}
            </td>
            <td className="overview-table__cell">
                {props.contact_name}
            </td>
            <td className="overview-table__cell">
                {props.contact_telephone}
            </td>
            <td className="overview-table__cell">
                {props.city}
            </td>
            <td className={"overview-table__cell"}>
                {props.creation_date ? (
                    <div className={"overview-table__text"}>
                        <time dateTime={props.creation_date}>
                            {new Intl.DateTimeFormat("nl-NL", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            }).format(new Date(props.creation_date))}
                        </time>
                    </div>
                ) : (
                    <div className={"overview-table__skeleton"}/>
                )}
            </td>
            {/*<td className={"overview-table__cell"}>*/}
            {/*    <InfoButton {...props}*/}
            {/*        sizeModifier={"medium"}>*/}
            {/*        {props.title}*/}
            {/*    </InfoButton>*/}
            {/*</td>*/}
        </tr>
    )

});

export const ClientSelectionDefinition = {

    title: i18n.t("Klantoverzicht"),
    identifier: 'client-overview',
    queryFn: getClients,
    RowStructure: StandardRowStructure,
    noHeader: true,
    noSearch: true,
    noContainer: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: i18n.t('Naam'),
            identifier: 'name',
            orderable: true,
            className:'overview-table__filter-cell--width-name',
            placeholder: i18n.t('Klantnaam'),
            Filter: TextFilter
        },
        {
            title: i18n.t('Naam contactpers.'),
            identifier: 'contact_name',
            orderable: true,
            className:'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Naam contactpers.'),
            Filter: TextFilter,
        },
        {
            title: i18n.t('Tel. contactpers.'),
            orderable: true,
            identifier: 'contact_telephone',
            className:'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Tel. contactpers.'),
            Filter: TextFilter,
        },
        {
            title: i18n.t('Stad'),
            identifier: 'city',
            orderable: true,
            className:'overview-table__filter-cell--width-large',
            placeholder: i18n.t('Woonplaats'),
            Filter: TextFilter,
        },
        {
            title: 'Aangemaakt op',
            identifier: 'creation_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter
        },
        // {
        //     identifier: 'info',
        //     className: 'overview-table__filter-cell--width-icon-button',
        //     Title: EmptyTitle,
        //     Filter: EmptyFilter
        // }
    ]
}
