import React, {useEffect, useState} from "react";
import {useAuth} from "../utils/useAuth";
import {useParams} from "react-router-dom";
import {getProject} from "../queries/project_detail";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {withAccessRight} from "../utils/withAccessHoC";
import IconButton from "../patterns/molecules/IconButton";
import {useTranslation} from "react-i18next";
import {useDebounce, useDebounce2} from "../utils/useDebounce";
import Noty from "noty";
import {
    remoteAddProjectFunctionDescriptionTemplate, remoteDelProjectFunctionDescriptionTemplate,
    remoteSetProjectFunctionDescriptionTemplate
} from "../queries/function_description";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import ToolTip from "./ToolTip";

export function DescriptionTemplateBase(props) {

    const {t, i18n} = useTranslation();
    const { project_id, client_id } = useParams();
    const [items, setItems] = useState(null);

    const {data: projectDetail, mutate } = getProject(project_id);
    const debounceUpdate = useDebounce2(value => save(), 4000);
    const [displayModal, setDisplayModal] = useState(false)

    const [debouncedSave, setDebouncedSave] = useDebounce(false);

    const add = async () => {
        await remoteAddProjectFunctionDescriptionTemplate(projectDetail.id, t("Nieuwe koptekst"), items.length);
        mutate();
    }

    const del = async (id) => {
        await remoteDelProjectFunctionDescriptionTemplate(id);
        mutate();
    }

    const save = () => {

        Object.entries(items).map( o => {
            const template = o[1];
            remoteSetProjectFunctionDescriptionTemplate(template.id, template.title, template.order);
        })

        let noty = new Noty({
            theme: 'bootstrap-v4',
            layout: 'bottomRight',
            text: t("Je wijziging is automatisch opgeslagen!"),
            closeWith: ['click', 'button'],
            type: "success",
            timeout: 2000,
        }).show();
    }


    useEffect(() => {
        if(items) {
            save();
        }
    }, [debouncedSave]);


    useEffect(() => {
        if(Array.isArray(projectDetail?.function_description_templates) && projectDetail?.function_description_templates.length >= 0) {
            let it = 1;
            let res = projectDetail.function_description_templates.sort((a,b) => a.order > b.order ? 1: -1 ).reduce( (acc, template) => {
                acc[it] = {
                    ...template,
                    order: it,
                };
                it = it + 1;
                return acc;
            }, {})
            setItems(
                res
            );
        }
    },[projectDetail?.function_description_templates]);

    const changeTitle = (id, title) => {
        const obj = Object.entries(items).find(o => o[1].id === id)[1];
        obj.title = title;
        setItems({...items});
        setDebouncedSave({...items});
    }

    const changeOrder = (id, direction=true) => {
        const obj = Object.entries(items).find(o => o[1].id === id)[1];
        let otherObj = false;
        if(obj) {
            if(direction) {
                if(obj.order > 1) {
                    otherObj = Object.entries(items).find(o => o[1].order === obj.order - 1)?.[1];
                    obj.order = obj.order - 1
                    if(otherObj) {
                        otherObj.order = otherObj.order + 1;
                    }
                }
            } else {
                otherObj = Object.entries(items).find(o => o[1].order === obj.order + 1)?.[1];

                if(obj.order +1 <= Object.entries(items).length)  {
                    obj.order = obj.order + 1;

                    if(otherObj) {
                        otherObj.order = otherObj.order - 1;
                    }
                }
            }
        }
        setItems({...items});
        setDebouncedSave({...items});
    }


    if(!projectDetail || items === null) {
        return <LoaderAnimation/>
    }

    return (
        <div className={"content-container"}>
            <div className="description-editor">

                <div className="description-editor__item">
                    <div className="description-editor__text description-editor__text--auto-height">
                        <h2 className={"modal-header__title"}>{t("Standaard template")}</h2>
                        <p className={"paragraph"}>
                            {t("Wijzig de standaard template voor alle functies binnen dit project. Alleen wanneer een nieuwe, lege beschrijving wordt gemaakt kan dit template gevolgd worden. Bestaande beschrijvingen worden niet aangepast.")}
                        </p>
                    </div>
                </div>

                {Object
                    .entries(items)
                    .sort((a,b) => a[1].order > b[1].order ? 1: -1 )
                    .map( (obj, idx) => {

                        const [ order , template ] = obj;
                        return (
                            <div key={template.id} className="description-editor__item">
                                <div className="description-editor__text">
                                <input type={"text"} name={"title_" + template.id } value={template.title}
                                       onChange={e => {
                                           changeTitle(template.id, e.target.value)
                                       }}/>
                                </div>
                                <div className={"description-editor__buttons"}>
                                    <ToolTip tooltip={t("Verplaats omhoog")}>
                                        <IconButton title={t("Verplaats omhoog")}
                                                    icon={"arrow-up"}
                                                    className={"description-editor__button"}
                                                    onClick={() => changeOrder(template.id, true)}
                                        />
                                    </ToolTip>
                                    <ToolTip tooltip={t("Verplaats omlaag")}>
                                        <IconButton title={t("Verplaats omlaag")}
                                                    icon={"arrow-down"}
                                                    className={"description-editor__button"}
                                                    onClick={() => changeOrder(template.id, false)}
                                        />
                                    </ToolTip>
                                    <ToolTip tooltip={t("Verwijder")}>
                                        <IconButton title={t("Verwijder")}
                                                    icon={"trash"}
                                                    className={"description-editor__button"}
                                                    onClick={() => setDisplayModal(template)}
                                        />
                                    </ToolTip>
                                </div>
                            </div>
                        )
                    })}

                {displayModal && (
                    <ConfirmModal
                        key={'default-text'}
                        title={t("Verwijder koptekst")}
                        body={t("Weet je zeker dat je onderstaande koptekst wilt verwijderen?")}
                        subject={displayModal.title + " " + displayModal.id|| ""}
                        confirmModal={() => {
                            del(displayModal.id);
                        }}
                        closeModal={() => setDisplayModal(false)} />
                )}
                <div className={"description-editor__options"}>
                    <button
                        className={"button button--tertiary"}
                        onClick={() => add()}
                    >{t("Nieuwe koptekst toevoegen")}</button>
                </div>
            </div>
        </div>
    )
}

export default withAccessRight(
    DescriptionTemplateBase,
    "base.change_project",
    [
        ["project_id", "project"]
    ]
)

