import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setCache, setLogin, setOption, setToken} from "../actions/auth";
import axios from "axios";
import {AuthDefaultState} from "../reducers/auth";
import {useCookies} from "react-cookie";
import {switchToUser} from "../queries/user";

export function useAuth() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const state = useSelector(state => state.AuthState);
    const [cookies, setCookie, removeCookie] = useCookies(["token"]);

    useEffect(() => {
        if (!state?.authLoaded && state?.user?.roles?.length > 0) {
            let nState = {};
            state.user.roles.map( user_role => {

                if(user_role?.object) {

                } else {

                }
                user_role.role.permissions.map( permission => {

                    let key = user_role?.object ?
                      JSON.stringify([permission.codename, user_role.object.type, parseInt(user_role.object.id)])
                      :
                      JSON.stringify([permission.codename]);
                    nState[key] = true;
                })

            });

            dispatch(setOption({permCache: nState}));
            dispatch(setOption({authLoaded: true}));
            if(state.user.insight_into_projects.length === 0) {
                dispatch(setOption({insightUser: false}));
            }
        }
    }, [state?.authLoaded, state?.user?.roles?.length]);


    const hasPermission = (codename, object_id, type='project') => {
        const objectKey = JSON.stringify([codename, type, parseInt(object_id)])
        const globalKey = JSON.stringify([codename]);
        // console.log(`checking ${codename} ${type} ${object_id} --> ${objectKey} || ${globalKey}`);

        return state?.authLoaded ? state.permCache?.[globalKey] || state.permCache?.[objectKey] || false : null;
    }

    const checkAccessRight = (props) => {

        const right = props.right;
        const param = props?.param || false;
        const type = props?.type || 'project';
        const params = param ? useParams() : false;
        const object_id = props?.object_id || params?.[param] || false;

        return hasPermission(right, object_id, type);

    }

    const checkAccessList = (right, list) => {

        // we pass a list of param|type options
        let finalResult = null;

        list.forEach( item => {
            // each item should be a list of 2 items, which can be a number|type or param|type  pair
            const [ localParam, localType ] = item;
            const id = parseInt(localParam);
            let result = null;

            if(isNaN(id)) {
                // no number is parsed, so it's probably a URL param identifier
                result = checkAccessRight({right: right, param: localParam, type: localType });
                // console.log(`Checking param based right:${right} param: ${localParam} type: ${localType} --> ${result}`)
            } else {
                // it is an id, so treat it as such
                result = checkAccessRight({right: right, object_id: id, type: localType })
                // console.log(`Checking object based right: ${right} object_id: ${id} type: ${localType} --> ${result}`);
            }

            if(finalResult !== true) {
                finalResult = result;
            }
        });

        // console.log("Acces from list items resulted in", finalResult)
        return finalResult;

    }

    const validateResponse = result => {

        if (result.status === 200) {

            if (result.data.status === 'ok') {
                // auth.signin(result.data.next);
                // window.location = result.data.next;
                let ret =  {
                    next: result?.data?.next,
                    user: {
                        ...result?.data?.user,
                        authorized: true,
                    },
                }
                return ret;
            } else {
                return {
                    error: "Error in username or password"
                };
            }
        }
        return {
            error: "Server response invalid"
        };
    }

    const validateResponse2 = result => {
        if (result.status === 200) {
            return result;
        } else {
            throw new Error("server-error")
        }
    }

    const validateResult = result => {

        if(result?.user?.authorized){
            dispatch(setLogin(result.user));
            if(result?.next) {
                navigate(result.next);
            }
            return true;
        }
        return result?.error || "Something went wrong logging in...";

    }

    const validateResult2 = result => {
        // check if result contains a token and return the data
        if(result?.data?.token) {
            dispatch(setToken(result?.data?.token));

            validateLogin2().then(() => {
                setCookie('token', result?.data?.token, { path: '/' });

                axios.defaults.headers.common['Authorization'] = `Token ${result?.data?.token}`;

                window.location.href = "/";
            }).catch(() => {console.error("Not validated!")});
        }

        return result;
    }

    const switchUser = async (id) => {
        const result = await switchToUser(id);
        validateResult2(result);
    }

    const login = async (username, password) => {

        let result = await axios.post("/api/login/", {
            username,
            password,
        })
          .then( result => validateResponse(result))
          .catch(function (error) {
              console.log(error);
          })

        return validateResult(result);
    }

    const login2 = async (username, password) => {
        let result = await axios.post("/api/token/", {
            username,
            password,
        })
          .then( result => validateResponse2(result))
          .catch(function (error) {
              throw new Error("server-error", {cause: error})
          })

        return validateResult2(result);
    }

    const validate_login = async () => {
        let result = await axios.get("/api/validate_login/")
          .then( result => validateResponse(result))
          .catch(function (error) {
              console.log(error);
          })
        let res = await validateResult(result);
        if(res !== true) {
            dispatch(setLogin({
                ...AuthDefaultState.user,
                authorized: false,  // explicit set auth to false (Because we are not authorized)
            }))
            return false
        }
        return  res;
    }

    const validateLogin2 = async () => {
        await axios.get("/api/validate_login/").then(result => {
            if(result.data.status === "error" || result.status === 401){
                dispatch(setLogin({
                    ...AuthDefaultState.user,
                    authorized: false,  // explicit set auth to false (Because we are not authorized)
                }))

                removeCookie('token', { path: '/' });
                axios.defaults.headers.common['Authorization'] = ``;
            } else {
                dispatch(setLogin({...result.data?.user, authorized: true}));
            }
        }).catch(function (error) {
            return error.response.data;
        })
    }

    const logout = (returnUrl='/') => {
        dispatch(setLogin(AuthDefaultState.user))
        window.location.href = '/logout/';
    }

    const logout2 = (returnUrl='/') => {
        dispatch(setToken(null));
        dispatch(setLogin(AuthDefaultState.user));

        axios.defaults.headers.common['Authorization'] = '';

        removeCookie('token', { path: '/' });
        removeCookie('sessionid', { path: '/' });
        removeCookie('csrftoken', { path: '/' });

        if(state.user.is_staff) {
            window.location.href = '/admin/logout/'
        } else {
            window.location.href = returnUrl;
        }
    };

    const authorized = () => {
        /// check if is authorized, maybe do some sanity check on tokens or something
        return state.user.authorized;
    }


    // return {
    //     user: state.user,
    //     loaded: state.authLoaded,
    //     insightUser: state.insightUser,
    //     hasPermission,
    //     authorized,
    //     login,
    //     logout,
    //     validate_login,
    //
    //     checkAccessRight,
    //     checkAccessList,
    // }

    return {
        user: state.user,
        loaded: state.authLoaded,
        insightUser: state.insightUser,
        hasPermission,
        authorized,
        login: login2,
        logout: logout2,
        switchUser,
        validate_login: validateLogin2,

        checkAccessRight,
        checkAccessList,
    }
}

