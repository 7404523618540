import React from 'react';

export const ScorePatternState = {
    points: 184,
}
export default function Points(props) {
    return (
        <div className={"pattern-points"} data-cy={"pattern-points"}>
            {props.points || '-'}
        </div>
    )
}
