import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";

import ProjectMetaDisplay from "../patterns/organisms/ProjectMeta";
import {getProject} from "../queries/project_detail";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import {useTranslation} from "react-i18next";
import {AccessDenied} from "../patterns/organisms/AccessDenied";
import TabNavigation from "./TabNavigation";
import {projectTabsProps} from "../utils/constants";
import {useAuth} from "../utils/useAuth";

export default function ProjectMeta(props) {

    const {client_id, project_id} = useParams();
    const {data: projectDetail, mutate: projectMutate} = getProject(project_id)
    const location = useLocation();
    const auth = useAuth();


    if(!projectDetail) {
        return <LoaderAnimation/>;
    }

    if(projectDetail?.noAccess) {
        return <AccessDenied/>
    }

    return (
        <>
            <ProjectMetaDisplay
                project_id={project_id}
                projectDetail={projectDetail}
                client_id={projectDetail?.client?.id}
                projectMutate={projectMutate}
            />

            {!location.pathname.includes("template") &&
                <div className={"content-container content-container--tabs"}>
                    <TabNavigation tabs={projectTabsProps(client_id ? client_id : projectDetail?.client?.id, project_id, auth, projectDetail)}/>
                </div>
            }
        </>
    )
}
