import React from 'react';
import {useSelector} from "react-redux";
import i18n from "../../utils/i18n";

// Values used by PatternLab
export const HeaderPatternState = {
    title: i18n.t('Default Title'),
    items: [
        'Default Context Item 1',
        'Default Context Item 2',
        (<a href={"#"}>Default Context Link?</a>),
    ]
};

export default function HeaderDisplay(props) {

    return (
        <ul>
            {props.items.map( (val, idx) => {
                return (
                    <li className={"dropdown-field"} key={idx}>
                        {val}
                    </li>
                )
            })}
        </ul>
    )
}
