import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-modal";
import IconButton from "../molecules/IconButton";
import ButtonSecondary from "../atoms/ButtonSecondary";
import ButtonPrimary from "../atoms/ButtonPrimary";

export default function ConfirmModal(props) {

    const {t, i18n} = useTranslation();
    const closeModal = () => {
        props?.closeModal && props.closeModal();
        props?.fallbackCloseModal ? props?.fallbackCloseModal() : null;
    }

    useEffect(() => {
        const escapeHandler = (e) => {
            if(e.key === "Escape") {
                closeModal();
            }
        }

        document.addEventListener("keyup", escapeHandler);

        return () => {
            document.removeEventListener("keyup", escapeHandler);
        }
    })

    return (
        <>
            <Modal isOpen={true} className={"modal modal--small"} overlayClassName={"modal-background"}
                   bodyOpenClassName={"has-modal"}>
                <div className={"modal-header"}>
                    <div className={"modal-header__top"}>
                        <h2 className={"modal-header__title"}>{t(props.title) || t("Bevestig")}</h2>
                        <ul className={"modal-header__controls"}>
                            <li className={"modal-header__control"}>
                                <IconButton icon={"close"} onClick={() => closeModal()}/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={"modal__body"}>
                    {!props?.children && (
                        <>
                            <p className={"paragraph"} data-cy={"confirm-modal-paragraph"}>
                                {props?.body ? t(props.body) : t("Weet je het zeker?")}

                                {props?.subject && !props?.body ? ":" : ""}

                            </p>
                            <strong>{props?.subject}</strong>
                        </>
                    )}
                    {props?.children && props.children}
                </div>
                <div className="modal-footer">
                    <ul className={"modal__buttons"}>
                        {!props?.singular === true && (
                            <li className={"modal__button"}>
                                <ButtonSecondary
                                    onClick={() => closeModal()}>
                                    {props?.cancelText || t("Annuleren")}
                                </ButtonSecondary>
                            </li>
                        )}
                        <li className={"modal__button"}>
                            <ButtonPrimary
                                onClick={() => {
                                    if(props?.confirmModal) {
                                        props?.confirmModal();
                                    }
                                    closeModal();
                                }}
                                data-cy={"confirm-modal"}
                            >
                                {props?.confirmText || t("Bevestigen")}
                            </ButtonPrimary>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    )

}
