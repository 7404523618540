import useSWR from "swr";
import React from "react";

export default function FullNameFromUserIdComponent(props) {
    const {data: getUser} = useSWR(`/api/users/${props.userId}/`);

    if(!getUser) {
        return <span>Loading...</span>
    }

    return (
        <>
            {getUser?.full_name}
        </>
    )
}
