export function DateTimeFormat(date) {
    const formattedDate = new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    }).format(new Date(date));

    return formattedDate;
}

export function DateFormat(date) {
    const formattedDate =  new Intl.DateTimeFormat("nl-NL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(new Date(date));

    return formattedDate
}


