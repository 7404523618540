import React, {useEffect, useState} from "react";
import RankingDisplay from "../patterns/organisms/Ranking";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {remoteAddReference, remoteRemoveReference} from "../actions/scoring";
import {setFilter, setOption} from "../actions/overview";
import {ClientProjectRankingFunctions} from "./Overviews/ClientProjectRankingFunctions";
import {getSelectedReferenceFunctions} from "../queries/function_detail";
import useSWR from "swr";
import {getProject} from "../queries/project_detail";
import axios from "axios";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import SelectedFunctionsDisplay from "../patterns/organisms/SelectedFunctions";
import {isFuwaterVbalans} from "../utils/constants";
import {useAuth} from "../utils/useAuth";


export const RankingDefaultState = {
};

export default function Ranking(props) {

    let {client_id, project_id, function_id} = useParams();
    let [selectedGroup, setSelectedGroup] = useState(0);

    const project = getProject(project_id);
    const projectDetail = project?.data;
    const fgsDetail = useSWR(() => projectDetail?.function_group_structure?.id ? `api/functiongroupstructures/${projectDetail?.function_group_structure?.id}/` : false);

    const dispatch = useDispatch();

     const selectedRefIds = props.functionDetail?.reference_functions?.map((referenceFunction) => {
        return referenceFunction.id
    })

    const selectedReferenceFunctions = getSelectedReferenceFunctions(props.functionDetail.id, selectedRefIds)

    if(!props?.functionDetail) {
        return <LoaderAnimation/>
    }

    const setGroupFilter = (rawVal, single=false) => {
        let data = (rawVal !== false && rawVal !== "") ? {
            identifier: 'function_group__in',
            value: single ? parseInt(rawVal): 0,
        } : {
            identifier: 'function_group__in',
            value: single ? "" : 0,
        }; // when rawVal is false, we should display all (But only for matching, ie: single:false


        let val = parseInt(rawVal) || false;
        if(val && !single) {
            /// If we want to display group-1 < group < group + 1 for distance functins
            let min = val - 1 || 1;
            data = {
                identifier: 'function_group__in',
                value: min + ',' + (min+1) + ',' + (min+2),
            }
        }

        let stateIdentifier = {
            identifier: ClientProjectRankingFunctions.identifier,
            client_id,
            project_id,
            function_id,
            match: props.functionDetail?.type === 'match',
        };
        dispatch(setOption(stateIdentifier, {size: 1}));
        dispatch(setFilter(stateIdentifier, data));
        setSelectedGroup(val || "");
    }

    useEffect(() => {
        const group = props?.functionDetail?.function_group || props?.functionDetail?.intermediate_function_group || selectedGroup;
        if(group!== null) {
            if(group > 0) {
                setGroupFilter(group, props?.functionDetail?.type === 'match')
            } else {
                if(props?.functionDetail?.type === 'match') {
                    setGroupFilter(false, true); // force group 1 for match
                }
            }
        }
    }, [props?.functionDetail?.function_group, props?.functionDetail?.intermediate_function_group, props?.functionDetail?.type]);

    let overviewDefinition = {
        ...ClientProjectRankingFunctions
    };

    const auth = useAuth();
    const canViewCompanyFunctionPoints = auth.hasPermission("base.view_company_function_points");
    const canViewReferenceFunctionPoints = auth.hasPermission("base.view_reference_function_points");

    if(isFuwaterVbalans(projectDetail?.method?.id)) {
        overviewDefinition.columns = overviewDefinition.columns.filter(col => col?.identifier !== 'mnt');
    } else {
        overviewDefinition.columns = overviewDefinition.columns.filter(col => col?.identifier !== 'vbalans');
    }

    if(!canViewCompanyFunctionPoints || !canViewReferenceFunctionPoints || props.functionDetail?.type === "match") {
        overviewDefinition.columns = overviewDefinition.columns.filter(col => col?.identifier !== 'distance-points');
        overviewDefinition.columns = overviewDefinition.columns.filter(col => col?.identifier !== 'difference');
    }

    if(props?.functionDetail?.type === 'match') {
        overviewDefinition.columns = overviewDefinition.columns.filter(col => col?.identifier !== 'distance');
        overviewDefinition.defaultOrderIdentifier = 'points';
    }

    return (
        <RankingDisplay
            edit={props?.edit}
            fgsDetail={fgsDetail?.data}
            functionDetail={props?.functionDetail}
            client_id={client_id}
            project_id={project_id}
            function_id={function_id}
            removeFunction={props.removeFunction}
            selectFunction={props.selectFunction}
            setGroupFilter={setGroupFilter}
            selectedGroup={selectedGroup}
            setFunctionGroup={props.setFunctionGroup}
            overviewDefinition={overviewDefinition}
            selectedReferenceFunctions={selectedReferenceFunctions?.data?.results}
        />
    )
}
