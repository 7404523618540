import React from "react";
import cx from "classnames";
import {useTranslation} from "react-i18next";

export function StatusTag(props) {

    const {t, i18n} = useTranslation();

    const customType = !!props?.type ? "status-tag--" + props?.type : "";

    return (
        <span className={cx("meta-info__status-tag status-tag", customType,
            {
                "status-tag--neutral": ['concept', undefined].includes(props?.status) && !props?.type,
                "status-tag--active": ['determined'].includes(props?.status),
                "status-tag--negative": ['expired', 'archived'].includes(props?.status)
        })}>{t(props?.title || props?.status)}</span>
    )
}
