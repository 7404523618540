import React, {useState} from "react";
import {usePopper} from "react-popper";
import cx from "classnames";
import ReactDOM from "react-dom";


const ToolTip = props => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);

    const [visible, setVisible] = useState(false);

    const Component = props.component;

    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: "bottom",
         modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            { name: 'offset', options: {
                    offset: [0, 0]

                }
            }
        ],
    });

    return (
    <>
        <div
            ref={setReferenceElement}
            className={cx("tooltip-container", {"tooltip-container--full-height": props.fullHeight}, props.classNames && props.classNames)}
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
        >
            {props.children}
            {visible &&
                ReactDOM.createPortal(
                    <div className={"tooltip-element"}
                         ref={setPopperElement}
                         style={styles.popper}
                         {...attributes.popper} >
                        {props.tooltip && <>{props.tooltip}</>}
                        {Component && <Component />}
                        <div ref={setArrowElement} style={styles.arrow} />
                    </div> ,
                    document.querySelector('#root')
                )
            }
        </div>
    </>
    )
}

export default ToolTip;
