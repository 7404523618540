import axios from "axios";
import useSWR, {useSWRInfinite} from "swr";
import {buildOrder, buildFilter, loadIfAllowed} from "../utils/constants";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {setHighlight} from "../actions/scoring";

export function getUserGroups(stateToDebounce) {
    const identifiers = ['project-usergroups', 'user-projectgroups', 'client-usergroups',
        'select-usergroup-overview', 'select-usergroup-overview-global'];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id: parseInt(fetchState?.identKey?.client_id)
            } : {},
            fetchState?.identKey?.user_id ? {
                user__id: fetchState?.identKey?.user_id
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState.identifier) ? `/api/usergroups/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index+1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getProjectUserGroups(stateToDebounce) {
    const identifiers = ['project-usergroups', 'user-projectgroups', 'client-usergroups'];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id: parseInt(fetchState?.identKey?.client_id)
            } : {},
            fetchState?.identKey?.user_id ? {
                user__id: fetchState?.identKey?.user_id
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState.identifier) ? `/api/projects/${fetchState.identKey.project_id}/usergroups/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index+1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getUserGroup(id) {

    let url = `/api/usergroups/${id}/`;

    return axios.get(url, {}).then( result => result).catch(error => console.log(error));
}

export async function remoteSetUserGroup(client_id, name, description) {
    return await axios.post('/api/usergroups/', {
        client_id,
        name,
        description,
    }).then(result => result);
}

export async function remoteRemoveUserGroup(pk) {
    return await axios.delete(`/api/usergroups/${pk}/`).then(result => result);
}

export async function remoteAddUsersToUserGroup(user_group_id, users) {
    return await axios.patch(`/api/usergroups/${user_group_id}/add_users/`, {
        users
    });
}

export async function remoteRemoveUsersFromUserGroup(user_group_id, users) {
    return await axios.patch(`/api/usergroups/${user_group_id}/remove_users/`, {
        users
    });
}
