export const ADD = 'ADD';

export function addPattern(cat, name, props, component) {
    return {
        type: ADD,
        cat,
        name,
        props,
    }
}


