import React from "react";
import {useAuth} from "../../utils/useAuth";
import {useTranslation} from "react-i18next";
import SimpleMetaDisplay from "../../patterns/organisms/SimpleMeta";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import {DescriptionEditor} from "../RichtextManager";
import {remoteSetFunctionDescriptionTemplateTitleContent} from "../../queries/function_description";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {TagEditor} from "../Tags";

export default function DefaultTextDetailScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();
    const {default_text_id} = useParams()

    const {data: defaultText} = useSWR(() => default_text_id ? `/api/functiondescriptiontemplates/${default_text_id}/` : false);

    if(!defaultText) {
        return <LoaderAnimation/>
    }

    return (
        <>
            <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
                <div className={"content-container"}>
                    <SimpleMetaDisplay
                        name={t("Standaard-tekst bewerken")}
                    />
                </div>
            </div>

            <div className={"content-container"}>
                <div className="description-editor">
                    <div className={"description-editor__item"}>
                        <div className="description-editor__text">
                            <DescriptionEditor
                                placeholder={t("Toelichting op dit aspect...")}
                                setLoaded={() => false}
                                save={(title, content) => remoteSetFunctionDescriptionTemplateTitleContent(defaultText.id, title, content)}
                                item={{
                                    title: defaultText.title,
                                    content: defaultText.content
                                }}
                            />
                            <div className={"description-editor__tags"}>
                                <TagEditor
                                    objectId={defaultText.id}
                                    objectType={defaultText.resourcetype}
                                    edit={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
