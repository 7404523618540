import {getBasedOnFunctions, getFunctions, getProjectGroupFunctions} from "../../queries/client_project_functions";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import {NameSourcetypeFilter} from "./Filters/NameSourcetypeFilter";
import {MNTTitle} from "./Titles/MNTTitle";
import {MNTFilter} from "./Filters/MNTFilter";
import {FunctionStatusFilter} from "./Filters/StatusFilter";
import {DateFilter} from "./Filters/DateFilter";
import {TextFilter} from "./Filters/TextFilter";
import {EmptyTitle} from "./Titles/EmptyTitle";
import {EmptyFilter} from "./Filters/EmptyFilter";
import React from "react";
import {prefetchFunction} from "../../queries/function_detail";
import cx from "classnames";
import OverviewName from "../../patterns/molecules/OverviewName";
import GroupPatternScore from "../../patterns/molecules/GroupPatternScore";
import {FunctionStatus} from "../../patterns/atoms/Pip";
import {InfoButton} from "../../patterns/molecules/InfoButton";
import {MNTDomains} from "../../utils/constants";
import {useTranslation} from "react-i18next";
import {ClientProjectFunctionOverviewDefinitionDefault, StandardRowStructure} from "./ClientProjectFunctionOverview";
import i18n from "../../utils/i18n";


// const StandardRowStructure = React.memo(tempProps => {
//
//     const {t, i18n} = useTranslation();
//     let props = Object.assign(
//         {
//             id: false,
//             type: 'unknown',
//             name: "",
//             pattern_list: [],
//             version_number: "",
//             project: {
//                 name: (<>&nbsp;</>)
//             },
//             function_group_label: "-",
//             points: "-",
//             displayPoints: props?.displayPoints,
//             comparison: props?.comparison,
//             modification_date: false,
//         },
//         tempProps,
//     );
//     // may be passed to OverviewName but this is the default in OverviewName  as well
//     // const lengths = {
//     //     small: 15,
//     //     medium: 20,
//     //     large: 25,
//     //     xlarge: 30,
//     //     full: 35,
//     // }
//     const detailClick = props?.onClick;
//
//     const kind = () => {
//         if(props.is_collective_labour_agreement) {
//             return "CAO";
//         }
//     }
//
//     const creation_date = props.creation_date ? new Intl.DateTimeFormat("nl-NL", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric"
//     }).format(new Date(props.creation_date)) : false;
//     const modification_date = props.modification_date ? new Intl.DateTimeFormat("nl-NL", {
//         day: "2-digit",
//         month: "2-digit",
//         year: "numeric"
//     }).format(new Date(props.modification_date)) : false;
//
//     return (
//         <tr
//             onClick={() => {            // the onclick is passed along through the Drawer component
//                 detailClick(props);
//             }}
//             key={props.id}
//             className={"overview-table__row"}
//         >
//             <td className={"overview-table__cell"}
//             >
//                 <span
//                     className={cx(
//                         'function-type-indicator',
//                         {'function-type-indicator--company': props.type === 'company'},
//                         {'function-type-indicator--reference': props.type === 'reference'}
//                     )}
//                     title={props.type === 'company' ? 'Bedrijfseigenfunctie': 'Referentiefunctie'}
//                 >
//                     {props.type === 'company' ? 'B': (props.type === 'reference') ? 'R': '-'}
//                 </span>
//             </td>
//             <td className={"overview-table__cell overview-table__cell--name"}
//             >
//                 <OverviewName
//                     {...props}
//                     kind={kind()}
//                 />
//             </td>
//             <td className={"overview-table__cell"}
//             >
//                 <GroupPatternScore {...props} comparison={false} displayPoints={false}/>
//             </td>
//             <td className={"overview-table__cell"}
//             >
//                 <div className={"overview-table__status"}>
//                     <FunctionStatus description_status={props.description_status} scoring_status={props.scoring_status}/>
//                 </div>
//             </td>
//             <td className={"overview-table__cell"}
//             >
//                 {props.modification_date  ? (
//
//                     <div className={"overview-table__text"}>
//                         <time dateTime={props.modification_date}>
//                             {new Intl.DateTimeFormat("nl-NL", {
//                                 day: "2-digit",
//                                 month: "2-digit",
//                                 year: "numeric"
//                             }).format(new Date(props.modification_date))}
//                         </time>
//                     </div>
//                 ) : (
//                     <div className={"overview-table__skeleton"}/>
//                 )}
//             </td>
//             <td className={"overview-table__cell"}
//             >
//                 <div className={"overview-table__text"}>
//                     {props.department}
//                 </div>
//             </td>
//             <td className={"overview-table__cell"}
//             >
//                 <div className={"overview-table__text"}>
//                     {props.sub_department}
//                 </div>
//             </td>
//             <td className={"overview-table__cell"}>
//                 <InfoButton
//                     icon={"info"}
//                     sizeModifier={"medium"}
//                     prefix_identifier={"client-project-overview"}
//                     id={props.id}
//                     title={"Meer informatie"}
//                 >
//                     <div className={"popover__content"}>
//                         <div className={"popover__title"} title={props.name}> {props.name} </div>
//                     </div>
//                     <div className={"popover__content"}>
//                         <dl className={"popover-info popover-info--duo"}>
//                             <div className={"popover-info__column"}>
//                                 <dt className={"popover-info__key"}>{t("Versie")}</dt>
//                                 <dd className={"popover-info__value"}>{props.version_number || "-"}</dd>
//
//                                 <dt className={"popover-info__key"}>{t("Aanmaakdatum")}</dt>
//                                 <dd className={"popover-info__value"}>{creation_date || "-"}</dd>
//
//                                 <dt className={"popover-info__key"}>{t("Project")}</dt>
//                                 <dd className={"popover-info__value"}>{props?.project?.name || "-"} </dd>
//
//                             </div>
//                             <div className={"popover-info__column"}>
//                                 <dt className={"popover-info__key"}>{t("Functiecode")}</dt>
//                                 <dd className={"popover-info__value"}>{props.code || "-"} </dd>
//
//                                 <dt className={"popover-info__key"}>{t("Wijzigingsdatum")}</dt>
//                                 <dd className={"popover-info__value"}>{modification_date || "-"} </dd>
//
//                                 <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
//                                 <dd className={"popover-info__value"}>{props?.client?.name || "-"}</dd>
//                             </div>
//                         </dl>
//                     </div>
//                     <div className={"popover__content"}>
//                         <dl className={"popover-info popover-info--duo"}>
//                             <div className={"popover-info__column"}>
//                                 <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
//                                 <dd className={"popover-info__value"}>{props?.department || "-"}</dd>
//
//                             </div>
//                             <div className={"popover-info__column"}>
//
//                                 <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
//                                 <dd className={"popover-info__value"}>{props?.sub_department || "-"}</dd>
//                             </div>
//                         </dl>
//                     </div>
//
//                     <div className={"popover__content"}>
//                         <dl className={"popover-info popover-info--duo"}>
//                             <div className={"popover-info__column"}>
//                                 <dt className={"popover-info__key"}>{t("Beschrijver")}</dt>
//                                 <dd className={"popover-info__value"}>
//                                     {props?.describer?.first_name} {props?.describer?.last_name || "-"}</dd>
//
//                                 <dt className={"popover-info__key"}>{t("Toepasser")}</dt>
//                                 <dd className={"popover-info__value"}>
//                                     {props?.applier_one?.first_name} {props?.applier_one?.last_name || "-"}</dd>
//
//                                 <dt className={"popover-info__key"}>{t("Toetser")}</dt>
//                                 <dd className={"popover-info__value"}>
//                                     {props?.applier_two?.first_name} {props?.applier_two?.last_name || "-"}</dd>
//                             </div>
//                         </dl>
//                     </div>
//                 </InfoButton>
//             </td>
//         </tr>
//     )
//
// });

export const FunctionSelectionDefinition = {

    title: i18n.t("Functieoverzicht"),
    identifier: 'function-overview',
    queryFn: getBasedOnFunctions,
    RowStructure: StandardRowStructure,
    noHeader: true,
    noSearch: true,
    noContainer: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: i18n.t('Type'),
            identifier: 'type__in',
            type: 'dropdown',
            className: 'overview-table__filter-cell--width-type',
            orderable: true,
            Filter: DropdownFilter,
            options: [
                {
                    title: i18n.t('Referentie'),
                    identifier: 'reference'
                },
                {
                    title: i18n.t('Bedrijfseigen'),
                    identifier: 'company',
                },
            ],
        },
        {
            title: i18n.t("Naam"),
            identifier: 'name',
            type: "name-kind",
            orderable: true,
            Filter: NameSourcetypeFilter,
            FilterOptions: [
                {
                    title: i18n.t('CAO'),
                    identifier: 'true'
                },
                {
                    title: i18n.t('Niet CAO'),
                    identifier: 'false'
                },
            ]
        },
        {
            type: 'mnt',
            identifier: 'mnt',
            className: 'overview-table__filter-cell--width-pattern-widget',
            Title: MNTTitle,
            TitleOptions: { domains: MNTDomains},
            Filter: MNTFilter,
            FilterOptions: { domains: MNTDomains},
        },
        {
            title: i18n.t('Stat.'),
            identifier: 'description_status__in',
            type: 'status-filter',
            orderable: false,
            className: 'overview-table__filter-cell--width-status',
            Filter: FunctionStatusFilter,
        },
        {
            title: i18n.t('Bijgewerkt'),
            identifier: 'modification_date',
            type: 'date-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-date',
            Filter: DateFilter
        },
        {
            title: i18n.t('Hoofdafdeling'),
            identifier: 'department',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter
        },
        {
            title: i18n.t('Subafdeling'),
            identifier: 'sub_department',
            type: 'text-filter',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter
        },
        {
            identifier: 'info',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],
}

export const FunctionSelectionDefinitionAll = {
    ...FunctionSelectionDefinition,
    identifier: "function-overview-all",
    queryFn: getBasedOnFunctions,
    columns: ClientProjectFunctionOverviewDefinitionDefault.columns.filter(col => !['department', 'sub_department'].includes(col.identifier)),
}
