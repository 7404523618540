import React, {useEffect, useState as useStateReact, useRef} from "react";
import {useDispatch} from "react-redux";
import {setPopOverActive as setPopOverActiveRedux} from "../actions/popover";

// helper function which is (semi) transparent and follows a useState like structure
// it tries to find the HoC. If it can't it will fall back on its own state (react's native useState)
export function useState(props, full_identifier, initialState) {
    // return (props && 'popOverActive' in props) ? [props?.popOverActive(full_identifier) || false, props.setPopOverActive ] : useStateReact(initialState);
    return props?.popOverActive ? [props?.popOverActive(full_identifier) || false, props.setPopOverActive ] : useStateReact(initialState);
}

// Higher order Component which handles outside click management for all popovers
export function singlePopOver(Wrapped) {

    return function(props) {

        // local state for managing if it is open or closed
        const [ popOverActive, setPopOverActive ] = useStateReact(false);

        // create a ref which stores the html ref, so we can find html elements
        const ref = useRef(false);

        // use the redux dispatcher so we can keep track in the state which popover is shown
        const dispatch = useDispatch();

        // global click listener
        const handleClickOutside = (e) => {
            // find out if our 'current ref' contains the target element
            if(!ref.current.contains(e.target)) {
                // disable the overlay if it is NOT
                setPopOverActive(false);
                // also notify redux that we closed the popover
                dispatch(setPopOverActiveRedux(false))


                // Items below can be re-enabled if click-outside should not do the clicked upon action immediately
                e.preventDefault();
                e.stopPropagation();
            }
        }

        // trigger the effect, only when popOverActive === true (see deps). This makes sure there aren't
        // many handlers active
        useEffect(() => {
            if(popOverActive) {
                // capture:true reverse the bubbling/propagation of an event, so we can first handle a global
                // click-event instead of bubbling down which will capture other component clicks first
                document.addEventListener('click', handleClickOutside, {capture: true});
            } else {
                document.removeEventListener('click', handleClickOutside, {capture: true})
            }
            // this returns the destructor, when the component unmounts the eventlistener is removed
            return () => {
                document.removeEventListener('click', handleClickOutside, {capture: true})
            }
        }, [popOverActive]);

        // pass our props to the actual component
        const newProps = {
            ...props,
            popOverActive: (full_identifier) => {
                return popOverActive;
            },
            setPopOverActive: (state, identifier) => {
                setPopOverActive(state)
                dispatch(setPopOverActiveRedux(identifier)); //we dispatch to Redux only for 'external' use, the popover itself is NOT redux driven!
            },
        }

        return (
            <div ref={ref}>
                <Wrapped {...newProps} />
            </div>
        )
    }
}



