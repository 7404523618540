import React  from 'react';
import Group from "../atoms/Group";
import Pattern from "../atoms/Pattern";
import Points from "../atoms/Points";

import { PatternPatternState } from "../atoms/Pattern";
import {comparePattern} from "../../utils/compare_function_pattern";
import cx from "classnames";
import {useParams} from "react-router-dom";
import useSWR from "swr";
import axios from "axios";
import {getProject} from "../../queries/project_detail";
import {useAuth} from "../../utils/useAuth";
import {useTranslation} from "react-i18next";

export const GroupPatternScorePatternState = Object.assign({
    group: 2,
    points: 120,
}, PatternPatternState);

export default function GroupPatternScore(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();

    // if we have info regarding project, use it to fetch info (might be cached so it won't be very expensive)
    let { project_id } = useParams();
    const projectDetail = getProject(project_id)

    const canViewFGS = auth.hasPermission('base.view_functiongroupstructure', projectDetail.id, 'project');
    const canViewPoints = (props.type === "reference" && auth.hasPermission('base.view_reference_function_points', parseInt(project_id), 'project')) || (props.type==="company" && auth.hasPermission('base.view_company_function_points', parseInt(project_id), 'project'));

    const fgsGet = useSWR(() => canViewFGS && projectDetail?.data?.function_group_structure?.id ? `api/functiongroupstructures/${projectDetail?.data?.function_group_structure.id}/` : false);
    const function_group = props.function_group || props.intermediate_function_group;

    // if we have the info, use it, else use several fallbacks for determining the FGS
    const fgsDetail = project_id ? fgsGet?.data : props?.fgsDetail || props?.contextData?.fgsDetail || false;

    // based on what we have we can de termine the group_label, if not it will be false (which will eventually fallback to props.function_group instead)
    const function_group_label = fgsDetail?.groups?.find(group => group.number === function_group)?.label  || props?.function_group_label?.replaceAll(' ','') || false;

    if(!props?.pattern_list || !(props.pattern_list.length > 0) ) {
        return (
            <div className={cx("pattern-container pattern-skeleton", props.className)}>
                <div className={"pattern-group pattern-group--skeleton"}/>
                <div className={"pattern-pattern-with-points pattern-pattern-with-points--skeleton"}/>
                {canViewPoints ? <div className={"pattern-points pattern-points--skeleton"}/> : null }
            </div>
        )
    }

    const comparedPattern = comparePattern(props.pattern_list, props?.comparedPatternList);

    return (
        <div className={cx("pattern-container", props.className)}>
            <Group group={function_group_label || function_group}/>
            {props.type !== "match" ?
                <Pattern pattern_list={comparedPattern} comparison={props?.comparison}
                         displayPoints={props?.displayPoints}/>
                : (
                    <div className={"pattern-pattern pattern-pattern--placeholder pattern-pattern-with-points"}>
                        {t("n.v.t.")}
                    </div>
                )
            }
            {canViewPoints ? <Points {...props} /> : null }
        </div>
    )

}
