import React from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import Drawer from "../../components/Drawer";
import Overview from "../../components/Overview";
import {ClientSelectionDefinition} from "../../components/Overviews/ClientSelection";
import {withAccessRight} from "../../utils/withAccessHoC";
import Notification from "../molecules/Notification";
import {Controller, useForm} from "react-hook-form";
import {createUser} from "../../queries/user";
import {useNavigate, useParams} from "react-router-dom";
import {getClient} from "../../queries/client_detail";
import {Input} from "../atoms/Input";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const UserCreatePatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function UserCreateDisplay(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {client_id} = useParams();
    const client = getClient(client_id).data;
    const {
        register, handleSubmit, setError, trigger, control, clearErrors, formState: {errors}
    } = useForm({defaultValues: {client_id: client_id}, criteriaMode: "all"});

    const onSubmit = () => async (values) => {
        await createUser(values, navigate);
    }

    return (
        <>
            <h1 className={"form-container__title"}>{t("Gebruiker aanmaken")}</h1>
            <form onSubmit={(e) => {
                clearErrors("server");

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error.cause.message === "server-error-user-create" || error.cause.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <div className={"input-group"}>
                        <Controller name={"client_id"} control={control}
                                    rules={{required: t("Dit is een verplicht veld")}}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer Klant")}
                                            buttonText={t("Zoek klant")}
                                            label={t("Klant")}
                                            initialReadOnlyVal={client?.name}
                                            readOnlyVal={obj => obj.name}
                                            placeholder={t("Geen klant gekozen")}
                                            disabled={false}
                                            isForm={true}
                                            id={"client-drawer"}
                                            error={errors.client_id}
                                            data-cy={"client-id"}

                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-client-overview"}
                                                    identKey={{
                                                        client_id: client_id,
                                                        project_id: props.project_id
                                                    }}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ClientSelectionDefinition}
                                                />
                                            }
                                        />)}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Algemene gegevens")}</legend>

                    <div className="input-group">
                        <Input
                            name={"first_name"}
                            type={"text"}
                            id={"first_name"}
                            label={t("Voornaam")}
                            data-cy={"first-name-input"}
                            register={register}
                            error={errors.first_name}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"insertion"}
                            type={"text"}
                            id={"insertion"}
                            label={t("Tussenvoegsel")}
                            data-cy={"insertion-input"}
                            register={register}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"last_name"}
                            type={"text"}
                            id={"last_name"}
                            label={t("Achternaam")}
                            data-cy={"last-name-input"}
                            register={register}
                            error={errors.last_name}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"email"}
                            type={"text"}
                            id={"email"}
                            label={t("Emailadres")}
                            data-cy={"email-input"}
                            register={register}
                            error={errors.email}
                            required
                            trigger={() => trigger("email")}
                            pattern={{
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Dit is geen geldig email adres"
                            }}
                        />
                    </div>

                    {errors.server && <Notification className={"form-container__error"} type={"error"}
                                                    text={errors.server.message}/>}
                    {Object.values(errors).find((error) => error.type === "pattern") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"email-pattern-errors-summary"}
                                      text={t("Het ingevulde e-mailadres voldoet niet aan de eisen.")}/>
                    }
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Aanmaken")}
                        fullWidth={true}
                        data-cy={"create-user-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const UserCreate = withAccessRight(
    UserCreateDisplay,
    'base.add_authuser',
    'client_id',
    'client'
);
