import React, {useState} from "react";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {useTranslation} from "react-i18next";
import IconButton from "../molecules/IconButton";
import Modal from "react-modal";
import {deleteMessage, getMessages, remoteSetMessage} from "../../queries/comments";
import {useParams} from "react-router-dom";
import UserName, {UserBadge} from "../../components/User";
import {DefinitionHeader} from "./ScoringItem";
import {mutate} from 'swr';
import ConfirmModal from "./ConfirmModal";
import i18n from "../../utils/i18n";
import ToolTip from "../../components/ToolTip";

function CommentDisplay(props) {

    const [modal, setModal] = useState(false);
    const {t, i18n} = useTranslation();

    return (
        <li className={"comment"}>
            <div className={"comment__header"}>
                <UserBadge
                    className={"comment__user-badge"}
                    userId={props.comment.user_id}
                />
                <span className={"comment__author"}><UserName userId={props.comment.user_id}/></span>
            </div>
            <IconButton icon={"trash"}
                        className={"comment__delete"}
                        onClick={() => {
                            setModal(true);
                        }}
            />
            <div>
                <time className={"comment__date-time"} dateTime={props.comment.creation_date}>
                    {new Intl.DateTimeFormat("nl-NL", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: false,
                    }).format(new Date(props.comment.creation_date))}
                </time>
                <div className={"comment__body"}>
                    {props.comment.message.split("\n").filter(obj => obj !== "").map((p, idx) => {
                        return (
                            <p key={idx} className={"paragraph"}>
                                {p}
                            </p>
                        )
                    })}
                </div>
            </div>
            {modal &&
                <ConfirmModal
                    title={i18n.t("Verwijder commentaar")}
                    body={i18n.t("Weet je zeker dat je dit commentaar wilt verwijderen?")}
                    closeModal={() => setModal(false)}
                    confirmModal={async () => {
                        await deleteMessage(props.comment.id)
                        props.mutate();
                    }}
                />}
        </li>
    )
}

const Comment = React.memo(CommentDisplay);

function CommentsModal(props) {

    let {function_id} = useParams();
    const is_public = props?.is_public || false;
    const {t, i18n} = useTranslation();
    const comments = getMessages('ScoringStructureMessage', is_public, parseInt(function_id), parseInt(props.identifier));
    const [comment, setComment] = useState("");
    const submitComment = async () => {
        setComment("");
        await remoteSetMessage(comment, 'ScoringStructureMessage', is_public, parseInt(function_id), parseInt(props.identifier))
        comments.mutate();
    }

    return (
        <Modal isOpen={props.commentActive} className={"modal modal--full-screen"} overlayClassName={"modal-background"}
               bodyOpenClassName={"has-modal"}>
            {props.children}

            <div className={"comments-header"}>
                {props.translation.title}
            </div>

            {comments?.data?.results.length > 0

                ? <ol className={"comments-list"}>
                    {comments?.data?.results.map((comment, idx) => {
                        return (
                            <Comment
                                key={idx}
                                comment={comment}
                                mutate={comments.mutate}
                            />
                        )
                    })}
                </ol>

                : <div className={"comments__empty"}>
                    {props.translation.empty}
                </div>
            }

            <div className={"comments-form"}>
                <textarea
                    className={"comments-form__input"}
                    placeholder={props.translation.placeholder}
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}/>
                <ButtonPrimary
                    disabled={comment === ""}
                    text={t("Plaats bericht")}
                    fullWidth={false}
                    onClick={submitComment}/>
            </div>
        </Modal>
    )

}

export default function Comments(props) {

    const {t, i18n} = useTranslation();
    const type = ["comment", "note"].includes(props?.type) ? props.type : "comment"; //default to comment
    const is_public = type === "comment";
    const translation = {
        "comment": {
            title: t("Opmerking"),
            empty: t("Er is nog geen commentaar geplaatst"),
            placeholder: t("Jouw commentaar...")
        },
        "note": {
            title: t("Persoonlijke notitie"),
            empty: t("Je hebt nog geen persoonlijke notitie geplaatst"),
            placeholder: t("Jouw persoonlijke notitie...")
        }
    }

    let {function_id} = useParams();

    const [commentActive, setCommentActive] = useState(false);
    const comments = props?.comments || [];
    const has_comments = comments.length > 0;  //comments?.data?.count > 0;

    return (
        <>
            <ToolTip tooltip={translation[type].title}>
                <IconButton icon={has_comments ? type : type + "-empty"} active={has_comments ? "true" : "false"}
                            sizeModifier={"small"}
                            title={translation[type].title}
                            className={has_comments ? "--force-display" : ""}
                            onClick={() => setCommentActive(!commentActive)}
                />
            </ToolTip>
            {commentActive && (
                <CommentsModal {...props}
                               is_public={is_public}
                               commentActive={commentActive}
                               setCommentActive={setCommentActive}
                               translation={translation[type]}>
                    <DefinitionHeader
                        setActive={(state) => {
                            setCommentActive(state);
                            // invalidate function as the comments have updated
                            mutate('/api/functions/' + parseInt(function_id) + '/');
                        }}
                        numberPrefix={props.numberPrefix}
                        scoringItem={props.scoringItem}
                    />
                </CommentsModal>
            )}
        </>
    )
}


