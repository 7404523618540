import React from 'react';
import cx from "classnames";
import Icon from "./Icon";
import i18n from "../../utils/i18n";

export const ButtonPrimaryPatternState = {
    // default (no modifier) which is passed as props to the React Component
    text: i18n.t('Default Action'),
    fullWidth: false,
    disabled: false,

    plMod: {
        // :fullWidth modifier
        fullwidth: {
            fullWidth: true
        },

        // :disabled modifier
        disabled: {
            disabled: true
        }
    }

}

export default function ButtonPrimary(props) {

    return (
        <button
            onClick={props.onClick}
            className={cx('button', 'button--primary', {'button--full-width': props.fullWidth}, props.className)}
            disabled={props.disabled}
            data-cy={props["data-cy"]}
            type={props.type}>
            {props.hasOwnProperty('icon') && <Icon icon={props.icon} sizeModifier={"small"} className={"button__icon"}/>}
            {props.text}{props.children}
        </button>
    )
}
