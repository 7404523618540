import React from "react";
import {Upload} from "../Upload";
import {getClient} from "../../queries/client_detail";
import {useParams} from "react-router-dom";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {withAccessRight} from "../../utils/withAccessHoC";


export function ClientFileUploadBase(props) {

    const { client_id } = useParams();
    const {data: clientDetail, mutate} = getClient(client_id);

    if(!clientDetail) {
        return <LoaderAnimation/>
    }

    return (
        <div className={"content-container"}>
            <div className={"file-box"}>
                <Upload
                    edit={true}
                    object_id={clientDetail.id}
                    files={clientDetail?.client_files}
                    resourcetype={"ClientFile"}
                    type={"attachment"}
                    mutate={() => mutate() }
                />
            </div>
        </div>
    )
}

export default withAccessRight(
    ClientFileUploadBase,
    "base.change_clientfile",
    [
        ["client_id", "client"]
    ]
)

