import React from "react";
import {DropdownFilter} from "../../../patterns/molecules/DropdownFilter";

export function EnvironmentStatusFilter(props) {
    return (
        <DropdownFilter
            {...props}
            identifier={'status__in'}
        />
    )
}
