import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import {useProjectInsight} from "../../components/ProjectInsight";
import {withHook} from "../../utils/withHookHoC";
import {withAccessRight} from "../../utils/withAccessHoC";
import Notification from "../molecules/Notification";
import Noty from "noty";
import {useForm} from "react-hook-form";
import {CheckboxInput, Input} from "../atoms/Input";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const ProjectInsightPatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function ProjectInsightDisplay(props) {
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        setError,
        setValue,
        clearErrors,
        getValues,
        formState: {errors}
    } = useForm();

    useEffect(() => {
        if (props.projectInsight) {
            setValue("email", props.projectInsight.email);
            setValue("password", props.projectInsight.password ? props.projectInsight.password : "");
            setValue("can_view_company_functions", props.projectInsight.can_view_company_functions && props.projectInsight.can_view_company_functions || false);
            setValue("can_view_match_functions", props.projectInsight.can_view_match_functions && props.projectInsight.can_view_match_functions || false);
        }
    }, [props.projectInsight])

    return (
        <>
            <h1 className={"form-container__title"}>{t("Inzageaccount instellingen")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server", "unique"]);

                handleSubmit(props.onSubmit)(e).catch((error) => {
                    if (error.cause?.message === "server-error-project-insight" || error.cause?.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }

                    if (error.cause?.message === "unique-error-project-insight") {
                        setError("unique", {
                            type: "unique",
                            message: t("Er is iets verkeerd gegaan. Mogelijk bestaat er al een inzageaccount met dit emailadres!")
                        })
                    }
                })
            }}>
                <section className={"form-meta"}>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Project")}</span>
                        <span className={"form-meta__description"}
                              data-cy={"project-name"}>{props?.project?.name}</span>
                    </div>
                    <div className={"form-meta__item"}>
                        <span className={"form-meta__label"}>{t("Klant")}</span>
                        <span className={"form-meta__description"} data-cy={"client-name"}>{props.client?.name}</span>
                    </div>
                </section>
                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>{t("Inzageaccount-instellingen")}</legend>

                    <div className="input-group">
                        <Input
                            name={"email"}
                            type={"text"}
                            id={"email"}
                            label={t("Emailadres")}
                            placeholder={t("Emailadres")}
                            helpTitle={t("Emailadres voor inzage account")}
                            helpText={t("Om een inzage-account aan te maken is een emailadres nodig dat " +
                                "gebruikt wordt om in te loggen. Dit is bij voorkeur een bestaand emailadres, " +
                                "maar dat is niet noodzakelijk. Wanneer dit veld leeggemaakt wordt zal het " +
                                "inzage account uitgeschakeld worden")}
                            register={register}
                            error={errors.email}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"password"}
                            type={'text'}
                            id={"password"}
                            placeholder={t("Wachtwoord")}
                            label={t("Wachtwoord")}
                            helpTitle={t("Wachtwoord voor inzage account")}
                            helpText={t("Geef een wachtwoord op voor het inzage account. " +
                                "Er zijn géén wachtwoordeisen voor het inzage account omdat dit account vaak " +
                                "wordt gedeeld met veel mensen. Wel is het belangrijk dat dit wachtwoord niet " +
                                "al te eenvoudig is. Indien het wachtwoord leeggelaten wordt zal er een " +
                                "wachtwoord worden gegenereerd.")}
                            copyTitle={t("Kopiëer wachtwoord naar het klembord")}
                            copyClick={() => {
                                let noty = new Noty({
                                    theme: 'bootstrap-v4',
                                    layout: 'bottomRight',
                                    text: t("Het wachtwoord is gekopieerd naar je klembord!"),
                                    closeWith: ['click', 'button'],
                                    type: "success",
                                    timeout: 2000,
                                }).show();
                            }}
                            register={register}
                            error={errors.password}
                            getValues={getValues}
                        />
                    </div>

                    <div className={"input-group"}>
                        <CheckboxInput name={"can_view_company_functions"}
                                       id={"can_view_company_functions"}
                                       label={t("Mag functies van het type 'bedrijfseigen' inzien")}
                                       register={register}
                        />
                    </div>

                    {props.allow_function_matching &&
                        <div className={"input-group"}>
                            <CheckboxInput name={"can_view_match_functions"}
                                           id={"can_view_match_functions"}
                                           label={t("Mag functies van het type 'inpassen' inzien")}
                                           register={register}
                            />
                        </div>
                    }

                    {errors.unique && <Notification type={"error"}
                                                    text={errors.unique.message}
                                                    data-cy={"error-unique"}
                                                    className={"form-container__error"}/>}
                    {errors.server && <Notification type={"error"}
                                                    text={errors.server.message}
                                                    className={"form-container__error"}/>}
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"project-insight-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const ProjectInsight = withAccessRight(
    withHook(
        ProjectInsightDisplay,
        useProjectInsight
    ),
    'base.change_project',
    [
        ['project_id', 'project'],
        ['client_id', 'client']

    ]
)
