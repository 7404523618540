import React from "react";
import cx from "classnames";
import Icon from "../../../patterns/atoms/Icon";
import {useTranslation} from "react-i18next";
import ToolTip from "../../ToolTip";
import DomainTooltip from "../../DomainTooltip";

export function MNTTitle(props) {
    const {t, i18n} = useTranslation();
    const ascIcon = (<Icon icon={"sort-ascending"} sizeModifier={"tiny"} className={"overview-table__sort-icon"}/>);
    const descIcon = (<Icon icon={"sort-descending"} sizeModifier={"tiny"} className={"overview-table__sort-icon"}/>);

    return (
        <div className={cx("overview-table__column-title")}>
            {!props.contextData?.noFunctionGroup &&
                <div className={cx("pattern-filter-title__group overview-table__column-title--orderable", {
                    "overview-table__column-title--active": "points" === props.ordering?.columnIdentifier
                })}
                     onClick={() => props.order('points')}>
                    {t("Gr.")}
                    {'points' === props.ordering?.columnIdentifier && (
                        props.ordering?.direction !== '-' ? ascIcon : descIcon
                    )}
                </div>
            }
            <div className={cx("pattern-filter-title__domain", {
                "pattern-filter-title__domain--vbalans": props?.filterOptions?.type === "vbalans",
                "pattern-filter-title__domain--wide": !props.contextData?.canViewPoints
            })}>
                {props?.domains.map((item,idx) => {
                    const DomainTooltipFun = () => <DomainTooltip domainIdx={idx} method_id={props.contextData?.method_id} />

                    return (
                        <ToolTip component={DomainTooltipFun} classNames={cx("pattern-filter-title__letter overview-table__column-title--orderable") } key={idx}>
                            <span
                                key={item.identifier} onClick={() => { if(props.order) {props.order(item.identifier); }}}
                            >
                                { item.identifier !== props.ordering?.columnIdentifier && item.short}
                                { item.identifier === props.ordering?.columnIdentifier && (
                                    props.ordering?.direction !== '-' ? ascIcon : descIcon
                                )}
                            </span>
                        </ToolTip>
                    )
                })}
            </div>
            {props.contextData?.canViewPoints ? (
                <div className={ cx("pattern-filter-title__points overview-table__column-title--orderable", {
                    "overview-table__column-title--active": "points" === props.ordering?.columnIdentifier
                })}
                     onClick={() =>  props.order('points')}
                >
                    {t("Punt.")}
                    { 'points' === props.ordering?.columnIdentifier && (
                        props.ordering?.direction !== '-' ? ascIcon : descIcon
                    )}
                </div>
            ) : null }
        </div>
    )
}

export function MNTTitleGlobal(props) {
    const ascIcon = (<Icon icon={"sort-ascending"} sizeModifier={"tiny"} className={"overview-table__sort-icon"}/>);
    const descIcon = (<Icon icon={"sort-descending"} sizeModifier={"tiny"} className={"overview-table__sort-icon"}/>);

    return (
        <div className={cx("overview-table__column-title")}>
            <div className={ cx("pattern-filter-title__group", {
                "overview-table__column-title--active": "points" === props.ordering?.columnIdentifier
            }) }
                 onClick={() =>  props.order('points')} >
                {t("Gr.")}
                { 'points' === props.ordering?.columnIdentifier &&  (
                    props.ordering?.direction !== '-' ? ascIcon : descIcon
                )}
            </div>
            <div className={cx("pattern-filter-title__domain", {
                "pattern-filter-title__domain--vbalans": props?.filterOptions?.type === "vbalans"
            })}>
            </div>
            {props.contextData?.canViewPoints ? (
                <div className={ cx("pattern-filter-title__points", {
                    "overview-table__column-title--active": "points" === props.ordering?.columnIdentifier
                })}
                     onClick={() =>  props.order('points')}
                >
                    {t("Punt.")}
                    { 'points' === props.ordering?.columnIdentifier && (
                        props.ordering?.direction !== '-' ? ascIcon : descIcon
                    )}
                </div>
            ) : null}
        </div>
    )
}
