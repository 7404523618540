import axios from 'axios';

export const SET_DOMAIN = 'SET_DOMAIN';
export const SET_FUNCTION_ID = 'SET_FUNCTION_ID';
export const SET_SCORE = 'SET_SCORE';
export const SET_HIGHLIGHT = 'SET_HIGHLIGHT';
export const SET_SUBSET = 'SET_SUBSET'
export const SET_SEARCH_OPTION = 'SET_SEARCH_OPTION'
export const SET_FUNCTION_SCORES = 'SET_FUNCITON_SCORES';
export const SET_SCORE_LOADING = 'SET_SCORE_LOADING';


export function setScoreLoading(function_id, structure_id) {
    return {
        type: SET_SCORE_LOADING,
        function_id,
        structure_id,
    }

}

export function setFunctionScores(function_id, scores) {
    return {
        type: SET_FUNCTION_SCORES,
        function_id,
        scores,
    }
}

export function setFunctionId(function_id) {

    return {
        type: SET_FUNCTION_ID,
        function_id,
    }

}

export function setDomain(identifier) {

    return {
        type: SET_DOMAIN,
        identifier,
    }

}

export function setScore(function_id, structure_id, score_id ) {

    return {
        type: SET_SCORE,
        function_id,
        structure_id,
        score_id,
    }

}

export function setHighlight(function_id, scores) {
    return {
        type: SET_HIGHLIGHT,
        function_id,
        scores,
    }
}

export function setSubset(function_id, identifier, state, explicit=false, complete=null) {
    return {
        type: SET_SUBSET,
        function_id,
        identifier,
        state,
        explicit,
        complete,
    }
}

export function setSidebarSearchOption(searchOption) {

    return {
        type: SET_SEARCH_OPTION,
        searchOption,
    }


}

export function remoteRemoveScore(function_id, structure_id, score_id) {
    return axios.patch(`/api/functions/${function_id}/remove_score/`, {
        scoring_structure_score: score_id,
    }).then(result => {
        return result;
    });
}

export function remoteSetScore(function_id, structure_id, score_id) {
    return axios.patch(`/api/functions/${function_id}/score/`, {
        scoring_structure_score: score_id,
    }).then(result => {
        return result;
    });
}

export function remoteAddReference(function_id, reference_function_id) {
    return axios.patch(`/api/functions/${function_id}/add_reference/`, {
        function_id: reference_function_id
    }).then(result => {
        return result;
    }).catch(error => console.log(error));
}
export function remoteRemoveReference(function_id, reference_function_id) {
    return axios.patch(`/api/functions/${function_id}/remove_reference/`, {
        function_id: reference_function_id
    }).then(result => {
        return result;
    });
}

export function remoteSetGroup(function_id, function_group) {
    return axios.patch(`/api/functions/${function_id}/`, {
        function_group: function_group
    }).then(result => {
        return result;
    });

}


export function getScoresForHighlight(function_id) {

    let url = `/api/functions/${function_id}/score/`;

    return (dispatch) => {
        axios.get(url, {}).then( result => {
            dispatch(setHighlight(function_id, result.data));
        }).catch(error => console.log(error));
    }

}
