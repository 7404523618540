import React from 'react';
import Icon from "./Icon";
import {useTranslation} from "react-i18next";
import {InfoButton} from "../molecules/InfoButton";
import cx from "classnames";

// TODO This file is needed for the value on line 27, if I add this in the normal Select, the Select component will not work anymore with final form
export default function SelectTemp(props) {
    const {t, i18n} = useTranslation();

    return (
        <>
            {props.label &&
                <label className={"label"} htmlFor={props.id}>{props.label}</label>
            }
            <div className={cx("input-group__input",{
                    "input-group__input--error": (props.displayErrors && props.meta.error && props.meta.touched)
                })}
            >
                <div className={"select-container"}>
                    <select id={props.id} {...props.input}
                            disabled={props?.disabled}
                            onChange={(...args) => {
                                props.input.onChange(...args);
                                if(props?.onChange) {
                                    props.onChange(...args);
                                }
                            }}
                            value={props.value && props.value}
                    >
                        {props.placeholder && <option key={-1} value={""}>{props.placeholder}</option>}
                        {props?.options?.map((option, index) => {
                            return (
                                <option key={index} value={option.display_name ? (option.value) : (option.id) }>
                                    {t(option.display_name ? (option.display_name) : (option.name)) }
                                </option>
                            )
                        })}
                    </select>
                    {props.displayErrors && props.meta.error && props.meta.touched && (
                        <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}/>
                    )}
                    <Icon icon={"angle-down"} sizeModifier={"small"} className={"select-container__icon"}/>
                </div>
                {props?.helpText && (
                    <div className={"input-group__buttons"}>
                        <InfoButton
                            icon={"info"}
                            sizeModifier={"medium"}
                            title={props?.helpTitle}
                        >
                            <div className={"popover__content"}>
                                {props?.helpText}
                            </div>
                        </InfoButton>
                    </div>
                )}
            </div>
        </>
    )
}

export function SelectErrorTemp(props) {

    return (
        <Select {...props} displayErrors={true} />
    )
}
