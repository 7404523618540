import React, {useEffect, useState, useMemo} from "react";
import {TextFilter} from "./Filters/TextFilter";
import {EmptyFilter} from "./Filters/EmptyFilter";
import {generatePath, useLocation, useParams} from "react-router-dom";
import {getProjectGroups, remoteAddProjectGroup} from "../../queries/project_group";
import MultiFieldModal from "../../patterns/organisms/MultiFieldModal";
import i18n from "../../utils/i18n";


export const ProjectGroupOverviewDefinition = {

    title: i18n.t("Projectgroepen"),
    identifier: 'projectgroup-overview',
    loadAllAllowed: true,
    queryFn: getProjectGroups,
    RowStructure: function (tempProps) {
        let props = Object.assign(
            {},
            tempProps,
        );

        const modDate = props?.modification_date  ? new Intl.DateTimeFormat("nl-NL", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        }).format(new Date(props.modification_date)) : "-";

        return (
            <tr key={props.id} className={"overview-table__row"}>
                <td onClick={e =>  {
                    props.navigate(generatePath('/projectgroup/:project_group_id/', { project_group_id: props.id}));
                }}
                    className={"overview-table__cell overview-table__cell--name overview-table__cell--interactive"}>{ props.name ? props.name : ''}</td>
                <td className={"overview-table__cell"}>{ props.sharing_method }</td>
                {/*<td className={"overview-table__cell"}>{ modDate } </td>*/}
            </tr>
        )
    },
    primary: {
    },
    secondary: {
        // title: "Bulk-actie",
        // identifier: 'bulk-action',
        // icon: 'list-icon'
    },
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: i18n.t('Projectnaam'),
            orderable: true,
            identifier: 'name',
            className:'overview__name',
            placeholder: i18n.t("Naam"),
            filter: TextFilter,
        },
        {
            title: i18n.t('Type'),
            orderable: true,
            identifier: 'sharing_method',
            className:'overview-table__filter-cell--width-medium',
            placeholder: i18n.t(""),
            filter: EmptyFilter,
        },
        // {
        //     title: i18n.t('Bijgewerkt'),
        //     identifier: 'modification_date',
        //     type: 'date-filter',
        //     orderable: true,
        //     className: 'overview-table__filter-cell--width-name',
        //     Filter: DateFilter
        // },
    ],
}
