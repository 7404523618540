// import './wdyr'; // why did you update should be loaded first
import '@babel/polyfill'; // was required for "ReferenceError: regeneratorRuntime is not defined" which was triggered by async/await
import React, {Suspense} from 'react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Modal from 'react-modal';
import { render } from 'react-dom';
import axios from 'axios';
import { Provider } from 'react-redux';
import { Cookies, CookiesProvider } from "react-cookie";
import hcglogo from 'IMAGES/logo-humancapitalgroup.svg';
import Noty from "noty";

Sentry.init({
    dsn: PRODUCTION ? "https://141a1121a78d470994b4c756351bbd64@o25848.ingest.sentry.io/5355303" : false,
    release: APPLICATION_TAG,
    integrations: [
        new Integrations.BrowserTracing()
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === 'console') {
          return null
        }
        return breadcrumb
  },
});

// import * as serviceWorker from './serviceWorker';

import SVG from "./utils/SVG";
import LoaderAnimation from "./patterns/atoms/LoaderAnimation";
import {SWRConfig} from "swr";

import i18n from "./utils/i18n";

const cookies = new Cookies();

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

// Defaults for axios
axios.defaults.baseURL = '//';
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept-Language'] = 'nl';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.headers.common['Client-Tag'] = APPLICATION_TAG
axios.defaults.headers.common['Client-Hash'] = APPLICATION_HASH
axios.defaults.validateStatus = (status) => {
    if(status === 409) {
        if(parseInt(params.it || 0) <= 5) {
            window.location.href = '/?it=' + (parseInt(params.it) + 1 || 1) + '&forceReload=' + Date.now();
        }
        return false;
    }
    return true;
};

const authToken = cookies.get('token');
if(authToken) {
    axios.defaults.headers.common['Authorization'] = `Token ${authToken}`;
}

axios.interceptors.response.use(
  response => {

      if(response?.status === 500) {

          Sentry.captureMessage(new Error(response?.data?.error));

          if (PRODUCTION === false) {
              new Noty({
                  theme: 'bootstrap-v4',
                  layout: 'bottomRight',
                  text: `${response?.status}: ${response?.data?.error}`,
                  closeWith: ['click', 'button'],
                  type: "error",
                  timeout: 5000,
              }).show();
          }
      }

      if(response?.status === 403) {
          Sentry.captureMessage(response?.data?.detail);

          new Noty({
            theme: 'bootstrap-v4',
            layout: 'bottomRight',
            text: `${response?.status}: ${response?.data?.detail}`,
            closeWith: ['click', 'button'],
            type: "error",
            timeout: 5000,
          }).show();
      }

      return response
  },
  error => error
  );

import FUWA from "./components/FUWA";

// Create our ReduxStore with componentconfig as startingpoint
import configureStore from './store/configureStore';
import {BrowserRouter as Router} from "react-router-dom";


const rootElement = document.getElementById('root');

if(rootElement) {

    const store = configureStore({SiteInfo: site_info || {}});

    Modal.setAppElement('#root');

    if(params?.it === "6") {

        const timeSinceReload = (Date.now() - params.forceReload) / 1000;
        if(timeSinceReload > 10) {
            window.location.href = '/?it=5&forceReload=' + Date.now();
        }

        render(
            <div style={{marginTop: 96 + "px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 24 + "px"}}>
                <img src={hcglogo} alt={"Human Capital Group"} className={"login-container__logo"}/>
                <div className={"tool"}>
                    <div className={"tool-header"}>
                        <h1 className={"tool-header__title"}>Technische fout</h1>
                    </div>
                    <div className={"tool__content"}>
                        <p className={"paragraph"}>
                            Helaas kan FUWA Online niet gestart worden. Probeer het over enkele minuten nogmaals.
                        </p>
                        <p className={"paragraph"}>
                            Wanneer deze fout blijft optreden dan kunt u het volgende proberen:
                        </p>
                        <ul className={"default"}>
                            <li>Herstart uw browser</li>
                            <li>Herstart uw computer</li>
                        </ul>
                        <p className={"paragraph"}>
                            Wanneer het probleem niet is verholpen kunt u
                            contact opnemen met: <a href={"mailto:support@humancapitalgroup.nl"} className={"link"}>support@humancapitalgroup.nl</a><br/>
                        </p>
                        <p className={"paragraph"}>Vermeld hierbij de volgende informatie:</p>
                        <ul className={"default"}>
                            <li>Foutmelding: HTTP-409</li>
                            <li>Versie: {APPLICATION_TAG} ({APPLICATION_HASH})</li>
                        </ul>
                    </div>
                </div>
            </div>,
            document.getElementById('root')
        )

    }  else {

        render(
            <>
                <CookiesProvider>
                    <Provider store={store}>
                        <Suspense fallback={<LoaderAnimation fullHeight={true} />}>
                            <SVG src={"icons.svg"} style={{display: 'none'}} />
                            <Router>
                                <FUWA />
                            </Router>
                        </Suspense>
                    </Provider>
                </CookiesProvider>
            </>,
            document.getElementById('root')
        )

    }


}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
