import axios from "axios";
import useSWR from "swr";
import {optionsFetcher} from "../utils/constants";

export function getClient(pk=false) {

    let url = `/api/clients/${pk}/`;

    const {data, error, mutate} = useSWR(() => pk ? url: false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getClientOptions() {

    let url = `/api/clients/?options`;

    const {data, error, mutate} = useSWR(url, optionsFetcher);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getClients() {

    let url = `/api/clients/`;


    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export async function archiveClient(id) {
    return await axios.delete(`/api/clients/${id}/`);
}


