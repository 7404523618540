import React, {useState} from 'react';
import ButtonPrimary from "../atoms/ButtonPrimary";
import {Input} from "../atoms/Input";
import Notification from "../molecules/Notification";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {Link} from "react-router-dom";

export const LoginPatternState = {
    onSubmit: () => {
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}
export default function LoginDisplay(props) {
    const {register, handleSubmit, setError, clearErrors, formState: {errors}} = useForm();
    const {t} = useTranslation();

    const error_message_map = {
        auth_failed: t("Je bent (automatisch) uitgelogd")
    };

    const [isPasswordShown, setIsPasswordShown] = useState(false);

    return (
        <div className={"login-form"}>
            <form onSubmit={(e) => {
                clearErrors("submitFailed");

                handleSubmit(props.onSubmit)(e).catch((error) => {
                    if (error.cause.message === "server-error") {
                        setError("submitFailed", {
                            type: "submitFailed",
                            message: t("Gebruikersnaam en/of wachtwoord onjuist")
                        })
                    }
                })
            }}>
                <legend className={"login-form__legend text-center"}>{t("Inloggen")}</legend>

                <fieldset className={"login-form__fieldset"}>
                    <div className={"login-form__inputs"}>
                        {props.error_message &&
                            <div className={"input-group"}>
                                <Notification type={"warning"} text={t(error_message_map[props.error_message])}/>
                            </div>
                        }
                        <div className={"input-group"}>
                            <Input
                                name={"username"}
                                type={"text"}
                                id={"login-email"}
                                label={t("E-mailadres")}
                                data-cy={"login-email-input"}
                                register={register}
                                error={errors.username}
                                autoFocus={true}
                            />
                        </div>
                        <div className={"input-group"}>
                            <Input
                                name={"password"}
                                type={isPasswordShown ? "text" : "password"}
                                id={"login-password"}
                                label={t("Wachtwoord")}
                                data-cy={"login-password-input"}
                                register={register}
                                error={errors.password}
                            />
                        </div>

                        {errors.submitFailed && <Notification type={"error"} text={errors.submitFailed.message}
                                                              data-cy={"error-notification-incorrect-user-password"}/>}

                        <input className="checkbox checkbox--stripped" id="show-password" name="show-password"
                               type="checkbox" onClick={() => setIsPasswordShown(!isPasswordShown)}/>
                        <label htmlFor="show-password">{t("Wachtwoord tonen")}</label>
                    </div>


                    <ButtonPrimary className={"login-form__submit"} disabled={props.submitting} text={t("Inloggen")}
                                   fullWidth={true} data-cy={"login-submit-button"}/>
                    <div className={"login-form__forgot-password-container"}>
                        <a href={"/password-reset/"} className={"login-form__forgot-password"}
                           data-cy={"login-password-reset-link"}>{t("Wachtwoord vergeten?")}</a>
                        <div className={"login__license"}>{t("Heeft u nog geen licentie?")}
                            <Link to={"/license/create"} className={"login__license-cta"} data-cy={"license-request-create-button"}>{t("Vraag aan")}</Link>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}


