import React from 'react';
import IconButton from "../molecules/IconButton";
import {IconPopoverButton, InfoButton} from "../molecules/InfoButton";
import {singlePopOver, useState} from "../../components/PopOverHoC";
import {useAuth} from "../../utils/useAuth";
import {generatePath, Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SVG from "../../utils/SVG";
import ConfirmModal from "./ConfirmModal";
import useSWR from "swr";
import {Icon} from "../atoms/Icon";
import ToolTip from "../../components/ToolTip";
import cx from "classnames";
import Overview from "../../components/Overview";
import {ReportsOverviewDropdownDefinition} from "../../components/Overviews/ReportsOverview";

function Toggle(props) {

    return (
        <>
            <input type={"checkbox"} name={"theme"} value={"dark"} checked={props.checked} onChange={props.onChange}/>
        </>
    )
}

function ContextMenuUserDisplay(props) {

    const full_identifier = 'UserMain'

    const {t, i18n} = useTranslation();
    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [popOverActive, setPopOverActive] = useState(props, full_identifier, false);
    const auth = useAuth();
    const navigate = useNavigate();

    const {data: getClient} = useSWR(auth?.user?.client?.id ? `/api/clients/${auth.user.client.id}/` : false);
    const {data: getManager} = useSWR(getClient?.manager ? `/api/users/${getClient.manager}/` : false)

    const clickContent = <div className={"user-name"} data-cy={"user-badge"}>
        <span className={"user-name__name"}>{auth.user?.full_name}</span>
        <Icon className={"user-name__icon"} icon={"angle-down"} sizeModifier={"small"}/>
    </div>

    return (
        <>
            <IconPopoverButton
                clickContent={clickContent}
                popOverClass={"dropdown"}
            >
                <div className={"user-menu"}>
                    <div className={"user-menu__name"}>
                        {auth.user.full_name}<br/>
                    </div>
                    <div className={"user-menu__client"} data-cy={"user-menu-client"}
                         onClick={() => navigate(`/client/${auth.user?.client?.id}`)}>
                        {auth.user.client?.name}
                    </div>
                    <div className={"user-menu__email"} data-cy={"user-menu-email"}>
                        {auth.user.email}
                    </div>
                </div>
                <hr/>
                <ul>
                    <li className={"dropdown-field"}><a className={"dropdown-field__label"} href={"#"} onClick={e => {
                        e.preventDefault();
                        setPopOverActive(true);
                    }}>{t("Help")}</a></li>
                </ul>
                <hr/>
                <ul>
                    <li className={"dropdown-field"}><a className={"dropdown-field__label"}
                                                        onClick={() => auth.logout()}
                                                        data-cy={"logout-button"}>{t("Uitloggen")}</a></li>
                </ul>

                {/*<Toggle onChange={() => props.themeObj.setTheme(props.themeObj.theme==='dark'? 'light': 'dark')} checked={props.themeObj.theme === 'dark'}/>*/}
            </IconPopoverButton>
            {popOverActive && (
                <ConfirmModal
                    key={'default-text'}
                    title={t("Help")}
                    confirmText={t("Sluiten")}
                    singular={true}
                    closeModal={() => setPopOverActive(false)}>
                    <>
                        <p className={"paragraph"}>
                            Download handleiding voor: <br/>
                            {!auth.insightUser && (
                                <>
                                    <a href={"/static/Handleiding-FUWA-Online-2.0.pdf"} className={"link"}
                                       download>{t("HR-medewerkers")}</a><br/>
                                    <a href={"/static/Handleiding-FUWA-Online-2.0-Klantbeheerders.pdf"}
                                       className={"link"} download>{t("Klantbeheerders")}</a><br/>
                                </>
                            )}
                            <a href={"/static/Handleiding-FUWA-Online-2.0-Inzageversie.pdf"} className={"link"}
                               download>{t("Inzage gebruikers")}</a><br/>
                            <a href={"/static/Richtlijnenboekje-MNT-2021.pdf"} className={"link"}
                               download>{t("MNT Richtlijnen")}</a><br/>
                        </p>
                        <p className={"paragraph"}>
                            {t("Voor meer informatie kunt u contact opnemen met")}: <br/>
                            {!getManager && (
                                <>
                                    <br/>
                                    <a href={"mailto:support@humancapitalgroup.nl"}
                                       className={"link"}>support@humancapitalgroup.nl</a> {t("voor technische vragen")}<br/>
                                    <a href={"mailto:info@humancapitalgroup.nl"}
                                       className={"link"}>info@humancapitalgroup.nl</a> {t("voor algemene vragen")}<br/>
                                </>
                            )}
                        </p>
                        {getManager && (
                            <p className={"paragraph"}>
                                {t("Uw FUWA Online beheerder")}:
                                <br/>
                                <b>{getManager?.full_name}</b><br/>
                                <a href={"mailto:" + getManager?.email} className={"link"}>{getManager?.email}</a>
                            </p>
                        )}
                        <p className={"paragraph"}>
                            {t("of telefonisch via")}:<br/>
                            <a href={"tel:+31302193960"} className={"link"}>030 219 39 60</a>
                        </p>
                        <p className={"paragraph"}>
                            Versie: <br/>
                            {APPLICATION_TAG} ({APPLICATION_HASH})
                        </p>
                    </>
                </ConfirmModal>
            )}
        </>
    )
}

export const ContextMenuUser = ContextMenuUserDisplay;


export function DownloadsDisplay(props) {
    const {t, i18n} = useTranslation();

    const full_identifier = 'download-popover';

    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [popOverActive, setPopOverActive] = useState(props, full_identifier, false);
    const auth = useAuth();

    return (
        <>
            <IconButton icon={"download"} notification={props.buttonActiveState} onClick={() => {
                setPopOverActive(!popOverActive);
                if(props.setButtonActiveState) {
                    props.setButtonActiveState("false");
                }
            }}/>

            {popOverActive && (
                <div
                    className={cx("reports-dropdown popover popover--flip-right-item-left popover--active", {"dropdown--active": popOverActive})}
                >
                    <Overview
                        identifier={"reports-download"}
                        quickview={{
                            identifier: "reports-download",
                            name: t("Rapportages")
                        }}
                        definition={{
                            ...ReportsOverviewDropdownDefinition,
                            noSearch: true,
                            noOrder: true,
                            noFilter: true,
                            noPagination: true,
                            noSticky: true,
                        }}
                    />
                    {!auth.insightUser &&
                        <div className={"reports-dropdown__footer"}>
                            <Link className={"reports-dropdown__link"}
                                  to={generatePath("/reports", {})}
                            >
                                {t("Alle rapportages")}
                            </Link>
                        </div>
                    }
                </div>
            )}
        </>

    );

}
export const Downloads = singlePopOver(DownloadsDisplay);


export default function ApplicationHeader(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const themeObj = props.themeObj || {
        theme: 'light',
        setTheme: () => {
        },
        themes: []
    }

    const canAddClient = auth.hasPermission('base.add_client');
    const canAddProject = auth.hasPermission('base.add_project');
    const canAddFunction = auth.hasPermission('base.add_function');

    const {data: getClients} = useSWR('/api/clients/?page_size=1');
    const multiClient = getClients?.count > 1;

    const canViewProjectGroup = auth.hasPermission('base.change_functiongroup')

    const canCreateReports = auth.hasPermission('base.can_create_reports');

    const canViewInvisible = auth.hasPermission('base.view_function_invisible');
    const canViewLicenseOverview = auth.hasPermission('base.view_license_overview');

    const canChangeMethod = auth.hasPermission('base.change_scoringstructure');
    const canChangeDefault = auth.hasPermission('base.change_defaultfunctiondescriptiontemplate');

    const canAccessAdmin = auth.hasPermission('base.has_access_to_app_admin');

    const contextItems = [
        {
            identifier: 'client-add',
            link: (
                <Link to={generatePath('/client/create')} className={"dropdown-field__label"}
                      title={t("Klant aanmaken")}>
                    {t("Klant aanmaken")}
                </Link>
            ),
            allowed: () => canAddClient,
        },
        {
            identifier: 'project-add',
            link: (
                <Link to={generatePath('/project/selector')} className={"dropdown-field__label"}
                      title={t("Project aanmaken")}>
                    {t("Project aanmaken")}
                </Link>
            ),
            allowed: () => canAddProject,
        },
        // {
        //     identifier: 'user-add',
        //     link: (
        //         <Link to={generatePath('/user/create')} className={"dropdown-field__label"} title={t("Gebruiker aanmaken")}>
        //             {t("Gebruiker aanmaken")}
        //         </Link>
        //     ),
        //     allowed: () => canAddUser,
        // },
    ].filter(o => o.allowed() === true);

    const navigate = useNavigate();


    return (
        <header className={"application-header"}>
            <div className={"content-container"}>
                <div className={"application-header__container"}>
                    <div className={"application-header__left"}>
                        <div className={"application-header__logo-container"}>
                            <Link to={"/dashboard"}>
                                <SVG src={"logo-humancapitalgroup.svg"} className={"application-header__logo"}/>
                            </Link>
                        </div>
                        <ul className={"application-navigation"}>
                            <li className={"application-navigation__item"}>
                                <Link to={"/dashboard"}
                                      className={"application-navigation__link"}>{t("Dashboard")}</Link>
                            </li>
                            {multiClient && (
                                <li className={"application-navigation__item"}>
                                    <Link to={"/client"} className={"application-navigation__link"}
                                          data-cy={"navigation-clients-link"}>{t("Klanten")}</Link>
                                </li>
                            )}
                            <li className={"application-navigation__item"}>
                                <Link to={"/project"} className={"application-navigation__link"}
                                      data-cy={"navigation-projects-link"}>{t("Projecten")}</Link>
                            </li>
                            {canViewProjectGroup && (
                                <li className={"application-navigation__item"}>
                                    <Link to={"/projectgroup"}
                                          className={"application-navigation__link"}>{t("Projectgroepen")}</Link>
                                </li>
                            )}
                            {canViewInvisible && (
                                <li className={"application-navigation__item"}>
                                    <Link to={"/function"} className={"application-navigation__link"}
                                          data-cy={"navigation-functions-link"}>{t("Functies")}</Link>
                                </li>
                            )}
                            <li className={"application-navigation__item"}>
                                <Link to={"/user"} className={"application-navigation__link"}
                                      data-cy={"navigation-users-link"}>{t("Gebruikers")}</Link>
                            </li>
                            {canViewLicenseOverview && (
                                <li className={"application-navigation__item"}>
                                    <Link to={"/backoffice/license"} className={"application-navigation__link"}
                                          data-cy={"navigation-license-link"}>{t("Licenties")}</Link>
                                </li>
                            )}
                            {canAccessAdmin &&
                                <li className={"application-navigation__item"}>
                                    <Link to={"/environment"} className={"application-navigation__link"}
                                          data-cy={"admin-button"}>{t("Beheer")}</Link>
                                </li>
                            }
                        </ul>
                    </div>
                    <div className={"application-header__right"}>
                        <div className={"user-actions"}>
                            <div className="user-actions__item">
                                <Downloads/>
                            </div>
                            {contextItems.length > 0 && (
                                <div className={"user-actions__item"}>
                                    <InfoButton
                                        icon={"add"} title={"Contextmenu"}
                                        popOverClickClass={"dropdown-toggle"}
                                        popOverClass={"dropdown"}
                                        popOverClassActive={"dropdown dropdown--active"}
                                    >
                                        <ul>
                                            {contextItems.map((obj, idx) => {
                                                return (
                                                    <li key={obj.identifier} className={"dropdown-field"}>
                                                        {obj.link}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </InfoButton>
                                </div>
                            )}
                        </div>
                        <ContextMenuUser themeObj={themeObj}/>
                    </div>
                </div>
            </div>
        </header>
    )
}
