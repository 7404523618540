import {SET_PAGE_TITLE} from "../actions/site_info";

export function SiteInfo(state={},action) {

    let ret = {
        ...state,
    }

    switch(action.type) {
        case SET_PAGE_TITLE:
            ret.pageTitle = action.pageTitle;
            return ret;
        default:
            return ret;
    }
}
