import React, {useState as useStateReact}  from "react";
import Modal from 'react-modal';
import {singlePopOver, useState} from "../../components/PopOverHoC";
import cx from "classnames";
import Icon from "../atoms/Icon";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import {editorConfiguration} from "../../components/RichtextManager";
import Note from "./Note";
import Comments from "./Comments";
import IconButton from "../molecules/IconButton";
import {AspectDescriptionModal} from "../../components/Scoring";
import {useTranslation} from "react-i18next";
import ToolTip from "../../components/ToolTip";

// A definition header is a modal header which is used to display an aspect or domain title and its prefix number
export function DefinitionHeader(props) {
    function descriptionHtmlContent() {
        return {
            __html: props.scoringItem.description
        }
    }

    return (
        <div className={"modal-header"}>
            <div className={"modal-header__top"}>
                <h2 className={"modal-header__title"}>{ props.numberPrefix !== undefined && props.numberPrefix !== '' ? props.numberPrefix + '.': ''}{props.scoringItem.number} – {props.scoringItem.name}</h2>
                <ul className={"modal-header__controls"}>
                    <li className={"modal-header__control"}>
                        <IconButton icon={"close"} onClick={() => props.setActive(false)}/>
                    </li>
                </ul>
            </div>
            {/*<h3 className={"modal-header__sub-title"}>*/}
            {/*    <div dangerouslySetInnerHTML={descriptionHtmlContent()} />*/}
            {/*</h3>*/}
        </div>
    )
}

export function ScoreReference(props) {

    const {t, i18n} = useTranslation();
    const full_identifier = 'ScoreReference'
    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [ popOverActive, setPopOverActive ] =  useState(props, full_identifier, false);

    function scoringItemScoreDefinitionHtmlContent(content) {
        return {
            __html: content
        }
    }
    function scoringItemDefinitionHtmlContent() {
        return {
            __html: props.scoringItem.description
        }
    }

    return (
        <>
            <ToolTip tooltip={t("Score-referentie")}>
                <IconButton icon={"score-reference"}
                            sizeModifier={"small"}
                            title={t("Scorereferentie")}
                            onClick={ () => setPopOverActive(!popOverActive, full_identifier) }
                />
            </ToolTip>
            {popOverActive && (
                <Modal isOpen={popOverActive} className={cx("modal modal--full-screen", {"modal--immutable": props?.canEdit !== true})} overlayClassName={"modal-background"} bodyOpenClassName={"has-modal"}>
                    {/*<div className={"score-reference-header"}>*/}
                    {/*    <h2 className={"score-reference-header__title"}>{ props.numberPrefix !== '' ? props.numberPrefix + '.': ''}{props.scoringItem.number} – {props.scoringItem.name}</h2>*/}
                    {/*    <span className={"score-reference-header__definition"}></span>*/}
                    {/*</div>*/}

                    <DefinitionHeader {...props} setActive={setPopOverActive}/>

                    <div className={"comments-header"}> {/* FIXME: this is temporary, this will be tabs */}
                        {t("Scorereferentie")}
                    </div>


                    {/*FIXME: this will be a seperate tab*/}
                    {/*<div className="scoring-item__definition"  dangerouslySetInnerHTML={ scoringItemDefinitionHtmlContent() } />*/}
                    <ul className="score-reference-scores">
                        {props.scoringItem.scores.filter(obj => obj.id).map((score,idx) => {
                                let selected = props.isSelected(score.id) || false;
                                let highlightColors = props.isHighlighted(score.id) || [];
                                let disabled = !score.id;

                                return (
                                    <li key={score.id}
                                        className={"score-reference-score"}>
                                        <span className={"score-reference-score__label"}>{score.label}</span>
                                        <div className="description-editor__rich-text score-reference-score__definition" dangerouslySetInnerHTML={ scoringItemScoreDefinitionHtmlContent(score.desc || "") }/>
                                        <button className={cx(
                                            "button", "score-reference-score__button", {
                                                "button--tertiary": !selected,
                                                "button--primary": selected
                                            }
                                        )}
                                                disabled={disabled}
                                                onClick={() => score.id && props.setScore(score.structure_id, score.id)}>
                                            {selected
                                                ? <>{t("Geselecteerd")}</>
                                                : <>{t("Selecteer")} {score.label}</>
                                            }
                                        </button>
                                    </li>

                                )
                            }
                        )}
                    </ul>
                </Modal>
            )}
        </>
    )
}


export default function ScoringItemDisplay(props) {


    const notes = props.getStructureMessages(props.scoringItem.pk, 'notes');
    const comments = props.getStructureMessages(props.scoringItem.pk, 'comments');

    return (
      <>
          <div className={"scoring-item__text"}>

              <span className={"scoring-item__number"}>{ props.numberPrefix !== '' ? props.numberPrefix + '.': ''}{props.scoringItem.number}</span>
              <span className={"scoring-item__name"}>{props.scoringItem.name}</span>
          </div>
          <ol className={"grader"}>
              {props.scoringItem.scores.map((score, idx) => {

                  let selected = props.isSelected(score.id) || false;
                  let highlightColors = props.isHighlighted(score.id) || [];
                  let disabled = !score.id;

                  return (
                    <li
                      // onClick={() => score.id && props.setScore(score.id)}
                      key={score.id + '-' + idx}
                      className={"grader__item"}>
                        <input
                          onChange={() => score.id && props.setScore(score.id)}
                          type={"radio"}
                          name={`grader-${props.numberPrefix}-${props.scoringItem.number}`}
                          className={"grader__radio"}
                          id={`grader-${props.numberPrefix}-${props.scoringItem.number}-${idx}`}
                          checked={selected}
                          disabled={disabled}/>

                        <label
                          htmlFor={`grader-${props.numberPrefix}-${props.scoringItem.number}-${idx}`}
                          className={"grader__label"}>
                            {score.label}
                        </label>
                        <div className={"grader-compare"}>
                            {highlightColors.includes(1) && (<span className={"grader-compare__item grader-compare__item--1"}/>)}
                            {highlightColors.includes(2) && (<span className={"grader-compare__item grader-compare__item--2"}/>)}
                            {highlightColors.includes(3) && (<span className={"grader-compare__item grader-compare__item--3"}/>)}
                        </div>
                    </li>
                  )
              }) }
          </ol>

          <ul className={"scoring-item__options"}>
              {/*<li className={"scoring-item__option"}>*/}
              {/*  <IconButton icon={"compare-aspect"} sizeModifier={"small"} title={"Vergelijken"}  />*/}
              {/*</li>*/}
              <li className={"scoring-item__option"}>
                  <AspectDescriptionModal
                      aspect={props.scoringItem}
                      />
              </li>
              <li className={"scoring-item__option"}>
                  <ScoreReference
                      canEdit={props?.canEdit}
                      numberPrefix={props.numberPrefix}
                      scoringItem={props.scoringItem}
                      isSelected={props.isSelected}
                      isHighlighted={props.isHighlighted}
                      setScore={props.setScore} />
              </li>
              <li className={cx(
                  "scoring-item__option", {
                      "scoring-item__option--visible": comments.length > 0
                  }
              )}>
                  <Comments
                      type={"comment"}
                      scoringItem={props.scoringItem}
                      comments={comments}
                      identifier={props.scoringItem.pk}
                  />
              </li>
              <li className={cx(
                  "scoring-item__option", {
                      "scoring-item__option--visible": notes?.length > 0
                  }
              )}>
                  <Comments
                      type={"note"}
                      scoringItem={props.scoringItem}
                      comments={notes}
                      identifier={props.scoringItem.pk}
                  />
              </li>
          </ul>
      </>
    )
}
