import axios from "axios";
import useSWR, {useSWRInfinite} from "swr";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder, loadIfAllowed} from "../utils/constants";

export function getMethods() {

    let url = `/api/methods/?page_size=1000`;

    const {data, error, mutate} = useSWR(url);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export function getMethodOverview(stateToDebounce) {

    const identifiers = ['method-overview', ];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
        )
    );

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/scoringstructures/?page=${index+1}&status=active&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

// http://localhost:8011/api/scoringstructures/?parent_id=19

export function getMethodDetailOverview(stateToDebounce) {

    const identifiers = ['method-detail-overview', ];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.parent_id ? {
                parent_id: fetchState?.identKey?.parent_id
            } : {},
        )
    );

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/scoringstructures/?page=${index+1}&status=active&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function remoteSetScoringStructureTitleDescription(pk, name, description) {

    return axios.patch(`/api/scoringstructures/${pk}/`, {
        name,
        description,
    }).then(result => {
        return result;
    });
}

export function remoteSetScoringStructureScoreDescription(pk, description) {
    return axios.patch(`/api/scoringstructurescores/${pk}/`, {
        description,
    }).then(result => {
        return result;
    });

}
