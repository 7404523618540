import React, { useState, useEffect, useRef } from 'react';
import { parse } from 'svg-parser';


const lowerCamelCaseKeys = (source) => {

    const newKey = (key) => {

        let tmp = key.split('-')
            .map((word) => {
                return word.replace(word[0], word[0].toUpperCase());
            }).join('');

        return tmp[0].toLowerCase() + tmp.slice(1);
    }

    return Object.keys(source)
        .reduce((destination, key) => {
            destination[newKey(key)] = source[key];
            return destination;
        }, {});
}

const reactIt = (obj, props) => {

    let children = []

    obj.children.forEach((child, idx) => {
        children.push(reactIt(child, {key: `${obj.tagName}${idx}`}));
    });

    // the properties from the SVG must overrule those passed from the JSX

    return React.createElement(
        obj.tagName,
        {...props, ...lowerCamelCaseKeys(obj.properties)},
        children
    );
};

const SVG = ({ src, ...props }) => {

    const [svg, setSVG] = useState('<svg />');

    useEffect(() => {

        const importSVG = async () => {

            try {

                let raw = await import(`!!raw-loader!../../images/${src}`);
                setSVG(raw.default);

            } catch (err) {
                throw err;
            }
        };

        importSVG();

        return () => setSVG(<React.Fragment/>);
    }, []);

    return reactIt(parse(svg).children[0], props);
};

export default SVG;
