export const UPDATE_HEARTBEAT = 'UPDATE_HEARTBEAT';
export const REMOVE_HEARTBEAT = 'REMOVE_HEARTBEAT';

export function updateHeartbeat(identifier, data) {
    return {
        type: UPDATE_HEARTBEAT,
        identifier,
        data
    }
}

export function removeHeartbeat(identifier) {
    return {
        type: REMOVE_HEARTBEAT,
        identifier,
    }
}
