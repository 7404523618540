import {TextFilter} from "./Filters/TextFilter";
import {DateFilter} from "./Filters/DateFilter";
import {EmptyFilter} from "./Filters/EmptyFilter";
import React from "react";
import {DropdownFilter} from "../../patterns/molecules/DropdownFilter";
import {getUserGroups} from "../../queries/usergroups";
import {generatePath} from "react-router-dom";
import cx from "classnames";
import {EmptyTitle} from "./Titles/EmptyTitle";
import IconButton from "../../patterns/molecules/IconButton";
import i18n from "../../utils/i18n";
import {useTranslation} from "react-i18next";

export const UserGroupOverviewDefinition = {

    title: i18n.t("Gebruikersgroep-overzicht"),
    identifier: 'usergroup-overview',
    queryFn: getUserGroups,
    primary: {
        // title: i18n.t("Gebruikergroep aanmaken"),
        // identifier: 'create-usergroup',
        // link: './create/',
    },
    secondary: {
        // title: i18n.t("Bulk-actie"),
        // identifier: 'bulk-action',
        // icon: 'list-icon'
    },
    defaultOrderIdentifier: 'name',
    columns: [
        {
            title: i18n.t("Naam"),
            identifier: 'name',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter,
        },
        {
            title: i18n.t("Beschrijving"),
            identifier: 'description',
            type: "text-filter",
            orderable: true,
            className: 'overview-table__filter-cell--width-large',
            placeholder: i18n.t("Naam"),
            Filter: TextFilter,
        },
        {
            title: i18n.t('Klant'),
            identifier: 'client__name',
            orderable: true,
            className: 'overview-table__filter-cell--width-medium',
            placeholder: i18n.t('Naam'),
            type: TextFilter,
        },
        {
            title: i18n.t("Gebruikers"),
            identifier: 'users',
            type: "text-filter",
            orderable: false,
            className: 'overview-table__filter-cell--width-small',
            placeholder: i18n.t(""),
            Filter: EmptyFilter,
        },
        {
            identifier: 'action',
            className: 'overview-table__filter-cell--width-icon-button',
            Title: EmptyTitle,
            Filter: EmptyFilter
        }
    ],
    RowStructure: (props) => {
        const {t} = useTranslation();
        const detailUrl = generatePath("/client/:client_id/usergroup/:user_group_id/" ,{
            client_id: props?.client?.id || 0,
            project_id: props?.project?.id || 0,
            user_group_id: props.id
        })

        const detailClick = props?.onClick ? () => props.onClick(props) : (e) => {
            props.navigate( detailUrl );

        }


        return (
            <tr className={"overview-table__row"}>
                <td onClick={detailClick}
                    className={cx("overview-table__cell overview-table__cell--name overview-table__cell--interactive")}
                    data-cy={"overview-table-cell-name"}>
                    <div className={"overview-table__text"} title={props.name}>
                        {props.name}
                    </div>
                </td>
                <td className="overview-table__cell">
                    <div className={"overview-table__text"} title={props.description}>
                        {props.description}
                    </div>
                </td>
                {props.columns.some(col => col.identifier === 'client__name') && (
                <td className={"overview-table__cell overview-table__cell--interactive"}
                    onClick={() => {
                        props?.onClick ? props.onClick(props) : props.navigate(generatePath('/client/:client_id/', {
                            client_id: props.client.id,
                        }));
                    }}
                >
                    <div className={"overview-table__text"} title={props.client?.name}>
                        {props.client?.name}
                    </div>
                </td>
                )}
                {props?.contextData?.hasRemoveRight && (
                    <td className="overview-table__cell">
                        <div className={"overview-table__text"} title={t("Aantal gebruikers")}>
                            {props?.user_count || 0 }
                        </div>
                    </td>
                )}
                {props.columns.some(col => col.identifier === 'action') && props?.contextData?.hasRemoveRight &&  (
                    <td className={"overview-table__cell"}>
                        <IconButton title={t("Verwijder")} icon={"trash"}  sizeModifier={"medium"} onClick={() => props?.contextData?.remove(props)}/>
                    </td>
                )}
            </tr>
        )
    },
}

export const UserGroupSelectionDefinition = {
    ...UserGroupOverviewDefinition,
    interactive: true,
    identifier: 'user-selection-overview',
    columns: UserGroupOverviewDefinition.columns.filter(o => o.identifier !== 'action' && o.identifier !== 'client__name'),
    noHeader: true,
    noSearch: true,
    noContainer: true,
    paginationSize: 5,
    loadAllAllowed: true,
}
export const UserGroupSelectionDefinitionGlobal = {
    ...UserGroupSelectionDefinition,
    columns: UserGroupOverviewDefinition.columns.filter(o => o.identifier !== 'action'),
}
