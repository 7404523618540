import React, {Fragment, useState} from 'react';
import {Icon} from "../atoms/Icon";
import {IconButton} from "../molecules/IconButton";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import ToolTip from "../../components/ToolTip";

export function Input(props) {
    const {t} = useTranslation();

    return (
        <>
            <div className={"input-group__label-container"}>
                <label className={"input-group__label"} htmlFor={props.id}>{props.label}</label>
                {props.required && props.type !== "hidden" &&
                    <span className={"input-group__required"}>{t("Verplicht")}</span>
                }
            </div>
            {props.helpText &&
                <p className={"input-group__help-text"}>{props.helpText}</p>
            }
            {props.error && (
                <div className={"input-group__error-message"}>
                    <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}
                          data-cy={props["data-cy"] + "-error-icon"}/>
                    <p data-cy={props["data-cy"] + "-error-message"}>{props.error.message}</p>
                </div>
            )}
            <div className={cx("input-group__input", {"input-group__input--error": props.error})}>
                <input
                    autoFocus={props.autoFocus}
                    type={props.type}
                    maxLength={props.maxLength}
                    onChange={(e) => props.onChange && props.onChange(e)}
                    {...(props.register && props.register(props.name, {
                        required: props.required && t("Dit is een verplicht veld"),
                        pattern: props.pattern,
                        onBlur: () => props.trigger && props.trigger(),
                    }))}
                    placeholder={props.placeholder} id={props.id} {...props.input} readOnly={props.readOnly}
                    value={props.value}
                    disabled={props?.disabled} data-cy={props["data-cy"] ? props["data-cy"] : props.id + "-input"}/>
                <div className={"input-group__buttons"}>
                    {props?.copyTitle && (
                        <IconButton
                            icon={"copy"}
                            sizeModifier={"medium"}
                            title={props?.copyTitle || ""}
                            onClick={() => {
                                navigator?.clipboard?.writeText(props.getValues(props.id));
                                props.copyClick && typeof props?.copyClick === 'function' && props?.copyClick();
                            }}
                            data-cy={props.id + "-copy-button"}
                        >
                        </IconButton>
                    )}
                </div>
            </div>
        </>
    )
}

export function CheckboxInput(props) {

    return (
        <>
            <input type={"checkbox"}
                   className={cx("checkbox",
                                {"checkbox--indented": props.indented})}
                   id={props.id} {...props.input}
                   readOnly={props.readOnly}
                   disabled={props?.disabled}
                   onChange={(...args) => {
                       props.input.onChange(...args);
                       if(props?.onChange) {
                           props.onChange(...args);
                       }
                   }}
                   data-cy={props.id + "-checkbox"}
                   {...(props.register && props.register(props.name, {required: props.required}))}
            />
            <label htmlFor={props.id}
                   data-cy={props?.["data-cy"] ? props["data-cy"] : props.id + "-label"}>{props.label}</label>
            {props.helpText &&
                <p className={"input-group__help-text input-group__help-text--select"}>{props.helpText}</p>
            }
        </>
    )
}

export function SimpleRadioInputs(props) {
   const {t} = useTranslation();

    return (
        <div>
            {props.options && props.options.map((option) => {
                return (
                    <Fragment key={option.name}>
                        <div
                            onClick={() => {
                                if(!option.disabled) {
                                    props.setValue(props.name, option.id.toString())
                                }
                            }}
                        >
                            <input
                                type={"radio"}
                                {...(props.register && props.register(props.name, {
                                    required: props.required && t("Dit is een verplicht veld"),
                                }))}
                                id={props.id + option.id}
                                {...props.input}
                                value={option.id}
                                disabled={option.disabled}
                                data-cy={props["data-cy"] ? props["data-cy"] : option.id + "-radio-input"}
                                className={cx("radio",
                                {"radio--indented": option.indented})}
                            />
                            <label className={"input-container__label"}
                                   htmlFor={props.id + option.id}
                                   data-cy={props["data-cy"] ? props["data-cy"] : option.id + "-radio-label"}>{t(option.name)}</label>
                        </div>
                         {option.helpText &&
                            <p className={"input-group__help-text input-group__help-text--select"}>{t(option.helpText)}</p>
                        }
                    </Fragment>
                )
            })}
        </div>
    )
}

export function RadioInputs(props) {
    const {t} = useTranslation();

    const [showMoreInformation, setShowMoreInformation] = useState([]);

    return (
        <div className={"input-group"}>
            <div className={"input-group__label-container"}>
                <label className={"input-group__label"} htmlFor={props.id}>{t(props.label)}</label>
                {props.required &&
                    <span className={"input-group__required"}>{t("Verplicht")}</span>
                }
            </div>
            {props.helpText &&
                <p className={"input-group__help-text"}>{props.helpText}</p>
            }
            {props.error && (
                <div className={"input-group__error-message"}>
                    <Icon icon={"error-filled"}
                          sizeModifier={"small"}
                          className={"input-group__error-icon"}
                          data-cy={props["data-cy"] + "-error-icon"}
                    />
                    <p data-cy={props["data-cy"] + "-error-message"}>{props.error.message}</p>
                </div>
            )}
            {props.options && props.options.map((option) => {
                return (
                    <Fragment key={option.name}>
                        <div
                            className={cx("input-container",
                                {"input-container--selected": props.selected === option.id},
                                {"input-container--disabled": option.disabled})}
                            onClick={() => {
                                if(!option.disabled) {
                                    props.setValue(props.name, option.id.toString())
                                }
                            }}
                        >
                            <input
                                type={"radio"}
                                {...(props.register && props.register(props.name, {
                                    required: props.required && t("Dit is een verplicht veld"),
                                }))}
                                id={props.id + option.id}
                                {...props.input}
                                value={option.id}
                                disabled={option.disabled}
                                data-cy={props["data-cy"] ? props["data-cy"] : option.id + "-radio-input"}
                                className={"radio"}
                            />
                            <label className={"input-container__label"}
                                   htmlFor={props.id + option.id}
                                   data-cy={props["data-cy"] ? props["data-cy"] : option.id + "-radio-label"}>{t(option.name)}</label>
                            {option.warning &&
                                <div className={"input-container__warning"}>
                                    <ToolTip tooltip={t("Let op: deze rol heeft rechten die een grote impact op de applicatie kunnen hebben")}>
                                        <Icon icon={'warning-triangle'}
                                              sizeModifier={'small'}>
                                        </Icon>
                                    </ToolTip>
                                </div>
                            }
                            {option.summary &&
                                <p className={"input-container__help-text"}>{option.summary}</p>
                            }
                            {showMoreInformation.includes(option.id) &&
                                <div className={"input-container__help-text"}>
                                    {option.description}
                                </div>
                            }
                            {option.description &&
                                <span className={cx("input-container__expand", {"input-container__expand--open": showMoreInformation.includes(option.id)})} onClick={(e) => {
                                    e.stopPropagation();
                                    setShowMoreInformation(prev => {
                                        if(prev.includes(option.id)) {
                                            return prev.filter(value => !(value === option.id))
                                        } else {
                                            return [...prev, option.id]
                                        }

                                    })
                                }}>{showMoreInformation.includes(option.id) ? t("Minder informatie") : t("Meer informatie")}</span>
                            }
                        </div>
                    </Fragment>
                )
            })}
        </div>
    )
}
