import { ADD_CHANGE} from "../actions/form";

const FormDefaultState = {

}

export function FormState(state=FormDefaultState, action) {

    switch(action.type) {
        case ADD_CHANGE:
            return {
                ...state,
                [action.form]: {
                    ...state[action.form],
                    [action.name]: action.value
                }
            }
        default:
            return state;
    }

}
