import axios from 'axios';

export const SET_FGS_DEFAULT_SETTING = 'SET_FGS_DEFAULT_SETTING';
export const SET_FGS_GROUP_SETTING = 'SET_FGS_GROUP_SETTING';
export const SET_FGS_GROUP_SETTINGS_ALL = 'SET_FGS_GROUP_SETTINGS_ALL';
export const SET_FGS_RECALCULATE_USING = 'SET_FGS_RECALCULATE_USING';
export const SET_FGS_GROUP_STATE = 'SET_FGS_GROUP_STATE';
export const SET_FGS_GROUP_STATE_MULTIPLE = 'SET_FGS_GROUP_STATE_MULTIPLE';


export function setFGSDefaultSetting(projectId,  value, recalculateUsing=false) {
    return {
        type: SET_FGS_DEFAULT_SETTING,
        projectId,
        value,
        recalculateUsing,
    }
}

export function setFGSRecaluclateUsing(projectId, recalculateUsing=false) {
    return {
        type: SET_FGS_RECALCULATE_USING,
        projectId,
        recalculateUsing,
    }

}

// pass dict as value. eg: { factor: 1.25, points_min:3 }
export function setFGSGroupSetting(projectId, groupNumber, value, recalculateUsing=false) {

    return {
        type: SET_FGS_GROUP_SETTING,
        projectId,
        groupNumber,
        value,
        recalculateUsing,
    }
}

export function setFGSGroupSettingsAll(projectId, value) {

    return {
        type: SET_FGS_GROUP_SETTINGS_ALL,
        projectId,
        value,
    }
}

export function setGroupStateMultiple(projectId, groups=[], state=false) {

    return {
        type: SET_FGS_GROUP_STATE_MULTIPLE,
        projectId,
        obj: Object.assign({}, ...(groups.map(o => ({[o]: state}))))
    }


}


export function setGroupState(projectId, groupId, state=false) {
    return {
        type: SET_FGS_GROUP_STATE,
        projectId,
        groupId,
        state,
    }

}
