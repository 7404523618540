import {Routes, Route, useLocation, useParams, useRouteMatch, useRoutes, matchRoutes} from "react-router-dom";
import FunctionMeta from "../FunctionMeta";
import FunctionStatusNavigation from "../FunctionStatusNavigation";
import RichTextManager, {EditorDisplay} from "../RichtextManager";
import React, {useEffect, useState, useMemo} from "react";
import {Scoring, Grading, Grading2, Scoring2} from "../Scoring";
import {getFunction, remoteAcquireLock, remoteUnlock} from "../../queries/function_detail";
import {getProject} from "../../queries/project_detail";
import Ranking from "../Ranking";
import SelectedFunctionsDisplay from "../../patterns/organisms/SelectedFunctions";
import {
    getScoresForHighlight,
    remoteAddReference,
    remoteRemoveReference,
    remoteSetGroup,
    setHighlight
} from "../../actions/scoring";
import ScoringSidebar from "../ScoringSidebar";
import {useDispatch, useSelector} from "react-redux";
import {Breadcrumbs} from "../../patterns/organisms/Breadcrumbs";
import Login from "../Login";
import {flatRouteList} from "../FUWA";
import {useAuth} from "../../utils/useAuth";
import cx from "classnames";
import {IconButton} from "../../patterns/molecules/IconButton";
import Icon from "../../patterns/atoms/Icon";
import {useTranslation} from "react-i18next";
import FunctionSummary from "../FunctionSummary";
import FunctionMotivation from "../FunctionMotivation";
import Description from "../Description";
import {removeHeartbeat, updateHeartbeat} from "../../actions/heartbeat";
import {Heartbeat} from "../Heartbeat";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import Notification from "../../patterns/molecules/Notification";
import {WithAccessRight} from "../../utils/withAccessHoC";
import {AccessDenied} from "../../patterns/organisms/AccessDenied";


/*
    Concept for heartbeat-logic

    FunctionDetailScreen is always active. It knows about the current location of the user and if, as such, the user
    is on a relevant function page which he might be editting.

    When a function is 'unlocked for editting' a redux (local) state item should be created which monitors that the user
    has a function in edit mode. This is also communicated with the backend.

    Local state can be updated more frequently if that makes sense in certain cases.

    There should be a component (HoC?) which monitors local state AND locations to see if the user is still on a
    page which can edit functions. If a user has navigated to a page which does NOT update the heartbeat, we can display
    a message indicating this is the case. It should notify that not returning to the function would automatically
    close the function after 2 minutes (120 seconds). The message (which also displays for 120 seconds) can be
    dismissed, but should also display two additional options: 'return to function' and 'lock function'.
    The lock function will dismiss the message and close the function immediately

    Once every 30 seconds, there should be a check-in with the backend to notify it is 'still there'.

    If a positive response is received everything restarts again
    If a negative response is received (function is closed by someone else, of in another window) handle the closing as
    is normally done, but also display a message about this

    If no response is received there are connection problems, we should display that this is the fact.
    We do not display 'reconnection' possibilities as we have no means of re-submitting changes because we are not
    really aware of which changes should be re-submitted. We should lock the function immediately and maybe even redirect
    to another page as to prevent further changes (a reload should be done)



    Serversided:
    We should monitor the active sessions for function-edits. If session has not responded for 3 minutes (180 seconds)
    we should terminate that session




 */


export default function FunctionDetailScreen(props) {

    const {t, i18n} = useTranslation();

    const auth = useAuth();
    let dispatch = useDispatch();
    // let { path, url } = useRouteMatch();
    let location = useLocation();
    let {client_id, project_id, function_id} = useParams();
    function_id = parseInt(function_id);
    project_id = parseInt(project_id);

    const highlights = useSelector(state => state.ScoringState.highlights);
    const functionDetail = getFunction(function_id);
    const [editState, setEditState] = useState(false);
    const [localFlatRouteList, setLocalFlatRouteList] = useState([]);

    // list of levels to check the access against against
    const permLevels = [
        [project_id, 'project'],
        [client_id, 'client']
    ]

    // Determine the right for this specific function
    const hasEditRight = auth.checkAccessList('base.change_' + functionDetail?.data?.type + '_function', permLevels)
    const canViewPoints = auth.checkAccessList('base.view_reference_function_points', permLevels);
    const canChangeReference = auth.checkAccessList('base.change_reference_function', permLevels);
    const canChangeProtection = auth.checkAccessList('base.change_function_protection', permLevels);
    const canViewInvisible = auth.checkAccessList('base.view_function_invisible', permLevels);
    const hasArchiveRight = auth.checkAccessList('base.archive_function', permLevels);

    const canChangeCollectiveLabourAgreement = auth.checkAccessList("base.change_collective_labour_agreement", permLevels);
    const canChangeIfCollectiveLabourAgreement = canChangeCollectiveLabourAgreement || functionDetail?.data?.is_collective_labour_agreement === false;

    const unEditables = ['determined', 'expired', 'archived'];


    const acquireLock = async () => {
        let lock = await remoteAcquireLock(function_id);
        await functionDetail.mutate();
    }

    const unlock = async () => {
        let unlock = await remoteUnlock(function_id);
        await functionDetail.mutate();
    }

    const isHighlighted = (scoringItem, scoreId) => {
        let ret = [];
        (highlights?.[0]?.scores?.findIndex(obj => obj.score_id === scoreId) >= 0) ? ret.push(1) : null;
        (highlights?.[1]?.scores?.findIndex(obj => obj.score_id === scoreId) >= 0) ? ret.push(2) : null;
        (highlights?.[2]?.scores?.findIndex(obj => obj.score_id === scoreId) >= 0) ? ret.push(3) : null;
        return ret;
    }

    const toggleHighlight = (functionId) => {
        let idx = highlights.findIndex(item => item.id === functionId);
        if (highlights.length < 3 && idx < 0) {
            // add if not 3 active
            dispatch(getScoresForHighlight(functionId));
        } else {
            // remove when already highlighted
            dispatch(setHighlight(functionId, []));
        }
    }

    const removeFunction = async (function_id) => {
        if (editState.canEdit) {
            await remoteRemoveReference(functionDetail?.data?.id, function_id)
            functionDetail.mutate();
        }
    }

    const setFunctionGroup = async (function_id, function_group) => {
        if (editState.canEdit) {
            await remoteSetGroup(function_id, function_group);
            functionDetail.mutate();
        }
    }

    const selectFunction = async (function_id) => {
        if (editState.canEdit) {
            await remoteAddReference(functionDetail?.data?.id, function_id)

            // we should create the resulting info which would be made when calling 'get reference functions' based on
            // what we already know

            // Then we should mutate the result of the (not yet existing) 'get reference functions' data without forcing update
            // using the above created info

            // Now we should 'await' call remoteRankFunction

            // afterwards we should invalidate the get reference functions result so it will fetch and update with correct
            // information from the server

            // see 'setScore' in components/Scoring.jsx for examples
            functionDetail.mutate();
        }
    };

    useEffect(() => {

        if (functionDetail?.data && editState !== false) {

            // The flat route list used for routing everything within the functiondetail (instead of global)
            setLocalFlatRouteList([
                {
                    path: 'description',
                    element: <Description function_id={function_id} project_id={project_id} editState={editState}/>,
                    breadCrumb: t("Beschrijving")
                },
                {
                    path: 'scoring',
                    element: (
                        <div className="scoring">
                            <div className={"scoring__main"}>
                                {editState.canEdit && editState.scoring_immutable && (
                                    <Notification type={"warning"} sizeModifier={"large"}
                                                  text={t("De indeling is al vastgesteld en kan daarom niet gewijzigd worden")}/>
                                )}
                                {functionDetail?.data?.type === 'company' && (
                                    <>
                                        <Scoring
                                            isHighlighted={isHighlighted}
                                            editState={editState}
                                        />
                                        {/*<Scoring2*/}
                                        {/*    isHighlighted={isHighlighted}*/}
                                        {/*    editState={editState}*/}
                                        {/*/>*/}
                                    </>

                                )}
                                {functionDetail?.data?.type === 'reference' && (
                                    <>
                                        <Grading
                                            isHighlighted={isHighlighted}
                                            editState={editState}
                                        />
                                        {/*<Grading2*/}
                                        {/*    isHighlighted={isHighlighted}*/}
                                        {/*    editState={editState}*/}

                                        {/*/>*/}
                                    </>

                                )}
                            </div>
                            <ScoringSidebar
                                functionDetail={functionDetail?.data}
                                comparedPatternList={functionDetail?.data?.pattern_list}
                                comparison={true}
                                displayPoints={canViewPoints}
                                toggleHighlight={toggleHighlight}
                                highlightActive={(functionId) => (highlights.findIndex(item => item.id === functionId) + 1)}
                            />
                        </div>
                    ),
                    breadCrumb: functionDetail?.data?.type === 'company' ? t("Waarderen") : t("Graderen"),
                },
                {
                    path: 'rank',
                    element: (
                        <>
                            {editState.canEdit && editState.scoring_immutable && (
                                <Notification type={"warning"} sizeModifier={"large"}
                                              text={t("De indeling is al vastgesteld en kan daarom niet gewijzigd worden")}/>
                            )}
                            <Ranking
                                functionDetail={functionDetail?.data}
                                edit={editState.canEdit && !unEditables.includes(functionDetail?.data?.scoring_status)}
                                removeFunction={removeFunction}
                                selectFunction={selectFunction}
                                setFunctionGroup={setFunctionGroup}
                            />
                        </>
                    ),
                    breadCrumb: t("Indeling")
                },
                {
                    path: 'motivation',
                    element: (
                        !(functionDetail?.data) ? (
                            <LoaderAnimation/>
                        ) : (
                            <>
                                {functionDetail?.data?.type === 'reference' ? (
                                    <FunctionMotivation
                                        edit={editState.canEdit && !unEditables.includes(functionDetail?.data?.scoring_status)}
                                        functionDetail={functionDetail}
                                        initialMotivation={functionDetail?.data?.ranking_motivation}
                                        title={functionDetail?.data?.type === 'reference' ? t("Functiebeeld") : t("Onderbouwing")}
                                    />

                                ) : (
                                    <div className="scoring">
                                        <div className={"scoring__main"}>
                                            {editState.canEdit && editState.scoring_immutable && (
                                                <Notification type={"warning"} sizeModifier={"large"}
                                                              text={t("De indeling is al vastgesteld en kan daarom niet gewijzigd worden")}/>
                                            )}
                                            <FunctionMotivation
                                                edit={editState.canEdit && !unEditables.includes(functionDetail?.data?.scoring_status)}
                                                functionDetail={functionDetail}
                                                initialMotivation={functionDetail?.data?.ranking_motivation}
                                                title={functionDetail?.data?.type === 'reference' ? t("Functiebeeld") : t("Onderbouwing")}
                                            />
                                        </div>
                                        <SelectedFunctionsDisplay
                                            edit={editState.canEdit && !unEditables.includes(functionDetail?.data?.scoring_status)}
                                            functionDetail={functionDetail?.data}
                                        />
                                    </div>
                                )}
                            </>
                        )
                    )
                },
                {
                    path: 'summary',
                    element: (
                        <FunctionSummary
                            functionDetail={functionDetail}
                        />
                    )
                }

            ].filter(o => functionDetail?.data?.type !== 'reference' || o.path !== 'scoring' || canChangeReference));
        }

    }, [functionDetail?.data, canChangeReference, editState, highlights]);

    // determine the matched routes (first one is probably the route we want)
    const routes = matchRoutes(flatRouteList, location.pathname)


    // determine the basename off of the route
    const basename = routes[0].route.path.substr(0, routes[0].route.path.length - 1);

    // localFlatRouteList is used for creating routes, but we want to prepend them with the basepaths as that is what Breadcrumbs uses
    const newLocalFlatRouteList = localFlatRouteList
        .map(route => {
            return {
                ...route,
                path: basename + route.path
            }
        });

    const FlatRoutes = function FlatRoutes(props) {
        let routes = useRoutes(localFlatRouteList);
        return routes;
    };

    useEffect(() => {
        highlights.map((item) => dispatch(setHighlight(item.id, [])))
    }, []);

    useEffect(() => {

        if (functionDetail?.data?.is_locked !== undefined) {

            const immutable =
                functionDetail?.data?.immutable ||
                (!canChangeProtection && functionDetail?.data?.is_protected) ||
                !canChangeIfCollectiveLabourAgreement ||
                (unEditables.includes(functionDetail?.data?.description_status) && unEditables.includes(functionDetail?.data?.scoring_status));

            setEditState({
                // immutable:   You can not edit this function at all (because it was 'finalized' by setting it to determined/expired/archived)

                // edit:
                //  closed:     No one is working (free to start editting)
                //  open:       You are working on this function (you can edit)
                //  locked:     Someone is working on this function you can not edit or start editting this function

                // user:        the person currently editting this function

                immutable,
                canEdit: (functionDetail?.data?.is_locked && functionDetail?.data?.locked_by?.id === auth.user?.id) && immutable === false && hasEditRight,
                state: functionDetail?.data?.is_locked && functionDetail?.data?.locked_by?.id === auth.user?.id ? "open" : functionDetail?.data?.is_locked ? "locked" : "closed",
                locked: {
                    locked: functionDetail?.data?.is_locked,
                    lockedBy: functionDetail?.data?.locked_by,
                    lockedByName: (functionDetail?.data?.locked_by?.get_full_name || ""),
                    lockedDate: functionDetail?.data?.locked_date,
                },
                description_immutable: unEditables.includes(functionDetail?.data?.description_status),
                scoring_immutable: unEditables.includes(functionDetail?.data?.scoring_status),
                hasEditRight,
                hasArchiveRight
            });
        }
        return () => {
        }
    }, [functionDetail?.data?.id, functionDetail?.data?.is_locked, hasEditRight, hasArchiveRight, functionDetail?.data?.description_status, functionDetail?.data?.scoring_status]);

    if (functionDetail.isError || (functionDetail?.data?.is_visible === false && !canViewInvisible)) {
        return <AccessDenied error={functionDetail.isError}/>;
    }

    return (
        <>
            <Breadcrumbs routeList={[...flatRouteList, ...newLocalFlatRouteList]}/>
            <Heartbeat
                short={functionDetail?.data?.name}
                function_id={function_id}
                running={editState.canEdit}
                type={"edit"}
            />
            <FunctionMeta
                editState={editState}
                acquireLock={acquireLock}
                unlock={unlock}
                function_id={function_id} project_id={project_id} client_id={client_id}/>
            <div className={"content-container"}>
                <div className={cx("function-wrapper", {
                    "function-wrapper--immutable": editState.immutable,
                    "function-wrapper--not-editable": !editState.canEdit,
                    "function-wrapper--description-immutable": editState.description_immutable,
                    "function-wrapper--scoring-immutable": editState.scoring_immutable,
                })}>
                    <FunctionStatusNavigation function_id={function_id}
                                              editState={editState}
                                              acquireLock={acquireLock}
                                              unlock={unlock}/>
                    <FlatRoutes/>
                </div>
            </div>
        </>
    );

}

// FunctionDetailScreen.whyDidYouRender = true;
