import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Drawer from "./Drawer";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import ButtonSecondary from "../patterns/atoms/ButtonSecondary";
import {TextFilter} from "./Overviews/Filters/TextFilter";
import CheckboxFilter from "./Overviews/Filters/CheckboxFilter";
import DatePickerFilter from "./Overviews/Filters/DatePickerFilter";
import AutocompleteUserDrawer from "./AutocompleteUserDrawer";
import FullNameFromUserIdComponent from "./FullNameFromUserIdComponent";
import {MNTFilter} from "./Overviews/Filters/MNTFilter";
import {
    FuwaterDomainsGte,
    FuwaterDomainsLte,
    MNTDomainsGte,
    MNTDomainsLte,
    isBakkenistMNT,
    isFuwaterVbalans,
} from "../utils/constants";
import FunctionGroupDropdownFilter from "./FunctionGroupDropdownFilter";
import ButtonTertiary from "../patterns/atoms/ButtonTertiary";
import {useAuth} from "../utils/useAuth";
import {CheckboxInput} from "../patterns/atoms/Input";
import cx from "classnames";
import {NumberFilter} from "./Overviews/Filters/NumberFilter";
import {Icon} from "../patterns/atoms/Icon";

export default function FunctionAdvancedFilter(props) {
    const {t} = useTranslation();

    return (
        <Drawer
            title={t("Filteren")}
            buttonText={t("Filteren")}
            buttonClassname={"button button--tertiary"}
            noReset={true}
            noButton={true}
            advancedFilter={true}
            resetDisabled={!Object.keys(props.filterState).some(filter => props.advancedFilters.includes(filter))}
            resetText={"Filters leegmaken"}
            reset={() => {
                props.clearFilters(props.advancedFilters)
            }}
            autoClose={false}
            type={"side-drawer"}
            contentComponent={
                <FilterDrawerContent
                    filterStateFunctionOverview={props.filterState}
                    setFilterFunctionOverview={(filters) => {
                        props.setFilters(filters)
                    }}
                    advancedFilters={props.advancedFilters}
                    clearFiltersFunctionOverview={(filters) => {
                        props.clearFilters(filters)
                    }}
                    contextData={props.contextData}
                    identifier={props.identifier}
                    overviewIdentifier={props.overviewIdentifier}
                />
            }
        />
    )
}

function FilterDrawerContent(props) {
    const {t} = useTranslation();

    const [filterState, setFilterState] = useState(props.filterStateFunctionOverview);
    const [resetAutocomplete, setResetAutocomplete] = useState(props.resetAutocomplete);
    const [errorCreationDate, setErrorCreationDate] = useState(false);
    const [errorModificationDate, setErrorModificationDate] = useState(false);

    const auth = useAuth();
    const canViewCompanyFunctionPoints = auth.hasPermission("base.view_company_function_points");
    const canViewReferenceFunctionPoints = auth.hasPermission("base.view_reference_function_points");

    function setFilter(key, value) {
        setFilterState(prevState => ({...prevState, [key]: value}))
    }

    function applyFilters() {
        props.setFilterFunctionOverview(filterState)
        props.close();
    }

    function resetFilters() {
        props.clearFiltersFunctionOverview(props.advancedFilters)
        props.advancedFilters.forEach(advancedFilter => {
            setResetAutocomplete(!resetAutocomplete);
            delete filterState[advancedFilter]
        })
    }

    if((filterState["creation_date_after"] && filterState["creation_date_before"]) && new Date(filterState["creation_date_after"]) > new Date(filterState["creation_date_before"]))
    {
        errorCreationDate !== true && setErrorCreationDate(true);
    } else {
        errorCreationDate !== false && setErrorCreationDate(false);
    }

    if((filterState["modification_date_after"] && filterState["modification_date_before"]) &&  new Date(filterState["modification_date_after"]) >  new Date(filterState["modification_date_before"]))
    {
        errorModificationDate !== true && setErrorModificationDate(true);
    } else {
        errorModificationDate !== false && setErrorModificationDate(false);
    }

    return (
        <div className={"filter-sidebar"}>
            <div className={"filter-sidebar__actions"}>
                <ButtonPrimary onClick={() => applyFilters()}>{t("Filters toepassen")}</ButtonPrimary>
                <ButtonTertiary disabled={!Object.keys(filterState).some(filter => props.advancedFilters.includes(filter))}
                                onClick={() => resetFilters()}>{t("Filters leegmaken")}
                </ButtonTertiary>
            </div>

            <div className={"filter-sidebar__section"}>
                <h1 className={"filter-sidebar__section-heading"}>{t("Functie eigenschappen")}</h1>
                <>
                    {(props.overviewIdentifier === "global-function-overview-bkn" || isBakkenistMNT(props.contextData?.project?.method?.id)) &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    <div className={"pattern-filter__domain-letter-heading-container"}>
                                        {MNTDomainsGte.map((domain) => {
                                            return <span key={domain.identifier}
                                                         className={"pattern-filter__domain-letter-heading"}>{domain.short}</span>
                                        })}
                                    </div>
                                    <MNTFilter domains={MNTDomainsGte} patternFilterNoMargin={true}
                                               advancedFilterClass={true} contextData={{noFunctionGroup: true}}
                                               setFilter={setFilter} state={filterState}/>
                                </div>
                                <span
                                    className={"input-range__divider input-range__divider--pattern-filter"}>{t("t/m")}</span>
                                <div className={"input-range__input"}>
                                    <div className={"pattern-filter__domain-letter-heading-container"}>
                                        {MNTDomainsLte.map((domain) => {
                                            return <span key={domain.identifier}
                                                         className={"pattern-filter__domain-letter-heading"}>{domain.short}</span>
                                        })}
                                    </div>
                                    <MNTFilter domains={MNTDomainsLte} patternFilterNoMargin={true}
                                               advancedFilterClass={true} contextData={{noFunctionGroup: true}}
                                               setFilter={setFilter} state={filterState}/>
                                </div>
                            </div>
                        </div>
                    }
                    {(props.overviewIdentifier === 'global-function-overview-vbalans' || isFuwaterVbalans(props.contextData?.project?.method?.id)) &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    <div className={"pattern-filter__domain-letter-heading-container"}>
                                        {FuwaterDomainsGte.map((domain) => {
                                            return <span key={domain.identifier}
                                                         className={"pattern-filter__domain-letter-heading pattern-filter__domain-letter-heading--vbalans"}>{domain.short}</span>
                                        })}
                                    </div>
                                    <MNTFilter domains={FuwaterDomainsGte} patternFilterNoMargin={true}
                                               advancedFilterClass={true} filterOptions={{type: "vbalans"}}
                                               contextData={{noFunctionGroup: true}} setFilter={setFilter}
                                               state={filterState}/>
                                </div>
                                <span
                                    className={"input-range__divider input-range__divider--pattern-filter--vbalans"}>{t("t/m")}</span>
                                <div className={"input-range__input"}>
                                    <div className={"pattern-filter__domain-letter-heading-container"}>
                                        {FuwaterDomainsLte.map((domain) => {
                                            return <span key={domain.identifier}
                                                         className={"pattern-filter__domain-letter-heading pattern-filter__domain-letter-heading--vbalans"}>{domain.short}</span>
                                        })}
                                    </div>
                                    <MNTFilter domains={FuwaterDomainsLte} patternFilterNoMargin={true}
                                               advancedFilterClass={true} filterOptions={{type: "vbalans"}}
                                               contextData={{noFunctionGroup: true}} setFilter={setFilter}
                                               state={filterState}/>
                                </div>
                            </div>
                        </div>
                    }


                    {props.advancedFilters.includes("function_group__gte") && props.advancedFilters.includes("function_group__lte") &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-group__label-container"}>
                                <h2 className={"input-group__label"}>{t("Groepbereik")}</h2>
                            </div>
                            {props.identifier !== "advanced-filter-client-project-function" &&
                                <p className={"input-group__help-text"}><strong>{t("Let Op! ")}</strong>{t("Functieoverzicht toont door klant goedgekeurde label ipv numerieke waarde")}</p>
                            }
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    {props.identifier === "advanced-filter-client-project-function" ?
                                        <FunctionGroupDropdownFilter project_id={props.contextData.project_id}
                                                                     identifier={"function_group__gte"}
                                                                     setFilter={setFilter} state={filterState}/> :
                                        <NumberFilter placeholder={t("-")} identifier={"function_group__gte"}
                                                    setFilter={setFilter}
                                                    state={filterState}/>
                                    }
                                </div>
                                <span className={"input-range__divider"}>{t("t/m")}</span>
                                <div className={"input-range__input"}>
                                    {props.identifier === "advanced-filter-client-project-function" ?
                                        <FunctionGroupDropdownFilter project_id={props.contextData.project_id}
                                                                     identifier={"function_group__lte"}
                                                                     setFilter={setFilter} state={filterState}/> :
                                        <NumberFilter className={"input-range__input"} placeholder={t("-")}
                                                    identifier={"function_group__lte"}
                                                    setFilter={setFilter} state={filterState}/>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {props.advancedFilters.includes("points__gte") && props.advancedFilters.includes("points__lte") && (canViewReferenceFunctionPoints || canViewCompanyFunctionPoints) &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-group__label-container"}>
                                <h2 className={"input-group__label"}>{t("Puntenbereik")}</h2>
                            </div>
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    <NumberFilter placeholder={t("-")} identifier={"points__gte"} setFilter={setFilter}
                                                state={filterState} />
                                </div>
                                <span className={"input-range__divider"}>{t("t/m")}</span>
                                <div className={"input-range__input"}>
                                    <NumberFilter className={"input-range__input"} placeholder={t("-")}
                                                identifier={"points__lte"} setFilter={setFilter}
                                                state={filterState} />
                                </div>
                            </div>
                        </div>
                    }

                    {props.advancedFilters.includes("creation_date_after") && props.advancedFilters.includes("creation_date_before") &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-group__label-container"}>
                                <h2 className={"input-group__label"}>{t("Datum Aangemaakt")}</h2>
                            </div>
                            {errorCreationDate && (
                                <div className={"input-group__error-message"}>
                                    <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}
                                          data-cy={props["data-cy"] + "-error-icon"}/>
                                    <p data-cy={props["data-cy"] + "-error-message"}>{t("Let op! Einddatum mag niet voor de startdatum liggen")}</p>
                                </div>
                            )}
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    <DatePickerFilter className={"input-range__input"}
                                                      identifier={"creation_date_after"} setFilter={setFilter}
                                                      state={filterState}
                                    />
                                </div>
                                <span className={"input-range__divider"}>{t("t/m")}</span>
                                <div className={"input-range__input"}>
                                    <DatePickerFilter className={"input-range__input"}
                                                      identifier={"creation_date_before"} setFilter={setFilter}
                                                      state={filterState}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {props.advancedFilters.includes("modification_date_after") && props.advancedFilters.includes("modification_date_before") &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-group__label-container"}>
                                <h2 className={"input-group__label"}>{t("Laatst bewerkt")}</h2>
                            </div>
                            {errorModificationDate && (
                                <div className={"input-group__error-message"}>
                                    <Icon icon={"error-filled"} sizeModifier={"small"} className={"input-group__error-icon"}
                                          data-cy={props["data-cy"] + "-error-icon"}/>
                                    <p data-cy={props["data-cy"] + "-error-message"}>{t("Let op! Einddatum mag niet voor de startdatum liggen")}</p>
                                </div>
                            )}
                            <div className={"input-range"}>
                                <div className={"input-range__input"}>
                                    <DatePickerFilter className={"input-range__input"}
                                                      identifier={"modification_date_after"} setFilter={setFilter}
                                                      state={filterState}
                                    />
                                </div>
                                <span className={"input-range__divider"}>{t("t/m")}</span>
                                <div className={"input-range__input"}>
                                    <DatePickerFilter className={"input-range__input"}
                                                      identifier={"modification_date_before"} setFilter={setFilter}
                                                      state={filterState}
                                    />
                                </div>
                            </div>

                        </div>
                    }

                    {props.advancedFilters.includes("code") &&
                        <div className={"filter-sidebar__input-container"}>
                            <div className={"input-group__label-container"}>
                                <h2 className={"input-group__label"}>{t("Functiecode")}</h2>
                            </div>
                            <TextFilter identifier={"code"} setFilter={setFilter} state={filterState}/>
                        </div>
                    }
                </>
            </div>

            <div className={"filter-sidebar__section"}>
                {(props.advancedFilters.includes("protected")
                        || props.advancedFilters.includes("visible")
                        || props.advancedFilters.includes("favorite")) &&
                    <>
                        <h1 className={"filter-sidebar__section-heading"}>{t("Functie Instellingen")}</h1>
                        <div className={"checkbox-container"}>
                            {props.advancedFilters.includes("protected") &&
                                <CheckboxFilter label={t("Beschermd")} identifier={"protected"} setFilter={setFilter}
                                                state={filterState} trueOrString={true}/>
                            }
                            {props.advancedFilters.includes("visible") &&
                                <CheckboxFilter label={t("Onzichtbaar")} identifier={"visible"} setFilter={setFilter}
                                                state={filterState} falseOrString={true}/>
                            }
                            {props.advancedFilters.includes("favorite") &&
                                <CheckboxFilter label={t("Favoriet")} identifier={"favorite"} setFilter={setFilter}
                                                state={filterState} trueOrString={true}/>
                            }
                        </div>
                    </>
                }
            </div>

            <div className={"filter-sidebar__section"}>
                {props.advancedFilters.includes("user__in") &&
                    <>
                        <h1 className={"filter-sidebar__section-heading"}>{t("Betrokken personen")}</h1>
                        <div className={"filter-sidebar__input-container"}>
                            <AutocompleteUserDrawer
                                title={t("Selecteer betrokken persoon")}
                                label={t("Naam")}
                                filter={"user__in"}
                                filterState={filterState}
                                setFilter={setFilter}
                                resetAutocomplete={resetAutocomplete}
                            />
                        </div>
                        <>
                            {filterState["user__in"] &&
                                filterState["user__in"].map((userId) => {
                                    return (
                                        <div className={"filter-sidebar__added"} key={userId}>
                                            <span className={"filter-sidebar__added-user"}>
                                                <FullNameFromUserIdComponent userId={userId}/>
                                            </span>
                                            <ButtonSecondary className={"button--compact"} onClick={() => {
                                                const user_in = filterState["user__in"].filter(v => v !== userId)
                                                if(user_in.length > 0) {
                                                    setFilter("user__in", user_in)
                                                } else {
                                                    delete filterState["user__in"]
                                                    setResetAutocomplete(!resetAutocomplete)
                                                }
                                            }}>
                                                {t("Verwijderen")}
                                            </ButtonSecondary>
                                        </div>
                                    )
                                })
                            }
                            {filterState["user__in"] && filterState["user__in"].length > 1 &&
                                <div className={"filter-sidebar__multi-filter-actions"}>
                                    <ButtonSecondary onClick={() => {
                                            delete filterState["user__in"]
                                            setResetAutocomplete(!resetAutocomplete)
                                        }}
                                    >
                                        {t("Alles verwijderen")}
                                    </ButtonSecondary>
                                </div>
                            }
                        </>
                    </>
                }
            </div>

            <div className={"filter-sidebar__section"}>
                {props.advancedFilters.includes("describer") &&
                    <>
                        <h1 className={"filter-sidebar__section-heading"}>{t("Beschrijving")}</h1>
                        <div className={"filter-sidebar__input-container"}>
                            <AutocompleteUserDrawer
                                title={t("Selecteer Beschrijver")}
                                label={t("Beschrijver")}
                                filter={"describer"}
                                filterState={filterState}
                                setFilter={setFilter}
                                resetAutocomplete={resetAutocomplete}
                            />
                        </div>
                    </>
                }
            </div>

            <div className={"filter-sidebar__section"}>
                {(props.advancedFilters.includes("applier_one") || props.advancedFilters.includes("applier_two")) &&
                    <>
                        <h1 className={"filter-sidebar__section-heading"}>{t("Indeling")}</h1>
                        {props.advancedFilters.includes("applier_one") &&
                            <div className={"filter-sidebar__input-container"}>
                                <AutocompleteUserDrawer
                                    title={t("Selecteer Toepasser")}
                                    label={t("Toepasser")}
                                    filter={"applier_one"}
                                    filterState={filterState}
                                    setFilter={setFilter}
                                    resetAutocomplete={resetAutocomplete}
                                />
                            </div>
                        }
                        {props.advancedFilters.includes("applier_two") &&
                            <div className={"filter-sidebar__input-container"}>
                                <AutocompleteUserDrawer
                                    title={t("Selecteer Toetser")}
                                    label={t("Toetser")}
                                    filter={"applier_two"}
                                    filterState={filterState}
                                    setFilter={setFilter}
                                    resetAutocomplete={resetAutocomplete}
                                />
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}
