import axios from "axios";
import { useSWRInfinite } from "swr";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder} from "../utils/constants";

export function getProjectProjects(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.projects ? {
                id: fetchState?.identKey?.projects.join(',')
            } : {}
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index =>  fetchState?.identifier ? `/api/projects/${fetchState?.identKey?.project_id}/projects/?page=${index+1}&ordering=${ordering}${filtering}`: false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}
