import React from "react";
import {useAuth} from "../../utils/useAuth";
import {useTranslation} from "react-i18next";
import Quickview from "../Quickview";
import Overview from "../Overview";
import {DefaultTextOverviewDefinition} from "../Overviews/DefaultTextOverview";

export default function DefaultTextOverviewScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();


    return (
        <Quickview>

            <Overview
                identifier={"default-text-overview"}
                // identKey={}
                quickview={{
                    identifier: "default-text",
                    name: t("Standaard teksten")
                }}
                definition={{
                    ...DefaultTextOverviewDefinition,
                }}

            />
        </Quickview>
    )
}
