import React from "react";
import ProjectMeta from "../ProjectMeta";
import {Upload} from "../Upload";
import {getProject} from "../../queries/project_detail";
import {useParams} from "react-router-dom";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {withAccessRight} from "../../utils/withAccessHoC";


export function ProjectFileUploadBase(props) {

    const { project_id } = useParams();
    const {data: projectDetail, mutate} = getProject(project_id);

    if(!projectDetail) {
        return <LoaderAnimation/>
    }

    return (
        <div className={"content-container"}>
            <div className={"file-box"}>
                <Upload
                    edit={true}
                    object_id={projectDetail.id}
                    files={projectDetail?.project_files}
                    resourcetype={"ProjectFile"}
                    type={"attachment"}
                    mutate={() => mutate() }
                />
            </div>
        </div>
    )
}

export default withAccessRight(
    ProjectFileUploadBase,
    "base.change_projectfile",
    [
        ["project_id", "project"],
        ["client_id", "client"]
    ]
)

