import React, {useState} from "react";
import GroupPatternScore from "./GroupPatternScore";
import {IconButton} from "./IconButton";
import {DescriptionModal} from "../../components/Screens/Description";
import {useTranslation} from "react-i18next";
import LoaderAnimation from "../atoms/LoaderAnimation";
import ToolTip from "../../components/ToolTip";

export default function SidebarSummary(props) {

    const {t, i18n} = useTranslation();
    const [description, setDescription] = useState(false);

    return (
        <div className={"side-bar-summary"}>
            { description && (
                <DescriptionModal
                    function_id={props.functionDetail?.id}

                    description={description}
                    setDescription={setDescription}
                />
            )}
            <GroupPatternScore
                function_group={props?.functionDetail?.function_group}
                intermediate_function_group={props?.functionDetail?.intermediate_function_group}
                points={props?.functionDetail?.points}
                pattern_list={props?.functionDetail?.pattern_list}
                displayPoints={props?.displayPoints}
                type={props?.functionDetail?.type}
                comparison={false}
            />

            <div className={"side-bar-progress-bar"}>
                {props?.displayPoints && (
                    <div>{props.functionDetail?.function_group_info?.min_points||'-'}</div>
                )}
                <div className={"side-bar-progress-bar__fill"} style={{width: props.functionDetail?.function_group_info?.percentage + '%'}}>
                    {(props?.displayPoints && props.functionDetail?.function_group_info?.percentage > 10 && props.functionDetail?.function_group_info?.percentage < 90) && (
                        <div className={"side-bar-progress-bar__current"}>{props?.functionDetail?.points}</div>
                    )}
                </div>
                {props?.displayPoints && (
                    <div>{props.functionDetail?.function_group_info?.max_points||'-'}</div>
                )}
            </div>
        </div>

    )
}
