import GroupPatternScore from "./GroupPatternScore";
import IconButton from "./IconButton";
import cx from "classnames";
import React from "react";
import {useTranslation} from "react-i18next";
import OverviewName from "./OverviewName";
import {FunctionSource} from "../../utils/constants";
import FunctionTypeIndicator from "../atoms/FunctionTypeIndicator";
import LoaderAnimation from "../atoms/LoaderAnimation";
import ToolTip from "../../components/ToolTip";
import FunctionSimilarity from "../../components/FunctionSimilarity";

export default function SidebarFunction(props) {

    const {t, i18n} = useTranslation();
    const func =  props?.function || {
        id: false,
        type: 'unknown',
        name: t(""),
        pattern_list: [],
        version_number: "",
        project: {
            name: (<>&nbsp;</>)
        },
        function_group: "-",
        points: "-",
        displayPoints: props?.displayPoints,
        comparison: props?.comparison,
    };

    if(func.fake) {
        return <LoaderAnimation/>;
    }

    const FunctionSimilarityFun = () => <FunctionSimilarity {...func}
                                        contextData={{functionDetail: props.functionDetail}}/>

    return (
        <div key={func.id} className={"side-bar-function"}>
            <div className={"side-bar-function__header"}>
                <div className={"side-bar-function__type"}>
                    <FunctionTypeIndicator sizeModifier={"small"} type={func.type}/>
                </div>
                <div className={"side-bar-function__function-data-wrapper"}>
                    <div className={"side-bar-function__function-data"}>
                        <ToolTip tooltip={
                                <>
                                    {func.name ? (
                                        <>
                                            {func.name}
                                            <span className="version version--padded version--no-margin">V{func.version_number}</span>
                                        </>
                                    ) : (<span>&nbsp;</span>)}
                                </>
                            }
                        >
                            <span className={"side-bar-function__function-name"}>
                                {func.name ? (
                                    <>
                                        {/* Meh, OverviewName is not reusable due to styling issues (But would be nicer! because it handles stuff for us*/}
                                        {func.name}
                                        <span className="version version--padded version--no-margin">V{func.version_number}</span>
                                        {/*<OverviewName {...func} />*/}
                                    </>
                                ) : (<span>&nbsp;</span>)}
                            </span>
                        </ToolTip>
                        <ToolTip tooltip={<>{func?.client?.name  ? (<>{func?.client?.name} / </>):null}{func.project.name}</>}>
                            <span className={"side-bar-function__client-project"}>
                                {func?.client?.name  ? (<>{func?.client?.name} / </>):null}{func.project.name}
                            </span>
                        </ToolTip>
                    </div>

                    {func.is_collective_labour_agreement ?
                        (<div className={"side-bar-function__tag"}>
                            <span className={"overview-table__tag status-tag status-tag--cao"}>{t(FunctionSource['cao'].short)}</span>
                        </div>
                        ) : (
                        func.source && FunctionSource[func.source].short !== "" ?
                            (<div className={"side-bar-function__tag"}>
                                <span className={cx("overview-table__tag","status-tag", {["status-tag--" + FunctionSource[func.source].class]: FunctionSource[func.source].class})}>{t(FunctionSource[func.source].short)}</span>
                            </div>
                            ) : ''
                    )}
                </div>
            </div>
            <div className={"side-bar-function__body"}>
                {func.points && func.analytical_difference && func.analytical_distance && props.functionDetail?.points ?
                    <ToolTip component={ FunctionSimilarityFun}>
                        <GroupPatternScore
                            className={"side-bar-function__pattern"}
                            function_group={func.function_group}
                            intermediate_function_group={func.intermediate_function_group}
                            points={func.points}
                            pattern_list={func.pattern_list}
                            displayPoints={props?.displayPoints}
                            comparedPatternList={props?.comparedPatternList}
                            comparison={props?.comparison}
                            type={func.type}/>
                    </ToolTip> : <GroupPatternScore
                            className={"side-bar-function__pattern"}
                            function_group={func.function_group}
                            intermediate_function_group={func.intermediate_function_group}
                            points={func.points}
                            pattern_list={func.pattern_list}
                            displayPoints={props?.displayPoints}
                            comparedPatternList={props?.comparedPatternList}
                            comparison={props?.comparison}
                            type={func.type}/>
                }

                {props.children}

            </div>
        </div>
    )
}
