import React, {useEffect, useState} from "react";
import Group from "../atoms/Group";
import Overview from "../../components/Overview";
import {InfoButton} from "../molecules/InfoButton";
import {useDispatch} from "react-redux";
import Icon from "../atoms/Icon";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import LoaderAnimation from "../atoms/LoaderAnimation";
import {useAuth} from "../../utils/useAuth";
import ButtonQuaternary from "../atoms/ButtonQuaternary";
import Notification from "../molecules/Notification";
import FunctionTypeIndicator from "../atoms/FunctionTypeIndicator";
import GroupPatternScore from "../molecules/GroupPatternScore";
import Pattern from "../atoms/Pattern";
import Points from "../atoms/Points";
import {FunctionInfoIconButton} from "../../components/Overviews/FunctionInfoIconButton";
import {DescriptionModal} from "../../components/Screens/Description";
import {IconButton} from "../molecules/IconButton";
import FunctionGroupSelector from "../../components/FunctionGroupSelector";
import ToolTip from "../../components/ToolTip";
import FunctionSimilarity from "../../components/FunctionSimilarity";
import {generatePath, Link} from "react-router-dom";
import ButtonTertiary from "../atoms/ButtonTertiary";
import ConfirmModal from "./ConfirmModal";

export const RankingPatternState = {
    functionDetail: {
        groupAdvice: 2,
        nextGroup: 3,
        rank_percentage: 70,
        groupHistory: [
            {
                id: 1,
                from: 4,
                to: 5,
                modification_date: "2015-09-01T16:03:56+02:00",
                modified_by: {
                    name: 'Mark Prins',
                }
            },
            {
                id: 2,
                from: 5,
                to: 4,
                modification_date: "2015-06-01T16:03:56+02:00",
                modified_by: {
                    name: 'Mark Prins',
                }
            }
        ]
    },
    client_id: 153,
    project_id: 555,
};

export function RankingHistory(props) {

    const {t, i18n} = useTranslation();

    return (
        <>
            <div className={"popover__content"}>
                <h4 className={"popover__title"}>{t("Groepselectiegeschiedenis")}</h4>
            </div>
            <div className={"popover__content"}>
                <ul>
                    {props.items?.map((item, idx) => {
                        return (
                            <li key={idx} className={"group-history-item"}>
                                <div
                                    className={cx("pattern-group group-history-item__group", {"pattern-group--subtle": idx !== 0})}>
                                    {/* below we translate the stored group number to the (potential) label. The fallback is done because old db stored raw strings :( */}
                                    {props?.fgsDetail?.groups.find(o => o.number === item.function_group)?.label || item?.function_group || "-"}
                                </div>
                                <div>
                                    <div className={"group-history-item__user"}>
                                        {item.modified_by?.get_full_name || '-'}
                                    </div>
                                    <div className={"group-history-item__date"}>
                                        {item.modification_date ? new Intl.DateTimeFormat("nl-NL", {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric",
                                            hour: "numeric",
                                            minute: "numeric"
                                        }).format(new Date(item.modification_date)) : "-"}
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>

        </>
    )

}

export function GroupAdvice(props) {
    if(!props?.fgsDetail) {
        return <LoaderAnimation/>;
    }

    const {t} = useTranslation();
    const auth = useAuth();
    const canViewCompanyFunctionPoints = auth.hasPermission("base.view_company_function_points");
    const canViewReferenceFunctionPoints = auth.hasPermission("base.view_reference_function_points");
    const [isFunctionSimilarityShown, setIsFunctionSimilarityShown] = useState([]);
    const [description, setDescription] = useState(false);

    return (
        <div className={"tool"}>
            <div className={"tool-header tool-header--border"}>
                <h3 className={"tool-header__title"}>{t("Geselecteerde referentiefuncties")}</h3>
                {!props.summary &&
                    <InfoButton
                        icon={"history"}
                        title={t("Geschiedenis functiegroep")}
                        popOverClass={"popover popover--auto-size"}
                        sizeModifier={"medium"}
                        border={"true"}
                    >
                        <RankingHistory
                            fgsDetail={props.fgsDetail}
                            items={props.functionDetail?.function_group_changes}
                        />
                    </InfoButton>
                }
            </div>
            <div className={"function-ranking-group-indication"}>
                {props.functionDetail?.type !== 'match' && (
                    <div className={"function-ranking-group-indication__header"}>
                        <FunctionTypeIndicator className={"function-ranking-group-indication__type"}
                                               type={props?.functionDetail?.type}/>
                        <span className={"function-ranking-group-indication__name"}>{props.functionDetail?.name}</span>
                        <GroupPatternScore {...props.functionDetail}/>
                        <div className={"function-ranking-group-indication__progress-bar"}>
                            <div className={"function-ranking-group-indication__progress-bar-fill"}
                                 style={{width: props.functionDetail?.function_group_info?.percentage + '%'}}>
                            </div>
                            <div className={"function-ranking-group-indication__groups"}>
                                <div className={"function-ranking-group-indication__label"}>
                                    <Group group={props?.functionDetail?.function_group_info?.label}/>
                                </div>
                                <div className={"function-ranking-group-indication__label"}>
                                    <Group
                                        group={props.fgsDetail?.groups.find(o => o.number === (props?.functionDetail?.function_group_info?.number + 1))?.label}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {props.selectedReferenceFunctions &&
                <div className={"function-ranking-group-indication__table-header"}>
                    <div
                        className={"function-ranking-group-indication__column-title function-ranking-group-indication__column-title--type"}>Type
                    </div>
                    <div
                        className={"function-ranking-group-indication__column-title function-ranking-group-indication__column-title--name"}>Naam
                    </div>
                    <div className={"function-ranking-group-indication__column-title"}>
                        <div className={"pattern-filter-title__group"}>
                            Gr.
                        </div>
                        <div className={"pattern-filter-title__domain"}>
                            <div className={"pattern-filter-title__letter"}>
                                K
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                P
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                S
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                R
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                U
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                M
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                O
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                O
                            </div>
                            <div className={"pattern-filter-title__letter"}>
                                B
                            </div>
                        </div>
                        {(canViewReferenceFunctionPoints || canViewCompanyFunctionPoints) &&
                            <div className={"pattern-filter-title__points"}>
                                Punt.
                            </div>
                        }
                    </div>
                    <div className={"function-ranking-group-indication__progress-bar-column"}></div>

                    {(canViewReferenceFunctionPoints && canViewCompanyFunctionPoints) &&
                        <>
                            <div
                                className={"function-ranking-group-indication__column-title function-ranking-group-indication__column-title--similarity"}>Verschil
                            </div>
                            <div
                                className={"function-ranking-group-indication__column-title function-ranking-group-indication__column-title--similarity"}>Afstand
                            </div>
                        </>
                    }

                    <div
                        className={"function-ranking-group-indication__column-title function-ranking-group-indication__column-title--similarity"}>Overeenk.
                    </div>
                    <div className={"function-ranking-group-indication__buttons"}></div>
                </div>
            }

            {props.selectedReferenceFunctions?.map((referenceFunction) => {
                const FunctionSimilarityFun = () => <FunctionSimilarity {...referenceFunction}
                                                                        contextData={{functionDetail: props.functionDetail}}/>

                const canViewPoints = auth.hasPermission('base.view_reference_function_points') && auth.hasPermission('base.view_company_function_points');

                return (
                    <div className={"function-ranking-group-indication function-ranking-group-indication--reference"}
                         key={referenceFunction.id}>
                        <div className={"function-ranking-group-indication__header"}>
                            <FunctionTypeIndicator className={"function-ranking-group-indication__type"}
                                                   type={referenceFunction.type}/>
                            <Link
                                className={"function-ranking-group-indication__name function-ranking-group-indication__name--interactive"}
                                to={generatePath('/client/:client_id/project/:project_id/function/:function_id/description', {
                                    client_id: referenceFunction?.client?.id || 0,
                                    project_id: referenceFunction.project.id || 0,
                                    function_id: referenceFunction.id
                                })} data-cy={"selected-reference-function-name"}>
                                {referenceFunction.name}
                            </Link>
                            <GroupPatternScore {...referenceFunction}
                                               comparedPatternList={props.functionDetail?.pattern_list}
                                               comparison={true}
                            />
                            <div className={"function-ranking-group-indication__progress-bar-column"}></div>

                            {canViewPoints &&
                                <div
                                    onMouseEnter={() => setIsFunctionSimilarityShown(referenceFunction.id)}
                                    onMouseLeave={() => setIsFunctionSimilarityShown(null)}
                                >
                                    {referenceFunction.points && referenceFunction.analytical_difference && referenceFunction.analytical_distance && props.functionDetail?.points &&
                                        <ToolTip component={FunctionSimilarityFun}>
                                            <span
                                                className={cx("function-similarity-table-data function-ranking-group-indication__similarity", {"function-similarity-table-data--hover": isFunctionSimilarityShown === referenceFunction.id})}>
                                                {referenceFunction.analytical_difference}
                                            </span>
                                        </ToolTip>
                                    }
                                </div>
                            }
                            {canViewPoints &&
                                <div
                                    onMouseEnter={() => setIsFunctionSimilarityShown(referenceFunction.id)}
                                    onMouseLeave={() => setIsFunctionSimilarityShown(null)}
                                >
                                    {referenceFunction.points && referenceFunction.analytical_difference && referenceFunction.analytical_distance && props.functionDetail?.points &&
                                        <ToolTip component={FunctionSimilarityFun}>
                                            <span
                                                className={cx("function-similarity-table-data function-ranking-group-indication__similarity", {"function-similarity-table-data--hover": isFunctionSimilarityShown === referenceFunction.id})}>
                                                {referenceFunction.analytical_distance}
                                            </span>
                                        </ToolTip>
                                    }
                                </div>
                            }
                            {referenceFunction.distance_perc && (
                                <div className={"function-ranking-group-indication__similarity"}>
                                    <div className={cx("function-distance-indicator",
                                        {'function-distance-indicator--too-far': (referenceFunction.distance_perc <= 59)},
                                        {'function-distance-indicator--highest': (referenceFunction.distance_perc > 59 && referenceFunction.distance_perc <= 80)},
                                        {'function-distance-indicator--high': (referenceFunction.distance_perc > 80 && referenceFunction.distance_perc <= 90)},
                                        {'function-distance-indicator--medium': (referenceFunction.distance_perc > 90 && referenceFunction.distance_perc <= 94)},
                                        {'function-distance-indicator--low': (referenceFunction.distance_perc > 94 && referenceFunction.distance_perc <= 97)},
                                        {'function-distance-indicator--lowest': referenceFunction.distance_perc > 97}
                                    )}>
                                        <div className={"function-distance-indicator__fill"}
                                             style={{width: referenceFunction.distance_perc + '%'}}/>
                                    </div>
                                </div>
                            )}

                            {props?.contextData?.summary !== true && (
                                <div className={"function-ranking-group-indication__buttons"}>

                                    <div className={"icon-button-group"}>
                                        <ToolTip
                                            tooltip={referenceFunction.description_type !== 'normal' ? t("Geen beschrijving aanwezig") : t("Vergelijk beschrijving")}>
                                            <IconButton border={"true"}
                                                        disabled={referenceFunction.description_type !== 'normal'}
                                                        icon={"compare-description"} sizeModifier={"medium"}
                                                        onClick={() => setDescription(referenceFunction.id)}
                                            />
                                        </ToolTip>

                                        {description === referenceFunction.id && (
                                            <DescriptionModal
                                                function_id={props?.functionDetail?.id}
                                                function_id_two={referenceFunction.id}
                                                comparison={referenceFunction.type !== "match"}

                                                description={description}
                                                setDescription={setDescription}
                                            />
                                        )}

                                        <FunctionInfoIconButton
                                            functionInfo={referenceFunction}
                                            border={"true"}
                                        />
                                    </div>

                                    {props.edit &&
                                        <ToolTip tooltip={t("Referentiefunctie verwijderen")}>
                                            <IconButton border={"true"}
                                                        icon={"close"}
                                                        sizeModifier={"medium"}
                                                        onClick={() => props.removeFunction(referenceFunction.id)}
                                            />
                                        </ToolTip>
                                    }

                                </div>
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default function RankingDisplay(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    let {client_id, project_id, function_id} = props;
    const [functionGroup, setFunctionGroup] = useState(props.functionDetail?.function_group || props?.functionDetail?.function_group_info?.number || props.fgsDetail?.groups?.[0].number);
    const [displayModal, setDisplayModal] = useState(false);
    const [displayGroupComparisonModal, setDisplayGroupComparisonModal] = useState(false);

    const dispatch = useDispatch();

    const canViewPoints = auth.hasPermission('base.view_reference_function_points', parseInt(project_id), 'project');

    const motivationContent = (content) => {

        return {
            __html: content
        }
    }

    useEffect(() => {
        if(props.functionDetail?.pattern_match_function && !props.functionDetail?.pattern_match_function_linked && props.edit) {
            setDisplayModal(true);
        }
    }, [props.functionDetail?.pattern_match_function, props.functionDetail?.pattern_match_function_linked, props.edit])

    return (
        <>
            {props.functionDetail?.pattern_match_function &&
                <div className={"temp-ranking-notification"}>
                    <Notification text={t("Er is een functie gevonden met een overeenkomstig patroon. De voorgestelde groep is op basis van deze functie aangepast.")}/>
                </div>
            }
            <div className={"function-ranking"}>

                {displayModal &&
                    <ConfirmModal
                        key={'pattern-match'}
                        title={t("Referentiefunctie gevonden")}
                        confirmModal={() => {
                            props.selectFunction(props.functionDetail?.pattern_match_function?.id)
                        }}
                        closeModal={() => setDisplayModal(false)}>
                        <p className="paragraph">
                            {t(`Er is een overeenkomende referentiefunctie gevonden met de naam "${props.functionDetail?.pattern_match_function?.name}".`)}
                        </p>
                        <p className="paragraph">
                            {t("Wilt u deze toevoegen?")}
                        </p>
                    </ConfirmModal>
                }
                <div className={"function-ranking-group"}>
                    <h3 className={"function-ranking-group__header"}>{t("Functiegroep")}</h3>
                    {props.functionDetail?.type !== "match" &&
                        <div className={"function-ranking-group__content"}>
                            <div className={"function-ranking-group__proposed"}>
                                <span>Voorgestelde functiegroep: </span>
                                <span
                                    className={"pattern-group"}>{props?.functionDetail?.function_group_info?.label ? props?.functionDetail?.function_group_info?.label : "-"}</span>
                            </div>
                            <p className={"function-ranking-group__proposed-explanation"}>
                                Het voorstel is gebaseerd op de opgegeven waardering en de bij FUWA Online bekende
                                overeenkomstige functies.
                            </p>
                        </div>
                    }

                    <div className={"function-ranking-group__content"}>
                        {!props.functionDetail?.function_group &&
                            <Notification type={"warning"} sizeModifier={"small"}
                                          text={t("Er is nog geen functiegroep bevestigd")}/>
                        }

                        {props.functionDetail?.function_group ?
                            <div className={"function-ranking-group__proposed"}>
                                <span>Bevestigde functiegroep: </span>
                                <span className={"pattern-group"}>{props.functionDetail?.function_group_label}</span>
                            </div> :
                            <div className={"function-ranking-group__proposed"}>
                                <span>Bevestigde functiegroep: </span>
                                <span className={"function-ranking-group__not-confirmed"}>Nog niet bevestigd</span>
                            </div>
                        }

                        {props?.edit && (
                            <>

                                {props.functionDetail?.function_group ?
                                    <span className={"function-ranking-group__confirm"}>
                                        Bevestigde functiegroep aanpassen:
                                    </span>
                                    :
                                    <span className={"function-ranking-group__confirm"}>
                                        Kies een groep om de functie in te delen:
                                    </span>
                                }
                                <div className={"function-ranking-group__buttons"}>
                                    <div className={"select-container"}>
                                        <>
                                            <select value={functionGroup}
                                                    onChange={(e) => {
                                                        props.setGroupFilter(e.target.value)
                                                        setFunctionGroup(e.target.value)
                                                    }}>
                                                {props.fgsDetail?.groups.map(group => {
                                                    return (
                                                        <option key={group.number} value={group.number}>
                                                            {props?.functionDetail?.function_group_info?.number === group.number ?
                                                                `${t("Groep")} ${group.label} (${t("Voorstel")})`
                                                                :
                                                                `${t("Groep")} ${group.label}`
                                                            }
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                            <Icon icon={"angle-down"} sizeModifier={"small"}
                                                  className={"select-container__icon"}/>
                                        </>
                                    </div>
                                    {displayGroupComparisonModal &&
                                        <ConfirmModal
                                            key={'group-comparison'}
                                            title={t("Gekozen functiegroep wijkt af")}
                                            confirmModal={() => {
                                                props.setFunctionGroup(props.functionDetail?.id, functionGroup)
                                            }}
                                            closeModal={() => setDisplayGroupComparisonModal(false)}
                                        >
                                            <p className={"paragraph"}>
                                                {t(`De gekozen functiegroep is ${functionGroup}, de voorgestelde functiegroep is ${props?.functionDetail?.function_group_info?.label}.`)}
                                            </p>
                                            <p className={"paragraph"}>
                                                {t(`Weet u zeker dat u van de voorgestelde functiegroep wilt afwijken?`)}
                                            </p>
                                        </ConfirmModal>
                                    }
                                    <ButtonTertiary text={"Bevestig"}
                                                      icon={"check"}
                                                      onClick={() => {
                                                          if(functionGroup != props?.functionDetail?.function_group_info?.label){
                                                              if(props.functionDetail?.type !== "match"){
                                                                  setDisplayGroupComparisonModal(true)
                                                              } else {
                                                                  props.setFunctionGroup(props.functionDetail?.id, functionGroup)
                                                              }
                                                          } else {
                                                              props.setFunctionGroup(props.functionDetail?.id, functionGroup)
                                                          }
                                                      }
                                    }
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={"function-ranking-compare"}>
                    <GroupAdvice
                        edit={props.edit}
                        functionDetail={props.functionDetail}
                        fgsDetail={props.fgsDetail}
                        setFunctionGroup={props.setFunctionGroup}
                        selectedReferenceFunctions={props.selectedReferenceFunctions}
                        removeFunction={props.removeFunction}
                    />
                </div>
            </div>

            <Overview
                identifier={"ranking-reference-function-overview"}
                identKey={{client_id, project_id, function_id, match: props?.functionDetail?.type === 'match'}}
                definition={props.overviewDefinition}
                contextData={{
                    edit: props?.edit,
                    function_id: props?.functionDetail?.id,
                    functionDetail: props?.functionDetail,
                    referenceFunctionCount: props?.functionDetail?.reference_functions?.length || 0,
                    referenceFunctions: props?.functionDetail?.reference_functions,
                    distance: props?.functionDetail?.type !== 'match',
                    comparison: props?.functionDetail?.type !== 'match',
                    patternList: props?.functionDetail?.pattern_list,
                    removeFunction: props?.removeFunction,
                    selectFunction: props?.selectFunction,
                    fgsDetail: props?.fgsDetail,
                    canViewPoints,
                }}
            >
                <FunctionGroupSelector
                    functionDetail={props?.functionDetail}
                    selectedGroup={props?.selectedGroup}
                    setGroupFilter={props?.setGroupFilter}
                    fgsDetail={props?.fgsDetail}
                />
            </Overview>
        </>
    )

}
