import {useTranslation} from "react-i18next";
import {useDropzone} from "react-dropzone";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Icon from "../patterns/atoms/Icon";
import cx from "classnames";
import LoaderAnimation from "../patterns/atoms/LoaderAnimation";
import ButtonPrimary from "../patterns/atoms/ButtonPrimary";
import {useAuth} from "../utils/useAuth";
import {checkAccessList} from "../utils/withAccessHoC";
import ConfirmModal from "../patterns/organisms/ConfirmModal";
import ButtonTertiary from "../patterns/atoms/ButtonTertiary";

export function Upload(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const {acceptedFiles, getRootProps, getInputProps, isDragActive} = useDropzone();
    const fileContextInfo = {
        resourcetype: props?.resourcetype || 'FunctionFile',
        type: props?.type || 'description_file',
        object_id: props?.object_id || 0,
    }

    const [ files, setFiles ] = useState([]);
    const [ confirmDelete, setConfirmDelete] = useState(false);

    // const canEdit = auth.hasPermission('base.change_file', [['project_id', 'project']]);
    const canEdit = auth.checkAccessList('base.change_file', [
        ['project_id', 'project'],
        ['client_id', 'client']
    ]);

    useEffect(() => {
        if(props?.files?.length >= 0) {
            let newFiles = {}

            props.files.map( file => {
                newFiles[file.id]  = {
                    name: file.filename,
                    id: file.id,
                    status: "complete",
                    size: file.size,
                    path: file.file,
                    object: file,
                };
            });
            setFiles(newFiles);
        }

    }, [props?.files?.length])

    useEffect(async () => {

        await acceptedFiles.map( async (file, idx) => {

            setFiles( curState => {
                return {
                    ...curState,
                    [file.name]: {
                        name: file.name,
                        status: "start",
                        size: file.size,
                        object: file,
                        id: file.name,
                    }
                }
            });

            let formData = new FormData();

            formData.append('resourcetype', fileContextInfo.resourcetype);
            formData.append('object_id', fileContextInfo.object_id);
            formData.append('type', fileContextInfo.type);
            formData.append('file', file);

            await axios.post(`/api/files/`, formData, {
                'content-type': 'multipart/form-data'
            }).then( result => {
                setFiles( curState => {
                    let tmpState = {
                        ...curState,
                        [result?.data?.id]: {
                            ...curState[file.name],
                            id: result?.data?.id,
                            object: result?.data,
                            status: "complete",
                        }
                    }
                    delete tmpState[file.name];
                    return tmpState;
                });

            })
        });
        props.mutate();

    }, [acceptedFiles?.length])

    const handleDownload = (file) => {
        axios.get(file.path, {
            responseType: "arraybuffer",
            headers: {"Content-Type": "application/json"}
        }).then((res) => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data], {type: type, encoding: 'UTF-8'})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = file.name
            link.click()
        })
    }

    return (
        <>
            <h3 className={"file-box__heading"}>{t("Bestanden")}</h3>
            <ul className={"file-list"}>
                {Object.entries(files).map( obj => {
                    const [key, file] = obj;

                    return (
                        <li key={`${file.id}`}>
                            <div className={"file-list__item"} onClick={(() => handleDownload(file))}>
                                <div className={"file-list__primary"}>
                                    <div className={"file-list__filename"}>
                                        {file.name}
                                    </div>
                                    {file?.size && (
                                        <div className={"file-list__filesize"}>
                                            {file.size} bytes
                                        </div>
                                    )}
                                </div>

                                <div className={"file-list__secondary"}>
                                    {
                                        file.status === "complete" ? (
                                            <Icon
                                                icon={cx({
                                                    "download": file.status === "complete",
                                                    "arrow-up": file.status === "start",
                                                })} sizeModifier={"small"}/>
                                        ) : (
                                            <LoaderAnimation small={true}/>
                                        )
                                    }
                                </div>

                                {props?.edit && canEdit && (
                                    <button className={"file-list__remove"}
                                            onClick={ e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                setConfirmDelete(file);
                                            }}
                                    >
                                        <Icon icon={"close"} sizeModifier={"xsmall"}/>
                                    </button>
                                )}
                            </div>
                        </li>

                    )
                })}
                {confirmDelete && (
                    <ConfirmModal
                        key={'default-text'}
                        title={t("Verwijder bestand")}
                        body={t("Weet je zeker dat je het bestand met onderstaande naam wilt verwijderen?")}
                        subject={confirmDelete.name}
                        confirmModal={ async () => {
                            await axios.delete(`/api/files/${confirmDelete.id}/?resourcetype=${fileContextInfo.resourcetype}`);
                            setFiles(curState => {
                                let tmpState = {
                                    ...curState,
                                }
                                delete tmpState[confirmDelete.id];
                                return tmpState;
                            })
                            if(props?.mutate) {
                                props.mutate();
                            }
                        }}
                        closeModal={() => setConfirmDelete(false)} />
                )}
            </ul>
            {Object.entries(files).length === 0 && (
                <p className={"paragraph paragraph--subtle"}>
                    {t("Er zijn nog geen bestanden toegevoegd")}
                </p>
            )}

            {props?.edit && canEdit && (

                <div {...getRootProps()}>
                    <div className={cx("file-upload", {"file-upload--dragging": isDragActive})}>
                        <input {...getInputProps()} />
                        <Icon
                            className={"file-upload__icon"}
                            icon={cx({
                                "success": isDragActive,
                                "file": !isDragActive
                            })} sizeModifier={"small"}/>
                        <p className={"file-upload__description"}>
                            {
                                isDragActive ? (
                                    <>
                                        {t("U kunt het bestand loslaten")}
                                    </>
                                ) : (
                                    <>
                                        {t("Sleep uw bestand hier naartoe of klik om te zoeken")}
                                    </>
                                )
                            }
                        </p>
                        <ButtonTertiary fullWidth={"true"}>{t("Bestand zoeken")}</ButtonTertiary>
                    </div>
                </div>
            )}

        </>
    );
}

