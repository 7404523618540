import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import ButtonPrimary from "../atoms/ButtonPrimary";
import cx from "classnames";
import Overview from "../../components/Overview";
import {ClientSelectionDefinition} from "../../components/Overviews/ClientSelection";
import {withAccessRight} from "../../utils/withAccessHoC";
import Notification from "../molecules/Notification";
import {Controller, useForm} from "react-hook-form";
import {CheckboxInput, Input} from "../atoms/Input";
import Drawer from "../../components/Drawer";
import {useNavigate, useParams} from "react-router-dom";
import {editUser, getUser} from "../../queries/user";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const UserEditPatternState = {
    shouldPrompt: false,
    onSubmit: async () => {
        await sleep(1000);
        return false
    }, // for the Form
    handleSubmit: () => {
        return false
    }, // for the form
    form: {
        reset: () => {
            return false
        }
    }
}

function UserEditDisplay() {
    const {t} = useTranslation();
    const {user_id} = useParams();
    const user = getUser(parseInt(user_id)).data;
    const navigate = useNavigate();

    const {register, handleSubmit, setError, clearErrors, setValue, control, formState: {errors}} = useForm();

    const onSubmit = () => async (values) => {
        await editUser(values, navigate, user_id);
    }

    useEffect(() => {
        if (user) {
            setValue("client_id", user.client.id);
            setValue("is_active", user.is_active);
            setValue("first_name", user.first_name);
            setValue("insertion", user.insertion);
            setValue("last_name", user.last_name);
            setValue("email", user.email);
        }
    }, [user])

    return (
        <>
            <h1 className={"form-container__title"}>{t("Gebruiker bewerken")}</h1>
            <form onSubmit={(e) => {
                clearErrors(["server", "unique"]);

                handleSubmit(onSubmit())(e).catch((error) => {
                    if (error?.cause?.data) {
                        Object.entries(error.cause.data).forEach(([field, messages]) => {
                            if (Array.isArray(messages)) {
                                messages.forEach((message) => {
                                    setError(field, {message: message})
                                })
                            }
                        })
                    }

                    if (error.message === "server-error-user-edit" || error.message === "server-error") {
                        setError("server", {
                            type: "server",
                            message: t("Er is iets verkeerd gegaan.")
                        })
                    }
                })
            }}>
                <fieldset className={"form-container__fieldset"}>
                    <div className="input-group">
                        <Controller name={"client_id"} control={control}
                                    rules={{required: t("Dit is een verplicht veld")}}
                                    render={({field: {onChange}}) => (
                                        <Drawer
                                            title={t("Selecteer Klant")}
                                            buttonText={t("Zoek klant")}
                                            label={t("Klant")}
                                            initialReadOnlyVal={user?.client?.name}
                                            readOnlyVal={obj => obj.name}
                                            placeholder={t("Geen klant gekozen")}
                                            disabled={false}
                                            isForm={true}
                                            id={"client-drawer"}
                                            required
                                            error={errors.client_id}

                                            reset={() => {
                                                onChange(null)
                                            }}

                                            onClick={(obj) => {
                                                onChange(obj.id)
                                            }}

                                            contentComponent={
                                                <Overview
                                                    identifier={"select-client-overview"}
                                                    identKey={{
                                                        client_id: user?.client?.id || 0,
                                                    }}
                                                    contextData={{
                                                        location,
                                                    }}
                                                    definition={ClientSelectionDefinition}
                                                />
                                            }
                                        />)}
                        />
                    </div>
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <legend className={"form-container__legend"}>
                        {t("Algemene gegevens")} &nbsp;
                        <span className={cx("meta-info__status-tag", {
                            "status-tag status-tag--active": user?.is_active,
                            "status-tag status-tag--negative": !user?.is_active
                        })} data-cy={"status-active"}>{user?.is_active ? t("Actief") : t("Inactief")}</span>
                    </legend>
                    {user?.username && (
                        <div className="input-group">
                            <label className={"label"}>{t("Gebruikersnaam in het oude FUWA Online")}</label>
                            <div className="input-group">
                                <input type={"text"} readOnly={true} value={user?.username}/>
                            </div>
                        </div>
                    )}
                    <div className="input-group">
                        <CheckboxInput
                            name={"is_active"}
                            type={"checkbox"}
                            id={"is_active"}
                            label={t("Gebruiker is actief")}
                            helpTitle={t("Wat betekent actief?")}
                            helpText={t(
                                "Wanneer een gebruiker naar \"inactief\" wordt gezet zal deze per direct " +
                                "geen toegang meer hebben tot het systeem. Wanneer je een gebruiker op " +
                                "actief zet zal er een nieuw wachtwoord worden aangemaakt en worden zijn " +
                                "gegevens per mail toegestuurd. Let Op: Zowel het e-mailadres en de klant " +
                                "moeten ingevuld zijn!"
                            )}
                            register={register}
                            error={errors.is_active}
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"first_name"}
                            type={"text"}
                            id={"first_name"}
                            label={t("Voornaam")}
                            placeholder={t("Voornaam")}
                            data-cy={"first-name"}
                            register={register}
                            error={errors.first_name}
                            required
                        />
                    </div>

                    <div className="input-group">
                        <Input
                            name={"insertion"}
                            type={"text"}
                            id={"insertion"}
                            label={t("Tussenvoegsel")}
                            placeholder={t("Tussenvoegsel")}
                            allowNull={true}
                            parse={value => (value === "" ? null : value)}
                            data-cy={"insertion"}
                            register={register}
                            error={errors.insertion}
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"last_name"}
                            type={"text"}
                            id={"last_name"}
                            label={t("Achternaam")}
                            placeholder={t("Achternaam")}
                            data-cy={"last-name"}
                            register={register}
                            error={errors.last_name}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <Input
                            name={"email"}
                            type={"text"}
                            id={"email"}
                            label={t("Emailadres")}
                            placeholder={t("Emailadres")}
                            data-cy={"email"}
                            register={register}
                            error={errors.email}
                            required
                        />
                    </div>

                    {errors.server && <Notification data-cy={"server-error"}
                                                    type={"error"}
                                                    text={errors.server.message}
                                                    className={"form-container__error"}/>}
                    {Object.values(errors).find((error) => error.type === "required") &&
                        <Notification className={"form-container__error"} type={"error"}
                                      data-cy={"required-errors-summary"}
                                      text={t("Niet alle verplichte velden zijn ingevuld. Vul deze in om verder te kunnen.")}/>
                    }
                </fieldset>

                <fieldset className={"form-container__fieldset"}>
                    <ButtonPrimary
                        text={t("Bewerken")}
                        fullWidth={true}
                        data-cy={"user-edit-submit-button"}
                    />
                </fieldset>
            </form>
        </>
    )
}

export const UserEdit = withAccessRight(
    UserEditDisplay,
    'base.change_authuser',
    'client_id',
    'client'
);
