import useSWR, { useSWRInfinite } from "swr";
import {buildFilter, buildOrder, loadIfAllowed} from "../utils/constants";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import axios from "axios";

export function getProjectGroups(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
            fetchState?.identKey?.client_id ? {
                client__id: fetchState?.identKey?.client_id
            } : {},
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => fetchState?.identifier ? `/api/projectgroups/?page=${index+1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}`: false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export function getProjectGroup(pk=false) {

    let url = `/api/projectgroups/${pk}/`;

    const { data } = useSWR(() => pk ? url : false);

    return {
        data: data,
    }
}


export async function remoteAddProjectGroup(name, sharing_method) {
    return await axios.post('/api/projectgroups/', {
        name,
        sharing_method,
    }).then(result => result);
}

export async function remoteAddProjectsToGroup(project_group_id, projects) {
    return await axios.patch(`/api/projectgroups/${project_group_id}/add_projects/`, {
        projects,
    });
}

export async function remoteRemoveProjectFromGroup(project_group_id, projects) {
    return await axios.patch(`/api/projectgroups/${project_group_id}/remove_projects/`, {
        projects,
    });
}
