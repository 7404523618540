import {getFunction} from "../../queries/function_detail";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import React from "react";
import Modal from "react-modal";
import IconButton from "../../patterns/molecules/IconButton";
import GroupPatternScore from "../../patterns/molecules/GroupPatternScore";
import FunctionTypeIndicator from "../../patterns/atoms/FunctionTypeIndicator";
import {useTranslation} from "react-i18next";

function descriptionHtmlContent(item) {
    return {
        __html: item
    }
}

export function DescriptionModal(props) {
    const {t, i18n} = useTranslation();
    const functionDetail =  getFunction(props.function_id);

    return (
        <Modal isOpen={props.description !== false} className={"modal modal--full-screen"} overlayClassName={"modal-background"} bodyOpenClassName={"has-modal"}>
            <div className={"modal-header"}>
                <div className={"modal-header__top"}>
                    <h2 className={"modal-header__title"}>{props.function_id_two && t("Vergelijk ")}{t("Beschrijving")}</h2>
                    <ul className={"modal-header__controls"}>
                        <li className={"modal-header__control"}>
                            <IconButton icon={"close"} onClick={() => props.setDescription(false)}/>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="description-compare">
                <Description
                    function_id={props.function_id}
                />
                {props.function_id_two && (

                    <Description
                        function_id={props.function_id_two}
                        comparedPatternList={functionDetail?.data?.pattern_list}
                        comparison={props?.comparison !== false}
                    />

                )}
            </div>
        </Modal>
    )
}

export function Description(props) {
    const {t, i18n} = useTranslation();
    const functionDetail = getFunction(parseInt(props.function_id));

    if(functionDetail.isLoading) {
        return (
            <LoaderAnimation />
        )
    }

   return (
      <>
          <div className={"description-compare__column"}>
              {/*TODO: below, the header is collection of various html... it feels cluthered and messy*/}
              <div className={"description-compare__header"}>
                  <div className={"side-bar-function__tags"}>
                      <FunctionTypeIndicator sizeModifier={"small"} type={functionDetail?.data.type}/>
                  </div>
                  <span className={"side-bar-function__project"}>{functionDetail?.data.project.name} {functionDetail?.data.is_collective_labour_agreement ? (<span className={"overview-table__tag status-tag"}>{t("CAO")}</span>): ''}</span>
                  <h3 className={"description-compare__function-title"}>{functionDetail?.data?.name} v{functionDetail?.data?.version_number}</h3>
                  <GroupPatternScore  {...functionDetail?.data} comparison={props?.comparison === true} comparedPatternList={props?.comparedPatternList}/>
              </div>
              <div className={"description-compare__body"}>
                  {functionDetail.data.descriptions.length === 0 && (
                      <div className="description-compare__item">
                          <h3 className={"description-editor__title"}>{t("Geen beschrijving aanwezig")}</h3>
                          {functionDetail?.data?.description_explanation  && (
                              <p className={"paragraph"}>
                                  <strong>{t("Opgegeven reden")}:</strong><br/>
                                  {functionDetail?.data?.description_explanation}
                              </p>
                          )}
                      </div>
                  )}
                  {functionDetail.data.descriptions.map((item, idx) => {
                      return (
                          <div className={"description-compare__item"} key={idx + '-' + item.title}>
                              <h3 className={"description-editor__title"}>{item.title}</h3>
                              <div className="description-editor__rich-text">
                                  <div dangerouslySetInnerHTML={descriptionHtmlContent(item.content)}/>
                              </div>
                          </div>
                      )
                  })}
              </div>
          </div>
      </>
   )
}
