import axios from "axios";
import useSWR, {mutate, useSWRInfinite} from "swr";
import React from "react";
import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder} from "../utils/constants";

// Get the actual function description (if it was prefetched, the prefetched data is used)
export function getFunctionDescription(id) {

    let url = `/api/functiondescriptions/${id}/`;

    const {data, error, mutate}  = useSWR(() => id ? url: false);


    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}


// Send a request to the server to update the functiondescription
export async function remoteSetFunctionDescription(id, data) {

    // if(id === null) {
    //
    //     // return await axios.post(`/api/functiondescriptions/`, {
    //     //     function_id: 57487,
    //     //     title: data.title,
    //     //     order: data.order,
    //     //     content: data.content
    //     // }).then(result => {
    //     //     return result;
    //     // });
    // }
    // console.log(id,data);
    // if(!id) {
        // apparently we are new
    // }

    return await axios.patch(`/api/functiondescriptions/${id}/`, {
        title: data.title,
        order: data.order,
        content: data.content,
    }).then(result => {
        return result;
    });
}


export async function addDescription(functionId, title='', content='', tags=[], order=99) {
    return await  axios.post('/api/functiondescriptions/', {
        function_id: functionId,
        order,
        title,
        content,
        tags,
    }).then (result => {
        return result;
    });
}


export async function deleteDescription(id) {
    await axios.delete(`/api/functiondescriptions/${id}/`).then(res => res);

}

export async function createFunctionDescriptionTemplate(title, content, tags=[], order=0) {
    return await axios.post('/api/functiondescriptiontemplates/', {
        title,
        content,
        tags,
        order,
        resourcetype: "DefaultFunctionDescriptionTemplate"
    }).then(result => {
        return result;
    })
}


export function getFunctionDescriptionTemplates(stateToDebounce) {

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => `/api/functiondescriptiontemplates/?resourcetype=DefaultFunctionDescriptionTemplate&page=${index + 1}&ordering=${ordering}${filtering}`,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export async function remoteSetFunctionDescriptionTemplateTitleContent(id, title, content) {
    await axios.patch(`/api/functiondescriptiontemplates/${id}/?resourcetype=DefaultFunctionDescriptionTemplate`, {
        title,
        content,
        resourcetype:  "DefaultFunctionDescriptionTemplate",
    })
}

export async function remoteSetProjectFunctionDescriptionTemplate(id, title, order) {
    await axios.patch(`/api/functiondescriptiontemplates/${id}/?resourcetype=ProjectFunctionDescriptionTemplate`, {
        resourcetype:  "ProjectFunctionDescriptionTemplate",
        title,
        order,
    })


}

export async function remoteAddProjectFunctionDescriptionTemplate(project_id, title='', order=99) {
    await axios.post(`/api/functiondescriptiontemplates/?resourcetype=ProjectFunctionDescriptionTemplate`, {
        resourcetype:  "ProjectFunctionDescriptionTemplate",
        project_id,
        title,
        order,
    })
}

export async function remoteDelProjectFunctionDescriptionTemplate(id) {
    await axios.delete(`/api/functiondescriptiontemplates/${id}/?resourcetype=ProjectFunctionDescriptionTemplate`);
}
