import React, {useState, useEffect, Fragment} from "react";
import {getFunction} from "../../queries/function_detail";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {PreloadAndDisplay} from "../../patterns/organisms/SelectedFunctions";
import {FunctionInfoIconButton} from "./FunctionInfoIconButton";
import IconButton from "../../patterns/molecules/IconButton";
import {DescriptionModal} from "../Screens/Description";
import {useTranslation} from "react-i18next";
import {getComparableFunctions} from "../../queries/client_project_functions";
import {SidebarOverview} from "../ScoringSidebar";
import {getFunctionDescriptionTemplates} from "../../queries/function_description";
import {TagEditor} from "../Tags";
import {TextFilter} from "./Filters/TextFilter";
import {Icon} from "../../patterns/atoms/Icon";



export function Description(props) {

    const {t, i18n} = useTranslation();

    function descriptionHtmlContent(item) {
        return {
            __html: item
        }
    }
    if(props.fake || (!props.item?.content && !props.item?.title)) {
        return <LoaderAnimation/>;
    }

    return (
        <div key={props.item.id} className={"side-drawer-block"}>
            <div className={"side-drawer-block__section"}>
                <h3 className={"description-editor__title"}>{props.item.title}</h3>
                {props.item?.content ? (
                    <div className="description-editor__rich-text">
                        <div dangerouslySetInnerHTML={descriptionHtmlContent(props.item.content)}/>
                    </div>
                ) : (
                    <>{t("Onderdeel bevat geen tekst")}</>
                )}
            </div>
            <div className={"side-drawer-block__section"}>
                <TagEditor
                    objectId={props.item.id}
                    objectType={props?.objectType}
                    edit={false}
                />

                <div className={"side-drawer-block__buttons"}>
                    <button
                        onClick={(e) => {
                            props.onClick(props.item);
                        }}
                        className={"button button--tertiary"}>
                        {t("Voeg beschrijving toe")}
                    </button>
                </div>
            </div>
        </div>

    )
}

export function DescriptionSelection(props) {

    const {t, i18n} = useTranslation();
    const functionDetail = ('getFunction' in props) ?  props.getFunction() : getFunction(props.function_id);


    if(!functionDetail?.data?.descriptions) {
        return <LoaderAnimation/>;
    }

    if(functionDetail.data.descriptions.length === 0) {
        return (
            <>
                {t("Geen beschrijving aanwezig")}
            </>
        )
    }

    return (
        <div className={"side-drawer-blocks"}>
            {functionDetail?.data?.descriptions.map((item) => {
                return (
                    <Description
                        key={item.id}
                        onClick={props.onClick}
                        item={item}
                        objectType={"FunctionDescription"}
                        />
                )

            })}
        </div>
    )
}

export default function SidebarDescriptionSelection(props) {

    const {t, i18n} = useTranslation();
    const [ description, setDescription] = useState(false);

    return (
        <>
            <div className={"side-drawer-function"}>
                <PreloadAndDisplay
                    comparison={false}
                    id={props.function_id_two}>

                    <div className={"side-bar-function__buttons"}>

                        <FunctionInfoIconButton
                            className={"side-bar-function__info"}
                            functionInfo={props.function_id_two}
                        />

                        <IconButton className={"side-bar-function__button"} icon={"compare-description"} sizeModifier={"medium"}
                                    onClick={() => setDescription(true)}
                        />
                        { description && (

                            <DescriptionModal
                                function_id={props.function_id}
                                function_id_two={props.function_id_two}

                                setDescription={setDescription}
                            />
                        )}
                    </div>
                </PreloadAndDisplay>
            </div>
            <DescriptionSelection
                onClick={props.onClick}
                function_id={props.function_id_two}
            />
        </>
    )

}

function SidebarDefaultDescriptionSelectionOverview(props) {

    console.log(props);

    const {t, i18n} = useTranslation();
    const [description, setDescription] = useState(false);

    return (
        <>
            <div className={"side-drawer-search"}>
                <TextFilter
                    state={props.filterState}
                    identifier={"search"}
                    placeholder={t("Deel van de titel of tag")}
                    setFilter={props.setFilter}
                />
                <Icon icon={"search-list"} sizeModifier={"small"} className={"side-drawer-search__icon"}/>
            </div>
            <div className={"side-drawer-blocks"}>
                {props?.overview?.data?.map( (page, idx) => {
                    return (
                        <Fragment key={idx}>
                            {page.results.map( (item, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <Description
                                            onClick={props.onClick}
                                            item={item}
                                            objectType={"DefaultFunctionDescriptionTemplate"}
                                        />
                                    </Fragment>
                                )
                            })}
                        </Fragment>
                    )
                })}
                {props.loadMore && (
                    <p className={"text-center paragraph"}>
                        <button className={"button button--tertiary"} onClick={() => props.setSize(props.overview.size + 1)}>{t("Toon meer resultaten")}</button>
                    </p>
                )}
            </div>
        </>
    )
}

export const SidebarDefaultDescriptionSelection = {

    identifier: 'sidebar-default-descriptions',
    queryFn: getFunctionDescriptionTemplates,
    displayOverride: SidebarDefaultDescriptionSelectionOverview,
    defaultSize: 0,

}
