import React from "react";
import {TextFilter} from "./Filters/TextFilter";
import {getMethodOverview, getMethodDetailOverview} from "../../queries/method_detail";
import {generatePath} from "react-router-dom";
import i18n from "../../utils/i18n";

export const MethodOverviewDefinition = {
    title: i18n.t("Methoden"),
    identifier: 'client-overview',
    queryFn: getMethodOverview,
    loadAllAllowed: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'name',
    RowStructure: (props) => {

        // console.log(props);

        return (
            <tr className={"overview-table__row"}>
                <td
                    onClick={e => {
                        props.navigate(generatePath("/method/:method_id", {
                            method_id: props.id,
                        }))
                    }}
                    className="overview-table__cell overview-table__cell--name overview-table__cell--interactive">
                    {props.name}
                </td>
            </tr>
        )
    },
    columns: [
        {
            title: i18n.t('Naam'),
            identifier: 'name',
            orderable: true,
            className:'overview-table__filter-cell--width-name',
            placeholder: i18n.t('Klantnaam'),
            Filter: TextFilter
        },
    ]
}

export const MethodDetailDefinition = {
    title: i18n.t("Onderdelen"),
    identifier: 'method-detail-overview',
    queryFn: getMethodDetailOverview,
    loadAllAllowed: true,
    primary: {},
    secondary: {},
    defaultOrderIdentifier: 'number',
    RowStructure: (props) => {

        return (
            <tr className={"overview-table__row"}>
                <td className={"overview-table__cell"}>
                    {props?.number}
                </td>
                <td
                    onClick={e => {
                        props.navigate(generatePath("/method/:method_id", {
                            method_id: props.id,
                        }))
                    }}
                    className="overview-table__cell overview-table__cell--name overview-table__cell--interactive">
                    {props.name}
                </td>
            </tr>
        )
    },
    columns: [
        {
            title: i18n.t('Nummer'),
            identifier: 'number',
            orderable: true,
            className:'overview-table__filter-cell--width-small',
            placeholder: i18n.t('Nr.'),
            Filter: TextFilter
        },
        {
            title: i18n.t('Naam'),
            identifier: 'name',
            orderable: true,
            className:'overview-table__filter-cell--width-name',
            placeholder: i18n.t('Naam'),
            Filter: TextFilter
        },
    ]
}
