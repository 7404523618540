import axios from "axios";
import useSWR from "swr";

export function getFunctionGroupStructure(pk=false) {

    let url = `/api/functiongroupstructures/${pk}/`;

    const {data, error, mutate} = useSWR(() => pk ? url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export async function saveFGS(pk, data) {
    let url = `/api/functiongroupstructures/${pk}/`;

    return await axios.patch(url, data).then(res => res.data)

}
