import React from "react";
import {useAuth} from "../../utils/useAuth";
import {useTranslation} from "react-i18next";
import Quickview from "../Quickview";
import Overview from "../Overview";
import {MethodOverviewDefinition} from "../Overviews/MethodOverview";

export default function MethodOverviewScreen(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();


    return (
        <Quickview>

            <Overview
                identifier={"method-overview"}
                // identKey={}
                quickview={{
                    identifier: "method",
                    name: t("Methoden")
                }}
                definition={{
                    ...MethodOverviewDefinition,
                    // interactive: true,
                    // primary: {
                    //     title: "Gebruiker aanmaken",
                    //     identifier: 'create-user',
                    //     link: './create/',
                    //     allowed: canCreateUser,
                    // },
                    // columns: UserOverviewDefinition.columns.filter(o => o.identifier !== 'action'),
                }}

            />
        </Quickview>
    )
}
