import cx from "classnames";
import React from "react";
import {useTranslation} from "react-i18next";

export function NumberFilter(props) {
    const {t, i18n} = useTranslation();

    return (
        <input
            key={props.identifier}
            className={cx('number-filter', props.className, {"number-filter--hide-controls": props.hideControls})}
            type={"number"}
            placeholder={t(props.placeholder)}
            onChange={(e) => props.setFilter(props.identifier, e.target.value)}
            value={props.state?.[props.identifier] || ""}
            maxLength={props.maxLength || ""}
            data-cy={(props.stateIdentifier?.identifier || "undefined") + "-" + props.identifier}
            onKeyDown={(e) => {
                if (e.key === "Backspace" || e.key === "Tab" || e.key === "ArrowLeft" || e.key === "ArrowRight") {
                    // Allow backspace, tab, and arrow keys
                    return;
                }
                if (!/^[0-9]$/.test(e.key)) {
                    e.preventDefault();
                }
            }}
        />
    )

}


