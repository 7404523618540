import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {InfoButton} from "../../patterns/molecules/InfoButton";
import {generatePath, Link, useParams} from "react-router-dom";
import {getUser, remoteRemoveUserRole} from "../../queries/user";
import {getClient} from "../../queries/client_detail";
import {getProject} from "../../queries/project_detail";
import SimpleMetaDisplay from "../../patterns/organisms/SimpleMeta";
import ConfirmModal from "../../patterns/organisms/ConfirmModal";
import cx from "classnames";
import {UserMeta} from "../UserMeta";
import Icon from "../../patterns/atoms/Icon";
import {useAuth} from "../../utils/useAuth";
import {IconButton} from "../../patterns/molecules/IconButton";

export function RoleRights(props) {
    const { permissions } = props;

    return (
        <ul className="role-permissions">
            {permissions.map(permission => (
                <li key={permission.name} className={"role-permissions__item"}>
                    {permission.name}
                </li>
            ))}
        </ul>

    )
}

export function Role(props) {

    const {t, i18n} = useTranslation();
    const { role, heading} = props;

    const [ displayRights, setDisplayRights ] = useState(false);
    const [ confirm, setConfirm ] = useState(false);

    return (
        <div className={"role-item"}>
            <div className="role-item__header">
                {props?.canAddRole ? (
                    <h4 className="role-item__title role-item__title--interactive" data-cy={"role-item"} onClick={() => setDisplayRights(!displayRights)}>
                        {role.name}
                        <Icon icon={"angle-" + (displayRights ? "up": "down")} sizeModifier={"xsmall"} className={"expand-button__icon"}/>
                    </h4>
                ) : (
                    <h4 className="role-item__title">{role.name}</h4>
                    )}
                {props?.canAddRole && (
                    <>
                        <IconButton icon={"trash"} text={""} onClick={() => {
                            // props.removeUserRole();
                            setConfirm(true);
                        }}/>

                        {confirm && (

                            <ConfirmModal
                                key={'remove-role'}
                                title={("Rol verwijderen")}
                                body={("Weet je zeker dat je de onderstaande rol voor deze gebruiker wilt verwijderen?")}
                                subject={heading + t(" met rol ") +role.name}
                                confirmModal={() => {
                                    props.removeUserRole();
                                }}
                                closeModal={() => setConfirm(false)} />
                        )}
                    </>
                )}
                {role.description !== "" && (
                    <InfoButton
                        icon={"info"}
                        sizeModifier={"medium"}
                        title={t("Toelichting")}
                    >
                        <div className={"popover__content"}>
                            {role.description}<br/>
                        </div>
                    </InfoButton>
                )}
            </div>



            {displayRights && (
                <RoleRights permissions={role.permissions} />
            )}
        </div>
    )

}

export function RoleGroup(props) {

    const {t, i18n} = useTranslation();
    const { title, roleGroup } = props;


    const {data: groupDetail} = props?.get ? props?.get(roleGroup?.[0]?.object.id) : { data: { name: "Algemeen"} };

    if(!groupDetail) {
        return <LoaderAnimation/>;
    }

    return (
        <div className={"role-overview__group"}>
            <h3 className={"role-overview__group-title"}>{groupDetail?.name}</h3>
            {roleGroup.map( userRole => {
                return (
                    <Role removeUserRole={() => props.removeUserRole(userRole.id)} canAddRole={props.canAddRole} key={userRole.id} role={userRole.role} heading={groupDetail?.name} />
                )
            })}
        </div>
    );

}

export function UserRights(props) {

    const {t, i18n} = useTranslation();
    const {user_id} = useParams();
    const {data: userDetail, mutate}= getUser(user_id);

    const auth = useAuth();
    const canAddRole = auth.hasPermission('base.add_userrole', userDetail?.client?.id, 'client');

    const removeUserRole = (role_id) => {
        if(canAddRole) {
            remoteRemoveUserRole(role_id);
            mutate();

        }
    }


    if(!userDetail) {
        return <LoaderAnimation/>;
    }

    const list = [
        {
            type: "global",
            title: t("Toegewezen gebruikersrollen"),
            icon: "user",
            roles: userDetail.roles.filter(o => !o.object_id),
            get: false,
        },
        {
            type: "client",
            title: t("Toegewezen klantrollen"),
            icon: "client",
            roles: userDetail.roles.filter(o => o?.object?.type === "client"),
            get:getClient,
        },
        {
            type: "project",
            title: t("Toegewezen projectrollen"),
            icon: "project",
            roles: userDetail.roles.filter(o => o?.object?.type === "project"),
            get:getProject
        }
    ].map(item => {
        return {
            ...item,
            roles: item.roles.reduce( (acc, role) => {
                const key = role.object_id;
                if(!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(role)
                return acc;
            }, {})
        }
    });



    return (
        <div className={"content-container"}>
            <div className={"global-rights-container"}>
                {list.map( roleType => {
                    if(Object.keys(roleType.roles).length === 0)  {
                        return null;
                    }
                    return (
                        <div key={roleType.type} className={"role-overview"}>
                                <h2 className="role-overview__title" data-cy={"role-overview-title"}>
                                    <Icon icon={roleType.icon}/>
                                    {roleType.title}
                                </h2>

                            {Object.values(roleType.roles).map((roleGroup, idx) => (
                                <RoleGroup canAddRole={canAddRole} removeUserRole={removeUserRole} title={roleType.title} get={roleType.get} key={idx} type={roleType.type} roleGroup={roleGroup} />
                            ))}

                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export function UserRoleScreen(props) {

    return (
        <>
            <UserMeta />
            <UserRights />
        </>
    );
}
