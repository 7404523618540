import ApplicationHeader from "../../patterns/organisms/ApplicationHeader";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import TabNavigation from "../TabNavigation";

export default function TabScreen(props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <ApplicationHeader/>

            <div
                className={"content-wrapper content-wrapper--border-bottom content-wrapper--white content-wrapper--no-margin-bottom"}>
                <div className={"content-container"}>
                    <div className={"meta-info meta-info--no-breadcrumb"}>
                        <div className={"meta-info__top"}>
                            <h1 className={"meta-info__title"}>{t(props.title)}</h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content-container">
                <TabNavigation tabs={props.tabs} />
                {props.children}
            </div>

        </>
    )
}
