import React from "react";
import {useLocation, useParams} from "react-router-dom";
import {getFunction, getFunctionOptions} from "../queries/function_detail";
import {getClient} from "../queries/client_detail";
import {getProject} from "../queries/project_detail";
import useSWR from "swr";
import {isFuwaterVbalans} from "../utils/constants";
import {useAuth} from "../utils/useAuth";

export const useFunctionCreate = () => {
    let location = useLocation();
    let {client_id, project_id} = useParams();

    const auth = useAuth();
    const client = getClient(client_id);
    const project = getProject(project_id);
    const function_options = getFunctionOptions()?.data?.actions?.POST;
    const basedOnFunction = getFunction(location?.state?.based_on_id);

    const method = useSWR(() => project?.data?.method?.id ? `/api/methods/${project?.data?.method?.id}/` : false);

    const type_options = function_options?.type.choices
        .filter(o => (o.value === 'company' && !isFuwaterVbalans(project?.data?.method?.id)) || o.value !== 'company')
        .filter(o => auth.checkAccessList(
                'base.change_' + o.value + '_function',
                [
                    [project?.data?.id, 'project'],
                    [client?.data?.id, 'client']
                ]
            )
        )
        .filter(o => (project?.data?.is_collective_labour_agreement === true ? o.value === 'reference' : true));

    const canChangeVisibility = auth.hasPermission('base.change_function_visibility', project?.data?.id, 'project');
    const canChangeProtection = auth.hasPermission('base.change_function_protection', project?.data?.id, 'project');

    const canChangeCollectiveLabourAgreement = auth.hasPermission("base.change_collective_labour_agreement");
    const canChangeIfCollectiveLabourAgreement = canChangeCollectiveLabourAgreement || project?.data?.is_collective_labour_agreement === false;

    return {
        client_id: parseInt(client_id),
        client: client?.data,
        project_id: parseInt(project_id),
        project: project?.data,
        method: method?.data,

        canChangeIfCollectiveLabourAgreement,
        canChangeProtection,
        canChangeVisibility,
        basedOnFunction,
        type_options: type_options,

        describer_group: project?.data?.default_describer_group,
        applier_one_group: project?.data?.default_applier_one_group,
        applier_two_group: project?.data?.default_applier_two_group,
        based_on_name: basedOnFunction?.data?.name,
    };
}
