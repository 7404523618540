import React, {useState} from "react";
import {InfoButton} from "../molecules/InfoButton";
import {useTranslation} from "react-i18next";
import {clientStatus} from "../../utils/constants";
import {Link, useNavigate, useParams} from "react-router-dom";
import {generatePath} from "react-router-dom";
import LoaderAnimation from "../atoms/LoaderAnimation";
import {useAuth} from "../../utils/useAuth";
import {getClient} from "../../queries/client_detail";
import ConfirmModal from "./ConfirmModal";
import {archiveClient} from "../../queries/client_detail";
import cx from "classnames";

export default function ClientMetaDisplay(props) {

    const {t, i18n} = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();

    const {client_id} = useParams();

    const {data: clientDetail} = getClient(client_id);

    const canChangeUser = auth.checkAccessList('base.change_authuser', [
        ['client_id', 'client']
    ])
    const canEditClient = auth.checkAccessList('base.change_client', [
        ['client_id', 'client']
    ])
    const hasArchiveRight = auth.checkAccessList('base.archive_client', [
        ['client_id', 'client'],
        [clientDetail?.id, 'client']
    ])
    const canSetupEnvironments = auth.checkAccessList('base.can_setup_environments', [
        ['client_id', 'client'],
        [clientDetail?.id, 'client']
    ])

    const [activeContext, setActiveContext] = useState(false);
    if(!clientDetail || clientDetail?.isLoading) {
        return <LoaderAnimation/>
    }

    const archive = async (id) => {
        await archiveClient(id);
        navigate("/client");
    }

    const contextItemsAllowed = (list) => {
        return list.filter(o => !('allowed' in o) || ('allowed' in o && (o.allowed === true || o.allowed() !== false)));
    }

    const contextItems = [
        {
            allowed: () => false,
            identifier: 'project-excel',
            link: (
                <a href={"#"}  onClick={e => {
                    e.preventDefault();
                    navigate(generatePath(`/client/:client_id/project/export/excel/`, {
                        client_id: clientDetail.id,
                    }), {
                        replace: true,
                        state: {
                            cancel: generatePath('/client/:client_id/', {
                                client_id: clientDetail.id,
                            })
                        }
                    });
                }} className={"dropdown-field__label"} title={t("Exporteer projecten naar Excel")}>
                    {t("Exporteer projecten naar Excel")}
                </a>
            ),
        },
        {
            allowed: () => false,
            identifier: 'project-pdf',
            link: (
                <a href={"#"}  onClick={e => {
                    e.preventDefault();
                    navigate(generatePath(`/client/:client_id/project/export/pdf/`, {
                        client_id: clientDetail.id,
                    }), {
                        replace: true,
                        state: {
                            cancel: generatePath('/client/:client_id/', {
                                client_id: clientDetail.id,
                            })
                        }
                    });
                }} className={"dropdown-field__label"} title={t("Exporteer projecten naar PDF")}>
                    {t("Exporteer projecten naar PDF")}
                </a>
            ),
        },
        {
            allowed: () => canEditClient,
            identifier: 'user-excel',
            link: (
                <a href={"#"}  onClick={e => {
                    e.preventDefault();
                    navigate(generatePath(`/client/:client_id/user/export/excel/`, {
                        client_id: clientDetail.id,
                    }), {
                        replace: true,
                        state: {
                            cancel: generatePath('/client/:client_id/', {
                                client_id: clientDetail.id,
                            })
                        }
                    });
                }} className={"dropdown-field__label"} title={t("Exporteer gebruikers naar Excel")}>
                    {t("Exporteer gebruikers naar Excel")}
                </a>
            ),
        },
        {
            allowed: () => canEditClient,
            identifier: 'user-pdf',
            link: (
                <a href={"#"}  onClick={e => {
                    e.preventDefault();
                    navigate(generatePath(`/client/:client_id/user/export/pdf/`, {
                            client_id: clientDetail.id,
                        }), {
                            replace: true,
                            state: {
                                cancel: generatePath('/client/:client_id/', {
                                    client_id: clientDetail.id,
                                })
                            }
                        });
                }} className={"dropdown-field__label"} title={t("Exporteer gebruikers naar PDF")}>
                    {t("Exporteer gebruikers naar PDF")}
                </a>
            ),
        },
        {
            allowed: () => canEditClient,
            identifier: 'hr2',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => canSetupEnvironments,
            identifier: 'environment-create',
            link: (
                <Link to={generatePath('/client/:client_id/environment/create', { client_id: clientDetail?.id})} className={"dropdown-field__label"} title={t("Demo-omgeving aanmaken")} data-cy={"client-dropdown-environment-create"}>
                    {t("Demo-omgeving aanmaken")}
                </Link>
            ),
        },
        {
            allowed: () => canSetupEnvironments,
            identifier: 'hr3',
            link: (
                <hr/>
            )
        },
        {
            allowed: () => hasArchiveRight,
            identifier: 'archive-client',
            link: (
                <a className={"dropdown-field__label"} title={t("Archiveer deze klant")} onClick={() => setActiveContext('archive-client')}>
                    {t("Klant archiveren")}
                </a>
            ),
            Component: <ConfirmModal
                key={'archive-client'}
                title={("Klant archiveren")}
                body={("Weet je zeker dat je deze klant wilt archiveren?") + ":"}
                subject={clientDetail?.name}
                confirmModal={() => archive(clientDetail?.id)}
                closeModal={() => setActiveContext(false)}/>
        },
        {
            allowed: () => canEditClient,
            identifier: 'client-edit',
            link: (
                <Link to={generatePath('/client/:client_id/edit', { client_id: clientDetail?.id})} className={"dropdown-field__label"} title={t("Bewerk Klanteigenschappen")} data-cy={"client-dropdown-edit"}>
                    {t("Klanteigenschappen")}
                </Link>
            ),
        },
    ]

    return (
        <div className={"content-wrapper content-wrapper--white content-wrapper--border-bottom"}>
            <div className={"content-container"}>
                <div className={"meta-info"}>
                    <div className={"meta-info__top"}>
                        <div className={"meta-info__primary"}>
                            <div className={"meta-info__title-container"}>
                                <h2 className={"meta-info__title"} data-cy={"client-meta-info-title"}>{clientDetail?.name}</h2>
                                <span className={"meta-info__status-tag status-tag status-tag--active"}>{clientStatus[clientDetail?.status]}</span>
                            </div>
                        </div>
                        <div className={"meta-info__secondary"}>
                            <div className={"meta-info__buttons"}>
                                {contextItemsAllowed(contextItems).length > 0 &&
                                    <InfoButton
                                        icon={"context-menu"} title={"Contextmenu"}
                                        popOverClickClass={"dropdown-toggle"}
                                        popOverClass={"dropdown"}
                                        popOverClassActive={"dropdown dropdown--active"}
                                        border={"true"}
                                        data-cy={"client-context-menu"}
                                    >
                                        <ul>
                                            {contextItemsAllowed(contextItems).map((obj, idx) => {
                                                return (
                                                    <li key={obj.identifier} className={"dropdown-field"}>
                                                        {obj.link}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </InfoButton>
                                }
                                {contextItemsAllowed(contextItems).map((obj) => {
                                    if(activeContext === obj.identifier)  {
                                        return obj.Component;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
