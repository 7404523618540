import axios from "axios";
import useSWR from "swr";

/*
Aanmaken message (endpoint)
/api/messages.json

Commentaar op functieniveau
/api/messages/?_type=function&function__function_id=4791

Commentaar op functieniveau aanmaken (payload)
{
  "resourcetype": "FunctionMessage",
  "message": "Mand",
  "function_id": 59242,
  "public": true
}


Commentaar op functieniveau + domein / aspect
/api/messages/?_type=scoringstructure&scoringstructure__scoring_structure_id=26&scoringstructure__function_id=4791

Commentaar op functieniveau + domein / aspect aanmaken (payload)
{
  "resourcetype": "ScoringStructureMessage",
  "message": "Mand",
  "scoring_structure_id": 26
  "function_id": 4791,
  "public": true
}


Notitie op domeinniveau / aspect
/api/messages/?_type=scoringstructure&scoringstructure__scoring_structure_id=26

Notitie op domeinniveau / aspect aanmaken (payload)
{
  "resourcetype": "ScoringStructureMessage",
  "message": "Mand",
  "scoring_structure_id": 26
  "public": false
}


 */



export function remoteSetMessage(message="", resourcetype=false, is_public=false, function_id=false, scoring_structure_id=false ) {

    if(!(['FunctionMessage', 'ScoringStructureMessage'].includes(resourcetype))) {
        return false;
    }

    let data = {
        resourcetype,
        message,
        is_public,
    }

    if(function_id) {
        data.function_id = function_id;
    }
    if(scoring_structure_id) {
        data.scoring_structure_id = scoring_structure_id;
    }

    return axios.post(`/api/messages.json`, data
    ).then(result => {
        return result;
    });
}

export function getMessages(resourcetype='FunctionMessage', is_public=false, function_id=false, scoring_structure_id=false) {

    let url = `/api/messages/?resourcetype=${resourcetype}&is_public=${is_public}`;

    switch(resourcetype) {
        case 'FunctionMessage':
            url = url + `&function__function_id=${function_id}`;
            break;
        case 'ScoringStructureMessage':
            url = url + `&scoringstructure__function_id=${function_id}&scoringstructure__scoring_structure_id=${scoring_structure_id}`;
            break;
        default:
            return false;
    }

    const {data, error, mutate} = useSWR(() => resourcetype ?  url : false);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }

}

export async function deleteMessage(messageId) {
    await axios.delete(`/api/messages/${messageId}/?resourcetype=ScoringStructureMessage`).then(res => res)
}
