import React, {useCallback, useState as useReactState} from "react";
import IconButton from "../molecules/IconButton";
import cx from "classnames";
import { useState, singlePopOver} from "../../components/PopOverHoC";

export const InfoButtonPatternState = {
    prefix_identifier: 'prefix-',
    identifier: 'identifier-',
    children: (
        <>
            Hoi Titel Tekst
        </>
    )
}

// defines a default icon popover which triggers a popover which can be open simultaniously with others
// If you want a singleton/singular popover, use the composed "IconPopoverButton" instead (see below)
export default function DefaultIconPopoverButton(props) {

    const full_identifier = props?.prefix_identifier || '' + 'icon-popover' + '-' + props?.id;

    const icon = props?.icon || "info";
    const active = props?.active || "";
    const sizeModifier = props?.sizeModifier || "";
    const title = props?.title || "";
    const border = props?.border || "";

    // we use the HoC utility function useState which returns either the HoC state items or the react native useState vars
    const [ popOverActive, setPopOverActive ] =  useState(props, full_identifier, false);
    const [ popOverSourceRect, setPopOverSourceRect] = useReactState(false);
    const [ clickablePopOverSourceRect, setClickablePopOverSourceRect] = useReactState(false);

    const popOverClickClass = props?.popOverClickClass || "";
    const popOverClass = props?.popOverClass || "popover";
    const popOverClassActive = props?.popOverClassActive || "popover--active";

    // we determine for both the content (callback) and icon (clickablecallback) the positions as they are both required
    const callbackRef = React.useCallback(domNode => {
        if(domNode) {
            setPopOverSourceRect(domNode.getBoundingClientRect());
        }
    }, []);
    const clickableCallbackRef = React.useCallback(domNode => {
        if(domNode) {
            setClickablePopOverSourceRect(domNode.getBoundingClientRect());
        }
    }, []);

    // We want to memoize the IconButton so it wont rerender often, but we need a callback for it so the click reference doesnt change
    const toggle = React.useCallback((domNode) => {
        setPopOverActive(!popOverActive, full_identifier)
    }, [popOverClickClass, popOverClass, popOverClassActive, popOverActive]);


    const clickContent = props?.clickContent ? props.clickContent : (
        <IconButton
            icon={icon}                     // Icon to use ("info")
            active={active}                 // Variation of display in color
            sizeModifier={sizeModifier}     // Variation of display in size
            title={title}
            className={popOverClickClass}
            border={border}
        />
    )

    return (
        <div key={props.prefix_identifier}
             className={props.className}
             ref={clickableCallbackRef}
             onClick={toggle}
             data-cy={props?.["data-cy"]}
        >


            {clickContent}

            {popOverActive && (

              <div
                  ref={callbackRef}

                  className={cx(popOverClass, {
                      [popOverClassActive]: popOverActive,
                      // Calculation is done using the clickable part as the source and adding the size of the content.
                      "popover--flip-right-item-left": (clickablePopOverSourceRect.x + popOverSourceRect.width) > (window.innerWidth / 2)
                  })}
              >
                  { props.children }
              </div>
            )}

        </div>
    )
}

// wrapper function for creating Infobuttons easily
export function InfoButtonDisplay(props) {
    let passProps = {
        ...props,
        id: props?.id || props?.icon || 'not-set'
    };

    return (
        <DefaultIconPopoverButton
            {...passProps}
        />
    )
}

// singular/singleton variation of the Icon Button Popover
export const IconPopoverButton = singlePopOver(DefaultIconPopoverButton);

// singular/singleton variation of the Info Button Popover
export const InfoButton = singlePopOver(InfoButtonDisplay);
