import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import LoginDisplay from "../patterns/organisms/Login";
import {useAuth} from "../utils/useAuth";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Training from "./Training";
import SVG from "../utils/SVG";

export default function Login(props) {
    let auth = useAuth();
    const {t} = useTranslation();
    let navigate = useNavigate();
    const state = useSelector(state => state.AuthState);
    const portalState = useSelector( state => state.SiteInfo);
    portalState?.links?.sort((a, b) => a.order - b.order);

    let formProps = Object.assign({}, props, {
        onSubmit: values => auth.login(values.username, values.password),
    });

    useEffect(() => {
       if(auth.authorized()) {
           if(auth.insightUser) {
               navigate(state.next || 'home');
           }
           navigate(state.next || 'home');
       }
    },[]);

    const error_message = useSelector(state => state.AuthState.error_message) || false;

    return(

        <div className={"login-page-container"}>
            <div className={"login"}>

                <div className={"login__header text-center"}>
                    <h1 className={"login__header-title"}>{props?.title || "FUWA Online"}</h1>
                    <span className={"login__header-subtitle"}>{t("Functiewaarderen met een online applicatie")}</span>
                </div>

                <LoginDisplay
                    title={portalState.title}
                    error_message={error_message}
                    {...formProps}
                />

            </div>

            <div className={"links-container"}>
                <div className={"mainsite-links"}>
                    <div className={"mainsite-links__heading"}>
                        <SVG src={"logo-humancapitalgroup.svg"} className={"mainsite-links__logo"} />
                        <address className={"mainsite-links__address"}>
                            <a className={"mainsite-links__phone"} href={"tel:0302193960"}>030 219 39 60</a>
                            <a className={"mainsite-links__mail"} href={"mailto:info@humancapitalgroup.nl"}>info@humancapitalgroup.nl</a>
                        </address>
                    </div>
                    <div className={"mainsite-links__content"}>
                        <div>
                            <p className={"mainsite-links__description paragraph"}>
                                Functiewaardering is complex. Daarom heeft Human Capital Group een applicatie ontwikkeld waarmee je zelf je functies waardeert: FUWA Online. FUWA Online ondersteunt ruim 700 organisaties in verschillende sectoren op het gebied van functiewaardering.
                            </p>
                            <p className={"mainsite-links__description paragraph"}>
                                 De applicatie ondersteunt bij de toepassing van verschillende functiewaarderingssystemen, zoals FuWater, Bakkenist, MNT, FuwaProv en VBalans. De applicatie maakt simpel en snel een objectieve (her)waardering.
                            </p>
                        </div>
                        <div>
                            <p className={"mainsite-links__cta"}>Wilt u meer weten of een HR-vraagstuk bespreken met één van onze collega's?</p>
                            <ul className={"mainsite-links__list"}>
                                {portalState?.links?.map((link,idx) => {
                                    return <li className={"mainsite-links__list-item"} key={link.target}>
                                        <a className={"mainsite-links__link"} href={link.target} target="_blank">{link.label}</a>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <Training />
            </div>
        </div>
    )
}
