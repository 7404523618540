import {useDebounceVercel as useDebounce} from "../utils/useDebounce";
import {buildFilter, buildOrder, loadIfAllowed, optionsFetcher} from "../utils/constants";
import useSWR, {useSWRInfinite} from "swr";
import axios from "axios";

export function getEnvironmentOverview(stateToDebounce) {
    const identifiers = ['environment-overview'];

    const fetchState = useDebounce(stateToDebounce);

    const filtering = buildFilter(
        Object.assign(
            {},
            fetchState?.filter,
        ));

    const ordering = buildOrder(fetchState?.ordering);

    return useSWRInfinite(
        index => identifiers.includes(fetchState?.identifier) ? `/api/environments/?page_size=${fetchState.pagination[fetchState.type].size}&page=${index + 1}&ordering=${ordering}${filtering}${loadIfAllowed(fetchState)}` : false,
        {
            initialSize: fetchState?.size || 1, // required for restoring correct page
        }
    );
}

export async function deleteEnvironment(id) {
    await axios.delete(`/api/environments/${id}/`);
}

export async function createEnvironment(values, navigate) {
    await axios.post("/api/environments/", values).then(result => {
        if(result.status === 201) {
            navigate('../');
        } else {
            throw new Error("server-error-environment-create", {cause: result});
        }
    })
        .catch(function(error) {
            throw new Error("server-error", {cause: error});
        })
}


export async function editEnvironment(id, values, navigate) {
    return await axios.patch(`/api/environments/${id}/`, values).then(result => {
        if(result.status === 200) {
            navigate('../');
        } else {
            throw new Error("server-error-environment-edit", {cause: result});
        }
    }).catch(function(error) {
        throw new Error("server-error", {cause: error});
    })
}

export function getEnvironment(id) {
    const {data, error, mutate} = useSWR(`/api/environments/${id}/`)

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate
    }
}

export function optionsEnvironment() {
    const url = "/api/environments/";

    const {data, error, mutate} = useSWR(url, optionsFetcher);

    return {
        data: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

