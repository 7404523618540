import React from "react";

export function EmptyTitle(props) {
    // emtpy title does NOT get classes for ordering!
    return (
        <div className="overview-table__column-title overview-table__column-title--empty">
            &nbsp;
        </div>
    )
}
