import {SET_POPOVER_ACTIVE} from "../actions/popover";

const defaultState = {
    identifier: false
}
export function PopOverState(state=defaultState, action) {
    switch(action.type) {

        case SET_POPOVER_ACTIVE:
            return {
                identifier: action.identifier
            }

        default:
            return state;
    }
}
