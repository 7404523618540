import React from 'react';
import cx from "classnames";
import Icon from "../atoms/Icon";
import i18n from "../../utils/i18n";

export const NotificationPatternState = {
    text: i18n.t('Default Text'),
    type: 'info',

    plMod: {
        error: {
            type: 'error'
        },

        warning: {
            type: 'warning'
        },
    }
}

export default function Notification(props) {
    let className = "";
    let icon = "warning";

    switch (props.type) {
        case "error":
            className = "notification--error";
            icon = "error";
            break;
        case "warning":
            className = "notification--warning";
            icon = "warning";
            break;
        case "info":
            className = "notification--info";
            icon = "warning";
            break;
        default:
            break;
    }

    return (
        <p data-cy={props["data-cy"]} className={cx('notification', className,
            {
                'notification--large': props.sizeModifier === 'large'
            },
            props.className
        )}>
            <Icon
                icon={icon}
                className={"notification__icon"}
                sizeModifier={cx({'small': props.sizeModifier !== 'large'})}
            />
            {props.text}
        </p>
    )
}
