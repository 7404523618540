import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {generatePath, Link, useParams} from "react-router-dom";
import SimpleMetaDisplay from "../../patterns/organisms/SimpleMeta";
import {getUser} from "../../queries/user";
import Quickview from "../Quickview";
import Overview from "../Overview";
import {ClientProjectOverviewDefinition} from "../Overviews/ClientProjectOverview";
import {UserGroupOverviewDefinition} from "../Overviews/UserGroupOverview";
import {ClientOverviewDefinition} from "../Overviews/ClientOverview";
import LoaderAnimation from "../../patterns/atoms/LoaderAnimation";
import {UserMeta} from "../UserMeta";
import {withAccessRight} from "../../utils/withAccessHoC";
import {useAuth} from "../../utils/useAuth";



export function UserDetailScreenBase(props) {

    const auth = useAuth();
    const {t, i18n} = useTranslation();
    const {user_id} = useParams();

    const {data: userDetail}= getUser(user_id);

    if(!userDetail) {
        return <LoaderAnimation/>
    }

    const hasPrimary = auth.hasPermission("base.change_authuser") ? {
        title: t("Project toewijzen"),
        identifier: "project-assign-project",
        link: "/",
    } : {};

    return (
        <>
            <UserMeta />

            <div className={"content-container"}>

                <Quickview>
                    <Overview
                        identifier={"user-projects"}
                        identKey={{
                            user_id,
                        }}
                        quickview={{
                            identifier: "user-projects",
                            name: t("Projecten")
                        }}
                        definition={{
                            ...ClientProjectOverviewDefinition,
                            title: t("Toegankelijke projecten"),
                            primary: hasPrimary,
                        }}
                    />

                    <Overview
                        identifier={"user-projectgroups"}
                        identKey={{
                            user_id,
                        }}
                        quickview={{
                            identifier: "user-projectgroups",
                            name: t("Gebruikersgroepen")
                        }}
                        contextData={{
                            client_id: userDetail?.client?.id || 0,
                        }}
                        definition={{
                            ...UserGroupOverviewDefinition,
                            title: t("Gebruikersgroepen"),
                            columns: UserGroupOverviewDefinition.columns
                                .filter(c => c.identifier !== 'users')
                                .filter(c => c.identifier !== 'action')

                        }}
                    />

                    <Overview
                        identifier={"user-client-overview"}
                        quickview={{
                            identifier: "client-overview",
                            name: t("Klanten")
                        }}
                        definition={{
                            ...ClientOverviewDefinition,
                        }}
                        contextData={{
                            location,
                        }}
                    />
                </Quickview>
            </div>
        </>
    )
}


export default withAccessRight(
    UserDetailScreenBase,
    'base.view_authuser',
    'client_id',
    'client'
)
