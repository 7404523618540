import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";

export default function ByNameDate(props) {

    const {t, i18n} = useTranslation();

    return (
        <div className={"modified-by"}>
            {(!props?.get_full_name && !props?.first_name && !props?.last_name) ? (
                <>
                    {t("Op")}
                </>
            ) : props?.get_full_name ? (
                <>
                    {t("Door")} {props?.get_full_name || t("Onbekend")} {t("op")}
                </>
            ): (
                <>
                    {t("Door")} {props?.first_name} {props?.last_name || t("Onbekend")} {t("op")}
                </>
            )}
            {" "}
            {props?.date ? (
                <time dateTime={props.date}>
                    {new Intl.DateTimeFormat("nl-NL", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                    }).format(new Date(props?.date))}
                </time>
            ) : (
                <>
                    {t("Onbekende datum")}
                </>
            )}
        </div>
    )

}
